import React, {useEffect, useState} from 'react';
import {Card, Col, Form, Row} from "react-bootstrap";
import {Controller, useFormContext, useWatch} from "react-hook-form";
import RoamingInvoiceFormTypeSelector from "./RoamingInvoiceFormTypeSelector";
import RoamingInvoiceFormOldInvoiceInfo from "./RoamingInvoiceFormOldInvoiceInfo";
import {selectDateFormat, selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import DatePicker from "react-datepicker";
import classNames from "classnames";
import RoamingReferenceService from "../../../../../services/roamingReference/roamingReferenceService";
import ReactSelect from "react-select";
import dayjs from "dayjs";

const RoamingInvoiceFormCardInfo = () => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const dateFormat = useSelector(selectDateFormat);
    const form = useFormContext();
    const hasRent = useWatch({name: `hasRent`, exact: true});
    const sellerInnOrPinfl = useWatch({name: `seller.innOrPinfl`, exact: true});
    const buyerInnOrPinfl = useWatch({name: `buyer.innOrPinfl`, exact: true});
    const [rentContracts, setRentContracts] = useState([])
    const [rentContract, setRentContract] = useState(null)

    useEffect(() => {
        if (rentContract) {
            const date = dayjs(rentContract.date, 'YYYY-MM-DD')
            form.setValue('contractDoc.contractNo', rentContract.id)
            form.setValue('contractDoc.contractDate', date.toDate())
        }
    }, [rentContract]);

    useEffect(async () => {
        if (sellerInnOrPinfl && buyerInnOrPinfl && hasRent) {
            try {
                const {data} = await RoamingReferenceService.getRentContracts({
                    seller: sellerInnOrPinfl,
                    buyer: buyerInnOrPinfl
                });
                setRentContracts(data)
            } catch (e) {
                console.error(e)
                setRentContracts([])
                setRentContract(null)
            }
        } else {
            setRentContracts([])
            setRentContract(null)
        }
    }, [sellerInnOrPinfl, buyerInnOrPinfl, hasRent]);

    return (
        <Card className="h-100">
            <Form.Group hidden>
                <Form.Label>{t(lang, 'roaming.invoice.id')}</Form.Label>
                <Form.Control name={`facturaId`}
                              {...form.register(`facturaId`)}
                />
            </Form.Group>

            <Card.Body>
                <Row className="g-2">
                    <Col xs={12} md={4}>
                        <Form.Group>
                            <Form.Label>
                                {t(lang, 'roaming.invoice.type')}
                                <span className="text-danger">*</span>
                            </Form.Label>
                            <Controller name={`facturaType`}
                                        rules={{
                                            required: t(lang, 'items.common.validations.is_required')
                                        }}
                                        render={({field, fieldState: {error}}) => (
                                            <RoamingInvoiceFormTypeSelector defaultType={field.value}
                                                                            onChange={field.onChange}
                                                                            selectProps={{
                                                                                menuPortalTarget: document.body,
                                                                                styles: {
                                                                                    control: base => ({
                                                                                        ...base,
                                                                                        border: error?.message && '1px solid #e63757',
                                                                                    }),
                                                                                    container: (provided) => ({
                                                                                        ...provided,
                                                                                        margin: '-1px'
                                                                                    }),
                                                                                    menuPortal: (provided) => ({
                                                                                        ...provided,
                                                                                        zIndex: 9999
                                                                                    })
                                                                                }
                                                                            }}
                                            />
                                        )}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={4}>
                        <Form.Group>
                            <Form.Label>
                                {t(lang, 'roaming.invoice.number')}
                                <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control name={`facturaDoc.facturaNo`}
                                          placeholder={t(lang, 'roaming.invoice.number.placeholder')}
                                          isInvalid={form.formState.errors?.facturaDoc?.facturaNo}
                                          {...form.register(`facturaDoc.facturaNo`, {
                                              required: t(lang, 'items.common.validations.is_required'),
                                          })}
                            />
                            <Form.Control.Feedback type="invalid">{form.formState.errors?.facturaDoc?.facturaNo?.message}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={4}>
                        <Form.Group>
                            <Form.Label>
                                {t(lang, 'roaming.invoice.date')}
                                <span className="text-danger">*</span>
                            </Form.Label>
                            <Controller name={`facturaDoc.facturaDate`}
                                        rules={{
                                            required: t(lang, 'items.common.validations.is_required'),
                                        }}
                                        render={({field, fieldState: {error}}) => (
                                            <DatePicker selected={field.value}
                                                        onChange={field.onChange}
                                                        placeholderText={t(lang, "roaming.invoice.date.placeholder")}
                                                        dateFormat={dateFormat}
                                                        isClearable
                                                        className={classNames('form-control', {
                                                            'is-invalid': form.formState.errors?.facturaDoc?.facturaDate,
                                                        })}
                                                        wrapperClassName={classNames({
                                                            'is-invalid': form.formState.errors?.facturaDoc?.facturaDate
                                                        })}
                                            />
                                        )}
                            />
                            <Form.Control.Feedback type="invalid">{form.formState.errors?.facturaDoc?.facturaDate?.message}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>

                {!hasRent &&
                    <Row className="g-2">
                        <Col xs={12} md={4}/>
                        <Col xs={12} md={4}>
                            <Form.Group>
                                <Form.Label>
                                    {t(lang, 'roaming.contract.number')}
                                    <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control name={'contractDoc.contractNo'}
                                              placeholder={t(lang, 'roaming.contract.number.placeholder')}
                                              isInvalid={form.formState.errors?.contractDoc?.contractNo}
                                              {...form.register('contractDoc.contractNo', {
                                                  required: t(lang, 'items.common.validations.is_required'),
                                              })}

                                />
                                <Form.Control.Feedback type="invalid">{form.formState.errors?.contractDoc?.contractNo?.message}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={4}>
                            <Form.Group>
                                <Form.Label>
                                    {t(lang, 'roaming.contract.date')}
                                    <span className="text-danger">*</span>
                                </Form.Label>
                                <Controller name={'contractDoc.contractDate'}
                                            rules={{
                                                required: t(lang, 'items.common.validations.is_required'),
                                            }}
                                            render={({field}) => (
                                                <DatePicker selected={field.value}
                                                            onChange={field.onChange}
                                                            placeholderText={t(lang, "roaming.invoice.date.placeholder")}
                                                            dateFormat={dateFormat}
                                                            isClearable
                                                            className={classNames('form-control', {
                                                                'is-invalid': form.formState.errors?.contractDoc?.contractDate,
                                                            })}
                                                            wrapperClassName={classNames({
                                                                'is-invalid': form.formState.errors?.contractDoc?.contractDate
                                                            })}
                                                />
                                            )}
                                />
                                <Form.Control.Feedback type="invalid">{form.formState.errors?.contractDoc?.contractDate?.message}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                }

                {hasRent &&
                    <>
                        <Row className="g-2">
                            <Col xs={12} md={4}>
                                <Form.Label>
                                    {t(lang, 'roaming.invoice.form.rent.contract_selector.title')}
                                    <span className="text-danger">*</span>
                                </Form.Label>
                                <ReactSelect options={rentContracts}
                                             getOptionLabel={option => t(lang, 'roaming.invoice.form.rent.contract_selector.option', {
                                                 id: option.id,
                                                 date: option.date
                                             })}
                                             onChange={(option => {
                                                 setRentContract(option || null)
                                             })}
                                />
                            </Col>
                            <Col xs={12} md={4}>
                                <Form.Group>
                                    <Form.Label>
                                        {t(lang, 'roaming.invoice.form.rent.id')}
                                        <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Form.Control name={'contractDoc.contractNo'}
                                                  placeholder={t(lang, 'roaming.contract.number.placeholder')}
                                                  isInvalid={form.formState.errors?.contractDoc?.contractNo}
                                                  disabled={true}
                                                  {...form.register('contractDoc.contractNo', {
                                                      required: t(lang, 'items.common.validations.is_required'),
                                                  })}

                                    />
                                    <Form.Control.Feedback type="invalid">{form.formState.errors?.contractDoc?.contractNo?.message}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={4}>
                                <Form.Group>
                                    <Form.Label>
                                        {t(lang, 'roaming.invoice.form.rent.date')}
                                        <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Controller name={'contractDoc.contractDate'}
                                                rules={{
                                                    required: t(lang, 'items.common.validations.is_required'),
                                                }}
                                                render={({field}) => (
                                                    <DatePicker selected={field.value}
                                                                onChange={field.onChange}
                                                                disabled={true}
                                                                placeholderText={t(lang, "roaming.invoice.date.placeholder")}
                                                                dateFormat={dateFormat}
                                                                className={classNames('form-control', {
                                                                    'is-invalid': form.formState.errors?.contractDoc?.contractDate,
                                                                })}
                                                                wrapperClassName={classNames({
                                                                    'is-invalid': form.formState.errors?.contractDoc?.contractDate
                                                                })}
                                                    />
                                                )}
                                    />
                                    <Form.Control.Feedback type="invalid">{form.formState.errors?.contractDoc?.contractDate?.message}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="g-2">
                            <Col xs={12} md={4}>

                            </Col>
                            <Col xs={12} md={4}>
                                <Form.Group>
                                    <Form.Label>
                                        {t(lang, 'roaming.invoice.rent.from')}
                                        <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Controller name={'facturaRentDoc.from'}
                                                rules={{
                                                    required: t(lang, 'items.common.validations.is_required'),
                                                }}
                                                render={({field}) => (
                                                    <DatePicker selected={field.value}
                                                                onChange={field.onChange}
                                                                placeholderText={t(lang, "roaming.invoice.date.placeholder")}
                                                                dateFormat={dateFormat}
                                                                isClearable
                                                                className={classNames('form-control', {
                                                                    'is-invalid': form.formState.errors?.facturaRentDoc?.from,
                                                                })}
                                                                wrapperClassName={classNames({
                                                                    'is-invalid': form.formState.errors?.facturaRentDoc?.from
                                                                })}
                                                    />
                                                )}
                                    />
                                    <Form.Control.Feedback type="invalid">{form.formState.errors?.facturaRentDoc?.from?.message}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={4}>
                                <Form.Group>
                                    <Form.Label>
                                        {t(lang, 'roaming.invoice.rent.to')}
                                        <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Controller name={'facturaRentDoc.to'}
                                                rules={{
                                                    required: t(lang, 'items.common.validations.is_required'),
                                                }}
                                                render={({field}) => (
                                                    <DatePicker selected={field.value}
                                                                onChange={field.onChange}
                                                                placeholderText={t(lang, "roaming.invoice.date.placeholder")}
                                                                dateFormat={dateFormat}
                                                                isClearable
                                                                className={classNames('form-control', {
                                                                    'is-invalid': form.formState.errors?.facturaRentDoc?.to,
                                                                })}
                                                                wrapperClassName={classNames({
                                                                    'is-invalid': form.formState.errors?.facturaRentDoc?.to
                                                                })}
                                                    />
                                                )}
                                    />
                                    <Form.Control.Feedback type="invalid">{form.formState.errors?.facturaRentDoc?.to?.message}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                    </>
                }
                <RoamingInvoiceFormOldInvoiceInfo/>
            </Card.Body>
        </Card>
    );
};

export default RoamingInvoiceFormCardInfo;
