import dayjs from "dayjs";
import numeral from "numeral";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {Card, Col, Row, Table} from "react-bootstrap";
import SoftBadge from "../../../../../../common/SoftBadge";
import React, {Fragment} from 'react';
import Background from "../../../../../../common/Background";
import IconButton from "../../../../../../common/IconButton";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {GetPaymentTypeText} from "../../../../../../../enum/ContractorWrapper";
import corner4 from "../../../../../../../assets/img/icons/spot-illustrations/corner-4.png";
import {selectLang, selectNumberFormat, Translate} from "../../../../../../../app/store/reducers/main/mainReducer";
import classNames from "classnames";
import YandexMap from "../../../../../../common/map/YandexMap";
import DeleteTelegramOrderBotOrderButton from "../../DeleteTelegramOrderBotOrderButton";
import {faEye, faPlus} from "@fortawesome/free-solid-svg-icons";

const TelegramOrderBotOrder = ({order, itemsDiffs}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const numberFormat = useSelector(selectNumberFormat);

    return (
        <Row>
            <Col xs={12}>
                <Card className="mb-3 mt-2">
                    <Background
                        image={corner4}
                        className="bg-card"
                        style={{
                            borderTopRightRadius: '0.375rem',
                            borderBottomRightRadius: '0.375rem'
                        }}
                    />
                    <Card.Body className="position-relative">
                        <Row>
                            <Col md={6} lg={6} className="mb-4 mb-lg-0">
                                <h5 className="mb-0">{t(lang, "crm.telegram-orders.view.order.details")}:
                                    #{order.id}</h5>

                                <div className={'my-2'}>
                                    <strong className="me-2">{t(lang, "edi.common.status")}: </strong>
                                    <SoftBadge pill bg={order.is_approved ? 'success' : 'warning'} className="fs-0">
                                        {order.is_approved ? t(lang, 'common.status.approved') : t(lang, 'common.status.not_approved')}
                                        <FontAwesomeIcon
                                            icon={order.is_approved ? 'check' : 'stream'}
                                            className="ms-1"
                                            transform="shrink-2"
                                        />
                                    </SoftBadge>
                                </div>

                                <div className={'my-3'}>
                                    <p className="fs--1 mt-1 mb-0">Дата
                                        создания: {dayjs(order.created_at).format('YYYY-MM-DD HH:mm:ss')}</p>
                                    <p className="fs--1 mt-1 mb-0">{t(lang, "crm.common.order.date")}: {dayjs(order.date).format('YYYY-MM-DD')}</p>
                                    {order.is_approved &&
                                        <p className="fs--1 mt-1">{t(lang, "crm.data.table.approved_at")}: {dayjs(order.approved_at).format('YYYY-MM-DD HH:mm:ss')}</p>}
                                </div>

                                {order.location &&
                                    <YandexMap readOnly={true}
                                               defaultLocation={order.location}
                                               mapProps={{
                                                   width: '100',
                                                   height: '200px'
                                               }}
                                    />
                                }
                                <div>
                                    <p className="me-2">{t(lang, "edi.orders.add_order.order_details.title")}: {order.comment}</p>
                                </div>
                            </Col>
                            <Col md={6} lg={6} className="mb-4 mb-lg-0">
                                <div className={'d-flex mb-3'}>
                                    <h5 className="mb-2 fs-0 me-3">{t(lang, "crm.common.datatable.contractor")}:</h5>
                                    <div>
                                        {order.contractor &&
                                            <h6><Link to={`/crm/contractor-card/${order.contractor.id}`}
                                                      className="fs-0">{order.contractor.name}</Link></h6>}
                                        <h6 className="mb-2">Ф.И.О: {order?.user?.first_name} {order?.user?.last_name}</h6>
                                        <p className="mb-0 fs--1">
                                            <strong>
                                                Тел: <a>{order?.user?.phone_number}</a>
                                            </strong>
                                        </p>
                                    </div>
                                </div>
                                <div className={'d-flex mb-3'}>
                                    <h5 className="mb-2 fs-0 me-3">{t(lang, "crm.telegram-orders.view.order.payment.type")}:</h5>
                                    <h5 className={'fs-0'}>{t(lang, GetPaymentTypeText(order?.payment_type))}</h5>
                                </div>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
            <Col xs={12}>
                <Card className={'my-3'}>
                    <Card.Body>
                        <div
                            className="d-flex flex-column justify-content-center flex-md-row justify-content-md-end gap-2">
                            {(!order.is_sale_created && !order.sale_info) ?
                                <>
                                    <div className="d-grid d-md-inline-block">
                                        <DeleteTelegramOrderBotOrderButton order={order}/>
                                    </div>
                                    <div className="d-grid d-md-inline-block">
                                        <IconButton as={Link}
                                                    icon={faPlus}
                                                    variant="falcon-success"
                                                    to={`/warehouse/operation/order-sale/register/${order.id}`}>
                                            {t(lang, 'crm.telegram.order.view.create_sale_button')}
                                        </IconButton>
                                    </div>
                                </>
                                :
                                <div className="d-grid d-md-inline-block">
                                    <IconButton as={Link}
                                                icon={faEye}
                                                variant="falcon-info"
                                                to={`/warehouse/operation/sales/view/${order.sale_info.id}`}
                                    >
                                        {t(lang, 'crm.telegram.order.view.view_order_sale')}
                                    </IconButton>
                                </div>
                            }
                        </div>
                    </Card.Body>
                </Card>
            </Col>
            {order.is_sale_created &&
                <Col xs={12}>
                    <Card>
                        <Card.Header className={'bg-light border-bottom'}>
                            <Row>
                                <Col>
                                    <div>
                                <span className={'me-3'}>
                                    <div className={'dot bg-success d-inline-block'}/>
                                    <span
                                        className={''}>{t(lang, 'crm.telegram.order.view.compare.status.added')}</span>
                                </span>
                                        <span className={'me-3'}>
                                    <div className={'dot bg-warning d-inline-block'}/>
                                    <span
                                        className={''}>{t(lang, 'crm.telegram.order.view.compare.status.changed')}</span>
                                </span>
                                        <span className={'me-3'}>
                                    <div className={'dot bg-danger d-inline-block'}/>
                                    <span
                                        className={''}>{t(lang, 'crm.telegram.order.view.compare.status.deleted')}</span>
                                </span>
                                    </div>
                                </Col>
                                <Col>
                                    <Card.Title
                                        className={'d-block text-center fs-2'}>{t(lang, 'crm.common.products')}</Card.Title>
                                </Col>
                                <Col/>
                            </Row>
                        </Card.Header>
                        <Card.Body className={'p-0'}>
                            <Table responsive={true} size={'md'} className={'fs--1'}>
                                <thead>
                                <tr className={'text-900 fs-0'}>
                                    <th className={'px-2 text-center'}>#</th>
                                    <th className={'px-1'}>{t(lang, "crm.common.product")}</th>
                                    <th className={'px-1 text-end'}>{t(lang, "crm.common.quantity")}</th>
                                    <th className={'px-1 text-end'}>{t(lang, "crm.common.price")}</th>
                                    <th className={'px-2 text-end'}>{t(lang, "crm.common.total")}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {itemsDiffs.map((item, index) => (
                                    <tr key={index} className={classNames({
                                        'bg-success text-white': item.is_position_added,
                                        'bg-danger text-white': item.is_position_deleted,
                                        'bg-warning text-white': item.is_position_price_changed || item.is_position_quantity_changed,
                                    })}>
                                        <td className={'px-2 text-center'}>{index + 1}</td>
                                        <td className={'px-1 fw-semi-bold'}>{item.order_item.name ? item.order_item.name : item.sale_item.name}</td>
                                        <td className={'px-1 fw-semi-bold text-end'}>
                                            {item.is_position_quantity_changed ? (
                                                <Fragment>
                                                    {numeral['formats'][numberFormat].format(item.order_item.quantity)}
                                                    <span className={'mx-1 fs-1'}>→</span>
                                                    {numeral['formats'][numberFormat].format(item.sale_item.quantity)}
                                                </Fragment>
                                            ) : (
                                                <Fragment>{numeral['formats'][numberFormat].format(item.order_item.quantity || item.sale_item.quantity)}</Fragment>
                                            )}
                                        </td>
                                        <td className={'px-1 fw-semi-bold text-end'}>
                                            {item.is_position_price_changed ? (
                                                <Fragment>
                                                    {numeral['formats'][numberFormat].format(item.order_item.price.amount)}&nbsp;{item.order_item.price.currency.name}
                                                    <span className={'mx-1 fs-1'}>→</span>
                                                    {numeral['formats'][numberFormat].format(item.sale_item.price.amount)}&nbsp;{item.sale_item.price.currency.name}
                                                </Fragment>
                                            ) : (
                                                <Fragment>
                                                    {numeral['formats'][numberFormat].format(item.order_item.price.amount || item.sale_item.price.amount)}
                                                    &nbsp;
                                                    {item.order_item.price.currency.name || item.sale_item.price.currency.name}
                                                </Fragment>
                                            )}
                                        </td>
                                        <td className={'px-2 fw-semi-bold text-end'}>
                                            {item.is_position_price_changed || item.is_position_quantity_changed ? (
                                                <Fragment>
                                                    {numeral['formats'][numberFormat].format(item.order_item.net_price.amount)}&nbsp;{item.order_item.net_price.currency.name}
                                                    <span className={'mx-1 fs-1'}>→</span>
                                                    {numeral['formats'][numberFormat].format(item.sale_item.net_price.amount)}&nbsp;{item.sale_item.net_price.currency.name}
                                                </Fragment>
                                            ) : (
                                                <Fragment>
                                                    {numeral['formats'][numberFormat].format(item.order_item.net_price.amount || item.sale_item.net_price.amount)}
                                                    &nbsp;
                                                    {item.order_item.price.currency.name || item.sale_item.net_price.currency.name}
                                                </Fragment>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
            }
            {!order.is_sale_created &&
                <Col xs={12}>
                    <Card className="mb-3">
                        <Card.Body>
                            <div className="table-responsive fs--1">
                                <Table striped className="border-bottom">
                                    <thead className="bg-200 text-900">
                                    <tr>
                                        <th className="border-0">№</th>
                                        <th className="border-0">{t(lang, "crm.common.product")}</th>
                                        <th className="border-0 text-center">{t(lang, "crm.common.quantity")}</th>
                                        <th className="border-0 text-end">{t(lang, "crm.common.price")}</th>
                                        <th className="border-0 text-end">{t(lang, "crm.common.total")}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {order.items.filter(x => !x.is_deleted).map((item, index) => (
                                        <tr key={index} className="border-200">
                                            <td>{index + 1}</td>
                                            <td className="align-middle">
                                                <h6 className="mb-0 text-nowrap">{item.item.name}</h6>
                                            </td>
                                            <td className="align-middle text-center">{numeral['formats'][numberFormat].format(item.quantity)}</td>
                                            <td className="align-middle text-end">{numeral['formats'][numberFormat].format(item.price.amount)} {item.price.currency.name}</td>
                                            <td className="align-middle text-end">{numeral['formats'][numberFormat].format(item.net_price.amount)} {item.net_price.currency.name}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>

                                <Row className="g-0 justify-content-end">
                                    <Col xs="auto">
                                        <Table borderless size="sm" className="fs--1 text-end">
                                            <tbody>
                                            <tr className={"border-bottom"}>
                                                <th className="text-900">{t(lang, 'crm.common.datatable.total_amount')}:</th>
                                                <td className="fw-semi-bold">
                                                    {order?.net_price.map((np, index) => {
                                                        return (
                                                            <div key={index}>
                                                                {numeral['formats'][numberFormat].format(np.amount)} {np.currency.name}
                                                            </div>
                                                        )
                                                    })}
                                                </td>
                                            </tr>
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            }
        </Row>
    );
};

export default TelegramOrderBotOrder;