import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {selectLang, selectNumberFormat, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {debounce} from "underscore";
import referenceDataAggregatorService
    from "../../../../services/referenceDataAggregator/referenceDataAggregatorService";
import {getCoreRowModel, getPaginationRowModel, useReactTable} from "@tanstack/react-table";
import {Badge, Card, OverlayTrigger, Popover} from "react-bootstrap";
import DataTable from "../../../common/table/DataTable";
import DataTablePagination from "../../../common/table/DataTablePagination";
import DataTableLimiter from "../../../common/table/DataTableLimiter";
import {
    ALERT_ON_STATE_0,
    ALERT_ON_STATE_1,
    ALERT_ON_STATE_2,
    ALERT_ON_STATE_3,
    ALERT_ON_STATE_4
} from "../../item/enum/itemWrapper";
import {transliterate} from "transliteration";
import naturalCompare from "natural-compare";
import classNames from "classnames";
import SoftBadge from "../../../common/SoftBadge";
import numeral from "numeral";
import {getNumberFixedValue, getNumberValue} from "../../../../helpers/utils";
import Flex from "../../../common/Flex";
import OwnerOrganizationsDataTableHeader from "./OwnerOrganizationsDataTableHeader";
import DataTableColumnSettings from "../../../common/table/DataTableColumnSettings";


const roundingValue = 2;
const OwnerOrganizationItemsDataTable = ({
                                             organizationBindingId,
                                             isViewItemPricesEnabled
                                         }) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const numberFormat = useSelector(selectNumberFormat);
    const [filters, setFilters] = useState({
        searchTerm: '',
        categoryId: null,
        alertOn: ALERT_ON_STATE_0
    });
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10
    });
    const [sorting, setSorting] = useState({id: "name", desc: true});
    const [columnVisibility, setColumnVisibility] = useState({
        "№": true,
        "name": true,
        "totalWarehouseState": true,
        "action": true,
    });
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);
    const [categories, setCategories] = useState([]);

    const onFilterChange = (filter) => {
        const filterParams = {...filter};
        const paginationParams = {...pagination, pageIndex: 0};
        setFilters(filterParams);
        setPagination(paginationParams);
    };
    const onDebouncedFilterChange = useCallback(debounce((filter) => {
        const filterParams = {...filter};
        const paginationParams = {...pagination, pageIndex: 0};
        setFilters(filterParams);
        setPagination(paginationParams);
    }, 500), [filters, pagination]);
    const onSortingChange = (sorting) => {
        setSorting(sorting);
    };
    const onColumnVisibilityChange = (updater) => {
        setColumnVisibility(updater);
    };
    const onPaginationChange = (updater) => {
        setPagination(updater);
    };

    const load = async (filter, pagination) => {
        try {
            setLoading(true);
            const {data: rdaResponse} = await referenceDataAggregatorService.getOrganizationBindingItems(organizationBindingId);
            setItems(rdaResponse.data);
            setCategories(rdaResponse.categories);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setItems([]);
            setCategories([]);
            setLoading(false);
        }
    };

    const columns = useMemo(() => {
        const dataTableColumns = [
            {
                id: '#',
                accessorKey: '№',
                enableSorting: false,
                enableHiding: false,
                header: ({column}) => {
                    return (
                        <th className="p-1 align-middle text-center text-dark fs--1"
                            style={{
                                width: "40px",
                                minWidth: "40px"
                            }}
                        >
                            №
                        </th>
                    );
                },
                cell: ({row}) => {
                    return (
                        <td className="p-1 align-middle text-center text-dark"
                            style={{
                                width: "40px",
                                minWidth: "40px"
                            }}
                        >
                            {row.index + 1}
                        </td>
                    );
                },
                footer: ({table}) => {
                    return (
                        <td></td>
                    )
                },
            },
            {
                id: 'name',
                accessorKey: t(lang, "edi.common.product.name"),
                enableHiding: false,
                header: ({column}) => {
                    return (
                        <th className="p-1 align-middle text-dark fs--1"
                            onClick={column.getToggleSortingHandler()}
                        >
                            {t(lang, "items.common.name")}
                        </th>
                    )
                },
                cell: ({row: {original: {item}}}) => {
                    return (
                        <td className="p-1 align-middle">
                            <h5 className="fs--1 fw-semi-bold font-sans-serif text-black text-800 text-truncate"
                                style={{maxWidth: '200px'}}>{item.name}</h5>
                            {item.category &&
                                <OverlayTrigger trigger={['hover', 'focus']}
                                                placement={'bottom'}
                                                overlay={
                                                    <Popover id="popover-basic" className='mt-0'>
                                                        <Popover.Header>
                                                            <SoftBadge
                                                                bg={'info'}>{t(lang, 'items.common.category')}</SoftBadge>
                                                        </Popover.Header>
                                                    </Popover>
                                                }
                                >
                                    <SoftBadge bg='info' className='me-1'>{item.category.name}</SoftBadge>
                                </OverlayTrigger>
                            }
                            {item.barcodes?.length > 0 && item.barcodes.map((barcode, index) =>
                                <OverlayTrigger key={index}
                                                trigger={['hover', 'focus']}
                                                placement={'bottom'}
                                                overlay={
                                                    <Popover id="popover-basic" className='mt-0'>
                                                        <Popover.Header>
                                                            <SoftBadge
                                                                bg={'secondary'}>{t(lang, 'items.common.barcode')}</SoftBadge>
                                                        </Popover.Header>
                                                    </Popover>
                                                }
                                >
                                    <SoftBadge bg='secondary' className='me-1'>{barcode}</SoftBadge>
                                </OverlayTrigger>
                            )}
                            {item.sku &&
                                <OverlayTrigger trigger={['hover', 'focus']}
                                                placement={'bottom'}
                                                overlay={
                                                    <Popover id="popover-basic" className='mt-0'>
                                                        <Popover.Header>
                                                            <SoftBadge
                                                                bg={'dark'}>{t(lang, 'items.common.sku')}</SoftBadge>
                                                        </Popover.Header>
                                                    </Popover>
                                                }
                                >
                                    <SoftBadge bg='dark' className='me-1'>{item.sku}</SoftBadge>
                                </OverlayTrigger>
                            }
                        </td>
                    )
                },
                footer: ({table}) => {
                    return (
                        <td></td>
                    )
                }
            },
            {
                id: 'totalWarehouseState',
                accessorKey: t(lang, "items.common.total_item_warehouse_state_amount"),
                header: ({column}) => {
                    return (
                        <th className="p-1 text-center align-middle text-dark fs--1">
                            {t(lang, 'items.common.total_item_warehouse_state_amount')}
                        </th>
                    )
                },
                cell: ({row: {original: {warehouse_states: {warehouse_items}}}}) => {
                    return (
                        <td className="p-1 text-center align-middle text-dark fw-bolder">
                            <OverlayTrigger trigger={['hover', 'focus']} placement="bottom" overlay={
                                <Popover id="popover-basic">
                                    <Popover.Header className="py-1">
                                        <div className="d-flex flex-column">
                                            {warehouse_items.map((warehouseItem, index) => {
                                                const colors = ['primary', 'info', 'success', 'dark', 'secondary'];
                                                const color = colors[index % colors.length];

                                                return (
                                                    <SoftBadge key={warehouseItem.id}
                                                               bg={color}
                                                               className='mb-1 flex-fill'
                                                    >
                                                        <span className="me-1">{warehouseItem.name}:</span>
                                                        <span className={classNames({
                                                            "text-danger": warehouseItem.state < 0,
                                                            "text-secondary": warehouseItem.state === 0,
                                                            "text-primary": warehouseItem.state > 0
                                                        })}>
                                                                {warehouseItem.state}
                                                            </span>
                                                    </SoftBadge>
                                                )
                                            })}
                                        </div>
                                    </Popover.Header>
                                </Popover>
                            }>
                                <div className="fw-bolder text-dark">
                                    {warehouse_items.reduce((sum, warehouseItem) => warehouseItem.state + sum, 0)}
                                </div>
                            </OverlayTrigger>
                        </td>
                    )
                },
                footer: ({table}) => {
                    const rows = table.getSelectedRowModel().rows.length ? table.getSelectedRowModel().rows : table.getRowModel().rows;
                    const warehouseStates = rows.reduce((acc, {original: {warehouse_states: {warehouse_items}}}) => {
                        warehouse_items.forEach((warehouseItem) => {
                            if (acc[warehouseItem.id])
                                acc[warehouseItem.id] = acc[warehouseItem.id] + (warehouseItem.state > 0 ? warehouseItem.state : 0)
                            else
                                acc[warehouseItem.id] = warehouseItem.state > 0 ? warehouseItem.state : 0
                        })
                        return acc;
                    }, {});

                    return (
                        <td className="p-1 align-middle text-center text-dark">
                            <OverlayTrigger
                                placement={'bottom'}
                                trigger={['hover', 'focus']}
                                overlay={
                                    <Popover id="popover-basic" className='ms-0'>
                                        <Popover.Header className="py-1">
                                            <div className="d-flex flex-column">
                                                {Object.keys(warehouseStates).map((warehouseId, index) => {
                                                    const colors = ['primary', 'info', 'success', 'dark', 'secondary'];
                                                    const color = colors[index % colors.length];
                                                    let warehouse = null;
                                                    rows.forEach(({original: {warehouse_states: {warehouse_items}}}) => {
                                                        const foundWarehouse = warehouse_items.find(w => w.id === parseInt(warehouseId));
                                                        if (foundWarehouse)
                                                            warehouse = foundWarehouse;
                                                    });

                                                    return (
                                                        <SoftBadge key={warehouseId}
                                                                   bg={color}
                                                                   className='mb-1 flex-fill'
                                                        >
                                                            <span className="me-1">{warehouse.name}:</span>
                                                            <span className={classNames({
                                                                "text-danger": warehouseStates[warehouseId] < 0,
                                                                "text-secondary": warehouseStates[warehouseId] === 0,
                                                                "text-primary": warehouseStates[warehouseId] > 0
                                                            })}>
                                                                {numeral['formats'][numberFormat].format(getNumberValue(getNumberFixedValue(warehouseStates[warehouseId], roundingValue)))}
                                                            </span>
                                                        </SoftBadge>
                                                    )
                                                })}
                                            </div>
                                        </Popover.Header>
                                    </Popover>
                                }
                            >
                                <div className="fw-bolder text-dark">
                                    {numeral['formats'][numberFormat].format(getNumberValue(getNumberFixedValue(Object.values(warehouseStates).reduce((acc, value) => acc + value, 0), roundingValue)))}
                                </div>
                            </OverlayTrigger>
                        </td>
                    )
                }
            },
            {
                id: 'action',
                accessorKey: t(lang, "notification.table.actions"),
                enableHiding: false,
                header: ({table}) => {
                    return (
                        <th className="p-1 align-middle text-center text-dark fs--1">
                            <DataTableColumnSettings table={table}/>
                        </th>
                    )
                },
                cell: ({row: {original: {item, price}}}) => {
                    return (
                        <td className="p-1 align-middle text-center">

                        </td>
                    )
                },
                footer: () => {
                    return (
                        <td className="p-1 align-middle text-center text-dark"></td>
                    )
                }
            },
        ];

        if (isViewItemPricesEnabled) {
            dataTableColumns.splice(2, 0, {
                id: 'purchasePrice',
                accessorKey: t(lang, "items.common.purchase_price"),
                enableSorting: false,
                enableHiding: true,
                header: ({column}) => {
                    return (
                        <th className="p-1 align-middle text-center text-dark fs--1">
                            {t(lang, 'items.common.purchase_price')}
                        </th>
                    )
                },
                cell: ({row: {original: {warehouse_states: {warehouse_items}}}}) => {
                    const colors = ['primary', 'info', 'success', 'dark', 'secondary']
                    return (
                        <td className="p-1 align-middle text-center text-dark">
                            <div>
                                {warehouse_items.length && warehouse_items.map((warehouseItem, index) => {
                                    const color = colors[index % colors.length]
                                    return <div className={"fs-0"} key={warehouseItem?.id}>
                                        {warehouseItem?.purchase_price && <OverlayTrigger trigger={['hover', 'focus']}
                                                                                          placement={'bottom'}
                                                                                          overlay={
                                                                                              <Popover
                                                                                                  id="popover-basic"
                                                                                                  className='mt-0'>
                                                                                                  <Popover.Header>
                                                                                                      <SoftBadge
                                                                                                          bg={color}>
                                                                                                          {warehouseItem?.name}
                                                                                                      </SoftBadge>
                                                                                                  </Popover.Header>
                                                                                              </Popover>
                                                                                          }
                                        >
                                            <SoftBadge className={'d-block text-end my-1'} bg={color}>
                                                {warehouseItem?.purchase_price?.price?.amount} {warehouseItem?.purchase_price?.price?.currency?.name}
                                            </SoftBadge>
                                        </OverlayTrigger>}
                                    </div>
                                })}
                            </div>
                        </td>
                    )
                },
                footer: ({table}) => {
                    const colors = ['primary', 'info', 'success', 'dark', 'secondary'];
                    const rows = table.getSelectedRowModel().rows.length ? table.getSelectedRowModel().rows : table.getRowModel().rows;
                    const purchasePrice = rows.reduce((acc, {original: {warehouse_states: {warehouse_items}}}) => {
                        warehouse_items.forEach(item => {
                            if (item.state > 0) {
                                if (item.purchase_price) {
                                    const {amount, currency: {name}} = item.purchase_price.price;
                                    if (!acc[item.id]) acc[item.id] = {};
                                    if (!acc[item.id][name]) acc[item.id][name] = 0;
                                    acc[item.id][name] += amount * item.state;
                                }
                            } else {
                                if (item.purchase_price) {
                                    const {currency: {name}} = item.purchase_price.price;
                                    if (!acc[item.id]) acc[item.id] = {};
                                    if (!acc[item.id][name]) acc[item.id][name] = 0;
                                    acc[item.id][name] += 0;
                                }
                            }
                        });
                        return acc;
                    }, {});

                    return (
                        <td className="p-1 align-middle text-center">
                            {Object.keys(purchasePrice).map((warehouseId, index) => {
                                let warehouse = null;
                                rows.forEach(({original: {warehouse_states: {warehouse_items}}}) => {
                                    const foundWarehouse = warehouse_items.find(w => w.id === parseInt(warehouseId));
                                    if (foundWarehouse)
                                        warehouse = foundWarehouse;
                                });
                                const color = colors[index % colors.length];

                                if (!warehouse) return null;

                                return (
                                    <OverlayTrigger key={warehouseId}
                                                    placement={'bottom'}
                                                    trigger={['hover', 'focus']}
                                                    overlay={
                                                        <Popover id="popover-basic" className='mt-0'>
                                                            <Popover.Header>
                                                                <SoftBadge className={'d-block text-center fs--1'}
                                                                           bg={color}>
                                                                    {warehouse.name}
                                                                </SoftBadge>
                                                            </Popover.Header>
                                                        </Popover>
                                                    }
                                    >
                                        <SoftBadge className={'d-block text-end my-1'} bg={color}>
                                            {Object.entries(purchasePrice[warehouseId]).map(([currency, amount]) => (
                                                <div key={currency}>
                                                    {`${numeral['formats'][numberFormat].format(getNumberValue(getNumberFixedValue(amount, roundingValue)))} ${currency}`}
                                                </div>
                                            ))}
                                        </SoftBadge>
                                    </OverlayTrigger>
                                )
                            })}
                        </td>
                    )
                }
            });
            dataTableColumns.splice(3, 0, {
                id: 'salePrice',
                accessorKey: t(lang, "items.common.price"),
                enableSorting: false,
                enableHiding: true,
                header: ({column}) => {
                    return (
                        <th className="p-1 align-middle text-center text-dark fs--1">
                            {t(lang, "items.common.price")}
                        </th>
                    )
                },
                cell: ({row: {original: {price}}}) => {
                    return (
                        <td className="p-1 align-middle text-center">
                            <div className={'fs-0'}>
                                <OverlayTrigger trigger={['hover', 'focus']}
                                                placement={'bottom'}
                                                overlay={
                                                    <Popover id="popover-basic" className='mt-0'>
                                                        <Popover.Header>
                                                            <SoftBadge
                                                                bg={'primary'}>{t(lang, 'items.common.price')}</SoftBadge>
                                                        </Popover.Header>
                                                    </Popover>
                                                }
                                >
                                    <SoftBadge className={'d-block text-end'} bg={'primary'}>
                                        {`${numeral.formats[numberFormat].format(price.common_price.amount)} ${price.common_price.currency.name}`}
                                    </SoftBadge>
                                </OverlayTrigger>
                                {price.bulk_price.amount > 0 &&
                                    <OverlayTrigger trigger={['hover', 'focus']}
                                                    placement={'bottom'}
                                                    overlay={
                                                        <Popover id="popover-basic" className='mt-0'>
                                                            <Popover.Header>
                                                                <SoftBadge
                                                                    bg={'secondary'}>{t(lang, 'items.common.bulk_price')}</SoftBadge>
                                                            </Popover.Header>
                                                        </Popover>
                                                    }
                                    >
                                        <SoftBadge className={'d-block d-block text-end mt-1'} bg={'secondary'}>
                                            {`${numeral.formats[numberFormat].format(price.bulk_price.amount)} ${price.bulk_price.currency.name}`}
                                        </SoftBadge>
                                    </OverlayTrigger>
                                }
                            </div>
                        </td>
                    )
                },
                footer: ({table}) => {
                    const rows = table.getSelectedRowModel().rows.length ? table.getSelectedRowModel().rows : table.getRowModel().rows;
                    const commonPrice = rows.reduce((acc, {original}) => {
                        const itemTotalWarehouseState = original.warehouse_states.warehouse_items.reduce((acc, itemWarehouse) => acc + itemWarehouse.state, 0);
                        const priceAmount = itemTotalWarehouseState > 0 ? original.price.common_price.amount * itemTotalWarehouseState : 0;
                        acc[original.price.common_price.currency.name] = (acc[original.price.common_price.currency.name] || 0) + priceAmount;
                        return acc;
                    }, {});
                    const bulkPrice = rows.reduce((acc, {original}) => {
                        const itemTotalWarehouseState = original.warehouse_states.warehouse_items.reduce((acc, itemWarehouse) => acc + itemWarehouse.state, 0);
                        const priceAmount = itemTotalWarehouseState > 0 ? original.price.bulk_price.amount * itemTotalWarehouseState : 0;
                        acc[original.price.bulk_price.currency.name] = (acc[original.price.bulk_price.currency.name] || 0) + priceAmount;
                        return acc;
                    }, {});
                    return (
                        <td className="p-1 align-middle text-center">
                            <div className={'fs-0'}>
                                <OverlayTrigger trigger={['hover', 'focus']}
                                                placement={'bottom'}
                                                overlay={
                                                    <Popover id="popover-basic" className='mt-0'>
                                                        <Popover.Header>
                                                            <SoftBadge
                                                                bg={'primary'}>{t(lang, 'items.common.price')}</SoftBadge>
                                                        </Popover.Header>
                                                    </Popover>
                                                }
                                >
                                    <SoftBadge className={'d-block text-end'} bg={'primary'}>
                                        {Object.keys(commonPrice).map((key) => (
                                            <div
                                                key={key}>{`${numeral.formats[numberFormat].format(getNumberValue(getNumberFixedValue(commonPrice[key], roundingValue)))} ${key}`}</div>
                                        ))}
                                    </SoftBadge>
                                </OverlayTrigger>
                                <OverlayTrigger trigger={['hover', 'focus']}
                                                placement={'bottom'}
                                                overlay={
                                                    <Popover id="popover-basic" className='mt-0'>
                                                        <Popover.Header>
                                                            <SoftBadge
                                                                bg={'secondary'}>{t(lang, 'items.common.bulk_price')}</SoftBadge>
                                                        </Popover.Header>
                                                    </Popover>
                                                }
                                >
                                    <SoftBadge className={'d-block d-block text-end mt-1'} bg={'secondary'}>
                                        {Object.keys(bulkPrice).map((key) => (
                                            <div
                                                key={key}>{`${numeral.formats[numberFormat].format(getNumberValue(getNumberFixedValue(bulkPrice[key], roundingValue)))} ${key}`}</div>
                                        ))}
                                    </SoftBadge>
                                </OverlayTrigger>
                            </div>
                        </td>
                    )
                }
            });
        }

        if (items.every(i => i.warehouse_states.warehouse_items.length === 2)) {
            dataTableColumns.splice(isViewItemPricesEnabled ? 4 : 3, 0, {
                id: 'warehouseStates',
                accessorKey: t(lang, 'items.common.warehouses_state'),
                header: () => {
                    return (
                        <th className="p-1 text-center align-middle text-dark fs--1">
                            {t(lang, 'items.common.warehouses_state')}
                        </th>
                    )
                },
                cell: ({row: {original: {warehouse_states: {warehouse_items}}}}) => {
                    return (
                        <td className="p-1 align-middle text-center text-dark">
                            <Flex className={'fs-0'}>
                                {warehouse_items.map((warehouseItem, index) => {
                                    const colors = ['primary', 'info', 'success', 'dark'];
                                    const color = colors[index % colors.length];

                                    if (warehouseItem.alert_on > warehouseItem.state) {
                                        return (
                                            <Badge key={warehouseItem.id}
                                                   bg="dark"
                                                   className='mb-1 flex-fill me-1'
                                            >
                                                {warehouseItem.state}
                                                <br/>
                                                {warehouseItem.name}
                                            </Badge>
                                        )
                                    }

                                    return (
                                        <SoftBadge key={warehouseItem.id}
                                                   bg={color}
                                                   className='mb-1 flex-fill me-1'
                                        >
                                            {warehouseItem.state}
                                            <br/>
                                            {warehouseItem.name}
                                        </SoftBadge>
                                    )
                                })}
                            </Flex>
                        </td>
                    )
                },
                footer: () => {
                    return (
                        <td className="p-1 align-middle text-center text-dark">

                        </td>
                    )
                }
            })
        }

        return dataTableColumns;
    }, [lang, items, isViewItemPricesEnabled, filters]);
    const data = useMemo(() => {
        const filteredItems = items.filter((item) => {
            // search by filter.categoryId
            {
                if (filters?.categoryId && filters.categoryId !== item.item.category?.id)
                    return false;
            }

            // search by filter.searchTerm
            {
                if (filters.searchTerm) {
                    let found = false;
                    const searchTermLower = filters.searchTerm.trim().toLowerCase();

                    // search by barcode
                    {
                        if (item.item.barcodes.some((b) => b.toLowerCase().includes(searchTermLower)))
                            found = true;
                    }

                    // search by code
                    {
                        if (item.item.code && item.item.code.trim().toLowerCase().includes(searchTermLower))
                            found = true;
                    }

                    // search by name
                    {
                        const itemNameLower = item.item.name.trim().toLowerCase();
                        const transliteratedQuery = transliterate(searchTermLower);
                        const filterNameParts = transliteratedQuery.replaceAll('  ', ' ').split(' ');
                        const transliteratedItemName = transliterate(itemNameLower);
                        if (filterNameParts.length === filterNameParts.filter(fnp => transliteratedItemName.indexOf(fnp) > -1).length)
                            found = true;
                    }

                    if (!found)
                        return false;
                }
            }

            // search by filter.alertOn
            {
                const itemTotalWarehouseState = item.warehouse_states.warehouse_items.reduce((acc, accItem) => acc + accItem.state, 0);
                switch (filters.alertOn) {
                    case ALERT_ON_STATE_1:
                        return !(itemTotalWarehouseState <= 0);
                    case ALERT_ON_STATE_2:
                        return itemTotalWarehouseState < 0 || item.warehouse_states.warehouse_items.some((warehouseItem) => warehouseItem.alert_on !== null && warehouseItem.alert_on > warehouseItem.state);
                    case ALERT_ON_STATE_3:
                        return !(itemTotalWarehouseState !== 0);
                    case ALERT_ON_STATE_4:
                        return !(itemTotalWarehouseState >= 0);
                    default:
                        break;
                }
            }

            return true;
        });

        const sortedItems = filteredItems.sort((a, b) => {
            const {id, desc} = sorting;

            // sort by total_warehouse_state
            {
                if (id === "state") {
                    const aState = a.warehouse_states.warehouse_items.reduce((sum, warehouseItem) => warehouseItem.state + sum, 0);
                    const bState = b.warehouse_states.warehouse_items.reduce((sum, warehouseItem) => warehouseItem.state + sum, 0);
                    return desc ? naturalCompare(bState, aState) : naturalCompare(aState, bState);
                }
            }

            // sort by created_at
            {
                if (id === "createdAt") {
                    return desc ? naturalCompare(b.item.created_at, a.item.created_at) : naturalCompare(a.item.created_at, b.item.created_at);
                }
            }

            // sort by sku
            {
                if (id === "sku")
                    return desc ? naturalCompare(b.item.sku, a.item.sku) : naturalCompare(a.item.sku, b.item.sku);
            }

            // sort by name
            {
                if (id === "name")
                    return desc ? naturalCompare(b.item.name, a.item.name) : naturalCompare(a.item.name, b.item.name);
            }
        });

        return sortedItems;
    }, [items, sorting, filters]);
    const table = useReactTable({
        data: data,
        columns: columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getRowId: row => row.item.id,
        onPaginationChange: onPaginationChange,
        onSortingChange: onSortingChange,
        onColumnVisibilityChange: onColumnVisibilityChange,
        autoResetPageIndex: false,
        manualFiltering: true,
        manualSorting: true,
        enableSorting: true,
        enableSortingRemoval: true,
        pageCount: Math.ceil(data.length / pagination.pageSize),
        meta: {
            getHeaderRowClassNames: () => {
                return "bg-200";
            },
            getCellRowStyles: (row) => {
                const rdaItem = row.original;
                const alertRed = 'rgb(227 81 97 / 23%)';
                const alertYellow = 'rgb(252 219 113 / 37%)';
                const rowStyle = {};

                const itemTotalWarehouseState = rdaItem.warehouse_states.warehouse_items.reduce((acc, item) => acc + item.state, 0);
                if (itemTotalWarehouseState < 0) {
                    rowStyle.backgroundColor = alertRed;
                    return rowStyle;
                }

                if (rdaItem.warehouse_states.warehouse_items.some((warehouseItem) => warehouseItem.alert_on !== null && warehouseItem.alert_on > warehouseItem.state)) {
                    rowStyle.backgroundColor = alertYellow;
                    return rowStyle;
                }

                return rowStyle;
            },
        },
        state: {
            pagination: pagination,
            sorting: sorting,
            columnVisibility: columnVisibility
        }
    });

    useEffect(() => {
        load(filters, pagination)
    }, [organizationBindingId]);

    return (
        <Card>
            <Card.Header>
                <OwnerOrganizationsDataTableHeader table={table}
                                                   load={() => load(filters, pagination)}
                                                   loading={loading}
                                                   categories={categories}
                                                   filter={filters}
                                                   onFilterChange={onFilterChange}
                                                   onDebouncedFilterChange={onDebouncedFilterChange}
                                                   sorting={sorting}
                                                   onSortingChange={onSortingChange}
                                                   itemPriceAccessTypeEnabled={isViewItemPricesEnabled}
                />
            </Card.Header>
            <Card.Body>
                <DataTable table={table}
                           loading={loading}
                           columns={columns}
                           tableProps={{
                               responsive: true
                           }}
                />
            </Card.Body>
            <Card.Footer>
                <DataTablePagination table={table}
                                     pagination={pagination}
                                     count={data.length}
                />
                <DataTableLimiter table={table}
                                  pagination={pagination}
                                  limitOptions={[10, 25, 50, 75, 100]}
                />
            </Card.Footer>
        </Card>
    );
};

export default OwnerOrganizationItemsDataTable;