import IconButton from "../../../../common/IconButton";
import React, {useState} from "react";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {Modal} from "react-bootstrap";
import FalconCloseButton from "../../../../common/FalconCloseButton";
import ContractorLabelForm from "./ContractorLabelForm";
import {registerContractorLabelAsync} from "../../../../../app/store/reducers/contractor/contractorLabelReducer";

const AddContractorLabelForm = () => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleOnSubmit = (formData) => {
        setLoading(true)

        registerContractorLabelAsync({
            name: formData.name,
        }).then(res => {
            setLoading(false)
            setShow(false)
        }).catch(err => {
            console.log(err)
            setLoading(false)
        });
    }
    return (<>
        <IconButton
            variant="falcon-default"
            size={"sm"}
            icon="plus"
            onClick={() => setShow(true)}
            transform="shrink-3">
             <span className="d-none d-sm-inline-block ms-1">
                    {t(lang, "edi.common.add_button")}
                </span>
        </IconButton>
        <Modal show={show} onHide={() => {
            setShow(false)
        }} size="lg">
            <Modal.Header>
                <Modal.Title>{t(lang, "crm.contractor.label.add_form_title")}</Modal.Title>
                <FalconCloseButton onClick={() => {
                    setShow(false)
                }}/>
            </Modal.Header>
            <Modal.Body>
                <ContractorLabelForm onClose={() => {
                    setShow(false)
                }} loading={loading} onSubmit={handleOnSubmit}/>
            </Modal.Body>
        </Modal>
    </>)
}
export default AddContractorLabelForm