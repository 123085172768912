import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../../../app/store/reducers/auth/authReducer";
import {
    changeFilterOptionsAsync, getTransfersAsync, getTransfersCountAsync,
    selectTransferCount,
    selectTransferFilterOptions,
    selectTransfers, transferDownloadPdfAsync
} from "../../../../../app/store/reducers/warehouse-operation/transferReducer";
import SoftBadge from "../../../../common/SoftBadge";
import dayjs from "dayjs";
import {Link} from "react-router-dom";
import {Card, Dropdown} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useEffect} from "react";
import AdvanceTableWrapper from "../../../../common/advance-table/AdvanceTableWrapper";
import AdvanceTable from "../../../../common/advance-table/AdvanceTable";
import AdvanceTableCustomPagination from "../../../../common/advance-table/AdvanceTableCustomPagination";
import TransferDatatableHeader from "./TransferDatatableHeader";
import {isApprovedCheckedWarehouseOperation} from "../../enum/warehouseOperationWrapper";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import EventBus from "../../../../../app/eventbus/EventBus";
import {
    WAREHOUSE_OPERATION_TRANSFER_DELETE_SUCCESS
} from "../../../../../app/eventbus/warehouse-operation/transferEvents";
import TransferDelete from "../components/TransferDelete";
import CardDropdown from "../../../../common/CardDropdown";
import IconButton from "../../../../common/IconButton";
import {faFileExcel, faFilePdf, faPrint} from "@fortawesome/free-solid-svg-icons";
import printJS from "print-js";
import UploadTransferToExcel from "../excel/UploadTransferToExcel";


const TransferDatatable = () => {
    const activeOrganization = useSelector(selectActiveOrganization)
    const transfers = useSelector(selectTransfers)
    const dispatch = useDispatch()
    const filters = useSelector(selectTransferFilterOptions)
    const count = useSelector(selectTransferCount)
    const lang = useSelector(selectLang)
    const t = Translate


    const downloadPdfTransfer = ({id, number}) => {
        transferDownloadPdfAsync({transferId: id})
            .then((response) => {
                const link = document.createElement("a");
                document.body.appendChild(link);

                const blobFile = new Blob([response], {type: 'application/pdf'});
                const blobUrl = window.URL.createObjectURL(blobFile);

                link.href = blobUrl;
                link.download = `${t(lang, "warehouse.item.download_excel.transfer_name", {number: number})}.pdf`;
                link.click();
                link.remove();
                window.URL.revokeObjectURL(blobUrl);
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const onPrint = ({id}) => {
        transferDownloadPdfAsync({transferId: id})
            .then(response => {
                const blob = new Blob([response], {type: "application/pdf"});
                const blobUrl = window.URL.createObjectURL(blob);
                printJS(blobUrl);
            })
            .catch(error => {
                console.log(error)
            })
    }

    const columns = [
        {
            Header: "#",
            id: "row",
            filterable: false,
            cellProps: {
                width: '40px',
            },
            Cell: ({row}) => {
                return (filters.page - 1) * filters.limit + row.index + 1;
            }
        },
        {
            accessor: 'number',
            Header: t(lang, "Номер"),
            Cell: ({row: {original}}) => {
                return (
                    <Link to={`./transfers/view/${original?.id}`} className="d-flex text-wrap">
                        {original?.number}
                    </Link>
                )
            }
        },
        {
            id: 'warehouse_from.id',
            Header: t(lang, "warehouse.operation.item.common.warehouse_from"),
            Cell: ({row: {original}}) => {
                return (
                    <>
                        {original?.items[0]?.warehouse_item_from?.warehouse?.name}
                    </>
                )
            }
        },
        {
            id: 'warehouse_to.id',
            Header: t(lang, "warehouse.operation.item.common.warehouse_to"),
            Cell: ({row: {original}}) => {
                return (
                    <>
                        {original?.items[0]?.warehouse_item_to?.warehouse?.name}
                    </>
                )
            }
        },
        {
            id: 'name',
            Header: t(lang, "warehouse.operation.item.common.sale_status"),
            Cell: ({row: {original}}) => {
                return (
                    <>
                        <SoftBadge bg={original?.is_approved ? 'success' : 'warning'}>
                            {t(lang, isApprovedCheckedWarehouseOperation(original?.is_approved))}
                        </SoftBadge>
                    </>
                )
            }
        },
        {
            accessor: 'account.username',
            Header: t(lang, "Пользователь"),
            headerProps: {
                className: "text-center"
            },
            cellProps: {
                className: "text-center"
            },
            Cell: ({row: {original}}) => {
                return (
                    <div className="d-flex justify-content-center">
                        <span className="d-flex text-wrap">{original?.account?.name || original?.account?.username}</span>
                    </div>
                )
            }
        },
        {
            accessor: 'note',
            Header: t(lang, "Информация"),
            headerProps: {
                className: "text-start"
            },
            cellProps: {
                className: "text-end"
            },
            Cell: ({row: {original}}) => {
                return (
                    <div style={{maxWidth: "250px", minWidth: "250px"}}>
                        <span className="d-flex text-wrap">{original?.note}</span>
                    </div>
                )
            }
        },
        {
            accessor: 'created_at',
            Header: t(lang, "warehouse.operation.item.common.time"),
            headerProps: {
                className: "text-end"
            },
            cellProps: {
                className: "text-end"
            },
            Cell: ({row: {original}}) => {
                return (
                    dayjs(original?.date).format("YYYY-MM-DD HH:mm:ss")
                )
            }
        },
        {
            Header: '',
            id: "none",
            cellProps: {
                className: 'text-end'
            },
            Cell: ({row: {original}}) => (
                <div className="d-inline-flex">
                    <Dropdown>
                        <Dropdown.Toggle className="p-0 px-1" variant="outline-primary">
                            <FontAwesomeIcon icon={faFilePdf}/>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="p-0">
                            <Dropdown.Item onClick={() => downloadPdfTransfer({id: original?.id, number: original?.number})} className="text-primary">
                                <FontAwesomeIcon className="me-1" icon={faFilePdf}/>
                                <span>{t(lang, "warehouse.operation.item.common.download_pdf")}</span>
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => onPrint({id: original?.id})} className="text-info">
                                <FontAwesomeIcon className="me-1" icon={faPrint}/>
                                <span>{t(lang, "warehouse.operation.item.common.print")}</span>
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <Link to={`./transfers/view/${original?.id}`}>
                        <IconButton
                            variant="falcon-info"
                            icon="eye"
                            title={t(lang, "warehouse.operation.item.common.show")}
                            className="mx-2"
                            size="sm"
                        />
                    </Link>
                    <UploadTransferToExcel className={'mx-1'} id={original?.id} number={original?.number}>
                        <FontAwesomeIcon className={'text-success'} icon={faFileExcel}/>
                    </UploadTransferToExcel>
                    <CardDropdown btnRevealClass="btn-reveal-sm">
                        <div className="py-2">
                            <Dropdown.Item as={Link} to={`./transfers/update/${original?.id}`}
                                           className="text-warning">
                                <FontAwesomeIcon className="me-1" size="sm" icon={"pencil"}/>
                                <span>{t(lang, "warehouse.operation.item.common.edit")}</span>
                            </Dropdown.Item>
                            <Dropdown.Item as={Link} to={`./transfers/update-excel/${original?.id}`}
                                           className="text-success">
                                <FontAwesomeIcon className="me-1" size="sm" icon={"pencil"}/>
                                <span>{t(lang, "Редактировать с Excel")}</span>
                            </Dropdown.Item>
                            <TransferDelete id={original?.id}/>
                            <Dropdown.Item as={Link} to={`./transfers/view/${original?.id}`} className="text-info">
                                <FontAwesomeIcon className="me-1" size="sm" icon={"eye"}/>
                                <span>{t(lang, "warehouse.operation.item.common.show")}</span>
                            </Dropdown.Item>
                        </div>
                    </CardDropdown>
                </div>
            )
        }
    ]


    const onPageChange = (page) => dispatch(changeFilterOptionsAsync({...filters, page: page}))

    const onLimitChange = (limit) => dispatch(changeFilterOptionsAsync({...filters, limit: limit, page: 1}))


    const loadTransfers = () => {
        dispatch(getTransfersAsync({...filters}))
        dispatch(getTransfersCountAsync({...filters}))
    }


    useEffect(() => {
        loadTransfers()

        const onTransferDeletedHandler = EventBus.on(WAREHOUSE_OPERATION_TRANSFER_DELETE_SUCCESS, () => {
            loadTransfers()
        })

        return () => {
            EventBus.remove(WAREHOUSE_OPERATION_TRANSFER_DELETE_SUCCESS, onTransferDeletedHandler)
        }
    }, [activeOrganization, filters])


    return (
        <>
            <AdvanceTableWrapper
                columns={columns}
                data={transfers}
                pagination
                sortable
                perPage={1000}
                // selection
            >
                <Card className="mb-3">
                    <Card.Header>
                        <TransferDatatableHeader table/>
                    </Card.Header>
                    <Card.Body className="p-0">
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-wrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden',
                                style: {
                                    minHeight: '250px'
                                }
                            }}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTableCustomPagination
                            count={count}
                            limit={filters.limit}
                            page={filters.page}
                            onLimitChange={onLimitChange}
                            onPageChange={onPageChange}
                        />
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>
        </>
    )
}


export default TransferDatatable;