import {Card, Col, Form, Row} from "react-bootstrap";
import React from "react";
import {Controller, useFormContext} from "react-hook-form";
import FindCompany from "../../organization/FindCompany";
import classNames from "classnames";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import FindCompanySearchable from "../../organization/FindCompanySearchable";


const ActFormOwnerBuyer = () => {
    const {register, formState: {errors}, control, setValue} = useFormContext();
    const lang = useSelector(selectLang);
    const t = Translate;


    const onChangeCompanyBuyer = company => {
        setValue('ActBuyer', {
            inn: company?.inn || '',
            name: company?.name || '',
            pinfl: company?.person_num || company?.inn,
        }, {shouldValidate: true});
    };


    return (
        <>
            <Row>
                <Col xs={12} lg={6} className="my-3 mt-lg-0">
                    <Card>
                        <Card.Header>
                            <h4 className="border-bottom">{t(lang, "roaming.act_form_seller_title")}</h4>
                        </Card.Header>
                        <Card.Body className="bg-light">
                            <Row>
                                <Col xs={12}>
                                    <Form.Group>
                                        <Form.Label>{t(lang, "roaming.act_form_seller_inn")}</Form.Label>
                                        <Form.Control
                                            type='number'
                                            disabled={true}
                                            placeholder={t(lang, "roaming.act_form_seller_inn")}
                                            className='input-spin-none'
                                            {...register('ActSeller.inn', {
                                                pattern: /^[0-9]+$/,
                                                required: t(lang, "edi.common.forms.validations.is_required")
                                            })}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12}>
                                    <Form.Group>
                                        <Form.Label>Инн | пинфл</Form.Label>
                                        <Form.Control
                                            type='number'
                                            disabled={true}
                                            placeholder={t(lang, "roaming.act_form_seller_inn")}
                                            className='input-spin-none'
                                            {...register('ActSeller.pinfl')}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12}>
                                    <Form.Group>
                                        <Form.Label>{t(lang, "roaming.act_form_seller_name")}</Form.Label>
                                        <Form.Control
                                            type='text'
                                            disabled={true}
                                            placeholder={t(lang, "roaming.act_form_seller_name")}
                                            {...register('ActSeller.name')}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12}>
                                    <Form.Group>
                                        <Form.Label>{t(lang, "roaming.act_form_seller_branch")}</Form.Label>
                                        <Form.Control
                                            placeholder={t(lang, "roaming.act_form_seller_branch_placeholder")}
                                            className="p-1"
                                            name='SellerBranchName'
                                            {...register(`SellerBranchName`)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12}>
                                    <Form.Group>
                                        <Form.Label>{t(lang, "roaming.act_form_seller_branch_code")}</Form.Label>
                                        <Form.Control
                                            placeholder={t(lang, "roaming.act_form_seller_branch_code_placeholder")}
                                            className="p-1"
                                            name='SellerBranchCode'
                                            {...register(`SellerBranchCode`)}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>

                <Col xs={12} lg={6} className="my-3 mt-lg-0">
                    <Card>
                        <Card.Header>
                            <h4 className="border-bottom">{t(lang, "roaming.act_form_buyer_name")}</h4>
                        </Card.Header>
                        <Card.Body className="bg-light">
                            <Row>
                                <Col xs={12}>
                                    <Form.Group>
                                        <Form.Label>{t(lang, "roaming.act_form_seller_inn")}</Form.Label>
                                        <Controller
                                            name="ActBuyer.inn"
                                            control={control}
                                            render={({field}) => (
                                                <FindCompanySearchable
                                                    defaultInn={field.value}
                                                    onChange={(company) => {
                                                        onChangeCompanyBuyer(company);
                                                    }}
                                                    inputProps={{
                                                        isInvalid: errors?.ActBuyer?.inn,
                                                        placeholder: t(lang, "edi.toast.find_company_searchable:placeholder")
                                                    }}
                                                    inputGroupProps={{
                                                        className: classNames({
                                                            'is-invalid': errors?.ActBuyer?.inn,
                                                        })
                                                    }}
                                                    ref={field.ref}
                                                />
                                            )}
                                            rules={{
                                                pattern: /^[0-9]+$/,
                                                required: t(lang, "edi.common.forms.validations.is_required")
                                            }}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors?.ActBuyer?.inn?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col xs={12}>
                                    <Form.Group>
                                        <Form.Label>Инн | пинфл</Form.Label>
                                        <Form.Control
                                            readOnly={true}
                                            {...register('ActBuyer.pinfl')}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12}>
                                    <Form.Group>
                                        <Form.Label>{t(lang, "roaming.act_form_seller_name")}</Form.Label>
                                        <Form.Control
                                            readOnly={true}
                                            {...register('ActBuyer.name')}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12}>
                                    <Form.Group>
                                        <Form.Label>{t(lang, "roaming.act_form_seller_branch")}</Form.Label>
                                        <Form.Control
                                            placeholder={t(lang, "roaming.act_form_seller_branch_placeholder")}
                                            className="p-1"
                                            name='BuyerBranchName'
                                            {...register(`BuyerBranchName`)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12}>
                                    <Form.Group>
                                        <Form.Label>{t(lang, "roaming.act_form_seller_branch_code")}</Form.Label>
                                        <Form.Control
                                            placeholder={t(lang, "roaming.act_form_seller_branch_code_placeholder")}
                                            className="p-1"
                                            name='BuyerBranchCode'
                                            {...register(`BuyerBranchCode`)}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default ActFormOwnerBuyer;
