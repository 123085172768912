import React, {useEffect, useRef, useState} from 'react';
import {Button, Col, Form, Row} from "react-bootstrap";
import classNames from "classnames";
import PropTypes from "prop-types";
import {Controller, FormProvider, useForm} from "react-hook-form";
import {
    selectKanbanItems,
    statusRegister,
    statusUpdate
} from "../../../app/store/reducers/kanban/kanbanReducer";
import {useSelector} from "react-redux";
import {useParams} from "react-router";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";
import ReactSelect from "react-select";

const AddAnotherForm = ({type, showForm, setShowForm, defaultValue, orderId, statusId}) => {

    const {id} = useParams()
    const kanbanItems = useSelector(selectKanbanItems)
    const lang = useSelector(selectLang);
    const t = Translate;
    const [loading, setLoading] = useState(false)
    const inputRef = useRef(null);

    const methods = useForm({
        defaultValues: {
            name: defaultValue || null,
            order: orderId || null
        }
    })

    const onSubmit = ({name, order}) => {
        setLoading(true)
        const listId = kanbanItems.length ? kanbanItems.length + 1 : 1;

        const newList = {
            order: listId,
            board_id: +id,
            name
        };

        const updateList = {
            status_id: statusId,
            order: order?.order,
            name,
        }

        if (orderId) {
            statusUpdate(updateList)
                .then(()=> {
                    setShowForm(false)
                    methods.reset()
                })
                .catch((e) => new Error(e))
                .finally(()=> setLoading(false))
        } else  {
            statusRegister(newList)
                .then(()=> {
                    setShowForm(false)
                    methods.reset()
                })
                .catch((e) => new Error(e))
                .finally(()=> setLoading(false))
        }

    }

    useEffect(() => {
        if (showForm) {
            inputRef.current?.focus();
        }
    }, [showForm]);

    return (
        <>
            {showForm && (
                <div
                    className={classNames('rounded-3 transition-none', {
                        'bg-100 p-x1': type === 'list',
                        'p-3 border bg-white dark__bg-1000 mt-3': type === 'card'
                    })}
                >
                   <FormProvider {...methods}>
                       <Form onSubmit={methods.handleSubmit(onSubmit)}>
                           <Form.Group>
                               <Form.Label>{t(lang, 'task.board.form.title')}</Form.Label>
                               <Form.Control
                                   as="textarea"
                                   rows={2}
                                   className="mb-2"
                                   defaultValue={defaultValue}
                                   ref={inputRef}
                                   {...methods.register('name', {
                                       required: t(lang, 'task.board.common.forms.validations.is_required'),
                                   })}
                                   placeholder={t(lang, 'task.board.modal.title.task.placeholder')}
                               />
                               <Form.Control.Feedback>{methods?.formState?.errors?.name?.message}</Form.Control.Feedback>
                           </Form.Group>
                           {orderId && <Form.Group className={'mb-2'}>
                               <Form.Label>{t(lang, 'task.board.edit.form.select.cols')}</Form.Label>
                               <Controller name={'order'} render={({field}) => (
                                   <ReactSelect
                                        options={kanbanItems.filter(i=> i.order !== orderId)}
                                        getOptionValue={option => option.order}
                                        getOptionLabel={option => option.name}
                                        value={field.value}
                                        onChange={field.onChange}
                                        placeholder={t(lang, "task.board.common.select.placeholder")}
                                        hideSelectedOptions
                                        classNamePrefix={'react-select'}
                                   />
                               )} />
                           </Form.Group>}
                           <Row className="gx-2">
                               <Col>
                                   <Button
                                       variant="primary"
                                       size="sm"
                                       className="d-block w-100"
                                       type="submit"
                                       disabled={loading}
                                   >
                                       {t(lang, 'task.board.common.add')}
                                   </Button>
                               </Col>
                               <Col>
                                   <Button
                                       variant="outline-dark"
                                       size="sm"
                                       className="d-block w-100 border-400"
                                       type="button"
                                       onClick={() => setShowForm(false)}
                                   >
                                       {t(lang, 'task.board.common.cancel')}
                                   </Button>
                               </Col>
                           </Row>
                       </Form>
                   </FormProvider>
                </div>
            )}
        </>
    );
};


export default AddAnotherForm;
