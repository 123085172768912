import axios from "axios";

class RoamingWaybillV2Service {

    constructor() {
        this.baseUrl = '/roaming-reference'
    }
    getCompanyAddresses(innOrPinfl) {
        return axios.get(`${this.baseUrl}/get-company-addresses/${innOrPinfl}`)
    }

    getWaybillRegion() {
        return axios.get(`${this.baseUrl}/get-waybill-regions`)
    }

    getWaybillStations() {
        return axios.get(`${this.baseUrl}/get-waybill-stations`)
    }

    getWaybillDistrict(regionCode) {
        return axios.get(`${this.baseUrl}/get-waybill-districts/${regionCode}`)
    }

    getWaybillVillage(soatoCode) {
        return axios.get(`${this.baseUrl}/get-waybill-villages/${soatoCode}`)
    }

    getTransport(innOrPinfl) {
        return axios.get(`${this.baseUrl}/get-transports/${innOrPinfl}`)
    }

    sendRoamingWaybillV2(params) {
        return axios.post('/roaming/waybill-v2/send', params)
    }

    getBulkWaybillsV2(payload) {
        return axios.post(`/roaming/waybill-v2/get-bulk`, payload);
    }

    getWaybillsV2Customer(params) {
        return axios.get(`/roaming/waybill-v2/customer`, {
            params: params
        })
    }

    getWaybillsV2CustomerCount(params) {
        return axios.get(`/roaming/waybill-v2/customer/count`, {
            params: params
        })
    }

    getWaybillsV2Executor(params) {
        return axios.get(`/roaming/waybill-v2/executor`, {
            params: params
        })
    }

    getWaybillsV2ExecutorCount(params) {
        return axios.get(`/roaming/waybill-v2/executor/count`, {
            params: params
        })
    }

    getWaybillV2(id) {
        return axios.get(`/roaming/waybill-v2/${id}`)
    }

    consignorCancel(id, signature) {
        return axios.post(`/roaming/waybill-v2/cancel/${id}`, {
            signature
        })
    }

    customerAccept(id, signature) {
        return axios.post(`/roaming/waybill-v2/customer/accept/${id}`, {
            signature
        })
    }

    customerDecline(id, signature) {
        return axios.post(`/roaming/waybill-v2/customer/decline/${id}`, {
            signature
        })
    }

    returnAccept(id, signature) {
        return axios.post(`/roaming/waybill-v2/return-accept/${id}`, {
            signature
        })
    }

    getCancelHashCode(id) {
        return axios.get(`/roaming/waybill-v2/hashcode/cancel/${id}`, {
            transformResponse: [data => data]
        })
    }

    getAcceptHashCode(id) {
        return axios.get(`/roaming/waybill-v2/hashcode/accept/${id}`, {
            transformResponse: [data => data]
        })
    }

    getReturnAcceptHashCode(id) {
        return axios.get(`/roaming/waybill-v2/hashcode/return-accept/${id}`, {
            transformResponse: [data => data]
        })
    }

    getDeclineHashCode(id) {
        return axios.get(`/roaming/waybill-v2/hashcode/decline/${id}`, {
            transformResponse: [data => data]
        })
    }

    sync(id) {
        return axios.get(`/roaming/waybill-v2/sync/${id}`)
    }

    // responsible person

    getWaybillV2ResponsiblePersons(params) {
        return axios.get('/roaming/waybill-v2/responsible-person', {
            params: params
        })
    }

    getWaybillV2ResponsiblePersonsCount(params) {
        return axios.get('/roaming/waybill-v2/responsible-person/count', {
            params: params
        })
    }

    responsiblePersonReject(id, signature, comment) {
        return axios.post(`/roaming/waybill-v2/responsible-person/reject/${id}`, {
            signature: signature,
            comment: comment
        })
    }

    responsiblePersonAccept(id, signature) {
        return axios.post(`/roaming/waybill-v2/responsible-person/accept/${id}`, {
            signature
        })
    }

    responsiblePersonTillReturn(id, signature, comment) {
        return axios.post(`/roaming/waybill-v2/responsible-person/till-return/${id}`, {
            signature: signature,
            comment: comment
        })
    }

    responsiblePersonGive(id, signature) {
        return axios.post(`/roaming/waybill-v2/responsible-person/give/${id}`, {
            signature
        })
    }

    responsiblePersonReturn(id, signature, comment) {
        return axios.post(`/roaming/waybill-v2/responsible-person/return/${id}`, {
            signature: signature,
            comment: comment
        })
    }

    getHashCodeResponsiblePersonReject(id) {
        return axios.get(`/roaming/waybill-v2/hashcode/responsible-person-return/${id}`, {
            transformResponse: [data => data]
        })
    }

    getHashCodeResponsiblePersonAccept(id) {
        return axios.get(`/roaming/waybill-v2/hashcode/responsible-person-accept/${id}`, {
            transformResponse: [data => data]
        })
    }

    getHashCodeResponsiblePersonTillReturn(id) {
        return axios.get(`/roaming/waybill-v2/hashcode/responsible-person-till-return/${id}`, {
            transformResponse: [data => data]
        })
    }

    getHashCodeResponsiblePersonGive(id) {
        return axios.get(`/roaming/waybill-v2/hashcode/responsible-person-give/${id}`, {
            transformResponse: [data => data]
        })
    }

    getHashCodeResponsiblePersonReturn(id) {
        return axios.get(`/roaming/waybill-v2/hashcode/responsible-person-return/${id}`, {
            transformResponse: [data => data]
        })
    }

    // draft
    saveDraftWaybillV2(data) {
        return axios.post('/roaming/waybill-v2/draft/save', data)
    }

    getWaybillV2Drafts(params) {
        return axios.get(`/roaming/waybill-v2/drafts`, {
            params: params
        })
    }

    getWaybillV2DraftsCount(params) {
        return axios.get('/roaming/waybill-v2/drafts/count', {
            params: params
        })
    }

    deleteDraft(id) {
        return axios.post('/roaming/waybill-v2/draft/delete/'+id)
    }

    getDraftWaybillV2(id) {
        return axios.get(`/roaming/waybill-v2/draft/${id}`)
    }

    getPdf(id) {
        return axios.get(`/pdf/pdf/roaming/waybill-v2/${id}`, {
            responseType: "blob"
        })
    }
}

const waybillV2Service = new RoamingWaybillV2Service()

export default waybillV2Service
