import {Button, Card, Col, Form, InputGroup, Row, Spinner} from "react-bootstrap";
import React, {useState} from "react";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {Controller, FormProvider, useFieldArray, useForm} from "react-hook-form";
import classNames from "classnames";
import IconButton from "../../../common/IconButton";
import FindCompanySearchable from "../../organization/FindCompanySearchable";
import SoftBadge from "../../../common/SoftBadge";
import {removeContractorLabelAsync} from "../../../../app/store/reducers/contractor/contractorLabelReducer";
import Cleave from "cleave.js/react";
import {selectNationalCurrency} from "../../../../app/store/reducers/currency/currencyReducer";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import InputGroupWithCurrency from "../../item/components/InputGroupWithCurrency";
import {toast} from "react-toastify";

const ContractorForm = ({onClose, loading, onSubmit, contractor, isEditing}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const nationalCurrency = useSelector(selectNationalCurrency);
    const [labels, setLabels] = useState(contractor?.labels || []);
    const form = useForm({
        defaultValues: {
            name: contractor?.name,
            inn: contractor?.inn,
            type: contractor?.type?.length ? contractor?.type.map(t => String(t)) : [],
            organization_code: contractor?.organization_code,
            phones: (contractor?.contacts?.length && contractor.contacts.filter(contact => contact.type === 1).length) ? contractor.contacts.filter(contact => contact.type === 1).map(contact => ({value: contact.value.length > 9 ? contact.value.slice(3, 12) : contact.value})) : [{value: ""}],
            emails: (contractor?.contacts?.length && contractor.contacts.filter(contact => contact.type === 3).length) ? contractor.contacts.filter(contact => contact.type === 3).map(contact => ({value: contact.value})) : [{value: ""}],
            is_default: contractor?.is_default || false,
            starting_debt_states: [
                {
                    amount: '',
                    currency_id: nationalCurrency?.id,
                }
            ],
        },
    });
    const {fields: fieldsPhone, append: appendPhone, remove: removePhone} = useFieldArray({
        control: form.control,
        name: "phones"
    });
    const {fields: fieldsEmails, append: appendEmail, remove: removeEmail} = useFieldArray({
        control: form.control,
        name: "emails"
    });
    const {fields: fieldStartingDebts, append: appendStartingDebt, remove: removeStartingDebt} = useFieldArray({
        control: form.control,
        name: "starting_debt_states"
    });

    const onChangeCompany = company => {
        if (company) {
            form.setValue('inn', company.inn)
            form.setValue('name', company.name)
        }
    };
    const deleteLabel = label => {
        removeContractorLabelAsync({
            contractor_id: contractor.id,
            label: label.id
        }).then(res => {
            setLabels(res.labels);
            toast.success(t(lang, 'common.toast.success'));
        }).catch(err => {
            toast.error(t(lang, 'common.toast.error'));
        })
    }


    const allowDeleteEmail = fieldsEmails.length > 1;
    const addNewEmail = () => {
        appendEmail({
            value: '',
            description: ''
        })
    };
    const deleteEmail = (index) => {
        removeEmail(index);
    };


    const allowDeletePhoneNumber = fieldsPhone.length > 1;
    const addNewPhoneNumber = () => {
        appendPhone({
            value: '',
            description: ''
        });
    };
    const deletePhoneNumber = (index) => {
        removePhone(index);
    };

    const allowDeleteStartingDebtState = fieldStartingDebts.length > 1;
    const addNewStartingDebt = () => {
        appendStartingDebt({
            amount: '',
            currency_id: nationalCurrency?.id,
        });
    };
    const deleteStartingDebtState = index => {
        removeStartingDebt(index)
    };

    return (
        <FormProvider {...form}>
            <Form onSubmit={form.handleSubmit(onSubmit)}>
                <Row className="g-3">
                    <Col xs={12}>
                        <Card className="h-100">
                            <Card.Body>
                                <div className={"d-flex justify-content-between"}>
                                    <div className={'d-flex'}>
                                        <div className={"me-3"}>
                                            <Form.Check type="checkbox" id="typeBuyer">
                                                <Form.Check.Input type="checkbox"
                                                                  value='1'
                                                                  {...form.register('type')}
                                                />
                                                <Form.Check.Label className="fs-1 fw-bold text-dark cursor-pointer">
                                                    {t(lang, "edi.common.buyer")}
                                                </Form.Check.Label>
                                            </Form.Check>
                                        </div>
                                        <div>
                                            <Form.Check type="checkbox" id="typeSeller">
                                                <Form.Check.Input type="checkbox"
                                                                  value='2'
                                                                  {...form.register('type')}
                                                />
                                                <Form.Check.Label className="fs-1 fw-bold text-dark cursor-pointer">
                                                    {t(lang, "edi.common.executor")}
                                                </Form.Check.Label>
                                            </Form.Check>
                                        </div>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} lg={6}>
                        <Card className="h-100">
                            <Card.Body>
                                <Row className="g-2">
                                    <Col xs={12}>
                                        <Form.Group className="text-start">
                                            <Form.Label>{t(lang, "edi.common.inn")}</Form.Label>
                                            <Controller name="inn"
                                                        render={({field}) => (
                                                            <FindCompanySearchable defaultInn={field.value}
                                                                                   onChange={(company) => {
                                                                                       onChangeCompany(company);
                                                                                       field.onChange(company?.inn);
                                                                                   }}
                                                                                   inputProps={{
                                                                                       placeholder: t(lang, "edi.toast.find_company_searchable:placeholder")
                                                                                   }}
                                                                                   ref={field.ref}
                                                            />
                                                        )}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12}>
                                        <Form.Group className="text-start">
                                            <Form.Label>{t(lang, "edi.common.name")}</Form.Label>
                                            <Form.Control
                                                placeholder={t(lang, "edi.common.name")}
                                                name="name"
                                                type="text"
                                                isInvalid={form.formState.errors?.name?.message}
                                                isValid={Object.keys(form.formState.errors).length > 0 && !form.formState.errors?.name?.message}
                                                {...form.register("name", {
                                                    required: {
                                                        value: true,
                                                        message: t(lang, "edi.common.forms.validations.is_required")
                                                    }
                                                })}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {form.formState.errors?.name?.message}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12}>
                                        <Form.Group className="text-start">
                                            <Form.Label>{t(lang, "partners.common.organization_code")}</Form.Label>
                                            <Form.Control
                                                placeholder={t(lang, "partners.common.organization_code.placeholder")}
                                                name="organization_code"
                                                type="text"
                                                {...form.register("organization_code")}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} lg={6}>
                        <Card className="h-100">
                            <Card.Body>
                                <Row className="g-2">
                                    <Col xs={12}>
                                        {fieldsEmails.map((item, index) => (
                                            <Form.Group key={item.id}
                                                        className={classNames({'mb-2': index !== fieldsEmails.length - 1})}
                                            >
                                                {index === 0 &&
                                                    <Form.Label>
                                                        <span>{t(lang, "edi.contract_formation.info.email")}</span>
                                                        <span className="text-info cursor-pointer ms-2"
                                                              onClick={addNewEmail}
                                                        >
                                                {t(lang, 'edi.common.add_button')}
                                            </span>
                                                    </Form.Label>
                                                }
                                                <InputGroup>
                                                    <Form.Control type="email"
                                                                  placeholder="example@gmail.com"
                                                                  {...form.register(`emails.${index}.value`, {
                                                                          pattern: {
                                                                              value: /^[a-z0-9](\.?[a-z0-9_-]){0,}@[a-z0-9-]+\.([a-z]{1,6}\.)?[a-z]{2,3}$/,
                                                                              message: t(lang, "edi.common.forms.validations.is_required")
                                                                          }
                                                                      }
                                                                  )}
                                                                  className={classNames('form-control', {
                                                                      'is-invalid': form.formState.errors?.emails?.[index]?.value.message,
                                                                      'is-valid': Object.keys(form.formState.errors).length > 0 && !form.formState.errors?.emails?.[index]?.value.message
                                                                  })}
                                                    />
                                                    <IconButton size="sm"
                                                                disabled={!allowDeleteEmail}
                                                                variant="falcon-default"
                                                                icon={faTrash}
                                                                iconClassName={"text-danger"}
                                                                onClick={() => deleteEmail(index)}
                                                    />
                                                </InputGroup>
                                                <Form.Control.Feedback type="invalid">
                                                    {form.formState.errors?.emails?.[index]?.value.message}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        ))}
                                    </Col>
                                    <Col xs={12}>
                                        {fieldsPhone.map((item, index) => (
                                            <Form.Group key={item.id}
                                                        className={classNames({'mb-2': index !== fieldsPhone.length - 1})}
                                            >
                                                {index === 0 &&
                                                    <Form.Label>
                                                        <span>{t(lang, "edi.common.cellPhone")}</span>
                                                        <span className="text-info cursor-pointer ms-2"
                                                              onClick={addNewPhoneNumber}
                                                        >
                                                        {t(lang, 'edi.common.add_button')}
                                                    </span>
                                                    </Form.Label>
                                                }
                                                <InputGroup>
                                                    <InputGroup.Text>+998</InputGroup.Text>
                                                    <Controller name={`phones.${index}.value`}
                                                                rules={{
                                                                    validate: {
                                                                        format: value => !value || /^(\d{9})$/.test(value.replace(/ /g, '')) || t(lang, 'authentication.forgot-password.phoneNumber.validations.format'),
                                                                    }
                                                                }}
                                                                render={({field}) => (
                                                                    <Cleave
                                                                        placeholder={"-- --- -- --"}
                                                                        options={{
                                                                            delimiters: [' ', ' ', ' ', ' '],
                                                                            blocks: [2, 3, 2, 2],
                                                                            numericOnly: true
                                                                        }}
                                                                        value={field?.value}
                                                                        onChange={(username) => field.onChange(username)}
                                                                        className={classNames('form-control', {
                                                                            'is-invalid': form.formState.errors?.phones?.[index]?.value.message,
                                                                            'is-valid': Object.keys(form.formState.errors).length > 0 && !form.formState.errors?.phones?.[index]?.value.message
                                                                        })}
                                                                    />
                                                                )}
                                                    />
                                                    <IconButton size="sm"
                                                                disabled={!allowDeletePhoneNumber}
                                                                variant="falcon-default"
                                                                icon={faTrash}
                                                                iconClassName={"text-danger"}
                                                                onClick={() => deletePhoneNumber(index)}
                                                    />
                                                </InputGroup>
                                                <Form.Control.Feedback type="invalid">
                                                    {form.formState.errors?.phones?.[index]?.value.message}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        ))}
                                    </Col>
                                    <Col xs={12}>
                                        <Form.Label
                                            className={'d-block'}>{t(lang, 'crm.contractor.datatable.label')}</Form.Label>
                                        {labels.length > 0 ? labels.map(label => {
                                            return (
                                                <SoftBadge key={label.id}
                                                           className={'me-1'}
                                                >
                                                    {label.name}
                                                    <span className={'ms-1 cursor-pointer h6'}
                                                          onClick={() => deleteLabel(label)}
                                                    >
                                                    x
                                                </span>
                                                </SoftBadge>
                                            )
                                        }) : 'Не указано'}
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                    {!isEditing &&
                        <Col xs={12} lg={6}>
                            <Card className="h-100">
                                <Card.Body>
                                    <Row className="g-2">
                                        <Col xs={12}>
                                            <Form.Group>
                                                <div className={"d-flex justify-content-between mb-1"}>
                                                    <Form.Label>{t(lang, 'crm.contractor.contractor_form.field.starting_debt.title')}</Form.Label>
                                                    <IconButton size="sm"
                                                                icon="plus"
                                                                variant="falcon-default"
                                                                iconClassName={'text-success'}
                                                                onClick={addNewStartingDebt}
                                                    />
                                                </div>
                                                {fieldStartingDebts.map((debt, index) => (
                                                    <Form.Group className={'text-start mb-3 d-flex'} key={debt.id}>
                                                        <InputGroupWithCurrency
                                                            currencyFieldName={`starting_debt_states.${index}.currency_id`}
                                                            priceFieldName={`starting_debt_states.${index}.amount`}
                                                        />
                                                        {allowDeleteStartingDebtState &&
                                                            <IconButton size="sm"
                                                                        icon={faTrash}
                                                                        variant="falcon-default"
                                                                        className={"ms-2"}
                                                                        iconClassName={"text-danger"}
                                                                        onClick={() => deleteStartingDebtState(index)}
                                                            />}
                                                    </Form.Group>
                                                ))}
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    }
                    <Col xs={12}>
                        <Card>
                            <Card.Body>
                                <Row>
                                    <Col xs={12}>
                                        <Form.Check type="checkbox" id="is_default">
                                            <Form.Check.Input type="checkbox"
                                                              {...form.register('is_default')}
                                            />
                                            <Form.Check.Label className="fs-0 fw-bold text-dark cursor-pointer">
                                                {t(lang, 'crm.contractor.select_as_default')}
                                            </Form.Check.Label>
                                        </Form.Check>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} className="text-end">
                        <Button variant="secondary" onClick={onClose} className={'me-2'}>
                            {t(lang, "edi.common.cancel_button")}
                        </Button>
                        <Button variant="primary" disabled={loading} type="submit">
                            {loading && <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                className="me-2"
                            />}
                            {t(lang, "edi.common.button.save")}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </FormProvider>
    )
}

export default ContractorForm

