import {Card, Col, Row, Table} from "react-bootstrap";
import IconButton from "../../../common/IconButton";
import React, {useEffect, useState} from "react";
import ActItemRow from "./ActItemRow";
import {useFieldArray} from "react-hook-form";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {faBookmark, faSignature} from "@fortawesome/free-solid-svg-icons";
import SignActionWithSelectCertificatWrapper from "../components/SignActionWithSelectCertificatWrapper";


const ActFormDataTable = () => {
    const [allowDeleteItems, setAllowDeleteItems] = useState(false);
    const itemActFieldItems = 'itemAct';
    const {append, fields, remove} = useFieldArray({name: itemActFieldItems});
    const lang = useSelector(selectLang);
    const t = Translate;



    const addNewItem = () => {
        append({
            catalogClassCode: '',
            Items: '',
            measurementId: '',
            packageCode: null,
            packageName: null,
            packages: null,
            quantity: '',
            price: '',
            totalSum: '',
        });
    };



    const onNewItemClicked = () => {
        addNewItem();
    };

    const onDeleteItem = index => {
        remove(index);
    };

    useEffect(() => {
        setAllowDeleteItems(fields.length > 1);
    }, [fields]);



    return(
        <>
            <Row className="g-3 mb-3">
                <Col xs={12} className="pe-lg-2 mb-3">
                    <Card className="h-100">
                        <Card.Header>
                            <Row className="align-items-end g-2">
                                <Col>
                                    <h5 className="mb-0">{t(lang, "edi.common.items")}</h5>
                                </Col>
                                <Col xs="auto">
                                    <IconButton variant="falcon-default" size="sm" icon="plus" transform="shrink-3"
                                                className="me-2" onClick={onNewItemClicked}>
                                        <span className="d-none d-sm-inline-block ms-1">
                                            {t(lang, "edi.common.button.add")}
                                        </span>
                                    </IconButton>
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body className="bg-light">
                            <Table responsive striped hover>
                                <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">{t(lang, "edi.common.items.product.catalog_class_code")}</th>
                                    <th scope="col">{t(lang, "edi.common.items.product")}</th>
                                    <th scope="col">{t(lang, "edi.common.items.product.measurement")}</th>
                                    <th scope="col">{t(lang, "edi.common.items.product.quantity")}</th>
                                    <th scope="col">{t(lang, "edi.common.items.product.price")}</th>
                                    <th scope="col">{t(lang, "edi.common.items.product.total")}</th>
                                    <th scope="col"/>
                                </tr>
                                </thead>
                                <tbody>
                                    {fields.map((item, index) => (
                                        <ActItemRow key={item.id} allowDelete={allowDeleteItems}
                                                    onDelete={onDeleteItem} arrayActFieldName={itemActFieldItems} index={index}/>
                                    ))}
                                </tbody>
                            </Table>

                            <div className="border-dashed-bottom my-3"/>

                            <Row>
                                <Col className="text-end d-inline-flex justify-content-end">
                                    <IconButton
                                        type="submit"
                                        variant="warning"
                                        name="draft"
                                        icon={faBookmark}
                                    >
                                        {t(lang,"roaming.act_form_save")}
                                    </IconButton>

                                    <SignActionWithSelectCertificatWrapper isForm>
                                        <IconButton 
                                            form="ActForm"
                                            className="mx-2" 
                                            type="submit" 
                                            name="send"  
                                            variant="falcon-primary" 
                                            icon={faSignature}
                                        >{t(lang, 'roaming.act_form_send')}
                                        </IconButton>
                                    </SignActionWithSelectCertificatWrapper>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    )
}


export default ActFormDataTable;