import React, {useEffect, useRef, useState} from 'react';
import {GeolocationControl, Map, Placemark, SearchControl, YMaps} from "@pbe/react-yandex-maps";

const YandexMap = ({defaultLocation, readOnly, onChange, mapProps}) => {
    const searchControlRef = useRef(null);
    const [maps, setMaps] = useState(null);
    const [coords, setCoords] = useState([]);
    const [center, setCenter] = useState([]);

    const onLoadMap = (map) => {
        setMaps(map);
    };
    const onBoundsChangeMap = (e) => {
        if (readOnly) return;

        const coordinates = e.get("target").getCenter();
        setCenter(coordinates);
    };
    const onClickMap = async (e) => {
        if (readOnly) return;

        try {
            const coordinates = e.get("coords");
            setCoords(coordinates);

            const resp = await maps.geocode(coordinates);
            const res = await resp;
            const firstGeoObject = res.geoObjects.get(0);
            const location = {
                latitude: coordinates[0],
                longitude: coordinates[1],
                address: firstGeoObject.getAddressLine(),
            };
            onChange(location);
        } catch (error) {
            console.log(error);
        }
    };
    const onSelectSearchResult = async () => {
        if (readOnly) return;

        if (searchControlRef.current) {
            try {
                const selectedIndex = searchControlRef.current.getSelectedIndex();
                const selectedOption = await searchControlRef.current.getResult(selectedIndex);
                const coordinates = selectedOption.geometry._coordinates;
                setCenter(coordinates);
                setCoords(coordinates);

                const resp = await maps.geocode(coordinates);
                const res = await resp;
                const firstGeoObject = res.geoObjects.get(0);
                const location = {
                    latitude: coordinates[0],
                    longitude: coordinates[1],
                    address: firstGeoObject.getAddressLine(),
                };
                onChange(location);
            } catch (error) {
                console.log(error);
            }
        }
    };
    const onLocationChange = (e) => {
        if (readOnly) return;

        const coordinates = e.get("position");
        setCenter(coordinates);
        setCoords(coordinates);

        const address = e.get("geoObjects").get(0).getAddressLine();
        const location = {
            latitude: coordinates[0],
            longitude: coordinates[1],
            address: address
        };
        onChange(location)
    };

    useEffect(() => {
        if (defaultLocation) {
            const defaultCoords = [defaultLocation.latitude, defaultLocation.longitude];
            setCoords(defaultCoords);
            setCenter(defaultCoords);
        }
    }, []);

    return (
        <YMaps query={{
            lang: 'ru_RU',
            mode: 'release',
            coordorder: 'latlong',
            apikey: '5b71cae6-2e14-4fff-9278-b5d20efa06b0',
            suggest_apikey: "31b9606b-88ba-4032-83da-d2042a23a7cf"
        }}
        >
            <Map width={'100'}
                 state={{
                     zoom: 16,
                     center: center,
                     type: 'yandex#map',
                     controls: ["zoomControl", "fullscreenControl"],
                 }}
                 modules={["control.ZoomControl", "control.FullscreenControl", "control.SearchControl", "control.GeolocationControl", "geolocation", "geocode"]}
                 onLoad={onLoadMap}
                 onBoundsChange={onBoundsChangeMap}
                 onClick={onClickMap}
                 {...mapProps}
            >
                <Placemark options={{preset: 'islands#lightBlueClusterIcons'}}
                           geometry={coords}
                />
                {!readOnly &&
                    <>
                        <SearchControl instanceRef={searchControlRef}
                                       onResultSelect={onSelectSearchResult}
                                       options={{
                                           float: "left",
                                           noPlacemark: true,
                                           resultsPerPage: 5,
                                           position: {
                                               top: 20,
                                               left: 20
                                           }
                                       }}
                        />
                        <GeolocationControl onLocationChange={onLocationChange}
                                            options={{
                                                float: "right",
                                                position: {
                                                    bottom: 150,
                                                    right: 20
                                                }
                                            }}
                        />
                    </>
                }
            </Map>
        </YMaps>

    );
};

export default YandexMap;

YandexMap.defaultProps = {
    defaultCoords: []
};