import {useState} from "react";
import {useSelector} from "react-redux";
import {selectCurrency} from "../../../../../app/store/reducers/currency/currencyReducer";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {FormProvider, useForm} from "react-hook-form";
import dayjs from "dayjs";
import {UZS} from "../../../../../enum/CurrencyCode";
import {toast} from "react-toastify";
import {Form} from "react-bootstrap";
import {
    refundAddItemAsync,
    refundChangeContractorAsync,
    refundChangeEmployeeAsync,
    refundUpdateNoteAsync,
    refundUpdateNumberAsync,
    selectRefundDefaultsSettings
} from "../../../../../app/store/reducers/warehouse-operation/refundReducer";
import RefundForm from "./RefundForm";
import {selectWarehouses} from "../../../../../app/store/reducers/warehouse/warehouseReducer";
import {selectDefaultSetting} from "../../../../../app/store/reducers/settings/settingsReducer";


const EditRefundForm = ({refund}) => {
    const refundDefaultsSettings = useSelector(selectRefundDefaultsSettings)
    const [loading, setLoading] = useState(false)
    const warehouses = useSelector(selectWarehouses)
    const currencies = useSelector(selectCurrency)
    const lang = useSelector(selectLang)
    const t = Translate
    const settings = useSelector(selectDefaultSetting)


    const form = useForm({
        defaultValues: {
            contractor_id: refund?.contractor?.id,
            employee_id: refund?.employee?.id,
            date: dayjs(refund?.date, "YYYY-MM-DD HH:mm").toDate(),
            note: refund?.note,
            number: refund?.number,
            item_id: null,
            warehouse_id: settings?.warehouse?.default_id || null,
            quantity: null,
            marks: [],
            commonPrice: {
                amount: null,
                currency_id: currencies?.find(c => c?.code === UZS)?.id,
            },
        }
    })


    const resetForm = (formData) => {
        form.reset({
            contractor_id: formData?.contractor_id,
            employee_id: formData?.employee_id,
            date: formData?.date && dayjs(formData?.date, "YYYY-MM-DD HH:mm").toDate(),
            note: formData?.note,
            number: formData?.number,
            item_id: null,
            quantity: null,
            warehouse_id: settings?.warehouse?.default_id || null,
            warehouse: warehouses,
            package_measurements: null,
            marks: [],
            commonPrice: {
                amount: '0',
                currency_id: currencies?.find(c => c?.code === UZS)?.id,
            },
        })
    }




    const onSubmit = async (formData, event) => {
        const buttonType = event?.nativeEvent?.submitter?.name
        const quantity = +formData?.quantity * +formData?.package_measurements?.quantity

        const item = {
            item_id: formData?.item_id,
            warehouse_id: formData?.warehouse_id,
            quantity: quantity,
            marks: formData?.marks || [],
            price: {
                amount: +formData?.commonPrice?.amount,
                currency_id: formData?.commonPrice?.currency_id,
            },
        }


        if (buttonType === "addOrEditRefund" || event === "addOrEditRefund") {
            setLoading(true)
            /*if (refund?.contractor?.id !== formData?.contractor_id) {
                await refundChangeContractorAsync({refundId: refund.id, contractorId: formData?.contractor_id})
                    .then(({data}) => {
                    })
                    .catch(() => {})
                    .finally(() => {
                        setLoading(false)
                    })
            }*/

            await refundAddItemAsync({
                refundId: refund?.id,
                operation: item
            })
                .then(({data}) => {
                    updateNote(formData)
                    // updateNumber(formData)
                    resetForm(formData)
                    updateEmployee(formData)
                    toast.success(t(lang, "warehouse.operation.item.common.toast.success"))
                })
                .catch((error) => {
                    if (error?.response?.data?.includes("warehouse")) {
                        toast.error(t(lang, "Склад не выбран"))
                    } else {
                        toast.error(t(lang, "warehouse.operation.item.common.toast.error"))
                    }
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }

    const updateEmployee = (formData) => {
        if (formData?.employee_id || refund?.employee) {
            if (formData?.employee_id !== refund?.employee?.id) {
                refundChangeEmployeeAsync({refundId: refund?.id, employeeId: formData?.employee_id})
                    .then(() => { })
                    .catch(() => {})
            }
        }
    }

    const updateNote = (formData) => {
        if (formData?.note !== refund?.note) {
            refundUpdateNoteAsync({refundId: refund?.id, note: formData?.note})
                .then(({data}) => {})
                .catch(() => {})
        }
    }

    // const updateNumber = (formData) => {
    //     if (formData?.number?.trim() && formData?.number !== refund?.number) {
    //         refundUpdateNumberAsync({refundId: refund?.id, number: formData?.number})
    //             .then(({data}) => {})
    //             .catch(() => {})
    //     }
    // }


    return(
        <>
            <FormProvider {...form}>
                <Form onSubmit={form.handleSubmit(onSubmit)}>
                    <RefundForm refund={refund} loading={loading} addItem={form.handleSubmit(onSubmit)} isEditing={true}/>
                </Form>
            </FormProvider>
        </>
    )
}

export default EditRefundForm;
