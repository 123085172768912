import React, {Fragment} from 'react';
import {Col, Row} from "react-bootstrap";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";
import BoardModal from "../modal/board/BoardModal";

const KanbanDatatableHeader = () => {

    const lang = useSelector(selectLang);
    const t = Translate;

    return (
       <Fragment>
           <Row className={'align-items-center'}>
               <Col md={6} sm={12}>
                   <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">{t(lang, "task.board.title")}</h5>
               </Col>
               <Col md={6} sm={12} className={'text-end'}>
                   <BoardModal icon={'plus'} title={'task.board.common.registration'}/>
               </Col>
           </Row>
       </Fragment>
    );
};

export default KanbanDatatableHeader;
