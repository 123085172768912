import React, {useEffect, useState} from "react";
import {useRouteMatch} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../../../app/store/reducers/auth/authReducer";
import {loadActDraftAsync} from "../../../../../app/store/reducers/roaming/roamingActReducer";
import {useHistory} from "react-router";
import dayjs from "dayjs";
import Act from "../View/Act";
import checkPermission from "../../../../../enum/Permission/CheckPermission";
import {PermissionRoamingActView} from "../../../../../enum/Permission/RoamingPermission";
import {selectAccountRolePermissions} from "../../../../../app/store/reducers/role/roleReducer";
import SpinnerComponent from "../../../spinner/SpinnerComponent";


const DraftActView = ({modalId}) => {
    const [draftAct, setDraftAct] = useState(null);
    const activeOrganization = useSelector(selectActiveOrganization)
    const {params: {id}} = useRouteMatch();
    const history = useHistory()
    const dispatch = useDispatch()
    const permission = useSelector(selectAccountRolePermissions)


    useEffect(() => {
        if (permission.length && !dispatch(checkPermission(PermissionRoamingActView))) {
            if (history.length > 1) {
                history.push('/roaming/acts/draft')
            } else {
                history.push('/')
            }
        }
    }, [permission])

    useEffect(() => {
        loadDraftAct()
    }, [modalId, id, activeOrganization]);


    const loadDraftAct = async () => {
        try {
            const  getDraftAct = await loadActDraftAsync(modalId ? modalId : id)
            setDraftAct(JSON.parse(getDraftAct?.data?.content))

        }catch (errors) {
            history.push("../acts/draft")
        }
    }

    const act = {
        id: draftAct?.ActId,
        info: {
            number: draftAct?.ActDoc?.ActNo,
            date: dayjs(draftAct?.ActDoc?.ActDate).format('YYYY-MM-DD'),
            text: draftAct?.ActDoc?.ActText,
        },
        contract: {
            number: draftAct?.ContractDoc?.ContractNo,
            date: dayjs(draftAct?.ContractDoc?.ContractDate).format('YYYY-MM-DD'),
        },
        executor: {
            inn: draftAct?.SellerTin,
            name: draftAct?.SellerName,
            branch: {
                branch_code: draftAct?.SellerBranchCode,
                branch_name: draftAct?.SellerBranchName,
            }
        },
        customer: {
            inn: draftAct?.BuyerTin,
            name: draftAct?.BuyerName,
            branch: {
                branch_code: draftAct?.BuyerBranchCode,
                branch_name: draftAct?.BuyerBranchName,
            }
        },
        items: draftAct?.ProductList?.Products?.map(item => {
                return {
                    catalog: {
                        code: item?.CatalogCode,
                        name: item?.CatalogName,
                    },
                    measure_id: item.MeasureId,
                    packageCode: item.PackageCode || null,
                    packageName: item.PackageName || null,
                    name: item?.Name,
                    ordering_number: item?.OrdNo,
                    price: item?.Summa,
                    quantity: item?.Count,
                    total: item?.TotalSum,
                }
            }),
        product_id: draftAct?.ActProductId,
    }

    return (
        <>
            {draftAct && <Act modalId={modalId} act={act} draftAct={draftAct}/>}
            {!draftAct && <SpinnerComponent />}
        </>
    )
}

export default DraftActView;
