import IconButton from "../../common/IconButton";
import PropTypes from 'prop-types';
import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {Button, Col, Collapse, Form, Row} from 'react-bootstrap';
import ShipmentDataTableFilter from "./ShipmentDataTableFilter";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";
import {useDispatch, useSelector} from "react-redux";
import {FormProvider, useForm} from "react-hook-form";
import dayjs from "dayjs";
import {
    changeDataTableColumn,
    changeFilterOptionsAsync, loadDataTableColumn,
    selectFilterOptions, STORAGE_SHIPMENT,
} from "../../../app/store/reducers/shipment/shipmentReducer";
import UploadShipmentListToExcel from "./excel/UploadShipmentListToExcel";
import DataTableSettings from "../DataTableSettings";
import  checkPermission from "../../../enum/Permission/CheckPermission";
import ShipmentCustomerApprove, {allowCustomer, allowExecutor} from "./ShipmentCustomerApprove";
import ActionProgressBar from "../../common/ActionProgressBar";
import {useParams} from "react-router";
import EdiViewerComponent from "../../EdiViewerComponent";
import CreateBulkInvoice from "./CreateBulkInvoice";
import {selectActiveOrganization} from "../../../app/store/reducers/auth/authReducer";
import {
    AfterConfirmByCustomer,
    OnShipmentWaybillCreation
} from "../../../app/order/OrderExecutorCreateInvoiceStrategyType";
import {CustomerApproved, CustomerEditApprovedByExecutor, ExecutorApproved} from "../../../enum/ShipmentStatus";

export const TYPE_CREATE_BULK_INVOICE = 'TYPE_CREATE_BULK_INVOICE'
export const TYPE_CREATE_BULK_APPROVE = 'TYPE_CREATE_BULK_APPROVE'

const ShipmentDataTableHeader = ({selectedRowIds, page, loadShipments, hideViewers}) => {
    const {viewer} = useParams()
    const filter = useSelector(selectFilterOptions);
    const dispatch = useDispatch();
    const lang = useSelector(selectLang);
    const t = Translate;
    const activeOrganization = useSelector(selectActiveOrganization);

    const [showProgressBar, setShowProgressBar] = useState(false);
    const [bulkActionResult, setBulkActionResult] = useState([]);
    const [customProgressBarCount, setCustomerProgressBarCount] = useState(null)
    const [isOpenFilterCollapse, setIsOpenFilterCollapse] = useState(true)

    const methods = useForm({
        defaultValues: {
            date_start: filter?.date_start && dayjs(filter?.date_start).toDate() || null,
            date_end: filter?.date_end && dayjs(filter?.date_end).toDate() || null,
            number: filter?.number || null,
            branch_id: filter?.branch_id || null,
            status: typeof (filter?.status) === 'undefined' ? null : filter.status,
        }
    });

    const selectedRowIndexArray = Object.keys(selectedRowIds);
    const selectedShipments =  selectedRowIndexArray.reduce((result, selectedIndex) => {
        result.push(page[selectedIndex]?.original);
        return result;
    }, [])

    const updateActionResult = (result) => setBulkActionResult(prev => [...prev, result]);
    const handleShowProgressBar = (type) => {
        if (type === TYPE_CREATE_BULK_INVOICE) {
            setCustomerProgressBarCount(selectedShipments.filter(shipment => activeOrganization.inn === shipment.executor_info.inn && allowCreateInvoice(shipment)).length)
        } else if (type === TYPE_CREATE_BULK_APPROVE) {
            setCustomerProgressBarCount(selectedShipments.filter(shipment => allowCustomer(activeOrganization, shipment) || allowExecutor(activeOrganization, shipment)).length)
        }
        setShowProgressBar(true)
    };

    const handleCloseProgressBar = () => {
        loadShipments();
        setBulkActionResult([]);
        setShowProgressBar(false);
        setCustomerProgressBarCount(null);
    };

    const allowCreateInvoice = (shipment) => {
        if (shipment.order.executor_create_invoice_strategy === OnShipmentWaybillCreation.id) {
            if (shipment.status === ExecutorApproved || shipment.status === CustomerApproved || shipment.status === CustomerEditApprovedByExecutor) {
                return true
            }
        } else if (shipment.order.executor_create_invoice_strategy === AfterConfirmByCustomer.id) {
            if (shipment.status === CustomerApproved || shipment.status === CustomerEditApprovedByExecutor) {
                return true
            }
        }
        return false
    }

    const showCreateInvoiceButton = useCallback(() => {
        return selectedShipments.every(shipment => activeOrganization.inn === shipment.executor_info.inn && allowCreateInvoice(shipment))
    }, [selectedShipments])

    const showBulkApproveButton = useCallback(() => {
        return selectedShipments.every(shipment => allowCustomer(activeOrganization, shipment) || allowExecutor(activeOrganization, shipment))
    }, [selectedShipments])

    const onSubmitFilter = async ({date_start, date_end, ...filters}) => {
        date_start = date_start && dayjs(date_start).format("YYYY-MM-DD")
        date_end = date_end && dayjs(date_end).format("YYYY-MM-DD")
        dispatch(changeFilterOptionsAsync({
            page: 1,
            date_start: date_start,
            date_end: date_end,
            ...filters
        }))
    }

    const allColumns = JSON.parse(localStorage.getItem(STORAGE_SHIPMENT))
    if (!allColumns) {
        dispatch(loadDataTableColumn())
        return <></>
    }

    return (
        <Fragment>
            <ActionProgressBar show={showProgressBar}
                               result={bulkActionResult}
                               count={customProgressBarCount}
                               onClose={handleCloseProgressBar}
            />
            <Row className="flex-between-center">
                <FormProvider {...methods}>
                    <Form onSubmit={methods.handleSubmit(onSubmitFilter)}>
                        <Collapse in={isOpenFilterCollapse}>
                            <Col sm={12}>
                                <ShipmentDataTableFilter/>
                                <hr className={`${!hideViewers ? 'my-0' : ""}`}/>
                            </Col>
                        </Collapse>
                    </Form>
                </FormProvider>

                {!hideViewers &&<Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
                    <EdiViewerComponent to={'/edi/shipments'} typeViewer={viewer}
                                        title={"edi.shipment.datatable.header.title"}/>
                </Col>}
                <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
                    {Object.keys(selectedRowIds).length > 0 ? (
                        <div className="d-flex gap-2">
                            {showBulkApproveButton() && <ShipmentCustomerApprove
                                handleShowProgressBar={handleShowProgressBar}
                                updateActionResult={updateActionResult}
                                shipments={selectedShipments}
                            />}
                            {showCreateInvoiceButton() &&
                                <CreateBulkInvoice
                                handleShowProgressBar={handleShowProgressBar}
                                updateActionResult={updateActionResult}
                                shipments={selectedShipments}/>
                            }
                        <UploadShipmentListToExcel selectable rowValues={page} />
                        </div>
                    ) : (
                        <div id="shipment-actions">
                            <IconButton
                                variant="falcon-default"
                                size="sm"
                                transform="shrink-3"
                                icon="refresh"
                                className="mx-2"
                                onClick={methods.handleSubmit(onSubmitFilter)}
                            >
                            <span
                                className="d-none d-sm-inline-block ms-1">{t(lang, "edi.orders.order.resume_order_button")}</span>
                            </IconButton>

                            <UploadShipmentListToExcel className="d-inline-block me-2"/>

                            <IconButton
                                onClick={() => setIsOpenFilterCollapse(!isOpenFilterCollapse)}
                                variant="falcon-default"
                                size="sm"
                                icon="filter"
                                transform="shrink-3"
                            >
                            <span
                                className="d-none d-sm-inline-block ms-1">{t(lang, "edi.shipment.datatable.header.navigation.filter")}</span>
                            </IconButton>
                        </div>
                    )}
                </Col>
            </Row>

        </Fragment>
    );
};

ShipmentDataTableHeader.propTypes = {
    selectedRowIds: PropTypes.object
};

export default ShipmentDataTableHeader;
