import React, {useState} from 'react';
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {Card, Col, Nav, Row, Tab} from "react-bootstrap";
import OwnerOrganizationItemsDataTable from "../items/OwnerOrganizationItemsDataTable";
import organizationBindingWrapper from "../../../../enum/organizationBinding/OrganizationBindingWrapper";
import OwnerOrganizationSalesDataTable from "../sales/OwnerOrganizationSalesDataTable";

const OwnerBinding = ({binding}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const [eventKey, setEventKey] = useState('1');
    const isViewItemPricesEnabled = organizationBindingWrapper.isItemPriceAccessEnabled(binding.item_price_access_type);
    const isViewSalePaymentsEnabled = organizationBindingWrapper.isSalePaymentAccessEnabled(binding.sales_payment_access_type);

    return (
        <Row className="gy-3">
            <Col xs={12}>
                <Tab.Container defaultActiveKey={eventKey}
                               onSelect={setEventKey}
                >
                    <Card>
                        <Card.Header>
                            <Card.Title>{binding.supervisor_organization_name}</Card.Title>
                            <Nav variant="tabs">
                                <Nav.Item>
                                    <Nav.Link eventKey={'1'} className="cursor-pointer">
                                        {t(lang, 'items.table.title')}
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey={'2'} className="cursor-pointer">
                                        {t(lang, 'partners.common.sales')}
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Card.Header>
                        <Card.Body>
                            <Tab.Content>
                                <Tab.Pane eventKey={'1'}>
                                    <OwnerOrganizationItemsDataTable organizationBindingId={binding.id}
                                                                     isViewItemPricesEnabled={isViewItemPricesEnabled}
                                    />
                                </Tab.Pane>
                                <Tab.Pane eventKey={'2'}>
                                    <OwnerOrganizationSalesDataTable organizationBinding={binding}
                                                                     isViewSalePaymentsEnabled={isViewSalePaymentsEnabled}
                                    />
                                </Tab.Pane>
                            </Tab.Content>
                        </Card.Body>
                    </Card>
                </Tab.Container>
            </Col>
        </Row>
    )
};

export default OwnerBinding;