import React, {useRef} from 'react';
import {useSelector} from "react-redux";
import {Col, Form, Row} from "react-bootstrap";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import BulkItemsForm from "./BulkItemsForm";
import {FormProvider, useForm} from "react-hook-form";
import EventBus from "../../../../../app/eventbus/EventBus";
import {BULK_EDIT_ITEMS_FINISHED, BULK_EDIT_ITEMS_STARTED} from "../../../../../app/eventbus/itemEvents";
import {editItemAsync} from "../../../../../app/store/reducers/item/itemReducer";
import {updatePriceAsync} from "../../../../../app/store/reducers/price/priceReducer";
import ResultCounter from "./ResultCounter";
import {toast} from "react-toastify";
import {selectNationalCurrency} from "../../../../../app/store/reducers/currency/currencyReducer";
import {WithoutVat} from "../../enum/itemWrapper";
import {updateWarehouseItemAlertOn} from "../../../../../app/store/reducers/warehouse/warehouseReducer";


const EditBulkItemsForm = ({editingItems, handleClose}) => {
    const t = Translate;
    const ref = useRef(null);
    const lang = useSelector(selectLang);
    const nationalCurrency = useSelector(selectNationalCurrency);

    const form = useForm({
        defaultValues: {
            items: editingItems.map(({item, price, warehouse_states}, index) => {
                return {
                    id: item.id,
                    names: [
                        {name: item.name}
                    ],
                    description: item.description || '',
                    isFavorite: item.is_favorite,
                    isStateControlled: item.is_state_controlled,
                    sku: item.sku || '',
                    code: item.code || '',
                    barcodes: item.barcodes.map((barcode) => ({barcode: barcode})),
                    measurement: item.measurement.toString(),
                    categoryId: item.category ? item.category.id : null,
                    descriptionAttributes: item.description_attributes.map((attr, index) => ({
                        key: attr.key,
                        value: attr.value
                    })),
                    packageMeasurements: item.package_measurements.map((pck) => ({
                        name: pck.name,
                        quantity: pck.quantity
                    })),
                    taxCatalog: item.tax?.catalog ? {
                        class_code: item.tax.catalog.code || null,
                        name: item.tax.catalog.name || null
                    } : null,
                    taxPackage: item.tax?.catalog?.package ? {
                        code: item.tax.catalog.package.code || null,
                        name_ru: item.tax.catalog.package.name || null
                    } : null,
                    taxMeasurement: null,
                    taxBenefit: item.tax?.benefit ? {
                        id: item.tax.benefit.id || null,
                        name: item.tax.benefit.name || null,
                        type: item.tax.benefit.type || null
                    } : null,
                    taxRate: item.tax?.tax_rate === null ? WithoutVat : item.tax.tax_rate.toString(),
                    legalType: item.legal_type,
                    images: item.images.map((image) => ({
                        id: image.id,
                        name: image.name,
                        path: image.path
                    })),
                    commonPrice: {
                        price: price.common_price.amount.toString(),
                        currencyId: price.common_price.currency.global_currency_id,
                    },
                    bulkPrice: {
                        price: price.bulk_price.amount.toString(),
                        currencyId: price.bulk_price.currency.global_currency_id,
                    },
                    alertOn: warehouse_states.warehouse_items.map(warehouseItem => warehouseItem.alert_on),
                    purchasePrices: warehouse_states.warehouse_items.map(warehouseItem => {
                        const purchasePrice = {
                            itemId: item.id,
                            warehouseId: warehouseItem.id,
                            warehouseName: warehouseItem.name,
                            purchasePrice: {
                                price: 0,
                                currency: {
                                    id: nationalCurrency?.id || null,
                                    name: nationalCurrency?.name || null,
                                },
                            },
                        };

                        if (warehouseItem.purchase_price) {
                            purchasePrice.purchasePrice.price = warehouseItem.purchase_price.price.amount;
                            purchasePrice.purchasePrice.currency.id = warehouseItem.purchase_price.price.currency.id;
                            purchasePrice.purchasePrice.currency.name = warehouseItem.purchase_price.price.currency.name;
                        }

                        return purchasePrice;
                    }),
                };
            })
        },
    });


    const onSubmit = async (formData, replace) => {
        ref.current.updateLoadedCount(formData.items.length);

        EventBus.dispatch(BULK_EDIT_ITEMS_STARTED);
        const failed = [];
        for (let i = 0; i < formData.items.length; i++) {
            const item = formData.items[i];
            try {
                const itemJson = {
                    name: item.names.map(obj => obj.name.trim()).join(' ').trim(),
                    description: item.description,
                    is_favorite: item.isFavorite,
                    is_state_controlled: item.isStateControlled,
                    sku: item.sku || null,
                    code: item.code || null,
                    barcodes: item.barcodes.map(({barcode}) => barcode),
                    measurement: item.measurement,
                    category_id: item.categoryId,
                    description_attributes: item.descriptionAttributes.map((attr) => ({
                        key: attr.key,
                        value: attr.value
                    })),
                    package_measurements: item.packageMeasurements.map((pck) => ({
                        name: pck.name,
                        quantity: +pck.quantity
                    })),
                    legal_type: item.legalType,
                    tax: {
                        tax_rate: item.taxRate === "" ? null : item.taxRate ,
                        catalog: item.taxCatalog ? {
                            code: item.taxCatalog.class_code,
                            name: item.taxCatalog.name,
                            package: (item.taxCatalog.use_package && item.taxPackage) ? {
                                code: item.taxPackage.code.toString(),
                                name: item.taxPackage.name_ru,
                            } : null,
                        } : null,
                        benefit: item.taxBenefit ? {
                            id: +item.taxBenefit.id,
                            name: item.taxBenefit.name,
                            type: +item.taxBenefit.type,
                        } : null,
                        measurement: (item.taxMeasurement && !item.taxCatalog.use_package) ? item.taxMeasurement.measureId : null,
                    },
                    images: item.images.map((image) => ({
                        id: image.id || null,
                        name: image.name,
                        content: image?.content?.split(",")?.[1]
                    }))
                };
                const editedItem = await editItemAsync(item.id, itemJson);

                const alertOnItem = {
                    warehouse_id: item.purchasePrices[0].warehouseId,
                    id: item.id,
                    alert_on: +item.alertOn
                }

                await updateWarehouseItemAlertOn(alertOnItem)

                const priceJson = {
                    item_id: editedItem.id,
                    common_price: {
                        currency_id: +item.commonPrice.currencyId,
                        amount: +item.commonPrice.price,
                    },
                    bulk_price: {
                        currency_id: +item.bulkPrice.currencyId,
                        amount: +item.bulkPrice.price
                    }
                };
                await updatePriceAsync(priceJson);

                ref.current.incrementSucceedCount();
            } catch (error) {
                console.log(error);
                failed.push(item)
                ref.current.incrementFailedCount();
            }
        }

        if (failed.length > 0) {
            replace(failed);
            toast.error(t(lang, 'items.common.toast.error'))
        } else {
            toast.success(t(lang, 'items.common.toast.success'));
            handleClose();
        }

        EventBus.dispatch(BULK_EDIT_ITEMS_FINISHED);
    };
    const onReset = () => {
        form.reset();
    };

    return (
        <>
            <ResultCounter ref={ref}/>
            <Row className="gy-3">
                <Col xs={12}>
                    <FormProvider {...form}>
                        <Form>
                            <BulkItemsForm isEditing={true}
                                           allowDeleteItem={false}
                                           allowAddItem={false}
                                           onSubmit={form.handleSubmit(onSubmit)}
                                           onReset={onReset}
                            />
                        </Form>
                    </FormProvider>
                </Col>
            </Row>
        </>
    );
};

export default EditBulkItemsForm;
