import React from 'react';
import {Col, Collapse, Form, Row} from "react-bootstrap";
import {Controller, useFormContext} from "react-hook-form";
import WarehouseOperationSelectStatus from "../WarehouseOperationSelectStatus";
import DatePicker from "react-datepicker";
import {addDays} from "date-fns";
import {useSelector} from "react-redux";
import {selectDateFormat, selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import ContractorSelect from "../../../common/ContractorSelect";
import PropTypes from "prop-types";
import WarehouseOperationWarehouseSelect from "../WarehouseOperationWarehouseSelect";

const DatatableHeaderFilter = ({name, contractor, predefinedFilters, isOpenFilterCollapse}) => {

    const dateFormat = useSelector(selectDateFormat);
    const {control} = useFormContext()
    const lang = useSelector(selectLang)
    const t = Translate

    return (
        <>
            <Row className="mb-3 g-3">
                <h5 className="fs-1 mb-0 text-nowrap py-2 py-xl-0">{name}</h5>
            </Row>

            <Collapse in={isOpenFilterCollapse}>
                <Row className="mb-3 g-3">
                    <Form.Group as={Col} md={4} xl={3}>
                        <Form.Label>{t(lang, "warehouse.operation.item.common.sale_status")}</Form.Label>
                        <Controller
                            name="status"
                            control={control}
                            render={({field}) => (
                                <WarehouseOperationSelectStatus
                                    classNamePrefix="react-select"
                                    defaultValue={field.value}
                                    onChange={data => field.onChange(data.value)}
                                />
                            )}
                        />
                    </Form.Group>

                    {
                        contractor && <Form.Group as={Col} md={4} xl={3}>
                            <Form.Label>{t(lang, "warehouse.operation.item.common.client")}</Form.Label>
                            <Controller
                                name="contractor_id"
                                control={control}
                                render={({field}) => {
                                    return (
                                        <ContractorSelect
                                            defaultValue={field?.value}
                                            isClearable={true}
                                            classNamePrefix="react-select"
                                            isDisabled={predefinedFilters.hasOwnProperty('contractor_id')}
                                            onChange={(option) => {
                                                field.onChange(option ? option?.id : null)
                                            }}
                                        />
                                    )
                                }}
                            />
                        </Form.Group>
                    }
                    <Form.Group as={Col} md={4} xl={3}>
                        <Form.Label>{t(lang, "warehouse.operation.item.common.filter.date_start")}</Form.Label>
                        <Controller
                            name="date_time_start"
                            control={control}
                            render={({field}) => (
                                <DatePicker
                                    className='form-control'
                                    placeholderText={t(lang, "warehouse.operation.item.common.filter.date:placeholder")}
                                    dateFormat={`${dateFormat} HH:mm`}
                                    selected={field.value}
                                    onChange={field.onChange}

                                    showTimeSelect
                                    timeFormat="p"
                                    timeIntervals={60}
                                />
                            )}
                        />
                    </Form.Group>
                    <Form.Group as={Col} md={4} xl={3}>
                        <Form.Label>{t(lang, "warehouse.operation.item.common.filter.date_end")}</Form.Label>
                        <Controller
                            name="date_time_end"
                            control={control}
                            render={({field}) => (
                                <DatePicker
                                    className='form-control'
                                    placeholderText={t(lang, "warehouse.operation.item.common.filter.date:placeholder")}
                                    dateFormat={`${dateFormat} HH:mm`}
                                    selected={field.value}
                                    onChange={field.onChange}
                                    maxDate={addDays(new Date(), 5)}

                                    showTimeSelect
                                    timeFormat="p"
                                    timeIntervals={60}
                                />
                            )}
                        />
                    </Form.Group>
                    <Form.Group as={Col} md={4} xl={3}>
                        <Form.Label>{t(lang, "warehouse.navbar.routes.warehouse")}</Form.Label>
                        <Controller name={'warehouse_id'} render={({field}) => (
                            <WarehouseOperationWarehouseSelect
                               onChange={field.onChange}
                               isClearable
                               classNamePrefix="react-select"
                            />
                        )} />
                    </Form.Group>
                </Row>
            </Collapse>
        </>
    );
};

DatatableHeaderFilter.propTypes = {
    predefinedFilters: PropTypes.object,
}

DatatableHeaderFilter.defaultProps = {
    predefinedFilters: {},
}

export default DatatableHeaderFilter;
