import React, {Fragment, useEffect, useRef, useState} from 'react';
import KanbanColumnHeader from "./KanbanColumnHeader";
import * as PropTypes from "prop-types";
import IconButton from "../../common/IconButton";
import AddAnotherForm from "./AddAnotherForm";
import KanbanCard from "./KanbanCard";
import classNames from "classnames";
import StrictModeDroppable from "./StrictModeDroppable";
import {useSelector} from "react-redux";
import {selectKanbanStatusItems} from "../../../app/store/reducers/kanban/kanbanReducer";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";

const KanbanColumn = ({kanbanColumnItem, setShowModal, showModal, setColumnId}) => {

    const lang = useSelector(selectLang);
    const t = Translate;

    const {order, id, name, } = kanbanColumnItem;
    const items = useSelector(selectKanbanStatusItems)

    const [showForm, setShowForm] = useState(false);
    const formViewRef = useRef(null);

    useEffect(() => {
        const timeout = setTimeout(() => {
            formViewRef.current.scrollIntoView({ behavior: 'smooth' });
        }, 500);

        return clearTimeout(timeout);
    }, [showForm]);


    return (
        <div className={classNames('kanban-column', { 'form-added': showForm })}>
            {
                showForm  ?  <AddAnotherForm
                        type="list"
                        defaultValue={name}
                        orderId={order}
                        statusId={id}
                        showForm={showForm}
                        setShowForm={setShowForm}
                    /> :
                    <Fragment>
                        <KanbanColumnHeader setShowForm={setShowForm} id={id} title={name} itemCount={items.filter(i => i?.status_id === id)?.length} />
                        <StrictModeDroppable droppableId={`${id}`} type="KANBAN">
                            {provided => (
                                <>
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        id={`container-${id}`}
                                        className="kanban-items-container scrollbar"
                                    >
                                        {items.filter(i => i?.status_id === id).map((item, index) => (
                                            <KanbanCard
                                                index={index}
                                                columnId={item?.status_id}
                                                key={item.id}
                                                task={item}
                                                showModal={showModal}
                                                setShowModal={setShowModal}
                                            />
                                        ))}
                                        {provided.placeholder}
                                        <div ref={formViewRef}></div>
                                    </div>

                                    <div className="kanban-column-footer">
                                        <IconButton
                                            size="sm"
                                            variant="link"
                                            className="d-block w-100 btn-add-card text-decoration-none text-600"
                                            icon="plus"
                                            iconClassName="me-2"
                                            onClick={() => {
                                                setColumnId(id)
                                                setShowModal(true)
                                            }}
                                        >
                                            {t(lang, 'task.board.add.new-card.button.title')}
                                        </IconButton>
                                    </div>
                                </>
                            )}
                        </StrictModeDroppable>
                    </Fragment>
            }
        </div>
    );
};



export default KanbanColumn;
