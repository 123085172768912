export const labelWrapper = [
    {color_type: 1, bg: 'success'},
    {color_type: 2, bg: 'primary'},
    {color_type: 3, bg: 'info'},
    {color_type: 4, bg: 'danger'},
    {color_type: 5, bg: 'secondary'},
    {color_type: 6, bg: 'warning'}
]

export const statusLabels = (status) => {
    switch (status)  {
        case 1 :
            return 'success'
        case 2 :
            return 'primary'
        case 3 :
            return 'info'
        case 4 :
            return 'danger'
        case 5 :
            return 'secondary'
        case 6 :
            return 'warning'
        default :
            return 'info'
    }
}

export const memberChangeType = (type) => {
    switch (type) {
        case 0 :
            return "task.board.history.datatable.change_type.register"
        case 1 :
            return "task.board.history.datatable.change_type.update"
        case 2 :
            return "task.board.history.datatable.change_type.delete"
    }
}
