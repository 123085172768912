import {Card, Form, InputGroup, Row, Tab, Table, Tabs} from "react-bootstrap";
import {useFormContext} from "react-hook-form";
import {
    currencyActivateAsync, currencyConverterConvert, currencyDeactivate, currencyUpdateRate, selectCurrency
} from "../../app/store/reducers/currency/currencyReducer";
import {useSelector} from "react-redux";
import ReactSelect from "react-select";
import {selectLang, Translate} from "../../app/store/reducers/main/mainReducer";
import {useState} from "react";
import IconButton from "../common/IconButton";


const CurrencySelectForm = () => {
    const {register, getValues} = useFormContext()
    const currency = useSelector(selectCurrency)
    const lang = useSelector(selectLang)
    const t = Translate


    const onChangeSwitch = async (index) => {
        const currencyCheck = getValues(`currency.${index}.is_active`)

        if (!currencyCheck) {
            await currencyActivateAsync({id: currency[index].id})
        } else {
            await currencyDeactivate({id: currency[index].id})
        }
    }

    const UpdateRate = async (id, index) => {
        const currencyRate = +getValues(`currency.${index}.rate`)

        if (currencyRate) {
            await currencyUpdateRate({id, rate: currencyRate})
        }
    }

    return (<Card>
            <Card.Header>
                <Card.Title>{t(lang, "main.common.currency")}</Card.Title>
            </Card.Header>
            <Card.Body>
                <Table responsive striped hover bordered size={"sm"}>
                    <thead>
                    <tr>
                        <th className="text-center fs--1 fw-bold">{t(lang, "main.common.currency.sequence")}</th>
                        <th className="text-center fs--1 fw-bold px-5">{t(lang, "main.common.currency.name")}</th>
                        <th className="text-center fs--1 fw-bold px-5 text-nowrap">{t(lang, "main.common.currency.rate")}</th>
                        <th className="text-center fs--1 fw-bold px-4 text-nowrap">{t(lang, "main.common.activate")}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {currency?.map((cur, index) => {
                        return (<tr key={index}>
                                <td className="text-center">
                                    {index + 1}
                                </td>
                                <td className="text-center">
                                    {cur?.name}
                                </td>
                                <td className="p-0 text-center" style={{maxWidth: "100px"}}>
                                    <InputGroup size={'sm'}>
                                        <Form.Control
                                            type="number"
                                            className={"text-center"}
                                            {...register(`currency.${index}.rate`)}
                                            disabled={cur?.is_national}
                                            defaultValue={cur?.rate}
                                        />
                                        <IconButton variant={"falcon-default"} onClick={() => UpdateRate(cur?.id, index)} disabled={cur?.is_national} icon="check" />
                                    </InputGroup>
                                </td>
                                <td className="text-center">
                                    <Form.Switch
                                        {...register(`currency.${index}.is_active`)}
                                        defaultChecked={cur?.is_active}
                                        className="ms-3"
                                        onClick={() => onChangeSwitch(index)}
                                    />
                                </td>
                            </tr>)
                    })}
                    </tbody>
                </Table>
            </Card.Body>
        </Card>)
}


export default CurrencySelectForm;
