import React, {Fragment, useEffect, useState} from 'react';
import {Button, Col, Collapse, Dropdown, Form, Row} from "react-bootstrap";
import IconButton from "../../../../common/IconButton";
import {useDispatch, useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {FormProvider, useForm} from "react-hook-form";
import {Link} from "react-router-dom";
import SoftBadge from "../../../../common/SoftBadge";
import {
    changeWaybillsV2FilterOptions,
    selectWaybillsV2FilterOptions, waybillAcceptAsync, waybillCancelAsync, waybillDeclineAsync,
    waybillResponsiblePersonAccept, waybillResponsiblePersonGive,
    waybillResponsiblePersonReject, waybillResponsiblePersonReturn,
    waybillResponsiblePersonTillReturn, waybillReturnAcceptAsync
} from "../../../../../app/store/reducers/roaming/roamingWaybillV2Reducer";
import WaybillV2DatatableFilter from "./WaybillV2DatatableFilter";
import dayjs from "dayjs";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faPlus} from "@fortawesome/free-solid-svg-icons";
import {
    RoamingWaybillV2StatusCustomerAccepted,
    RoamingWaybillV2StatusCustomerRejected,
    RoamingWaybillV2StatusExecutorSent, RoamingWaybillV2StatusResponsiblePersonAccepted,
    RoamingWaybillV2StatusResponsiblePersonGiven,
    RoamingWaybillV2StatusResponsiblePersonReturned,
    RoamingWaybillV2StatusResponsiblePersonTillReturned,
    VIEW_OUTBOX_STATUSES
} from "../../../../../enum/roaming/WaybillV2Status";
import WaybillV2DraftDelete from "../forms/subformV2/WaybillV2DraftDelete";
import ActionProgressBar from "../../../../common/ActionProgressBar";
import {RESPONSIBLE_PERSON, VIEW_DRAFTS, VIEW_INBOX, VIEW_OUTBOX} from "../../../../../enum/roaming/WaybillStatus";
import WaybillV2DraftSendAll from "../forms/subformV2/WaybillV2DraftSendAll";
import WaybillV2DraftSend from "../forms/subformV2/WaybillV2DraftSend";
import WaybillV2ConsignorCancel from "../forms/subformV2/WaybillV2ConsignorCancel";
import WaybillV2CustomerAccept from "../forms/subformV2/WaybillV2CustomerAccept";
import WaybillV2CustomerDecline from "../forms/subformV2/WaybillV2CustomerDecline";
import WaybillV2ReturnAccepted from "../forms/subformV2/WaybillV2ReturnAccepted";
import WaybillV2ResponsiblePersonReject from "../forms/subformV2/WaybillV2ResponsiblePersonReject";
import WaybillV2ResponsiblePersonAccept from "../forms/subformV2/WaybillV2ResponsiblePersonAccept";
import WaybillV2ResponsiblePersonTillReturn from "../forms/subformV2/WaybillV2ResponsiblePersonTillReturn";
import WaybillV2ResponsiblePersonGive from "../forms/subformV2/WaybillV2ResponsiblePersonGive";
import WaybillV2ResponsiblePersonReturn from "../forms/subformV2/WaybillV2ResponsiblePersonReturn";
import {selectActiveOrganization} from "../../../../../app/store/reducers/auth/authReducer";
import {DRAFT, VIEWER_CUSTOMER, VIEWER_EXECUTOR, VIEWER_PERSON} from "../../../../../enum/RoamingInvoiceWrapper";
import {useHistory} from "react-router-dom";
import {
    isConsigneeInSelectedItems, isConsignorInSelectedItems,
    isResponsiblePersonInSelectedItems
} from "../../../../../enum/roaming/WaybillV2Wrapper";

const WaybillV2DataTableHeader = ({waybillCount, viewer, loadWaybills, selectedRowIds, page, predefinedFilters, changeFilterOptionsAsync, excelRegistry, waybills}) => {
    const activeOrganization = useSelector(selectActiveOrganization);
    const history = useHistory();
    const [isCollapsed, setIsCollapsed] = useState(false);
    const dispatch = useDispatch();
    const filters = useSelector(selectWaybillsV2FilterOptions);
    const [activeKey, setActiveKey] = useState(0);
    const [statusName, setStatusName] = useState(0);
    const [showProgressBar, setShowProgressBar] = useState(false);
    const [customProgressBarCount, setCustomerProgressBarCount] = useState(null)
    const [bulkActionResult, setBulkActionResult] = useState([]);
    const [isOpenFilterCollapse, setIsOpenFilterCollapse] = useState(false);
    const viewer_inn_or_pinfl = activeOrganization.pinfl || activeOrganization.inn;

    useEffect(() => {
        setStatusName(VIEW_OUTBOX_STATUSES.find(i => i.id === filters.status)?.name)
        setActiveKey(VIEW_OUTBOX_STATUSES.find(i => i.id === filters.status)?.id)
    }, [filters])

    const selectedRowIndexArray = Object.keys(selectedRowIds);

    const selectedWaybills = selectedRowIndexArray.length > 0 ? selectedRowIndexArray.reduce((result, selectedIndex) => {
        result.push(waybills[selectedIndex]);
        return result;
    }, []) : waybills.map(i => i);

    const viewerIsDraft = viewer === VIEW_DRAFTS

    const allowExecutorBulkSign = viewerIsDraft && selectedRowIndexArray.length > 0;
    const allowExecutorBulkDelete = viewerIsDraft && selectedRowIndexArray.length > 0;
    const allowDraftSendAll = viewerIsDraft;
    const allowCustomerBulkActions = viewer === VIEW_INBOX && filters?.status === RoamingWaybillV2StatusExecutorSent && selectedWaybills.length > 0;
    const allowCreateInvoice = viewer === VIEW_OUTBOX && filters?.status === RoamingWaybillV2StatusCustomerAccepted && !!filters?.customer_inn && selectedWaybills.length > 0;

    const allowIsConsignor = !viewerIsDraft && filters.status === RoamingWaybillV2StatusExecutorSent && (selectedWaybills.length > 0 && isConsignorInSelectedItems(selectedWaybills, viewer_inn_or_pinfl));
    const allowIsConsignee = !viewerIsDraft && (filters.status === RoamingWaybillV2StatusResponsiblePersonGiven || filters.status === RoamingWaybillV2StatusExecutorSent) && (selectedWaybills.length > 0 && isConsigneeInSelectedItems(selectedWaybills, viewer_inn_or_pinfl));
    // const isDraftWaybill = !waybill.status && content;
    const allowReturnAccepted = !viewerIsDraft && (filters.status === RoamingWaybillV2StatusResponsiblePersonTillReturned || filters.status === RoamingWaybillV2StatusResponsiblePersonReturned) && selectedWaybills.length > 0

    // responsible-person
    const allowResponsiblePersonRejectedOrAccepted = !viewerIsDraft && filters.status === RoamingWaybillV2StatusExecutorSent && (selectedWaybills.length > 0 && isResponsiblePersonInSelectedItems(selectedWaybills, viewer_inn_or_pinfl))
    const allowResponsiblePersonReturnOrGiven = !viewerIsDraft && filters.status === RoamingWaybillV2StatusResponsiblePersonAccepted && selectedWaybills.length > 0
    const allowResponsiblePersonReturn = !viewerIsDraft && (filters.status === RoamingWaybillV2StatusResponsiblePersonGiven || filters.status === RoamingWaybillV2StatusCustomerRejected) && selectedWaybills.length > 0


    // show progress modal
    const handleShowProgressBar = () => setShowProgressBar(true);
    // bulk action result
    const updateActionResult = (result) => setBulkActionResult(prev => [...prev, result]);
    const handleCloseProgressBar = () => {
        loadWaybills();
        setBulkActionResult([]);
        setShowProgressBar(false);
        setCustomerProgressBarCount(null);
    };

    const methods = useForm({
        customer_inn: null,
        executor_inn: null,
        executor_branch_code: null,
        customer_branch_code: null,
        responsible_person_inn: null,
        number: null,
        registry_id: null,
        contract_number: null,
        contract_date: null,
        date_start: null,
        date_end: null,
        delivery_type: null,
        transport_type: null,
    });
    const t = Translate;
    const lang = useSelector(selectLang);

    const toggleCollapse = () => setIsCollapsed(!isCollapsed);
    const onSubmitFilter = (filter) => {
        const filterDateFormat = "YYYY-MM-DD";
        const filterData = {
            customer_inn: filter.customer_inn || null,
            executor_inn: filter.executor_inn || null,
            executor_branch_code: filter.executor_branch_code || null,
            customer_branch_code: filter.customer_branch_code || null,
            responsible_person_inn: filter.responsible_person_inn || null,
            number: filter.number || null,
            registry_id: filter.registry_id || null,
            contract_number: filter.contract_number || null,
            contract_date: filter.contract_date && dayjs(filter.contract_date).format(filterDateFormat) || null,
            date_start: filter.date_start && dayjs(filter.date_start).format(filterDateFormat) || null,
            date_end: filter.date_end && dayjs(filter.date_end).format(filterDateFormat) || null,
            created_at_start: filter.created_at_start && dayjs(filter.created_at_start).format(filterDateFormat) || null,
            created_at_end: filter.created_at_end && dayjs(filter.created_at_end).format(filterDateFormat) || null,
            delivery_type: filter.delivery_type || null,
            transport_type: filter.transport_type || null,
        }
        if (excelRegistry) {
            changeFilterOptionsAsync({...filters, ...filterData})
        } else {
            dispatch(changeFilterOptionsAsync({...filters, ...filterData}))
        }
    };

    const onCreateInvoice = (waybills) => {
        let link = `/roaming/invoice/send?waybill_v2_id=`;
        waybills.map((waybill, index) => {
            link += waybill.id;
            if (index !== waybills.length - 1)
                link += ",";
        });
        history.push(link);
    };

    return (
        <Fragment>
            <ActionProgressBar show={showProgressBar}
                               result={bulkActionResult}
                               count={customProgressBarCount || selectedWaybills.length}
                               onClose={handleCloseProgressBar}
            />

            <Row className="flex-between-center align-items-center">

                <Col sm={12}>
                    <FormProvider {...methods}>
                        <Form onSubmit={methods.handleSubmit(onSubmitFilter)}>
                            <WaybillV2DatatableFilter isCollapsed={isCollapsed} viewer={viewer} predefinedFilters={predefinedFilters}/>
                        </Form>
                    </FormProvider>
                </Col>

                <Col xs={4} sm="auto" className="d-flex flex-column pe-0">
                    <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">{t(lang, "edi.navbar.routes.edo.waybill.v2")}</h5>
                    <div className="mt-1">
                        {t(lang, "roaming.common.count")}:
                        <SoftBadge bg='success' className='ms-2'>
                            {waybillCount}
                        </SoftBadge>
                    </div>
                </Col>
                <Col xs={8} sm="auto" className="d-flex justify-content-end flex-wrap gap-2 ms-auto text-end ps-0">

                    {
                        allowResponsiblePersonRejectedOrAccepted && <Fragment>
                            <WaybillV2ResponsiblePersonReject
                                waybills={selectedWaybills}
                                handleShowProgressBar={handleShowProgressBar}
                                updateActionResult={updateActionResult}
                                requestApi={waybillResponsiblePersonReject}
                                btnText={"roaming.waybill.v2.title.responsible.person.rejected"}
                                variant={"falcon-danger"}
                                mTitle={"roaming.waybill.v2.responsible.person.reject"}
                            />

                            <WaybillV2ResponsiblePersonAccept
                                waybills={selectedWaybills}
                                handleShowProgressBar={handleShowProgressBar}
                                updateActionResult={updateActionResult}
                                requestApi={waybillResponsiblePersonAccept}
                                btnText={"roaming.waybill.v2.title.responsible.person.accepted"}
                                variant={'falcon-success'}
                            />
                        </Fragment>
                    }

                    {
                        allowResponsiblePersonReturnOrGiven && <Fragment>
                            <WaybillV2ResponsiblePersonTillReturn
                                waybills={selectedWaybills}
                                handleShowProgressBar={handleShowProgressBar}
                                updateActionResult={updateActionResult}
                                requestApi={waybillResponsiblePersonTillReturn}
                                btnText={"roaming.waybill.v2.title.responsible.person.returned"}
                                variant={'falcon-danger'}
                                mTitle={"roaming.waybill.v2.responsible.person.till.return"}
                            />

                            <WaybillV2ResponsiblePersonGive
                                waybills={selectedWaybills}
                                handleShowProgressBar={handleShowProgressBar}
                                updateActionResult={updateActionResult}
                                requestApi={waybillResponsiblePersonGive}
                                btnText={"roaming.waybill.send.additional_information.giver_fio"}
                            />
                        </Fragment>
                    }

                    {
                        allowResponsiblePersonReturn && <WaybillV2ResponsiblePersonReturn
                            waybills={selectedWaybills}
                            handleShowProgressBar={handleShowProgressBar}
                            updateActionResult={updateActionResult}
                            requestApi={waybillResponsiblePersonReturn}
                            btnText={"roaming.waybill.v2.title.responsible.person.returned"}
                            variant={'falcon-danger'}
                            mTitle={"roaming.waybill.v2.responsible.person.till.return"}
                        />
                    }

                    {allowReturnAccepted && <WaybillV2ReturnAccepted
                        size={"sm"}
                        requestApi={waybillReturnAcceptAsync}
                        waybills={selectedWaybills}
                        handleShowProgressBar={handleShowProgressBar}
                        updateActionResult={updateActionResult}
                    />}

                    {allowIsConsignor && <WaybillV2ConsignorCancel
                        size={"sm"}
                        requestApi={waybillCancelAsync}
                        waybills={selectedWaybills}
                        handleShowProgressBar={handleShowProgressBar}
                        updateActionResult={updateActionResult}
                        btnText={'roaming.free_form_document.view.action.cancel'}
                        variant={"falcon-default"}
                    />}

                    {allowIsConsignee && <Fragment>
                        <WaybillV2CustomerAccept
                            size={"sm"}
                            requestApi={waybillAcceptAsync}
                            waybills={selectedWaybills}
                            handleShowProgressBar={handleShowProgressBar}
                            updateActionResult={updateActionResult}
                            btnText={"roaming.waybill.v2.title.consignee.accepted"}
                        />
                        <WaybillV2CustomerDecline
                            size={"sm"}
                            requestApi={waybillDeclineAsync}
                            waybills={selectedWaybills}
                            handleShowProgressBar={handleShowProgressBar}
                            updateActionResult={updateActionResult}
                            btnText={"roaming.waybill.v2.title.consignee.rejected"}
                            variant={"falcon-danger"}
                        />
                    </Fragment>}

                    {allowExecutorBulkDelete && <WaybillV2DraftDelete
                        hasTitle
                        size={"sm"}
                        waybills={selectedWaybills}
                        handleShowProgressBar={handleShowProgressBar}
                        updateActionResult={updateActionResult}
                    />}

                    {allowExecutorBulkSign && <WaybillV2DraftSend
                        waybills={selectedWaybills}
                        handleShowProgressBar={handleShowProgressBar}
                        updateActionResult={updateActionResult}
                        size="sm"
                    />}

                    {allowDraftSendAll &&
                        <WaybillV2DraftSendAll
                            handleShowProgressBar={handleShowProgressBar}
                            updateActionResult={updateActionResult}
                            filters={filters}
                            predefinedFilters={predefinedFilters}
                            setCount={setCustomerProgressBarCount}
                            size="sm"
                        />
                    }

                    {allowCreateInvoice &&
                        <IconButton size="sm"
                                    variant="falcon-primary"
                                    icon={faPlus}
                                    onClick={() => onCreateInvoice(selectedWaybills)}
                        >
                            {t(lang, 'edi.common.new_invoice_button')}
                        </IconButton>
                    }

                    {viewer !== "drafts" && <Dropdown>
                        <Dropdown.Toggle
                            variant="falcon-default"
                            className="py-0 px-2 dropdown-caret-none text-decoration-none h-100 d-flex align-items-center">
                            <FontAwesomeIcon icon={faChevronDown} className="me-2 fs--2"/>
                            <span
                                className={'fs--1'}>{t(lang, statusName ? statusName : "roaming.waybill.v2.status.all")}</span>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className='py-2'>
                            {VIEW_OUTBOX_STATUSES.map((status, index) => (
                                <Dropdown.Item key={index} onClick={() => {
                                    setActiveKey(status.id)
                                    setStatusName(status.name)
                                    dispatch(changeWaybillsV2FilterOptions({
                                        ...filters,
                                        status: status.id !== 0 ? status.id : null
                                    }))
                                }}>
                                    <span
                                        className={`${status.id === activeKey ? 'text-primary fw-semi-bold' : ''}`}>{t(lang, status.name)}</span>
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>}

                    <IconButton
                        as={Link}
                        // as={dispatch(checkPermission(PermissionRoamingShipmentCreate)) ? Link : 'div'}
                        // onClick={()=>!dispatch(checkPermission(PermissionRoamingShipmentCreate)) && dispatch(toastPermission())}
                        to="/roaming/waybill-v2/send"
                        variant="falcon-default"
                        size="sm"
                        icon="plus"
                        transform="shrink-3"
                    >
                        <span className="d-none d-sm-inline-block ms-1">
                            {t(lang, "roaming.waybill.datatable.header.navigation.new_waybill")}
                        </span>
                    </IconButton>

                    <IconButton
                        size="sm"
                        icon="refresh"
                        transform="shrink-3"
                        variant="falcon-default"
                        onClick={methods.handleSubmit(onSubmitFilter)}
                    >
                            <span className="d-none d-sm-inline-block ms-1">
                                {t(lang, "roaming.waybill.datatable.header.navigation.refresh")}
                            </span>
                    </IconButton>

                    <IconButton
                        size="sm"
                        icon="filter"
                        transform="shrink-3"
                        variant="falcon-default"
                        onClick={toggleCollapse}
                    >
                            <span className="d-none d-sm-inline-block ms-1">
                                {t(lang, "roaming.waybill.datatable.header.navigation.filter")}
                            </span>
                    </IconButton>
                </Col>
            </Row>

        </Fragment>
    );
};

export default WaybillV2DataTableHeader;
