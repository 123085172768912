import React, {useEffect, useState} from 'react';
import ReactSelect from "react-select";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../../app/store/reducers/main/mainReducer";

const SelectVillage = ({options, onChange, defaultVillageCode, isDisabled, ...props}) => {

    const lang = useSelector(selectLang);
    const t = Translate;
    const [value, setValue] = useState(null)

    useEffect(() => {
        setValue(options.find(i => i.code === defaultVillageCode) || null)
    }, [options, defaultVillageCode]);

    const handleChange = (option) => {
        onChange(option)
        setValue(option)
    }

    return (
        <ReactSelect
            value={value}
            options={options}
            onChange={(option) => handleChange(option)}
            classNamePrefix={'react-select'}
            placeholder={t(lang, "roaming.common.select.placeholder")}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.code}
            hideSelectedOptions
            {...props}
        />
    );
};

export default SelectVillage;
