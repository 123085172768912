import PropTypes from "prop-types";
import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import IconButton from "../../common/IconButton";
import {Button, Form, Modal, Spinner} from "react-bootstrap";
import {Translate, selectLang} from "../../../app/store/reducers/main/mainReducer";
import {
    customerShipmentApproveAsync,
    executorApproveShipmentAsync
} from "../../../app/store/reducers/shipment/shipmentReducer";
import checkPermission from "../../../enum/Permission/CheckPermission";
import {PermissionEdiShipmentApprove} from "../../../enum/Permission/EdiPermission";
import toastPermission from "../../../enum/Permission/ToastPermission";
import {
    AwaitExecutorApprove,
    CustomerEditApprovedByExecutor,
    CustomerEdited,
    ExecutorApproved,
    ExecutorEdited
} from "../../../enum/ShipmentStatus";
import {selectActiveOrganization} from "../../../app/store/reducers/auth/authReducer";
import {AfterConfirmByCustomer} from "../../../app/order/OrderExecutorCreateInvoiceStrategyType";
import SelectCertificateWrapperComponent from "../roaming/components/SelectCertificateWrapperComponent";
import {selectCurrentCertificate} from "../../../app/store/reducers/eimzo/eimzoReducer";
import {TYPE_CREATE_BULK_APPROVE} from "./ShipmentDataTableHeader";
import {useParams} from "react-router";
import {EDI_VIEWER_CUSTOMER} from "../../../enum/edi/ediViewer";

export const allowExecutor = (activeOrganization, shipment) => {
    return  activeOrganization.inn === shipment.executor_info.inn && shipment.order.executor_create_invoice_strategy === AfterConfirmByCustomer.id &&
        (
            //AwaitExecutorApprove
            shipment.status === AwaitExecutorApprove ||

            //ExecutorEdited
            shipment.status === ExecutorEdited ||

            //CustomerEdited
            shipment.status === CustomerEdited
        )
}

export const allowCustomer = (activeOrganization, shipment) => {
    return activeOrganization.inn === shipment.customer_info.inn &&
        (
            //ExecutorApproved
            shipment.status === ExecutorApproved ||

            //ExecutorEdited
            shipment.status === ExecutorEdited ||

            //CustomerEditApprovedByExecutor
            shipment.status === CustomerEditApprovedByExecutor
        )
}

const ShipmentCustomerApprove = ({
                                     id,
                                     shipments,
                                     handleShowProgressBar,
                                     updateActionResult,
                                     isCustomer,
                                     ...props}) => {
    const {viewer} = useParams()
    const dispatch = useDispatch()
    const [comment, setComment] = useState('');
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const activeCertificate = useSelector(selectCurrentCertificate);
    const lang = useSelector(selectLang)
    const t = Translate
    const activeOrganization = useSelector(selectActiveOrganization);

    const handleShow = () => {
        if (dispatch(checkPermission(PermissionEdiShipmentApprove))) setShow(true);
        else dispatch(toastPermission())
    }
    const handleClose = () => {
        setLoading(false);
        setShow(false);
    }

    const onCustomerApproveShipment = () => {
        setLoading(true);
        if (shipments?.length > 0) {
            bulkApprove()
        } else {
            singleApprove()
        }
    }



    const actionResult = (payload) => {
        updateActionResult(payload);
    }

    const bulkApprove = async () => {
        handleShowProgressBar(TYPE_CREATE_BULK_APPROVE);

        for (let i = 0; i < shipments.length; i++) {
            const shipment = shipments[i];
            const allowExecutorApprove = allowExecutor(activeOrganization, shipment)
            const allowCustomerApprove = allowCustomer(activeOrganization, shipment)

            const payload = {
                id: shipment.id,
                comment: comment
            }

            const updateActionResultPayload = {
                number: shipment?.contract_info?.number,
                date: shipment?.contract_info?.date,
                contractor: {
                    name: shipment?.customer_info?.name,
                    inn: shipment?.customer_info?.inn
                }
            }

            try {
                if (allowExecutorApprove) {
                    await executorApproveShipmentAsync(activeCertificate, lang, payload)
                        .then(() => actionResult(updateActionResultPayload))
                        .catch((error) => actionResult({...updateActionResultPayload, error: t(lang, error.response?.data?.roaming_error_message) || error}))
                } else if (allowCustomerApprove) {
                    await customerShipmentApproveAsync(payload)
                        .then(() => actionResult(updateActionResultPayload))
                        .catch(() => actionResult({...updateActionResultPayload, error: t(lang, 'roaming.empowerment.alert.error.getAcceptHashCode')}))
                }
            } catch (e) {
                updateActionResult({
                    number: shipment?.contract_info?.number,
                    date: shipment?.contract_info?.date,
                    contractor: {
                        name: shipment?.customer_info?.name,
                        inn: shipment?.customer_info?.inn
                    },
                    error: e?.message
                })
            }
        }
        setLoading(false);
    }

    const singleApprove = () => {
        customerShipmentApproveAsync({
            id: id,
            comment: comment
        })
            .then(() => handleClose())
            .catch((error) => console.log(error))
            .finally(() => setLoading(false))
    }

    return (
        <>
            {(viewer === EDI_VIEWER_CUSTOMER || isCustomer) ?
                <IconButton
                    id={'approve-bulk-shipment'}
                    onClick={handleShow}
                    variant="falcon-primary"
                    size="sm"
                    icon="check"
                    transform="shrink-3"
                >
                        <span
                            className="d-none d-sm-inline-block">{t(lang, "edi.shipments.shipment.navigation.button_customer_approve")}</span>
                </IconButton> :
                <SelectCertificateWrapperComponent
                submitButton={
                    <IconButton
                        id={'approve-bulk-shipment'}
                        onClick={handleShow}
                        variant="falcon-primary"
                        size="sm"
                        icon="check"
                        transform="shrink-3"
                    >
                        <span
                            className="d-none d-sm-inline-block">{t(lang, "edi.shipments.shipment.navigation.button_customer_approve")}</span>
                    </IconButton>
                }
                openDialogButtonText={t(lang, 'edi.common.approve')}
            />}

            <Modal size="md" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t(lang, "edi.shipments.shipment.navigation.modal_customer_approve.title")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>{t(lang, 'edi.common.label.comment')}</Form.Label>
                        <Form.Control
                            name="Name"
                            as="textarea" rows={3}
                            placeholder={t(lang, 'edi.common.leave_comment')}
                            onChange={(e) => setComment(e.target.value)}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        handleClose()
                    }}>{t(lang, "edi.shipments.shipment.navigation.modal_customer_approve.button_close")}</Button>
                    <Button variant="primary" className="d-flex align-items-center" disabled={loading}
                            onClick={onCustomerApproveShipment}>
                        {loading && <Spinner className="me-1" animation="border" role="status" size="sm"/>}
                        {t(lang, "edi.shipments.shipment.navigation.modal_customer_approve.button_approve")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

ShipmentCustomerApprove.propTypes = {
    id: PropTypes.string,
}

ShipmentCustomerApprove.defaultProps = {
    id: ''
}

export default ShipmentCustomerApprove;
