import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../../../app/store/reducers/auth/authReducer";
import React, {useEffect, useState} from "react";
import {useRouteMatch} from "react-router-dom";
import {loadActDraftAsync} from "../../../../../app/store/reducers/roaming/roamingActReducer";
import DraftActForm from "./DraftActForm";
import {useHistory} from "react-router";
import {selectAccountRolePermissions} from "../../../../../app/store/reducers/role/roleReducer";
import checkPermission from "../../../../../enum/Permission/CheckPermission";
import {
    PermissionRoamingActUpdate,
} from "../../../../../enum/Permission/RoamingPermission";


const DraftActEdit = () => {
    const activeOrganization = useSelector(selectActiveOrganization)
    const [draftAct, setDraftAct] = useState(null);
    const {params: {id}} = useRouteMatch();
    const history = useHistory();

    const dispatch = useDispatch()
    const permission = useSelector(selectAccountRolePermissions)

    useEffect(() => {
        if (permission.length && !dispatch(checkPermission(PermissionRoamingActUpdate))) {
            if (history.length > 1) {
                history.push('/roaming/acts/draft')
            } else {
                history.push('/')
            }
        }
    }, [permission])

    useEffect(() => {
        loadDraftAct()
    }, [id, activeOrganization]);


    const loadDraftAct = async () => {
        try {
            const  getDraftAct = await loadActDraftAsync(id)
            {setDraftAct(JSON.parse(getDraftAct?.data?.content))}
        }catch (error) {
            history.push("../register")
        }
    }


    return (
        <>
            {draftAct && <DraftActForm draftAct={draftAct}/>}
            {!draftAct && <span>loading....</span>}
        </>
    )
}


export default DraftActEdit;
