import {Dropdown, Nav} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useSelector} from "react-redux";
import MemberDropdownSelect from "../../form/taskCard/MemberDropdownSelect";
import SelectDropdownLabels from "../../form/taskCard/SelectDropdownLabels";
import {Controller, useFormContext} from "react-hook-form";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import classNames from "classnames";
import ConfirmationModal from "../ConfirmationModal";
import {Fragment, useRef} from "react";

const ModalSidebar = ({labelFields, addLabels, edit, assigner, confirmation, itemId}) => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const {formState: {errors}} = useFormContext();

    const ref = useRef(null)

    return (
        <Fragment>
            <h6 className="mt-5 mt-lg-0">{t(lang, 'task.board.modal.sidebar.title')}</h6>

            <Nav className="flex-lg-column fs--1">
                <Nav.Item className={"me-2 me-lg-0"}>
                    <Dropdown>
                        <Dropdown.Toggle
                            disabled={edit}
                            as={Nav.Link}
                            className={classNames("border dropdown-caret-none nav-link-card-details", {
                                'border-danger': errors?.reporter_id
                            })
                        }>
                            <FontAwesomeIcon icon={'user'} className="me-2" />
                            <span>{t(lang,'task.board.modal.reporter')}</span>
                        </Dropdown.Toggle>
                        <Controller
                            rules={{required: true}}
                            name={edit ? 'reporter' : 'reporter_id'} render={({field}) => (
                            <MemberDropdownSelect onChange={field.onChange} value={field.value}  />
                        )} />
                    </Dropdown>
                </Nav.Item>

                <Nav.Item className="me-2 me-lg-0">
                    {edit ?
                        <ConfirmationModal
                            title={'task.board.common.edit'}
                            titleLabel={'task.board.member'}
                            icon={'user'}
                            update
                            register={'task.board.modal.assigner'}
                            disabled={assigner}
                            style={{paddingLeft: 10}}
                            confirmationAction={confirmation}
                            className={'d-flex w-100 border align-items-center shadow-none nav-link-card-details mx-0 mt-0 mb-1 py-2 pe-1 fs-0'}
                        />
                        : <Dropdown>
                            <Dropdown.Toggle
                                as={Nav.Link}
                                className={"border dropdown-caret-none nav-link-card-details"}
                            >
                                <FontAwesomeIcon icon={'user'} className="me-2"/>
                                <span> {t(lang, 'task.board.modal.assigner')}</span>
                            </Dropdown.Toggle>
                            <Controller
                                name={edit ? 'assigner' : 'assigner_id'} render={({field}) => (
                                <MemberDropdownSelect onChange={field.onChange} value={field.value}/>
                            )}/>
                    </Dropdown>}
                </Nav.Item>

                <Nav.Item className={'me-2 me-lg-0'}>
                    <Dropdown ref={ref} id={'tag-id'}>
                        <Dropdown.Toggle disabled={edit} as={Nav.Link} className="border dropdown-caret-none nav-link-card-details">
                            <FontAwesomeIcon icon={'tag'} className="me-2" />
                            {t(lang, 'task.board.modal.label')}
                        </Dropdown.Toggle>
                        <SelectDropdownLabels
                            itemId={itemId}
                            addLabels={addLabels}
                            fields={labelFields}
                        />
                    </Dropdown>
                </Nav.Item>
            </Nav>
        </Fragment>
    );
};

export default ModalSidebar;
