import React, {Fragment, useEffect} from 'react';
import CashBoxesDatatable from "../../../../components/hippo/cashbox/cash-boxes/CashBoxesDatatable";
import {useDispatch, useSelector} from "react-redux";
import {getAllCashboxAsync} from "../../../../app/store/reducers/cashbox/CashboxReducer";
import {getOrganizationUsersAsync, selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import EventBus from "../../../../app/eventbus/EventBus";
import {
    CASHBOX_BINDING_SUCCESS,
    CASHBOX_DELETE_SUCCESS,
    CASHBOX_REGISTER_SUCCESS,
    CASHBOX_UPDATE_SUCCESS
} from "../../../../app/eventbus/cashbox/cashboxEvents";
import {getAccount} from "../../../../app/store/reducers/crm/bind-contractor-to-account/bindContractorToAccountReducer";

const CashBoxes = () => {

    const dispatch = useDispatch()
    const organization = useSelector(selectActiveOrganization);

    useEffect(() => {
        dispatch(getOrganizationUsersAsync())
    }, [organization])

    useEffect(() => {
        const cashboxRegisterSuccess =  EventBus.on(CASHBOX_REGISTER_SUCCESS, () => {
            dispatch(getAllCashboxAsync())
        })

        const cashboxUpdateSuccess =  EventBus.on(CASHBOX_UPDATE_SUCCESS, () => {
            dispatch(getAllCashboxAsync())
        })

        const cashboxDeleteSuccess =  EventBus.on(CASHBOX_DELETE_SUCCESS, () => {
            dispatch(getAllCashboxAsync())
        })



        return () => {
            EventBus.remove(CASHBOX_REGISTER_SUCCESS, cashboxRegisterSuccess)
            EventBus.remove(CASHBOX_UPDATE_SUCCESS, cashboxUpdateSuccess)
            EventBus.remove(CASHBOX_DELETE_SUCCESS, cashboxDeleteSuccess)
        }
    }, [])

    return (
        <CashBoxesDatatable />
    );
};

export default CashBoxes;
