import React from 'react';
import {Form, InputGroup} from "react-bootstrap";
import classNames from "classnames";
import IconButton from "../../../../common/IconButton";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {ErrorMessage} from "@hookform/error-message";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {Controller, useFieldArray, useFormContext} from "react-hook-form";
import itemWrapper from "../../enum/itemWrapper";


const barcodeRegExp = /^[0-9]{0,13}$/;
const BarcodeRow = ({fieldName, id, checkIsExistingItem, onRightClickHandler}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const form = useFormContext();
    const {error} = form.getFieldState(fieldName, form.formState);

    const {
        fields: barcodes,
        append: appendBarcode,
        remove: removeBarcode
    } = useFieldArray({name: `${fieldName}`});

    return (
        <>
            <h6 className="text-info cursor-pointer d-inline-block" onClick={() => appendBarcode({barcode: itemWrapper.generateGtinBarcode(new Date().getTime().toString().slice(5, 13))})}>{t(lang, 'items.common.add_barcode')}</h6>
            {barcodes.map((barcode, index) => {
                return (
                    <Form.Group className="mb-2" key={barcode.id}>
                        {/*{index === 0 && <Form.Label>*/}
                        {/*    {t(lang, 'items.common.barcode')}*/}
                        {/*    <span className="text-danger">*</span>*/}
                        {/*</Form.Label>}*/}
                        <Controller name={`${fieldName}.${index}.barcode`}
                                    rules={{
                                        required: t(lang, 'items.common.validations.is_required'),
                                        pattern: {
                                            value: barcodeRegExp,
                                            message: t(lang, "items.common.validations.invalid_barcode")
                                        },
                                    }}
                                    render={({field}) => {
                                        const isExistingBarcode = checkIsExistingItem(null, null, field.value, id);
                                        return (
                                            <>
                                                <InputGroup className={classNames({'is-invalid': error?.barcode || isExistingBarcode})}>
                                                    <Form.Control placeholder={t(lang, 'items.common.barcode.placeholder')}
                                                                  name={field.name}
                                                                  isInvalid={error?.barcode || isExistingBarcode}
                                                                  value={field.value}
                                                                  onChange={field.onChange}
                                                                  onContextMenu={onRightClickHandler}

                                                    />
                                                    <IconButton
                                                        variant="falcon-default"
                                                        size="sm"
                                                        icon={faTrash}
                                                        iconClassName={"text-danger"}
                                                        onClick={() => removeBarcode(index)}
                                                    />
                                                </InputGroup>
                                                <ErrorMessage name='barcode'
                                                              errors={error}
                                                              as={<Form.Control.Feedback type="invalid"/>}
                                                />
                                                {(!error?.barcode && isExistingBarcode) && <Form.Control.Feedback type="invalid">{t(lang, "items.common.validations.existing_barcodes", {barcode: field.value})}</Form.Control.Feedback>}
                                            </>
                                        )
                                    }}
                        />
                    </Form.Group>
                )
            })}
        </>
    );
};

export default BarcodeRow;