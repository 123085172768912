import React from 'react';
import {ListGroup} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {
    selectAccount,
    selectActiveOrganization,
    selectIsLoginWithCertificate, selectOrganizations,
    switchActiveOrganization
} from "../../../app/store/reducers/auth/authReducer";
import {Link} from "react-router-dom";
import classNames from "classnames";
import PropTypes from 'prop-types';

const Organization = ({organization, onClick}) => {
    const onClicked = e => {
        e.preventDefault();
        onClick(organization);
    };

    return (
        <Link to="#" className={classNames('notification rounded-0', {'bg-200': organization.isActive})}
              onClick={onClicked}>
            <div className="notification-body">
                <p className="mb-1 fw-bold text-dark">
                    {organization.name}
                </p>
                <span className="notification-time">
                    {organization.inn}
                </span>
            </div>
        </Link>
    )
};

Organization.propTypes = {
    organization: PropTypes.shape({
        isActive: PropTypes.bool,
        name: PropTypes.string,
        inn: PropTypes.string
    }).isRequired,
    onClick: PropTypes.func.isRequired
}

const OrganizationChooser = (props) => {
    const isLoginWithCertificate = useSelector(selectIsLoginWithCertificate);
    const activeOrganization = useSelector(selectActiveOrganization);
    const organizations = useSelector(selectOrganizations);
    const dispatch = useDispatch();

    const onOrganizationClicked = organization => {
        dispatch(switchActiveOrganization(organization));
    }

    return (
        <ListGroup variant="flush" className="fw-normal fs--1 scrollbar pb-3" {...props} style={{maxHeight: '25rem'}}>
            {organizations.map(organization => {
                if (organization.inn === activeOrganization.inn && isLoginWithCertificate === 1) {
                    return (
                        <ListGroup.Item key={organization.id} className="border-0">
                            <Organization organization={organization} onClick={onOrganizationClicked}/>
                        </ListGroup.Item>
                    )
                }
                else if(isLoginWithCertificate === 0) {
                    return (
                        <ListGroup.Item key={organization.id} className="border-0">
                            <Organization organization={organization} onClick={onOrganizationClicked}/>
                        </ListGroup.Item>
                    )
                }
            })}
        </ListGroup>
    );
};

export default OrganizationChooser;