import React from 'react';
import ReactSelect from "react-select";
import {useSelector} from "react-redux";
import {selectMembers} from "../../app/store/reducers/kanban/kanbanReducer";

const SelectMember = ({value, onChange, ...props}) => {
    const members = useSelector(selectMembers)

    return (
        <ReactSelect
            options={members}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            value={members.find(i => i.id === value)}
            onChange={(option) => onChange(option?.id)}
            {...props}
        />
    );
};

export default SelectMember;
