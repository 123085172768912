import React, {Fragment, useEffect} from 'react';
import KanbanHeader from "./KanbanHeader";
import KanbanContainer from "./KanbanContainer";
import {useDispatch, useSelector} from "react-redux";
import {clearBoardStatusItems, itemLoader, selectKanbanItems} from "../../../app/store/reducers/kanban/kanbanReducer";
import {Spinner} from "react-bootstrap";
import SpinnerComponent from "../../hippo/spinner/SpinnerComponent";

const KanbanProvider = () => {

    const loading = useSelector(itemLoader)

    const style = {
        width: '100vh',
        height: '100vh',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)'
    }


    return (
        <Fragment>
            <KanbanHeader/>
            <KanbanContainer loading={loading}/>
            {loading &&
                <div style={style} className={'position-absolute d-flex align-items-center justify-content-center w-100'}>
                    <Spinner className='align-middle me-2' animation='border' role='switch' size='sm'/>
                </div>}
        </Fragment>
    );
};

export default KanbanProvider;
