import React, {useEffect, useState} from 'react';
import ReactSelect from "react-select";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {
    loadWaybillRegionsAsync,
    selectWaybillRegions
} from "../../../../app/store/reducers/roamingReference/roamingReferenceReducer";

const WaybillRegionSelector = ({regionCode, onChange, ...props}) => {
    // const regions = useSelector(selectWaybillRegions);
    const lang = useSelector(selectLang);

    const [regions, setRegions] = useState([])

    useEffect(() => {
        loadWaybillRegions();
    }, [])

    const loadWaybillRegions = () => {
        loadWaybillRegionsAsync()
            .then(res => setRegions(res))
            .catch(() => setRegions([]))
    }

    const onSelectRegion = selectedRegion => {
        onChange(selectedRegion);
    };

    return (
        <ReactSelect
            options={regions}
            hideSelectedOptions={true}
            classNamePrefix={'react-select'}
            getOptionValue={option => option.code}
            getOptionLabel={option => option.name}
            onChange={newValue => onSelectRegion(newValue)}
            value={regions.find(r => r.code === regionCode) || null}
            placeholder={Translate(lang, "roaming.common.select.placeholder")}
            {...props}
        />
    );
};

export default WaybillRegionSelector;
