import React, {useState} from 'react';
import {FormProvider, useForm} from "react-hook-form";
import {useSelector} from "react-redux";
import {Form} from "react-bootstrap";
import ModalForm from "./ModalForm";
import {
    itemRegister,
    selectKanbanItems,
    selectKanbanStatusItems
} from "../../../../app/store/reducers/kanban/kanbanReducer";
import PropTypes from "prop-types";

const FormTask = ({id, setShow}) => {
    const kanbanItems = useSelector(selectKanbanItems)
    const kanbanStatusItems = useSelector(selectKanbanStatusItems)
    const [loading, setLoading] = useState(false)

    const methods = useForm({
        defaultValues: {
            title: '',
            description: '',
            assigner_id: null,
            reporter_id: '',
            attachments: [],
            labels: [],
            comments: []
        }
    })

    const onSubmit = (formData) => {

        setLoading(true)
        const targetList = kanbanItems?.find(item => item.id === id);
        const createOrder = kanbanStatusItems.length ? Math.max(...kanbanStatusItems.map(i => i.order)) + 1 : 1
        const newCard = {...formData, status_id: targetList?.id, order: createOrder};

        itemRegister(newCard)
            .then(() => setShow(false))
            .catch(e => new Error(e))
            .finally(() => setLoading(false))
    }

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <ModalForm loading={loading} itemId={id}/>
            </Form>
        </FormProvider>
    );
};

FormTask.propTypes = {
    // id: PropTypes.number | PropTypes.string,
    setShow: PropTypes.func
}
export default FormTask;
