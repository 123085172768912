import {useDispatch, useSelector} from "react-redux";
import {useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import IconButton from "../../../../common/IconButton";
import {Form, Modal} from "react-bootstrap";
import FalconCloseButton from "../../../../common/FalconCloseButton";
import TransferDefaultSettingsForm from "./TransferDefaultSettingsForm";
import {
    selectTransferDefaultsSettings,
    setTransferDefaultsSettings
} from "../../../../../app/store/reducers/warehouse-operation/transferReducer";


const TransferSettings = () => {
    const transferDefaultsSettings = useSelector(selectTransferDefaultsSettings)
    const dispatch = useDispatch()
    const [show, setShow] = useState(false)
    const form = useForm({
        defaultValues: {
            defaultEmployeeId: null,
            priceTypeOfInputOption: null,
            bulkPriceCommon: null,
            showItemSearchOptions: 20,
            numbersAfterDecimalPoint: 4,
            defaultWarehouseId: null,
            isCheckedViewCategories: false,
            isCheckedViewDateTime: false,
            isCheckedViewNumber: false,
            operationItemExtraInfo: null,
            showOperationItemPurchasePrice: false,
        }
    })


    const handleShow = () => {
        setShow(true)
        form.setValue("defaultEmployeeId", transferDefaultsSettings?.defaultEmployeeId)
        form.setValue("priceTypeOfInputOption", transferDefaultsSettings?.priceTypeOfInputOption)
        form.setValue("bulkPriceCommon", transferDefaultsSettings?.bulkPriceCommon)
        form.setValue("showItemSearchOptions", transferDefaultsSettings?.showItemSearchOptions)
        form.setValue("numbersAfterDecimalPoint", transferDefaultsSettings?.numbersAfterDecimalPoint)
        form.setValue("defaultWarehouseId", transferDefaultsSettings?.defaultWarehouseId)
        form.setValue("isCheckedViewCategories", transferDefaultsSettings?.isCheckedViewCategories)
        form.setValue("isCheckedViewDateTime", transferDefaultsSettings?.isCheckedViewDateTime)
        form.setValue("isCheckedViewNumber", transferDefaultsSettings?.isCheckedViewNumber)
        form.setValue("operationItemExtraInfo", transferDefaultsSettings?.operationItemExtraInfo)
        form.setValue("showOperationItemPurchasePrice", transferDefaultsSettings?.showOperationItemPurchasePrice)
    }


    const handleClose = () => {
        setShow(false)
    }


    const onSubmit = (formData) => {
        dispatch(setTransferDefaultsSettings(JSON.stringify(formData)))
        handleClose()
    }



    return(
        <>
            <IconButton
                icon="gear"
                onClick={handleShow}
                variant="falcon-primary"
                size="sm"
                className="mx-2"
            />
            <Modal show={show} onHide={handleClose} size="xl">
                <Modal.Header>
                    <Modal.Title>
                        {/*Сотув созламалари*/}
                        Настройки
                    </Modal.Title>
                    <FalconCloseButton onClick={handleClose}/>
                </Modal.Header>
                <Modal.Body>
                    <FormProvider {...form}>
                        <Form onSubmit={event => {
                            event.stopPropagation();
                            return form.handleSubmit(onSubmit)(event);
                        }}>
                            <TransferDefaultSettingsForm/>
                        </Form>
                    </FormProvider>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default TransferSettings;
