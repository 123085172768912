import React, {useEffect, useState} from 'react';
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useDispatch, useSelector} from "react-redux";
import {Button, Col, Collapse, Form, Row} from "react-bootstrap";
import SelectWarehouse from "../components/SelectWarehouse";
import {selectIsFilterCollapsed, updateIsFilterCollapsed} from "../../../../app/store/reducers/item/itemReducer";
import SelectCategory from "../components/SelectCategory";
import {selectCategories} from "../../../../app/store/reducers/category/categoryReducer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilter} from "@fortawesome/free-solid-svg-icons";
import SelectBranch from "../../../common/SelectBranch";
import SortSelector from "./SortSelector";
import SelectYesOrNo from "../../SelectYesOrNo";
import AlertOnSelector from "./AlertOnSelector";
import EventBus from "../../../../app/eventbus/EventBus";
import {SCAN_SUCCESS} from "../../../../app/eventbus/itemEvents";
import {CheckScanBarcodeOrMarking} from "../../warehouse-operation/enum/warehouseOperationWrapper";

const ItemDataTableFilter = ({
                                 filter,
                                 onFilterChange,
                                 onDebouncedFilterChange,
                                 sorting,
                                 onSortingChange,
                             }) => {
    const dispatch = useDispatch();
    const t = Translate;
    const lang = useSelector(selectLang);
    const isFilterCollapsed = useSelector(selectIsFilterCollapsed);
    const categories = useSelector(selectCategories);
    const [filters, setFilters] = useState({
        searchTerm: filter.searchTerm,
        sku: filter.sku,
        categoryId: filter.categoryId,
        warehouseId: filter.warehouseId,
        branchId: filter.branchId,
        alertOn: filter.alertOn,
        hasCatalog: filter.hasCatalog,
        hasBarcode: filter.hasBarcode,
        hasCategory: filter.hasCategory,
        hasSku: filter.hasSku
    });

    const toggleCollapseAdditionalFilters = (isFilterCollapsed) => {
        dispatch(updateIsFilterCollapsed(isFilterCollapsed));
    };

    useEffect(() => {
        const onScan = (value) => {
            const barcode = CheckScanBarcodeOrMarking(value);
            setFilters({...filters, searchTerm: barcode});
            onFilterChange({...filter, searchTerm: barcode});
        };

        const onScanSuccessHandler = EventBus.on(SCAN_SUCCESS, onScan);
        return () => EventBus.remove(SCAN_SUCCESS, onScanSuccessHandler);
    }, [filter, filters])

    return (
        <Row className="g-2">
            <Col xs={12} sm={6} lg={4} xl={3} xxl={2}>
                <Form.Group>
                    <Form.Label>{t(lang, 'items.common.datatable.filter.deep_search')}</Form.Label>
                    <Form.Control name="searchTerm"
                                  placeholder={t(lang, 'items.common.datatable.filter.deep_search.placeholder')}
                                  value={filters.searchTerm}
                                  onChange={(e) => {
                                      setFilters({...filters, searchTerm: e.target.value});
                                      onDebouncedFilterChange({...filter, searchTerm: e.target.value});
                                  }}

                    />
                </Form.Group>
            </Col>
            <Col xs={12} sm={6} lg={4} xl={3} xxl={2}>
                <Form.Group>
                    <Form.Label>{t(lang, 'items.common.sku')}</Form.Label>
                    <Form.Control name="sku"
                                  placeholder={t(lang, 'items.common.sku.placeholder')}
                                  value={filters.sku}
                                  onChange={(e) => {
                                      setFilters({...filters, sku: e.target.value});
                                      onDebouncedFilterChange({...filter, sku: e.target.value});
                                  }}

                    />
                </Form.Group>
            </Col>
            <Col xs={12} sm={6} lg={4} xl={3} xxl={2}>
                <Form.Group>
                    <Form.Label>{t(lang, 'items.common.warehouse')}</Form.Label>
                    <SelectWarehouse defaultWarehouseId={filters.warehouseId}
                                     onChange={(warehouse) => {
                                         setFilters({...filters, warehouseId: warehouse ? warehouse.id : null});
                                         onFilterChange({...filter, warehouseId: warehouse ? warehouse.id : null});
                                     }}
                                     selectProps={{
                                         isClearable: true
                                     }}
                    />
                </Form.Group>
            </Col>
            <Col xs={12} sm={6} lg={4} xl={3} xxl={2}>
                <Form.Group>
                    <Form.Label>{t(lang, 'roaming.invoice.branch')}</Form.Label>
                    <SelectBranch defaultValue={filters.branchId}
                                  onChange={(branch) => {
                                      setFilters({...filters, branchId: branch ? branch.id : null});
                                      onFilterChange({...filter, branchId: branch ? branch.id : null});
                                  }}
                    />
                </Form.Group>
            </Col>
            <Col xs={12} sm={6} lg={4} xl={3} xxl={2}>
                <Form.Group>
                    <Form.Label>{t(lang, 'items.common.alert_on.state')}</Form.Label>
                    <AlertOnSelector defaultValue={filters.alertOn}
                                     onChange={(option) => {
                                         setFilters({...filters, alertOn: option.value})
                                         onFilterChange({...filter, alertOn: option.value});
                                     }}
                    />
                </Form.Group>
            </Col>
            <Col xs={12} sm={6} lg={4} xl={3} xxl={2}>
                <Form.Group>
                    <Form.Label>{t(lang, 'items.common.sort')}</Form.Label>
                    <SortSelector defaultValue={sorting}
                                  onChange={onSortingChange}
                    />
                </Form.Group>
            </Col>
            <Col xs={12}>
                <Collapse in={isFilterCollapsed}>
                    <Row className="g-2">
                        <Col xs={12} sm={6} lg={4} xl={3} xxl={2}>
                            <Form.Group>
                                <Form.Label>{t(lang, 'items.common.has_category')}</Form.Label>
                                <SelectYesOrNo defaultValue={filters.hasCategory}
                                               placeholder={t(lang, 'items.common.select')}
                                               onChange={(option) => {
                                                   setFilters({...filters, hasCategory: option.value})
                                                   onFilterChange({...filter, hasCategory: option.value});
                                               }}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={12} sm={6} lg={4} xl={3} xxl={2}>
                            <Form.Group>
                                <Form.Label>{t(lang, 'items.common.category')}</Form.Label>
                                <SelectCategory defaultCategoryId={filter.categoryId}
                                                categories={categories}
                                                onChange={(category) => {
                                                    setFilters({...filters, categoryId: category ? category.id : null})
                                                    onFilterChange({
                                                        ...filter,
                                                        categoryId: category ? category.id : null
                                                    });
                                                }}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={12} sm={6} lg={4} xl={3} xxl={2}>
                            <Form.Group>
                                <Form.Label>{t(lang, 'items.common.has_catalog')}</Form.Label>
                                <SelectYesOrNo defaultValue={filters.hasCatalog}
                                               placeholder={t(lang, 'items.common.select')}
                                               onChange={(option) => {
                                                   setFilters({...filters, hasCatalog: option.value})
                                                   onFilterChange({...filter, hasCatalog: option.value});
                                               }}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={12} sm={6} lg={4} xl={3} xxl={2}>
                            <Form.Group>
                                <Form.Label>{t(lang, 'items.common.has_barcode')}</Form.Label>
                                <SelectYesOrNo defaultValue={filters.hasBarcode}
                                               placeholder={t(lang, 'items.common.select')}
                                               onChange={(option) => {
                                                   setFilters({...filters, hasBarcode: option.value});
                                                   onFilterChange({...filter, hasBarcode: option.value});
                                               }}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={12} sm={6} lg={4} xl={3} xxl={2}>
                            <Form.Group>
                                <Form.Label>{t(lang, 'items.common.has_sku')}</Form.Label>
                                <SelectYesOrNo defaultValue={filters.hasSku}
                                               placeholder={t(lang, 'items.common.select')}
                                               onChange={(option) => {
                                                   setFilters({...filters, hasSku: option.value});
                                                   onFilterChange({...filter, hasSku: option.value});
                                               }}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Collapse>
            </Col>
            <Col xs={12}
                 className="text-sm-end"
            >
                <Button variant="primary"
                        onClick={() => toggleCollapseAdditionalFilters(!isFilterCollapsed)}
                >
                    <FontAwesomeIcon icon={faFilter}
                                     className="me-1"
                    />
                    <span>{t(lang, 'items.common.extra_filters')}</span>
                </Button>
            </Col>
        </Row>
    );
};

export default ItemDataTableFilter;