import React, {useState} from 'react';
import {Button, Modal, Spinner} from "react-bootstrap";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import FalconCloseButton from "../../../common/FalconCloseButton";
import {memberDelete} from "../../../../app/store/reducers/kanban/kanbanReducer";

const DeleteMemberModal = ({show, setShow, id}) => {

    const lang = useSelector(selectLang);
    const t = Translate;

    const [loading, setLoading] = useState(false)

    const handleClose = () => {
        setShow(false)
    }

    const agreed = () => {
        setLoading(true)
        memberDelete(id)
            .then(() => setShow(false))
            .catch((e) => new Error(e))
            .finally(() => setLoading(false))

    }

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header>
                <Modal.Title>{t(lang, 'task.board.common.delete')}</Modal.Title>
                <FalconCloseButton onClick={handleClose}/>
            </Modal.Header>
            <Modal.Footer>
                <Button variant="falcon-danger" onClick={handleClose}>
                    {t(lang, 'task.board.common.cancel')}
                </Button>
                <Button variant="falcon-primary" onClick={agreed}>
                    {loading &&
                        <Spinner className='align-middle me-2' animation='border' role='switch' size='sm'/>
                    }
                    {t(lang, 'task.board.common.delete')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DeleteMemberModal;
