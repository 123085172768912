import React from 'react';
import {Route, Switch, useRouteMatch} from "react-router-dom";
import Kanban from "./layout/Kanban";
import KanbanView from "./layout/KanbanView";
import KanbanItem from "../../../components/kanban/KanbanItem";

const KanbanLayout = () => {
    const {url} = useRouteMatch();

    return (
        <Switch>
            <Route path={`${url}`} exact component={Kanban} />
            <Route path={`${url}/board/:id`} component={KanbanView} />
        </Switch>
    );
};

export default KanbanLayout;
