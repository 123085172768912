export const ContractorTypeBuyer = 1
export const ContractorTypeProvider = 2


export const PaymentTypeCashCode = 1
export const PaymentTypeUzCardCode = 2
export const PaymentTypeHumoCode = 3
export const PaymentTypeBankTransferCode = 4
export const PaymentTypeClickCode = 5
export const PaymentTypePaymeCode = 6
export const PaymentTypeVisaCode = 7

export const PaymentTypeCashText = 'crm.payment_type.cash'
export const PaymentTypeUzCardText = 'crm.payment_type.uzcard'
export const PaymentTypeHumoText = 'crm.payment_type.humo'
export const PaymentTypeBankTransferText = 'crm.payment_type.bank_transfer'
export const PaymentTypeClickText = 'crm.payment_type.click'
export const PaymentTypePaymeText = 'crm.payment_type.payme'
export const PaymentTypeVisaText = 'crm.payment_type.visa'


// Region Id
export const RegionIdTashkentCity = 1
export const RegionIdTashkent = 2
export const RegionIdSamarkand = 3
export const RegionIdJizzakh = 4
export const RegionIdSirdaryo = 5
export const RegionIdFergana = 6
export const RegionIdAndijan = 7
export const RegionIdNamangan = 8
export const RegionIdQashqadaryo = 9
export const RegionIdSurxondaryo = 10
export const RegionIdBukhara = 11
export const RegionIdNavoiy = 12
export const RegionIdRepublicOfKarakalpakstan = 13
export const RegionIdXorazm = 14


// Region Name
export const RegionNameTashkentCity = "г.Ташкент"
export const RegionNameTashkent = "Ташкентская область"
export const RegionNameSamarkand = "Самарканд вилояти"
export const RegionNameJizzakh = "Жиззах вилояти"
export const RegionNameSirdaryo = "Сирдарё вилояти"
export const RegionNameFergana = "Фаргона вилояти"
export const RegionNameAndijan = "Андижон вилояти"
export const RegionNameNamangan = "Наманган вилояти"
export const RegionNameQashqadaryo = "Кашкадарё вилояти"
export const RegionNameSurxondaryo = "Сурхондарё вилояти"
export const RegionNameBukhara = "Бухоро вилояти"
export const RegionNameNavoiy = "Навоий вилояти"
export const RegionNameRepublicOfKarakalpakstan = "Коракалпогистон Республикаси"
export const RegionNameXorazm = "Хоразм вилояти"


const SourceEdi = 1
const SourceBOT = 2
const SourceCRM = 3
const SourceClientApi = 4

const SourceVariantPrimary = 'primary'
const SourceVariantInfo = 'info'
const SourceVariantWarning = 'success'
const SourceVariantSuccess = 'warning'

export const ContractorTypes = [
    {
        label: 'edi.common.buyer',
        value: ContractorTypeBuyer
    },
    {
        label: 'roaming.common.executor',
        value: ContractorTypeProvider
    }
]

export const Regions = [
    {
        Id: RegionIdTashkentCity,
        Name: RegionNameTashkentCity
    },
    {
        Id: RegionIdTashkent,
        Name: RegionNameTashkent
    },
    {
        Id: RegionIdSamarkand,
        Name: RegionNameSamarkand
    },
    {
        Id: RegionIdJizzakh,
        Name: RegionNameJizzakh
    },
    {
        Id: RegionIdSirdaryo,
        Name: RegionNameSirdaryo
    },
    {
        Id: RegionIdFergana,
        Name: RegionNameFergana
    },
    {
        Id: RegionIdAndijan,
        Name: RegionNameAndijan
    },
    {
        Id: RegionIdNamangan,
        Name: RegionNameNamangan
    },
    {
        Id: RegionIdQashqadaryo,
        Name: RegionNameQashqadaryo
    },
    {
        Id: RegionIdSurxondaryo,
        Name: RegionNameSurxondaryo
    },
    {
        Id: RegionIdBukhara,
        Name: RegionNameBukhara
    },
    {
        Id: RegionIdNavoiy,
        Name: RegionNameNavoiy
    },
    {
        Id: RegionIdRepublicOfKarakalpakstan,
        Name: RegionNameRepublicOfKarakalpakstan
    },
    {
        Id: RegionIdXorazm,
        Name: RegionNameXorazm
    }
]

export const ContractorPaymentTypes = [
    {
        label: PaymentTypeCashText,
        value: PaymentTypeCashCode,
        bg: "#ADFE30"
    },
    {
        label: PaymentTypeUzCardText,
        value: PaymentTypeUzCardCode,
        bg: "#82D9F7"
    },
    {
        label: PaymentTypeHumoText,
        value: PaymentTypeHumoCode,
        bg: "#F7F183"
    },
    {
        label: PaymentTypeBankTransferText,
        value: PaymentTypeBankTransferCode,
        bg: "#F0BC8D"
    },
    {
        label: PaymentTypeClickText,
        value: PaymentTypeClickCode,
        bg: "#E59EEA"
    },
    {
        label: PaymentTypePaymeText,
        value: PaymentTypePaymeCode,
        bg: "#9FE3D3"
    },
    {
        label: PaymentTypeVisaText,
        value: PaymentTypeVisaCode,
        bg: "#E39FA0"
    },
]

export const GetPaymentTypeText = (type) => {
    switch (type) {
        case PaymentTypeCashCode:
            return PaymentTypeCashText;
        case PaymentTypeUzCardCode:
            return PaymentTypeUzCardText;
        case PaymentTypeHumoCode:
            return PaymentTypeHumoText;
        case PaymentTypeBankTransferCode:
            return PaymentTypeBankTransferText;
        case PaymentTypeClickCode:
            return PaymentTypeClickText;
        case PaymentTypePaymeCode:
            return PaymentTypePaymeText;
        case PaymentTypeVisaCode:
            return PaymentTypeVisaText;
        default:
            return "Не определено"
    }
}


export const sourceName = (source) => {
    switch (source) {
        case SourceEdi :
            return 'EDI'
        case SourceBOT :
            return 'БОТ'
        case SourceCRM :
            return 'CRM'
        case SourceClientApi :
            return 'crm.data.table.source.client.api'
    }
}

export const sourceVariant = (source) => {
    switch (source) {
        case SourceEdi :
            return SourceVariantInfo
        case SourceBOT :
            return SourceVariantPrimary
        case SourceCRM :
            return SourceVariantWarning
        case SourceClientApi :
            return SourceVariantSuccess
    }
}

export const OperationTotalSumInfo = (items, currency) => {
    let groupByCurrencyId = {}

    items?.forEach(item => {
        if (item?.price?.currency?.global_id in groupByCurrencyId) {
            groupByCurrencyId[item?.price?.currency?.global_id].totalSum += item?.price?.amount * item?.quantity
        } else {
            if (item?.price?.currency?.global_id) {
                groupByCurrencyId[item?.price?.currency?.global_id] = {
                    id: item?.price?.currency?.global_id,
                    name: currency && currency.length && currency.find(currency => currency.id === item?.price?.currency?.global_id)?.name || item?.price?.currency?.name,
                    quantity: item?.quantity,
                    totalSum: item?.price?.amount * item?.quantity,
                }
            }
        }
    })
    return Object.values(groupByCurrencyId)
}

export const SeparationCurrenciesAndTotalSum = (states, currency) => {
    let totalSum = 0
    currency.length && states.forEach(state => {
        totalSum += (state?.price.amount * state.quantity) * currency.find(f => f.id === state.price?.currency?.global_id)?.rate || 0
    })
    return totalSum
}

export const searchAreas = ({regionId, regionName}) => {
    if (regionId)
        return Areas.filter(area => area?.Region?.Id === regionId)
    if (regionName)
        return Areas.filter(area => area?.Region?.Name === regionName)
}

class ContractorDictionaryWrapper {
    getContractorName(contractorDictionary, contractorId) {
        if (contractorDictionary[contractorId]) {
            return contractorDictionary[contractorId].name;
        }
        return null;
    }
}

const contractorDictionaryWrapper = new ContractorDictionaryWrapper();
export default contractorDictionaryWrapper;


const Areas = [
    {
        Id: 1,
        Name: "Миробод тумани",
        Region: {
            Id: 1,
            Name: "г.Ташкент"
        }
    },
    {
        Id: 2,
        Name: "М. Улугбек тумани",
        Region: {
            Id: 1,
            Name: "г.Ташкент"
        }
    },
    {
        Id: 3,
        Name: "Яккасарой тумани",
        Region: {
            Id: 1,
            Name: "г.Ташкент"
        }
    },
    {
        Id: 4,
        Name: "Олмазор тумани",
        Region: {
            Id: 1,
            Name: "г.Ташкент"
        }
    },
    {
        Id: 5,
        Name: "Юнусобод тумани",
        Region: {
            Id: 1,
            Name: "г.Ташкент"
        }
    },
    {
        Id: 6,
        Name: "Чилонзор тумани",
        Region: {
            Id: 1,
            Name: "г.Ташкент"
        }
    },
    {
        Id: 7,
        Name: "Учтепа тумани",
        Region: {
            Id: 1,
            Name: "г.Ташкент"
        }
    },
    {
        Id: 8,
        Name: "Сергели тумани",
        Region: {
            Id: 1,
            Name: "г.Ташкент"
        }
    },
    {
        Id: 9,
        Name: "Яшнабод тумани",
        Region: {
            Id: 1,
            Name: "г.Ташкент"
        }
    },
    {
        Id: 10,
        Name: "Шайхонтохур тумани",
        Region: {
            Id: 1,
            Name: "г.Ташкент"
        }
    },
    {
        Id: 11,
        Name: "Бектемир тумани",
        Region: {
            Id: 1,
            Name: "г.Ташкент"
        }
    },
    {
        Id: 2026,
        Name: "ЯНГИҲАЁТ ТУМАНИ",
        Region: {
            Id: 1,
            Name: "г.Ташкент"
        }
    },
    {
        Id: 2024,
        Name: "ОҲАНГАРОН ШАҲРИ",
        Region: {
            Id: 2,
            Name: "Ташкентская область"
        }
    },
    {
        Id: 2016,
        Name: "Ф.ХЎЖАЕВ ТУМАНИ",
        Region: {
            Id: 2,
            Name: "Ташкентская область"
        }
    },
    {
        Id: 2017,
        Name: "ТЎКИМАЧИ ТУМАНИ",
        Region: {
            Id: 2,
            Name: "Ташкентская область"
        }
    },
    {
        Id: 205,
        Name: "Красногорск",
        Region: {
            Id: 2,
            Name: "Ташкентская область"
        }
    },
    {
        Id: 211,
        Name: "Тошкент тумани",
        Region: {
            Id: 2,
            Name: "Ташкентская область"
        }
    },
    {
        Id: 213,
        Name: "Янгийиул шахри",
        Region: {
            Id: 2,
            Name: "Ташкентская область"
        }
    },
    {
        Id: 1121,
        Name: "ЯНГИОБОД ШАХРИ",
        Region: {
            Id: 2,
            Name: "Ташкентская область"
        }
    },
    {
        Id: 1123,
        Name: "НУРАФШОН ШАҲРИ",
        Region: {
            Id: 2,
            Name: "Ташкентская область"
        }
    },
    {
        Id: 1419,
        Name: "ТЕМИРЙЎЛ ТУМАНИ",
        Region: {
            Id: 2,
            Name: "Ташкентская область"
        }
    },
    {
        Id: 12,
        Name: "Олмалик шахар",
        Region: {
            Id: 2,
            Name: "Ташкентская область"
        }
    },
    {
        Id: 13,
        Name: "Ангрен шахар",
        Region: {
            Id: 2,
            Name: "Ташкентская область"
        }
    },
    {
        Id: 14,
        Name: "Охангарон тумани",
        Region: {
            Id: 2,
            Name: "Ташкентская область"
        }
    },
    {
        Id: 15,
        Name: "Оккургон тумани",
        Region: {
            Id: 2,
            Name: "Ташкентская область"
        }
    },
    {
        Id: 16,
        Name: "Бекобод шахар",
        Region: {
            Id: 2,
            Name: "Ташкентская область"
        }
    },
    {
        Id: 17,
        Name: "Бука тумани",
        Region: {
            Id: 2,
            Name: "Ташкентская область"
        }
    },
    {
        Id: 18,
        Name: "Бустонлик тумани",
        Region: {
            Id: 2,
            Name: "Ташкентская область"
        }
    },
    {
        Id: 19,
        Name: "Бекобод тумани",
        Region: {
            Id: 2,
            Name: "Ташкентская область"
        }
    },
    {
        Id: 20,
        Name: "Зангиота тумани",
        Region: {
            Id: 2,
            Name: "Ташкентская область"
        }
    },
    {
        Id: 21,
        Name: "Кибрай тумани",
        Region: {
            Id: 2,
            Name: "Ташкентская область"
        }
    },
    {
        Id: 22,
        Name: "Паркент тумани",
        Region: {
            Id: 2,
            Name: "Ташкентская область"
        }
    },
    {
        Id: 23,
        Name: "Пискент тумани",
        Region: {
            Id: 2,
            Name: "Ташкентская область"
        }
    },
    {
        Id: 24,
        Name: "Куйичирчик тумани",
        Region: {
            Id: 2,
            Name: "Ташкентская область"
        }
    },
    {
        Id: 25,
        Name: "Уртачирчик тумани",
        Region: {
            Id: 2,
            Name: "Ташкентская область"
        }
    },
    {
        Id: 26,
        Name: "Чирчик шахар",
        Region: {
            Id: 2,
            Name: "Ташкентская область"
        }
    },
    {
        Id: 27,
        Name: "Чиноз тумани",
        Region: {
            Id: 2,
            Name: "Ташкентская область"
        }
    },
    {
        Id: 28,
        Name: "Юкоричирчик тумани",
        Region: {
            Id: 2,
            Name: "Ташкентская область"
        }
    },
    {
        Id: 29,
        Name: "Янгийиул тумани",
        Region: {
            Id: 2,
            Name: "Ташкентская область"
        }
    },
    {
        Id: 30,
        Name: "Самарканд шахар",
        Region: {
            Id: 3,
            Name: "Самарканд вилояти"
        }
    },
    {
        Id: 31,
        Name: "Самарканд тумани",
        Region: {
            Id: 3,
            Name: "Самарканд вилояти"
        }
    },
    {
        Id: 32,
        Name: "Булунгур тумани",
        Region: {
            Id: 3,
            Name: "Самарканд вилояти"
        }
    },
    {
        Id: 33,
        Name: "Жомбой тумани",
        Region: {
            Id: 3,
            Name: "Самарканд вилояти"
        }
    },
    {
        Id: 34,
        Name: "Пастаргом тумани",
        Region: {
            Id: 3,
            Name: "Самарканд вилояти"
        }
    },
    {
        Id: 35,
        Name: "Иштихон тумани",
        Region: {
            Id: 3,
            Name: "Самарканд вилояти"
        }
    },
    {
        Id: 36,
        Name: "Каттакургон шахар",
        Region: {
            Id: 3,
            Name: "Самарканд вилояти"
        }
    },
    {
        Id: 37,
        Name: "Нуробод тумани",
        Region: {
            Id: 3,
            Name: "Самарканд вилояти"
        }
    },
    {
        Id: 38,
        Name: "Окдаре тумани",
        Region: {
            Id: 3,
            Name: "Самарканд вилояти"
        }
    },
    {
        Id: 39,
        Name: "Нарпай тумани",
        Region: {
            Id: 3,
            Name: "Самарканд вилояти"
        }
    },
    {
        Id: 40,
        Name: "Пайарик тумани",
        Region: {
            Id: 3,
            Name: "Самарканд вилояти"
        }
    },
    {
        Id: 41,
        Name: "Каттакургон тумани",
        Region: {
            Id: 3,
            Name: "Самарканд вилояти"
        }
    },
    {
        Id: 42,
        Name: "Пахтачи тумани",
        Region: {
            Id: 3,
            Name: "Самарканд вилояти"
        }
    },
    {
        Id: 43,
        Name: "Тайлок тумани",
        Region: {
            Id: 3,
            Name: "Самарканд вилояти"
        }
    },
    {
        Id: 44,
        Name: "Ургут тумани",
        Region: {
            Id: 3,
            Name: "Самарканд вилояти"
        }
    },
    {
        Id: 45,
        Name: "Кушробод тумани",
        Region: {
            Id: 3,
            Name: "Самарканд вилояти"
        }
    },
    {
        Id: 1420,
        Name: "ОКТОШ ШАХРИ",
        Region: {
            Id: 3,
            Name: "Самарканд вилояти"
        }
    },
    {
        Id: 1423,
        Name: "СИЁБ ТУМАНИ",
        Region: {
            Id: 3,
            Name: "Самарканд вилояти"
        }
    },
    {
        Id: 1412,
        Name: "ГЎЗАЛКЕНТ ТУМАНИ",
        Region: {
            Id: 3,
            Name: "Самарканд вилояти"
        }
    },
    {
        Id: 1416,
        Name: "ЧЕЛАК ТУМАНИ",
        Region: {
            Id: 3,
            Name: "Самарканд вилояти"
        }
    },
    {
        Id: 1418,
        Name: "БОИШАМОЛ ТУМАНИ",
        Region: {
            Id: 3,
            Name: "Самарканд вилояти"
        }
    },
    {
        Id: 2027,
        Name: "Жиззах тумани",
        Region: {
            Id: 4,
            Name: "Жиззах вилояти"
        }
    },
    {
        Id: 47,
        Name: "Жиззах шахар",
        Region: {
            Id: 4,
            Name: "Жиззах вилояти"
        }
    },
    {
        Id: 48,
        Name: "Ш.Рашидов тумани",
        Region: {
            Id: 4,
            Name: "Жиззах вилояти"
        }
    },
    {
        Id: 49,
        Name: "Галлаорол тумани",
        Region: {
            Id: 4,
            Name: "Жиззах вилояти"
        }
    },
    {
        Id: 50,
        Name: "Бахмал тумани",
        Region: {
            Id: 4,
            Name: "Жиззах вилояти"
        }
    },
    {
        Id: 51,
        Name: "Пахтакор тумани",
        Region: {
            Id: 4,
            Name: "Жиззах вилояти"
        }
    },
    {
        Id: 52,
        Name: "Зафаробод тумани",
        Region: {
            Id: 4,
            Name: "Жиззах вилояти"
        }
    },
    {
        Id: 53,
        Name: "Дустлик тумани",
        Region: {
            Id: 4,
            Name: "Жиззах вилояти"
        }
    },
    {
        Id: 54,
        Name: "Арнасой тумани",
        Region: {
            Id: 4,
            Name: "Жиззах вилояти"
        }
    },
    {
        Id: 55,
        Name: "Мирзачул тумани",
        Region: {
            Id: 4,
            Name: "Жиззах вилояти"
        }
    },
    {
        Id: 56,
        Name: "Зарбдор тумани",
        Region: {
            Id: 4,
            Name: "Жиззах вилояти"
        }
    },
    {
        Id: 57,
        Name: "Зомин тумани",
        Region: {
            Id: 4,
            Name: "Жиззах вилояти"
        }
    },
    {
        Id: 58,
        Name: "Даштобод тумани",
        Region: {
            Id: 4,
            Name: "Жиззах вилояти"
        }
    },
    {
        Id: 59,
        Name: "Фориш тумани",
        Region: {
            Id: 4,
            Name: "Жиззах вилояти"
        }
    },
    {
        Id: 60,
        Name: "Янгиобод тумани",
        Region: {
            Id: 4,
            Name: "Жиззах вилояти"
        }
    },
    {
        Id: 61,
        Name: "Гулистон шахар",
        Region: {
            Id: 5,
            Name: "Сирдарё вилояти"
        }
    },
    {
        Id: 62,
        Name: "Янгиер шахар",
        Region: {
            Id: 5,
            Name: "Сирдарё вилояти"
        }
    },
    {
        Id: 63,
        Name: "Ширин шахар",
        Region: {
            Id: 5,
            Name: "Сирдарё вилояти"
        }
    },
    {
        Id: 64,
        Name: "Ок-олтин тумани",
        Region: {
            Id: 5,
            Name: "Сирдарё вилояти"
        }
    },
    {
        Id: 65,
        Name: "Боевут тумани",
        Region: {
            Id: 5,
            Name: "Сирдарё вилояти"
        }
    },
    {
        Id: 66,
        Name: "Гулистон тумани",
        Region: {
            Id: 5,
            Name: "Сирдарё вилояти"
        }
    },
    {
        Id: 67,
        Name: "Сирдарё тумани",
        Region: {
            Id: 5,
            Name: "Сирдарё вилояти"
        }
    },
    {
        Id: 69,
        Name: "Сайхунобод тумани",
        Region: {
            Id: 5,
            Name: "Сирдарё вилояти"
        }
    },
    {
        Id: 70,
        Name: "Хавос тумани",
        Region: {
            Id: 5,
            Name: "Сирдарё вилояти"
        }
    },
    {
        Id: 71,
        Name: "Мирзаобод тумани",
        Region: {
            Id: 5,
            Name: "Сирдарё вилояти"
        }
    },
    {
        Id: 72,
        Name: "Сардоба тумани",
        Region: {
            Id: 5,
            Name: "Сирдарё вилояти"
        }
    },
    {
        Id: 73,
        Name: "Маргилон шахар",
        Region: {
            Id: 6,
            Name: "Фаргона вилояти"
        }
    },
    {
        Id: 74,
        Name: "Фаргона шахар",
        Region: {
            Id: 6,
            Name: "Фаргона вилояти"
        }
    },
    {
        Id: 75,
        Name: "Кувасой шахар",
        Region: {
            Id: 6,
            Name: "Фаргона вилояти"
        }
    },
    {
        Id: 76,
        Name: "Кукон шахар",
        Region: {
            Id: 6,
            Name: "Фаргона вилояти"
        }
    },
    {
        Id: 77,
        Name: "Богдод тумани",
        Region: {
            Id: 6,
            Name: "Фаргона вилояти"
        }
    },
    {
        Id: 78,
        Name: "Бешарик тумани",
        Region: {
            Id: 6,
            Name: "Фаргона вилояти"
        }
    },
    {
        Id: 79,
        Name: "Бувайда тумани",
        Region: {
            Id: 6,
            Name: "Фаргона вилояти"
        }
    },
    {
        Id: 80,
        Name: "Дангара тумани",
        Region: {
            Id: 6,
            Name: "Фаргона вилояти"
        }
    },
    {
        Id: 81,
        Name: "Езъевон тумани",
        Region: {
            Id: 6,
            Name: "Фаргона вилояти"
        }
    },
    {
        Id: 82,
        Name: "Олтиарик тумани",
        Region: {
            Id: 6,
            Name: "Фаргона вилояти"
        }
    },
    {
        Id: 83,
        Name: "Куштепа тумани",
        Region: {
            Id: 6,
            Name: "Фаргона вилояти"
        }
    },
    {
        Id: 84,
        Name: "Риштон тумани",
        Region: {
            Id: 6,
            Name: "Фаргона вилояти"
        }
    },
    {
        Id: 85,
        Name: "Сух тумани",
        Region: {
            Id: 6,
            Name: "Фаргона вилояти"
        }
    },
    {
        Id: 86,
        Name: "Тошлок тумани",
        Region: {
            Id: 6,
            Name: "Фаргона вилояти"
        }
    },
    {
        Id: 87,
        Name: "Уч-куприк тумани",
        Region: {
            Id: 6,
            Name: "Фаргона вилояти"
        }
    },
    {
        Id: 88,
        Name: "Фаргона тумани",
        Region: {
            Id: 6,
            Name: "Фаргона вилояти"
        }
    },
    {
        Id: 89,
        Name: "Фуркат тумани",
        Region: {
            Id: 6,
            Name: "Фаргона вилояти"
        }
    },
    {
        Id: 90,
        Name: "Узбекистон тумани",
        Region: {
            Id: 6,
            Name: "Фаргона вилояти"
        }
    },
    {
        Id: 91,
        Name: "Кува тумани",
        Region: {
            Id: 6,
            Name: "Фаргона вилояти"
        }
    },
    {
        Id: 1505,
        Name: "ОХУНБОБОЕВ ТУМАНИ",
        Region: {
            Id: 6,
            Name: "Фаргона вилояти"
        }
    },
    {
        Id: 1519,
        Name: "КЎКОН ШАХРИ",
        Region: {
            Id: 6,
            Name: "Фаргона вилояти"
        }
    },
    {
        Id: 1522,
        Name: "КИРГУЛИ ТУМАНИ",
        Region: {
            Id: 6,
            Name: "Фаргона вилояти"
        }
    },
    {
        Id: 1719,
        Name: "КОРАСУВ ШАХРИ",
        Region: {
            Id: 7,
            Name: "Андижон вилояти"
        }
    },
    {
        Id: 1720,
        Name: "БЎСТОН ТУМАНИ",
        Region: {
            Id: 7,
            Name: "Андижон вилояти"
        }
    },
    {
        Id: 92,
        Name: "Андижон шахар",
        Region: {
            Id: 7,
            Name: "Андижон вилояти"
        }
    },
    {
        Id: 93,
        Name: "Андижон тумани",
        Region: {
            Id: 7,
            Name: "Андижон вилояти"
        }
    },
    {
        Id: 94,
        Name: "Асака тумани",
        Region: {
            Id: 7,
            Name: "Андижон вилояти"
        }
    },
    {
        Id: 95,
        Name: "Баликчи тумани",
        Region: {
            Id: 7,
            Name: "Андижон вилояти"
        }
    },
    {
        Id: 96,
        Name: "Буз тумани",
        Region: {
            Id: 7,
            Name: "Андижон вилояти"
        }
    },
    {
        Id: 97,
        Name: "Булокбоши тумани",
        Region: {
            Id: 7,
            Name: "Андижон вилояти"
        }
    },
    {
        Id: 98,
        Name: "Жалолкудук тумани",
        Region: {
            Id: 7,
            Name: "Андижон вилояти"
        }
    },
    {
        Id: 99,
        Name: "Избоскан тумани",
        Region: {
            Id: 7,
            Name: "Андижон вилояти"
        }
    },
    {
        Id: 100,
        Name: "Кургонтепа тумани",
        Region: {
            Id: 7,
            Name: "Андижон вилояти"
        }
    },
    {
        Id: 101,
        Name: "Хонобод шахри",
        Region: {
            Id: 7,
            Name: "Андижон вилояти"
        }
    },
    {
        Id: 103,
        Name: "Мархамат тумани",
        Region: {
            Id: 7,
            Name: "Андижон вилояти"
        }
    },
    {
        Id: 104,
        Name: "Олтинкул тумани",
        Region: {
            Id: 7,
            Name: "Андижон вилояти"
        }
    },
    {
        Id: 105,
        Name: "Пахтаобод тумани",
        Region: {
            Id: 7,
            Name: "Андижон вилояти"
        }
    },
    {
        Id: 106,
        Name: "Улугнор тумани",
        Region: {
            Id: 7,
            Name: "Андижон вилояти"
        }
    },
    {
        Id: 107,
        Name: "Хужаобод тумани",
        Region: {
            Id: 7,
            Name: "Андижон вилояти"
        }
    },
    {
        Id: 108,
        Name: "Шахрихон тумани",
        Region: {
            Id: 7,
            Name: "Андижон вилояти"
        }
    },
    {
        Id: 109,
        Name: "Наманган шахар",
        Region: {
            Id: 8,
            Name: "Наманган вилояти"
        }
    },
    {
        Id: 110,
        Name: "Косонсой тумани",
        Region: {
            Id: 8,
            Name: "Наманган вилояти"
        }
    },
    {
        Id: 111,
        Name: "Норин тумани",
        Region: {
            Id: 8,
            Name: "Наманган вилояти"
        }
    },
    {
        Id: 112,
        Name: "Учкургон тумани",
        Region: {
            Id: 8,
            Name: "Наманган вилояти"
        }
    },
    {
        Id: 113,
        Name: "Чорток тумани",
        Region: {
            Id: 8,
            Name: "Наманган вилояти"
        }
    },
    {
        Id: 114,
        Name: "Чуст тумани",
        Region: {
            Id: 8,
            Name: "Наманган вилояти"
        }
    },
    {
        Id: 115,
        Name: "Туракургон тумани",
        Region: {
            Id: 8,
            Name: "Наманган вилояти"
        }
    },
    {
        Id: 116,
        Name: "Поп тумани",
        Region: {
            Id: 8,
            Name: "Наманган вилояти"
        }
    },
    {
        Id: 117,
        Name: "Мингбулок тумани",
        Region: {
            Id: 8,
            Name: "Наманган вилояти"
        }
    },
    {
        Id: 118,
        Name: "Наманган тумани",
        Region: {
            Id: 8,
            Name: "Наманган вилояти"
        }
    },
    {
        Id: 119,
        Name: "Уйчи тумани",
        Region: {
            Id: 8,
            Name: "Наманган вилояти"
        }
    },
    {
        Id: 120,
        Name: "Янгикурган тумани",
        Region: {
            Id: 8,
            Name: "Наманган вилояти"
        }
    },
    {
        Id: 1601,
        Name: "ДАВЛАТОБОД ТУМАНИ",
        Region: {
            Id: 8,
            Name: "Наманган вилояти"
        }
    },
    {
        Id: 1618,
        Name: "ХАККУЛОБОД ШАХРИ",
        Region: {
            Id: 8,
            Name: "Наманган вилояти"
        }
    },
    {
        Id: 2030,
        Name: "ЯНГИ НАМАНГАН ТУМАНИ",
        Region: {
            Id: 8,
            Name: "Наманган вилояти"
        }
    },
    {
        Id: 1807,
        Name: "БАХОРИСТОН ТУМАНИ",
        Region: {
            Id: 9,
            Name: "Кашкадарё вилояти"
        }
    },
    {
        Id: 2029,
        Name: "КЎКДАЛА ТУМАНИ",
        Region: {
            Id: 9,
            Name: "Кашкадарё вилояти"
        }
    },
    {
        Id: 212,
        Name: "Шахрисабз шахри",
        Region: {
            Id: 9,
            Name: "Кашкадарё вилояти"
        }
    },
    {
        Id: 121,
        Name: "Карши шахар",
        Region: {
            Id: 9,
            Name: "Кашкадарё вилояти"
        }
    },
    {
        Id: 122,
        Name: "Шахрисабз тумани",
        Region: {
            Id: 9,
            Name: "Кашкадарё вилояти"
        }
    },
    {
        Id: 123,
        Name: "Китоб тумани",
        Region: {
            Id: 9,
            Name: "Кашкадарё вилояти"
        }
    },
    {
        Id: 124,
        Name: "Яккабог тумани",
        Region: {
            Id: 9,
            Name: "Кашкадарё вилояти"
        }
    },
    {
        Id: 125,
        Name: "Чирокчи тумани",
        Region: {
            Id: 9,
            Name: "Кашкадарё вилояти"
        }
    },
    {
        Id: 126,
        Name: "Камаши тумани",
        Region: {
            Id: 9,
            Name: "Кашкадарё вилояти"
        }
    },
    {
        Id: 127,
        Name: "Гузор тумани",
        Region: {
            Id: 9,
            Name: "Кашкадарё вилояти"
        }
    },
    {
        Id: 128,
        Name: "Карши тумани",
        Region: {
            Id: 9,
            Name: "Кашкадарё вилояти"
        }
    },
    {
        Id: 129,
        Name: "Нишон тумани",
        Region: {
            Id: 9,
            Name: "Кашкадарё вилояти"
        }
    },
    {
        Id: 130,
        Name: "Косон тумани",
        Region: {
            Id: 9,
            Name: "Кашкадарё вилояти"
        }
    },
    {
        Id: 131,
        Name: "Касби тумани",
        Region: {
            Id: 9,
            Name: "Кашкадарё вилояти"
        }
    },
    {
        Id: 132,
        Name: "Миришкор тумани",
        Region: {
            Id: 9,
            Name: "Кашкадарё вилояти"
        }
    },
    {
        Id: 133,
        Name: "Муборак тумани",
        Region: {
            Id: 9,
            Name: "Кашкадарё вилояти"
        }
    },
    {
        Id: 134,
        Name: "Дехконобод тумани",
        Region: {
            Id: 9,
            Name: "Кашкадарё вилояти"
        }
    },
    {
        Id: 135,
        Name: "Ангор тумани",
        Region: {
            Id: 10,
            Name: "Сурхондарё вилояти"
        }
    },
    {
        Id: 136,
        Name: "Бойсун тумани",
        Region: {
            Id: 10,
            Name: "Сурхондарё вилояти"
        }
    },
    {
        Id: 137,
        Name: "Денов шахар ",
        Region: {
            Id: 10,
            Name: "Сурхондарё вилояти"
        }
    },
    {
        Id: 138,
        Name: "Денов тумани",
        Region: {
            Id: 10,
            Name: "Сурхондарё вилояти"
        }
    },
    {
        Id: 139,
        Name: "Жаркургон тумани",
        Region: {
            Id: 10,
            Name: "Сурхондарё вилояти"
        }
    },
    {
        Id: 140,
        Name: "Кизирик тумани",
        Region: {
            Id: 10,
            Name: "Сурхондарё вилояти"
        }
    },
    {
        Id: 141,
        Name: "Кумкурган тумани",
        Region: {
            Id: 10,
            Name: "Сурхондарё вилояти"
        }
    },
    {
        Id: 142,
        Name: "Музрабад тумани",
        Region: {
            Id: 10,
            Name: "Сурхондарё вилояти"
        }
    },
    {
        Id: 143,
        Name: "Олтинсай тумани",
        Region: {
            Id: 10,
            Name: "Сурхондарё вилояти"
        }
    },
    {
        Id: 144,
        Name: "Сариосиё тумани",
        Region: {
            Id: 10,
            Name: "Сурхондарё вилояти"
        }
    },
    {
        Id: 145,
        Name: "Термез шахар",
        Region: {
            Id: 10,
            Name: "Сурхондарё вилояти"
        }
    },
    {
        Id: 146,
        Name: "Термез тумани",
        Region: {
            Id: 10,
            Name: "Сурхондарё вилояти"
        }
    },
    {
        Id: 147,
        Name: "Шеробод тумани",
        Region: {
            Id: 10,
            Name: "Сурхондарё вилояти"
        }
    },
    {
        Id: 148,
        Name: "Шурчи тумани",
        Region: {
            Id: 10,
            Name: "Сурхондарё вилояти"
        }
    },
    {
        Id: 149,
        Name: "Узун тумани",
        Region: {
            Id: 10,
            Name: "Сурхондарё вилояти"
        }
    },
    {
        Id: 2021,
        Name: "Бандихон тумани",
        Region: {
            Id: 10,
            Name: "Сурхондарё вилояти"
        }
    },
    {
        Id: 1905,
        Name: "ШАРГУН ТУМАНИ",
        Region: {
            Id: 10,
            Name: "Сурхондарё вилояти"
        }
    },
    {
        Id: 2009,
        Name: "ГАЗЛИ ШАХРИ",
        Region: {
            Id: 11,
            Name: "Бухоро вилояти"
        }
    },
    {
        Id: 206,
        Name: "Когон шахри",
        Region: {
            Id: 11,
            Name: "Бухоро вилояти"
        }
    },
    {
        Id: 150,
        Name: "Бухоро шахар",
        Region: {
            Id: 11,
            Name: "Бухоро вилояти"
        }
    },
    {
        Id: 151,
        Name: "Ромитан тумани",
        Region: {
            Id: 11,
            Name: "Бухоро вилояти"
        }
    },
    {
        Id: 152,
        Name: "Когон тумани",
        Region: {
            Id: 11,
            Name: "Бухоро вилояти"
        }
    },
    {
        Id: 153,
        Name: "Гиждувон тумани",
        Region: {
            Id: 11,
            Name: "Бухоро вилояти"
        }
    },
    {
        Id: 154,
        Name: "Бухоро тумани",
        Region: {
            Id: 11,
            Name: "Бухоро вилояти"
        }
    },
    {
        Id: 155,
        Name: "Жондор тумани",
        Region: {
            Id: 11,
            Name: "Бухоро вилояти"
        }
    },
    {
        Id: 156,
        Name: "Вобкент тумани",
        Region: {
            Id: 11,
            Name: "Бухоро вилояти"
        }
    },
    {
        Id: 157,
        Name: "Пешку тумани",
        Region: {
            Id: 11,
            Name: "Бухоро вилояти"
        }
    },
    {
        Id: 158,
        Name: "Шофиркон тумани",
        Region: {
            Id: 11,
            Name: "Бухоро вилояти"
        }
    },
    {
        Id: 159,
        Name: "Коракул тумани",
        Region: {
            Id: 11,
            Name: "Бухоро вилояти"
        }
    },
    {
        Id: 160,
        Name: "Олот тумани",
        Region: {
            Id: 11,
            Name: "Бухоро вилояти"
        }
    },
    {
        Id: 161,
        Name: "Коровулбозор тумани",
        Region: {
            Id: 11,
            Name: "Бухоро вилояти"
        }
    },
    {
        Id: 162,
        Name: "Зарафшон шахар",
        Region: {
            Id: 12,
            Name: "Навоий вилояти"
        }
    },
    {
        Id: 163,
        Name: "Кармана тумани",
        Region: {
            Id: 12,
            Name: "Навоий вилояти"
        }
    },
    {
        Id: 164,
        Name: "Кизилтепа тумани",
        Region: {
            Id: 12,
            Name: "Навоий вилояти"
        }
    },
    {
        Id: 165,
        Name: "Конимех тумани",
        Region: {
            Id: 12,
            Name: "Навоий вилояти"
        }
    },
    {
        Id: 166,
        Name: "Навоий шахар",
        Region: {
            Id: 12,
            Name: "Навоий вилояти"
        }
    },
    {
        Id: 167,
        Name: "Навбахор тумани",
        Region: {
            Id: 12,
            Name: "Навоий вилояти"
        }
    },
    {
        Id: 168,
        Name: "Нурота тумани",
        Region: {
            Id: 12,
            Name: "Навоий вилояти"
        }
    },
    {
        Id: 169,
        Name: "Хатирчи тумани",
        Region: {
            Id: 12,
            Name: "Навоий вилояти"
        }
    },
    {
        Id: 207,
        Name: "Учкудук тумани",
        Region: {
            Id: 12,
            Name: "Навоий вилояти"
        }
    },
    {
        Id: 208,
        Name: "Томди тумани",
        Region: {
            Id: 12,
            Name: "Навоий вилояти"
        }
    },
    {
        Id: 2025,
        Name: "ҒОЗҒОН ШАҲРИ",
        Region: {
            Id: 12,
            Name: "Навоий вилояти"
        }
    },
    {
        Id: 2022,
        Name: "Бузатов тумани",
        Region: {
            Id: 13,
            Name: "Коракалпогистон Республикаси"
        }
    },
    {
        Id: 198,
        Name: "Бозатау участок",
        Region: {
            Id: 13,
            Name: "Коракалпогистон Республикаси"
        }
    },
    {
        Id: 199,
        Name: "Халкобод шахри",
        Region: {
            Id: 13,
            Name: "Коракалпогистон Республикаси"
        }
    },
    {
        Id: 1500,
        Name: "Тахиаташский район",
        Region: {
            Id: 13,
            Name: "Коракалпогистон Республикаси"
        }
    },
    {
        Id: 170,
        Name: "Нукус шахар",
        Region: {
            Id: 13,
            Name: "Коракалпогистон Республикаси"
        }
    },
    {
        Id: 171,
        Name: "Нукус тумани",
        Region: {
            Id: 13,
            Name: "Коракалпогистон Республикаси"
        }
    },
    {
        Id: 172,
        Name: "Кегеили тумани",
        Region: {
            Id: 13,
            Name: "Коракалпогистон Республикаси"
        }
    },
    {
        Id: 173,
        Name: "Чимбой тумани",
        Region: {
            Id: 13,
            Name: "Коракалпогистон Республикаси"
        }
    },
    {
        Id: 174,
        Name: "Караузяк тумани",
        Region: {
            Id: 13,
            Name: "Коракалпогистон Республикаси"
        }
    },
    {
        Id: 175,
        Name: "Тахтакупир тумани",
        Region: {
            Id: 13,
            Name: "Коракалпогистон Республикаси"
        }
    },
    {
        Id: 176,
        Name: "Хужайли тумани",
        Region: {
            Id: 13,
            Name: "Коракалпогистон Республикаси"
        }
    },
    {
        Id: 177,
        Name: "Шуманай тумани",
        Region: {
            Id: 13,
            Name: "Коракалпогистон Республикаси"
        }
    },
    {
        Id: 178,
        Name: "Канлыкуль тумани",
        Region: {
            Id: 13,
            Name: "Коракалпогистон Республикаси"
        }
    },
    {
        Id: 179,
        Name: "город Тахиаташ ",
        Region: {
            Id: 13,
            Name: "Коракалпогистон Республикаси"
        }
    },
    {
        Id: 180,
        Name: "Кунград тумани",
        Region: {
            Id: 13,
            Name: "Коракалпогистон Республикаси"
        }
    },
    {
        Id: 181,
        Name: "Муйнак тумани",
        Region: {
            Id: 13,
            Name: "Коракалпогистон Республикаси"
        }
    },
    {
        Id: 182,
        Name: "Амурдарья тумани",
        Region: {
            Id: 13,
            Name: "Коракалпогистон Республикаси"
        }
    },
    {
        Id: 183,
        Name: "Турткуль тумани",
        Region: {
            Id: 13,
            Name: "Коракалпогистон Республикаси"
        }
    },
    {
        Id: 184,
        Name: "Элликкала тумани",
        Region: {
            Id: 13,
            Name: "Коракалпогистон Республикаси"
        }
    },
    {
        Id: 185,
        Name: "Беруний тумани",
        Region: {
            Id: 13,
            Name: "Коракалпогистон Республикаси"
        }
    },
    {
        Id: 186,
        Name: "Ургенч шахар",
        Region: {
            Id: 14,
            Name: "Хоразм вилояти"
        }
    },
    {
        Id: 187,
        Name: "Ургенч тумани",
        Region: {
            Id: 14,
            Name: "Хоразм вилояти"
        }
    },
    {
        Id: 188,
        Name: "Хива тумани",
        Region: {
            Id: 14,
            Name: "Хоразм вилояти"
        }
    },
    {
        Id: 189,
        Name: "Хонка тумани",
        Region: {
            Id: 14,
            Name: "Хоразм вилояти"
        }
    },
    {
        Id: 190,
        Name: "Шовот тумани",
        Region: {
            Id: 14,
            Name: "Хоразм вилояти"
        }
    },
    {
        Id: 191,
        Name: "Богот тумани",
        Region: {
            Id: 14,
            Name: "Хоразм вилояти"
        }
    },
    {
        Id: 192,
        Name: "Янгиарик тумани",
        Region: {
            Id: 14,
            Name: "Хоразм вилояти"
        }
    },
    {
        Id: 193,
        Name: "Янгибозор тумани",
        Region: {
            Id: 14,
            Name: "Хоразм вилояти"
        }
    },
    {
        Id: 194,
        Name: "Гурлан тумани",
        Region: {
            Id: 14,
            Name: "Хоразм вилояти"
        }
    },
    {
        Id: 195,
        Name: "Кушкупир тумани",
        Region: {
            Id: 14,
            Name: "Хоразм вилояти"
        }
    },
    {
        Id: 196,
        Name: "Хазарасп тумани",
        Region: {
            Id: 14,
            Name: "Хоразм вилояти"
        }
    },
    {
        Id: 197,
        Name: "Питнак тумани",
        Region: {
            Id: 14,
            Name: "Хоразм вилояти"
        }
    },
    {
        Id: 2023,
        Name: "ТУПРОҚҚАЛЪА ТУМАНИ",
        Region: {
            Id: 14,
            Name: "Хоразм вилояти"
        }
    },
    {
        Id: 2020,
        Name: "Хива шахар",
        Region: {
            Id: 14,
            Name: "Хоразм вилояти"
        }
    }]
