import React, {useEffect, useState} from 'react';
import IconButton from "../../../common/IconButton";
import {CloseButton, Col, Form, Modal, Row, Spinner} from "react-bootstrap";
import {FormProvider, useFieldArray, useForm, useWatch} from "react-hook-form";
import Button from "react-bootstrap/Button";
import {useDispatch, useSelector} from "react-redux";
import {selectCurrency} from "../../../../app/store/reducers/currency/currencyReducer";
import {selectLang, selectNumberFormat, Translate} from "../../../../app/store/reducers/main/mainReducer";
import itemWrapper, {UZS} from "../../item/enum/itemWrapper";
import {
    loadContractorAsync,
    selectCardContractor, selectContractorsDictionary,
    updateCardContractor
} from "../../../../app/store/reducers/contractor/contractorReducer";
import useQuery from "../../roaming/invoice/form/useQuery";
import {checkTypeOfReturnNumber} from "../enum/warehouseOperationWrapper";
import numeral from "numeral";
import WOWithDiscountPaymentForm from "./WOWithDiscountPaymentForm";
import {faWallet} from "@fortawesome/free-solid-svg-icons";
import contractorDictionaryWrapper from "../../../../enum/ContractorWrapper";

const WOWIthDiscountPayment = ({saleId, contractorId, payment, exactDiscounts = [], percentDiscount = 0, totalAmounts = [], onSubmitPay, isEditing, ...props}) => {
    const [loading, setLoading] = useState(false)
    const [show, setShow] = useState(false)
    const {payment_id} = useQuery()
    const contractorsDictionary = useSelector(selectContractorsDictionary);
    const contractor = useSelector(selectCardContractor)
    const numberFormat = useSelector(selectNumberFormat)
    const currencies = useSelector(selectCurrency);
    const dispatch = useDispatch()
    const lang = useSelector(selectLang)
    const t = Translate

    useEffect(() => {
        if (payment_id) {
            setShow(true)
        }
    },[])

    const handlerShow = () => {
        setShow(true)
        loadContractorAsync(contractorId)
            .then(cr => {
                dispatch(updateCardContractor(cr))
            }).catch(err => console.log(err));

        if (payment?.cashBoxStates) {
            let cc = payment?.cashBoxStates.map(pay => {
                return {
                    type: pay?.paymentType,
                    currency: {
                        amount: pay?.amount,
                        currency_id: pay?.currencyId
                    }
                }
            })

            replaceCashBoxStates(cc)
            form.setValue('notes', payment?.notes)
        }

        form.setValue('percentDiscount', percentDiscount)
        exactDiscounts?.map((oldExactDiscount, index) => {
            form.setValue(`exactDiscounts.${index}.amount`, oldExactDiscount?.amount)
            form.setValue(`exactDiscounts.${index}.currency_id`, oldExactDiscount?.currency?.id)
        })
    }
    const handlerClose = () => {
        setShow(false)
        form.reset()
    }

    const defaultExactDiscount = {
        amount: '0',
        currency_id: currencies?.filter(cur => cur.is_active)?.find(c => c?.code === UZS)?.id
    }
    const defaultDebtStates = {
        type: 1,
        amount: '0',
        currency_id: itemWrapper.getDefaultCurrency(currencies)?.id,
    }
    const defaultCashBoxStates = {
        type: 1,
        currency: {
            amount: '0',
            currency_id: itemWrapper.getDefaultCurrency(currencies)?.id,
        }
    }
    const form = useForm({
        defaultValues: {
            debt_states: [defaultDebtStates],
            cash_box_states: [defaultCashBoxStates],
            notes: "",
            exactDiscounts: [defaultExactDiscount],
            cash_back_pay: [defaultCashBoxStates],
            customer_debt_pay: [defaultExactDiscount],
            percentDiscount: 0,
        }
    })


    const {
        fields: fieldsExactDiscounts,
        update: updateExactDiscounts,
        append: appendExactDiscounts,
        remove: removeExactDiscounts,
    } = useFieldArray({
        control: form.control,
        name: 'exactDiscounts'
    });
    const {
        fields: fieldsCashBoxStates,
        update: updateCashBoxStates,
        append: appendCashBoxStates,
        remove: removeCashBoxStates,
        replace: replaceCashBoxStates,
    } = useFieldArray({
        control: form.control,
        name: 'cash_box_states'
    });

    const {
        fields: fieldsCashBackPay,
        update: updateCashBackPay,
        append: appendCashBackPay,
        remove: removeCashBackPay,
    } = useFieldArray({
        control: form.control,
        name: 'cash_back_pay'
    });

    const {
        fields: fieldsCustomerDebtPay,
        update: updateCustomerDebtPay,
        append: appendCustomerDebtPay,
        remove: removeCustomerDebtPay,
    } = useFieldArray({
        control: form.control,
        name: 'customer_debt_pay'
    });

    const watchCashBackPay = useWatch({name: 'cash_back_pay', control: form.control});
    const controlledFieldsCashBackPay = fieldsCashBackPay.map((field, index) => {
        return {
            ...field,
            ...watchCashBackPay[index]
        };
    });

    const watchCustomerDebtPay = useWatch({name: 'customer_debt_pay', control: form.control});
    const controlledFieldsCustomerDebtPay = fieldsCustomerDebtPay.map((field, index) => {
        return {
            ...field,
            ...watchCustomerDebtPay[index]
        };
    });

    const addNewRowToCashBackPay = () => {
        appendCashBackPay(defaultCashBoxStates)
    }

    const onTrashCashBackPay = (index) => {
        removeCashBackPay(index)
    }

    const onChangeCashBackPayPaymentType = (selectedPaymentType, field, index) => {

        if (selectedPaymentType) {
            updateCashBackPay(index, {
                type: selectedPaymentType.value,
                currency: field.currency,
                rate: field.currency.rate
            })
        }
    }

    const onChangeCashBackPayCurrencyId = (currencyCode, field, index) => {
        const convertToUZS = checkTypeOfReturnNumber(field.currency.amount) * currencies.find(cur => cur.id === field?.currency?.currency_id)?.rate

        updateCashBackPay(index, {
            type: field.type,
            currency: {
                currency_id: currencyCode.id,
                amount: (convertToUZS || 0) / currencyCode?.rate,
            }
        })
    }

    const addNewRowToCustomerDebtPay = () => {
        appendCustomerDebtPay(defaultExactDiscount)
    }

    const onTrashCustomerDebtPay = (index) => {
        removeCustomerDebtPay(index)
    }

    const onChangeCustomerDebtPayCurrencyId = (currencyCode, field, index) => {
        const convertToUZS = checkTypeOfReturnNumber(field?.amount) * currencies.find(cur => cur?.id === field?.currency_id)?.rate
        updateCustomerDebtPay(index, {
            currency_id: currencyCode.id,
            amount: (convertToUZS || 0) / currencyCode?.rate,
        })
    }


    const watchExactDiscounts = useWatch({name: 'exactDiscounts', control: form.control});
    const controlledFieldsExactDiscounts = fieldsExactDiscounts.map((field, index) => {
        return {
            ...field,
            ...watchExactDiscounts[index]
        };
    });


    const addNewRowToExactDiscounts = () => {
        appendExactDiscounts(defaultExactDiscount)
    }

    const onTrashExactDiscounts = (index) => {
        removeExactDiscounts(index)
    }

    const onChangeExactDiscountsCurrencyId = (currencyCode, field, index) => {
        const convertToUZS = checkTypeOfReturnNumber(field?.amount) * currencies.find(cur => cur?.id === field?.currency_id)?.rate

        updateExactDiscounts(index, {
            currency_id: currencyCode.id,
            amount: (convertToUZS || 0) / currencyCode?.rate,
        })
    }





    const addNewPaymentRowToCashBoxStates = () => {
        appendCashBoxStates(defaultCashBoxStates)
    }
    const onTrashCashBoxStates = (index) => {
        removeCashBoxStates(index)
    }

    const onChangeCashBoxStatesPaymentType = (selectedPaymentType, field, index) => {
        if (selectedPaymentType) {
            updateCashBoxStates(index, {
                type: selectedPaymentType.value,
                currency: field.currency,
                rate: field.currency.rate
            })
        }
    }

    const onChangeCashBoxStatesCurrencyCode = (currencyCode, field, index) => {
        const convertToUZS = checkTypeOfReturnNumber(field.currency.amount) * currencies.find(cur => cur.id === field?.currency?.currency_id)?.rate

        if (currencyCode) {
            updateCashBoxStates(index, {
                type: field.type,
                currency: {
                    currency_id: currencyCode.id,
                    amount: (convertToUZS || 0) / currencyCode?.rate,
                }
            })
        }
    }

    const onSubmit = async (formData) => {
        setLoading(true)
        const result = {
            cash_box_states: formData?.cash_box_states.map(cbs => {
                return {
                    amount: checkTypeOfReturnNumber(cbs?.currency?.amount),
                    currency_id: cbs?.currency?.currency_id,
                    type: cbs?.type,
                }
            }).filter(x => x.amount !== 0),
            debt_states: formData?.debt_states?.map(ds => {
                return {
                    amount: checkTypeOfReturnNumber(ds?.amount),
                    currency_id: ds?.currency_id,
                }
            }).filter(x => x.amount !== 0),
            notes: formData?.notes,
        }

        const exactDiscounts = formData?.exactDiscounts.map(ed => {
            return {
                amount: checkTypeOfReturnNumber(ed?.amount),
                currency_id: ed?.currency_id,
            }
        }).filter(x => x?.amount > 0)
        formData?.cash_back_pay.map((cbp) => {
            const resultCashBox = result.cash_box_states.findIndex(x => x?.type === cbp?.type && x?.currency_id === cbp?.currency?.currency_id)

            if (resultCashBox > -1) {
                result.cash_box_states[resultCashBox].amount = result?.cash_box_states[resultCashBox]?.amount - checkTypeOfReturnNumber(cbp?.currency?.amount)
            } else {
                result.cash_box_states.push({
                    type: cbp?.type,
                    currency_id: cbp?.currency?.currency_id,
                    amount: -checkTypeOfReturnNumber(cbp?.currency?.amount),
                })
            }
        })
        formData?.customer_debt_pay?.map((cdp) => {
            const resultDebtPay = result.debt_states.findIndex(x => x?.currency_id === cdp?.currency_id)

            if (resultDebtPay > -1) {
                result.debt_states[resultDebtPay].amount = result.debt_states[resultDebtPay]?.amount + checkTypeOfReturnNumber(cdp?.amount)
            } else {
                result.debt_states.push({
                    amount: checkTypeOfReturnNumber(cdp?.amount),
                    currency_id: cdp?.currency_id,
                })
            }

        })

        result.debt_states = result.debt_states.filter(x => x.amount !== 0)
        result.cash_box_states = result.cash_box_states.filter(x => x.amount !== 0)

        onSubmitPay({
            id: saleId,
            payment: result.cash_box_states?.length > 0 || result.debt_states?.length > 0 ? result : null,
            exactDiscounts: exactDiscounts,
            percentDiscount: +formData?.percentDiscount
        })
            .then(() => {})
            .catch(() => {})
            .finally(() => {
                setLoading(false)
                handlerClose()
            })
    }

    return (
        <>
            <IconButton
                icon={faWallet}
                variant="primary"
                size="sm"
                className="me-2"
                onClick={() => handlerShow()}
                {...props}
            >
                {isEditing ? t(lang, 'warehouse.operation.item.common.edit_payment') : t(lang, "warehouse.operation.item.common.payment")}
            </IconButton>

            <Modal show={show} onHide={handlerClose} size="xl">
                <Modal.Header>
                    <Row className="d-flex justify-content-between w-100">
                        <Col xs={12} className="d-flex justify-content-between">
                            <div>
                                <h4>{contractorDictionaryWrapper.getContractorName(contractorsDictionary, contractorId)}</h4>
                            </div>
                            <div className="d-flex align-items-center">
                                <CloseButton onClick={() => handlerClose()}/>
                            </div>
                        </Col>
                        <Col xs={12} className={"d-flex fw-bold"}>
                            <h5 className="d-flex justify-content-start fw-bold me-2">{t(lang, "Текущий долг")}:</h5>
                            <div className="d-flex flex-wrap justify-content-between fs-1">
                                {contractor?.debts?.length > 0 ? contractor?.debts.map((item, index) => {
                                    if (item.amount){
                                        return (
                                            <div key={index} className="d-flex justify-content-start mx-2">
                                                {numeral.formats[numberFormat].format(item.amount)} <span className="text-info ms-1">{item.currency.name}</span>
                                            </div>
                                        )
                                    } else {
                                        return '0 сум'
                                    }
                                }) : '0 сум'}
                            </div>
                        </Col>
                    </Row>
                </Modal.Header>
                <FormProvider {...form}>
                    <Form onSubmit={form.handleSubmit(onSubmit)}>
                        <Modal.Body>
                            <WOWithDiscountPaymentForm
                                controlledFieldsExactDiscounts={controlledFieldsExactDiscounts}
                                addNewRowToExactDiscounts={addNewRowToExactDiscounts}
                                onTrashExactDiscounts={onTrashExactDiscounts}
                                onChangeExactDiscountsCurrencyId={onChangeExactDiscountsCurrencyId}
                                totals={totalAmounts}

                                controlledFieldsCashBackPay={controlledFieldsCashBackPay}
                                onChangeCashBackPayPaymentType={onChangeCashBackPayPaymentType}
                                addNewRowToCashBackPay={addNewRowToCashBackPay}
                                onTrashCashBackPay={onTrashCashBackPay}
                                onChangeCashBackPayCurrencyId={onChangeCashBackPayCurrencyId}

                                controlledFieldsCustomerDebtPay={controlledFieldsCustomerDebtPay}
                                addNewRowToCustomerDebtPay={addNewRowToCustomerDebtPay}
                                onTrashCustomerDebtPay={onTrashCustomerDebtPay}
                                onChangeCustomerDebtPayCurrencyId={onChangeCustomerDebtPayCurrencyId}


                                addNewPaymentRowToCashBoxStates={addNewPaymentRowToCashBoxStates}
                                onChangeCashBoxStatesPaymentType={onChangeCashBoxStatesPaymentType}
                                onChangeCashBoxStatesCurrencyCode={onChangeCashBoxStatesCurrencyCode}
                                onTrashCashBoxStates={onTrashCashBoxStates}
                                fieldsCashBoxStates={fieldsCashBoxStates}
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handlerClose}>
                                {t(lang, "warehouse.operation.item.common.close")}
                            </Button>
                            <Button variant="falcon-info" type="button" onClick={form.handleSubmit(onSubmit)} disabled={loading}>
                                {loading &&
                                    <Spinner className='align-middle me-2' animation='border' size="sm"
                                             role='switch'/>
                                }
                                {t(lang, "items.common.save")}
                            </Button>
                        </Modal.Footer>
                    </Form>
                </FormProvider>
            </Modal>
        </>
    );
};

export default WOWIthDiscountPayment;
