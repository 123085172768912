import ActionButton from "../../../common/ActionButton";
import React, {useState} from "react";
import {getOrganizationUsersAsync, removeUserAsync, selectAccount} from "../../../../app/store/reducers/auth/authReducer";
import {toast} from "react-toastify";
import {Button, Modal} from "react-bootstrap";
import FalconCloseButton from "../../../common/FalconCloseButton";
import {useDispatch, useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";


const DeleteUser = ({username}) => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const dispatch = useDispatch()
    const account = useSelector(selectAccount)
    const [isShow, setIsShow] = useState(false)


    const onDeleteUser = async () => {
        await removeUserAsync({username})
        dispatch(getOrganizationUsersAsync())
        toast.success(t(lang, "edi.common.user_organization_delete"))
        setIsShow(false)
    }


    const handlerClose = () => {
        setIsShow(false)
    }

    const handlerOpen = () => {
        setIsShow(true)
    }


    return (
        <>
            <ActionButton
                icon="trash-alt"
                title="Удалить"
                variant="action"
                className={username !== account.username ? "p-0 text-danger d-inline" : "p-0 text-danger d-none"}
                onClick={handlerOpen}
            />


            <Modal show={isShow}>
                <Modal.Header>
                    <Modal.Title>{t(lang, "edi.common.user_organization_delete_title")}</Modal.Title>
                    <FalconCloseButton onClick={handlerClose}/>
                </Modal.Header>
                <Modal.Body>
                    <p>{t(lang, "edi.common.user_organization_delete_ask")}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handlerClose}>{t(lang, "edi.common.select_yes_or_no.option.no")}</Button>
                    <Button variant="danger" onClick={onDeleteUser}>{t(lang, "edi.common.select_yes_or_no.option.yes")}</Button>
                </Modal.Footer>
            </Modal>

        </>
    )
}

export default DeleteUser;