import React from 'react';
import dayjs from "dayjs";
import {Form} from "react-bootstrap";
import {toast} from "react-toastify";
import {useSelector} from "react-redux";
import ShipmentForm from "./forms/ShipmentForm";
import {FormProvider, useForm} from "react-hook-form";
import ConvertQuantityToWord from "../../../app/filter/ConvertQuantityToWord";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";
import {selectProductCatalogs} from "../../../app/store/reducers/invoice/invoiceReducer";
import {selectCompanyCatalogs} from "../../../app/store/reducers/roaming/roamingReducer";
import {
    registerShipmentAsync,
    saveShipmentProductCatalogs, selectShipmentProductCatalogs
} from "../../../app/store/reducers/shipment/shipmentReducer";
import {selectEdiSettings, updateEdiSettings} from "../../../app/store/reducers/settings/ediSettingsReducer";
import {Roadway} from "../roaming/waybill/forms/SendWaybillFormV2";
import {selectActiveOrganization} from "../../../app/store/reducers/auth/authReducer";

const AddShipmentForm = ({order, organizationInfo}) => {
    const productCatalogsShipment = useSelector(selectShipmentProductCatalogs);
    const catalogs = useSelector(selectCompanyCatalogs);
    const settings = useSelector(selectEdiSettings)
    const lang = useSelector(selectLang);
    const t = Translate;

    const methods = useForm({
        defaultValues: {
            branch_code: order.branch?.code || null,
            order_info: {
                number: order?.info?.number,
                date: dayjs(order?.info?.date, 'YYYY-MM-DD').toDate(),
                expires_on: dayjs(order?.expires_on, 'YYYY-MM-DD').toDate(),
            },
            shipment_info: {
                number: '',
                date: new Date(),
            },
            contract_info: {
                number: '',
                date: null,
            },
            transport: {
                registration_number: {
                    registration_number: '',
                    model: '',
                },
            },
            customer_shipment_id: order.customer_shipment_id,
            items: order.items.map((item, index) => {
                const companyCatalog = catalogs.find(c => c.class_code === productCatalogsShipment?.[item.product]?.catalogClassCode);

                return {
                    uid: index,
                    id: item.id,
                    product: item.product,
                    barcode: item.barcode,
                    customer_shipment_id: item.customer_shipment_id,
                    measurement: item.measurement,
                    quantity: item.quantity_left,
                    price: item.price,
                    total: item.total,
                    nds_rate: item.nds_rate,
                    nds_value: item.nds_value,
                    quantity_left: item.quantity_left,
                    total_with_nds: item.total_with_nds,
                    catalog_class_name:  companyCatalog?.name || item.catalog_class_name || '',
                    catalog_class_code: companyCatalog?.class_code || item.catalog_class_code ||  '',
                    package_code: companyCatalog?.package_names?.find(i=>i.code.toString() === productCatalogsShipment?.[item.product]?.packageCode)?.code || '',
                    package_name: companyCatalog?.package_names?.find(i=>i.code.toString() ===  productCatalogsShipment?.[item.product]?.packageCode)?.name_ru || '',
                    benefit: item.benefit,
                    marks: [{code: ""}]
                }
            }),
            totalWithNds: null,
            executor: {
                inn_or_pinfl:
                    organizationInfo?.person_num || organizationInfo?.inn ||
                    order?.executor?.inn || order?.executor?.inn_or_pinfl ||  null,
                name:
                    organizationInfo?.name ||
                    order?.executor?.name || null,
            },
            customer: {
                inn_or_pinfl:
                    order?.customer_info?.inn || order?.customer_info?.pinfl ||
                    order?.customer?.inn || order?.customer?.inn_or_pinfl || null,
                name:
                    order?.customer_info?.name ||
                    order?.customer?.name || null,
            },
            freight_forwarder: {
                inn_or_pinfl:
                    organizationInfo?.person_num || organizationInfo?.inn || null,
                name:
                    organizationInfo?.name || null,
            },
            carrier: {
                inn_or_pinfl:  organizationInfo?.person_num || organizationInfo?.inn || null,
                name:  organizationInfo?.name ||null,
            },
            responsible_person: {
                inn_or_pinfl:
                    organizationInfo?.person_num || organizationInfo?.director_inn || null,
                name:
                    organizationInfo?.person_num ? organizationInfo?.name : organizationInfo?.director || null,
            },
            driver: {
                inn_or_pinfl: organizationInfo?.person_num || organizationInfo?.director_inn || null,
                name: organizationInfo?.person_num ? organizationInfo?.name : organizationInfo?.director || null,
            },
            total_distance: +order?.total_distance || null,
            delivery_cost: +order?.delivery_cost || null,
            total_delivery_cost: +order?.total_delivery_cost || null,
            transport_type: Roadway,
            executor_is_freight_forwarder: (organizationInfo?.person_num || organizationInfo?.inn) && true || false,
            customer_is_freight_forwarder: false,
            freight_forwarder_is_carrier: (organizationInfo?.person_num || organizationInfo?.inn) && true || false,
            responsible_person_is_driver: (organizationInfo?.person_num || organizationInfo?.inn)&& true || false,
            payer_contract_info: {
                number: null,
                date: new Date()
            },
            is_hide_additional_information: false
        }
    });

    const onSubmit = (form) => {
        const activeDriver = form?.car_info?.drivers?.find(d => d?.label === form?.car_info?.name)
        const activeNumber = form?.car_info?.numbers?.find(d => d?.label === form?.car_info?.serial_number)
        const activeModel = form?.car_info?.models?.find(d => d?.label === form?.car_info?.mark)

        const total_distance = typeof form.total_distance === 'string' ? form.total_distance.replace(/ /g, '') : form.total_distance
        const delivery_cost = typeof form.delivery_cost === 'string' ? form.delivery_cost.replace(/ /g, '') : form.delivery_cost
        const total_delivery_cost = typeof form.total_delivery_cost === 'string' ? form.total_delivery_cost.replace(/ /g, '') : form.total_delivery_cost


        const shipmentSettingsJson = {
            edi: {
                shipment: {
                    vehicles: {
                        drivers: form?.car_info?.drivers.map((driver) => {
                            return{
                                label: driver.label,
                                is_active: activeDriver?.label === driver?.label
                            }
                        }),
                        numbers: form?.car_info?.numbers.map((driver) => {
                            return{
                                label: driver.label,
                                is_active: activeNumber?.label === driver?.label
                            }
                        }),
                        models: form?.car_info?.models.map((driver) => {
                            return{
                                label: driver.label,
                                is_active: activeModel?.label === driver?.label
                            }
                        })
                    }
                },
                return_shipment: {
                    vehicles: settings?.return_shipment?.vehicles
                },
                should_validate_invoice_number: settings?.should_validate_invoice_number
            },
        }

        const payload = {
            order_id: order.id,
            shipment_info: {
                number: form.shipment_info.number,
                date: dayjs(form.shipment_info.date).format('YYYY-MM-DD'),
            },
            contract_info: {
                number: form.contract_info.number,
                date: dayjs(form.contract_info.date).format('YYYY-MM-DD'),
            },
            customer_info: {
                inn: order.customer.inn,
                name: order.customer.name
            },
            executor_info: {
                inn: order.executor.inn,
                name: order.executor.name,
            },
            car_info: {
                serial_number: form.transport?.registration_number?.registration_number || null,
                mark: form.transport?.registration_number?.model || null,
            },
            total_in_words: ConvertQuantityToWord.getText(parseInt(form.totalWithNds)),
            total_distance: +total_distance,
            delivery_cost: +delivery_cost,
            total_delivery_cost: +total_delivery_cost,
            loading_point: {
                address: form.loading_point.street || null,
                region_code: form.loading_point.region_code || null, // required when company_address_id = null and branch_id = null
                region_name: form.loading_point.region_name || null, // required when company_address_id = null and branch_id = null
                district_code: form.loading_point.district_code || null, // required when company_address_id = null and branch_id = null
                district_name: form.loading_point.district_name || null, // required when company_address_id = null and branch_id = null
                village_code: form.loading_point.village_code || null, // required when company_address_id = null and branch_id = null
                village_name: form.loading_point.village_name || null, // required when company_address_id = null and branch_id = null
                longitude: +form.loading_point.longitude || null, // required when company_address_id = null and branch_id = null
                latitude: +form.loading_point.latitude || null,// required when company_address_id = null and branch_id = null
            },
            unloading_point: {
                address: form.unloading_point.street || null,
                region_code: form.unloading_point.region_code || null, // required when company_address_id = null and branch_id = null
                region_name: form.unloading_point.region_name || null, // required when company_address_id = null and branch_id = null
                district_code: form.unloading_point.district_code || null, // required when company_address_id = null and branch_id = null
                district_name: form.unloading_point.district_name || null, // required when company_address_id = null and branch_id = null
                village_code: form.unloading_point.village_code || null, // required when company_address_id = null and branch_id = null
                village_name: form.unloading_point.village_name || null, // required when company_address_id = null and branch_id = null
                longitude: +form.unloading_point.longitude || null, // required when company_address_id = null and branch_id = null
                latitude: +form.unloading_point.latitude || null,// required when company_address_id = null and branch_id = null
            },
            items: form.items
                .filter(item => item.quantity > 0)
                // TODO packege_name
                .map(item => {
                    return {
                        order_item_id: item.id,
                        product: item.product,
                        quantity: +item.quantity,
                        barcode: item.barcode,
                        catalog_name: item.catalog_class_name || null,
                        catalog_code: item.catalog_class_code || null,
                        package_code: item.package_code?.code?.toString() || item.package_code?.toString() || item.package_code,
                        package_name: item.package_code?.code && (lang === 'ru' ? item.package_code?.name_ru : item.package_code?.name_uz) || item.package_name,
                        marks: item.marks.filter(i => i.code).map(i => i.code)
                    }
                })
        }

        if (form.freight_forwarder.inn_or_pinfl) {
            payload['freight_forwarder'] = {
                inn: form.freight_forwarder.inn_or_pinfl || null,
                name: form.freight_forwarder.name || null
            }
        }

        if (form.carrier.inn_or_pinfl) {
            payload['carrier'] = {
                inn: form.carrier.inn_or_pinfl || null,
                name: form.carrier.name || null
            }
        }

        if (form.responsible_person.inn_or_pinfl) {
            payload['responsible_person'] = {
                inn: form.responsible_person.inn_or_pinfl || null,
                name: form.responsible_person.name || null
            }
        }

        if (form.driver.inn_or_pinfl) {
            payload['driver'] = {
                inn: form.driver.inn_or_pinfl || null,
                name: form.driver.name || null
            }
        }

        if (form.freight_forwarder?.inn_or_pinfl !== form.carrier?.inn_or_pinfl) {
            payload['payer_contract_info'] = {
                number: form.payer_contract_info?.number,
                date: dayjs(form.payer_contract_info?.date).format('YYYY-MM-DD'),
            }
        }

        if (form.is_hide_additional_information || !form.loading_point?.district_code) {
            payload['loading_point'] = null
        }

        if (form.is_hide_additional_information || !form.unloading_point?.district_code) {
            payload['unloading_point'] = null
        }

        registerShipmentAsync(payload)
            .then( async (shipment) => {

                const productKeys = shipment.items.map((item) => {
                    return {
                        product: item.product,
                        catalogClassCode: item.catalog_class_code,
                        packageCode: item.package_code || "",
                    }
                });
                saveShipmentProductCatalogs(productKeys)

                toast.success(t(lang, 'edi.toast.register_shipment_succeed'))
                await updateEdiSettings({payload: shipmentSettingsJson})
                    .then(() => {})
                    .catch(() => {})
            })
            .catch(() => toast.error(t(lang, 'edi.toast.register_shipment_failed')))
    }

    return (
        <>
            <FormProvider {...methods}>
                <Form onSubmit={methods.handleSubmit(onSubmit)}>
                    <ShipmentForm order={order}/>
                </Form>
            </FormProvider>
        </>
    );
};

export default AddShipmentForm;
