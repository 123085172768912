import React, {Fragment} from 'react';
import {Card, Col, Form, Nav, Row, Tab} from "react-bootstrap";
import {Controller} from "react-hook-form";
import SelectMeasurement from "../components/SelectMeasurement";
import PackageMeasurementRow from "../components/PackageMeasurementRow";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import SelectWarehouse from "../components/SelectWarehouse";
import Cleave from "cleave.js/react";
import ItemAlertOn from "../components/ItemAlertOnState";

const ItemFormCardStateAndMeasurements = ({isEditing}) => {
    const t = Translate;
    const lang = useSelector(selectLang);

    return (
        <Card className="h-100">
            <Tab.Container defaultActiveKey={'1'}>
                <Card.Header>
                    <Nav variant="tabs">
                        <Nav.Item className="cursor-pointer">
                            <Nav.Link eventKey={'1'}>
                                <Card.Title>{t(lang, 'items.common.measurement')}</Card.Title>
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="cursor-pointer">
                            <Nav.Link eventKey={'2'}>
                                <Card.Title>{t(lang, 'items.common.state')}</Card.Title>
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="cursor-pointer">
                            <Nav.Link eventKey={'3'}>
                                <Card.Title>{t(lang, 'items.common.package_measurement')}</Card.Title>
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Card.Header>
                <Tab.Content>
                    <Tab.Pane eventKey={'1'}>
                        <Card.Body>
                            <Row>
                                <Col xs={12}>
                                    <Form.Group>
                                        <Form.Label>
                                            {t(lang, 'items.common.measurement')}
                                            <span className="text-danger">*</span>
                                        </Form.Label>
                                        <Controller name={'measurement'}
                                                    render={({field}) => (
                                                        <SelectMeasurement defaultMeasurement={field.value}
                                                                           onChange={field.onChange}
                                                        />
                                                    )}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Tab.Pane>
                    <Tab.Pane eventKey={'2'}>
                        <Card.Body>
                            <Row>
                                {!isEditing && <Fragment>
                                    <Col xs={12}>
                                        <Form.Group>
                                            <Form.Label>{t(lang, 'items.common.warehouse')}</Form.Label>
                                            <Controller name={`warehouseId`}
                                                        render={({field}) => (
                                                            <SelectWarehouse defaultWarehouseId={field.value}
                                                                             onChange={(option) => field.onChange(option ? option.id : null)}
                                                                             selectProps={{
                                                                                 isSearchable: false
                                                                             }}
                                                            />
                                                        )}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12}>
                                        <Form.Group>
                                            <Form.Label>{t(lang, 'items.common.state')}</Form.Label>
                                        </Form.Group>
                                        <Controller name={`state`}
                                                    render={({field}) => (
                                                        <Cleave htmlRef={field.ref}
                                                                className="form-control"
                                                                placeholder={t(lang, 'items.common.state.placeholder')}
                                                                onKeyDown={(e) => {
                                                                    if (e.keyCode === 188)
                                                                        e.target.value += "."
                                                                }}
                                                                options={{
                                                                    numeral: true,
                                                                    delimiter: ' ',
                                                                    numeralDecimalScale: 3,
                                                                }}
                                                                value={field.value}
                                                                onChange={e => field.onChange(e.target.rawValue)}

                                                        />
                                                    )}
                                        />
                                    </Col>
                                    <Col xs={12}>
                                        <Form.Group>
                                            <Form.Label>{t(lang, 'items.common.alert_on.state')}</Form.Label>
                                            <Controller
                                                name={'alertOn'}
                                                render={({field}) => (
                                                    <Cleave htmlRef={field.ref}
                                                            className="form-control"
                                                            placeholder={t(lang, 'items.common.state.placeholder')}
                                                            onKeyDown={(e) => {
                                                                if (e.keyCode === 188)
                                                                    e.target.value += "."
                                                            }}
                                                            options={{
                                                                numeral: true,
                                                                delimiter: ' ',
                                                                numeralDecimalScale: 3,
                                                            }}
                                                            value={field.value}
                                                            onChange={e => field.onChange(e.target.rawValue)}
                                                    />
                                                )}
                                            />
                                        </Form.Group>
                                </Col>
                                </Fragment>}

                                {isEditing && <Form.Group as={Col} xs={12}>
                                    <Form.Label>{t(lang, "items.common.alert_on.state")}</Form.Label>
                                    <ItemAlertOn />
                                </Form.Group>}

                            </Row>
                        </Card.Body>
                    </Tab.Pane>
                    <Tab.Pane eventKey={'3'}>
                        <Card.Body>
                            <Row>
                                <Col xs={12}>
                                    <PackageMeasurementRow fieldName={"packageMeasurements"}/>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        </Card>
    );
};

export default ItemFormCardStateAndMeasurements;
