import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {Modal} from "react-bootstrap";
import FalconCloseButton from "../../../../common/FalconCloseButton";
import ContractorLabelForm from "./ContractorLabelForm";
import {editContractorLabelAsync} from "../../../../../app/store/reducers/contractor/contractorLabelReducer";

const EditContractorLabelForm = ({contractorLabel, onClose}) => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const [loading, setLoading] = useState(false);

    const handleOnSubmit = (formData) => {
        setLoading(true)
        editContractorLabelAsync(contractorLabel.id, {
            name: formData.name,
        }).then(res => {
            setLoading(false)
            onClose()
        }).catch(err => {
            console.log(err)
        });
    }
    return (<>
        <Modal show={true} onHide={onClose} size="xl">
            <Modal.Header>
                <Modal.Title>{t(lang, "crm.contractor.label.edit_form_title")}</Modal.Title>
                <FalconCloseButton onClick={onClose}/>
            </Modal.Header>
            <Modal.Body>
                <ContractorLabelForm contractorLabel={contractorLabel} onClose={onClose} loading={loading} onSubmit={handleOnSubmit}/>
            </Modal.Body>
        </Modal>
    </>)
}
export default EditContractorLabelForm