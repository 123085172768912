import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    editTaskCard, getItem,
    selectKanbanItems,
    selectModalContent, updateModalContent
} from "../../../../app/store/reducers/kanban/kanbanReducer";
import {FormProvider, useForm} from "react-hook-form";
import {Form} from "react-bootstrap";
import ModalForm from "./ModalForm";
import EventBus from "../../../../app/eventbus/EventBus";
import {
    ITEM_DELETE_ASSIGNER_FAIL,
    ITEM_DELETE_ASSIGNER_SUCCESS, ITEM_DELETE_COMMENT_FAIL,
    ITEM_DELETE_COMMENT_SUCCESS, ITEM_REGISTER_ASSIGNER_FAIL,
    ITEM_REGISTER_ASSIGNER_SUCCESS, ITEM_REGISTER_COMMENT_FAIL,
    ITEM_REGISTER_COMMENT_SUCCESS, ITEM_UPDATE_ASSIGNER_FAIL, ITEM_UPDATE_ASSIGNER_SUCCESS, ITEM_UPDATE_COMMENT_FAIL,
    ITEM_UPDATE_COMMENT_SUCCESS
} from "../../../../app/eventbus/kanban/KanbanEvents";
import {toast} from "react-toastify";
import PropTypes from "prop-types";

const EditFormTask = () => {

    const dispatch = useDispatch()
    const modalContent = useSelector(selectModalContent)

    useEffect(() => {
        // assigner success
        const onItemRegisterAssignerHandlerSuccess = EventBus.on(ITEM_REGISTER_ASSIGNER_SUCCESS, () => {
            dispatch(getItem(modalContent?.id))
        })
        const onItemUpdateAssignerHandlerSuccess = EventBus.on(ITEM_UPDATE_ASSIGNER_SUCCESS, () => {
            dispatch(getItem(modalContent?.id))
        })
        const onItemDeleteAssignerHandlerSuccess = EventBus.on(ITEM_DELETE_ASSIGNER_SUCCESS, () => {
            dispatch(getItem(modalContent?.id))
        })

        // comment success
        const onItemRegisterCommentHandlerSuccess = EventBus.on(ITEM_REGISTER_COMMENT_SUCCESS, () => {
            dispatch(getItem(modalContent?.id))
        })
        const onItemUpdateCommentHandlerSuccess = EventBus.on(ITEM_UPDATE_COMMENT_SUCCESS, () => {
            dispatch(getItem(modalContent?.id))
        })
        const onItemDeleteCommentHandlerSuccess = EventBus.on(ITEM_DELETE_COMMENT_SUCCESS, () => {
            dispatch(getItem(modalContent?.id))
        })

        // assigner fail
        const onItemRegisterAssignerHandlerFailed = EventBus.on(ITEM_REGISTER_ASSIGNER_FAIL, () => {
            toast.error("Error")
        })
        const onItemUpdateAssignerHandlerFailed = EventBus.on(ITEM_UPDATE_ASSIGNER_FAIL, () => {
            toast.error("Error")
        })
        const onItemDeleteAssignerHandlerFailed = EventBus.on(ITEM_DELETE_ASSIGNER_FAIL, () => {
            toast.error("Error")
        })

        // comment fail
        const onItemRegisterCommentHandlerFailed = EventBus.on(ITEM_REGISTER_COMMENT_FAIL, () => {
            toast.error("Error")
        })
        const onItemUpdateCommentHandlerFailed = EventBus.on(ITEM_UPDATE_COMMENT_FAIL, () => {
            toast.error("Error")
        })
        const onItemDeleteCommentHandlerFailed = EventBus.on(ITEM_DELETE_COMMENT_FAIL, () => {
            toast.error("Error")
        })

        return () => {
            // assigner
            EventBus.remove(ITEM_REGISTER_ASSIGNER_SUCCESS, onItemRegisterAssignerHandlerSuccess)
            EventBus.remove(ITEM_REGISTER_ASSIGNER_FAIL, onItemRegisterAssignerHandlerFailed)

            EventBus.remove(ITEM_UPDATE_ASSIGNER_SUCCESS, onItemUpdateAssignerHandlerSuccess)
            EventBus.remove(ITEM_UPDATE_ASSIGNER_FAIL, onItemUpdateAssignerHandlerFailed)

            EventBus.remove(ITEM_DELETE_ASSIGNER_SUCCESS, onItemDeleteAssignerHandlerSuccess)
            EventBus.remove(ITEM_DELETE_ASSIGNER_FAIL, onItemDeleteAssignerHandlerFailed)

            // comment
            EventBus.remove(ITEM_REGISTER_COMMENT_SUCCESS, onItemRegisterCommentHandlerSuccess)
            EventBus.remove(ITEM_REGISTER_COMMENT_FAIL, onItemRegisterCommentHandlerFailed)

            EventBus.remove(ITEM_UPDATE_COMMENT_SUCCESS, onItemUpdateCommentHandlerSuccess)
            EventBus.remove(ITEM_UPDATE_COMMENT_FAIL, onItemUpdateCommentHandlerFailed)

            EventBus.remove(ITEM_DELETE_COMMENT_SUCCESS, onItemDeleteCommentHandlerSuccess)
            EventBus.remove(ITEM_DELETE_COMMENT_FAIL, onItemDeleteCommentHandlerFailed)
        }

    }, [])

    const methods = useForm({
        defaultValues: {
            title: modalContent?.title,
            description: modalContent?.description,
            assigner: modalContent?.assigner?.assigner?.id || null,
            reporter: modalContent?.reporter?.id,
            members: modalContent?.members,
            attachments: modalContent?.attachments,
            labels: modalContent?.labels.map(i => {
                return {...i, label_id: i.id}
            }),
            comments: modalContent?.comments.map(i => {
                return {...i, comment_id: i.id}
            })
        }
    })

    useEffect(() => {
        methods.setValue('comments',  modalContent?.comments.map(i => {
            return {...i, comment_id: i.id}
        }))
        methods.setValue('assigner', modalContent?.assigner?.assigner?.id)
        methods.setValue('assigner', modalContent?.assigner?.assigner?.id)
        methods.setValue('labels', modalContent?.labels.map(i => {
            return {...i, label_id: i.id}
        }))
    }, [modalContent])


    return (
        <FormProvider {...methods}>
            <ModalForm edit id={modalContent.id}/>
        </FormProvider>
    );
};

EditFormTask.propTypes = {
    setShow: PropTypes.func
}
export default EditFormTask;
