import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    changeLoader,
    selectKanbanItems, selectModalContent,
} from "../../../app/store/reducers/kanban/kanbanReducer";
import {DragDropContext} from "react-beautiful-dnd";
import KanbanColumn from "./KanbanColumn";
import AddAnotherForm from "./AddAnotherForm";
import IconButton from "../../common/IconButton";
import KanbanModal from "../modal/kanban/KanbanModal";
import is from 'is_js';
import ItemChangeStatusModal from "../modal/ItemChangeStatusModal";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";


const KanbanContainer = ({loading}) => {

    const lang = useSelector(selectLang);
    const t = Translate;

    const dispatch = useDispatch()
    const [showModal, setShowModal] = useState(false)
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    const [dragEnd, setDragEnd] = useState(null)
    const [showForm, setShowForm] = useState(false);
    const [columnId, setColumnId] = useState(null);
    const containerRef = useRef(null);
    const kanbanItems = useSelector(selectKanbanItems)
    const modalContent = useSelector(selectModalContent)

    useEffect(() => {
        if (is.ipad()) {
            containerRef.current.classList.add('ipad');
        }

        if (is.mobile()) {
            containerRef.current.classList.add('mobile');
            if (is.safari()) {
                containerRef.current.classList.add('safari');
            }
            if (is.chrome()) {
                containerRef.current.classList.add('chrome');
            }
        }
    }, []);


    useEffect(() => {
        const timeout = setTimeout(() => {
            containerRef.current.scrollIntoView({ behavior: 'smooth' });
        }, 500);

        return clearTimeout(timeout);
    }, [showForm]);

    const getColumn = (id) => {
        return kanbanItems.find(item => item.id === Number(id));
    };

    const reorderArray = (array, fromIndex, toIndex) => {
        const newArr = [...array];
        const chosenItem = newArr.splice(fromIndex, 1)[0];
        newArr.splice(toIndex, 0, chosenItem);

        return newArr;
    };

    const move = (source, destination) => {
        const sourceItemsClone = getColumn(source.droppableId);
        const destItemsClone = getColumn(destination.droppableId);
        const [removedItem] = sourceItemsClone.splice(source.index, 1);
        destItemsClone.splice(destination.index, 0, removedItem);

        return {
            updatedDestItems: destItemsClone,
            updatedSourceItems: sourceItemsClone
        };
    };

    const onChangeUpdaterMember = (updater) => {
        return new Promise((resolve, reject) => {
            if (updater) {
                resolve(true)
            } else {
                reject(true)
            }
        })
    }

    const handleDragEnd  = (result) => {
        dispatch(changeLoader(true))
        const { source, destination } = result;

        if (!destination || source.droppableId === destination.droppableId) {
           return dispatch(changeLoader(false))
        }

        setDragEnd(result)
        setShowConfirmationModal(true)
    };

    return (
        <DragDropContext onDragEnd={handleDragEnd}>
            <div style={{height: '100vh',  opacity: loading && 0.2}} className="kanban-container me-n3 scrollbar " ref={containerRef}>
                {kanbanItems?.map(kanbanColumnItem => (
                    <KanbanColumn
                        key={kanbanColumnItem.id}
                        kanbanColumnItem={kanbanColumnItem}
                        showModal={showModal}
                        setShowModal={setShowModal}
                        setShowForm={setShowForm}
                        showForm={showForm}
                        setColumnId={setColumnId}
                    />
                ))}
                <div className="kanban-column">
                    <AddAnotherForm
                        type="list"
                        showForm={showForm}
                        setShowForm={setShowForm}
                    />
                    {!showForm && (
                        <IconButton
                            variant="primary"
                            className="d-block w-100"
                            icon="plus"
                            iconClassName="me-1"
                            onClick={() => setShowForm(true)}
                        >
                            {t(lang, 'task.board.add.new-status.button.title')}
                        </IconButton>
                    )}
                </div>

                <KanbanModal show={showModal} setShow={setShowModal} id={columnId} edit={!!Object.keys(modalContent).length}/>
                <ItemChangeStatusModal getColumn={getColumn} dragEnd={dragEnd} setDragEnd={setDragEnd} onChangeUpdaterMember={onChangeUpdaterMember} show={showConfirmationModal} setShow={setShowConfirmationModal}  />
            </div>
        </DragDropContext>
    );
};

export default KanbanContainer;
