import React from 'react';
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import ReactSelect from "react-select";
import organizationBindingWrapper, {
    ItemsAccessTypes
} from "../../../../enum/organizationBinding/OrganizationBindingWrapper";

const SelectItemsAccessType = ({onChange, defaultItemsAccessType}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const options = ItemsAccessTypes;

    return (
        <ReactSelect options={options}
                     isSearchable={!!defaultItemsAccessType}
                     placeholder={t(lang, 'items.common.select')}
                     getOptionLabel={option => t(lang, option.label)}
                     getOptionValue={(option) => option.value}
                     onChange={(option) => onChange(option.value)}
                     value={options.find(o => o.value === defaultItemsAccessType) || null}
        />
    );
};

export default SelectItemsAccessType;