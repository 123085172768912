import axios from "axios";

const taskBoard = '/task-board'
class KanbanService {
    // board
    boardRegister(name) {
        return axios.post(`${taskBoard}/board/register`, name)
    }

    boardChangeName({id, name}) {
        return axios.post(`${taskBoard}/board/change-name/${id}`, {name})
    }

    boardGet(id) {
        return axios.get(`${taskBoard}/board/get/${id}`)
    }

    boardItemsGet(id) {
        return axios.get(`${taskBoard}/board/items/get/${id}`)
    }

    boardOrganizationGet() {
        return axios.get(`${taskBoard}/board/organization/get`)
    }

    // member

    memberRegister(data) {
        return axios.post(`${taskBoard}/member/register`, data)
    }

    memberDelete(id) {
        return axios.post(`${taskBoard}/member/delete/${id}`)
    }

    memberChangeName(id,data) {
        return axios.post(`${taskBoard}/member/change-name/${id}`,data)
    }

    //status

    statusRegister(data) {
        return axios.post(`${taskBoard}/status/register`,data)
    }

    statusDelete(id) {
        return axios.post(`${taskBoard}/status/delete/${id}`)
    }

    statusUpdate(data) {
        return axios.post(`${taskBoard}/status/update`, data)
    }

    // item
    itemRegister(data) {
        return axios.post(`${taskBoard}/item/register`, data)
    }

    getItem(id) {
        return axios.get(`${taskBoard}/item/get/${id}`)
    }

    itemRequestAssigner(itemId, params) {
        return axios.post(`${taskBoard}/item/add/assigner/${itemId}`, params)
    }

    itemDeleteAssigner(itemId, params) {
        return axios.post(`${taskBoard}/item/delete/assigner/${itemId}`, params)
    }

    itemUpdateAssigner(itemId, params) {
        return axios.post(`${taskBoard}/item/change/assigner/${itemId}`, params)
    }

    // comment

    itemRegisterComment(itemId, params) {
        return axios.post(`${taskBoard}/item/add/comment/${itemId}`, params)
    }

    itemUpdateComment(commentId, params) {
        return axios.post(`${taskBoard}/item/update/comment/${commentId}`, params)
    }

    itemDeleteComment(commentId, itemId) {
        return axios.post(`${taskBoard}/item/delete/comment/${commentId}`, {
            item_id: itemId
        })
    }

    // label
    itemRegisterLabel(itemId, params) {
        return axios.post(`${taskBoard}/item/add/label/${itemId}`, {
            label: [params]
        })
    }

    itemUpdateLabel() {
        return axios.post(`${taskBoard}/item/update/label`)
    }

    itemDeleteLabel() {
        return axios.post(`${taskBoard}/item/delete/label`)
    }

    itemChangeStatus(id, params) {
        return axios.post(`${taskBoard}/item/change/status/${id}`, params)
    }

    getItemStatusChange(id) {
        return axios.get(`${taskBoard}/item/status-changes/get/${id}`)
    }
    getItemMemberChange(id) {
        return axios.get(`${taskBoard}/item/member-changes/get/${id}`)
    }



}

const kanbanService = new KanbanService()
export default kanbanService
