import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {Row, Col, Form, Nav, Alert, OverlayTrigger, Tooltip} from 'react-bootstrap';
import IconButton from "../../../../common/IconButton";
import {Link} from "react-router-dom";
import {selectLang, selectNumberFormat, Translate} from '../../../../../app/store/reducers/main/mainReducer';
import {useDispatch, useSelector} from 'react-redux';
import {faFileExcel} from "@fortawesome/free-solid-svg-icons";
import {useForm, FormProvider} from "react-hook-form";
import {
    changeFilterOptionsAsync, clearFilterOptions, selectCount,
    selectInvoiceFilterOptions,
} from "../../../../../app/store/reducers/roaming/roamingInvoiceReducer";
import RoamingInvoiceDataTableFilter from "./RoamingInvoiceDataTableFilter";
import dayjs from "dayjs";
import roamingInvoiceWrapper, {
    defaultRoamingInvoiceDatatableColumns,
    DRAFT,
    StatusSentToCustomer,
    VIEWER_CUSTOMER,
    VIEWER_EXECUTOR,
    VIEWER_PERSON
} from "../../../../../enum/RoamingInvoiceWrapper";
import UploadRoamingInvoiceListToExcel from "../excel/UploadRoamingInvoiceListToExcel";
import RoamingInvoiceCustomerDecline from "../actions/RoamingInvoiceCustomerDecline";
import RoamingInvoicePersonDecline from "../actions/RoamingInvoicePersonDecline";
import RoamingInvoiceCustomerAccept from "../actions/RoamingInvoiceCustomerAccept";
import RoamingInvoicePersonAccept from "../actions/RoamingInvoicePersonAccept";
import RoamingInvoiceExecutorCancel from "../actions/RoamingInvoiceExecutorCancel";
import RoamingInvoiceDraftDelete from "../actions/RoamingInvoiceDraftDelete";
import RoamingInvoiceDraftSend from "../actions/RoamingInvoiceDraftSend";
import ActionProgressBar from "../../../../common/ActionProgressBar";
import numeral from 'numeral';
import DatatableSettingsControl from "../../../../common/datatable-settings/DatatableSettingsControl";
import {useLocation} from "react-router";
import {selectSettings} from "../../../../../app/store/reducers/settings/edoSettingsReducer";
import checkPermission from "../../../../../enum/Permission/CheckPermission";
import {PermissionRoamingInvoiceCreate} from "../../../../../enum/Permission/RoamingPermission";
import toastPermission from "../../../../../enum/Permission/ToastPermission";
import RoamingInvoiceDraftSendAll from "../actions/RoamingInvoiceDraftSendAll";


const RoamingInvoiceDataTableHeader = ({
                                           returnInvoice,
                                           excelRegistry,
                                           viewer,
                                           predefinedFilters,
                                           selectedRowIds,
                                           page,
                                           loadInvoices,
                                           positiveSumPage,
                                           negativeSumPage,
                                           changeFilterOptionsAsync,
                                           filters,
                                           count,
                                           ...props
                                       }) => {
    const dispatch = useDispatch();
    const lang = useSelector(selectLang)
    const t = Translate
    const numberFormat = useSelector(selectNumberFormat);
    const methods = useForm({
        defaultValues: {
            number: filters.number || null,
            date_start: filters.date_start ? dayjs(filters.date_start).toDate() :null,
            date_end: filters.date_end ? dayjs(filters.date_end).toDate() : null,
            created_at_start: filters.created_at_start ? dayjs(filters.created_at_start).toDate() : null,
            created_on_end: filters.created_on_end ? dayjs(filters.created_on_end).toDate() : null,
            contract_number: filters.contract_number || null,
            contract_date: filters.contract_date ?  dayjs(filters.contract_date).toDate() : null,
            customer_inn: filters.customer_inn || null,
            customer_branch_code: filters.customer_branch_code || null,
            executor_inn: filters.executor_inn || null,
            executor_branch_code: filters.executor_branch_code || null,
            start_total_sum: filters.start_total_sum || null,
            end_total_sum: filters.end_total_sum || null,
            status: filters.status || null
        },
    });

    // status filter/tab (key)
    const statuses = roamingInvoiceWrapper.getStatuses(viewer);
    const [activeKey, setActiveKey] = useState(0);

    // show/hide filter
    const [isOpenFilterCollapse, setIsOpenFilterCollapse] = useState(returnInvoice || false);

    // get selected invoices
    const selectedRowIndexArray = Object.keys(selectedRowIds);
    const selectedInvoices = selectedRowIndexArray.reduce((result, selectedIndex) => {
        result.push(page[selectedIndex]?.original);
        return result;
    }, [])

    // allow bulk actions
    const allowExecutorBulkSign = viewer === DRAFT && selectedRowIndexArray.length > 0;
    const allowExecutorBulkDelete = viewer === DRAFT && selectedRowIndexArray.length > 0;
    const allowDraftSendAll = viewer === DRAFT;
    const allowExecutorBulkCancel = viewer === VIEWER_EXECUTOR && filters?.status === StatusSentToCustomer && selectedRowIndexArray.length > 0;
    const allowCustomerBulkActions = viewer === VIEWER_CUSTOMER && filters?.status === StatusSentToCustomer && selectedRowIndexArray.length > 0;
    const allowPersonBulkActions = viewer === VIEWER_PERSON && filters?.status === StatusSentToCustomer && selectedRowIndexArray.length > 0;

    // show progress modal
    const [showProgressBar, setShowProgressBar] = useState(false);
    const handleShowProgressBar = () => setShowProgressBar(true);
    const [customProgressBarCount, setCustomerProgressBarCount] = useState(null)
    const handleCloseProgressBar = () => {
        loadInvoices();
        setBulkActionResult([]);
        setShowProgressBar(false);
        setCustomerProgressBarCount(null);
    };

    // bulk action result
    const [bulkActionResult, setBulkActionResult] = useState([]);
    const updateActionResult = (result) => setBulkActionResult(prev => [...prev, result]);

    const onSubmitFilter = ({
                                number,
                                date_start,
                                date_end,
                                created_at_start,
                                created_at_end,
                                contract_number,
                                contract_date,
                                customer_inn,
                                customer_branch_code,
                                executor_inn,
                                executor_branch_code,
                                start_total_sum,
                                end_total_sum
                            }) => {
        const filterDateFormat = "YYYY-MM-DD";
        const filter = {
            created_at_start: created_at_start ? dayjs(created_at_start).format(filterDateFormat) : null,
            created_at_end: created_at_end ? dayjs(created_at_end).format(filterDateFormat) : null,
            date_start: date_start ? dayjs(date_start).format(filterDateFormat) : null,
            date_end: date_end ? dayjs(date_end).format(filterDateFormat) : null,
            number: number || null,
            contract_number: contract_number || null,
            contract_date: contract_date ? dayjs(contract_date).format(filterDateFormat) : null,
            start_total_sum: start_total_sum ? +start_total_sum : null,
            end_total_sum: end_total_sum ? +end_total_sum : null,
            customer_inn: customer_inn || null,
            customer_branch_code: customer_branch_code || null,
            executor_inn: executor_inn || null,
            executor_branch_code: executor_branch_code || null
        }

        if (excelRegistry || returnInvoice) {
            changeFilterOptionsAsync({...filters, ...filter})
        } else {
            dispatch(changeFilterOptionsAsync({...filters, ...filter}))
        }

    }

    const onResetFilter = () => {
        methods.reset()
        dispatch(clearFilterOptions({
            page: filters.page,
            limit: filters.limit,
        }))
    }

    useEffect(() => {
        if(filters.status)
            setActiveKey(filters.status)
        else
            setActiveKey(0);
    }, [filters])

    const invoicePositive = selectedInvoices.filter((sumPositive) => sumPositive?.total_info?.total_with_nds > 0)
    const invoiceNegative = selectedInvoices.filter((sumNegative) => sumNegative?.total_info?.total_with_nds < 0)
    const positiveSumSelected = invoicePositive.reduce((acc, cur) => acc + cur?.total_info?.total_with_nds, 0)
    const negativeSumSelected = invoiceNegative.reduce((acc, cur) => acc + cur?.total_info?.total_with_nds, 0)
    const settingServiceEdo = useSelector(selectSettings)
    const datatableColumns = settingServiceEdo?.invoice_settings?.invoice_datatable_column?.length > 0 ? settingServiceEdo?.invoice_settings?.invoice_datatable_column : defaultRoamingInvoiceDatatableColumns
    const location = useLocation()

    const totalSum = [
        {name: 'roaming.invoices.datatable.invoices_positive_total_sum', sum: numeral.formats[numberFormat].format((positiveSumPage)), selected: false, variant: 'success'},
        {name: 'roaming.invoices.datatable.invoices_negative_total_sum', sum: numeral.formats[numberFormat].format((negativeSumPage)), selected: false, variant: 'danger'},
        {name: 'roaming.invoices.datatable.invoices_total_sum', sum: numeral.formats[numberFormat].format((positiveSumPage + negativeSumPage)), selected: false,  variant: 'dark'},
        {name: 'roaming.invoices.datatable.invoices_positive_total_sum', sum: numeral.formats[numberFormat].format((positiveSumSelected)), selected: true,  variant: 'success'},
        {name: 'roaming.invoices.datatable.invoices_negative_total_sum', sum: numeral.formats[numberFormat].format((negativeSumSelected)), selected: true,  variant: 'danger'},
        {name: 'roaming.invoices.datatable.invoices_total_sum', sum: numeral.formats[numberFormat].format((positiveSumSelected + negativeSumSelected)), selected: true,  variant: 'dark'},
    ]

    return (
        <>
            <ActionProgressBar show={showProgressBar}
                               result={bulkActionResult}
                               count={customProgressBarCount || selectedInvoices.length}
                               onClose={handleCloseProgressBar}
            />
            <Row className="flex-between-center">
                <Col sm={12}>
                    <FormProvider {...methods}>
                        <Form onSubmit={methods.handleSubmit(onSubmitFilter)}>
                            <RoamingInvoiceDataTableFilter  viewer={viewer} isOpenFilterCollapse={isOpenFilterCollapse}
                                                           predefinedFilters={predefinedFilters} onResetFilter={onResetFilter}
                            />
                        </Form>
                    </FormProvider>
                    <hr/>
                </Col>
                <Col xs={4} sm="auto" className="align-items-center pe-0">
                    <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">{t(lang, "roaming.invoices.datatable.title")}</h5>
                    <small className="mb-1">{t(lang, 'roaming.invoices.datatable.invoices_count')}: <span className="fw-semi-bold text-black mx-1 fs--1">{count}</span></small>
                </Col>
                <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
                    {location.pathname.includes('invoices') &&
                        <DatatableSettingsControl columns={defaultRoamingInvoiceDatatableColumns} datatableColumns={datatableColumns} loadInvoices={loadInvoices}/>
                    }
                    {allowExecutorBulkDelete &&
                        <RoamingInvoiceDraftDelete invoices={selectedInvoices}
                                                   handleShowProgressBar={handleShowProgressBar}
                                                   updateActionResult={updateActionResult}
                                                   hasTitle
                                                   size="sm"
                        />
                    }

                    {allowDraftSendAll && <RoamingInvoiceDraftSendAll
                        handleShowProgressBar={handleShowProgressBar}
                        updateActionResult={updateActionResult}
                        filters={filters}
                        predefinedFilters={predefinedFilters}
                        setCount={setCustomerProgressBarCount}
                        size="sm"
                    />}
                    {allowExecutorBulkSign &&
                        <RoamingInvoiceDraftSend invoices={selectedInvoices}
                                                 handleShowProgressBar={handleShowProgressBar}
                                                 updateActionResult={updateActionResult}
                                                 size="sm"
                        />
                    }

                    {allowExecutorBulkCancel &&
                        <RoamingInvoiceExecutorCancel invoices={selectedInvoices}
                                                      handleShowProgressBar={handleShowProgressBar}
                                                      updateActionResult={updateActionResult}
                                                      size="sm"
                        />
                    }

                    {allowCustomerBulkActions &&
                        <>
                            <RoamingInvoiceCustomerDecline invoices={selectedInvoices}
                                                           handleShowProgressBar={handleShowProgressBar}
                                                           updateActionResult={updateActionResult}
                                                           size="sm"
                            />
                            <RoamingInvoiceCustomerAccept invoices={selectedInvoices}
                                                          handleShowProgressBar={handleShowProgressBar}
                                                          updateActionResult={updateActionResult}
                                                          size="sm"
                            />
                        </>

                    }

                    {allowPersonBulkActions &&
                        <>
                            <RoamingInvoicePersonDecline invoices={selectedInvoices}
                                                         handleShowProgressBar={handleShowProgressBar}
                                                         updateActionResult={updateActionResult}
                                                         size="sm"
                            />
                            <RoamingInvoicePersonAccept invoices={selectedInvoices}
                                                        handleShowProgressBar={handleShowProgressBar}
                                                        updateActionResult={updateActionResult}
                                                        size="sm"
                            />
                        </>
                    }

                    <IconButton
                        variant="falcon-default"
                        size="sm"
                        icon="plus"
                        transform="shrink-3"
                        onClick={()=>!dispatch(checkPermission(PermissionRoamingInvoiceCreate)) && dispatch(toastPermission())}
                        as={dispatch(checkPermission(PermissionRoamingInvoiceCreate)) ? Link : 'div'}
                        className="m-2"
                        to="/roaming/invoice/send"
                    >
                        <span className="d-none d-sm-inline-block ms-1">{t(lang, "roaming.invoices.datatable.register_invoice")}</span>
                    </IconButton>

                    <IconButton
                        size="sm"
                        as={Link}
                        className="m-2"
                        icon={faFileExcel}
                        transform="shrink-3"
                        variant="falcon-default"
                        to="/roaming/invoice/mass-add"
                    >
                        <span className="d-none d-sm-inline-block ms-1">
                            {t(lang, "roaming.invoices.datatable.mass_add_invoice")}
                        </span>
                    </IconButton>


                    <IconButton
                        variant="falcon-default"
                        size="sm"
                        transform="shrink-3"
                        icon="refresh"
                        onClick={methods.handleSubmit(onSubmitFilter)}
                        className="m-2"
                    >
                        <span className="d-none d-sm-inline-block ms-1">{t(lang, "roaming.invoices.table_header.show")}</span>
                    </IconButton>

                    <UploadRoamingInvoiceListToExcel className="d-inline-block m-2" />

                    <IconButton
                        onClick={() => setIsOpenFilterCollapse(!isOpenFilterCollapse)}
                        variant="falcon-default"
                        size="sm"
                        icon="filter"
                        transform="shrink-3"
                        className="m-2"
                    >
                        <span className="d-none d-sm-inline-block ms-1">{t(lang, "roaming.invoices.table_header.filter")}</span>
                    </IconButton>
                </Col>
                <Col xs={12} className="mt-3">
                    <Row className={'flex-wrap justify-content-between w-100'}>
                       <Col lg={6} className={'d-flex gap-1 text-center w-md-50 w-lg-auto flex-column flex-lg-row align-items-lg-center align-content-sm-start mt-3 mt-lg-0 pe-0'}>
                           <h6 className={'mb-0'}>{t(lang, "roaming.invoices.datatable.invoices_total_sum")}: </h6>
                           {totalSum.filter(item => !item.selected).map(item => <OverlayTrigger key={item.name} overlay={<Tooltip>{t(lang,item.name)}</Tooltip>} placement={'top'}>
                               <Alert className={'d-inline-block px-2 py-0 mb-0 ms-1 fs--1'}  variant={item.variant}>
                                   <strong>{item.sum}</strong>
                               </Alert>
                           </OverlayTrigger>)}
                       </Col>
                        {
                            selectedInvoices.length > 0 &&
                            <Col lg={6} className="d-flex gap-1  text-center w-md-50 w-lg-auto flex-column flex-lg-row  justify-content-lg-end align-items-lg-center align-content-sm-start  mt-3 mt-lg-0 pe-0">
                                <h6 className={'mb-0'}>{t(lang, "roaming.invoices.datatable.invoices_total_sum_selected")}: </h6>
                                {totalSum.filter(item => item.selected).map(item => <OverlayTrigger key={item.name} overlay={<Tooltip>{t(lang,item.name)}</Tooltip>} placement={'top'}>
                                    <Alert className={'d-inline-block px-2 py-0 mb-0 ms-1 fs--1'}  variant={item.variant}>
                                        <strong>{item.sum}</strong>
                                    </Alert>
                                </OverlayTrigger>)}
                            </Col>
                        }
                    </Row>
                </Col>
                {(viewer !== DRAFT && !predefinedFilters.hasOwnProperty("status")) &&
                    <Col xs={12} className="my-4">
                        <Nav justify variant="tabs" activeKey={activeKey}>
                            {statuses.map((status) => (
                                <Nav.Item key={status.id} onClick={() => {
                                    dispatch(changeFilterOptionsAsync({...filters, status: status.id !== 0 ? status.id : null}))
                                }}>
                                    <Nav.Link eventKey={status.id}>
                                        <span className={`text-${roamingInvoiceWrapper.getStatusVariants(viewer, status.id)}`}>{t(lang, status.name)}</span>
                                    </Nav.Link>
                                </Nav.Item>
                            ))}
                        </Nav>
                    </Col>
                }
            </Row>
        </>
    );
};

RoamingInvoiceDataTableHeader.propTypes = {
    selectedRowIds: PropTypes.object,
};

RoamingInvoiceDataTableHeader.defaultProps = {
    selectedRowIds: {},
}

export default RoamingInvoiceDataTableHeader;
