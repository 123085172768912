import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {CloseButton, Dropdown, Modal} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBarcode} from "@fortawesome/free-solid-svg-icons";
import React, {useState} from "react";
import PrintItemBarcode from "./PrintItemBarcode";
import itemWrapper from "../enum/itemWrapper";
import {selectCurrency} from "../../../../app/store/reducers/currency/currencyReducer";

const PrintItemDropdownItem = ({item, price}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const currencies = useSelector(selectCurrency);
    const [show, setShow] = useState(false)

    const defaultCurrency = itemWrapper.getCurrency(currencies, price?.common_price?.currency?.global_currency_id);

    const handlerShow = () => {
        setShow(true)
    }

    const handlerClose = () => {
        setShow(false)
    }

    return (
        <>
            <Dropdown.Item  className="border-bottom align-center" onClick={handlerShow}>
                <FontAwesomeIcon className="me-1" size="sm" icon={faBarcode}/>
                <span>{t(lang, "items.print.barcode.modal.title")}</span>
            </Dropdown.Item>
            <Modal show={show} onHide={handlerClose} size="xl">
                <Modal.Header>
                    <Modal.Title>
                        {t(lang, "items.print.barcode.modal.title")}
                    </Modal.Title>
                    <CloseButton onClick={handlerClose}/>
                </Modal.Header>
                <Modal.Body>
                    <PrintItemBarcode defaultPrice={price?.common_price?.amount} itemName={item.name} defaultBarcode={item.barcodes[0]}
                                      defaultCurrency={defaultCurrency} sku={item.sku}/>
                </Modal.Body>
            </Modal>
        </>
    );
}
export default PrintItemDropdownItem;