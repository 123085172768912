import React, {useEffect} from 'react';
import {Route, Switch, useRouteMatch} from "react-router-dom";
import CashBoxes from "./cashboxes/CashBoxes";
import CashBoxExpense from "./expense/CashBoxExpense";
import CashBoxCashIn from "./cash-in/CashBoxCashIn";
import CashBoxCashOut from "./cash-out/CashBoxCashOut";
import Branches from "../edi/branch/Branches";
import {
    selectedCurrencyForCashBox,
    setCurrency
} from "../../../app/store/reducers/cashbox/CashboxReducer";
import {getAccount} from "../../../app/store/reducers/crm/bind-contractor-to-account/bindContractorToAccountReducer";
import {getOrganizationUsersAsync, selectActiveOrganization} from "../../../app/store/reducers/auth/authReducer";
import {useDispatch, useSelector} from "react-redux";
import ViewCashBox from "./cashboxes/ViewCashBox";
import ViewExpense from "./expense/ViewExpense";
import ViewCashIn from "./cash-in/ViewCashIn";
import ViewCashOut from "./cash-out/ViewCashOut";
import {RUB, USD, UZS} from "../../../enum/CurrencyCode";
import {selectCurrency} from "../../../app/store/reducers/currency/currencyReducer";
import CashBoxOperation from "./operation/CashBoxOperation";
import ViewOperation from "./operation/ViewOperation";
import CashBoxTransfer from "./transfer/CashBoxTransfer";
import ViewTransfer from "./transfer/ViewTransfer";
import CashBoxDashboard from "./CashBoxDashboard";

const CashBoxLayout = () => {
    const {url} = useRouteMatch();

    const dispatch = useDispatch()

    const activeOrganization = useSelector(selectActiveOrganization)
    const currency = useSelector(selectCurrency)
    const selectedCurrency = useSelector(selectedCurrencyForCashBox)

    useEffect(() => {
        dispatch(getAccount())
    }, [activeOrganization])

    useEffect(() => {
        if (!selectedCurrency)
            dispatch(setCurrency(currency.filter(currency => currency.is_active).find(currency => currency.code === UZS || currency.code === USD || currency.code === RUB)))
    }, [currency])

    return (
        <Switch>
            <Route path={`${url}`} exact component={CashBoxDashboard} />

            <Route path={`${url}/cash-boxes`} exact component={CashBoxes} />
            <Route path={`${url}/cash-boxes/view/:id`} exact component={ViewCashBox} />

            <Route path={`${url}/cash-in`} exact component={CashBoxCashIn} />
            <Route path={`${url}/cash-in/view/:id`} exact component={ViewCashIn} />

            <Route path={`${url}/cash-out`} exact component={CashBoxCashOut} />
            <Route path={`${url}/cash-out/view/:id`} exact component={ViewCashOut} />

            <Route path={`${url}/expense`} exact component={CashBoxExpense} />
            <Route path={`${url}/expense/view/:id`} exact component={ViewExpense} />

            <Route path={`${url}/branches`} exact component={Branches}/>

            <Route path={`${url}/operation`} exact component={CashBoxOperation}/>
            <Route path={`${url}/operation/view/:id`} exact component={ViewOperation}/>

            <Route path={`${url}/transfer`} exact component={CashBoxTransfer}/>
            <Route path={`${url}/transfer/view/:id`} exact component={ViewTransfer}/>

        </Switch>
    );
};

export default CashBoxLayout;
