import React, {useEffect, useState} from 'react';
import KanbanProvider from "../../../../components/kanban/main/KanbanProvider";
import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useLocation, useParams} from "react-router";
import {
    clearBoardStatusItems,
    getBoardItemAsync,
    getBoardStatusItemsAsync, selectKanbanItems,
} from "../../../../app/store/reducers/kanban/kanbanReducer";

import EventBus from "../../../../app/eventbus/EventBus";
import {
    ITEM_CHANGE_STATUS_FAIL,
    ITEM_CHANGE_STATUS_SUCCESS,
    ITEM_REGISTER_FAIL,
    ITEM_REGISTER_SUCCESS,
    MEMBER_DELETE_FAIL,
    MEMBER_DELETE_SUCCESS,
    MEMBER_REGISTER_FAIL,
    MEMBER_REGISTER_SUCCESS, MEMBER_UPDATE_FAIL, MEMBER_UPDATE_SUCCESS,
    STATUS_DELETE_FAIL,
    STATUS_DELETE_SUCCESS,
    STATUS_REGISTER_FAIL,
    STATUS_REGISTER_SUCCESS, STATUS_UPDATE_FAIL, STATUS_UPDATE_SUCCESS
} from "../../../../app/eventbus/kanban/KanbanEvents";
import {toast} from "react-toastify";

import {useHistory} from "react-router-dom";


const KanbanView = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const activeOrganization = useSelector(selectActiveOrganization)

    const lang = useSelector(selectLang);
    const t = Translate;

    const {id} = useParams()

    useEffect(async () => {
        getBoardAll()
    }, [activeOrganization])

    const getBoardAll = async () => {
        try {
            await dispatch(getBoardStatusItemsAsync(id))
            await dispatch(getBoardItemAsync(id))
        } catch (e) {
            history.push('/kanban')
        }
    }

    useEffect(() => {
        //status success
        const onStatusRegisterHandlerSuccess = EventBus.on(STATUS_REGISTER_SUCCESS, () => {
            getBoardAll()
            toastSuccess()
        })
        const onStatusUpdateHandlerSuccess = EventBus.on(STATUS_UPDATE_SUCCESS, () => {
            getBoardAll()
            toastSuccess()
        })
        const onStatusDeleteHandlerSuccess = EventBus.on(STATUS_DELETE_SUCCESS, () => {
            getBoardAll()
            toastSuccess()
        })

        //member success
        const onMemberRegisterHandlerSuccess = EventBus.on(MEMBER_REGISTER_SUCCESS, () => {
            dispatch(getBoardItemAsync(id))
            toastSuccess()
        })

        const onMemberUpdateHandlerSuccess = EventBus.on(MEMBER_UPDATE_SUCCESS, () => {
            dispatch(getBoardItemAsync(id))
            toastSuccess()
        })

        const onMemberDeleteHandlerSuccess = EventBus.on(MEMBER_DELETE_SUCCESS, () => {
            dispatch(getBoardItemAsync(id))
            toastSuccess()
        })
        //item
        const onItemRegisterHandlerSuccess = EventBus.on(ITEM_REGISTER_SUCCESS, () => {
            dispatch(getBoardStatusItemsAsync(id))
            toastSuccess()
        })

        // item change status

        const onItemChangeStatusHandlerSuccess = EventBus.on(ITEM_CHANGE_STATUS_SUCCESS, () => {
            dispatch(getBoardStatusItemsAsync(id))
        })

        //status fail
        const onStatusRegisterHandlerFailed = EventBus.on(STATUS_REGISTER_FAIL, () => {
            toastError()
        })
        const onStatusUpdateHandlerFailed = EventBus.on(STATUS_UPDATE_FAIL, () => {
            toastError()
        })
        const onStatusDeleteHandlerFailed = EventBus.on(STATUS_DELETE_FAIL, () => {
            toastError()
        })

        // member fail
        const onMemberRegisterHandlerFailed = EventBus.on(MEMBER_REGISTER_FAIL, () => {
            toastError()
        })

        const onMemberUpdateHandlerFailed = EventBus.on(MEMBER_UPDATE_FAIL, () => {
            toastError()
        })

        const onMemberDeleteHandlerFailed = EventBus.on(MEMBER_DELETE_FAIL, () => {
            toastError()
        })

        // item fail
        const onITemRegisterHandlerFailed = EventBus.on(ITEM_REGISTER_FAIL, () => {
            toastError()
        })


        // item change status fail
        const onItemChangeStatusHandlerFailed = EventBus.on(ITEM_CHANGE_STATUS_FAIL, () => {
            toastError()
        })

        return () => {
            EventBus.remove(STATUS_REGISTER_SUCCESS, onStatusRegisterHandlerSuccess)
            EventBus.remove(STATUS_UPDATE_SUCCESS, onStatusUpdateHandlerSuccess)
            EventBus.remove(STATUS_REGISTER_FAIL, onStatusRegisterHandlerFailed)

            EventBus.remove(STATUS_DELETE_SUCCESS, onStatusDeleteHandlerSuccess)
            EventBus.remove(STATUS_UPDATE_FAIL, onStatusUpdateHandlerFailed)
            EventBus.remove(STATUS_DELETE_FAIL, onStatusDeleteHandlerFailed)

            EventBus.remove(MEMBER_REGISTER_SUCCESS, onMemberRegisterHandlerSuccess)
            EventBus.remove(MEMBER_REGISTER_FAIL, onMemberRegisterHandlerFailed)

            EventBus.remove(MEMBER_UPDATE_SUCCESS, onMemberUpdateHandlerSuccess)
            EventBus.remove(MEMBER_UPDATE_FAIL, onMemberUpdateHandlerFailed)

            EventBus.remove(MEMBER_DELETE_SUCCESS, onMemberDeleteHandlerSuccess)
            EventBus.remove(MEMBER_DELETE_FAIL, onMemberDeleteHandlerFailed)

            EventBus.remove(ITEM_REGISTER_SUCCESS, onItemRegisterHandlerSuccess)
            EventBus.remove(ITEM_REGISTER_FAIL, onITemRegisterHandlerFailed)

            EventBus.remove(ITEM_CHANGE_STATUS_SUCCESS, onItemChangeStatusHandlerSuccess)
            EventBus.remove(ITEM_CHANGE_STATUS_FAIL, onItemChangeStatusHandlerFailed)
        }
    }, [])

    function toastSuccess() {
        return toast.success(t(lang, "Success"))
    }

    function toastError() {
        return toast.error(t(lang, "Error"))
    }

    return (
        <KanbanProvider/>
    );
};


export default KanbanView;
