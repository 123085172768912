import React, {useEffect, useState} from 'react';
import {Button, Card, OverlayTrigger, Tooltip} from 'react-bootstrap';
import dayjs from 'dayjs';
import numeral from 'numeral';
import ReturnInvoiceTableHeader from './ReturnInvoiceDataTableHeader';
import AdvanceTableWrapper from "../../common/advance-table/AdvanceTableWrapper";
import AdvanceTable from "../../common/advance-table/AdvanceTable";
import AdvanceTableFooter from "../../common/advance-table/AdvanceTableFooter";
import {
    changeFilterOptionsAsync,
    loadReturnInvoicesAsync,
    loadCountReturnInvoiceAsync,
    signReturnInvoiceAsync
} from '../../../app/store/reducers/return-invoice/returnInvoiceReducer';
import {selectReturnInvoices, selectCount, selectFilterOptions} from '../../../app/store/reducers/return-invoice/returnInvoiceReducer';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {selectActiveOrganization} from '../../../app/store/reducers/auth/authReducer';
import AdvanceTableCustomPagination from '../../common/advance-table/AdvanceTableCustomPagination';
import {
    getStatusColorReturnInvoice,
    GetStatusTextReturnInvoice,
} from '../../../enum/ReturnInvoiceStatus';
import {InvoiceCreated} from "../../../enum/ReturnInvoiceStatus";
import EventBus from "../../../app/eventbus/EventBus";
import {DELETE_RETURN_INVOICE_SUCCESS} from "../../../app/eventbus/returnInvoiceEvents";
import {selectLang, Translate, selectDateDayJSFormat, selectNumberFormat} from "../../../app/store/reducers/main/mainReducer";
import CountbleProgressBar from "../../common/CountbleProgressBar";
import {selectCurrentCertificate} from "../../../app/store/reducers/eimzo/eimzoReducer";

const ReturnInvoiceDataTable = ({activeColumns}) => {
    const dispatch = useDispatch()
    const returnInvoices = useSelector(selectReturnInvoices)
    const count = useSelector(selectCount)
    const filters = useSelector(selectFilterOptions)
    const activeOrganization = useSelector(selectActiveOrganization)
    const currentDateDayJSFormat = useSelector(selectDateDayJSFormat)
    const activeCertificate = useSelector(selectCurrentCertificate);
    const numberFormat = useSelector(selectNumberFormat);
    const lang = useSelector(selectLang);
    const t = Translate;

    const totalSum = returnInvoices.reduce((totSum, total) => totSum + total.total_info.total, 0)

    function getActiveColumns(activeColumns, columns = []) {
        return columns?.filter(column => activeColumns[column?.id])
    }

    const columns = getActiveColumns(activeColumns, [
        {
            Header: "#",
            id: "index",
            filterable: false,
            cellProps: {
                width: '40px',
            },
            Cell: ({row}) => {
              return `${((filters.page-1) * filters.limit) + row.index + 1}`;
            }
        },
        {
            accessor: 'return_invoice_info',
            id: "status",
            Header: t(lang, "edi.return_invoice.datatable.header.row.status"),
            cellProps: {
                width: '100px',
                className: 'p-0'
            },
            totalInfo: `${t(lang,"edi.common.datatable.total")}:`,
            totalInfoClassName: 'fs-1 fw-bold text-nowrap text-end',
            Cell: rowData => {
                const {original} = rowData.row

                return <div className="d-grid gap-2">
                    <OverlayTrigger
                        overlay={
                            <Tooltip>
                                {t(lang, GetStatusTextReturnInvoice(original.status))}
                            </Tooltip>
                        }
                        placement="right"
                    >
                        <div className="d-grid gap-2">
                            <Button variant="primary"  style={{backgroundColor: getStatusColorReturnInvoice(original.status)}} as={Link} to={`./return-invoice/${original.id}`}>
                                {original.return_invoice_info.number}
                            </Button>
                        </div>
                    </OverlayTrigger>
                </div>
            }
        },
        {
            accessor: 'return_invoice_info.date',
            id: "date",
            Header: t(lang, "edi.return_invoice.datatable.header.row.date"),
            cellProps: {
                width: '80px',
            },
            Cell: ({row: {original}}) => {
                return <b>{dayjs(original.return_invoice_info.date, "YYYY-MM-DD").format(currentDateDayJSFormat)}</b>
            }
        },
        {
            accessor: 'total_info.total',
            id: "total_sum",
            Header: t(lang, "edi.return_invoice.datatable.header.row.total_sum"),
            cellProps: {
                width: '80px',
                className: 'text-end'
            },
            totalInfo: numeral.formats[numberFormat].format(totalSum),
            totalInfoClassName: "fw-bold text-nowrap",
            Cell: ({row: {original}}) => {
                return numeral.formats[numberFormat].format(original.total_info.total)
            }
        },
        {
            accessor: 'return_shipment.return_order.branch.name',
            id: "branch",
            Header: t(lang, "edi.return_invoice.datatable.header.row.branch"),
            cellProps: {
                className: 'fw-bold'
            },
            Cell: ({row: {original}}) => {
                return <b>{original.return_shipment.return_order.branch.name}</b>
            }
        },
        {
            accessor: 'contractor',
            id: "contractor",
            Header: t(lang, "edi.return_invoice.datatable.header.row.contractor"),
            cellProps: {
                className: 'fw-bold'
            },
            Cell: ({row: {original}}) => {
                const isCustomer = activeOrganization.inn === original.customer.inn

                if(isCustomer){
                    return<>
                        {original.executor.name}
                    </>
                }

                return<>
                    {original.customer.name}
                </>
            }
        },
        {
            accessor: 'contract_number',
            id: 'contract_num',
            Header: t(lang, 'edi.return_invoice.datatable.header.row.contract.number'),
            cellProps: {
                className: 'fw-bold'
            },
            Cell: ({row: {original}}) => {
                return <b>{original.contract_info?.number}</b>
            }
        }
    ]);

    const onPageChange = async (page) => dispatch(changeFilterOptionsAsync({...filters, page: page}));
    const onLimitChange = async (limit) => dispatch(changeFilterOptionsAsync({...filters, limit: limit, page: 1}));

    useEffect(() => {
        const onReturnInvoiceDeletedHandler = EventBus.on(DELETE_RETURN_INVOICE_SUCCESS, () => {
            dispatch(loadReturnInvoicesAsync({...filters, viewer_inn: activeOrganization.inn}))
        });

        return () => {
            EventBus.remove(DELETE_RETURN_INVOICE_SUCCESS, onReturnInvoiceDeletedHandler);
        }
    })


    const [showProgressBar, setShowProgressBar] = useState(false);
    const [signCount, setSignCount] = useState(0);
    const [countSignedInvoices, setCountSignedInvoices] = useState(0);
    const [bulkSignResult, setBulkSignResult] = useState([]);

    const onBulkSignHandler = async (selectedInvoices) => {
        setShowProgressBar(true);
        setSignCount(selectedInvoices.length);
        for(let i = 0; i < selectedInvoices.length; i++) {
            try {
                if(selectedInvoices[i].status === InvoiceCreated) {
                    await signReturnInvoiceAsync(selectedInvoices[i], activeCertificate);
                    setCountSignedInvoices(countSignedInvoices + 1);
                    setBulkSignResult(prev => [...prev, true]);
                }
            } catch (e) {
                setBulkSignResult(prev => [...prev, false]);
                console.log(e);
            }
        }
        await dispatch(loadReturnInvoicesAsync({...filters, viewer_inn: activeOrganization.inn}))
        await dispatch(loadCountReturnInvoiceAsync({...filters, viewer_inn: activeOrganization.inn}))

        setBulkSignResult([]);
        setSignCount(0);
        setCountSignedInvoices(0);
        setShowProgressBar(false);
    };

    return (
        <AdvanceTableWrapper
            columns={columns}
            data={returnInvoices}
            selection
            width={10}
            pagination
            perPage={1000}
        >
            <CountbleProgressBar show={showProgressBar} signed={countSignedInvoices} results={bulkSignResult} length={signCount}/>
            <Card className="mb-3">
                <Card.Header>
                    <ReturnInvoiceTableHeader table onClickBulkSign={onBulkSignHandler} />
                </Card.Header>
                <Card.Body className="p-0">
                    <AdvanceTable
                        table
                        headerClassName="bg-200 text-900 text-wrap align-middle"
                        rowClassName="align-middle white-space-nowrap"
                        tableProps={{
                            size: 'sm',
                            striped: true,
                            className: 'fs--1 mb-0 overflow-hidden'
                        }}
                    />
                </Card.Body>
                <Card.Footer>
                    <AdvanceTableFooter
                        table
                    />
                    <AdvanceTableCustomPagination
                        count={count}
                        limit={filters.limit}
                        page={filters.page}
                        onLimitChange={onLimitChange}
                        onPageChange={onPageChange}
                    />
                </Card.Footer>
            </Card>
        </AdvanceTableWrapper>
    );
};

export default ReturnInvoiceDataTable;
