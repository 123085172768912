import dayjs from "dayjs";
import numeral from "numeral";
import DatePicker from "react-datepicker";
import {useRouteMatch} from "react-router-dom";
import React, {Fragment, useEffect, useMemo, useRef, useState} from 'react';
import {Controller, useForm, useWatch} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import SoftBadge from "../../../../../common/SoftBadge";
import {Button, Card, Col, Form, Row, Table} from "react-bootstrap";
import {selectCurrency} from "../../../../../../app/store/reducers/currency/currencyReducer";
import {selectDateFormat, selectLang, selectNumberFormat, Translate} from "../../../../../../app/store/reducers/main/mainReducer";
import {
    changeContractorActReportFilterOptions,
    loadContactorActReportsAsync, selectContractorActReport,
    selectContractorActReportFiltersOptions
} from "../../../../../../app/store/reducers/contractor/contractorActReportReducer";
import {
    GetContractorOperationTypeBgVariant, GetContractorOperationTypeLabel,
    OperationTypePayment,
    OperationTypePayout,
    OperationTypePurchase,
    OperationTypeRefund,
    OperationTypeReturn,
    OperationTypeSale
} from "../../../../../../enum/ContractorOperationTypes";
import UploadActReportToExcel from "./UploadActReportToExcel";
import ContractorActReportsModal from "./ContractorActReportsModal";

const isDebitOperation = type => Number(type) === OperationTypePayout || Number(type) === OperationTypeSale || Number(type) === OperationTypeReturn;
const isCreditOperation = type => Number(type) === OperationTypePayment || Number(type) === OperationTypePurchase || Number(type) === OperationTypeRefund;

const ContractorActReports = () => {
    const filter = useSelector(selectContractorActReportFiltersOptions);
    const actReport = useSelector(selectContractorActReport);
    const numberFormat = useSelector(selectNumberFormat);
    const dateFormat = useSelector(selectDateFormat);
    const currencies = useSelector(selectCurrency);
    const {params: {id}} = useRouteMatch();
    const lang = useSelector(selectLang);
    const dispatch = useDispatch();
    const t = Translate;
    const [viewReport, setViewReport] = useState(null)
    const modalRef = useRef();

    useEffect(() => {
        dispatch(loadContactorActReportsAsync(id, {date_start: filter.date_start, date_end: filter.date_end}));
    }, [filter])

    const {control, handleSubmit} = useForm({
        defaultValues: {
            dateStart: filter?.date_start ? dayjs(filter.date_start).toDate() : new Date(),
            dateEnd: filter?.date_end ? dayjs(filter.date_end).toDate() : new Date(),
        },
    });
    const dateStart = useWatch({name: 'dateStart', control: control, exact: true})
    const dateEnd = useWatch({name: 'dateEnd', control: control, exact: true})
    const filterRequiredExcel = !dateStart || !dateEnd

    const onSubmit = formDate => {
        dispatch(changeContractorActReportFilterOptions({
            ...filter,
            date_start: formDate.dateStart ? dayjs(formDate.dateStart).format("YYYY-MM-DD") : null,
            date_end: formDate.dateEnd ? dayjs(formDate.dateEnd).format("YYYY-MM-DD") : null,
        }));
    };

    const totalDebitAmount = useMemo(() => {
        // todo optimize
        if (actReport?.operations) {
            const debitOperations = actReport.operations.filter(operation => isDebitOperation(operation.type));
            const debitOperationDebts = debitOperations.map(operation => operation.debt_states);
            const debts = debitOperationDebts.reduce((array, debt) => array.concat(debt), []);

            let filteredArr = [];
            currencies.forEach(currency => {
                let filtered_debts = debts.filter(debt => debt.currency.id === currency.id);
                if (filtered_debts.length > 0) {
                    filteredArr.push(filtered_debts);
                }
            });

            return filteredArr.map(arr => {
                let amount = arr.reduce((total, item) => {
                    return total + item.amount;
                }, 0);
                return {
                    amount: amount,
                    currency: arr[0]['currency']
                }
            });
        }

        return [];
    }, [actReport])

    const totalCreditAmount = useMemo(() => {
        // todo optimize code
        if (actReport?.operations) {
            const creditOperations = actReport.operations.filter(operation => isCreditOperation(operation.type));
            const creditOperationDebts = creditOperations.map(operation => operation.debt_states);
            const debts = creditOperationDebts.reduce((array, debt) => array.concat(debt), []);

            let filteredArr = [];
            currencies.forEach(currency => {
                let filtered_debts = debts.filter(debt => debt.currency.id === currency.id);
                if (filtered_debts.length > 0) {
                    filteredArr.push(filtered_debts);
                }
            });

            return filteredArr.map(arr => {
                let amount = arr.reduce((total, item) => {
                    return total + item.amount;
                }, 0);
                return {
                    amount: amount,
                    currency: arr[0]['currency']
                }
            });
        }

        return [];
    }, [actReport])

    const totalDifference = useMemo(() => {
        let nationalDebit = totalDebitAmount.find(debitDebt => debitDebt.currency.is_national);
        let nationalCredit = totalCreditAmount.find(creditDebt => creditDebt.currency.is_national);
        return {
            amount: nationalDebit?.amount - nationalCredit?.amount,
            currency: nationalDebit?.currency || nationalCredit?.currency,
        }
    }, [totalDebitAmount, totalCreditAmount])

    const totalCurrencyDifference = useMemo(() => {
        let currencyDebit = totalDebitAmount.filter(d => !d.currency.is_national);
        let currencyCredit = totalCreditAmount.filter(c => !c.currency.is_national);

        currencyDebit = currencyDebit.map(debt => {
            return {...debt, is_debit: true};
        });
        currencyCredit = currencyCredit.map(debt => {
            return {...debt, is_debit: false}
        });

        let concatArr = currencyDebit.concat(currencyCredit);

        let filteredArrByCurrency = [];
        currencies.forEach(currency => {
            let filtered_debts = concatArr.filter(debt => debt.currency.id === currency.id);
            if (filtered_debts.length > 0) {
                filteredArrByCurrency.push(filtered_debts)
            }
        })
        return filteredArrByCurrency.map(fa => {
            let debitTotalAmount = fa.reduce((total, debt) => {
                if (debt.is_debit)
                    return total + debt.amount
                return total
            }, 0);
            let creditTotalAmount = fa.reduce((total, debt) => {
                if (!debt.is_debit)
                    return total + debt.amount
                return total
            }, 0);
            const total = debitTotalAmount - creditTotalAmount;
            return {
                amount: total,
                currency: fa[0]['currency']
            }
        })

    }, [totalDebitAmount, totalCreditAmount])

    const openModal = (operation) => {
        setViewReport(operation)
        if (modalRef.current) {
            modalRef.current.handleShow();
        }
    };

    return (
        <Fragment>
            <Card>
                <Card.Header className={'bg-light'}>
                    <Card.Title>{t(lang, 'crm.contractor.view.tab.act_report.title')}</Card.Title>
                </Card.Header>
                <Card.Header className={'border-bottom'}>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Form.Group xs={4} as={Col}>
                                <Form.Label>{t(lang, 'crm.contractor.view.tab.act_report.filter.date_start.title')}</Form.Label>
                                <Controller
                                    control={control}
                                    name={'dateStart'}
                                    render={({field}) => (
                                        <DatePicker
                                            isClearable
                                            className="form-control"
                                            dateFormat={dateFormat}
                                            selected={field.value}
                                            onChange={field.onChange}
                                            placeholderText={t(lang, 'crm.contractor.view.tab.act_report.filter.date_start.placeholder')}
                                        />
                                    )}
                                />
                            </Form.Group>

                            <Form.Group xs={4} as={Col}>
                                <Form.Label>{t(lang, 'crm.contractor.view.tab.act_report.filter.date_end.title')}</Form.Label>
                                <Controller
                                    name={'dateEnd'}
                                    control={control}
                                    render={({field}) => (
                                        <DatePicker
                                            isClearable
                                            selected={field.value}
                                            className="form-control"
                                            dateFormat={dateFormat}
                                            onChange={field.onChange}
                                            placeholderText={t(lang, 'crm.contractor.view.tab.act_report.filter.date_end.placeholder')}
                                        />
                                    )}
                                />
                            </Form.Group>

                            <Form.Group xs={"auto"} as={Col} className={'align-self-end'}>
                                <Button type="submit">{t(lang, 'crm.contractor.view.tab.act_report.filter.submit')}</Button>
                                <UploadActReportToExcel id={id} disabled={filterRequiredExcel} contractorName={actReport?.contractor?.name} hasName dateStart={dateStart} dateEnd={dateEnd}/>
                            </Form.Group>
                        </Row>
                    </Form>
                </Card.Header>
                <Card.Body>
                    <Table className={'border border-300'} bordered size={'sm'}>
                        <thead>
                        <tr>
                            <th colSpan={'8'} className={'py-3'}>{t(lang, 'crm.contractor.view.tab.act_report.initial_balance')}: {actReport?.before_debts.map((debt, index) => (
                                <SoftBadge key={index} bg={'primary'} className={'mx-1 fs-semi-bold text-black fs-0'}>{numeral['formats'][numberFormat].format(debt.amount)} {debt.currency.name}</SoftBadge>
                            ))}</th>
                        </tr>
                        </thead>
                        <thead className={'bg-100'}>
                        <tr>
                            <th/>
                            <th/>
                            <th colSpan={'2'} className={'text-center'}>{t(lang, 'crm.contractor.view.tab.act_report.national_currency')}</th>
                            <th colSpan={'2'} className={'text-center'}>{t(lang, 'crm.contractor.view.tab.act_report.with_currency')}</th>
                            <th/>
                            <th/>
                        </tr>
                        <tr className={'bg-100'}>
                            <th width={50}>#</th>
                            <th>Операция</th>
                            <th className={'text-center'}>{t(lang, 'crm.contractor.view.tab.act_report.debt_increase')}</th>
                            <th className={'text-center'}>{t(lang, 'crm.contractor.view.tab.act_report.debt_decrease')}</th>
                            <th className={'text-center'}>{t(lang, 'crm.contractor.view.tab.act_report.debt_increase')}</th>
                            <th className={'text-center'}>{t(lang, 'crm.contractor.view.tab.act_report.debt_decrease')}</th>
                            <th width={200}>{t(lang, 'crm.contractor.view.tab.act_report.account')}</th>
                            <th width={200}>{t(lang, 'crm.contractor.view.tab.act_report.datetime')}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {actReport?.operations.map((operation, index) => (
                            <tr key={index}>
                                <th width={50}>{index + 1}</th>
                                <th>
                                    <SoftBadge onClick={() => openModal(operation)} className={'d-block w-100 text-decoration-underline cursor-pointer'} bg={GetContractorOperationTypeBgVariant(operation.type)}>{t(lang, GetContractorOperationTypeLabel(operation.type))}</SoftBadge>
                                </th>
                                <th className={'text-center bg-soft-danger'}>
                                    {isDebitOperation(operation.type) && operation.debt_states.map((ds, key) => {
                                        return (
                                            ds.currency.is_national &&
                                            <span key={key}>{numeral['formats'][numberFormat].format(ds.amount)}</span>
                                        )
                                    })}
                                </th>
                                <th className={'text-center bg-soft-success'}>{isCreditOperation(operation.type) && operation.debt_states.map((ds, key) => {
                                    return (
                                        ds.currency.is_national &&
                                        <span key={key}>{numeral['formats'][numberFormat].format(ds.amount)}</span>
                                    )
                                })}</th>
                                <th className={'text-center bg-soft-danger'}>
                                    {isDebitOperation(operation.type) && operation.debt_states.map((ds, key) => {
                                        return (
                                            !ds.currency.is_national && <div className={'ms-1'}
                                                                             key={key}>{numeral['formats'][numberFormat].format(ds.amount)} {ds.currency.name}</div>
                                        )
                                    })}
                                </th>
                                <th className={'text-center bg-soft-success'}>
                                    {isCreditOperation(operation.type) && operation.debt_states.map((ds, key) => {
                                        return (
                                            !ds.currency.is_national && <div className={'ms-1'}
                                                                             key={key}>{numeral['formats'][numberFormat].format(ds.amount)} {ds.currency.name}</div>
                                        )
                                    })}
                                </th>
                                <th width={200}>{operation.account.name ? operation.account.name : operation.account.username}</th>
                                <th width={200}>{operation.date}</th>
                            </tr>
                        ))}
                        </tbody>
                        <tbody>
                        <tr className="bg-200">
                            <td/>
                            <td colSpan={"1"} className={'text-end'}>
                                <h5>{t(lang, "crm.contractor.view.tab.act_report.total")}</h5>
                            </td>
                            <td className="text-center"><h5>{totalDebitAmount?.map(total => {
                                return (
                                    <div key={total.currency.id}>{total.currency.is_national && <span>{numeral['formats'][numberFormat].format(total.amount)} {total.currency.name}</span>}</div>
                                );
                            })}</h5>
                            </td>
                            <td className="text-center"><h5>{totalCreditAmount?.map(total => {
                                return (
                                    <div key={total.currency.id}>{total.currency.is_national && <span>{numeral['formats'][numberFormat].format(total.amount)} {total.currency.name}</span>}</div>
                                );
                            })}</h5>
                            </td>
                            <td className="text-center"><h5>{totalDebitAmount?.map(total => {
                                return (
                                    <div key={total.currency.id}>{!total.currency.is_national && <span>{numeral['formats'][numberFormat].format(total.amount)} {total.currency.name}</span>}</div>
                                );
                            })}</h5>
                            </td>
                            <td className="text-center"><h5>{totalCreditAmount?.map(total => {
                                return (
                                    <div key={total.currency.id}>{!total.currency.is_national && <span>{numeral['formats'][numberFormat].format(total.amount)} {total.currency.name}</span>}</div>
                                );
                            })}</h5>
                            </td>
                            <td/>
                            <td/>
                        </tr>
                        <tr className="bg-200">
                            <td/>
                            <td colSpan="1" className={'text-end'}>
                                <h5>{t(lang, "crm.contractor.view.tab.act_report.total_debt")}</h5>
                            </td>
                            <td className="text-center" colSpan="2">
                                <h5>{totalDifference?.amount ? (numeral['formats'][numberFormat].format(totalDifference.amount) + ' ' + totalDifference.currency.name) : 0}</h5>
                            </td>
                            <td className="text-center" colSpan="2">
                                <h5>
                                    {totalCurrencyDifference.map((total, index) => {
                                        return (
                                            <div key={index}><span>{numeral['formats'][numberFormat].format(total.amount)} {total.currency.name}</span></div>
                                        );
                                    })}
                                </h5></td>
                            <td/>
                            <td/>
                        </tr>
                        </tbody>
                        <tfoot>
                        <tr>
                            <th colSpan={'8'} className={'py-3'}>{t(lang, 'crm.contractor.view.tab.act_report.final_balance')}: {actReport?.after_debts.map((debt, index) => (
                                <SoftBadge key={index} bg={'primary'} className={'mx-1 fs-semi-bold text-black fs-0'}>{numeral['formats'][numberFormat].format(debt.amount)} {debt.currency.name}</SoftBadge>
                            ))}</th>
                        </tr>
                        </tfoot>
                    </Table>
                </Card.Body>
            </Card>

            <ContractorActReportsModal ref={modalRef} viewReport={viewReport} />
        </Fragment>
    );
};

export default ContractorActReports;
