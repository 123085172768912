import React from 'react';
import CashOut from "../../../../components/hippo/cashbox/cash-out/CashOut";
import {getCashOutAsync} from "../../../../app/store/reducers/cashbox/CashboxReducer";

const ViewCashOut = () => {
    return (
        <CashOut
            request={getCashOutAsync}
            title={"cashboxes.cash-out.nav.label"}
        />
    );
};

export default ViewCashOut;
