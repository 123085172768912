import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import dayjs from 'dayjs';
import {
    changeFilterOrdersAsync,
    selectCountOrders,
    selectFilterOptions,
    selectOrderIsLoading,
    selectOrders
} from '../../../app/store/reducers/order/orderReducer';
import {Card} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import OrderDataTableHeader from './OrderDataTableHeader';
import AdvanceTableWrapper from "../../common/advance-table/AdvanceTableWrapper";
import AdvanceTable from "../../common/advance-table/AdvanceTable";
import {
    AwaitCustomerApprove,
    AwaitExecutorApprove,
    getStatusColor,
    GetStatusText
} from '../../../enum/OrderStatus';
import AdvanceTableCustomPagination from '../../common/advance-table/AdvanceTableCustomPagination';
import {selectActiveOrganization} from '../../../app/store/reducers/auth/authReducer';
import {selectDateDayJSFormat, selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";


const OrderDataTable = ({activeColumns, loadOrders}) => {
    const currentDateDayJSFormat = useSelector(selectDateDayJSFormat)
    const orders = useSelector(selectOrders)
    const count = useSelector(selectCountOrders)
    const filters = useSelector(selectFilterOptions)
    const activeOrganization = useSelector(selectActiveOrganization)
    const isLoading = useSelector(selectOrderIsLoading)
    const dispatch = useDispatch()
    const lang = useSelector(selectLang);
    const t = Translate;

    const getActiveColumns = (activeColumns, columns = []) => {
        return columns?.filter(column => activeColumns[column?.id])
    }

    const columns = getActiveColumns(activeColumns, [
        {
            Header: "#",
            id: "index",
            cellProps: {
                width: '40px',
            },
            filterable: false,
            Cell: ({row}) => {
                return `${((filters.page - 1) * filters.limit) + row.index + 1}`;
            }
        },
        {
            accessor: 'info.number',
            id: "number",
            Header: t(lang, "edi.order.datatable.header.row.number"),
            cellProps: {
                width: '130px',
                className: 'p-0'
            },
            Cell: rowData => {
                const {original} = rowData.row;

                return <Link to={`../order/${original.id}`}>
                            <strong>
                                {original.info.number}
                            </strong>
                        </Link>

            }
        },
        {
            accessor: 'info.date',
            id: "date",
            Header: t(lang, "edi.order.datatable.header.row.date"),
            cellProps: {
                width: '80px',
            },
            Cell: rowData => {
                const {date} = rowData.row.original.info
                return <b>{dayjs(date, "YYYY-MM-DD").format(currentDateDayJSFormat)}</b>
            }
        },
        {
            accessor: 'branch.name',
            id: "unloading_point",
            Header: t(lang, "edi.order.datatable.header.row.unloading_point"),
            cellProps: {
                className: 'fw-bold'
            },
        },
        {
            accessor: 'expires_on',
            id: "expire_date",
            Header: t(lang, "edi.order.datatable.header.row.expire_date"),
            cellProps: {
                minwidth: '100px',
                className: 'fw-bold',

            },
            Cell: rowData => {
                const {expires_on} = rowData.row.original
                return dayjs(expires_on, "YYYY-MM-DD").format(currentDateDayJSFormat)
            }
        },
        {
            accessor: 'created_at',
            id: "created_date",
            Header: t(lang, "edi.order.datatable.header.row.created_date"),
            cellProps: {
                minwidth: '100px',
                className: 'fw-bold'
            },
            Cell: rowData => {
                const {created_at} = rowData.row.original
                return dayjs(created_at, "YYYY-MM-DD").format(currentDateDayJSFormat)
            }
        },
        {
            accessor: 'status',
            id: "status",
            Header: t(lang, "edi.order.datatable.header.row.status"),
            cellProps: {
                width: '130px',
                className: 'p-0'
            },
            Cell: rowData => {
                const {original} = rowData.row;
                return (
                    <div className="d-grid gap-2 text-center">
                        <span style={{backgroundColor: getStatusColor(original.status), color: 'white'}} className='rounded px-2 py-1'>
                            {t(lang, GetStatusText(original.status))}
                        </span>
                    </div>
                )
            }
        },
        {
            accessor: 'customer_employee_code',
            id: "customer_employee_code",
            Header: t(lang, "edi.order.datatable.header.row.customer_employee_code"),
            cellProps: {
                className: 'fw-bold'
            },
        },
        {
            accessor: 'contractor',
            id: "contractor",
            Header: t(lang, "edi.order.datatable.header.row.contractor"),
            cellProps: {
                className: 'fw-bold'
            },
            Cell: ({row: {original}}) => {
                const isCustomer = activeOrganization.inn === original.customer.inn

                if (isCustomer) {
                    return <>
                        {original.executor.name}
                    </>
                }

                return <>
                    {original.customer.name}
                </>
            }
        },
    ]);


    const onPageChange = (page) => dispatch(changeFilterOrdersAsync({...filters, page: page}));
    const onLimitChange = (limit) => dispatch(changeFilterOrdersAsync({...filters, limit: limit, page: 1}));

    return (
        <AdvanceTableWrapper
            columns={columns}
            data={orders}
            selection
            selectionColumnWidth={'50px'}
            pagination
            perPage={1000}
        >
            <Card className="mb-3">
                <Card.Header className={'pb-0'}>
                    <OrderDataTableHeader table loadOrders={loadOrders}/>
                </Card.Header>
                <Card.Body className="p-0">
                    <AdvanceTable
                        table
                        isLoading={isLoading}
                        headerClassName="bg-200 text-900 text-wrap align-middle"
                        rowClassName="align-middle white-space-nowrap"
                        tableProps={{
                            size: 'sm',
                            striped: true,
                            className: 'fs--1 mb-0 overflow-hidden'
                        }}
                    />
                </Card.Body>
                <Card.Footer>
                    <AdvanceTableCustomPagination
                        count={count}
                        limit={filters.limit}
                        page={filters.page}
                        onPageChange={onPageChange}
                        onLimitChange={onLimitChange}
                    />
                </Card.Footer>
            </Card>
        </AdvanceTableWrapper>
    );
};

export default OrderDataTable;
