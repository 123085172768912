import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {Controller, FormProvider, useForm} from "react-hook-form";
import React, {useState} from "react";
import {getRandomNumber} from "../../../../../helpers/utils";
import TelegramOrderBotService from "../../../../../services/telegram-order-bot/telegramOrderBotService";
import EventBus from "../../../../../app/eventbus/EventBus";
import {DELETE_TELEGRAM_ORDER_BOT_SUCCEED} from "../../../../../app/eventbus/telegram-bot/telegramOrderBotEvents";
import {Button, Form, Modal, Spinner} from "react-bootstrap";
import FalconCloseButton from "../../../../common/FalconCloseButton";
import Cleave from "cleave.js/react";
import classNames from "classnames";
import IconButton from "../../../../common/IconButton";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {toast} from "react-toastify";

const DeleteTelegramOrderBotOrderButton = ({order}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const form = useForm({
        defaultValues: {
            code: ""
        }
    });
    const [code] = useState(getRandomNumber(100000, 999999));
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const onDeleteTelegramOrderBotOrder = async (order) => {
        try {
            setLoading(true);
            await TelegramOrderBotService.deleteTelegramOrderBotOrder(order.id);
            setLoading(false);
            handleClose();
            form.reset();
            toast.success(t(lang, "common.toast.success"));
            EventBus.dispatch(DELETE_TELEGRAM_ORDER_BOT_SUCCEED);
        } catch (error) {
            toast.error(t(lang, "common.toast.error"));
            setLoading(false);
            console.log(error);
        }
    };

    return (
        <React.Fragment>
            <IconButton variant="falcon-danger"
                        icon={faTrash}
                        onClick={handleShow}
                        disabled={loading}
            >
                {t(lang, 'items.common.delete')}
                {loading && <Spinner className="align-middle ms-2" animation="border" role="switch" size="sm"/>}
            </IconButton>
            {show &&
                <Modal show={true}
                       centered={true}
                       onHide={handleClose}
                       size="lg"
                >
                    <FormProvider {...form}>
                        <Form>
                            <Modal.Header>
                                <Modal.Title>
                                    {t(lang, "crm.telegram_bots.order.delete_order_modal_title")}
                                </Modal.Title>
                                <FalconCloseButton onClick={handleClose}/>
                            </Modal.Header>
                            <Modal.Body>
                                <Controller name={'code'}
                                            rules={{
                                                required: t(lang, 'items.common.validations.is_required'),
                                                validate: (value) => {
                                                    if (+value === code)
                                                        return true;
                                                    return t(lang, 'items.common.validations.delete_item.modal.invalid_key_code', {code: code});
                                                }
                                            }}
                                            render={({field, fieldState: {error}}) => (
                                                <Form.Group>
                                                    <Form.Label>
                                                        <span>{t(lang, 'items.common.delete_item_modal.key_code')}:</span>
                                                        <span className="ms-1 text-primary">{code}</span>
                                                    </Form.Label>
                                                    <Cleave onChange={(e) => field.onChange(e.target.rawValue)}
                                                            value={field.value}
                                                            htmlRef={field.ref}
                                                            placeholder={t(lang, 'items.common.delete_item_modal.key_code.placeholder')}
                                                            className={classNames('form-control', {
                                                                'is-invalid': !!error
                                                            })}
                                                            options={{
                                                                numeral: true,
                                                                delimiter: ' ',
                                                                numeralDecimalScale: 3
                                                            }}
                                                    />
                                                    {!!error && <Form.Control.Feedback
                                                        type="invalid">{error.message}</Form.Control.Feedback>}
                                                </Form.Group>
                                            )}
                                />
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    {t(lang, "items.common.cancel")}
                                </Button>
                                <Button variant="danger"
                                        disabled={loading}
                                        onClick={form.handleSubmit(() => onDeleteTelegramOrderBotOrder(order))}
                                >
                                    {t(lang, "items.common.delete")}
                                    {loading && <Spinner className="align-middle ms-2" animation="border" role="switch" size="sm"/>}
                                </Button>
                            </Modal.Footer>
                        </Form>
                    </FormProvider>
                </Modal>
            }
        </React.Fragment>
    );
};

export default DeleteTelegramOrderBotOrderButton;