import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {selectCurrency} from "../../../../../app/store/reducers/currency/currencyReducer";
import {useHistory} from "react-router";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {FormProvider, useForm} from "react-hook-form";
import dayjs from "dayjs";
import {UZS} from "../../../../../enum/CurrencyCode";
import {toast} from "react-toastify";
import EventBus from "../../../../../app/eventbus/EventBus";
import {Form} from "react-bootstrap";
import {
    getTransferItemAsync,
    transferAddItemAsync,
    transferUpdateNoteAsync,
    transferRegisterAsync,
    transferUpdateNumberAsync,
    selectTransferDefaultsSettings,
    transferChangeEmployeeAsync
} from "../../../../../app/store/reducers/warehouse-operation/transferReducer";
import {
    WAREHOUSE_OPERATION_TRANSFER_ADD_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_TRANSFER_APPROVED_ITEM_SUCCESS, WAREHOUSE_OPERATION_TRANSFER_DATE_SUCCESS,
    WAREHOUSE_OPERATION_TRANSFER_DELETE_ITEM_SUCCESS, WAREHOUSE_OPERATION_TRANSFER_NUMBER_SUCCESS,
    WAREHOUSE_OPERATION_TRANSFER_REGISTER_SUCCESS,
    WAREHOUSE_OPERATION_TRANSFER_UPDATE_ITEM_SUCCESS
} from "../../../../../app/eventbus/warehouse-operation/transferEvents";
import TransferForm from "./TransferForm";
import {selectWarehouses} from "../../../../../app/store/reducers/warehouse/warehouseReducer";
import {selectDefaultSetting} from "../../../../../app/store/reducers/settings/settingsReducer";


const AddTransferForm = () => {
    const transferDefaultsSettings = useSelector(selectTransferDefaultsSettings)
    const [transfer, setTransfer] = useState(null)
    const [loading, setLoading] = useState(false)
    const warehouses = useSelector(selectWarehouses)
    const currencies = useSelector(selectCurrency)
    const history = useHistory()
    const lang = useSelector(selectLang)
    const t = Translate;
    const settings = useSelector(selectDefaultSetting)



    const form = useForm({
        defaultValues: {
            employee_id: transferDefaultsSettings?.defaultEmployeeId || null,
            date: dayjs(new Date(), "YYYY-MM-DD HH:mm").toDate(),
            note: '',
            number: null,
            item_id: null,
            warehouse_id: settings?.warehouse?.default_id || null,
            warehouse_from_id: settings?.warehouse?.default_id || null,
            warehouse_to_id: null,
            quantity: null,
            marks_from: [],
            marks_to: [],
            commonPrice: {
                amount: '0',
                currency_id: currencies?.find(c => c?.code === UZS)?.id,
            },
        }
    })

    const resetForm = (formData) => {
        form.reset({
            employee_id: formData?.employee?.id || formData?.employee_id,
            date: formData?.date && dayjs(formData?.date, "YYYY-MM-DD HH:mm").toDate(),
            note: formData?.note,
            number: formData?.number,
            item_id: null,
            quantity: null,
            warehouse_id: formData.warehouse_id,
            warehouse_from_id: formData.warehouse_id,
            warehouse_to_id: null,
            warehouse: null,
            package_measurements: null,
            marks_from: [],
            marks_to: [],
            commonPrice: {
                amount: '0',
                currency_id: currencies?.find(c => c?.code === UZS)?.id,
            },
        })
    }



    const onSubmit = async (formData, event) => {
        const buttonType = event?.nativeEvent?.submitter?.name
        const quantity = +formData?.quantity * +formData?.package_measurements?.quantity

        const item = {
            item_id: formData?.item_id,
            quantity: quantity,
            marks_from: formData?.marks_from || [],
            marks_to: formData?.marks_to || [],
            price: {
                amount: +formData?.commonPrice?.amount,
                currency_id: formData?.commonPrice?.currency_id,
            },
            warehouse_from_id: formData?.warehouse_from_id,
            warehouse_to_id: formData?.warehouse_to_id,
        }

        if (buttonType === "addOrEditTransfer" || event === "addOrEditTransfer") {
            setLoading(true)
            if (!transfer) {
                await transferRegisterAsync({
                    date: dayjs(formData?.date).format("YYYY-MM-DD HH:mm:ss"),
                    note: formData?.note,
                    number: formData?.number?.trim() ? formData?.number : null,
                    items: [item],
                    employee_id: formData?.employee_id,
                })
                    .then(({data}) => {
                        resetForm(data)
                        toast.success(t(lang, "warehouse.operation.item.common.toast.success"))
                    })
                    .catch((error) => {
                        if (error.response.data.error.includes("warehouse")) {
                            toast.error(t(lang, "Склад не выбран"))
                        } else {
                            toast.error(t(lang, "warehouse.operation.item.common.toast.error"))
                        }
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            }
            if (transfer) {
                await transferAddItemAsync({
                    transferId: transfer?.id,
                    operation: item,
                })
                    .then(() => {
                        updateNote(formData)
                        // updateNumber(formData)
                        resetForm(formData)
                        updateEmployee(formData)
                        toast.success(t(lang, "warehouse.operation.item.common.toast.success"))
                    })
                    .catch((error) => {
                        if (error.response.data.error.includes("warehouse")) {
                            toast.error(t(lang, "Склад не выбран"))
                        } else {
                            toast.error(t(lang, "warehouse.operation.item.common.toast.error"))
                        }
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            }
        }
    }

    const updateEmployee = (formData) => {
        if (formData?.employee_id || transfer?.employee) {
            if (formData?.employee_id !== transfer?.employee?.id) {
                transferChangeEmployeeAsync({transferId: transfer?.id, employeeId: formData?.employee_id})
                    .then(({data}) => {
                        setTransfer(data, data.items.reverse())
                    })
                    .catch(() => {})
            }
        }
    }

    const updateNote = (formData) => {
        if (formData?.note !== transfer?.note) {
            transferUpdateNoteAsync({transferId: transfer?.id, note: formData?.note})
                .then(({data}) => {
                    setTransfer(data, data.items.reverse())
                })
                .catch(() => {})
        }
    }

    // const updateNumber = (formData) => {
    //     if (formData?.number?.trim() && formData?.number !== transfer?.number) {
    //         transferUpdateNumberAsync({transferId: transfer?.id, number: formData?.number})
    //             .then(({data}) => {
    //                 setTransfer(data, data.items.reverse())
    //             })
    //             .catch(() => {})
    //     }
    // }

    useEffect(() => {
        const onRegisterHandler = EventBus.on(WAREHOUSE_OPERATION_TRANSFER_REGISTER_SUCCESS, (id) => {
            loadTransfer(id)
        })

        const onAddItemHandler = EventBus.on(WAREHOUSE_OPERATION_TRANSFER_ADD_ITEM_SUCCESS, (id) => {
            loadTransfer(id)
        })

        const onUpdateItemHandler = EventBus.on(WAREHOUSE_OPERATION_TRANSFER_UPDATE_ITEM_SUCCESS, (id) => {
            loadTransfer(id)
        })

        const onDeleteItemHandler = EventBus.on(WAREHOUSE_OPERATION_TRANSFER_DELETE_ITEM_SUCCESS, (id) => {
            loadTransfer(id)
        })

        const onApprovedHandler = EventBus.on(WAREHOUSE_OPERATION_TRANSFER_APPROVED_ITEM_SUCCESS, (id) => {
            history.push(`./view/${id}`)
        })

        const onChangedDateHandler = EventBus.on(WAREHOUSE_OPERATION_TRANSFER_DATE_SUCCESS, (id) => {
            loadTransfer(id)
        })

        const onChangedNumberHandler = EventBus.on(WAREHOUSE_OPERATION_TRANSFER_NUMBER_SUCCESS, (id) => {
            loadTransfer(id)
        })

        return () => {
            EventBus.remove(WAREHOUSE_OPERATION_TRANSFER_REGISTER_SUCCESS, onRegisterHandler)
            EventBus.remove(WAREHOUSE_OPERATION_TRANSFER_ADD_ITEM_SUCCESS, onAddItemHandler)
            EventBus.remove(WAREHOUSE_OPERATION_TRANSFER_UPDATE_ITEM_SUCCESS, onUpdateItemHandler)
            EventBus.remove(WAREHOUSE_OPERATION_TRANSFER_DELETE_ITEM_SUCCESS, onDeleteItemHandler)
            EventBus.remove(WAREHOUSE_OPERATION_TRANSFER_APPROVED_ITEM_SUCCESS, onApprovedHandler)
            EventBus.remove(WAREHOUSE_OPERATION_TRANSFER_DATE_SUCCESS, onChangedDateHandler)
            EventBus.remove(WAREHOUSE_OPERATION_TRANSFER_NUMBER_SUCCESS, onChangedNumberHandler)
        }
    }, [])

    useEffect(() => {
        form.setValue('warehouse_id', settings?.warehouse?.default_id || null)
        form.setValue('warehouse_from_id', settings?.warehouse?.default_id || null)
    }, [settings])

    const loadTransfer = async (id) => {
        await getTransferItemAsync({transferId: id})
            .then((response) => {
                setTransfer(response.data, response.data.items.reverse())
            })
            .catch((error) => {
                setTransfer(null)
            })
    }


    return(
        <>
            <FormProvider {...form}>
                <Form onSubmit={form.handleSubmit(onSubmit)}>
                    <TransferForm transfer={transfer} loading={loading} addItem={form.handleSubmit(onSubmit)}/>
                </Form>
            </FormProvider>
        </>
    )
}

export default AddTransferForm;
