import React, {useEffect, useState} from 'react';
import {useParams} from "react-router";
import {loadOrganizationBinding} from "../../../app/store/reducers/organization-binding/organizationBindingReducer";
import Error404 from "../../../components/errors/Error404";
import OwnerBinding from "../../../components/hippo/organization-bindings/owner/OwnerBinding";
import {useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../app/store/reducers/auth/authReducer";

const ViewOwner = () => {
    const {id} = useParams();
    const activeOrganization = useSelector(selectActiveOrganization);
    const [loading, setLoading] = useState(false);
    const [isInitialized, setIsInitialized] = useState(false);
    const [binding, setBinding] = useState(null);

    const load = async (id) => {
        try {
            setLoading(true);
            const binding = await loadOrganizationBinding(id);
            setBinding(binding);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setBinding(null);
            setLoading(false);
        }
        setIsInitialized(true);
    }

    useEffect(() => {
        load(id)
    }, [id, activeOrganization]);

    if (loading)
        return <>loading...</>;

    if (!isInitialized)
        return null;

    if (!binding)
        return <Error404/>;

    return <OwnerBinding binding={binding}/>
};

export default ViewOwner;