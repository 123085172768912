import {FormProvider, useForm} from "react-hook-form";
import {Form} from "react-bootstrap";
import ActFormInfo from "../ActFormInfo";
import ActFormOwnerBuyer from "../ActFormOwnerBuyer";
import ActFormDataTable from "../ActFormDataTable";
import React, {useEffect, useState} from "react";
import dayjs from "dayjs";
import {ActFormSendAsync, ActFormSendDraft} from "../../../../../app/store/reducers/roaming/roamingActReducer";
import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../../../app/store/reducers/auth/authReducer";
import {selectCurrentCertificate} from "../../../../../app/store/reducers/eimzo/eimzoReducer";
import {useHistory} from "react-router-dom";
import {selectLang, Translate, updateShowInProgressAsync} from "../../../../../app/store/reducers/main/mainReducer";
import {
    generateIdAsync,
    loadCompanyProductCatalogsAsync,
    loadMeasurementsAsync, selectCompanyCatalogs
} from "../../../../../app/store/reducers/roaming/roamingReducer";
import EventBus from "../../../../../app/eventbus/EventBus";
import {
    ROAMING_ACT_DRAFT_FAILED,
    ROAMING_ACT_DRAFT_REQUESTED, ROAMING_ACT_DRAFT_SUCCESS,
    ROAMING_ACT_REGISTER_FAILED,
    ROAMING_ACT_REGISTER_REQUESTED,
    ROAMING_ACT_REGISTER_SUCCESS
} from "../../../../../app/eventbus/roaming/roamingActEvents";
import {toast} from "react-toastify";


const FillActForm = ({act}) => {
    const activeOrganization = useSelector(selectActiveOrganization);
    const currentCertificate = useSelector(selectCurrentCertificate);
    const catalogs = useSelector(selectCompanyCatalogs)
    const dispatch = useDispatch();
    const history = useHistory()
    const lang = useSelector(selectLang);
    const t = Translate;

    useEffect(() => {
        dispatch(loadCompanyProductCatalogsAsync(activeOrganization.inn, lang));
        dispatch(loadMeasurementsAsync(lang));

        methods.setValue('ActSeller', {
            name: activeOrganization?.name || '',
            inn: activeOrganization?.inn || '',
            pinfl: activeOrganization?.pinfl || '',
        });

    }, [activeOrganization])

    useEffect(() => {
        const onSendActRequestedHandler = EventBus.on(ROAMING_ACT_REGISTER_REQUESTED, () => {
            dispatch(updateShowInProgressAsync(true))
        })

        const onSendActSuccessHandler = EventBus.on(ROAMING_ACT_REGISTER_SUCCESS, act => {
            toast.success(t(lang, "edi.common.toast.success"))
            history.push(`/roaming/act/${act.id}`)
            dispatch(updateShowInProgressAsync(false))
        })

        const onSendActFailedHandler = EventBus.on(ROAMING_ACT_REGISTER_FAILED, () => {
            toast.error(t(lang, "edi.common.toast.error"))
            dispatch(updateShowInProgressAsync(false))
        })

        const onDraftSendActRequestedHandler = EventBus.on(ROAMING_ACT_DRAFT_REQUESTED, () => {
            dispatch(updateShowInProgressAsync(true))
        })

        const onDraftSendActSuccessHandler = EventBus.on(ROAMING_ACT_DRAFT_SUCCESS, act => {
            toast.success(t(lang, "roaming.act_form_save_success"))
            history.push(`/roaming/draft-act/${act.id}`)
            dispatch(updateShowInProgressAsync(false))
        })

        const onDraftSendActFailedHandler = EventBus.on(ROAMING_ACT_DRAFT_FAILED, () => {
            toast.error(t(lang, "roaming.act_form_save_error"))
            dispatch(updateShowInProgressAsync(false))
        })





        return () => {
            EventBus.remove(ROAMING_ACT_REGISTER_REQUESTED, onSendActRequestedHandler)
            EventBus.remove(ROAMING_ACT_REGISTER_SUCCESS, onSendActSuccessHandler)
            EventBus.remove(ROAMING_ACT_REGISTER_FAILED, onSendActFailedHandler)
            EventBus.remove(ROAMING_ACT_DRAFT_REQUESTED, onDraftSendActRequestedHandler)
            EventBus.remove(ROAMING_ACT_DRAFT_SUCCESS, onDraftSendActSuccessHandler)
            EventBus.remove(ROAMING_ACT_DRAFT_FAILED, onDraftSendActFailedHandler)
        }

    },[])


    const methods = useForm({
        defaultValues: {
            act: {
                info: act.info.number,
                date: dayjs(act.info.date).toDate(),
            },
            contract: {
                number: act.contract.number,
                date: dayjs(act.contract.date).toDate(),
            },
            ActComment: act.info.text,
            ActSeller: {
                inn: act.executor.inn,
                name: act.executor.name,
            },
            SellerTin: act.executor.inn,
            SellerName: act.executor.name,
            SellerBranchCode: act?.executor?.branch?.code || null,
            SellerBranchName: act?.executor?.branch?.name || "",
            BuyerBranchName: act?.customer?.branch?.name || "",
            BuyerBranchCode: act?.customer?.branch?.code || null,
            ActBuyer: {
                inn: act.customer.inn,
                name: act.customer.name,
            },
            itemAct: act.items.map(item => {
                const catalog = catalogs?.find(c => c?.class_code === item?.catalog.code)
                const packages = catalog?.package_names.find(p => p?.code === +item?.packageCode)



                return {
                    catalogClassCode: item.catalog.code,
                    catalogClassName: item.catalog.name,
                    Items: item.name,
                    measurementId: item.measure_id,
                    packageCode: +item.packageCode,
                    packageName: item.packageName,
                    catalog: catalog,
                    packages: packages,
                    quantity: item.quantity,
                    price: item.price,
                    totalSum: item.total,
                }
            })
        },
    });




    const onSubmit = async (formData, event) => {
        const buttonType = event.nativeEvent.submitter.name
        const [ActId, ActProductId] = await generateIdAsync(2)

        const ActSend = JSON.stringify({
            ActId,
            ActDoc: {
                ActNo: formData.act.info,
                ActDate: dayjs(formData.act.date).format('YYYY-MM-DD'),
                ActText: formData.ActComment,
            },
            ContractDoc: {
                ContractNo: formData.contract.number,
                ContractDate: dayjs(formData.contract.date).format('YYYY-MM-DD'),
            },
            SellerTin: formData.ActSeller.pinfl || formData.ActSeller.inn,
            SellerName: formData.ActSeller.name,
            SellerBranchCode: formData.SellerBranchCode,
            SellerBranchName: formData.SellerBranchName,
            BuyerTin: formData.ActBuyer.pinfl || formData.ActBuyer.inn,
            BuyerName: formData.ActBuyer.name,
            BuyerBranchName: formData.BuyerBranchName,
            BuyerBranchCode: formData.BuyerBranchCode,
            ProductList: {
                ActProductId,
                Tin: formData.ActSeller.pinfl || formData.ActSeller.inn,
                Products: formData.itemAct.map((item, index) => {
                    return {
                        OrdNo: index + 1,
                        Name: item.Items,
                        CatalogCode: item.catalogClassCode,
                        CatalogName: item.catalogClassName,
                        PackageCode: item.packageCode ? `${item.packageCode}` : null,
                        PackageName: item.packageName,
                        MeasureId: item.measurementId,
                        Count: item.quantity,
                        Summa: item.price,
                        TotalSum: item.totalSum,
                    }
                })
            },
        })

        if(buttonType === "send") {
            if(currentCertificate?.keyId) {
                await ActFormSendAsync(currentCertificate, lang, ActSend, ActId, formData.ActSeller.pinfl || formData.ActSeller.inn)
            }
        } else if(buttonType === "draft") {
            await ActFormSendDraft(ActSend)
        }
    };



    return (
        <>
            <FormProvider {...methods}>
                <Form onSubmit={methods.handleSubmit(onSubmit)} id="ActForm">
                    <ActFormInfo/>
                    <ActFormOwnerBuyer/>
                    <ActFormDataTable/>
                </Form>
            </FormProvider>
        </>
    )
}


export default FillActForm;