import React, {Fragment, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    deleteCashbox,
    selectCashbox, selectedCurrencyForCashBox
} from "../../../../app/store/reducers/cashbox/CashboxReducer";
import AdvanceTableWrapper from "../../../common/advance-table/AdvanceTableWrapper";
import {Badge, Card, Col, Dropdown, OverlayTrigger, Popover, Row,} from "react-bootstrap";
import AdvanceTable from "../../../common/advance-table/AdvanceTable";
import {selectLang, selectNumberFormat, Translate} from "../../../../app/store/reducers/main/mainReducer";
import CashBoxesDatatableHeader from "./CashBoxesDatatableHeader";
import CashBoxesRegisterModal from "./CashBoxesRegisterModal";
import {ContractorPaymentTypes, PaymentTypeCashCode} from "../../../../enum/ContractorWrapper";
import numeral from "numeral";
import CashBoxAmountStatistics from "../CashBoxAmountStatistics";
import {
    totalAmountCard,
    totalAmountCash,
    totalAmountInCashBox, totalGroupAmounts, totalGroupCashAmount,
} from "../../../../enum/cash_box/cashBoxWrapper";
import CardDropdown from "../../../common/CardDropdown";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {selectCurrency} from "../../../../app/store/reducers/currency/currencyReducer";
import CashBoxCashInDeleteModal from "../cash-in/CashBoxCashInDeleteModal";
import {Link} from "react-router-dom";
import classNames from "classnames";
import SoftBadge from "../../../common/SoftBadge";
import {isApprovedCheckedWarehouseOperation} from "../../warehouse-operation/enum/warehouseOperationWrapper";
import checkPermission from "../../../../enum/Permission/CheckPermission";

const CashBoxesDatatable = () => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const cashbox = useSelector(selectCashbox)
    const numberFormat = useSelector(selectNumberFormat);
    const currency = useSelector(selectCurrency)

    const [show, setShow] = useState(false)
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    const [defaultValue, setDefaultValue] = useState(null)
    const selectedCurrency = useSelector(selectedCurrencyForCashBox)

    const totalAmountDataTableCash = useMemo(() => {
            return  totalAmountCash(cashbox, currency)
    }, [cashbox, currency])

    const totalAmountDataTableCard = useMemo(() => {
            return  totalAmountCard(cashbox, currency)
    }, [cashbox, currency])


    const columns = [
        {
            Header: '#',
            Cell: ({row}) => `${row.index + 1}`,
            cellProps: {
                width: '50px',
            }
        },
        {
            Header: t(lang, "edi.common.name"),
            cellProps: {className: 'pe-4'},
            Cell: ({row: {original}}) => {
                return (
                    <Link to={{
                        pathname: `/cash-box/operation`,
                        state: original
                    }}>
                        <strong>{original.name}</strong>
                    </Link>
                );
            }
        },
        {
            Header: t(lang, "edi.contract_formation.datatable.header.row.created_at"),
            cellProps: {className: 'pe-4'},
            Cell: ({row: {original}}) => {
                return (
                    <span>{original.created_at}</span>
                );
            }
        },
        {
            Header: t(lang, "edi.common.branch"),
            cellProps: {className: 'pe-4'},
            Cell: ({row: {original}}) => {
                return (
                    <span>{original.branch?.name}</span>
                );
            }
        },
        {
            Header: t(lang, "crm.data.table.total.sum"),
            headerProps: {className: "text-center"},
            cellProps: {className: 'text-end'},
            Cell: ({row: {original}}) => {
                const total = totalAmountInCashBox(original.amounts, currency, true)
                return (
                    <span className={'fw-bold fs-0'}>
                        <span className={'text-700'}>
                            {numeral['formats'][numberFormat].format(total / selectedCurrency?.rate)}
                        </span>
                        <strong className={'text-info ms-2 fw-bold'}>{selectedCurrency?.name}</strong>
                    </span>
                );
            }
        },
        {
            Header: t(lang, "crm.payment_type.cash"),
            headerProps: {className: "text-end pe-4"},
            cellProps: {className: 'pe-2 text-end'},
            Cell: ({row: {original}}) => {
                const cash = totalGroupCashAmount(original.amounts.filter(i => i.money_type === PaymentTypeCashCode))
                return (
                    <Fragment>
                        {cash.map((i, index) => <Badge key={index} bg='success' className='me-2 py-2'
                                                       style={{fontSize: 12}}>
                            <span>{numeral.formats[numberFormat].format(i?.total)}</span>
                            <strong className={'ms-2 fw-bold'}>{i?.name}</strong>
                        </Badge>)}
                    </Fragment>
                );
            }
        },
        {
            Header: t(lang, "Карта"),
            headerProps: {className: "text-end pe-4"},
            cellProps: {width: 250, className: 'pe-2 text-end'},
            Cell: ({row: {original}}) => {
                let plasticCard = totalGroupAmounts(original.amounts.filter(i => i.money_type !== PaymentTypeCashCode && (i.money_type !== null && i.money_type))) || []
                return (
                    <Fragment>
                        {plasticCard.map((item, index) => <Badge key={index} bg='info' className='me-2'
                                                                 style={{fontSize: 12}}>
                                <span>{numeral.formats[numberFormat].format(item?.total)}</span>
                                <strong className={'ms-2 fw-bold'}>{item?.name}</strong>
                                <i className={'d-block fs--2 fw-bold text-center m-0 mt-1 text-dark'}>{t(lang, ContractorPaymentTypes.find(b => b.value === item?.money_type)?.label)}</i>
                            </Badge>
                        )}
                    </Fragment>
                );
            }
        },
        {
            Header: "",
            accessor: 'none',
            cellProps: {
                width: '80px',
                className: 'text-end'
            },
            Cell: ({row: {original}}) => {
                return (
                    <CardDropdown drop={'start'} btnRevealClass="btn-reveal-sm">
                        <Dropdown.Item
                            onClick={() => {
                                setDefaultValue(original)
                                setShow(true)
                            }}
                            className="text-warning">
                            <FontAwesomeIcon className="me-1" icon={"edit"} color={'warning'}/>
                            <span>{t(lang, "roaming.common.edit")}</span>
                        </Dropdown.Item>

                        <Dropdown.Item
                            onClick={() => {
                                setShowConfirmationModal(true)
                                setDefaultValue(original)
                            }}
                            className="text-danger">
                            <FontAwesomeIcon className="me-1" icon={"trash"}/>
                            <span>{t(lang, "roaming.invoice.delete")}</span>
                        </Dropdown.Item>
                    </CardDropdown>
                );
            }
        },
    ]

    const onHide = () => {
        setShow(false)
        setDefaultValue(null)
    }

    const onHideConfirmationModal = () => {
        setShowConfirmationModal(false)
        setDefaultValue(null)
    }


    return (
        <Fragment>
            <CashBoxAmountStatistics />
            <Card>
                <CashBoxesDatatableHeader />
                <Card.Body className={'py-1'}>
                    <Row>
                        {
                            cashbox.map((item, index) => <Col key={index} xxl={3} md={6} className={classNames(`px-2 mb-sm-2 border-md-0 border-sm-dashed-bottom`, {
                                'border-md-end border-xxl-bottom-0': (index + 1) % 4 !== 0,
                            }) }>
                                <div className={'text-center position-relative'}>
                                    <Link className={'fw-bold '}  to={{
                                        pathname: `/cash-box/operation`,
                                        state: item
                                    }}>{item.name}</Link>
                                    <div className={'position-absolute'} style={{right: 0, top: 0}}>
                                        <CardDropdown drop={'start'} btnRevealClass="btn-reveal-sm">
                                            <Dropdown.Item
                                                onClick={() => {
                                                    setDefaultValue(item)
                                                    setShow(true)
                                                }}
                                                className="text-warning">
                                                <FontAwesomeIcon className="me-1" icon={"edit"} color={'warning'}/>
                                                <span>{t(lang, "roaming.common.edit")}</span>
                                            </Dropdown.Item>

                                            <Dropdown.Item
                                                onClick={() => {
                                                    setShowConfirmationModal(true)
                                                    setDefaultValue(item)
                                                }}
                                                className="text-danger">
                                                <FontAwesomeIcon className="me-1" icon={"trash"}/>
                                                <span>{t(lang, "roaming.invoice.delete")}</span>
                                            </Dropdown.Item>
                                        </CardDropdown>
                                    </div>
                                </div>
                                <Row className={'align-items-center justify-content-between'}>
                                    <Col xs={12} md={6}>{item.branch.name}</Col>
                                    <Col xs={12} md={6} className={'text-end'}>
                                        <span className={'fs--1'}>{item.created_at}</span>
                                    </Col>
                                    <Col md={12} className={'text-end'}>
                                        {
                                            totalGroupCashAmount(item.amounts.filter(i => i.money_type === PaymentTypeCashCode))
                                                .filter(i => i.total !== 0)
                                                .map((i, index) => (
                                                    <strong key={index} className={classNames('text-success ms-2 py-2')}
                                                           style={{fontSize: 12}}>
                                                        <span>{numeral.formats[numberFormat].format(i?.total)}</span>
                                                        <strong className={'ms-2 fw-bold text-info'}>{i?.name}</strong>
                                                    </strong>
                                                ))
                                        }
                                    </Col>
                                    <Col md={12} className={'text-end mt-2 d-flex flex-wrap gap-2 justify-content-end mb-md-3'}>
                                        {
                                            totalGroupAmounts(item.amounts.filter(i => i.money_type !== PaymentTypeCashCode && (i.money_type !== null && i.money_type)))
                                                .filter(i => i.total !== 0)
                                                .map((i, index) => (

                                                    <OverlayTrigger trigger={['hover', 'focus']}
                                                                    placement={'bottom'}
                                                                    overlay={
                                                                        <Popover id="popover-basic" className='mt-0'>
                                                                            <Popover.Header>
                                                                                <div className={'d-block text-center fs--1 px-2'}
                                                                                           style={{backgroundColor: ContractorPaymentTypes.find(b => b.value === i?.money_type)?.bg}}>{t(lang, ContractorPaymentTypes.find(b => b.value === i?.money_type).label)}</div>
                                                                            </Popover.Header>
                                                                        </Popover>
                                                                    }
                                                    >
                                                        <div className={'d-block text-center fs--1 cursor-pointer'}>
                                                            <strong key={index} className={`px-1 fw-bold`}
                                                                    style={{fontSize: 12, backgroundColor: ContractorPaymentTypes.find(b => b.value === i?.money_type)?.bg}}>
                                                                <span>{numeral.formats[numberFormat].format(i?.total)}</span>
                                                                <strong className={'ms-2 fw-bold'}>{i?.name}</strong>
                                                            </strong>
                                                        </div>
                                                    </OverlayTrigger>


                                                ))
                                        }
                                    </Col>
                                </Row>
                            </Col>)
                        }
                    </Row>
                </Card.Body>
            </Card>
            {/*<AdvanceTableWrapper
                data={cashbox}
                columns={columns}
                perPage={1000}
                pagination
            >
                <Card>
                    <Card.Header className={'pb-0'}>
                        <CashBoxesDatatableHeader />
                    </Card.Header>
                    <Card.Body>
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}/>
                    </Card.Body>
                </Card>
            </AdvanceTableWrapper>*/}

            <CashBoxesRegisterModal show={show} onHide={onHide} defaultValue={defaultValue}/>
            <CashBoxCashInDeleteModal show={showConfirmationModal} setShow={onHideConfirmationModal}
                                      id={defaultValue?.id}
                                      deleteRequest={deleteCashbox}/>
        </Fragment>
    );
};

export default CashBoxesDatatable;
