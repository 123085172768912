import React, {useState} from 'react';
import {Badge, Button, Form} from "react-bootstrap";
import {labelWrapper} from "../../../../enum/KanbanWrapper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useDispatch, useSelector} from "react-redux";
import {addItemRegisterLabel, itemRegisterLabel} from "../../../../app/store/reducers/kanban/kanbanReducer";
import {useForm} from "react-hook-form";
import classNames from "classnames";
import PropTypes from "prop-types";

const CreatLabel = ({setCreateLabel, itemId, edit}) => {
    const dispatch = useDispatch()
    const t = Translate;
    const lang = useSelector(selectLang);
    const [loading, setLoading] = useState(false)
    const [isValidate, setIsValidate] = useState(false)

    const methods = useForm({
        defaultValues: {
            name: "",
            color_type: null
        }
    })

    const name = methods.watch('name')
    const color_type = methods.watch('color_type')

    const onSubmit = () => {
        if (!name) {
            return setIsValidate(true)
        }

        const params = {
            name: name,
            color_type: color_type?.color_type || null
        }

        setIsValidate(false)

        if (edit) {
            setLoading(true)
            itemRegisterLabel(itemId, params)
                .then(() => {
                    methods.reset()
                    setCreateLabel(false)
                })
                .catch((e) => new Error(e))
                .finally(() => setLoading(false))
        } else {
            dispatch(addItemRegisterLabel(params))
            methods.reset()
            setCreateLabel(false)
        }
    }

    return (
        <div className={'px-2'}>
            <Form.Control
                value={name}
                onChange={(e) => methods.setValue('name', e.target.value)}
                size={'sm'}
                className={classNames('d-block mb-2 border-1', {
                    "border-danger": isValidate
                })}
                placeholder={t(lang, 'task.board.form.title')}
            />
            <Form.Control.Feedback type={'invalid'}>{methods.formState?.errors?.name?.message}</Form.Control.Feedback>

            <div className={'d-flex justify-content-between gap-2 mb-2'}>
                {labelWrapper.map((label,index) =>
                    <Badge
                        key={index}
                        onClick={() => methods.setValue('color_type', label)}
                        bg={label.bg} style={{width: 20, height: 20}}
                        className='d-flex justify-content-center align-items-center cursor-pointer d-block'
                    >
                        {(color_type?.color_type === label.color_type) &&
                            <FontAwesomeIcon color={'white'} icon={faCheck}/>}
                    </Badge>
                )}
                {/*  {fields.some(i => i.status === label.status) &&
                                       <FontAwesomeIcon color={'black'} icon={faCheck}/>}*/}
            </div>
            <div className={'d-flex justify-content-between'}>
                <Button disabled={loading} onClick={onSubmit} size={'sm'} >{t(lang, 'task.board.common.create')}</Button>
                <Button variant={'falcon-danger'} onClick={()=>setCreateLabel(false)} size={'sm'} >{t(lang, 'task.board.common.cancel')}</Button>
            </div>
        </div>
    );
};


export default CreatLabel;
