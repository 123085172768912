import React from 'react';
import {Nav} from 'react-bootstrap';
import ProfileDropdown from "./ProfileDropdown";
import OrganizationBalanceInfo from "./OrganizationBalanceInfo";
import ActiveOrganizationComponent from "./ActiveOrganizationComponent";
import TechSupportInformation from "./TechSupportInformation";
import NotificationDropdown from "./NotificationDropdown";
import OrganizationSettings from "./OrganizationSettings";

const TopNavRightSideNavItem = () => {
    return (
        <Nav navbar className={"navbar-nav-icons flex-row ms-lg-auto align-items-center"} as="ul">
            <ActiveOrganizationComponent />
            <OrganizationBalanceInfo />
            <OrganizationSettings />
            <NotificationDropdown />
            <TechSupportInformation />
            <ProfileDropdown/>
        </Nav>
    );
};

export default TopNavRightSideNavItem;
