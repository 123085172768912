import React, {useState} from 'react';
import {Col, Row} from "react-bootstrap";
import IconButton from "../../common/IconButton";
import GroupMember from "./GroupMember";
import {useSelector} from "react-redux";
import {selectBoardName, selectMembers} from "../../../app/store/reducers/kanban/kanbanReducer";
import MemberModal from "../modal/member/MemberModal";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";

const KanbanHeader = () => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const members = useSelector(selectMembers)
    const boardName = useSelector(selectBoardName)
    const [show, setShow] = useState(false)

    return (
        <Row className=" gx-0 kanban-header rounded-2 px-x1 p-2 mt-0 mb-3">
            <Col className="d-flex align-items-center">
                <GroupMember
                    avatarSize="l"
                    members={members}
                    showMember={4}
                    className="d-none d-md-block"
                />
                <div className="vertical-line vertical-line-400 position-relative h-100 mx-3 d-none d-md-flex"></div>
                <IconButton
                    onClick={()=>setShow(true)}
                    variant={'falcon-primary'}
                    size="sm"
                    icon={'plus'}
                    iconClassName="me-2"
                    className={'me-2 d-none d-md-block '}
                >
                    {t(lang, 'task.board.add.member.button.title')}
                </IconButton>
                <MemberModal setShow={setShow} show={show} />
            </Col>
            <Col>
                <h4 className={'text-end m-0'}>{boardName}</h4>
            </Col>
        </Row>
    );
};

export default KanbanHeader;
