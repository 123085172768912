import React, {useEffect, useState} from "react";
import {useHistory, useRouteMatch} from "react-router-dom";
import {loadShipmentAsync} from "../../../../app/store/reducers/shipment/shipmentReducer";
import Shipment from "../../../../components/hippo/shipment/Shipment";
import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import EventBus from "../../../../app/eventbus/EventBus";
import {CUSTOMER_APPROVE_SHIPMENT_SUCCESS, CUSTOMER_DECLINE_SHIPMENT_SUCCESS, DELETE_SHIPMENT_SUCCESS, EXECUTOR_APPROVE_SHIPMENT_SUCCESS} from "../../../../app/eventbus/shipmentEvents";
import {setEdiBreadcrumbData} from "../../../../app/store/reducers/order/orderReducer";
import checkPermission from "../../../../enum/Permission/CheckPermission";
import {PermissionEdiShipmentView} from "../../../../enum/Permission/EdiPermission";
import {selectAccountRolePermissions} from "../../../../app/store/reducers/role/roleReducer";

const ViewShipment = () => {
    const {params: {id}} = useRouteMatch();
    const [shipment, setShipment] = useState(null);
    const activeOrganization = useSelector(selectActiveOrganization)
    const dispatch = useDispatch()
    const history = useHistory()
    const permission = useSelector(selectAccountRolePermissions)

    useEffect(() => {
        if (permission.length && !dispatch(checkPermission(PermissionEdiShipmentView))) {
            if (history.length > 1) {
                history.push('../shipments')
            } else {
                history.push('/')
            }
        }
    }, [permission])

    useEffect(() => {
        loadShipment();
    }, [id, activeOrganization]);

    useEffect(() => {
        const onExecutorApprovedShipmentHandler = EventBus.on(EXECUTOR_APPROVE_SHIPMENT_SUCCESS, () => {
            loadShipment();
        });

        const onCustomerDeclinedShipmentHandler = EventBus.on(CUSTOMER_DECLINE_SHIPMENT_SUCCESS, () => {
            loadShipment();
        });

        const onCustomerApprovedShipmentHandler = EventBus.on(CUSTOMER_APPROVE_SHIPMENT_SUCCESS, () => {
            loadShipment();
        });

        const onShipmentDeletedHandler = EventBus.on(DELETE_SHIPMENT_SUCCESS, () => {
            loadShipment()
        })

        return () => {
            EventBus.remove(EXECUTOR_APPROVE_SHIPMENT_SUCCESS, onExecutorApprovedShipmentHandler);
            EventBus.remove(CUSTOMER_DECLINE_SHIPMENT_SUCCESS, onCustomerDeclinedShipmentHandler);
            EventBus.remove(CUSTOMER_APPROVE_SHIPMENT_SUCCESS, onCustomerApprovedShipmentHandler);
            EventBus.remove(DELETE_SHIPMENT_SUCCESS, onShipmentDeletedHandler);
        }
    }, []);

    const loadShipment = () => {
        loadShipmentAsync(id)
            .then(loadedShipment => {
                dispatch(checkPermission(PermissionEdiShipmentView)) && dispatch(setEdiBreadcrumbData(loadedShipment))
                setShipment(loadedShipment)
            })
    };

    return (
        <>
            {shipment && <Shipment shipment={shipment}/>}
            {!shipment && <span>loading....</span>}
        </>
    )
};


export default ViewShipment;
