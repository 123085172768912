import React, {useEffect} from 'react';
import AddItemForm from "../../../components/hippo/item/forms/AddItemForm";
import EventBus from "../../../app/eventbus/EventBus";
import {ADD_ITEM_SUCCESS} from "../../../app/eventbus/itemEvents";
import {useHistory} from "react-router-dom";

const AddItem = () => {
    const history = useHistory();

    useEffect(() => {
        const onItemAddedHandler = EventBus.on(ADD_ITEM_SUCCESS, (id) => {
            history.replace('/warehouse/items');
        })

        return () => {
            EventBus.remove(ADD_ITEM_SUCCESS, onItemAddedHandler);
        }
    }, [])

    return <AddItemForm />
};

export default AddItem;
