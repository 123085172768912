import React from 'react';
import {Controller} from "react-hook-form";
import {BulkPrice, CommonPrice, excelImportColumnOptions, PurchasePrice, State} from "../enum/itemExcelWrapper";
import ReactSelect from "react-select";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {InputGroup} from "react-bootstrap";
import SelectCurrency from "../components/SelectCurrency";


const ImportItemsFromExcelColumnSelectors = ({isEditing, items}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const maxRowLength = Math.max(...items.map(row => row.length));
    let options = excelImportColumnOptions;

    return [...Array(maxRowLength).fill().map((column, index) => (
        <th key={index} style={{minWidth: '350px'}}>
            <Controller name={`columns.${index}`}
                        render={({field}) => {
                            switch (field.value) {
                                case CommonPrice:
                                case BulkPrice:
                                case PurchasePrice:
                                    return (
                                        <InputGroup className="flex-nowrap">
                                            <ReactSelect options={options}
                                                         getOptionLabel={option => t(lang, option.label)}
                                                         getOptionValue={option => option.key}
                                                         isClearable
                                                         hideSelectedOptions
                                                         placeholder={t(lang, 'items.common.select')}
                                                         onChange={(option) => field.onChange(option ? option.key : null)}
                                                         value={options.find(o => o.key === field.value) || null}
                                                         menuPortalTarget={document.querySelector(".modal-content")}
                                                         styles={{
                                                             container: (provided) => ({
                                                                 ...provided,
                                                                 width: '70%',
                                                             })
                                                         }}
                                            />
                                            <Controller name={`${field.value}.currencyId`}
                                                        render={({field}) => (
                                                            <SelectCurrency
                                                                onChange={(oldCurrency, newCurrency) => field.onChange(newCurrency ? newCurrency.id : null)}
                                                                defaultCurrencyId={field.value}
                                                                selectProps={{
                                                                    menuPortalTarget: document.querySelector(".modal-content")
                                                                }}
                                                            />
                                                        )}
                                            />
                                        </InputGroup>

                                    )
                                default:
                                    return (
                                        <ReactSelect options={options}
                                                     getOptionLabel={option => t(lang, option.label)}
                                                     getOptionValue={option => option.key}
                                                     isClearable
                                                     hideSelectedOptions
                                                     placeholder={t(lang, 'items.common.select')}
                                                     onChange={(option) => field.onChange(option ? option.key : null)}
                                                     value={options.find(o => o.key === field.value) || null}
                                                     menuPortalTarget={document.body}
                                                     styles={{
                                                         container: (provided) => ({
                                                             ...provided,
                                                             width: '100%',
                                                         })
                                                     }}
                                        />
                                    )
                            }
                        }}
            />
        </th>
    ))]
};

export default ImportItemsFromExcelColumnSelectors;