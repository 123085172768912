import React, {Fragment, useEffect, useState} from 'react';
import {getItemMemberChange, getItemStatusChange} from "../../app/store/reducers/kanban/kanbanReducer";
import {selectLang, Translate} from "../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {memberChangeType} from "../../enum/KanbanWrapper";
import dayjs from "dayjs";

const KanbanItem = ({id}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const [data, setData] = useState([])

    useEffect( async () => {
        const status = await getItemStatusChange(id)
        const member = await  getItemMemberChange(id)

        setData([...status, ...member])

    }, [])

    return (
        <Fragment>
            {
                data.map((item, index) => <p key={index} className={'pb-1 fs--1 border-1 border-bottom'}>
                    <strong className={'text-primary'}>{item.changer?.name}: </strong>
                    {item.from_status && <span>{t(lang, 'task.board.history.datatable.status', {
                        from_status: item.from_status.name,
                        to_status: item.to_status.name
                    })}</span>}
                    {item.assigner && <span>
                        {t(lang, memberChangeType(item.change_type), {
                            assigner:  item.assigner.name
                        })}
                    </span>}
                    <span className={' fs--2 d-flex justify-content-end gap-1'}>
                        <span className={'text-primary fw-bold'}>{dayjs(item.created_at).format('HH:mm')}</span>
                        <span>{dayjs(item.created_at).format('DD-MM-YYYY')}</span>
                    </span>
                </p>)
            }
        </Fragment>
    );
};

export default KanbanItem;
