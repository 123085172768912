import React from 'react';
import {Switch, useRouteMatch} from "react-router-dom";
import {Route} from "react-router";
import OrganizationBindings from "./OrganizationBindings";
import ViewSupervisor from "./ViewSupervisor";
import ViewOwner from "./ViewOwner";

const OrganizationBindingsLayout = () => {
    const {url} = useRouteMatch();
    return (
        <Switch>
            <Route path={`${url}`} exact component={OrganizationBindings} />
            <Route path={`${url}/supervisor/:id`} component={ViewSupervisor} />
            <Route path={`${url}/owner/:id`} component={ViewOwner} />
        </Switch>
    );
};

export default OrganizationBindingsLayout;