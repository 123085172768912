import {useEffect, useMemo, useState} from "react";
import {loadActAsync, loadActDraftAsync} from "../../../../../app/store/reducers/roaming/roamingActReducer";
import {useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../../../app/store/reducers/auth/authReducer";
import {useParams} from "react-router";
import {useHistory} from "react-router-dom";
import FillActForm from "./FillActForm";
import {toast} from "react-toastify";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";


const FillAct = () => {
    const activeOrganization = useSelector(selectActiveOrganization)
    const [draftAct, setDraftAct] = useState(null);
    const [act, setAct] = useState(null);
    const history = useHistory()
    const lang = useSelector(selectLang)
    const t = Translate
    const {id} = useParams()




    const query = useMemo(() => {
        return new URLSearchParams(history.location.search)
    },[history.location.search])


    useEffect(() => {
        loadAct()
    }, [id, activeOrganization]);

    const loadAct = async () => {
        const status = JSON.parse(query.get("status"));
        if (status) {
            loadActAsync(id)
                .then(response => {
                    setAct(response?.data)
                })
                .catch(error => {
                    setAct(null)
                    console.log(error)
                    toast.error(t(lang, "roaming.common.error_not_found"))
                })

        } else {
            loadActDraftAsync(id)
                .then(response => {
                    const parsedContent = JSON.parse(response?.data?.content)

                    const actFormat = {
                        contract: {
                            number: parsedContent.ContractDoc.ContractNo,
                            date: parsedContent.ContractDoc.ContractDate,
                        },
                        customer: {
                            inn: parsedContent.BuyerTin,
                            name: parsedContent.BuyerName,
                            branch: {
                                code: parsedContent.BuyerBranchCode,
                                name: parsedContent.BuyerBranchName
                            }
                        },
                        executor: {
                            inn: parsedContent.SellerTin,
                            name: parsedContent.SellerName,
                            branch: {
                                code: parsedContent.SellerBranchCode,
                                name: parsedContent.SellerBranchName
                            }
                        },
                        info: {
                            number: parsedContent.ActDoc.ActNo,
                            date: parsedContent.ActDoc.ActDate,
                            text: parsedContent.ActDoc.ActText
                        },
                        items: parsedContent.ProductList.Products.map(item => {
                            return {
                                catalog: {
                                    code: item.CatalogCode,
                                    name: item.CatalogName
                                },
                                measure_id: item.MeasureId,
                                packageCode: item.PackageCode,
                                packageName: item.PackageName,
                                name: item.Name,
                                price: item.Summa,
                                quantity: item.Count,
                                total: item.TotalSum
                            }
                        })
                    }
                    setDraftAct(actFormat)
                })
                .catch(error => {
                    setDraftAct(null)
                    console.log(error)
                    toast.error(t(lang,"roaming.common.error_not_found"))
                })
        }
    };



    if (!act && !draftAct) {
        return(
            <>
                loading...
            </>
        )
    } else {
        return(
            <>
                <FillActForm act={act || draftAct} />
            </>
        )
    }
}


export default FillAct;