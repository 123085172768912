import {useSelector} from "react-redux";
import {selectMembers} from "../../../../app/store/reducers/kanban/kanbanReducer";
import {Dropdown, Nav} from "react-bootstrap";
import Avatar from "../../../common/Avatar";
import classNames from "classnames";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import PropTypes from "prop-types";

const MemberDropdownSelect = ({value, onChange}) => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const members = useSelector(selectMembers)
    const onChangeMember = (e) => {
        if (value) {
            if (value === e?.id) onChange(null)
            else onChange(e.id)
        } else {
            onChange(e.id)
        }
    }

    return (
        <>
            <Dropdown.Menu align={'start'} className="dropdown-md">
                <>
                    <Nav className="list-unstyled px-2 py-0 flex-lg-column fs--1">
                        {
                            !!members.length ?
                                members.map(item =>
                                    <Nav.Item key={item.id} onClick={() => onChangeMember(item)}
                                              className={classNames(`me-lg-0 mb-1 d-flex justify-content-between align-items-center cursor-pointer`, {
                                                  'bg-light': item.id === value
                                              })}>
                                        <div className={'d-flex align-items-center'}>
                                            <Avatar className={'me-2'} size="l" name={item.name}/>
                                            <span className={'text-dark'}>{item.name}</span>
                                        </div>
                                    </Nav.Item>) :
                                <span className={'d-block text-center text-dark'}>{t(lang, 'task.board.common.no.data')}</span>
                        }
                    </Nav>
                </>
            </Dropdown.Menu>
        </>
    );
};

export default MemberDropdownSelect;
