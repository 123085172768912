import {Draggable} from "react-beautiful-dnd";
import {useDispatch, useSelector} from "react-redux";
import {getItem, itemUpdate, updateModalContent} from "../../../app/store/reducers/kanban/kanbanReducer";
import TaskCardItem from "./TaskCardItem";
import {useEffect, useState} from "react";
import EventBus from "../../../app/eventbus/EventBus";
import {ITEM_DELETE_ASSIGNER_FAIL, ITEM_DELETE_ASSIGNER_SUCCESS} from "../../../app/eventbus/kanban/KanbanEvents";
import {toast} from "react-toastify";
import PropTypes from "prop-types";

const KanbanCard = ({task, index, showModal, setShowModal}) => {

    const dispatch = useDispatch()

    const handleModalOpen = () => {
        dispatch(getItem(task.id))
        setShowModal(true)
    };
    const getItemStyle = isDragging => ({
        cursor: isDragging ? 'grabbing' : 'pointer',
        transform: isDragging ? 'rotate(-2deg)' : ''
    });

    return (
        <Draggable draggableId={`${task.id}`} index={index}>
            {
                (provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={provided.draggableProps.style}
                        className="kanban-item"
                    >
                        <TaskCardItem  snapshot={snapshot} handleModalOpen={handleModalOpen} show={showModal} task={task} getItemStyle={getItemStyle} />
                    </div>
                )
            }
        </Draggable>
    );
};

export default KanbanCard;
