import React from 'react';
import {Breadcrumb, Card} from "react-bootstrap";
import {useLocation, useParams} from "react-router";
import {useSelector} from "react-redux";
import {selectEdiBreadcrumbData} from "../../../app/store/reducers/order/orderReducer";
import {Link} from "react-router-dom";
import classNames from "classnames";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";
import {useViewer} from "../../../pages/main/edi/ViewerProvider";

const EdiBreadcrumb = () => {

    const lang = useSelector(selectLang);
    const t = Translate;
    const selectEdiBreadcrumb = useSelector(selectEdiBreadcrumbData)
    const {pathname} = useLocation()
    const order = pathname.includes('order') && !pathname.includes('return-order')
    const shipment = pathname.includes('shipment') && !pathname.includes('return-shipment')
    const invoice = pathname.includes('invoice') && !pathname.includes('return-invoice')
    const { viewer } = useViewer();

    return (
        <div className={'position-sticky'} style={{zIndex: 999, top: '4.3125rem'}}>

            {
                order && <Card className={'mb-3'}>
                    <Card.Header>
                        <Breadcrumb>
                            <li className={'breadcrumb-item'}>
                                <Link
                                    to={`/edi/orders/inbox`}
                                    className={classNames('text-decoration-none', {
                                        'text-secondary': pathname === '/edi/orders' && true
                                    })}>
                                    {t(lang, 'edi.dashboard.orders.title')}
                                </Link>
                            </li>
                            {
                                selectEdiBreadcrumb?.info?.number &&
                                <li className={'breadcrumb-item'}>
                                    <span> {t(lang, 'edi.common.order')} №: {selectEdiBreadcrumb?.info?.number}</span>
                                </li>
                            }
                            <li className={'breadcrumb-item'}>
                                <Link
                                    className={'text-decoration-none'}
                                    to={`/edi/shipments/outbox`}>
                                    {t(lang, 'main.templates.type.shipments')}
                                </Link></li>
                            <li className={'breadcrumb-item'}>
                                <Link
                                    className={'text-decoration-none'}
                                    to={`/edi/invoices`}>
                                    {t(lang, 'main.templates.type.invoices')}
                                </Link>
                            </li>
                        </Breadcrumb>
                    </Card.Header>
                </Card>
            }

            {
                shipment && <Card className={'mb-3'}>
                    <Card.Header>
                        <Breadcrumb className={'d-flex align-items-center'}>
                            <li className={'breadcrumb-item'}>
                                <Link
                                    className={'text-decoration-none'}
                                    to={`/edi/orders/inbox`}>
                                    {t(lang, 'edi.dashboard.orders.title')}
                                </Link>
                            </li>
                            {
                                selectEdiBreadcrumb?.order?.info?.number &&
                                <li className={'breadcrumb-item'}>
                                    <Link
                                        className={'text-decoration-none'}
                                        to={'/edi/order/' + selectEdiBreadcrumb?.order?.id}>
                                        {t(lang, 'edi.common.order')} №: {selectEdiBreadcrumb?.order?.info?.number}
                                    </Link>
                                </li>
                            }
                            <li className={'breadcrumb-item'}>
                                <Link
                                    to={`/edi/shipments/outbox`}
                                    className={classNames('text-decoration-none', {
                                        'text-secondary': pathname === '/edi/shipments' && true
                                    })}>
                                    {t(lang, 'main.templates.type.shipments')}
                                </Link>
                            </li>
                            {
                                selectEdiBreadcrumb?.shipment_info?.number &&
                                <li className={'breadcrumb-item'}>
                                    <span>{t(lang, 'main.templates.type.shipment')} №: {selectEdiBreadcrumb?.shipment_info?.number}
                                    </span>
                                </li>
                            }
                            <li className={'breadcrumb-item'}>
                                <Link to={`/edi/invoices`} className={'text-decoration-none'}>
                                    {t(lang, 'main.templates.type.invoices')}
                                </Link>
                            </li>
                            {
                                selectEdiBreadcrumb?.invoice?.id &&
                                <li className={'breadcrumb-item'}>
                                    <Link
                                        className={'text-decoration-none'}
                                        to={`/edi/invoice/${viewer}` + selectEdiBreadcrumb?.invoice?.id}>
                                        {t(lang, 'edi.common.invoice')} №: {selectEdiBreadcrumb?.invoice?.invoice_info?.number}
                                    </Link>
                                </li>
                            }
                        </Breadcrumb>
                    </Card.Header>
                </Card>
            }

            {
                invoice && <Card className={'mb-3'}>
                    <Card.Header>
                        <Breadcrumb>
                            <li className={'breadcrumb-item'}><Link to={'/edi/orders/inbox'}>
                                {t(lang, 'edi.dashboard.orders.title')}
                            </Link></li>
                            {
                                selectEdiBreadcrumb?.shipment?.order?.info?.number &&
                                <li className={'breadcrumb-item'}>
                                    <Link
                                        to={'/edi/order/' + selectEdiBreadcrumb?.shipment?.order?.id}
                                        className={'text-decoration-none'}>
                                        {t(lang, 'edi.common.order')} №: {selectEdiBreadcrumb?.shipment?.order?.info?.number}
                                    </Link>
                                </li>
                            }
                            <li className={'breadcrumb-item'}>
                                <Link
                                    className={'text-decoration-none'}
                                    to={`/edi/shipments/outbox`}>
                                    {t(lang, 'main.templates.type.shipments')}
                                </Link>
                            </li>
                            {
                                selectEdiBreadcrumb?.shipment?.shipment_info?.number &&
                                <li className={'breadcrumb-item'}>
                                    <Link
                                        className={'text-decoration-none'}
                                        to={'/edi/shipment/' + selectEdiBreadcrumb?.shipment?.id}>
                                        {t(lang, 'main.templates.type.shipment')} №: {selectEdiBreadcrumb?.shipment?.shipment_info?.number}
                                    </Link>
                                </li>
                            }
                            <li className={'breadcrumb-item'}>
                                <Link
                                    to={'/edi/invoices'}
                                    className={classNames('p-0 text-decoration-none', {
                                        'text-secondary': pathname === '/edi/invoices' && true
                                    })}>
                                    {t(lang, 'main.templates.type.invoices')}
                                </Link>
                            </li>
                            {
                                selectEdiBreadcrumb?.invoice_info?.number &&
                                <li className={'breadcrumb-item'}>
                                    <span>{t(lang, 'edi.common.invoice')} №: {selectEdiBreadcrumb?.invoice_info?.number}</span>
                                </li>
                            }
                        </Breadcrumb>
                    </Card.Header>
                </Card>
            }

        </div>
    );
};

export default EdiBreadcrumb;
