import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import {Link} from 'react-router-dom';
import {Card, Dropdown, ListGroup} from 'react-bootstrap';
import {isIterableArray} from "../../../../helpers/utils";
import FalconCardHeader from "../../../common/FalconCardHeader";
import Notification from "../../../notification/Notification";
import {
    clearAllPushUpdateNotifications,
    selectPushUpdateNotification
} from "../../../../app/store/reducers/push-update-notification/pushUpdateNotificationReducer";
import {useDispatch, useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import SoftBadge from "../../../common/SoftBadge";
import {getOrganizationUsersAsync, selectUsers} from "../../../../app/store/reducers/auth/authReducer";

const NotificationDropdown = () => {
    const dispatch = useDispatch()
    const [isOpen, setIsOpen] = useState(false);
    const notifications = useSelector(selectPushUpdateNotification)
    const users = useSelector(selectUsers)
    const [allRead, setAllRead] = useState(false)
    const lang = useSelector(selectLang);
    const t = Translate;
    const handleToggle = () => {
        setIsOpen(!isOpen);
    };
    const clearAllNotification = () => {
        dispatch(clearAllPushUpdateNotifications())
        setIsOpen(false)
        setAllRead(false)
    }
    useEffect(() => {
        if (notifications.length) {
            setAllRead(true)
        } else {
            setAllRead(false)
        }
    }, [notifications]);

    useEffect(() => {
        if (!users.length) {
            dispatch(getOrganizationUsersAsync())
        }
    }, [])

    return (
        <Dropdown navbar={true} as="li" onToggle={handleToggle} show={isOpen}>
            <Dropdown.Toggle
                bsPrefix="toggle"
                as={Link}
                to="#!"
                className={classNames('px-0 nav-link', {
                    'notification-indicator notification-indicator-primary': allRead
                })}
            >
                <FontAwesomeIcon icon="bell" transform="shrink-6" className="fs-5 text-facebook"/>
            </Dropdown.Toggle>

            <Dropdown.Menu className="dropdown-menu-card dropdown-menu-end dropdown-caret dropdown-caret-bg">
                <Card
                    className="dropdown-menu-notification dropdown-menu-end shadow-none"
                    style={{maxWidth: '20rem'}}
                >
                    <FalconCardHeader
                        className="card-header border-bottom"
                        title={t(lang, "notification.name")}
                        titleTag="h6"
                        light={false}
                        endEl={<SoftBadge bg={"info"}>{notifications.length}</SoftBadge>}
                    />
                    <ListGroup
                        className="fw-normal fs--1 scrollbar"
                        style={{maxHeight: '19rem'}}
                    >
                        {isIterableArray(notifications) ?
                            notifications.map((notification, index) => (
                                <ListGroup.Item key={index} className={"hover-bg-200"}>
                                    <Notification index={index} notification={notification}/>
                                </ListGroup.Item>
                            )) : <h6 className={"text-center m-0 py-2 text-dark fw-normal"}>
                                {t(lang, "notification.no_data")}</h6>}
                    </ListGroup>

                    <div className="card-footer text-center border-top">
                        <Link className="card-link text-danger fw-semi-bold" onClick={clearAllNotification} to="#!">
                            {t(lang, "notification.clear_all")}
                        </Link>
                    </div>
                </Card>
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default NotificationDropdown;
