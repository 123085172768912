import React from 'react';
import {Card, Dropdown} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useDispatch, useSelector} from "react-redux";
import {removeTaskCard, selectCurrentUser} from "../../../app/store/reducers/kanban/kanbanReducer";


const TaskDropMenu = ({id}) => {

    const dispatch = useDispatch()

    const handleRemoveTaskCard = () => {
        dispatch(removeTaskCard({id}))
    };

    return (
        <Dropdown
            onClick={e => e.stopPropagation()}
            align="end"
            className="font-sans-serif"
        >
            <Dropdown.Toggle
                variant="falcon-default"
                size="sm"
                className="kanban-item-dropdown-btn hover-actions dropdown-caret-none"
            >
                <FontAwesomeIcon icon="ellipsis-h" transform="shrink-2"/>
            </Dropdown.Toggle>

            <Dropdown.Menu className="py-0" align={'start'}>
                <Dropdown.Item as={'li'} onClick={handleRemoveTaskCard} className="text-danger">
                    Удалить
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
};

const TaskCardItem = ({
                          snapshot,
                          getItemStyle,
                          handleModalOpen,
                          task: {id, title, assigner, attachments}
                      }) => {

    const image = attachments?.length ? attachments[0] : null;
    return (
        <Card
            style={getItemStyle(snapshot.isDragging)}
            className="kanban-item-card hover-actions-trigger"
            onClick={handleModalOpen}
        >
            {image && (
                <div className={`position-relative rounded-top-lg overflow-hidden ${image.className}`}>
                    <img className={'w-100'}
                         src={image?.image.includes('/static') ? image?.image : `data:image/jpeg;base64,${image?.image}`}
                         alt=""/>
                </div>
            )}
            <Card.Body>
                {/*<div className="position-relative">
                    <TaskDropMenu id={id}/>
                </div>*/}
                <p
                    className="mb-0 fw-medium font-sans-serif stretched-link"
                    dangerouslySetInnerHTML={{__html: title}}
                />
                <div className="kanban-item-footer cursor-default d-flex justify-content-end">
                    <span className={'text-primary fw-bold'}>{assigner?.assigner?.name}</span>
                </div>
            </Card.Body>
        </Card>
    );
};

export default TaskCardItem;
