import React, {Fragment} from 'react';
import AdvanceTableWrapper from "../../common/advance-table/AdvanceTableWrapper";
import {Card} from "react-bootstrap";
import AdvanceTable from "../../common/advance-table/AdvanceTable";
import AdvanceTableFooter from "../../common/advance-table/AdvanceTableFooter";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {selectDateDayJSFormat, selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";
import dayjs from "dayjs";
import {selectBoards} from "../../../app/store/reducers/kanban/kanbanReducer";
import AdvanceTablePagination from "../../common/advance-table/AdvanceTablePagination";
import KanbanDatatableHeader from "./KanbanDatatableHeader";
import BoardModal from "../modal/board/BoardModal";

const KanbanDatatable = () => {
    const currentDateDayJSFormat = useSelector(selectDateDayJSFormat)

    const boards = useSelector(selectBoards)
    const lang = useSelector(selectLang);
    const t = Translate;


    const columns = [
        {
            Header: "#",
            id: "row",
            maxWidth: 50,
            filterable: false,
            Cell: ({row}) => {
                return row.index + 1;
            }
        },
        {
            accessor: 'name',
            Header: t(lang, "task.board.datatable.title"),
            headerProps: {className: 'pe-7'},
            cellProps: {
                className: 'fw-bold fs-0 text-uppercase',
            },
            Cell: ({row: {original}}) => {
                return (
                    <Link className={'text-decoration-none'} to={`/kanban/board/${original?.id}`}>
                        <strong>{original?.name}</strong>
                    </Link>
                );
            }
        },
        {
            accessor: 'created_at',
            id: "created_at",
            Header: t(lang, "admin.organization.view.active_sessions.session.created_at"),
            cellProps: {
                minwidth: '100px',
                className: 'fw-bold fs-0',
            },
            Cell: rowData => {
                const {created_at} = rowData?.row?.original
                return created_at && dayjs(created_at, "YYYY-MM-DD").format(currentDateDayJSFormat)
            }
        },
        {
            accessor: 'none',
            cellProps: {
                className: 'text-end',
            },
            Cell: ({row: {original}})=> (
                <BoardModal
                    variant={'falcon-warning'}
                    icon={'edit'}
                    title={'task.board.common.edit'}
                    name={original?.name}
                    id={original?.id}
                />
            )
        }
    ]


    return (
        <Fragment>
            <AdvanceTableWrapper
                columns={columns}
                data={boards}
                pagination
                perPage={100}
            >
                <Card>
                    <Card.Header className={'pb-0'}>
                        <KanbanDatatableHeader />
                    </Card.Header>

                    <Card.Body>
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden',
                            }}
                            isLoading={!boards.length}
                        >
                        </AdvanceTable>
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTableFooter
                            table
                            rowsPerPageSelection
                            rowsPerPageOptions={[10, 25, 50]}
                        />
                        <AdvanceTablePagination table/>
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>
        </Fragment>
    );
};

export default KanbanDatatable;
