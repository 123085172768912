import React, {useState} from 'react';
import {Dropdown} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useDispatch, useSelector} from "react-redux";
import {removeKanbanColumn} from "../../../app/store/reducers/kanban/kanbanReducer";
import KanbanModal from "../modal/kanban/KanbanModal";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";
import PropTypes from "prop-types";

const KanbanColumnHeader = ({id, title, itemCount, setShowForm}) => {

    const lang = useSelector(selectLang);
    const t = Translate;

    const dispatch = useDispatch()
    const [show, setShow] = useState(false);

    const handleRemoveColumn = () => {
        dispatch(removeKanbanColumn({id}))
    };

    return (
        <>
            <div className="kanban-column-header">
                <h5 className="fs-0 mb-0">
                    {title} <span className="text-500">({itemCount})</span>
                </h5>
                <Dropdown align="end" className="font-sans-serif btn-reveal-trigger">
                    <Dropdown.Toggle variant="reveal" size="sm" className="py-0 px-2">
                        <FontAwesomeIcon icon="ellipsis-h" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="py-0">
                        <Dropdown.Item onClick={()=>setShow(true)}>{t(lang, 'task.board.common.add')}</Dropdown.Item>
                        <Dropdown.Item onClick={()=>setShowForm(true)}>{t(lang, 'task.board.common.edit')}</Dropdown.Item>
                        {/*<Dropdown.Item onClick={handleRemoveColumn} className="text-danger">{t(lang, 'task.board.common.delete')}</Dropdown.Item>*/}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            <KanbanModal show={show} setShow={setShow} id={id} />
        </>

    );
};



export default KanbanColumnHeader;
