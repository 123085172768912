import React, {Fragment, useState} from 'react';
import { Button,  Dropdown, Form, }  from "react-bootstrap";
import {statusLabels} from "../../../../enum/KanbanWrapper";
import SoftBadge from "../../../common/SoftBadge";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import CreatLabel from "./CreatLabel";
import {selectLabels} from "../../../../app/store/reducers/kanban/kanbanReducer";
import PropTypes from "prop-types";

const SelectDropdownLabels = ({addLabels, itemId, fields}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const [createLabel, setCreateLabel] = useState(false)
    const labelsList = useSelector(selectLabels)
    return (
        <>
            <Dropdown.Menu style={{minWidth: 200}} tabIndex="0">
                <h6 className="dropdown-header py-0 px-3 mb-0">{t(lang, 'task.board.modal.label')}</h6>
                <Dropdown.Divider/>
                {
                    !createLabel ?
                        <Fragment>
                            {labelsList.map(label => (
                                <div key={label.color_type}
                                     className={'d-flex gap-1 align-items-between align-items-center px-1 mb-1'}>
                                    <Form.Check onChange={() => addLabels(label)}
                                                checked={fields.some(i => i.name === label.name)} type='checkbox'/>
                                    <SoftBadge bg={statusLabels(label.color_type)} className="py-2 d-block w-100"
                                               key={label.color_type}>
                                        <span className={'text-uppercase fw-bold'}>{t(lang, label.name)}</span>
                                    </SoftBadge>
                                </div>
                            ))}
                            <Button onClick={() => setCreateLabel(true)} size={'sm'} variant={'falcon-primary'} className={'d-block m-auto fs--1'}>
                                {t(lang, 'task.board.modal.add-new.label')}
                            </Button>
                        </Fragment> :
                       <CreatLabel setCreateLabel={setCreateLabel} itemId={itemId} />
                }

            </Dropdown.Menu>
        </>
    );
};


export default SelectDropdownLabels;
