import {createSlice} from "@reduxjs/toolkit";
import {
    WAREHOUSE_OPERATION_PURCHASE_ADD_CLIENT_FAILED,
    WAREHOUSE_OPERATION_PURCHASE_ADD_CLIENT_REQUESTED,
    WAREHOUSE_OPERATION_PURCHASE_ADD_CLIENT_SUCCESS,
    WAREHOUSE_OPERATION_PURCHASE_ADD_ITEM_FAILED,
    WAREHOUSE_OPERATION_PURCHASE_ADD_ITEM_REQUESTED,
    WAREHOUSE_OPERATION_PURCHASE_ADD_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_PURCHASE_APPROVED_ITEM_FAILED,
    WAREHOUSE_OPERATION_PURCHASE_APPROVED_ITEM_REQUESTED,
    WAREHOUSE_OPERATION_PURCHASE_APPROVED_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_PURCHASE_CHANGE_CONTRACTOR_FAILED,
    WAREHOUSE_OPERATION_PURCHASE_CHANGE_CONTRACTOR_REQUESTED,
    WAREHOUSE_OPERATION_PURCHASE_CHANGE_CONTRACTOR_SUCCESS,
    WAREHOUSE_OPERATION_PURCHASE_DATE_FAILED,
    WAREHOUSE_OPERATION_PURCHASE_DATE_REQUESTED,
    WAREHOUSE_OPERATION_PURCHASE_DATE_SUCCESS,
    WAREHOUSE_OPERATION_PURCHASE_DELETE_FAILED,
    WAREHOUSE_OPERATION_PURCHASE_DELETE_ITEM_FAILED,
    WAREHOUSE_OPERATION_PURCHASE_DELETE_ITEM_REQUESTED,
    WAREHOUSE_OPERATION_PURCHASE_DELETE_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_PURCHASE_DELETE_PAYOUT_FAILED,
    WAREHOUSE_OPERATION_PURCHASE_DELETE_PAYOUT_REQUESTED,
    WAREHOUSE_OPERATION_PURCHASE_DELETE_PAYOUT_SUCCESS,
    WAREHOUSE_OPERATION_PURCHASE_DELETE_REQUESTED,
    WAREHOUSE_OPERATION_PURCHASE_DELETE_SUCCESS,
    WAREHOUSE_OPERATION_PURCHASE_EMPLOYEE_FAILED,
    WAREHOUSE_OPERATION_PURCHASE_EMPLOYEE_REQUESTED,
    WAREHOUSE_OPERATION_PURCHASE_EMPLOYEE_SUCCESS,
    WAREHOUSE_OPERATION_PURCHASE_NOTE_FAILED,
    WAREHOUSE_OPERATION_PURCHASE_NOTE_REQUESTED,
    WAREHOUSE_OPERATION_PURCHASE_NOTE_SUCCESS,
    WAREHOUSE_OPERATION_PURCHASE_NUMBER_FAILED,
    WAREHOUSE_OPERATION_PURCHASE_NUMBER_REQUESTED,
    WAREHOUSE_OPERATION_PURCHASE_NUMBER_SUCCESS,
    WAREHOUSE_OPERATION_PURCHASE_REGISTER_PAYOUT_FAILED,
    WAREHOUSE_OPERATION_PURCHASE_REGISTER_PAYOUT_REQUESTED,
    WAREHOUSE_OPERATION_PURCHASE_REGISTER_PAYOUT_SUCCESS,
    WAREHOUSE_OPERATION_PURCHASE_UPDATE_ITEM_FAILED,
    WAREHOUSE_OPERATION_PURCHASE_UPDATE_ITEM_REQUESTED,
    WAREHOUSE_OPERATION_PURCHASE_UPDATE_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_PURCHASE_UPDATE_PAYOUT_FAILED,
    WAREHOUSE_OPERATION_PURCHASE_UPDATE_PAYOUT_REQUESTED,
    WAREHOUSE_OPERATION_PURCHASE_UPDATE_PAYOUT_SUCCESS
} from "../../../eventbus/warehouse-operation/purchaseEvents";
import EventBus from "../../../eventbus/EventBus";
import warehouseOperationService from "../../../../services/warehouse-operation/warehouseOperationService";
import dayjs from "dayjs";

const currentDate = new Date()
const dateTimeStartFormat = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
const dateTimeEndFormat = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 23, 59, 59);


const purchaseReducer = createSlice({
    name: "purchase",
    initialState: {
        purchases: [],
        purchaseCount: 0,
        filterOptions: {
            page: 1,
            limit: 10,
            date_time_start: dayjs(dateTimeStartFormat)?.format("YYYY-MM-DD HH:mm:ss"),
            date_time_end: dayjs(dateTimeEndFormat)?.format("YYYY-MM-DD HH:mm:ss"),
        },
        purchaseDefaultsSettings: null,
    },
    reducers: {
        updateFilterOptions: (state, action) => {
            state.filterOptions = action.payload
        },
        updatePurchases: (state, action) => {
            state.purchases = action.payload
        },
        updatePurchaseCount: (state, action) => {
            state.purchaseCount = action.payload
        },
        setPurchaseDefaultsSettings: (state, action) => {
            state.purchaseDefaultsSettings = JSON.parse(action.payload)
            localStorage.setItem("PurchaseSettings", action.payload)
        },
        loadPurchaseDefaultsSettings: (state, action) => {
            const purchaseDefaults = JSON.parse(localStorage.getItem("PurchaseSettings"))
            state.purchaseDefaultsSettings = purchaseDefaults
        }
    },
})


export const changeFilterOptionsAsync = ({...params}) => dispatch => {
    dispatch(purchaseReducer.actions.updateFilterOptions({...params}))
}

export const getPurchasesAsync = ({page, limit, ...filters}) => dispatch => {
    const skip = (page - 1) * limit
    const params = {skip, limit, ...filters}

    return new Promise((resolve, reject) => {
        warehouseOperationService.getPurchases(params)
            .then((response) => {
                dispatch(purchaseReducer.actions.updatePurchases(response.data))
                resolve(response.data)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const getPurchasesCountAsync = (params) => dispatch => {
    let { limit, page, ...filters} = params

    return new Promise((resolve, reject) =>  {
        warehouseOperationService.getPurchasesCount(filters)
            .then((response) => {
                dispatch(purchaseReducer.actions.updatePurchaseCount(response.data))
                resolve(response.data)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const purchaseRegisterAsync = (payload) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_PURCHASE_ADD_CLIENT_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.purchaseRegister(payload)
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_PURCHASE_ADD_CLIENT_SUCCESS, response.data?.id)
                resolve(response)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_PURCHASE_ADD_CLIENT_FAILED)
                reject(error)
            })
    })
}

export const purchaseAddItemAsync = ({purchaseId, operation}) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_PURCHASE_ADD_ITEM_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.addPurchaseItem({purchaseId, operation})
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_PURCHASE_ADD_ITEM_SUCCESS, purchaseId)
                resolve(response)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_PURCHASE_ADD_ITEM_FAILED)
                reject(error)
            })
    })
}

export const getPurchaseItemAsync = ({purchaseId}) => {
    return new Promise((resolve, reject) => {
        warehouseOperationService.getPurchase({purchaseId})
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const purchaseDeleteItemAsync = ({purchaseId, operationId}) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_PURCHASE_DELETE_ITEM_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.deletePurchaseItem({purchaseId, operationId})
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_PURCHASE_DELETE_ITEM_SUCCESS, purchaseId)
                resolve(response)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_PURCHASE_DELETE_ITEM_FAILED)
                reject(error)
            })
    })
}

export const purchaseUpdateItemAsync = ({purchaseId, operation}) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_PURCHASE_UPDATE_ITEM_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.updatePurchaseItem({purchaseId, operation})
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_PURCHASE_UPDATE_ITEM_SUCCESS, purchaseId)
                resolve(response)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_PURCHASE_UPDATE_ITEM_FAILED)
                reject(error)
            })
    })
}

export const purchaseApproveAsync = ({purchaseId, payout}) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_PURCHASE_APPROVED_ITEM_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.approvePurchase({purchaseId, payout})
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_PURCHASE_APPROVED_ITEM_SUCCESS, purchaseId)
                resolve(response)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_PURCHASE_APPROVED_ITEM_FAILED)
                reject(error)
            })
    })
}

export const purchaseDeleteAsync = ({purchaseId}) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_PURCHASE_DELETE_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.purchaseDelete({purchaseId})
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_PURCHASE_DELETE_SUCCESS)
                resolve(response)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_PURCHASE_DELETE_FAILED)
                reject(error)
            })
    })
}

export const purchaseUpdateNoteAsync = ({purchaseId, note}) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_PURCHASE_NOTE_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.purchaseUpdateNote({purchaseId, note})
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_PURCHASE_NOTE_SUCCESS, purchaseId)
                resolve(response)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_PURCHASE_NOTE_FAILED)
                reject(error)
            })
    })
}

export const purchaseUpdateDateAsync = ({purchaseId, date}) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_PURCHASE_DATE_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.purchaseUpdateDate({purchaseId, date})
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_PURCHASE_DATE_SUCCESS, purchaseId)
                resolve(response)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_PURCHASE_DATE_FAILED)
                reject(error)
            })
    })
}

export const purchaseUpdateNumberAsync = ({purchaseId, number}) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_PURCHASE_NUMBER_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.purchaseUpdateNumber({purchaseId, number})
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_PURCHASE_NUMBER_SUCCESS, purchaseId)
                resolve(response)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_PURCHASE_NUMBER_FAILED)
                reject(error)
            })
    })
}

export const purchaseChangeContractorAsync = ({purchaseId, contractorId}) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_PURCHASE_CHANGE_CONTRACTOR_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.purchaseChangeContractor({purchaseId, contractorId})
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_PURCHASE_CHANGE_CONTRACTOR_SUCCESS, purchaseId)
                resolve(response)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_PURCHASE_CHANGE_CONTRACTOR_FAILED)
                reject(error)
            })
    })
}

export const purchaseChangeEmployeeAsync = ({purchaseId, employeeId}) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_PURCHASE_EMPLOYEE_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.purchaseChangeEmployee({purchaseId, employeeId})
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_PURCHASE_EMPLOYEE_SUCCESS, response)
                resolve(response)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_PURCHASE_EMPLOYEE_FAILED)
                reject(error)
            })
    })
}

export const purchasePayoutRegisterAsync = ({purchaseId, payout}) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_PURCHASE_REGISTER_PAYOUT_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.purchasePayoutRegister({purchaseId, payout})
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_PURCHASE_REGISTER_PAYOUT_SUCCESS, purchaseId)
                resolve(response.data)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_PURCHASE_REGISTER_PAYOUT_FAILED)
                reject(error)
            })
    })
}

export const purchasePayoutUpdateAsync = ({purchaseId, payout}) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_PURCHASE_UPDATE_PAYOUT_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.purchasePayoutUpdate({purchaseId, payout})
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_PURCHASE_UPDATE_PAYOUT_SUCCESS, purchaseId)
                resolve(response.data)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_PURCHASE_UPDATE_PAYOUT_FAILED)
                reject(error)
            })
    })
}

export const deletePurchasePayoutAsync = (purchaseId) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_PURCHASE_DELETE_PAYOUT_REQUESTED);

    return new Promise((resolve, reject) => {
        warehouseOperationService.deletePurchasePayout(purchaseId)
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_PURCHASE_DELETE_PAYOUT_SUCCESS, purchaseId);
                resolve(response.data);
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_PURCHASE_DELETE_PAYOUT_FAILED);
                reject(error);
            })
    })
}

export const purchaseDownloadPdfAsync = ({purchaseId}) => {
    return new Promise((resolve, reject) => {
        warehouseOperationService.purchaseDownloadPdf({purchaseId})
            .then((response) => {
                resolve(response.data)
            })
            .catch((error) => {
                reject(error)
            })
    })
}




export const selectPurchaseFilterOptions = state => state.purchase.filterOptions
export const selectPurchaseCount = state => state.purchase.purchaseCount
export const selectPurchases = state => state.purchase.purchases


export const selectPurchaseDefaultsSettings = state => state.purchase.purchaseDefaultsSettings


export const {setPurchaseDefaultsSettings, loadPurchaseDefaultsSettings} = purchaseReducer.actions


export default purchaseReducer.reducer;