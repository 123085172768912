import React from 'react';
import {Form, InputGroup} from "react-bootstrap";
import {useSelector} from "react-redux";
import {Controller, useFormContext} from "react-hook-form";
import SelectCategory from "../../components/SelectCategory";
import ChooseImagesModal from "./ChooseImagesModal";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import CatalogPackageLinkSelector from "../../../../common/CatalogPackageLinkSelector";
import {ErrorMessage} from "@hookform/error-message";
import SelectMeasurement from "../../components/SelectMeasurement";
import AddCategoryButton from "../../../category/components/AddCategoryButton";
import CatalogLinkSelector from "../../../../common/CatalogLinkSelector";
import BenefitLinkSelector from "../../../../common/BenefitLinkSelector";
import PropTypes from "prop-types";
import SelectCurrency from "../../components/SelectCurrency";
import Cleave from "cleave.js/react";
import {currencyConverterConvert} from "../../../../../app/store/reducers/currency/currencyReducer";
import PackageMeasurementRow from "./PackageMeasurementRow";
import BarcodeRow from "./BarcodeRow";
import {selectAllCategories} from "../../../../../app/store/reducers/category/categoryReducer";
import EditCategoryButton from "../../../category/components/EditCategoryButton";
import IconButton from "../../../../common/IconButton";
import {faLayerGroup} from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import SelectWarehouse from "../../components/SelectWarehouse";
import NameRow from "./NameRow";
import VatRateLinkSelector from "../../../../common/VatRateLinkSelector";
import ItemLegalTypeSelector from "../../components/ItemLegalTypeSelector";
import ItemPurchasePricesInput from "../../components/ItemPurchasePricesInput";
import {getNumberFixedValue} from "../../../../../helpers/utils";

const ItemRow = ({
                     isEditing,
                     index,
                     fieldName,
                     id,
                     deleteItem,
                     allowDeleteItem,
                     checkIsExistingItem,
                     onRightClickHandler
                 }) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const categories = useSelector(selectAllCategories);
    const form = useFormContext();
    const {error} = form.getFieldState(fieldName, form.formState);

    const onChangeCurrencyHandler = async (oldCurrency, newCurrency, valueFrom) => {
        try {
            if ((oldCurrency && valueFrom) && oldCurrency.id !== newCurrency.id) {
                const {currency_to: {value}} = await currencyConverterConvert({
                    currency_id_from: oldCurrency.id,
                    value_from: +valueFrom,
                    currency_id_to: newCurrency.id
                });
                return getNumberFixedValue(value, 2);
            }
            return getNumberFixedValue(valueFrom, 2);
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <tr>
            <td className="p-0 align-middle" style={{minWidth: '30px'}}>
                <div className="d-flex flex-column align-items-center justify-content-center">
                    <span>{index + 1}</span>
                    {allowDeleteItem &&
                        <FontAwesomeIcon icon={'trash-alt'}
                                         title={t(lang, 'items.common.delete')}
                                         className="text-danger cursor-pointer my-2"
                                         onClick={() => deleteItem(index)}
                        />
                    }
                </div>
            </td>
            <NameRow itemFieldName={fieldName}
                     itemIndex={index}
                     onRightClickHandler={onRightClickHandler}
                     checkIsExistingItem={checkIsExistingItem}
                     id={id}
            />
            <td className="py-0 align-middle">
                <Controller name={`${fieldName}.measurement`}
                            render={({field}) => (
                                <>
                                    <InputGroup className={classNames({'is-invalid': error?.measurement})}>
                                        <div className="react-select form-control p-0">
                                            <SelectMeasurement defaultMeasurement={field.value}
                                                               onChange={field.onChange}
                                                               ref={field.ref}
                                                               selectProps={{
                                                                   menuPortalTarget: document.body,
                                                                   styles: {
                                                                       container: (provided) => ({
                                                                           ...provided,
                                                                           margin: '-1px'
                                                                       }),
                                                                       menuPortal: (provided) => ({ ...provided, zIndex: 9999 })
                                                                   }
                                                               }}
                                            />
                                        </div>
                                        <IconButton icon={faLayerGroup}
                                                    variant={'falcon-primary'}
                                                    style={{zIndex: '1'}}
                                                    onContextMenu={(e) => {
                                                        onRightClickHandler(e, index, 'measurement', field.value);
                                                    }}
                                        />
                                    </InputGroup>
                                    <ErrorMessage name='measurement'
                                                  errors={error?.code}
                                                  as={<Form.Control.Feedback type="invalid"/>}
                                    />
                                </>
                            )}
                />
            </td>
            <td className="py-0 align-middle">
                <InputGroup>
                    <Controller name={`${fieldName}.commonPrice`}
                                render={({field}) => (
                                    <>
                                        <Cleave className="form-control"
                                                htmlRef={field.ref}
                                                placeholder={t(lang, 'items.common.price.placeholder')}
                                                onKeyDown={(e) => {
                                                    if (e.keyCode === 188)
                                                        e.target.value += "."
                                                }}
                                                options={{
                                                    numeral: true,
                                                    delimiter: ' ',
                                                    numeralDecimalScale: 4,
                                                }}
                                                value={field.value?.price}
                                                onChange={e => field.onChange({
                                                    ...field.value,
                                                    price: e.target.rawValue
                                                })}
                                                onContextMenu={(e) => onRightClickHandler(e, index, 'commonPrice', field.value)}
                                        />
                                        <SelectCurrency defaultCurrencyId={field.value?.currencyId}
                                                        onChange={async (oldCurrency, newCurrency) => {
                                                            field.onChange({
                                                                currencyId: newCurrency ? newCurrency.id : null,
                                                                price: await onChangeCurrencyHandler(oldCurrency, newCurrency, field.value?.price)
                                                            })
                                                        }}
                                                        selectProps={{
                                                            menuPortalTarget: document.body,
                                                            styles: {
                                                                control: (provided) => ({
                                                                    ...provided,
                                                                    border: 'none',
                                                                    boxShadow: 'none',
                                                                    borderRadius: '0',
                                                                    backgroundColor: '#0d6efd',
                                                                }),
                                                                singleValue: (provided) => ({
                                                                    ...provided,
                                                                    color: '#fff'
                                                                }),
                                                                menu: (provided) => ({
                                                                    ...provided,
                                                                    marginTop: 0,
                                                                    boxShadow: 'none',
                                                                    borderRadius: '0',
                                                                }),
                                                                menuPortal: (provided) => ({ ...provided, zIndex: 9999 })
                                                            }
                                                        }}
                                        />
                                    </>
                                )}
                    />
                </InputGroup>
            </td>
            <td className="py-0 align-middle">
                <InputGroup>
                    <Controller name={`${fieldName}.bulkPrice`}
                                render={({field}) => (
                                    <>
                                        <Cleave className="form-control"
                                                htmlRef={field.ref}
                                                placeholder={t(lang, 'items.common.bulk_price.placeholder')}
                                                onKeyDown={(e) => {
                                                    if (e.keyCode === 188)
                                                        e.target.value += "."
                                                }}
                                                options={{
                                                    numeral: true,
                                                    delimiter: ' ',
                                                    numeralDecimalScale: 4,
                                                }}
                                                value={field.value?.price}
                                                onChange={e => field.onChange({
                                                    ...field.value,
                                                    price: e.target.rawValue
                                                })}
                                                onContextMenu={(e) => onRightClickHandler(e, index, 'bulkPrice', field.value)}
                                        />
                                        <SelectCurrency defaultCurrencyId={field.value?.currencyId}
                                                        onChange={async (oldCurrency, newCurrency) => {
                                                            field.onChange({
                                                                currencyId: newCurrency ? newCurrency.id : null,
                                                                price: await onChangeCurrencyHandler(oldCurrency, newCurrency, field.value?.price)
                                                            })
                                                        }}
                                                        selectProps={{
                                                            menuPortalTarget: document.body,
                                                            styles: {
                                                                control: (provided) => ({
                                                                    ...provided,
                                                                    border: 'none',
                                                                    boxShadow: 'none',
                                                                    borderRadius: '0',
                                                                    backgroundColor: '#0d6efd',
                                                                }),
                                                                singleValue: (provided) => ({
                                                                    ...provided,
                                                                    color: '#fff'
                                                                }),
                                                                menu: (provided) => ({
                                                                    ...provided,
                                                                    marginTop: 0,
                                                                    boxShadow: 'none',
                                                                    borderRadius: '0',
                                                                }),
                                                                menuPortal: (provided) => ({ ...provided, zIndex: 9999 })
                                                            }
                                                        }}
                                        />
                                    </>
                                )}
                    />
                </InputGroup>
            </td>
            {!isEditing && (
                <td className="py-0 align-middle">
                    <InputGroup>
                        <Controller name={`${fieldName}.purchasePrice`}
                                    render={({field}) => (
                                        <>
                                            <Cleave className="form-control"
                                                    htmlRef={field.ref}
                                                    placeholder={t(lang, 'items.common.purchase_price.placeholder')}
                                                    onKeyDown={(e) => {
                                                        if (e.keyCode === 188)
                                                            e.target.value += "."
                                                    }}
                                                    options={{
                                                        numeral: true,
                                                        delimiter: ' ',
                                                        numeralDecimalScale: 4,
                                                    }}
                                                    value={field.value?.price}
                                                    onChange={e => field.onChange({
                                                        ...field.value,
                                                        price: e.target.rawValue
                                                    })}
                                                    onContextMenu={(e) => onRightClickHandler(e, index, 'purchasePrice', field.value)}
                                            />
                                            <SelectCurrency defaultCurrencyId={field.value?.currencyId}
                                                            onChange={async (oldCurrency, newCurrency) => {
                                                                field.onChange({
                                                                    currencyId: newCurrency ? newCurrency.id : null,
                                                                    price: await onChangeCurrencyHandler(oldCurrency, newCurrency, field.value?.price)
                                                                })
                                                            }}
                                                            selectProps={{
                                                                menuPortalTarget: document.body,
                                                                styles: {
                                                                    control: (provided) => ({
                                                                        ...provided,
                                                                        border: 'none',
                                                                        boxShadow: 'none',
                                                                        borderRadius: '0',
                                                                        backgroundColor: '#0d6efd',
                                                                    }),
                                                                    singleValue: (provided) => ({
                                                                        ...provided,
                                                                        color: '#fff'
                                                                    }),
                                                                    menu: (provided) => ({
                                                                        ...provided,
                                                                        marginTop: 0,
                                                                        boxShadow: 'none',
                                                                        borderRadius: '0',
                                                                    }),
                                                                    menuPortal: (provided) => ({ ...provided, zIndex: 9999 })
                                                                }
                                                            }}
                                            />
                                        </>
                                    )}
                        />
                    </InputGroup>
                </td>
            )}
            {!!isEditing && (
                <td className="py-0 align-middle">
                    <ItemPurchasePricesInput purchasePricesArrayName={`${fieldName}.purchasePrices`}/>
                </td>
            )}
            {!isEditing &&
                <>
                    <td className="py-0 align-middle">
                        <Controller name={`${fieldName}.state`}
                                    render={({field}) => (
                                        <Cleave htmlRef={field.ref}
                                                className="form-control"
                                                placeholder={t(lang, 'items.common.state.placeholder')}
                                                onKeyDown={(e) => {
                                                    if (e.keyCode === 188)
                                                        e.target.value += "."
                                                }}
                                                options={{
                                                    numeral: true,
                                                    delimiter: ' ',
                                                    numeralDecimalScale: 3,
                                                }}
                                                value={field.value}
                                                onChange={e => field.onChange(e.target.rawValue)}
                                                onContextMenu={(e) => onRightClickHandler(e, index, 'state', field.value)}
                                        />
                                    )}
                        />
                    </td>
                    <td className="py-0 align-middle">
                        <Controller name={`${fieldName}.warehouseId`}
                                    render={({field}) => (
                                        <InputGroup>
                                            <div className="react-select form-control p-0">
                                                <SelectWarehouse defaultWarehouseId={field.value}
                                                                 onChange={option => field.onChange(option ? option.id : null)}
                                                                 selectProps={{
                                                                     isSearchable: false,
                                                                     menuPortalTarget: document.body,
                                                                     styles: {
                                                                         container: (provided) => ({
                                                                             ...provided,
                                                                             margin: '-1px'
                                                                         }),
                                                                         menuPortal: (provided) => ({ ...provided, zIndex: 9999 })
                                                                     }
                                                                 }}
                                                />
                                            </div>
                                            <IconButton icon={faLayerGroup}
                                                        variant={'falcon-primary'}
                                                        style={{zIndex: '1'}}
                                                        onContextMenu={(e) => {
                                                            onRightClickHandler(e, index, 'warehouseId', field.value);
                                                        }}
                                            />
                                        </InputGroup>

                                    )}
                        />
                    </td>
                </>
            }
            <td className="py-0 align-middle">
                <Controller
                    name={`${fieldName}.alertOn`}
                    render={({field}) => (
                        <Cleave htmlRef={field.ref}
                                className="form-control"
                                placeholder={t(lang, 'items.common.state.placeholder')}
                                onKeyDown={(e) => {
                                    if (e.keyCode === 188)
                                        e.target.value += "."
                                }}
                                options={{
                                    numeral: true,
                                    delimiter: ' ',
                                    numeralDecimalScale: 3,
                                }}
                                value={field.value}
                                onChange={e => field.onChange(e.target.rawValue)}
                        />
                    )}
                />
            </td>
            <td className="py-0 align-middle">
                <PackageMeasurementRow fieldName={`${fieldName}.packageMeasurements`}
                                       onRightClickHandler={(e) => onRightClickHandler(e, index, 'packageMeasurements', form.getValues(`${fieldName}.packageMeasurements`))}
                />
            </td>
            <td className="py-0 align-middle">
                <Controller name={`${fieldName}.categoryId`}
                            render={({field}) => {
                                return (
                                    <InputGroup>
                                        <div className="react-select form-control p-0">
                                            <SelectCategory categories={categories}
                                                            defaultCategoryId={field.value}
                                                            onChange={(option) => {
                                                                field.onChange(option ? option.id : null);
                                                            }}
                                                            selectProps={{
                                                                menuPortalTarget: document.body,
                                                                styles: {
                                                                    container: (provided) => ({
                                                                        ...provided,
                                                                        margin: '-1px'
                                                                    }),
                                                                    menuPortal: (provided) => ({ ...provided, zIndex: 9999 })
                                                                }
                                                            }}
                                            />
                                        </div>
                                        {field.value ?
                                            <>
                                                <EditCategoryButton categoryId={field.value}/>
                                                <IconButton icon={faLayerGroup}
                                                            variant={'falcon-primary'}
                                                            style={{zIndex: '1'}}
                                                            onContextMenu={(e) => {
                                                                onRightClickHandler(e, index, 'categoryId', field.value);
                                                            }}
                                                />
                                            </>
                                            :
                                            <AddCategoryButton/>
                                        }
                                    </InputGroup>
                                )
                            }}
                />
            </td>
            <td className="py-0 align-middle">
                <BarcodeRow fieldName={`${fieldName}.barcodes`}
                            checkIsExistingItem={checkIsExistingItem}
                            id={id}
                            onRightClickHandler={(e) => onRightClickHandler(e, index, 'barcodes', form.getValues(`${fieldName}.barcodes`))}
                />
            </td>
            <td className="py-0 align-middle">
                <Controller name={`${fieldName}.code`}
                            render={({field}) => {
                                const isExistingCode = checkIsExistingItem(null, field.value, null, id);
                                return (
                                    <>
                                        <Form.Control name={`${fieldName}.code`}
                                                      placeholder={t(lang, 'items.common.code.placeholder')}
                                                      isInvalid={error?.code || isExistingCode}
                                                      value={field.value}
                                                      onChange={field.onChange}
                                                      onContextMenu={(e) => onRightClickHandler(e, index, 'code', field.value)}
                                        />
                                        <ErrorMessage name='code'
                                                      errors={error?.code}
                                                      as={<Form.Control.Feedback type="invalid"/>}
                                        />
                                        {(!error?.code && isExistingCode) && <Form.Control.Feedback
                                            type="invalid">{t(lang, "items.common.validations.name_exists", {name: field.value})}</Form.Control.Feedback>}
                                    </>
                                )
                            }}
                />

            </td>
            <td className="py-0 align-middle">
                <Controller name={`${fieldName}.sku`}
                            render={({field}) => (
                                <Form.Control name={`${fieldName}.sku`}
                                              placeholder={t(lang, 'items.common.sku.placeholder')}
                                              value={field.value}
                                              onChange={field.onChange}
                                              onContextMenu={(e) => onRightClickHandler(e, index, 'sku', field.value)}
                                />
                            )}
                />
            </td>
            <Controller name={`${fieldName}.taxCatalog`}
                        render={({field: catalogField}) => (
                            <>
                                <td className="py-0 align-middle">
                                    <CatalogLinkSelector defaultCatalog={catalogField.value}
                                                         onChange={(catalog) => {
                                                             catalogField.onChange(catalog);
                                                             form.setValue(`${fieldName}.taxPackage`, (catalog?.use_package && catalog?.package_names?.length === 1) ? catalog.package_names[0] : null)
                                                             form.setValue(`${fieldName}.taxBenefit`, null)
                                                         }}
                                    />
                                    <FontAwesomeIcon icon={faLayerGroup}
                                                     className="ms-2 cursor-pointer"
                                                     onContextMenu={(e) => {
                                                         onRightClickHandler(e, index, 'taxCatalog', catalogField.value);
                                                     }}
                                    />
                                </td>
                                <td className="py-0 align-middle">
                                    <Controller name={`${fieldName}.taxPackage`}
                                                render={({field: packageField}) => (
                                                    <CatalogPackageLinkSelector
                                                        catalogPackages={catalogField.value?.package_names}
                                                        defaultCatalogPackage={packageField.value}
                                                        onChange={packageField.onChange}
                                                    />
                                                )}
                                    />
                                </td>
                                <td className="py-0 align-middle">
                                    <Controller name={`${fieldName}.taxRate`}
                                                render={({field}) => (
                                                    <>
                                                        <VatRateLinkSelector defaultVatRate={field.value}
                                                                             onChange={field.onChange}
                                                        />
                                                        <FontAwesomeIcon icon={faLayerGroup}
                                                                         className="ms-2 cursor-pointer text-warning"
                                                                         onContextMenu={(e) => {
                                                                             onRightClickHandler(e, index, 'taxRate', field.value);
                                                                         }}
                                                        />
                                                    </>
                                                )}
                                    />
                                </td>
                                <td className="py-0 align-middle">
                                    <Controller name={`${fieldName}.taxBenefit`}
                                                render={({field}) => (
                                                    <BenefitLinkSelector benefits={catalogField.value?.lgotas}
                                                                         defaultBenefit={field.value}
                                                                         onChange={field.onChange}
                                                    />
                                                )}
                                    />
                                </td>
                            </>

                        )}
            />
            <td className="py-0 align-middle">
                <Controller
                    name={`${fieldName}.legalType`}
                    render={({field}) => (
                        <ItemLegalTypeSelector legalType={field.value} onChange={field.onChange}/>
                    )}
                />
            </td>
            <td className="py-0 align-middle text-center">
                <ChooseImagesModal fieldName={`${fieldName}.images`}
                                   onRightClickHandler={(e) => {
                                       onRightClickHandler(e, index, 'images', form.getValues(`${fieldName}.images`));
                                   }}
                />
                {form.getValues(`${fieldName}.images`)?.length > 0 &&
                    <span className="fw-bold ms-2 text-info">
                        {form.watch(`${fieldName}.images`)?.length}
                    </span>
                }
            </td>
            <td className="p-0 align-middle text-center" style={{minWidth: '30px'}}>
                {allowDeleteItem &&
                    <FontAwesomeIcon icon={'trash-alt'}
                                     title={t(lang, 'items.common.delete')}
                                     className="text-danger cursor-pointer"
                                     onClick={() => deleteItem(index)}
                    />
                }
            </td>
        </tr>
    );
};

export default ItemRow;

ItemRow.propTypes = {
    fieldName: PropTypes.string.isRequired,
    deleteItem: PropTypes.func,
    allowDeleteItem: PropTypes.bool,
    categories: PropTypes.array,
    checkIsExistingItem: PropTypes.func
}

ItemRow.defaultProps = {
    fieldName: '',
    deleteItem: () => {
    },
    allowDeleteItem: false,
    categories: [],
    checkIsExistingItem: () => {
    }
}
