import {FormProvider, useForm} from "react-hook-form";
import {
    ActFormSendAsync,
    ActFormSendDraft,
} from "../../../../../app/store/reducers/roaming/roamingActReducer";
import dayjs from "dayjs";
import {toast} from "react-toastify";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../../../app/store/reducers/auth/authReducer";
import {selectCurrentCertificate} from "../../../../../app/store/reducers/eimzo/eimzoReducer";
import {useHistory} from "react-router-dom";
import {selectLang, Translate, updateShowInProgressAsync} from "../../../../../app/store/reducers/main/mainReducer";
import {
    loadCompanyProductCatalogsAsync,
    loadMeasurementsAsync, selectCompanyCatalogs
} from "../../../../../app/store/reducers/roaming/roamingReducer";
import {Form} from "react-bootstrap";
import ActFormInfo from "../ActFormInfo";
import ActFormOwnerBuyer from "../ActFormOwnerBuyer";
import ActFormDataTable from "../ActFormDataTable";
import EventBus from "../../../../../app/eventbus/EventBus";
import {
    ROAMING_ACT_DRAFT_FAILED, ROAMING_ACT_DRAFT_REQUESTED,
    ROAMING_ACT_DRAFT_SUCCESS,
    ROAMING_ACT_REGISTER_FAILED, ROAMING_ACT_REGISTER_REQUESTED,
    ROAMING_ACT_REGISTER_SUCCESS
} from "../../../../../app/eventbus/roaming/roamingActEvents";
import {getOrganizationBillingInfoAsync} from "../../../../../app/store/reducers/billing/billingReducer";


const DraftActForm = ({draftAct}) => {
    const activeOrganization = useSelector(selectActiveOrganization);
    const currentCertificate = useSelector(selectCurrentCertificate);
    const catalogs = useSelector(selectCompanyCatalogs)
    const dispatch = useDispatch();
    const history = useHistory()
    const lang = useSelector(selectLang);
    const t = Translate;

    useEffect( () => {
        dispatch(loadCompanyProductCatalogsAsync(activeOrganization.inn, lang));
        dispatch(loadMeasurementsAsync(lang));

        methods.setValue('ActSeller', {
            name: activeOrganization?.name || '',
            inn: activeOrganization?.inn || '',
            pinfl: activeOrganization?.pinfl || '',
        });

        methods.setValue('ActBuyer', {
            inn: draftAct?.BuyerTin,
            name: draftAct?.BuyerName,
            pinfl: draftAct?.BuyerTin,
        })

    }, [activeOrganization])

    useEffect(() => {
        const onSendActRequestedHandler = EventBus.on(ROAMING_ACT_REGISTER_REQUESTED, () => {
            dispatch(updateShowInProgressAsync(true))
        })

        const onSendActSuccessHandler = EventBus.on(ROAMING_ACT_REGISTER_SUCCESS, () => {
            toast.success(t(lang, "edi.common.toast.success"))
            dispatch(getOrganizationBillingInfoAsync());
            history.push(`/roaming/act/${draftAct.ActId}`)
            dispatch(updateShowInProgressAsync(false))
        })

        const onSendActFailedHandler = EventBus.on(ROAMING_ACT_REGISTER_FAILED, () => {
            toast.error(t(lang, "edi.common.toast.error"))
            dispatch(updateShowInProgressAsync(false))
        })

        const onDraftSendActRequestedHandler = EventBus.on(ROAMING_ACT_DRAFT_REQUESTED, () => {
            dispatch(updateShowInProgressAsync(true))
        })

        const onDraftSendActSuccessHandler = EventBus.on(ROAMING_ACT_DRAFT_SUCCESS, () => {
            toast.success(t(lang, "roaming.act_form_save_success"))
            history.push(`/roaming/draft-act/${draftAct.ActId}`)
            dispatch(updateShowInProgressAsync(false))
        })

        const onDraftSendActFailedHandler = EventBus.on(ROAMING_ACT_DRAFT_FAILED, () => {
            toast.error(t(lang, "roaming.act_form_save_error"))
            dispatch(updateShowInProgressAsync(false))
        })


        return () => {
            EventBus.remove(ROAMING_ACT_REGISTER_REQUESTED, onSendActRequestedHandler)
            EventBus.remove(ROAMING_ACT_REGISTER_SUCCESS, onSendActSuccessHandler)
            EventBus.remove(ROAMING_ACT_REGISTER_FAILED, onSendActFailedHandler)
            EventBus.remove(ROAMING_ACT_DRAFT_REQUESTED, onDraftSendActRequestedHandler)
            EventBus.remove(ROAMING_ACT_DRAFT_SUCCESS, onDraftSendActSuccessHandler)
            EventBus.remove(ROAMING_ACT_DRAFT_FAILED, onDraftSendActFailedHandler)
        }

    },[])

    const methods = useForm({
        defaultValues: {
            act: {
                info: draftAct?.ActDoc?.ActNo,
                date: dayjs(draftAct?.ActDoc?.ActDate, 'YYYY-MM-DD').toDate(),
            },
            contract: {
                number: draftAct?.ContractDoc?.ContractNo,
                date: dayjs(draftAct?.ContractDoc?.ContractDate, 'YYYY-MM-DD').toDate(),
            },
            ActComment: draftAct?.ActDoc?.ActText,
            ActSeller: {
                inn: draftAct?.SellerTin,
                name: draftAct?.SellerName,
                pinfl: draftAct?.SellerTin,
            },
            SellerTin: draftAct?.SellerTin,
            SellerName: draftAct?.SellerName,
            SellerBranchCode: draftAct?.SellerBranchCode,
            SellerBranchName: draftAct?.SellerBranchName,
            BuyerBranchName: draftAct?.BuyerBranchName,
            BuyerBranchCode: draftAct?.BuyerBranchCode,
            ActBuyer: {
                inn: draftAct?.BuyerTin,
                name: draftAct?.BuyerName,
                pinfl: draftAct?.BuyerTin,
            },
            itemAct: draftAct?.ProductList?.Products.map(item => {
                const catalog = catalogs?.find(c => c?.class_code === item?.CatalogCode)
                const packages = catalog?.package_names.find(p => p?.code === +item?.PackageCode)


                return  {
                    catalogClassCode: item?.CatalogCode,
                    catalogClassName: item?.CatalogName,
                    Items: item?.Name,
                    measurementId: item.MeasureId,
                    packageCode: +item.PackageCode,
                    packageName: item.PackageName,
                    catalog: catalog,
                    packages: packages,
                    quantity: item?.Count,
                    price: item?.Summa,
                    totalSum: item?.TotalSum,
                }
            })
        },
    });



    const onSubmit = async (formData, event) => {
        const buttonType = event.nativeEvent.submitter.name

        const ActFormSend = JSON.stringify({
            ActId: draftAct.ActId,
            ActDoc: {
                ActNo: formData.act.info,
                ActDate: dayjs(formData.act.date).format('YYYY-MM-DD'),
                ActText: formData.ActComment,
            },
            ContractDoc: {
                ContractNo: formData.contract.number,
                ContractDate: dayjs(formData.contract.date).format('YYYY-MM-DD'),
            },
            SellerTin: formData.ActSeller.pinfl || formData.ActSeller.inn,
            SellerName: formData.ActSeller.name,
            SellerBranchCode: formData.SellerBranchCode,
            SellerBranchName: formData.SellerBranchName,
            BuyerTin: formData.ActBuyer.pinfl || formData.ActBuyer.inn,
            BuyerName: formData.ActBuyer.name,
            BuyerBranchName: formData.BuyerBranchName,
            BuyerBranchCode: formData.BuyerBranchCode,
            ProductList: {
                ActProductId: draftAct.ProductList.ActProductId,
                Tin: formData.ActSeller.pinfl || formData.ActSeller.inn,
                Products: formData.itemAct.map((item, index) => {
                    return {
                        OrdNo: index + 1,
                        Name: item.Items,
                        CatalogCode: item.catalogClassCode,
                        CatalogName: item.catalogClassName,
                        MeasureId: item.measurementId,
                        PackageCode: item.packageCode ? `${item.packageCode}` : null,
                        PackageName: item.packageName,
                        Count: item.quantity,
                        Summa: item.price,
                        TotalSum: item.totalSum,
                    }
                })
            },
        })


        if(buttonType === "send") {
            if(currentCertificate?.keyId) {
                await ActFormSendAsync(currentCertificate, lang, ActFormSend, draftAct.ActId, formData.ActSeller.pinfl || formData.ActSeller.inn)
            }
        } else if(buttonType === "draft") {
            await ActFormSendDraft(ActFormSend)
        }
    };

    return(
        <>
            <FormProvider {...methods}>
                <Form onSubmit={methods.handleSubmit(onSubmit)} id="ActForm">
                    <ActFormInfo/>
                    <ActFormOwnerBuyer/>
                    <ActFormDataTable/>
                </Form>
            </FormProvider>
        </>
    )
}


export default DraftActForm;