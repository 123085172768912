import {FormProvider, useForm} from "react-hook-form";
import {Form} from "react-bootstrap";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {useEffect, useState} from "react";
import {
    getSaleItemAsync,
    saleAddItemAsync, saleChangeContractorAsync, saleUpdateNoteAsync,
    saleRegisterAsync, saleUpdateNumberAsync, selectSaleDefaultsSettings, saleChangeEmployeeAsync
} from "../../../../../app/store/reducers/warehouse-operation/saleReducer";
import EventBus from "../../../../../app/eventbus/EventBus";
import {
    WAREHOUSE_OPERATION_SALE_ADD_CLIENT_SUCCESS,
    WAREHOUSE_OPERATION_SALE_ADD_ITEM_SUCCESS, WAREHOUSE_OPERATION_SALE_APPROVED_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_SALE_DATE_SUCCESS,
    WAREHOUSE_OPERATION_SALE_DELETE_ITEM_SUCCESS, WAREHOUSE_OPERATION_SALE_NUMBER_SUCCESS,
    WAREHOUSE_OPERATION_SALE_UPDATE_ITEM_SUCCESS
} from "../../../../../app/eventbus/warehouse-operation/saleEvents";
import dayjs from "dayjs";
import {selectCurrency} from "../../../../../app/store/reducers/currency/currencyReducer";
import {UZS} from "../../../../../enum/CurrencyCode";
import SaleForm from "./SaleForm";
import {useHistory} from "react-router";
import {toast} from "react-toastify";
import {selectWarehouses} from "../../../../../app/store/reducers/warehouse/warehouseReducer";
import {withoutDiscount, withDiscount} from "../../enum/warehouseOperationWrapper";
import {selectDefaultSetting} from "../../../../../app/store/reducers/settings/settingsReducer";


const AddSaleForm = () => {
    const [response, setResponse] = useState(null)
    const [loading, setLoading] = useState(false)
    const saleDefaultsSettings = useSelector(selectSaleDefaultsSettings)
    const [sale, setSale] = useState(null)
    const warehouses = useSelector(selectWarehouses)
    const currencies = useSelector(selectCurrency)
    const history = useHistory()
    const lang = useSelector(selectLang)
    const t = Translate;
    const settings = useSelector(selectDefaultSetting)


    const form = useForm({
        defaultValues: {
            contractor_id: null,
            employee_id: saleDefaultsSettings?.defaultEmployeeId || null,
            cashbox_id: settings?.cash_box?.default_id || null,
            number: null,
            date: dayjs(new Date(), "YYYY-MM-DD HH:mm").toDate(),
            note: '',
            item_id: null,
            warehouse_id: settings?.warehouse?.default_id || null,
            quantity: null,
            marks: [],
            commonPrice: {
                amount: null,
                currency_id: currencies?.find(c => c?.code === UZS)?.id,
            },
            commonDiscount: {
                amount: 0,
                type: withoutDiscount,
            },
        }
    })

    const resetForm = (formData, item) => {
        let discountType = null
        let discountValue = 0
        if (item) {
            discountType = item.discount?.type
            if (item.discount?.value)
                discountValue = item.discount.value
        }
        form.reset({
            contractor_id: formData?.contractor?.id || formData?.contractor_id,
            employee_id: formData?.employee?.id || formData?.employee_id,
            cashbox_id: settings?.cash_box?.default_id || formData?.cashbox?.id || formData?.cashbox_id,
            date: formData?.date && dayjs(formData?.date, "YYYY-MM-DD HH:mm").toDate(),
            note: formData?.note,
            number: formData?.number,
            item_id: null,
            quantity: null,
            warehouse_id: settings?.warehouse?.default_id || null,
            warehouse: warehouses,
            package_measurements: null,
            marks: [],
            commonPrice: {
                amount: '0',
                currency_id: currencies?.find(c => c?.code === UZS)?.id,
            },
            commonDiscount: {
                amount: discountValue,
                type: discountType || formData?.commonDiscount?.type,
            },
        })
    }

    const onSubmit = async (formData, event) => {
        const buttonType = event?.nativeEvent?.submitter?.name
        const quantity = +formData?.quantity * +formData?.package_measurements?.quantity
        const item = {
            item_id: formData?.item_id,
            warehouse_id: formData?.warehouse_id,
            quantity: quantity,
            marks: formData?.marks || [],
            price: {
                amount: +formData?.commonPrice?.amount,
                currency_id: formData?.commonPrice?.currency_id,
            },
            discount: {
                value: +formData?.commonDiscount?.amount,
                type: formData?.commonDiscount?.type,
            },
        }


        if (buttonType === "addOrEditSale" || event === "addOrEditSale") {
            setLoading(true)
            if (!sale) {
                await saleRegisterAsync({
                    contractor_id: formData?.contractor_id,
                    date: dayjs(formData?.date).format("YYYY-MM-DD HH:mm:ss"),
                    note: formData?.note,
                    number: formData?.number?.trim() ? formData?.number : null,
                    items: [item],
                    employee_id: formData?.employee_id,
                    cashbox_id: formData?.cashbox_id
                })
                    .then(({data}) => {
                        setResponse(data)
                        resetForm(data, item)
                        toast.success(t(lang, "warehouse.operation.item.common.toast.success"))
                    })
                    .catch((error) => {
                        if (error?.response?.data?.error?.includes("warehouse")) {
                            toast.error(t(lang, "Склад не выбран"))
                        } else {
                            toast.error(t(lang, "warehouse.operation.item.common.toast.error"))
                        }
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            }
           /* if (sale && response?.contractor?.id !== formData?.contractor_id) {
                await saleChangeContractorAsync({saleId: sale.id, contractorId: formData?.contractor_id})
                    .then(({data}) => {
                        setResponse(data)
                    })
                    .catch(() => {
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            }*/
            if (sale) {
                await saleAddItemAsync({
                    saleId: sale?.id,
                    operation: item
                })
                    .then(({data}) => {
                        updateNote(formData)
                        // updateNumber(formData)
                        resetForm(formData, item)
                        updateEmployee(formData)
                        toast.success(t(lang, "warehouse.operation.item.common.toast.success"))
                    })
                    .catch((error) => {
                        if (error?.response?.data?.error?.includes("warehouse") || error?.response?.data?.includes("warehouse")) {
                            toast.error(t(lang, "Склад не выбран"))
                        } else {
                            toast.error(t(lang, "warehouse.operation.item.common.toast.error"))
                        }
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            }
        }
    }

    const updateEmployee = (formData) => {
        if (formData?.employee_id || sale?.employee) {
            if (formData?.employee_id !== sale?.employee?.id) {
                saleChangeEmployeeAsync({saleId: sale?.id, employeeId: formData?.employee_id})
                    .then(({data}) => {
                        setSale(data, data.items.reverse())
                    })
                    .catch(() => {})
            }
        }
    }

    const updateNote = (formData) => {
        if (formData?.note !== sale?.note) {
            saleUpdateNoteAsync({saleId: sale?.id, note: formData?.note})
                .then(({data}) => {
                    setSale(data, data.items.reverse())
                })
                .catch(() => {})
        }
    }

    // const updateNumber = (formData) => {
    //     if (formData?.number?.trim() && formData?.number !== sale?.number) {
    //         saleUpdateNumberAsync({saleId: sale?.id, number: formData?.number})
    //             .then(({data}) => {
    //                 setSale(data, data.items.reverse())
    //             })
    //             .catch(() => {})
    //     }
    // }

    useEffect(() => {
        const onAddClientHandler = EventBus.on(WAREHOUSE_OPERATION_SALE_ADD_CLIENT_SUCCESS, (id) => {
            loadSale(id)
        })

        const onAddItemHandler = EventBus.on(WAREHOUSE_OPERATION_SALE_ADD_ITEM_SUCCESS, (id) => {
            loadSale(id)
        })

        const onUpdateItemHandler = EventBus.on(WAREHOUSE_OPERATION_SALE_UPDATE_ITEM_SUCCESS, (id) => {
            loadSale(id)
        })

        const onDeleteItemHandler = EventBus.on(WAREHOUSE_OPERATION_SALE_DELETE_ITEM_SUCCESS, (id) => {
            loadSale(id)
        })

        const onApprovedHandler = EventBus.on(WAREHOUSE_OPERATION_SALE_APPROVED_ITEM_SUCCESS, (id) => {
            history.push(`./view/${id}`)
        })

        const onChangedDateHandler = EventBus.on(WAREHOUSE_OPERATION_SALE_DATE_SUCCESS, (id) => {
            loadSale(id)
        })

        const onChangedNumberHandler = EventBus.on(WAREHOUSE_OPERATION_SALE_NUMBER_SUCCESS, (id) => {
            loadSale(id)
        })

        return () => {
            EventBus.remove(WAREHOUSE_OPERATION_SALE_ADD_CLIENT_SUCCESS, onAddClientHandler)
            EventBus.remove(WAREHOUSE_OPERATION_SALE_ADD_ITEM_SUCCESS, onAddItemHandler)
            EventBus.remove(WAREHOUSE_OPERATION_SALE_UPDATE_ITEM_SUCCESS, onUpdateItemHandler)
            EventBus.remove(WAREHOUSE_OPERATION_SALE_DELETE_ITEM_SUCCESS, onDeleteItemHandler)
            EventBus.remove(WAREHOUSE_OPERATION_SALE_APPROVED_ITEM_SUCCESS, onApprovedHandler)
            EventBus.remove(WAREHOUSE_OPERATION_SALE_DATE_SUCCESS, onChangedDateHandler)
            EventBus.remove(WAREHOUSE_OPERATION_SALE_NUMBER_SUCCESS, onChangedNumberHandler)
        }
    }, [])

    useEffect(() => {
        form.setValue('cashbox_id', settings?.cash_box?.default_id || null)
        form.setValue('warehouse_id', settings?.warehouse?.default_id || null)
    }, [settings])

    const loadSale = async (id) => {
        await getSaleItemAsync({saleId: id})
            .then((response) => {
                setSale(response.data, response.data.items.reverse())
            })
            .catch((error) => {
                setSale(null)
            })
    }


    return(
        <>
            <FormProvider {...form}>
                <Form onSubmit={form.handleSubmit(onSubmit)}>
                    <SaleForm sale={sale} loading={loading} addItem={form.handleSubmit(onSubmit)} isEditing={false}/>
                </Form>
            </FormProvider>
        </>
    )
}

export default AddSaleForm;
