import {Card, Col, Form, FormControl, Row} from "react-bootstrap";
import DatePicker from "react-datepicker";
import React from "react";
import {Controller, useFormContext} from "react-hook-form";
import classNames from "classnames";
import {useSelector} from "react-redux";
import {selectDateFormat, selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";



const ActFormInfo = () => {
    const {register, formState: {errors}, control, watch} = useFormContext()
    const dateFormat = useSelector(selectDateFormat);
    const lang = useSelector(selectLang);
    const t = Translate;


    return(
        <>
            <Card className="my-3">
                <Row className="rounded p-3">
                    <h3 className="border-bottom">{t(lang, "roaming.act_form.title")}</h3>
                    <Col xs={12} md={6} className="d-flex flex-column my-2">
                        <Form.Label>{t(lang, "roaming.act_form.act_number")}</Form.Label>
                        <FormControl
                            name="act.info"
                            type="text"
                            placeholder={t(lang, "roaming.act_form.act_number")}
                            {...register("act.info", {
                                required: t(lang, "edi.common.forms.validations.is_required")
                            })}
                            isInvalid={errors?.act?.info}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors?.act?.info?.message}
                        </Form.Control.Feedback>
                    </Col>
                    <Col xs={12} md={6} className="d-flex flex-column my-2">
                        <Form.Label>{t(lang, "roaming.act_form.act_date")}</Form.Label>
                        <Controller
                            control={control}
                            name='act.date'
                            render={({field}) => (
                                <DatePicker
                                    placeholderText={t(lang, "roaming.act_form.act_date")}
                                    wrapperClassName={classNames({
                                        'is-invalid': errors?.act?.date,
                                    })}
                                    dateFormat={dateFormat}
                                    selected={field.value}
                                    onChange={(date) => field.onChange(date)}
                                    className={classNames('form-control', {
                                        'is-invalid': errors?.act?.date,
                                    })}
                                    maxDate={new Date()}
                                />
                            )}
                            rules={{
                                required: t(lang, "edi.common.forms.validations.is_required"),
                                validate: (value) => {
                                    if(value < watch('contract.date'))
                                        return t(lang, "roaming.act_form.date_validation");
                                    return true;
                                }
                            }}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors?.act?.date?.message}
                        </Form.Control.Feedback>
                    </Col>
                    <Col xs={12} md={6} className="d-flex flex-column my-2">
                        <Form.Label>{t(lang, "roaming.act_form_contract_number")}</Form.Label>
                        <FormControl
                            name="contract.number"
                            type="text"
                            placeholder={t(lang, "roaming.act_form_contract_number")}
                            {...register("contract.number", {
                                required: t(lang, "edi.common.forms.validations.is_required"),
                            })}
                            isInvalid={errors?.contract?.number}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors?.contract?.number?.message}
                        </Form.Control.Feedback>
                    </Col>
                    <Col xs={12} md={6} className="d-flex flex-column my-2">
                        <Form.Label>{t(lang, "roaming.act_form_contract_date")}</Form.Label>
                        <Controller
                            control={control}
                            name='contract.date'
                            render={({field}) => (
                                <DatePicker
                                    placeholderText={t(lang, "roaming.act_form_contract_date")}
                                    wrapperClassName={classNames({
                                        'is-invalid': errors?.contract?.date,
                                    })}
                                    dateFormat={dateFormat}
                                    selected={field.value}
                                    onChange={(date) => field.onChange(date)}
                                    className={classNames('form-control', {
                                        'is-invalid': errors?.contract?.date,
                                    })}
                                    maxDate={new Date()}
                                />
                            )}
                            rules={{
                                required: t(lang, "edi.common.forms.validations.is_required"),
                                validate: (value) => {
                                    if(value > watch('act.date'))
                                        return t(lang, "roaming.act_form.date_validation");
                                    return true;
                                }
                            }}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors?.contract?.date?.message}
                        </Form.Control.Feedback>

                    </Col>
                    <Col className="d-flex flex-column my-2">
                        <Form.Label>{t(lang, "roaming.act_form_act_comment")}</Form.Label>
                        <Form.Control as="textarea"
                                      rows="8"
                                      {...register('ActComment', {
                                          required: t(lang, "edi.common.forms.validations.is_required")
                                      })}
                                      placeholder={t(lang, "roaming.act_form_act_comment")}
                                      className={classNames('form-control', {
                                          'is-invalid': errors?.ActComment,
                                      })}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors?.ActComment?.message}
                        </Form.Control.Feedback>
                    </Col>
                </Row>
            </Card>
        </>
    )
}

export default ActFormInfo;