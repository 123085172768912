import React from 'react';
import {getTransferAsync} from "../../../../app/store/reducers/cashbox/CashboxReducer";
import CashBoxTransfer from "../../../../components/hippo/cashbox/transfer/CashBoxTransfer";

const ViewTransfer = () => {
    return (
      <CashBoxTransfer
          request={getTransferAsync}
          title={"warehouse.dashboard.items.transfer_title"}
      />
    );
};

export default ViewTransfer;
