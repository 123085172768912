import {FormProvider, useForm} from "react-hook-form";
import {boardChangeName, boardRegister} from "../../../../app/store/reducers/kanban/kanbanReducer";
import {Form} from "react-bootstrap";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import PropTypes from "prop-types";

const BoardForm = ({setLoading, setShow, name, id}) => {

    const t = Translate;
    const lang = useSelector(selectLang);

    const methods = useForm({
        defaultValues: {
            name: name || null
        }
    })

    const onSubmit = ({name}) => {
        setLoading(true)
        if (id) {
            boardChangeName({id, name})
                .then(() => setShow(false))
                .catch((e) => new Error(e))
                .finally(()=> setLoading(false))
        } else {
            boardRegister({name})
                .then(()=> setShow(false))
                .catch((e) => new Error(e))
                .finally(()=> setLoading(false))
        }

    }

    return (
        <FormProvider {...methods}>
            <Form id={'board'} onSubmit={methods.handleSubmit(onSubmit)}>
                <Form.Group>
                    <Form.Label>{t(lang, 'task.board.form.title')}</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={t(lang, 'task.board.form.title.placeholder')}
                        isInvalid={methods?.formState?.errors?.name}
                        {...methods.register('name', {
                            required: t(lang, "task.board.common.forms.validations.is_required")
                        })}
                    />
                    <Form.Control.Feedback type="invalid">
                        {methods?.formState?.errors?.name?.message}
                    </Form.Control.Feedback>
                </Form.Group>
            </Form>
        </FormProvider>
    );
};


export default BoardForm;
