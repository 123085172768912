import {Button, Col, Form, Row} from "react-bootstrap";
import IconButton from "../../../../common/IconButton";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {FormProvider, useForm} from "react-hook-form";
import React, {useEffect, useState} from "react";
import {
    changeFilterOptionsAsync,
    selectPurchaseFilterOptions
} from "../../../../../app/store/reducers/warehouse-operation/purchaseReducer";
import dayjs from "dayjs";
import DatatableHeaderFilter from "../../components/form/DatatableHeaderFilter";


const PurchaseDatatableHeader = ({predefinedFilters}) => {
    const [isOpenFilterCollapse, setIsOpenFilterCollapse] = useState(true);
    const filter = useSelector(selectPurchaseFilterOptions)
    const dispatch = useDispatch()
    const lang = useSelector(selectLang)
    const t = Translate

    const form = useForm({
        defaultValues: {
            date_time_start: filter?.date_time_start && dayjs(filter?.date_time_start, "YYYY-MM-DD HH:mm").toDate(),
            date_time_end: filter?.date_time_end && dayjs(filter?.date_time_end, "YYYY-MM-DD HH:mm").toDate(),
            contractor_id: null,
            status: null,
            warehouse_id: null
        }
    })

    useEffect(async () => {
        if (predefinedFilters && Object.keys(predefinedFilters).length > 0) {
            let params = {...predefinedFilters};

            Object.keys(params).forEach(key => {
                form.setValue(key, params[key]);
            });
        }
    }, [predefinedFilters])

    const onSubmit = (formData) => {
        dispatch(changeFilterOptionsAsync({
            ...filter,
            date_time_start: formData?.date_time_start && dayjs(formData?.date_time_start).format("YYYY-MM-DD HH:mm:ss"),
            date_time_end: formData?.date_time_end && dayjs(formData?.date_time_end).format("YYYY-MM-DD HH:mm:ss"),
            is_approved: formData?.status,
            contractor_id: formData?.contractor_id,
            warehouse_id: formData?.warehouse_id?.id,
        }))
    }

    return(
        <>
            <Row>
                <Col xs={12}>
                    <FormProvider {...form}>
                        <Form id={'purchase'} onSubmit={form.handleSubmit(onSubmit)}>
                            <DatatableHeaderFilter predefinedFilters={predefinedFilters} name={t(lang, "warehouse.operation.item.common.list_coming")} contractor isOpenFilterCollapse={isOpenFilterCollapse} />
                        </Form>
                    </FormProvider>
                </Col>
                <Col xs={12} className="d-flex justify-content-end">
                    <div>
                        <IconButton
                            size="sm"
                            variant="falcon-primary"
                            icon="fa-plus"
                            as={Link}
                            to={'/warehouse/operation/purchases/register'}
                        >{t(lang, "warehouse.operation.item.common.new_coming")}</IconButton>
                        <IconButton
                            size="sm"
                            variant="falcon-success"
                            icon="fa-plus"
                            className="mx-2"
                            as={Link}
                            to={'/warehouse/operation/purchases/register-excel'}
                        >{t(lang,"Новая приход с Excel")}</IconButton>
                        <IconButton
                            onClick={() => setIsOpenFilterCollapse(!isOpenFilterCollapse)}
                            variant={isOpenFilterCollapse ? "falcon-success" : "falcon-default"}
                            size="sm"
                            className="ms-2"
                            icon="filter"
                            transform="shrink-3"
                        >
                            <span className="d-none d-sm-inline-block ms-1">{t(lang, "warehouse.operation.item.common.filter")}</span>
                        </IconButton>
                        <Button size={'sm'} form={'purchase'} type="submit" variant="primary" className={'ms-2'}>
                            {t(lang, 'warehouse.operation.item.common.filter.search')}
                        </Button>
                    </div>
                </Col>
            </Row>
        </>
    )
}


export default PurchaseDatatableHeader;
