import {createSlice} from '@reduxjs/toolkit'
import shipmentService from '../../../../services/shipmentService'
import EventBus from "../../../eventbus/EventBus";
import {
    CUSTOMER_APPROVE_SHIPMENT_FAILED,
    CUSTOMER_APPROVE_SHIPMENT_REQUESTED,
    CUSTOMER_APPROVE_SHIPMENT_SUCCESS,
    CUSTOMER_DECLINE_SHIPMENT_FAILED,
    CUSTOMER_DECLINE_SHIPMENT_REQUESTED,
    CUSTOMER_DECLINE_SHIPMENT_SUCCESS,
    EDIT_SHIPMENT_FAILED,
    EDIT_SHIPMENT_REQUESTED,
    EDIT_SHIPMENT_SUCCEED,
    CUSTOMER_EDITED_SHIPMENT,
    EXECUTOR_APPROVE_SHIPMENT_FAILED,
    EXECUTOR_APPROVE_SHIPMENT_REQUESTED,
    EXECUTOR_APPROVE_SHIPMENT_SUCCESS,
    REGISTER_SHIPMENT_FAILED,
    REGISTER_SHIPMENT_REQUESTED,
    REGISTER_SHIPMENT_SUCCEED, DELETE_SHIPMENT_REQUESTED, DELETE_SHIPMENT_SUCCESS, DELETE_SHIPMENT_FAILED
} from "../../../eventbus/shipmentEvents";
import {createPkcs7WithTimestamp} from "../eimzo/eimzoReducer";
import {Translate} from "../main/mainReducer";
import {toast} from "react-toastify";
import {AwaitExecutorApprove} from "../../../../enum/ShipmentStatus";
import {EDI_VIEWER_CUSTOMER, EDI_VIEWER_EXECUTOR} from "../../../../enum/edi/ediViewer";


export const STORAGE_SHIPMENT = "ShipmentsDataTableSettings"
const STORAGE_PRODUCT_CATALOGS = "productCatalogsShipment";
const t = Translate;

export const shipmentSlice = createSlice({
    name: 'shipment',
    initialState: {
        shipments: [],
        countShipments: 0,
        cars: [],
        drivers: [],
        carSerials: [],
        filterOptions: {page: 1, limit: 10},
        shipmentsDataTableSettings: {},
        isLoading: false
    },
    reducers: {
        updateShipments: (state, action) => {
            state.shipments = action.payload
        },
        updateCountShipments: (state, action) => {
            state.countShipments = action.payload
        },
        updateFilterOptions: (state, action) => {
            state.filterOptions = {...state.filterOptions, ...action.payload}
        },

        loadProviders: (state) => {
            const storage = JSON.parse(localStorage.getItem('shipmentProvidersInfo'));

            if (storage?.['drivers']) {
                state.drivers = storage['drivers'];
            }

            if (storage?.['cars']) {
                state.cars = storage['cars'];
            }

            if (storage?.['carSerials']) {
                state.carSerials = storage['carSerials'];
            }
        },

        saveProviders: (state) => {
            const data = {
                cars: state.cars,
                drivers: state.drivers,
                carSerials: state.carSerials,
            };
            localStorage.setItem('shipmentProvidersInfo', JSON.stringify(data))
        },

        loadDataTableColumn: (state) => {
            let storageColumn = JSON.parse(localStorage.getItem(STORAGE_SHIPMENT))
            if(!storageColumn) {
                storageColumn = {
                    index: true,
                    number: true,
                    date: true,
                    total_sum: true,
                    branch: true,
                    invoice: true,
                    contractor: true
                };
                localStorage.setItem(STORAGE_SHIPMENT, JSON.stringify(storageColumn))
            }
            state.shipmentsDataTableSettings = storageColumn
        },

        changeDataTableColumn: (state, action) => {
            state.shipmentsDataTableSettings = action.payload
            localStorage.setItem(STORAGE_SHIPMENT, JSON.stringify(state.shipmentsDataTableSettings))
        },
        setLoading: (state, action) => {
            state.isLoading = action.payload;
        },
    }
})

export const registerShipmentAsync = (payload) => {
    EventBus.dispatch(REGISTER_SHIPMENT_REQUESTED);

    return new Promise((resolve, reject) => {
        shipmentService.registerShipment(payload)
            .then(response => {
                EventBus.dispatch(REGISTER_SHIPMENT_SUCCEED, response.data)
                resolve(response.data);
            }).catch((e) => {
            EventBus.dispatch(REGISTER_SHIPMENT_FAILED)
            reject(e)
        })
    })
}

export const printShipmentAsync = (id) => {
    return new Promise((resolve, reject) => {
        shipmentService.printShipment(id)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
    })
}

export const editShipmentAsync = (id,payload) => {
    EventBus.dispatch(EDIT_SHIPMENT_REQUESTED);

    return new Promise((resolve, reject) => {
        shipmentService.editShipment(id, payload).then(response => {
            EventBus.dispatch(EDIT_SHIPMENT_SUCCEED, response.data);
            resolve(response);
        }).catch(error => {
            EventBus.dispatch(EDIT_SHIPMENT_FAILED)
            reject(error);
        });
    })
}

export const shipmentCustomerEditAsync = (id,payload) => {
    return new Promise((resolve, reject) => {
        shipmentService.shipmentCustomerEdit(id, payload).then(response => {
            EventBus.dispatch(CUSTOMER_EDITED_SHIPMENT, response.data);
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    })
}

export const shipmentDeleteAsync = ({id, reason, comment}) => {
    EventBus.dispatch(DELETE_SHIPMENT_REQUESTED, {id, reason, comment});

    return new Promise((resolve, reject) => {
        shipmentService.deleteShipment({id, reason, comment})
            .then((response) => {
                EventBus.dispatch(DELETE_SHIPMENT_SUCCESS, response.data);
                resolve(response.data);
            })
            .catch((error) => {
                EventBus.dispatch(DELETE_SHIPMENT_FAILED, error.message);
                reject(error)
            })
    });
};

export const loadShipments = (viewer,{page, limit, ...filters}) => async (dispatch) => {

    try {
        const skip = (page - 1) * limit
        const params = {skip, limit, ...filters}
        dispatch(shipmentSlice.actions.setLoading(true))

        let shipments = []

        switch (viewer) {
            case EDI_VIEWER_CUSTOMER:
                shipments = (await shipmentService.getShipmentsCustomer(params)).data
                break;
            case EDI_VIEWER_EXECUTOR:
                shipments = (await shipmentService.getShipmentsExecutor(params)).data
                break
            default:
                shipments = (await shipmentService.getShipments(params)).data
                break
        }

        dispatch(shipmentSlice.actions.updateShipments(shipments))
        dispatch(shipmentSlice.actions.setLoading(false))

    } catch (e) {
        console.log(e)
    }
}

export const loadShipmentAsync = (id) => {
    return new Promise((resolve, reject) => {
        shipmentService.getShipment(id)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    })
}

export const customerShipmentApproveAsync = ({id, comment}) => {
    EventBus.dispatch(CUSTOMER_APPROVE_SHIPMENT_REQUESTED, {id, comment})

    return new Promise((resolve, reject) => {
        shipmentService.customerShipmentApprove({id, comment})
            .then(response => {
                EventBus.dispatch(CUSTOMER_APPROVE_SHIPMENT_SUCCESS, response.data)
                resolve(response.data);
            })
            .catch(error => {
                EventBus.dispatch(CUSTOMER_APPROVE_SHIPMENT_FAILED, error)
                reject(error)
            })
    });
}

export const loadCountShipments = (viewer, params) => async (dispatch) => {

    try {
        let count = 0
        let {limit, page, ...others} = params

        switch (viewer) {
            case EDI_VIEWER_CUSTOMER:
                count = (await shipmentService.getCustomerCountShipments(others)).data
                break;
            case EDI_VIEWER_EXECUTOR:
                count = (await shipmentService.getExecutorCountShipments(others)).data
                break;
            default:
                count = (await  shipmentService.getCountShipments(others)).data
        }

        dispatch(shipmentSlice.actions.updateCountShipments(count))
    } catch (e) {
        console.log(e)
    }
}

export const changeFilterOptionsAsync = (params) => (dispatch) => {
    return dispatch(shipmentSlice.actions.updateFilterOptions(params))
}

export const executorApproveShipmentAsync = (certificate, lang, {id, comment}) => {
    EventBus.dispatch(EXECUTOR_APPROVE_SHIPMENT_REQUESTED, {id, comment});
    let errorResponse;

    return new Promise((resolve, reject) => {
        shipmentService.executorApproveShipmentHashCode(id)
            .then(({data: hashCode}) => {
                createPkcs7WithTimestamp(hashCode, certificate.keyId)
                    .then(({pkcs7: signature}) => {
                        shipmentService.executorApproveShipment({id, comment, signature})
                            .then((response) => {
                                EventBus.dispatch(EXECUTOR_APPROVE_SHIPMENT_SUCCESS, response.data);
                                resolve(response.data);
                            })
                            .catch((error) => {
                                EventBus.dispatch(EXECUTOR_APPROVE_SHIPMENT_FAILED, error);
                                toast.error(error.response.data?.roaming_error_message, lang)
                                reject(error)
                            });
                    })
                    .catch(() => {
                        errorResponse = {message: t(lang, "roaming.empowerment.alert.error.createPkcs7Async")}
                        toast.error(t(lang, "roaming.empowerment.alert.error.createPkcs7Async"))
                        EventBus.dispatch(EXECUTOR_APPROVE_SHIPMENT_FAILED, errorResponse);
                        reject(errorResponse)
                    })
            })
            .catch(() => {
                errorResponse = {message: t(lang, "roaming.empowerment.alert.error.getAcceptHashCode")}
                toast.error(t(lang, "roaming.empowerment.alert.error.getAcceptHashCode"))
                EventBus.dispatch(EXECUTOR_APPROVE_SHIPMENT_FAILED, errorResponse);
                reject(errorResponse)
            })
    });
};

export const customerDeclineShipmentAsync = ({id, reason, comment}) => {
    EventBus.dispatch(CUSTOMER_DECLINE_SHIPMENT_REQUESTED, {id, reason});

    return new Promise((resolve, reject) => {
        shipmentService.customerDeclineShipment({id, reason, comment})
            .then((response) => {
                EventBus.dispatch(CUSTOMER_DECLINE_SHIPMENT_SUCCESS, response.data);
                resolve(response.data);
            })
            .catch((error) => {
                EventBus.dispatch(CUSTOMER_DECLINE_SHIPMENT_FAILED, error);
                reject(error);
            })
    });
};

export const uploadShipmentToExcelAsync = ({uri, id}) => {
    return new Promise((resolve, reject) => {
        shipmentService.uploadShipmentToExcel({uri, id})
            .then(response => resolve(response.data))
            .catch(error => reject(error))
    })
};
export const uploadShipmentsToExcelAsync = async (viewer, {uri, page, limit, ...filters}) => {
    try {
        let response

        const skip = (page - 1) * limit;
        const params = {skip, limit, ...filters};
        switch (viewer) {
            case EDI_VIEWER_EXECUTOR:
                 response = await shipmentService.uploadExecutorShipmentsToExcel({params: params});
                 break;
            case EDI_VIEWER_CUSTOMER:
                 response = await shipmentService.uploadCustomerShipmentsToExcel({params: params});
                 break;
            default :
                 response = await shipmentService.uploadShipmentsToExcel({uri: uri, params: params});
                 break;
        }
        return response.data
    } catch (e) {
        console.log(e)
    }
};

export const uploadSelectedShipmentsToExcelAsync = async (viewer, selectedShipmentsIDs) => {
    try {
        let response
        switch (viewer) {
            case EDI_VIEWER_EXECUTOR:
               response = await shipmentService.uploadSelectedExecutorShipmentsToExcel(selectedShipmentsIDs);
                break;
            case EDI_VIEWER_CUSTOMER:
                response = await shipmentService.uploadSelectedCustomerShipmentsToExcel(selectedShipmentsIDs);
                break;
            default :
                response = await shipmentService.uploadSelectedShipmentsToExcel(selectedShipmentsIDs);
                break
        }
        return response.data
    } catch (e) {
        console.log(e)
    }
}

export const uploadSelectedShipmentsWithItemsToExcelAsync = async (viewer, selectedShipmentsIDs) => {

    try {
        let response
        switch (viewer) {
            case EDI_VIEWER_EXECUTOR:
                response = await shipmentService.uploadSelectedExecutorShipmentsWithItemsToExcel(selectedShipmentsIDs);
                break;
            case EDI_VIEWER_CUSTOMER:
                response = await shipmentService.uploadSelectedCustomerShipmentsWithItemsToExcel(selectedShipmentsIDs);
                break;
            default :
                response = await shipmentService.uploadSelectedShipmentsWithItemsToExcel(selectedShipmentsIDs);
                break
        }
        return response.data
    } catch (e) {
        console.log(e)
    }

}

export const uploadShipmentsWithItemsToExcelAsync = async (viewer, {uri, page, limit, ...filters}) => {

    try {
        let response

        const skip = (page - 1) * limit;
        const params = {skip, limit, ...filters};

        switch (viewer) {
            case EDI_VIEWER_EXECUTOR:
                response = await shipmentService.uploadExecutorShipmentsWithItemsToExcel({params: params});
                break;
            case EDI_VIEWER_CUSTOMER:
                response = await shipmentService.uploadCustomerShipmentsWithItemsToExcel({params: params});
                break;
            default :
                response = await shipmentService.uploadShipmentsWithItemsToExcel({uri: uri, params: params});
                break
        }
        return response.data
    } catch (e) {
        console.log(e)
    }
};

export const saveShipmentProductCatalogs = (items) => {
    let storageProductCatalogs = {}

    const storageValue = localStorage.getItem(STORAGE_PRODUCT_CATALOGS)
    if (storageValue) {
        storageProductCatalogs = JSON.parse(storageValue)
    }

    items.forEach((item) => {
        storageProductCatalogs[item.product] = {
            catalogClassCode: item.catalogClassCode,
            packageCode: item.packageCode,
        }
    });
    localStorage.setItem(STORAGE_PRODUCT_CATALOGS, JSON.stringify(storageProductCatalogs));
}

export const shipmentLoadProviders = shipmentSlice.actions.loadProviders;
export const shipmentSaveProviders = shipmentSlice.actions.saveProviders;

export const selectShipmentCarOptions = (state) => state.shipment.cars;
export const selectShipmentDriverOptions = (state) => state.shipment.drivers;
export const selectShipmentCarSerialOptions = (state) => state.shipment.carSerials;

export const selectShipments = (state) => state.shipment.shipments
export const selectCountShipments = (state) => state.shipment.countShipments
export const selectFilterOptions = (state) => state.shipment.filterOptions

export const selectShipmentsDataTable = (state) => state.shipment.shipmentsDataTableSettings
export const selectShipmentLoading = (state) => state.shipment.isLoading
export const selectShipmentProductCatalogs = () => JSON.parse(localStorage.getItem(STORAGE_PRODUCT_CATALOGS))
export const {loadDataTableColumn} = shipmentSlice.actions
export const {changeDataTableColumn} = shipmentSlice.actions

export default shipmentSlice.reducer
