import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {currencyGetAsync, selectCurrency} from "../../app/store/reducers/currency/currencyReducer";
import CurrencySelectForm from "./CurrencySelectForm";
import {FormProvider, useForm} from "react-hook-form";
import {Form} from "react-bootstrap";
import EventBus from "../../app/eventbus/EventBus";
import {
    CURRENCY_ACTIVATE_FAILED,
    CURRENCY_ACTIVATE_SUCCESS, CURRENCY_CONVERTER_CONVERT_FAILED, CURRENCY_DEACTIVATE_FAILED,
    CURRENCY_DEACTIVATE_SUCCESS, CURRENCY_UPDATE_RATE_FAILED, CURRENCY_UPDATE_RATE_SUCCESS
} from "../../app/eventbus/currencyEvents";
import {selectActiveOrganization} from "../../app/store/reducers/auth/authReducer";
import {toast} from "react-toastify";
import {selectLang, Translate} from "../../app/store/reducers/main/mainReducer";


const CurrenciesDataTable = () => {
    const activeOrganization = useSelector(selectActiveOrganization)
    const currency = useSelector(selectCurrency)
    const dispatch = useDispatch()
    const lang = useSelector(selectLang)
    const t = Translate
    const methods = useForm({
        defaultValues: {
            currency
        }
    })


    useEffect(() => {
        loadCurrency()
    },[activeOrganization])

    const loadCurrency = () => {
        dispatch(currencyGetAsync())
    }

    useEffect(() => {
        const onCurrencyActivateSuccessHandler = EventBus.on(CURRENCY_ACTIVATE_SUCCESS, () => {
            loadCurrency()
            toast.success(t(lang,"main.common.activate"))
        })

        const onCurrencyActivateFailedHandler = EventBus.on(CURRENCY_ACTIVATE_FAILED, () => {
            toast.error(t(lang,"main.common.error"))
        })

        const onCurrencyUpdateRateSuccessHandler = EventBus.on(CURRENCY_UPDATE_RATE_SUCCESS, () => {
            loadCurrency()
            toast.success(t(lang,"edi.common.toast.success"))
        })

        const onCurrencyUpdateRateFailedHandler = EventBus.on(CURRENCY_UPDATE_RATE_FAILED, rate => {
            if (rate.status === 409) {
                toast.error(t(lang,"main.common.conflict"))
            } else {
                toast.error(t(lang,"main.common.error"))
            }
        })

        const onCurrencyDeActivateSuccessHandler = EventBus.on(CURRENCY_DEACTIVATE_SUCCESS, () => {
            loadCurrency()
            toast.success(t(lang,"main.common.deactivate"))
        })

        const onCurrencyDeActivateFailedHandler = EventBus.on(CURRENCY_DEACTIVATE_FAILED, () => {
            toast.error(t(lang,"main.common.error"))
        })

        const onCurrencyConverterConvertFailedHandler = EventBus.on(CURRENCY_CONVERTER_CONVERT_FAILED, () => {
            toast.error(t(lang,"main.common.error"))
        })


        return () => {
            EventBus.remove(CURRENCY_ACTIVATE_SUCCESS, onCurrencyActivateSuccessHandler)
            EventBus.remove(CURRENCY_ACTIVATE_FAILED, onCurrencyActivateFailedHandler)
            EventBus.remove(CURRENCY_UPDATE_RATE_SUCCESS, onCurrencyUpdateRateSuccessHandler)
            EventBus.remove(CURRENCY_UPDATE_RATE_FAILED, onCurrencyUpdateRateFailedHandler)
            EventBus.remove(CURRENCY_DEACTIVATE_SUCCESS, onCurrencyDeActivateSuccessHandler)
            EventBus.remove(CURRENCY_DEACTIVATE_FAILED, onCurrencyDeActivateFailedHandler)
            EventBus.remove(CURRENCY_CONVERTER_CONVERT_FAILED, onCurrencyConverterConvertFailedHandler)
        }
    },[])


    return(
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit()}>
                <CurrencySelectForm />
            </Form>
        </FormProvider>
    )
}


export default CurrenciesDataTable;
