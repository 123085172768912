import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as PropTypes from "prop-types";
import Flex from "../../../common/Flex";
import classNames from "classnames";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";

const ModalMediaContent = ({
                               children,
                               icon,
                               transform,
                               title,
                               headingClass,
                               headingContent,
                               isHr = true
                           }) => {

    const t = Translate;
    const lang = useSelector(selectLang);

    return (
        <Flex>
            <span className="fa-stack ms-n1 me-3"><FontAwesomeIcon icon="circle" className="text-200 fa-stack-2x"/><FontAwesomeIcon
                icon={icon}
                transform={`shrink-2 ${transform}`}
                className="text-primary fa-stack-1x"
                inverse
            />
            </span>
            <div className="flex-1">
                <Flex className={classNames('mb-2', headingClass)}>
                    <h5 className="mb-0 fs-0">{t(lang,title)}</h5>
                    {headingContent && headingContent}
                </Flex>
                {children}
                {isHr && <hr className="my-4"/>}
            </div>
        </Flex>
    );
};

ModalMediaContent.propTypes = {
    title: PropTypes.string.isRequired,
    // icon: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired | PropTypes.string,
    children: PropTypes.node.isRequired,
    headingClass: PropTypes.string,
    headingContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    transform: PropTypes.string,
    isHr: PropTypes.bool
};

export default ModalMediaContent;
