import {useDispatch, useSelector} from "react-redux";
import AdvanceTableWrapper from "../../../../common/advance-table/AdvanceTableWrapper";
import {Card, Dropdown, OverlayTrigger, Popover} from "react-bootstrap";
import AdvanceTable from "../../../../common/advance-table/AdvanceTable";
import AdvanceTableCustomPagination from "../../../../common/advance-table/AdvanceTableCustomPagination";
import {selectActiveOrganization} from "../../../../../app/store/reducers/auth/authReducer";
import {selectLang, selectNumberFormat, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {
    changeFilterOptionsAsync, getRefundsAsync, getRefundsCountAsync,
    selectRefundCount,
    selectRefundFilterOptions,
    selectRefunds
} from "../../../../../app/store/reducers/warehouse-operation/refundReducer";
import SoftBadge from "../../../../common/SoftBadge";
import numeral from "numeral";
import dayjs from "dayjs";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useEffect} from "react";
import RefundDatatableHeader from "./RefundDatatableHeader";
import {
    isApprovedCheckedWarehouseOperation,
    ItemsTotalSumGroupCurrencyId,
    ItemsTotalSumPayoutDebtGroupPaymentTypeCurrencyId,
    ItemsTotalSumPayoutDebtStatesGroupPaymentTypeCurrencyId,
    OperationInfoPaymentType,
    OperationTotalSumInfo,
    OperationTotalSumInfoPayment
} from "../../enum/warehouseOperationWrapper";
import {
    GetPaymentTypeText, PaymentTypeBankTransferCode,
    PaymentTypeCashCode, PaymentTypeClickCode,
    PaymentTypeHumoCode, PaymentTypePaymeCode,
    PaymentTypeUzCardCode, PaymentTypeVisaCode
} from "../../../../../enum/ContractorWrapper";
import EventBus from "../../../../../app/eventbus/EventBus";
import {WAREHOUSE_OPERATION_REFUND_DELETE_SUCCESS} from "../../../../../app/eventbus/warehouse-operation/refundEvents";
import RefundDelete from "../components/RefundDelete";
import CardDropdown from "../../../../common/CardDropdown";
import IconButton from "../../../../common/IconButton";
import {faFileExcel, faFilePdf, faPrint} from "@fortawesome/free-solid-svg-icons";
import UploadRefundToExcel from "../excel/UploadRefundToExcel";
import DownloadRefundDropdown from "../components/DownloadRefundDropdown";
import PrintRefundDropdown from "../components/PrintRefundDropdown";


const RefundDatatable = () => {
    const activeOrganization = useSelector(selectActiveOrganization)
    const numberFormat = useSelector(selectNumberFormat)
    const refunds = useSelector(selectRefunds)
    const dispatch = useDispatch()
    const filters = useSelector(selectRefundFilterOptions)
    const count = useSelector(selectRefundCount)
    const lang = useSelector(selectLang)
    const t = Translate


    const totalSumArray = ItemsTotalSumGroupCurrencyId(refunds)
    const totalSumCashBoxPaymentType = ItemsTotalSumPayoutDebtStatesGroupPaymentTypeCurrencyId(refunds)
    const totalSumDebtsPaymentType = ItemsTotalSumPayoutDebtGroupPaymentTypeCurrencyId(refunds)

    const debtTypeCurrency = []

    // debt calc
    {
        totalSumArray?.forEach(itemPrices => {
            if (itemPrices.currency_id) {
                const foundDebtState = totalSumDebtsPaymentType?.find(x => x.currency_id === itemPrices.currency_id);
                const totalSum = foundDebtState ? itemPrices.totalSum - foundDebtState.totalSum : itemPrices.totalSum;

                debtTypeCurrency.push({
                    id: itemPrices.currency_id,
                    name: itemPrices.name,
                    totalSum: totalSum
                });
            }
        });

        totalSumDebtsPaymentType?.forEach(itemPrices => {
            if (itemPrices.currency_id) {
                const foundSeparationCurrency = totalSumArray.find(separationCurrency => separationCurrency.currency_id === itemPrices.currency_id);
                const totalSum = foundSeparationCurrency ? itemPrices.totalSum - foundSeparationCurrency.totalSum : itemPrices.totalSum;

                if (!foundSeparationCurrency) {
                    debtTypeCurrency.push({
                        id: itemPrices.currency_id,
                        name: itemPrices.name,
                        totalSum: totalSum
                    });
                }
            }
        });
    }


    const columns = [
        {
            Header: "#",
            id: "row",
            filterable: false,
            cellProps: {
                width: '40px',
            },
            Cell: ({row}) => {
                return <>
                    <OverlayTrigger trigger={['hover', 'focus']}
                                    placement={'bottom'}
                                    overlay={
                                        <Popover id="popover-basic" className='mt-0'>
                                            <Popover.Header>
                                                <SoftBadge className={'d-block text-center fs--1'}
                                                           bg={row?.original?.is_approved ? 'success' : 'warning'}>{t(lang, isApprovedCheckedWarehouseOperation(row?.original?.is_approved))}</SoftBadge>
                                            </Popover.Header>
                                        </Popover>
                                    }
                    >
                        <SoftBadge className={'d-block text-center fs--1 cursor-pointer'}
                                   bg={row?.original?.is_approved ? 'success' : 'warning'}>
                            {(filters.page - 1) * filters.limit + row.index + 1}
                        </SoftBadge>
                    </OverlayTrigger>
                </>
            }
        },
        {
            accessor: 'number',
            Header: t(lang, "Номер"),
            Cell: ({row: {original}}) => {
                return (
                    <Link to={`./refunds/view/${original?.id}`} className="d-flex text-wrap">
                        {original?.number}
                    </Link>
                )
            }
        },
        {
            accessor: 'contractor.name',
            Header: t(lang, "warehouse.operation.item.common.client"),
            totalInfo: `${t(lang, "Итого:")}`,
            totalInfoClassName: 'fs-1 fw-bold text-nowrap text-end',
            cellProps: {
                maxwidth: "250px",
                minwidth: "250px"
            },
            Cell: ({row: {original}}) => {
                return (
                    <>
                        <Link to={`../../crm/contractor-card/${original?.contractor?.id}`} className="d-flex text-wrap"
                              style={{maxWidth: "250px", minWidth: "250px"}}>
                            {original?.contractor?.name}
                        </Link>
                    </>
                )
            }
        },
        {
            Header: t(lang, "warehouse.operation.item.common.total_sum"),
            headerProps: {
                className: "text-end"
            },
            cellProps: {
                className: "text-end"
            },
            totalInfo: totalSumArray?.map((totalSum, index) => {
                return (
                    <div key={index}>
                        <OverlayTrigger trigger={['hover', 'focus']}
                                        placement={'bottom'}
                                        overlay={
                                            <Popover id="popover-basic" className='mt-0'>
                                                <Popover.Header>
                                                    <SoftBadge className={'d-block text-center fs--1'}
                                                               bg="info">{t(lang, "warehouse.operation.item.common.total_sum")}</SoftBadge>
                                                </Popover.Header>
                                            </Popover>
                                        }
                        >
                            <SoftBadge bg="info" className="cursor-pointer">
                                {numeral.formats[numberFormat].format(totalSum?.totalSum)}
                                &nbsp;
                                <span className="fw-bold">{totalSum?.name}</span>
                            </SoftBadge>
                        </OverlayTrigger>
                    </div>
                )
            }),
            totalInfoClassName: "fw-bold fs-1 text-end text-nowrap",
            Cell: ({row: {original}}) => {
                const separationCurrencyCalc = OperationTotalSumInfo(original?.items?.filter(f => !f?.is_deleted))


                return separationCurrencyCalc?.map((item, index) => {
                    return (
                        <div className={'fs-0'} key={index}>
                            <SoftBadge className={'d-block text-end mb-1 flex-fill'} bg={"info"}>
                                {numeral.formats[numberFormat].format(item?.totalSum)}
                                &nbsp;
                                {item?.name}
                            </SoftBadge>
                        </div>
                    )
                })
            }
        },
        {
            Header: t(lang, GetPaymentTypeText(PaymentTypeCashCode)),
            headerProps: {
                className: "text-end"
            },
            cellProps: {
                className: "text-end"
            },
            totalInfo: totalSumCashBoxPaymentType.map((totalSum, index) => {

                if (totalSum?.payment_type === PaymentTypeCashCode) {
                    return (
                        <div key={index}>
                            <OverlayTrigger trigger={['hover', 'focus']}
                                            placement={'bottom'}
                                            overlay={
                                                <Popover id="popover-basic" className='mt-0'>
                                                    <Popover.Header>
                                                        <SoftBadge className={'d-block text-center fs--1'}
                                                                   bg="success">{t(lang, GetPaymentTypeText(PaymentTypeCashCode))}</SoftBadge>
                                                    </Popover.Header>
                                                </Popover>
                                            }
                            >
                                <SoftBadge bg="success" className="cursor-pointer">
                                    {numeral.formats[numberFormat].format(totalSum?.totalSum)}
                                    &nbsp;
                                    <span className="fw-bold">{totalSum?.name}</span>
                                </SoftBadge>
                            </OverlayTrigger>
                        </div>
                    )
                }
            }),
            totalInfoClassName: "fw-bold fs-1 text-end text-nowrap",
            Cell: ({row: {original}}) => {
                const separationCurrencyCalc = OperationTotalSumInfoPayment(original?.payout?.cash_box_states)


                if (separationCurrencyCalc) {
                    return separationCurrencyCalc?.map((item, index) => {
                        if (PaymentTypeCashCode === item?.payment_type) {
                            return (
                                <div className={'fs-0'} key={index}>
                                    <SoftBadge className={'d-block text-end mb-1 flex-fill'} bg={"success"}>
                                        {numeral.formats[numberFormat].format(item?.totalSum)}
                                        &nbsp;
                                        {item?.name}
                                    </SoftBadge>
                                </div>
                            )
                        }
                        return ""
                    })
                }
                return ""
            }
        },
        {
            Header: t(lang, GetPaymentTypeText(PaymentTypeUzCardCode)),
            headerProps: {
                className: "text-end"
            },
            cellProps: {
                className: "text-end"
            },
            totalInfo: totalSumCashBoxPaymentType.map((totalSum, index) => {

                if (totalSum?.payment_type === PaymentTypeUzCardCode) {
                    return (
                        <div key={index}>
                            <OverlayTrigger trigger={['hover', 'focus']}
                                            placement={'bottom'}
                                            overlay={
                                                <Popover id="popover-basic" className='mt-0'>
                                                    <Popover.Header>
                                                        <SoftBadge className={'d-block text-center fs--1'}
                                                                   bg="success">{t(lang, GetPaymentTypeText(PaymentTypeUzCardCode))}</SoftBadge>
                                                    </Popover.Header>
                                                </Popover>
                                            }
                            >
                                <SoftBadge bg="success" className="cursor-pointer">
                                    {numeral.formats[numberFormat].format(totalSum?.totalSum)}
                                    &nbsp;
                                    <span className="fw-bold">{totalSum?.name}</span>
                                </SoftBadge>
                            </OverlayTrigger>
                        </div>
                    )
                }
            }),
            totalInfoClassName: "fw-bold fs-1 text-end text-nowrap",
            Cell: ({row: {original}}) => {
                const separationCurrencyCalc = OperationInfoPaymentType(original?.payout?.cash_box_states)[PaymentTypeUzCardCode]


                if (separationCurrencyCalc) {

                    return separationCurrencyCalc?.map((item, index) => {
                        if (PaymentTypeUzCardCode === item?.payment_type && item?.totalSum) {
                            return (
                                <div className={'fs-0'} key={index}>
                                    <SoftBadge className={'d-block text-end mb-1 flex-fill'} bg={"success"}>
                                        {numeral.formats[numberFormat].format(item?.totalSum)}
                                        &nbsp;
                                        {item?.name}
                                    </SoftBadge>
                                </div>
                            )
                        }
                        return ""
                    })
                }
                return ""
            }
        },
        {
            Header: t(lang, GetPaymentTypeText(PaymentTypeHumoCode)),
            headerProps: {
                className: "text-end"
            },
            cellProps: {
                className: "text-end"
            },
            totalInfo: totalSumCashBoxPaymentType.map((totalSum, index) => {

                if (totalSum?.payment_type === PaymentTypeHumoCode) {
                    return (
                        <div key={index}>
                            <OverlayTrigger trigger={['hover', 'focus']}
                                            placement={'bottom'}
                                            overlay={
                                                <Popover id="popover-basic" className='mt-0'>
                                                    <Popover.Header>
                                                        <SoftBadge className={'d-block text-center fs--1'}
                                                                   bg="success">{t(lang, GetPaymentTypeText(PaymentTypeHumoCode))}</SoftBadge>
                                                    </Popover.Header>
                                                </Popover>
                                            }
                            >
                                <SoftBadge bg="success" className="cursor-pointer">
                                    {numeral.formats[numberFormat].format(totalSum?.totalSum)}
                                    &nbsp;
                                    <span className="fw-bold">{totalSum?.name}</span>
                                </SoftBadge>
                            </OverlayTrigger>
                        </div>
                    )
                }
            }),
            totalInfoClassName: "fw-bold fs-1 text-end text-nowrap",
            Cell: ({row: {original}}) => {
                const separationCurrencyCalc = OperationInfoPaymentType(original?.payout?.cash_box_states)[PaymentTypeHumoCode]


                if (separationCurrencyCalc) {

                    return separationCurrencyCalc?.map((item, index) => {
                        if (PaymentTypeHumoCode === item?.payment_type && item?.totalSum) {
                            return (
                                <div className={'fs-0'} key={index}>
                                    <SoftBadge className={'d-block text-end mb-1 flex-fill'} bg={"success"}>
                                        {numeral.formats[numberFormat].format(item?.totalSum)}
                                        &nbsp;
                                        {item?.name}
                                    </SoftBadge>
                                </div>
                            )
                        }
                        return ""
                    })
                }
                return ""
            }
        },
        {
            Header: t(lang, GetPaymentTypeText(PaymentTypeBankTransferCode)),
            headerProps: {
                className: "text-end"
            },
            cellProps: {
                className: "text-end"
            },
            totalInfo: totalSumCashBoxPaymentType.map((totalSum, index) => {

                if (totalSum?.payment_type === PaymentTypeBankTransferCode) {
                    return (
                        <div key={index}>
                            <OverlayTrigger trigger={['hover', 'focus']}
                                            placement={'bottom'}
                                            overlay={
                                                <Popover id="popover-basic" className='mt-0'>
                                                    <Popover.Header>
                                                        <SoftBadge className={'d-block text-center fs--1'}
                                                                   bg="success">{t(lang, GetPaymentTypeText(PaymentTypeBankTransferCode))}</SoftBadge>
                                                    </Popover.Header>
                                                </Popover>
                                            }
                            >
                                <SoftBadge bg="success" className="cursor-pointer">
                                    {numeral.formats[numberFormat].format(totalSum?.totalSum)}
                                    &nbsp;
                                    <span className="fw-bold">{totalSum?.name}</span>
                                </SoftBadge>
                            </OverlayTrigger>
                        </div>
                    )
                }
            }),
            totalInfoClassName: "fw-bold fs-1 text-end text-nowrap",
            Cell: ({row: {original}}) => {
                const separationCurrencyCalc = OperationInfoPaymentType(original?.payout?.cash_box_states)[PaymentTypeBankTransferCode]


                if (separationCurrencyCalc) {

                    return separationCurrencyCalc?.map((item, index) => {
                        if (PaymentTypeBankTransferCode === item?.payment_type && item?.totalSum) {
                            return (
                                <div className={'fs-0'} key={index}>
                                    <SoftBadge className={'d-block text-end mb-1 flex-fill'} bg={"success"}>
                                        {numeral.formats[numberFormat].format(item?.totalSum)}
                                        &nbsp;
                                        {item?.name}
                                    </SoftBadge>
                                </div>
                            )
                        }
                        return ""
                    })
                }
                return ""
            }
        },
        {
            Header: t(lang, GetPaymentTypeText(PaymentTypeClickCode)),
            headerProps: {
                className: "text-end"
            },
            cellProps: {
                className: "text-end"
            },
            totalInfo: totalSumCashBoxPaymentType.map((totalSum, index) => {

                if (totalSum?.payment_type === PaymentTypeClickCode) {
                    return (
                        <div key={index}>
                            <OverlayTrigger trigger={['hover', 'focus']}
                                            placement={'bottom'}
                                            overlay={
                                                <Popover id="popover-basic" className='mt-0'>
                                                    <Popover.Header>
                                                        <SoftBadge className={'d-block text-center fs--1'}
                                                                   bg="success">{t(lang, GetPaymentTypeText(PaymentTypeClickCode))}</SoftBadge>
                                                    </Popover.Header>
                                                </Popover>
                                            }
                            >
                                <SoftBadge bg="success" className="cursor-pointer">
                                    {numeral.formats[numberFormat].format(totalSum?.totalSum)}
                                    &nbsp;
                                    <span className="fw-bold">{totalSum?.name}</span>
                                </SoftBadge>
                            </OverlayTrigger>
                        </div>
                    )
                }
            }),
            totalInfoClassName: "fw-bold fs-1 text-end text-nowrap",
            Cell: ({row: {original}}) => {
                const separationCurrencyCalc = OperationInfoPaymentType(original?.payout?.cash_box_states)[PaymentTypeClickCode]


                if (separationCurrencyCalc) {

                    return separationCurrencyCalc?.map((item, index) => {
                        if (PaymentTypeClickCode === item?.payment_type && item?.totalSum) {
                            return (
                                <div className={'fs-0'} key={index}>
                                    <SoftBadge className={'d-block text-end mb-1 flex-fill'} bg={"success"}>
                                        {numeral.formats[numberFormat].format(item?.totalSum)}
                                        &nbsp;
                                        {item?.name}
                                    </SoftBadge>
                                </div>
                            )
                        }
                        return ""
                    })
                }
                return ""
            }
        },
        {
            Header: t(lang, GetPaymentTypeText(PaymentTypePaymeCode)),
            headerProps: {
                className: "text-end"
            },
            cellProps: {
                className: "text-end"
            },
            totalInfo: totalSumCashBoxPaymentType.map((totalSum, index) => {

                if (totalSum?.payment_type === PaymentTypePaymeCode) {
                    return (
                        <div key={index}>
                            <OverlayTrigger trigger={['hover', 'focus']}
                                            placement={'bottom'}
                                            overlay={
                                                <Popover id="popover-basic" className='mt-0'>
                                                    <Popover.Header>
                                                        <SoftBadge className={'d-block text-center fs--1'}
                                                                   bg="success">{t(lang, GetPaymentTypeText(PaymentTypePaymeCode))}</SoftBadge>
                                                    </Popover.Header>
                                                </Popover>
                                            }
                            >
                                <SoftBadge bg="success" className="cursor-pointer">
                                    {numeral.formats[numberFormat].format(totalSum?.totalSum)}
                                    &nbsp;
                                    <span className="fw-bold">{totalSum?.name}</span>
                                </SoftBadge>
                            </OverlayTrigger>
                        </div>
                    )
                }
            }),
            totalInfoClassName: "fw-bold fs-1 text-end text-nowrap",
            Cell: ({row: {original}}) => {
                const separationCurrencyCalc = OperationInfoPaymentType(original?.payout?.cash_box_states)[PaymentTypePaymeCode]


                if (separationCurrencyCalc) {

                    return separationCurrencyCalc?.map((item, index) => {
                        if (PaymentTypePaymeCode === item?.payment_type && item?.totalSum) {
                            return (
                                <div className={'fs-0'} key={index}>
                                    <SoftBadge className={'d-block text-end mb-1 flex-fill'} bg={"success"}>
                                        {numeral.formats[numberFormat].format(item?.totalSum)}
                                        &nbsp;
                                        {item?.name}
                                    </SoftBadge>
                                </div>
                            )
                        }
                        return ""
                    })
                }
                return ""
            }
        },
        {
            Header: t(lang, GetPaymentTypeText(PaymentTypeVisaCode)),
            headerProps: {
                className: "text-end"
            },
            cellProps: {
                className: "text-end"
            },
            totalInfo: totalSumCashBoxPaymentType.map((totalSum, index) => {

                if (totalSum?.payment_type === PaymentTypeVisaCode) {
                    return (
                        <div key={index}>
                            <OverlayTrigger trigger={['hover', 'focus']}
                                            placement={'bottom'}
                                            overlay={
                                                <Popover id="popover-basic" className='mt-0'>
                                                    <Popover.Header>
                                                        <SoftBadge className={'d-block text-center fs--1'}
                                                                   bg="success">{t(lang, GetPaymentTypeText(PaymentTypeVisaCode))}</SoftBadge>
                                                    </Popover.Header>
                                                </Popover>
                                            }
                            >
                                <SoftBadge bg="success" className="cursor-pointer">
                                    {numeral.formats[numberFormat].format(totalSum?.totalSum)}
                                    &nbsp;
                                    <span className="fw-bold">{totalSum?.name}</span>
                                </SoftBadge>
                            </OverlayTrigger>
                        </div>
                    )
                }
            }),
            totalInfoClassName: "fw-bold fs-1 text-end text-nowrap",
            Cell: ({row: {original}}) => {
                const separationCurrencyCalc = OperationInfoPaymentType(original?.payout?.cash_box_states)[PaymentTypeVisaCode]


                if (separationCurrencyCalc) {

                    return separationCurrencyCalc?.map((item, index) => {
                        if (PaymentTypeVisaCode === item?.payment_type && item?.totalSum) {
                            return (
                                <div className={'fs-0'} key={index}>
                                    <SoftBadge className={'d-block text-end mb-1 flex-fill'} bg={"success"}>
                                        {numeral.formats[numberFormat].format(item?.totalSum)}
                                        &nbsp;
                                        {item?.name}
                                    </SoftBadge>
                                </div>
                            )
                        }
                        return ""
                    })
                }
                return ""
            }
        },
        {
            Header: t(lang, "warehouse.operation.item.common.debt_state"),
            headerProps: {
                className: "text-end"
            },
            cellProps: {
                className: "text-end"
            },

            totalInfo: debtTypeCurrency?.map((x, index) => {
                if (x.totalSum) {
                    return (
                        <div key={index}>
                            <OverlayTrigger trigger={['hover', 'focus']}
                                            placement={'bottom'}
                                            overlay={
                                                <Popover id="popover-basic" className='mt-0'>
                                                    <Popover.Header>
                                                        <SoftBadge className={'d-block text-center fs--1'}
                                                                   bg={x?.totalSum > 0 ? "danger" : "warning"}>
                                                            {x?.totalSum > 0 ?
                                                                t(lang, "warehouse.operation.item.common.debt_state")
                                                                :
                                                                t(lang, "Я должен заплатить")
                                                            }
                                                        </SoftBadge>
                                                    </Popover.Header>
                                                </Popover>
                                            }
                            >
                                <SoftBadge bg={x?.totalSum > 0 ? "danger" : "warning"} className="cursor-pointer">
                                    {numeral.formats[numberFormat].format(x.totalSum)}
                                    &nbsp;
                                    <span className="fw-bold">{x.name}</span>
                                </SoftBadge>
                            </OverlayTrigger>
                        </div>
                    )
                }
            }),
            totalInfoClassName: "fw-bold fs-1 text-end text-nowrap",
            Cell: ({row: {original}}) => {
                const debtTypeCurrency = []

                // debt calc
                {
                    original?.net_price?.forEach(price => {
                        if (price?.currency?.id) {
                            const foundDebtState = original?.payout?.debt_states?.find(debtState => debtState.currency.id === price?.currency?.id);
                            const totalSum = foundDebtState ? price?.amount - foundDebtState.amount : price?.amount;

                            debtTypeCurrency.push({
                                id: price?.currency?.id,
                                name: price?.currency?.name,
                                totalSum: totalSum
                            });
                        }
                    });

                    original?.payout?.debt_states?.forEach(debt => {
                        if (debt.currency.id) {
                            const foundSeparationCurrency = original?.net_price?.find(price => price?.currency?.id === debt.currency.id);
                            const totalSum = foundSeparationCurrency ? debt.amount - foundSeparationCurrency.totalSum : debt.amount;

                            if (!foundSeparationCurrency) {
                                debtTypeCurrency.push({
                                    id: debt.currency.id,
                                    name: debt.currency.name,
                                    totalSum: totalSum
                                });
                            }
                        }
                    });
                }


                if (debtTypeCurrency?.length) {
                    return(
                        debtTypeCurrency?.map((x, index) => {
                            if(x.totalSum) {
                                return (
                                    <div className={'fs-0'} key={index}>
                                        <SoftBadge className={'d-block text-end mb-1 flex-fill'} bg={x?.totalSum > 0 ? "danger" : "warning"}>
                                            {numeral.formats[numberFormat].format(x?.totalSum)}
                                            &nbsp;
                                            {x?.name}
                                        </SoftBadge>
                                    </div>
                                )
                            }
                        })
                    )
                }
                return ''
            }
        },
        {
            accessor: 'account.username',
            Header: t(lang, "Продавец"),
            headerProps: {
                className: "text-end"
            },
            cellProps: {
                className: "text-end"
            },
            Cell: ({row: {original}}) => {
                return (
                    <div className="d-flex justify-content-end">
                        <span className="d-flex text-wrap">{original?.account?.name || original?.account?.username}</span>
                    </div>
                )
            }
        },
        {
            accessor: 'note',
            Header: t(lang, "Информация"),
            headerProps: {
                className: "text-end"
            },
            cellProps: {
                className: "text-end"
            },
            Cell: ({row: {original}}) => {
                return (
                    <div className="d-flex justify-content-end" style={{maxWidth: "250px", minWidth: "250px"}}>
                        <span className="d-flex text-wrap">{original?.note}</span>
                    </div>
                )
            }
        },
        {
            accessor: 'created_at',
            Header: t(lang, "warehouse.operation.item.common.date_refund"),
            headerProps: {
                className: "text-end"
            },
            cellProps: {
                className: "text-end"
            },
            Cell: ({row: {original}}) => {
                return (
                    <div className="d-flex flex-column">
                        <span>{dayjs(original?.date).format("YYYY-MM-DD")}</span>
                        <span>{dayjs(original?.date).format("HH:mm:ss")}</span>
                    </div>
                )
            }
        },
        {
            Header: '',
            id: "none",
            cellProps: {
                className: 'text-end'
            },
            Cell: ({row: {original}}) => (
                <div className="d-inline-flex">
                    <DownloadRefundDropdown className={'mx-1'} refundId={original?.id} refundNumber={original?.number}>
                        <FontAwesomeIcon className={'text-primary'} icon={faFilePdf}/>
                    </DownloadRefundDropdown>
                    <PrintRefundDropdown className={'mx-1'} refundId={original?.id}>
                        <FontAwesomeIcon className={'text-primary'} icon={faPrint}/>
                    </PrintRefundDropdown>
                    <Link to={`./refunds/view/${original?.id}`}>
                        <IconButton
                            variant="falcon-info"
                            icon="eye"
                            title={t(lang, "warehouse.operation.item.common.show")}
                            className="mx-2"
                            size="sm"
                        />
                    </Link>
                    <UploadRefundToExcel className={'mx-1'} id={original?.id} number={original?.number}>
                        <FontAwesomeIcon className={'text-success'} icon={faFileExcel}/>
                    </UploadRefundToExcel>
                    <CardDropdown btnRevealClass="btn-reveal-sm">
                        <div className="py-2">
                            <Dropdown.Item as={Link} to={`./refunds/update/${original?.id}`}
                                           className="text-warning">
                                <FontAwesomeIcon className="me-1" size="sm" icon={"pencil"}/>
                                <span>{t(lang, "warehouse.operation.item.common.edit")}</span>
                            </Dropdown.Item>
                            <Dropdown.Item as={Link} to={`./refunds/update-excel/${original?.id}`}
                                           className="text-success">
                                <FontAwesomeIcon className="me-1" size="sm" icon={"pencil"}/>
                                <span>{t(lang, "Редактировать с Excel")}</span>
                            </Dropdown.Item>
                            <RefundDelete id={original?.id}/>
                            <Dropdown.Item as={Link} to={`./refunds/view/${original?.id}`} className="text-info">
                                <FontAwesomeIcon className="me-1" size="sm" icon={"eye"}/>
                                <span>{t(lang, "warehouse.operation.item.common.show")}</span>
                            </Dropdown.Item>
                        </div>
                    </CardDropdown>
                </div>
            )
        }
    ]


    const onPageChange = (page) => dispatch(changeFilterOptionsAsync({...filters, page: page}))

    const onLimitChange = (limit) => dispatch(changeFilterOptionsAsync({...filters, limit: limit, page: 1}))


    const loadRefunds = () => {
        dispatch(getRefundsAsync({...filters}))
        dispatch(getRefundsCountAsync({...filters}))
    }


    useEffect(() => {
        loadRefunds()

        const onRefundDeletedHandler = EventBus.on(WAREHOUSE_OPERATION_REFUND_DELETE_SUCCESS, () => {
            loadRefunds()
        })

        return () => {
            EventBus.remove(WAREHOUSE_OPERATION_REFUND_DELETE_SUCCESS, onRefundDeletedHandler)
        }
    }, [activeOrganization, filters])


    return (
        <>
            <AdvanceTableWrapper
                columns={columns}
                data={refunds}
                pagination
                sortable
                perPage={1000}
                // selection
            >
                <Card className="mb-3">
                    <Card.Header>
                        <RefundDatatableHeader/>
                    </Card.Header>
                    <Card.Body className="p-0">
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-wrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-2 overflow-hidden',
                                style: {
                                    minHeight: '250px'
                                }
                            }}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTableCustomPagination
                            count={count}
                            limit={filters.limit}
                            page={filters.page}
                            onLimitChange={onLimitChange}
                            onPageChange={onPageChange}
                        />
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>
        </>
    )
}


export default RefundDatatable;