import React from 'react';
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {Dropdown} from "react-bootstrap";
import withValidateCatalog from "./withValidateCatalog";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRepeat} from "@fortawesome/free-solid-svg-icons";

const ValidateCatalogDropdownItem = ({id, validateCatalog}) => {
    const t = Translate;
    const lang = useSelector(selectLang);

    return (
        <Dropdown.Item onClick={() => validateCatalog(id)}
                       className="border-bottom align-center"
        >
            <FontAwesomeIcon className="me-1" size="sm" icon={faRepeat} />
            <span>{t(lang, 'items.common.catalog.sync')}</span>
        </Dropdown.Item>
    );
};

export default withValidateCatalog(ValidateCatalogDropdownItem);