import React, {useEffect} from "react";
import {Card} from "react-bootstrap";
import AdvanceTable from "../../common/advance-table/AdvanceTable";
import AdvanceTableWrapper from "../../common/advance-table/AdvanceTableWrapper";
import ContactFormationDataTableHeader from "./ContactFormationDataTableHeader";
import {useDispatch, useSelector} from "react-redux";
import {
    selectLang,
    selectNumberFormat,
    Translate
} from "../../../app/store/reducers/main/mainReducer";
import {
    changeFilterOptionsAsync,
    loadContractsAsync, loadContractsCountAsync, selectContractFormationFilterOptions, selectContractsCount,
    selectContractsFormation
} from "../../../app/store/reducers/contract-formation/contractFormationReducer";
import IconButton from "../../common/IconButton";
import {Link} from "react-router-dom";
import numeral from "numeral";
import SoftBadge from "../../common/SoftBadge";
import {GetStatusText, GetStatusVariant} from "./contract-formation-view/ContractFormationStatus";
import AdvanceTableCustomPagination from "../../common/advance-table/AdvanceTableCustomPagination";
import {selectActiveOrganization} from "../../../app/store/reducers/auth/authReducer";
import checkPermission from "../../../enum/Permission/CheckPermission";
import {
    PermissionContractFormationCreate,
    PermissionContractFormationView
} from "../../../enum/Permission/EdiPermission";
import toastPermission from "../../../enum/Permission/ToastPermission";

const ContactFormationDataTable = ({activeColumns}) => {
    const contractsFormation = useSelector(selectContractsFormation)
    const numberFormat = useSelector(selectNumberFormat);
    const lang = useSelector(selectLang);
    const t = Translate;
    const filters = useSelector(selectContractFormationFilterOptions)
    const count = useSelector(selectContractsCount)
    const dispatch = useDispatch()
    const activeOrganization = useSelector(selectActiveOrganization)


    function getActiveColumns(activeColumns, columns = []) {
        return columns?.filter(column => activeColumns[column?.id])
    }


    const columns = getActiveColumns(activeColumns, [
        {
            Header: "#",
            id: "index",
            filterable: false,
            Cell: ({row}) => {
                return `${((filters.page - 1) * filters.limit) + row.index + 1}`
            }
        },
        {
            accessor: 'info',
            id: "name",
            Header: t(lang, "edi.contract_formation.datatable.header.row.contract"),
            Cell: ({row: {original}}) => {
                return (
                    <>
                        {original.name}
                    </>
                )
            }
        },
        {
            accessor: 'owner',
            id: "contractor",
            Header: t(lang, "edi.contract_formation.datatable.header.row.contractor"),
            Cell: ({row: {original}}) => {
                return (
                    <>
                        {original.customer.name}
                    </>
                )
            }
        },
        {
            accessor: 'status',
            id: "status",
            Header: t(lang, "edi.contract_formation.datatable.header.row.status"),
            Cell: ({row: {original}}) => {

                return <h5>
                    <SoftBadge bg={GetStatusVariant(original.status)}
                               className='me-2 py-2'>{GetStatusText(t(lang, original.status))}</SoftBadge>
                </h5>
            }
        },
        {
            accessor: 'uid',
            id: "uid",
            Header: t(lang, "common.datatable.setting.uid"),
            Cell: ({row: {original}}) => {
                return <span>
                            {original.uid}
                        </span>
            }
        },
        {
            accessor: 'created_at',
            id: "created_date",
            Header: t(lang, "edi.contract_formation.datatable.header.row.created_at"),
        },
        {
            accessor: 'total_sum',
            id: "total_sum",
            Header: t(lang, "edi.contract_formation.datatable.header.row.total_sum"),
            cellProps: {
                className: 'text-end'
            },
            headerProps: {
                className: 'text-end'
            },
            Cell: ({row: {original}}) => {
                return (
                    <strong>
                        {numeral.formats[numberFormat].format((original?.contract?.items?.reduce((total, item) => total + item?.total_with_nds, 0) || 0))}
                    </strong>
                )
            }
        },
        {
            accessor: 'action',
            id: "button_action",
            Header: "",
            disableSortBy: true,
            cellProps: {
                className: 'text-end'
            },
            Cell: ({row: {original}}) => {
                return <>
                    {original.status === 1 &&
                        <IconButton
                            to={`./contract-formation/template/${original.id}`}
                            as={dispatch(checkPermission(PermissionContractFormationCreate)) ? Link : 'div'}
                            onClick={() => !dispatch(checkPermission(PermissionContractFormationCreate)) && dispatch(toastPermission())}
                            variant="falcon-default"
                            size="sm"
                            icon="plus"
                            title={t(lang, "edi.common.button.create")}
                            className="mx-2"
                            transform="shrink-3"
                        />
                    }
                    {original.status !== 1 &&
                        <IconButton
                            to={`./contract-formation/view/${original.id}`}
                            as={dispatch(checkPermission(PermissionContractFormationView)) ? Link : 'div'}
                            onClick={() => !dispatch(checkPermission(PermissionContractFormationView)) && dispatch(toastPermission())}
                            variant="falcon-default"
                            size="sm"
                            icon="eye"
                            title={t(lang, "roaming.dashboard.contracts.description")}
                            className="mx-2"
                            transform="shrink-3"
                        />
                    }
                </>
            }
        }
    ])


    const onPageChange = async (page) => {
        dispatch(changeFilterOptionsAsync({...filters, page: page}))
    }

    const onLimitChange = async (limit) => {
        dispatch(changeFilterOptionsAsync({...filters, limit: limit, page: 1}))
    }


    useEffect(() => {
        let params = {...filters}
        dispatch(loadContractsAsync(params));
        dispatch(loadContractsCountAsync(params))
    }, [filters, activeOrganization]);


    return (
        <>
            <AdvanceTableWrapper
                columns={columns}
                data={contractsFormation}
                pagination
                perPage={1000}
            >
                <Card className="mb-3">
                    <Card.Header>
                        <ContactFormationDataTableHeader table/>
                    </Card.Header>
                    <Card.Body className="p-0">
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-wrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTableCustomPagination
                            count={count}
                            limit={filters.limit}
                            page={filters.page}
                            onLimitChange={onLimitChange}
                            onPageChange={onPageChange}
                        />
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>
        </>
    )
}


export default ContactFormationDataTable;
