import React, {Fragment, useEffect, useState} from 'react';
import {Card, Col, Form, Row} from "react-bootstrap";
import {useFormContext, useWatch} from "react-hook-form";
import AdditionalInformationV2Point from "./AdditionalInformationV2Point";
import useQuery from "../../../invoice/form/useQuery";
import {
    loadSystemOrganizationAddressesAsync, selectSystemOrganizationAddresses
} from "../../../../../../app/store/reducers/roamingReference/roamingReferenceReducer";
import {useDispatch, useSelector} from "react-redux";

const AdditionalInformationV2 = ({isEditing, content, ediShipment}) => {
    const dispatch = useDispatch();
    const organizationAddresses = useSelector(selectSystemOrganizationAddresses);

    const {draft_waybill_id, waybill_id, shipment_id} = useQuery();

    const executorInnOrPinfl = useWatch({name:'executor.inn_or_pinfl', exact: true})
    const customerInnOrPinfl = useWatch({name:'customer.inn_or_pinfl', exact: true})
    const branchCode = useWatch({name:'branch_code', exact: true})

    useEffect(() => {
        dispatch(loadSystemOrganizationAddressesAsync());
    }, []);

    return (
        <Fragment>
            <Row className={'my-3'}>
                <Col sm={12} md={6}>
                    <AdditionalInformationV2Point
                        pointName={'loading_point'}
                        inn={executorInnOrPinfl}
                        title={'roaming.waybill.v2.send.loading_point.title'}
                        isEditing={isEditing || draft_waybill_id || waybill_id || shipment_id}
                        content={content}
                        ediShipment={ediShipment}
                    />
                </Col>

                <Col sm={12} md={6}>
                    <AdditionalInformationV2Point
                        pointName={'unloading_point'}
                        inn={customerInnOrPinfl}
                        title={'roaming.waybill.v2.send.unloading_point.title'}
                        organizationAddresses={organizationAddresses}
                        customer={customerInnOrPinfl}
                        isEditing={isEditing || draft_waybill_id || waybill_id || shipment_id}
                        content={content}
                        brachCode={branchCode}
                        ediShipment={ediShipment}
                    />
                </Col>
            </Row>
        </Fragment>
    );
};

export default AdditionalInformationV2;
