import React, {useState} from 'react';
import FalconCloseButton from "../../common/FalconCloseButton";
import {Button, Modal} from "react-bootstrap";
import SelectMember from "../../common/SelectMember";
import {changeLoader, itemChangeStatus} from "../../../app/store/reducers/kanban/kanbanReducer";
import {useDispatch, useSelector} from "react-redux";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";
import PropTypes from "prop-types";

const ItemChangeStatusModal = ({show, setShow, dragEnd, setDragEnd, getColumn}) => {
    const dispatch = useDispatch()
    const t = Translate;
    const lang = useSelector(selectLang);

    const [value, setValue] = useState(null)
    const [loading, setLoading] = useState(false)
    const handleClose = () => {
        setShow(false)
        dispatch(changeLoader(false))
    };

    const handleDragEnd = () => {
        setLoading(true)
        const { source, destination, draggableId } = dragEnd

        if (source.droppableId === destination.droppableId) {
            const items = getColumn(source.droppableId).items;
            const column = getColumn(source.droppableId);
            /*const reorderedItems = reorderArray(
                items,
                source.index,
                destination.index
            );*/

            // dispatch(updateSingleColumn({column, reorderedItems}))

        } else {
            const destColumn = getColumn(destination.droppableId);
            const params = {
                member_id : value,
                status_id: destColumn.id,
            }
            itemChangeStatus(draggableId, params)
                .then(() => {
                    setDragEnd(null)
                    setShow(false)
                })
                .catch((e) => new Error(e))
                .finally(() => {
                    dispatch(changeLoader(false))
                    setLoading(false)
                    setValue(null)
                })
        }

    }


    return (
        <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
            <Modal.Header>
                <Modal.Title>{t(lang, 'task.board.member')}</Modal.Title>
                <FalconCloseButton onClick={handleClose}/>
            </Modal.Header>
            <Modal.Body>
                <SelectMember
                    value={value}
                    onChange={setValue}
                    classNamePrefix={'react-select'}
                    placeholder={t(lang, "task.board.common.select.placeholder")}
                    hideSelectedOptions
                    isClearable
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="falcon-danger" onClick={handleClose}>{t(lang, 'task.board.common.close')}</Button>
                <Button disabled={loading} onClick={handleDragEnd} variant="primary">{t(lang, 'task.board.common.save')}</Button>
            </Modal.Footer>
        </Modal>
    );
};

ItemChangeStatusModal.propTypes = {
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
    dragEnd: PropTypes.object,
    setDragEnd: PropTypes.func,
    getColumn: PropTypes.func
}
export default ItemChangeStatusModal;
