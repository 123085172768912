import {Button, Col, Form, Row, Spinner} from "react-bootstrap";
import {useFieldArray, useFormContext} from "react-hook-form";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import ModalMediaContent from "../../modal/kanban/ModalMediaContent";
import ModalSidebar from "../../modal/kanban/ModalSidebar";
import ModalLabelContent from "../../modal/kanban/ModalLabelContent";
import ModalCommentContent from "../../modal/kanban/ModalCommentContent";
import {faFont} from "@fortawesome/free-solid-svg-icons";
import GroupMember from "../../main/GroupMember";
import React, {useState} from "react";
import {
    itemDeleteAssigner, itemRegisterAssigner, itemUpdateAssigner,
    selectMembers,
    selectModalContent
} from "../../../../app/store/reducers/kanban/kanbanReducer";
import ConfirmationModal from "../../modal/ConfirmationModal";
import PropTypes from "prop-types";
import Kanban from "../../../../pages/main/kanban/layout/Kanban";
import KanbanItem from "../../KanbanItem";

const ModalForm = ({loading, edit, itemId, id}) => {
    const members = useSelector(selectMembers)
    const modalContent = useSelector(selectModalContent)

    const t = Translate;
    const lang = useSelector(selectLang);
    const [loadingContent, setLoadingContent] = useState(false)
    const {register, formState: {errors}, watch} = useFormContext();


    const attachments = watch('attachments')
    // const comments = watch('comments')
    const assigner = watch(edit ? 'assigner' : 'assigner_id')
    const reporter = watch(edit ? 'reporter' : 'reporter_id')
    const labelsFields = watch('labels')

    const labels = useFieldArray({
        name: "labels"
    });

    const comments = useFieldArray({
        name: 'comments'
    })

    const addLabels = (label) => {
         let index = labels.fields.findIndex(i => label.color_type === i.color_type)
         if (index !== -1) labels.remove(index)
         else labels.append(label)
    }

    const deleteAssigner = (requesterId) => {
        setLoadingContent(true)
        const params = {
            assigner_id: modalContent.assigner?.id,
            requester_id: requesterId ? requesterId : modalContent.reporter?.id
        }
        return new Promise((resolve, reject) => {
            itemDeleteAssigner(modalContent?.id, params)
                .then((res) => resolve(res))
                .catch((e) => reject(e))
                .finally(() => setLoadingContent(false))
        })
    }

    const updateAssigner = (requesterId, assignerId) => {
        setLoadingContent(true)
        const params = {
            assigner_id: assignerId,
            requester_id: requesterId ? requesterId : modalContent.reporter?.id
        }

        return new Promise((resolve, reject) => {
            itemUpdateAssigner(modalContent?.id, params)
                .then((res) => resolve(res))
                .catch((e) => reject(e))
                .finally(() => setLoadingContent(false))
        })
    }

    const registerAssigner = (requesterId, assignerId) => {
        setLoadingContent(true)

        const params = {
            assigner_id: assignerId,
            requester_id: requesterId ? requesterId : modalContent.reporter?.id
        }

        return new Promise((resolve, reject) => {
            itemRegisterAssigner(modalContent?.id, params)
                .then((res) => resolve(res))
                .catch((e) => reject(e))
                .finally(() => setLoadingContent(false))
        })
    }

    return (
        <Row>
            <Col lg={9}>
                <ModalMediaContent title={t(lang, 'task.board.modal.title.task')} icon={faFont}>
                    <Form.Group>
                        <Form.Control
                            type={'text'}
                            placeholder={t(lang, 'task.board.modal.title.task.placeholder') }
                            disabled={edit}
                            isInvalid={errors?.title}
                            {...register('title', {
                                required: t(lang, "crm.common.forms.validations.is_required")
                            })}
                        />
                    </Form.Group>
                </ModalMediaContent>

                {reporter && <ModalMediaContent title={'task.board.modal.reporter'} icon="user">
                    <GroupMember
                        edit={edit}
                        name={edit ? 'reporter' : 'reporter_id'}
                        value={members.find(i => i.id === reporter)?.name}
                        avatarSize="xl"
                    />

                </ModalMediaContent>}

                {assigner && <ModalMediaContent title={t(lang, 'task.board.modal.assigner')} icon="user">
                    <div className={'d-flex justify-content-between gap-2'}>
                        <GroupMember
                            name={edit ? 'assigner' : 'assigner_id'}
                            value={members.find(i => i.id === assigner)?.name}
                            avatarSize="xl"
                            edit={edit}
                        />
                        {edit && <div className={'d-flex justify-content-end gap-2'}>
                            <ConfirmationModal
                                title={t(lang, 'task.board.common.delete')}
                                titleLabel={t(lang, 'task.board.modal.reporter')}
                                icon={'trash'}
                                variant={'falcon-danger'}
                                confirmationAction={deleteAssigner}/>

                            <ConfirmationModal
                                title={t(lang, 'task.board.common.edit')}
                                titleLabel={t(lang, 'task.board.member')}
                                icon={'edit'}
                                variant={'falcon-warning'}
                                update
                                confirmationAction={updateAssigner}
                            />

                        </div>}
                    </div>

                </ModalMediaContent>}

                <ModalMediaContent title={t(lang, 'task.board.modal.description.task')} icon="align-left">
                    <Form.Group>
                        <Form.Control
                            disabled={edit}
                            as="textarea"
                            placeholder={t(lang, 'task.board.modal.description.task.placeholder')}
                            {...register('description')}
                        />
                    </Form.Group>
                </ModalMediaContent>

                {labels.fields.length > 0 && <ModalMediaContent title={t(lang, 'task.board.modal.label')} icon="tag">
                    <ModalLabelContent  labels={labels.fields} addLabels={addLabels}/>
                </ModalMediaContent>}

                {/* ====== Will be needed ====== */}
                {/* <ModalMediaContent
                    title="Вложения"
                    icon="paperclip"
                    headingClass="d-flex justify-content-between"
                    headingContent={
                        <Controller name={'attachments'}
                                    render={({field}) => (
                                        <UploadAttachments files={field.value} preview />
                                    )}
                        />
                    }
                >
                    <ModalAttachmentContent attachments={attachments}/>
                </ModalMediaContent>*/}

                {edit && <ModalMediaContent
                    title={t(lang, 'task.board.modal.comments')}
                    icon={['far', 'comment']}
                    headingClass="mb-3"
                >
                    <ModalCommentContent comments={comments}/>
                </ModalMediaContent>}

                {/*{edit && <ModalMediaContent
                    title="Activity"
                    icon="list-ul"
                    headingClass="mb-3"
                    isHr={false}
                >
                    <ModalActivityContent/>
                </ModalMediaContent>}*/}

            </Col>

            <Col lg={3}>
                <ModalSidebar
                    confirmation={registerAssigner}
                    assigner={assigner}
                    edit={edit}
                    addLabels={addLabels}
                    labelFields={labelsFields}
                    itemId={itemId}
                />
            </Col>

            {edit &&
                <ModalMediaContent
                    title={t(lang, 'task.board.modal.actions')}
                    icon={'list'}
                    headingClass="mb-3"
                >
                    <KanbanItem id={id}/>
                </ModalMediaContent>
            }

            {!edit && <Form.Group className={'mt-2'}>
                <Button className={'d-flex align-items-center float-end'} type="submit" variant="falcon-primary" disabled={loading}>
                    {loading &&
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                    }
                    {t(lang, "task.board.common.add")}
                </Button>
            </Form.Group>}
        </Row>
    );
};


export default ModalForm;
