import classNames from "classnames";
import React, {useEffect, useState} from 'react';
import {Col, Form, Row} from "react-bootstrap";
import {useSelector} from "react-redux";
import {Controller, useFormContext, useWatch} from "react-hook-form";
import WaybillRegionSelector from "../../common/WaybillRegionSelector";
import WaybillVillageSelector from "../../common/WaybillVillageSelector";
import WaybillDistrictSelector from "../../common/WaybillDistrictSelector";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {
    loadWaybillDistrictsAsync,
    loadWaybillRegionsAsync,
    loadWaybillVillagesAsync,
    selectWaybillDistricts,
    updateWaybillDistricts,
    updateWaybillVillages
} from "../../../../../app/store/reducers/roamingReference/roamingReferenceReducer";
import YandexMap from "../../../../common/map/YandexMap";
import FindCompanySearchable from "../../../organization/FindCompanySearchable";
import {
    getWaybillDistrictAsync,
    getWaybillVillageAsync
} from "../../../../../app/store/reducers/roaming/roamingWaybillV2Reducer";

const OrganizationAddressForm = ({isEditing, systemOrganization}) => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const {setValue, register, formState: {errors}, resetField} = useFormContext();

    const [district, setDistrict] = useState([])
    const [village, setVillage] = useState([])
    const regionCode = useWatch({name: 'regionCode', exact: true});
    const districtCode = useWatch({name: 'districtCode', exact: true});

    useEffect(() => {
        initializeLocation();
    }, [])

    useEffect(() => {
        if (regionCode) {
            loadWaybillDistricts(regionCode);
        } else {
            resetField('districtCode');
            setVillage([]);
        }
    }, [regionCode])

    useEffect(() => {
        const districtSoatoCode = district.find(d => d.code === districtCode)?.soato_code || null;
        if (districtSoatoCode) {
            loadWaybillVillages(districtSoatoCode);
        } else if (!isEditing) {
            resetField('villageCode');
            // setDistrict([]);
        }
    }, [districtCode, district])

    const loadWaybillDistricts = (regionCode) => {
        getWaybillDistrictAsync(regionCode)
            .then(res => setDistrict(res))
            .catch(() => setDistrict([]));
    }

    const loadWaybillVillages = (soatoCode) => {
        console.log(soatoCode, 'soatoCode')

        getWaybillVillageAsync(soatoCode)
            .then(res => setVillage(res))
            .catch(() => setVillage([]));
    }

    const initializeLocation = () => {
        if (!isEditing) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setValue('location', {
                        latitude: position.latitude,
                        longitude: position.longitude,
                        address: ''
                    })
                }, (error) => {
                    console.log(error);
                });
        }
    }

    const onSelectRegion = (selectedRegion, onChangeCallback) => {
        setValue('regionName', selectedRegion ? selectedRegion.name : null);
        onChangeCallback(selectedRegion ? selectedRegion.code : null);
    }

    const onSelectDistrict = (selectedDistrict, onChangeCallback) => {
        setValue('districtName', selectedDistrict ? selectedDistrict.name : null);
        onChangeCallback(selectedDistrict ? selectedDistrict.code : null);
    }

    const onSelectVillage = (selectedVillage, onChangeCallback) => {
        setValue('villageName', selectedVillage ? selectedVillage.name : null);
        onChangeCallback(selectedVillage ? selectedVillage.code : null);
    }

    return (
        <Row>
            {systemOrganization && <Form.Group as={Col} xs={6} className={'my-1'}>
                <Form.Label>{t(lang, 'roaming.waybill.datatable.row.contractor')}</Form.Label>
                <Controller
                    rules={{required: t(lang, "edi.common.forms.validations.is_required")}}
                    name={'contractor_inn'}
                    render={({field}) => (
                    <FindCompanySearchable
                        onChange={(customer) => {
                            field.onChange(customer ? customer?.person_num ? customer?.person_num : customer?.inn : null)
                        }}
                        classNamePrefix={'react-select'}
                        inputProps={{
                            className: `${errors?.contractor_inn && 'is-invalid'} w-100`,
                            placeholder: t(lang, "roaming.contracts.add.clients_info.inn_or_pinfl")
                        }}
                    />
                )}
                />
                <Form.Control.Feedback type="invalid" className={'d-block'}>
                    {errors?.contractor_inn?.message}
                </Form.Control.Feedback>
            </Form.Group>}
            <Form.Group as={Col} xs={6} className={'my-1'}>
                <Form.Label>{t(lang, 'roaming.organization_address.company_address_form.field.region')}</Form.Label>
                <Controller
                    name={'regionCode'}
                    rules={{required: t(lang, 'roaming.validations.is_required')}}
                    render={({field}) => (
                        <WaybillRegionSelector
                            isClearable={true}
                            regionCode={field.value}
                            wrapperClassName={classNames({'is-invalid': errors?.regionCode})}
                            onChange={selectedRegion => onSelectRegion(selectedRegion, field.onChange)}
                            className={classNames('', {'is-invalid': errors?.regionCode})}
                        />
                    )}
                />
                <Form.Control.Feedback type={'invalid'}>
                    {errors?.regionCode?.message}
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} xs={6} className={'my-1'}>
                <Form.Label>{t(lang, 'roaming.organization_address.company_address_form.field.district')}</Form.Label>
                <Controller
                    name={'districtCode'}
                    rules={{required: t(lang, 'roaming.validations.is_required')}}
                    render={({field}) => (
                        <WaybillDistrictSelector
                            districts={district}
                            isClearable={true}
                            districtCode={field.value}
                            className={classNames('', {'is-invalid': errors?.districtCode})}
                            wrapperClassName={classNames({'is-invalid': errors?.districtCode})}
                            onChange={selectedDistrict => onSelectDistrict(selectedDistrict, field.onChange)}
                        />
                    )}
                />
                <Form.Control.Feedback type={'invalid'}>
                    {errors?.districtCode?.message}
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} xs={6} className={'my-1'}>
                <Form.Label>{t(lang, 'roaming.organization_address.company_address_form.field.village')}</Form.Label>
                <Controller
                    name={'villageCode'}
                    rules={{required: t(lang, 'roaming.validations.is_required')}}
                    render={({field}) => (
                        <WaybillVillageSelector
                            villages={village}
                            isClearable={true}
                            villageCode={field.value}
                            className={classNames('', {'is-invalid': errors?.villageCode})}
                            wrapperClassName={classNames({'is-invalid': errors?.villageCode})}
                            onChange={selectedVillage => onSelectVillage(selectedVillage, field.onChange)}
                        />
                    )}
                />
                <Form.Control.Feedback type={'invalid'}>
                    {errors?.villageCode?.message}
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} xs={6} className={'my-1'}>
                <Form.Label>{t(lang, 'roaming.organization_address.company_address_form.field.street')}</Form.Label>
                <Form.Control
                    isInvalid={errors?.street}
                    placeholder={t(lang, 'roaming.organization_address.company_address_form.field.street.placeholder')}
                    {...register('street', {required: t(lang, 'roaming.validations.is_required')})}
                />
                <Form.Control.Feedback type={'invalid'}>
                    {errors?.street?.message}
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} xs={systemOrganization ? 6 : 12} className={'my-1'}>
                <Form.Label>{t(lang, 'roaming.organization_address.company_address_form.field.other')}</Form.Label>
                <Form.Control
                    isInvalid={errors?.other}
                    placeholder={t(lang, 'roaming.organization_address.company_address_form.field.other.placeholder')}
                    {...register('other', {required: t(lang, 'roaming.validations.is_required')})}
                />
                <Form.Control.Feedback type={'invalid'}>
                    {errors?.other?.message}
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} xs={12} className={'my-1'}>
                <Form.Label>{t(lang, 'roaming.organization_address.company_address_form.field.location')}</Form.Label>
                <Controller name={`location`}
                            render={({field}) => (
                                <YandexMap defaultLocation={field.value}
                                           onChange={field.onChange}
                                           mapProps={{
                                               width: '100%',
                                               height: '500px'
                                           }}
                                />
                            )}
                />
            </Form.Group>
        </Row>
    );
};

export default OrganizationAddressForm;
