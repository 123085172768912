import axios from 'axios'

class RoamingInvoiceService {
    send(payload) {
        return axios.post('/roaming/invoice/send', payload)
    }

    get(id) {
        return axios.get(`/roaming/invoice/get/${id}`)
    }

    getDrafts(params) {
        return axios.get('/roaming/invoice/drafts/get', {
            params: params
        })
    }

    getDraftsCount(params) {
        return axios.get('/roaming/invoice/drafts/count', {
            params: params
        });
    }

    getDraft(id) {
        return axios.get(`/roaming/invoice/draft/get/${id}`);
    }

    deleteDraft(id) {
        return axios.post(`/roaming/invoice/draft/delete/${id}`)
    }

    save(payload) {
        return axios.post('/roaming/invoice/draft/save', payload);
    }


    getExecutorInvoices(params) {
        return axios.get('/roaming/invoice/executor/get', {
            params: params
        })
    }

    getCustomerInvoices(params) {
        return axios.get('/roaming/invoice/customer/get', {
            params: params
        })
    }

    getAgentInvoices(params) {
        return axios.get('/roaming/invoice/person/get', {
            params: params
        })
    }

    getExecutorInvoicesCount(params) {
        return axios.get('/roaming/invoice/executor/count', {
            params: params
        })
    }

    getCustomerInvoicesCount(params) {
        return axios.get('/roaming/invoice/customer/count', {
            params: params
        })
    }

    getAgentInvoicesCount(params) {
        return axios.get('/roaming/invoice/person/count', {
            params: params
        })
    }

    getAcceptHashCode(id) {
        return axios.get(`/roaming/invoice/hashcode/accept/${id}`, {
            transformResponse: [data => data]
        })
    }

    getCancelHashCode(id) {
        return axios.get(`/roaming/invoice/hashcode/cancel/${id}`, {
            transformResponse: [data => data]
        })
    }

    getDeclineHashCode(id, comment) {
        return axios.get(`/roaming/invoice/hashcode/decline/${id}`, {
            transformResponse: [data => data],
            params: {comment: comment}
        })
    }

    executorCancel(id, signature) {
        return axios.post(`/roaming/invoice/cancel/${id}`, {
            signature: signature
        })
    }

    customerAccept(id, signature) {
        return axios.post(`/roaming/invoice/customer/accept/${id}`, {
            signature: signature
        })
    }

    customerDecline(id, signature, comment) {
        return axios.post(`/roaming/invoice/customer/decline/${id}`, {
            signature: signature,
            comment: comment
        })
    }

    sync(id) {
        return axios.get(`roaming/invoice/sync/${id}`)
    }

    syncHistories(id) {
        return axios.get(`/roaming/invoice/sync-histories/${id}`)
    }

    importRoamingInvoice(insertItem, executorInn, customerInn) {
        return axios.get(`/roaming/invoice/insert/${insertItem}?executor_inn=${executorInn}&customer_inn=${customerInn}`)
    }

    personAccept(id, signature) {
        return axios.post(`/roaming/invoice/person/accept/${id}`, {
            signature: signature
        })
    }

    personDecline(id, signature, comment) {
        return axios.post(`/roaming/invoice/person/decline/${id}`, {
            signature: signature,
            comment: comment
        })
    }

    getPDF(id) {
        return axios.get(`/pdf/roaming/invoice/${id}`, {
            responseType: "blob"
        })
    }

    getDraftPDF(id) {
        return axios.get(`/pdf/roaming/invoice/draft/${id}`, {
            responseType: "blob"
        })
    }

    uploadCustomerInvoicesToExcel({uri, params}) {
        return axios.get(`${uri}/customer`, {
            params: params,
            responseType: "arraybuffer",
        })
    }

    uploadCustomerInvoicesWithItemsToExcel({uri, params}) {
        return axios.get(`${uri}/customer`, {
            params: params,
            responseType: "arraybuffer",
        })
    }

    uploadExecutorInvoicesToExcel({uri, params}) {
        return axios.get(`${uri}/executor`, {
            params: params,
            responseType: "arraybuffer",
        })
    }

    uploadExecutorInvoicesWithItemsToExcel({uri, params}) {
        return axios.get(`${uri}/executor`, {
            params: params,
            responseType: "arraybuffer",
        })
    }
}

const roamingInvoiceService = new RoamingInvoiceService();
export default roamingInvoiceService;
