import IconButton from "../../../../common/IconButton";
import React, {cloneElement, Fragment, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {Modal} from "react-bootstrap";
import FalconCloseButton from "../../../../common/FalconCloseButton";
import {
    registerContractorPaymentAsync
} from "../../../../../app/store/reducers/contractor/contractorPaymentReducer";
import ContractorPaymentForm from "./ContractorPaymentForm";
import dayjs from "dayjs";

const AddContractorPayment = ({contractor, children}) => {
    const dispatch = useDispatch();
    const lang = useSelector(selectLang);
    const t = Translate;
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const handleOnSubmit = (formData) => {
        const payload = {
            contractor_id: formData.contractor,
            date: dayjs(formData.date, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss"),
            notes: formData.notes,
            cash_box_states: formData.cash_box_states.map((act) => {
                return {
                    type: act.type,
                    amount: typeof (act.currency.amount) === 'string' ? +act.currency.amount.replace(/ /g, '') : +act.currency.amount,
                    currency_id: act.currency.currency_id
                }
            }),
            debt_states: formData.debt_states.map((debt) => {
                return {
                    amount: typeof (debt.amount) === 'string' ? +debt.amount.replace(/ /g, '') : +debt.amount,
                    currency_id: debt.currency_id
                }
            }),
            cashbox_id: formData.cashbox_id,
        }

        setLoading(true)
        dispatch(registerContractorPaymentAsync(payload))
            .then(re => {
                setShow(false)
            })
            .catch(err => console.log(err))
            .finally(() => setLoading(false));
    }
    return (
        <Fragment>
            {!!children ? cloneElement(children, {...children.props, onClick: () => setShow(true)}) : (
                <IconButton
                    variant="falcon-default"
                    size="sm"
                    icon="plus"
                    onClick={() => setShow(true)}
                    transform="shrink-3">
                <span className="d-none d-sm-inline-block ms-1">
                    {t(lang, "edi.common.add_button")}
                </span>
                </IconButton>
            )}

            <Modal show={show} onHide={() => {
                setShow(false)
            }} size={"xl"}>
                <Modal.Header>
                    <Modal.Title>{t(lang, "edi.contractor_payment_form_title")}</Modal.Title>
                    <FalconCloseButton onClick={() => {
                        setShow(false)
                    }}/>
                </Modal.Header>
                <Modal.Body>
                    <ContractorPaymentForm onClose={() => {
                        setShow(false)
                    }} loading={loading} onSubmit={handleOnSubmit} contractor={contractor}/>
                </Modal.Body>
            </Modal>
        </Fragment>)
}
export default AddContractorPayment
