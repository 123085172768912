import IconButton from "../../../../../../common/IconButton";
import {faSignature} from "@fortawesome/free-solid-svg-icons";
import SelectCertificateWrapperComponent from "../../../../components/SelectCertificateWrapperComponent";
import React, {useState} from "react";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../../../app/store/reducers/main/mainReducer";
import {selectCurrentCertificate} from "../../../../../../../app/store/reducers/eimzo/eimzoReducer";
import EventBus from "../../../../../../../app/eventbus/EventBus";
import {
    WAYBILL_V2_BULK_ACTION_FINISHED,
    WAYBILL_V2_BULK_ACTION_STARTED
} from "../../../../../../../app/eventbus/roaming/roamingWaybillV2Events";

export const hashCodeCertificate = (displayName) => {

    const HashCodeCertificate = ({
                                     id,
                                     waybills = [],
                                     handleShowProgressBar,
                                     updateActionResult,
                                     requestApi = () => {},
                                     btnText,
                                     variant,
                                    ...props
                                 } ) => {

        const lang = useSelector(selectLang);
        const t = Translate;
        const activeCertificate = useSelector(selectCurrentCertificate);

        const [loading, setLoading] = useState(false); // cancel

        const confirmation = () => {
            if (waybills.length > 0)
                bulkConfirmation();
            else
                singleConfirmation();
        };

        const singleConfirmation = () => {
            setLoading(true);
            requestApi(activeCertificate, lang, id)
                .then(() => {
                    setLoading(false)
                })
                .catch((error) => {
                    setLoading(false)
                })
        }

        const bulkConfirmation = async () => {
            handleShowProgressBar();
            EventBus.dispatch(WAYBILL_V2_BULK_ACTION_STARTED);
            for (let i = 0; i < waybills.length; i++) {
                const waybill = waybills[i];
                try {
                    await requestApi(activeCertificate, lang, waybill.id);
                    updateActionResult({
                        number: waybill.info.number,
                        date: waybill.info.date,
                        contractor: {
                            name: waybill.customer.name,
                            inn: waybill.customer.inn
                        }
                    });
                } catch (error) {
                    updateActionResult({
                        number: waybill.info.number,
                        date: waybill.info.date,
                        contractor: {
                            name: waybill.customer.name,
                            inn: waybill.customer.inn
                        },
                        error: error?.message
                    })
                }
            }
            EventBus.dispatch(WAYBILL_V2_BULK_ACTION_FINISHED);
        }

        return (
            <SelectCertificateWrapperComponent
                variant={variant ? variant : "falcon-primary"}
                openDialogButtonText={btnText ? t(lang, btnText) : t(lang, "roaming.free_form_document.view.action.accept")}
                submitButton={
                    <IconButton
                        id={displayName || "HashCodeCertificate"}
                        onClick={confirmation}
                        variant={variant ? variant : "falcon-primary"}
                        size="sm"
                        icon={faSignature}
                        disabled={loading}
                        {...props}
                    >
                        {btnText ? t(lang, btnText) : t(lang, "roaming.free_form_document.view.action.accept")}
                    </IconButton>}
            />
        )
    }

    HashCodeCertificate.displayName = displayName || "HashCodeCertificate"

    return HashCodeCertificate

}
