import React from 'react';
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import ReactSelect from "react-select";

const SortSelector = ({defaultValue, onChange}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const options = [
        {label: t(lang, "items.common.sort.name"), id: "name", desc: true},
        {label: t(lang, "items.common.sort.name"), id: "name", desc: false},
        {label: t(lang, "items.common.sort.created_at"), id: "createdAt", desc: true},
        {label: t(lang, "items.common.sort.created_at"), id: "createdAt", desc: false},
        {label: t(lang, "items.common.sort.state"), id: "state", desc: true},
        {label: t(lang, "items.common.sort.state"), id: "state", desc: false},
        {label: t(lang, "items.common.sort.sku"), id: "sku", desc: true},
        {label: t(lang, "items.common.sort.sku"), id: "sku", desc: false},
    ];

    return (
        <ReactSelect options={options}
                     isClearable={false}
                     hideSelectedOptions={true}
                     isSearchable={false}
                     defaultValue={options.find((option) => option.id === defaultValue.id && option.desc === defaultValue.desc)}
                     getOptionValue={option => `${option.id} ${option.desc}`}
                     getOptionLabel={option => `${option.label} ${option.desc ? `(${t(lang, "items.common.sort_by_desc.yes")})` : `(${t(lang, "items.common.sort_by_desc.no")})`}`}
                     onChange={onChange}
        />
    );
};

export default SortSelector;