import {createSlice} from "@reduxjs/toolkit";
import roamingInvoiceService from '../../../../services/roaming/roamingInvoiceService'
import EventBus from "../../../eventbus/EventBus";
import {
    ROAMING_INVOICE_CUSTOMER_ACCEPT_FAILED,
    ROAMING_INVOICE_CUSTOMER_ACCEPT_REQUESTED,
    ROAMING_INVOICE_CUSTOMER_ACCEPT_SUCCESS,
    ROAMING_INVOICE_CUSTOMER_DECLINE_FAILED,
    ROAMING_INVOICE_CUSTOMER_DECLINE_REQUESTED,
    ROAMING_INVOICE_CUSTOMER_DECLINE_SUCCESS,
    ROAMING_INVOICE_EXECUTOR_CANCEL_FAILED,
    ROAMING_INVOICE_EXECUTOR_CANCEL_REQUESTED,
    ROAMING_INVOICE_EXECUTOR_CANCEL_SUCCESS,
    ROAMING_INVOICE_PERSON_ACCEPT_FAILED,
    ROAMING_INVOICE_PERSON_ACCEPT_REQUESTED,
    ROAMING_INVOICE_PERSON_ACCEPT_SUCCESS,
    ROAMING_INVOICE_PERSON_DECLINE_FAILED,
    ROAMING_INVOICE_PERSON_DECLINE_REQUESTED,
    ROAMING_INVOICE_PERSON_DECLINE_SUCCESS,
    ROAMING_INVOICE_SAVE_FAILED,
    ROAMING_INVOICE_SAVE_REQUESTED,
    ROAMING_INVOICE_SAVE_SUCCESS,
    ROAMING_INVOICE_SEND_FAILED,
    ROAMING_INVOICE_SEND_REQUESTED,
    ROAMING_INVOICE_SEND_SUCCESS,
    ROAMING_INVOICE_SYNC_FAILED,
    ROAMING_INVOICE_SYNC_HISTORIES_FAILED,
    ROAMING_INVOICE_SYNC_HISTORIES_REQUESTED,
    ROAMING_INVOICE_SYNC_HISTORIES_SUCCESS,
    ROAMING_INVOICE_SYNC_REQUESTED,
    ROAMING_INVOICE_SYNC_SUCCESS
} from '../../../eventbus/roaming/roamingInvoiceEvents'
import {attachPkcs7WithTimestamp, createPkcs7WithTimestamp} from "../eimzo/eimzoReducer";
import {Translate} from "../main/mainReducer";
import {DRAFT, StatusSentToCustomer, VIEWER_CUSTOMER, VIEWER_EXECUTOR, VIEWER_PERSON} from "../../../../enum/RoamingInvoiceWrapper";
import documentGeneratorService from "../../../../services/documentGenerator/documentGeneratorService";

const t = Translate;

export const roamingInvoiceSlice = createSlice({
    name: 'roamingInvoice',
    initialState: {
        filterOptions: {
            page: 1,
            limit: 10,
            status: StatusSentToCustomer
        },
        invoices: [],
        count: 0,
        isLoading: false,
        drafts: [],
        draftsCount: 0,
        viewPage: {
            id: null,
            draftId: null,
            showModal: false
        },
        roamingCommonHideModal: null
    },
    reducers: {
        setShowModal: (state, action) => {
            if (!action.payload) {
                state.viewPage = {id: null, draftId: null, showModal: false}
            } else {
                state.viewPage.showModal = action.payload
            }
        },
        setId: (state, {payload}) => {
            const {draftId, id} = payload
            if (draftId) state.viewPage.draftId = draftId
            else if (id) state.viewPage.id = id
        },
        setRoamingCommonHideModal: (state, action) => {
            state.roamingCommonHideModal = action.payload
        },
        updateFilterOptions: (state, action) => {
            state.filterOptions = action.payload;
        },
        clearFilterOptions: (state, action) => {
            state.filterOptions = action.payload
        },
        updateRoamingInvoices: (state, action) => {
            state.invoices = action.payload
        },
        updateRoamingInvoicesCount: (state, action) => {
            state.count = action.payload
        },
        setLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        updateRoamingInvoiceDrafts: (state, action) => {
            state.drafts = action.payload;
        },
        updateRoamingInvoiceDraftsCount: (state, action) => {
            state.draftsCount = action.payload;
        },
    }
});

export default roamingInvoiceSlice.reducer;
export const {
    setShowModal,
    setId,
    setRoamingCommonHideModal,
    clearFilterOptions
} = roamingInvoiceSlice.actions

export const selectRoamingInvoices = state => state.roamingInvoice.invoices;
export const selectInvoiceFilterOptions = state => state.roamingInvoice.filterOptions;
export const selectCount = state => state.roamingInvoice.count;
export const selectIsLoading = state => state.roamingInvoice.isLoading;

export const viewPage = state => state.roamingInvoice.viewPage
export const roamingHideModal = state => state.roamingInvoice.roamingCommonHideModal

export const clear = () => (dispatch) => {
    dispatch(roamingInvoiceSlice.actions.updateFilterOptions({page: 1, limit: 10}));
    dispatch(roamingInvoiceSlice.actions.updateRoamingInvoices([]));
    dispatch(roamingInvoiceSlice.actions.updateRoamingInvoicesCount(0));
};
export const changeFilterOptionsAsync = (filters) => dispatch => dispatch(roamingInvoiceSlice.actions.updateFilterOptions(filters))

export const loadInvoicesAsync = (viewer, filters) => {
    const {page, limit} = filters;
    const skip = (page - 1) * limit;
    const params = {...filters, skip, limit};
    return new Promise((resolve, reject) => {
        switch (viewer) {
            case VIEWER_CUSTOMER :
                return roamingInvoiceService.getCustomerInvoices(params)
                    .then(res => resolve(res.data))
                    .catch(e => reject(e))
            case VIEWER_EXECUTOR :
                return roamingInvoiceService.getExecutorInvoices(params)
                    .then(res => resolve(res.data))
                    .catch(e => reject(e))
            case VIEWER_PERSON :
                return roamingInvoiceService.getAgentInvoices(params)
                    .then(res => resolve(res.data))
                    .catch(e => reject(e))
            case DRAFT :
                return roamingInvoiceService.getDrafts(params)
                    .then(res => resolve(res.data))
                    .catch(e => reject(e))
        }
    })
}

export const loadInvoicesCountAsync = (viewer, filters) => {
    return new Promise((resolve, reject) => {
        switch (viewer) {
            case VIEWER_CUSTOMER :
                return roamingInvoiceService.getCustomerInvoicesCount({...filters})
                    .then(res => resolve(res.data))
                    .catch(e => reject(e))
            case VIEWER_EXECUTOR :
                return roamingInvoiceService.getExecutorInvoicesCount({...filters})
                    .then(res => resolve(res.data))
                    .catch(e => reject(e))
            case VIEWER_PERSON :
                return roamingInvoiceService.getAgentInvoicesCount({...filters})
                    .then(res => resolve(res.data))
                    .catch(e => reject(e))
            case DRAFT :
                return roamingInvoiceService.getDraftsCount({...filters})
                    .then(res => resolve(res.data))
                    .catch(e => reject(e))
        }
    })
}

export const loadRoamingInvoicesAsync = (viewer, filters) => async (dispatch) => {
    try {
        dispatch(roamingInvoiceSlice.actions.setLoading(true));

        const {page, limit} = filters;
        const skip = (page - 1) * limit;
        const params = {...filters, skip, limit};

        let invoices = [];

        switch (viewer) {
            case VIEWER_CUSTOMER:
                invoices = (await roamingInvoiceService.getCustomerInvoices(params)).data;
                break;
            case VIEWER_EXECUTOR:
                invoices = (await roamingInvoiceService.getExecutorInvoices(params)).data;
                break;
            case VIEWER_PERSON:
                invoices = (await roamingInvoiceService.getAgentInvoices(params)).data;
                break;
            case DRAFT:
                const drafts = (await roamingInvoiceService.getDrafts(params)).data;
                invoices = drafts.map((i) => {
                    const content = JSON.parse(i.content);
                    return {
                        content: i.content,
                        id: content.FacturaId,
                        created_at: i.created_at,
                        updated_at: i.updated_at,
                        customer: {
                            inn: content.BuyerTin || '',
                            name: content.Buyer?.Name || '',
                            account: content.Buyer?.Account || '',
                            mfo: content.Buyer?.BankId || '',
                            director: content.Buyer?.Director || '',
                            accountant: content.Buyer?.Accountant || '',
                            oked: content.Buyer?.Oked || '',
                            address: content.Buyer?.Address || '',
                            nds_registration_code: content.Buyer?.VatRegCode || '',
                            nds_status: content.Buyer?.VatRegStatus || '',
                            tax_gap: content.Buyer?.TaxGap || '',
                            branch: {
                                code: content.Buyer?.BranchCode || '',
                                name: content.Buyer?.BranchName || ''
                            }
                        },
                        executor: {
                            inn: content.SellerTin,
                            name: content.Seller.Name,
                            account: content.Seller.Account,
                            mfo: content.Seller.BankId,
                            director: content.Seller.Director,
                            accountant: content.Seller.Accountant,
                            oked: content.Seller.Oked,
                            address: content.Seller.Address,
                            nds_registration_code: content.Seller.VatRegCode,
                            nds_status: content.Seller.VatRegStatus,
                            tax_gap: content.Seller.TaxGap,
                            branch: {
                                code: content.Seller.BranchCode,
                                name: content.Seller.BranchName
                            }
                        },
                        info: {
                            number: content.FacturaDoc.FacturaNo,
                            date: content.FacturaDoc.FacturaDate
                        },
                        contract: {
                            number: content.ContractDoc.ContractNo,
                            date: content.ContractDoc.ContractDate
                        },
                        old_invoice: {
                            id: content.OldFacturaDoc?.OldFacturaId || '',
                            number: content.OldFacturaDoc?.OldFacturaNo || '',
                            date: content.OldFacturaDoc?.OldFacturaDate || ''
                        },
                        notes: '',
                        status: null,
                        foreign_company: {
                            country_id: content.ForeignCompany?.CountryId || '',
                            name: content.ForeignCompany?.Name || '',
                            address: content.ForeignCompany?.Address || '',
                            account: content.ForeignCompany?.Account || ''
                        },
                        releaser: {
                            name: content.ItemReleasedDoc?.ItemReleasedFio || '',
                            pinfl: content.ItemReleasedDoc?.ItemReleasedPinfl || '',
                            inn: ''
                        },
                        empowerment: {
                            name: content.FacturaEmpowermentDoc?.AgentFio || '',
                            inn: '',
                            pinfl: content.FacturaEmpowermentDoc?.AgentPinfl || '',
                            number: content.FacturaEmpowermentDoc?.EmpowermentNo || '',
                            date_of_issue: content.FacturaEmpowermentDoc?.EmpowermentDateOfIssue || ''
                        },
                        total_info: {
                            total: i.total_info.total,
                            nds_value: i.total_info.nds_value,
                            total_with_nds: i.total_info.total_with_nds
                        },
                        histories: [],
                        lot_id: content.LotId,
                        waybill_id: content.WaybillIds,
                        contract_id: content.ContractId,
                        factura_type: content.FacturaType,
                        single_sided_type: content.SingleSidedType,
                        income_type: content.IncomeType,
                        has_marking: content.HasMarking,
                        has_committent: content.ProductList.HasCommittent,
                        has_lgota: content.ProductList.HasLgota,
                        hide_report_committent: content.ProductList.HideReportCommittent,
                        has_medical: content.ProductList.HasMedical,
                        has_excise: content.ProductList.HasExcise,
                        has_vat: content.ProductList.HasVat,
                        is_empowered: false,
                        items: content.ProductList.Products.map((item) => {
                            return {
                                ordering_number: item.OrdNo,
                                committent_name: item.CommittentName,
                                committent_inn: item.CommittentTin,
                                committent_nds_registration_code: item.CommittentVatRegCode,
                                committent_nds_status: item.CommitentVatRegStatus,
                                name: item.Name,
                                catalog: {
                                    code: item.CatalogCode,
                                    name: item.CatalogName
                                },
                                package: {
                                    code: item.PackageCode,
                                    name: item.PackageName
                                },
                                barcode: item.Barcode,
                                measure_id: item.MeasureId,
                                base_price: +item.BaseSumma,
                                profit_rate: +item.ProfitRate,
                                price: +item.Summa,
                                nds_rate: item.VatRate,
                                nds_value: +item.VatSum,
                                excise_rate: +item.ExciseRate,
                                excise_value: +item.ExciseSum,
                                quantity: +item.Count,
                                without_vat: item.WithoutVat,
                                total: +item.DeliverySum,
                                total_with_nds: +item.DeliverySumWithVat,
                                lgota_id: item.LgotaId,
                                lgota_name: item.LgotaName,
                                lgota_type: item.LgotaType,
                                lgota_vat_sum: item.LgotaVatSum,
                                serial: item.Serial,
                                marking: item.Marks ? {
                                    product_type: item.Marks.product_type,
                                    kiz: item.Marks.KIZ,
                                    nom_upak: item.Marks.NomUpak,
                                    ident_trans_upak: item.Marks.IdentTransUpak
                                } : null
                            }
                        }),
                        roaming_error: i.roaming_error
                    }
                });
                break
            default:
                break;
        }

        dispatch(roamingInvoiceSlice.actions.updateRoamingInvoices(invoices));
        dispatch(roamingInvoiceSlice.actions.setLoading(false));
    } catch (error) {
        console.log(error)
    }
}
export const loadRoamingInvoicesCountAsync = (viewer, filters) => async (dispatch) => {
    try {
        let count;
        let params = {...filters};

        switch (viewer) {
            case VIEWER_CUSTOMER:
                count = (await roamingInvoiceService.getCustomerInvoicesCount(params)).data
                break;
            case VIEWER_EXECUTOR:
                count = (await roamingInvoiceService.getExecutorInvoicesCount(params)).data
                break;
            case VIEWER_PERSON:
                count = (await roamingInvoiceService.getAgentInvoicesCount(params)).data
                break;
            case DRAFT:
                count = (await roamingInvoiceService.getDraftsCount(params)).data
                break
            default:
                break;
        }
        dispatch(roamingInvoiceSlice.actions.updateRoamingInvoicesCount(count));
    } catch (error) {
        console.log(error)
    }
}
export const loadRoamingInvoiceAsync = async id => {
    return new Promise((resolve, reject) => {
        roamingInvoiceService.get(id)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            })
    })
}
export const loadRoamingInvoiceDraftAsync = async (id) => {
    return new Promise((resolve, reject) => {
        roamingInvoiceService.getDraft(id)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            })
    })
};
export const deleteRoamingInvoiceDraftAsync = (id) => {
    return new Promise((resolve, reject) => {
        roamingInvoiceService.deleteDraft(id)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                console.log(error);
                reject(error)
            });
    });
};

export const generateRoamingInvoiceSendHashCode = (payload) => {
    return new Promise((resolve, reject) => {
        documentGeneratorService.generateRoamingInvoiceSendHashCode(payload)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    })
};
export const sendAsync = async (certificate, lang, hashCode) => {
    EventBus.dispatch(ROAMING_INVOICE_SEND_REQUESTED, hashCode);
    let errorResponse;

    return new Promise((resolve, reject) => {
        createPkcs7WithTimestamp(hashCode, certificate.keyId)
            .then(({pkcs7: signature}) => {
                roamingInvoiceService.send({signature: signature, lang})
                    .then((response) => {
                        EventBus.dispatch(ROAMING_INVOICE_SEND_SUCCESS, response.data);
                        resolve(response.data);
                    })
                    .catch((error) => {
                        errorResponse = errorSender(error.response.data, lang)
                        EventBus.dispatch(ROAMING_INVOICE_SEND_FAILED, errorResponse);
                        reject(errorResponse);
                    })
            })
            .catch((error) => {
                errorResponse = {message: t(lang, "roaming.invoice.alert.error.createPkcs7Async")}
                reject(errorResponse)
            })
    })
}
export const saveAsync = (hashCode, lang) => {
    EventBus.dispatch(ROAMING_INVOICE_SAVE_REQUESTED);
    return new Promise((resolve, reject) => {
        let errorResponse;
        roamingInvoiceService.save({content: hashCode})
            .then((response) => {
                EventBus.dispatch(ROAMING_INVOICE_SAVE_SUCCESS, response.data);
                resolve(response.data);
            })
            .catch((error) => {
                console.log(error);
                errorResponse = errorSender(error.response.data, lang);
                EventBus.dispatch(ROAMING_INVOICE_SAVE_FAILED, errorResponse);
                reject(errorResponse);
            })
    })
}

export const errorSender = (errorResponse, lang) => {
    let detectedError;
    if (errorResponse.not_allowed_inn)
        detectedError = {message: t(lang, 'roaming.invoice.alert.not_allowed_inn')}
    else if (errorResponse.content_parse_failed)
        detectedError = {message: t(lang, 'roaming.invoice.alert.content_parse_failed')}
    else if (errorResponse.billing_not_allowed)
        detectedError = {message: t(lang, 'roaming.invoice.billing_error.not_allowed')}
    else if (errorResponse.billing_balance_not_enough)
        detectedError = {message: t(lang, 'roaming.invoice.billing_error.balance_not_enough')}
    else if (errorResponse.billing_organization_not_found)
        detectedError = {message: t(lang, 'roaming.invoice.billing_error.organization_not_found')}
    else if (errorResponse.roaming_error_message)
        detectedError = {message: lang === 'ru' ? JSON.parse(errorResponse.roaming_error_message)?.NameRu : JSON.parse(errorResponse.roaming_error_message)?.NameUz || errorResponse.roaming_error_message}
    else
        detectedError = {message: JSON.stringify(errorResponse)}
    return detectedError;
};

export const executorCancelAsync = async (certificate, lang, id) => {
    EventBus.dispatch(ROAMING_INVOICE_EXECUTOR_CANCEL_REQUESTED, id);
    let errorResponse;

    return new Promise((resolve, reject) => {
        roamingInvoiceService.getCancelHashCode(id)
            .then(({data: hashCode}) => {
                createPkcs7WithTimestamp(hashCode, certificate.keyId)
                    .then(({pkcs7: signature}) => {
                        roamingInvoiceService.executorCancel(id, signature)
                            .then((response) => {
                                EventBus.dispatch(ROAMING_INVOICE_EXECUTOR_CANCEL_SUCCESS, id);
                                resolve();
                            })
                            .catch((e) => {
                                errorResponse = errorSender(e.response.data, lang)
                                EventBus.dispatch(ROAMING_INVOICE_EXECUTOR_CANCEL_FAILED, errorResponse)
                                reject(errorResponse);
                            })
                    })
                    .catch(() => {
                        errorResponse = {message: t(lang, "roaming.empowerment.alert.error.createPkcs7Async")}
                        EventBus.dispatch(ROAMING_INVOICE_EXECUTOR_CANCEL_FAILED, errorResponse)
                        reject();
                    })
            })
            .catch(() => {
                errorResponse = {message: t(lang, "roaming.empowerment.alert.error.getCancelHashCode")}
                EventBus.dispatch(ROAMING_INVOICE_EXECUTOR_CANCEL_FAILED, errorResponse)
                reject();
            })
    })
}
export const customerAcceptAsync = async (certificate, lang, id) => {
    EventBus.dispatch(ROAMING_INVOICE_CUSTOMER_ACCEPT_REQUESTED, id);
    let errorResponse;

    return new Promise((resolve, reject) => {
        roamingInvoiceService.getAcceptHashCode(id)
            .then(({data: hashCode}) => {
                attachPkcs7WithTimestamp(hashCode, certificate.keyId)
                    .then(({pkcs7: signature}) => {
                        roamingInvoiceService.customerAccept(id, signature)
                            .then(() => {
                                EventBus.dispatch(ROAMING_INVOICE_CUSTOMER_ACCEPT_SUCCESS, id);
                                resolve();
                            })
                            .catch((e) => {
                                errorResponse = errorSender(e.response.data, lang)
                                EventBus.dispatch(ROAMING_INVOICE_CUSTOMER_ACCEPT_FAILED, errorResponse);
                                reject(errorResponse);
                            })
                    })
                    .catch(() => {
                        errorResponse = {message: t(lang, "roaming.empowerment.alert.error.attachPkcs7Async")};
                        EventBus.dispatch(ROAMING_INVOICE_CUSTOMER_ACCEPT_FAILED, errorResponse);
                        reject();
                    })
            })
            .catch(() => {
                errorResponse = {message: t(lang, "roaming.empowerment.alert.error.getAcceptHashCode")};
                EventBus.dispatch(ROAMING_INVOICE_CUSTOMER_ACCEPT_FAILED, errorResponse);
                reject();
            })
    })
}
export const customerDeclineAsync = async (certificate, lang, id, comment) => {
    EventBus.dispatch(ROAMING_INVOICE_CUSTOMER_DECLINE_REQUESTED, id);
    let errorResponse;

    return new Promise((resolve, reject) => {
        roamingInvoiceService.getDeclineHashCode(id, comment)
            .then(({data: hashCode}) => {
                createPkcs7WithTimestamp(hashCode, certificate.keyId)
                    .then(({pkcs7: signature}) => {
                        roamingInvoiceService.customerDecline(id, signature, comment)
                            .then(() => {
                                EventBus.dispatch(ROAMING_INVOICE_CUSTOMER_DECLINE_SUCCESS, id);
                                resolve();
                            })
                            .catch((e) => {
                                errorResponse = errorSender(e.response.data, lang)
                                EventBus.dispatch(ROAMING_INVOICE_CUSTOMER_DECLINE_FAILED, errorResponse);
                                reject(errorResponse);
                            })
                    })
                    .catch(() => {
                        errorResponse = {message: t(lang, "roaming.empowerment.alert.error.createPkcs7Async")}
                        EventBus.dispatch(ROAMING_INVOICE_CUSTOMER_DECLINE_FAILED, errorResponse);
                        reject();
                    })
            })
            .catch(() => {
                errorResponse = {message: t(lang, "roaming.empowerment.alert.error.getDeclineHashCode")}
                EventBus.dispatch(ROAMING_INVOICE_CUSTOMER_DECLINE_FAILED, errorResponse);
                reject();
            })


    })


}
export const personAcceptAsync = async (certificate, lang, id) => {
    EventBus.dispatch(ROAMING_INVOICE_PERSON_ACCEPT_REQUESTED, id);
    let errorResponse;

    return new Promise((resolve, reject) => {
        roamingInvoiceService.getAcceptHashCode(id)
            .then(({data: hashCode}) => {
                attachPkcs7WithTimestamp(hashCode, certificate.keyId)
                    .then(({pkcs7: signature}) => {
                        roamingInvoiceService.personAccept(id, signature)
                            .then(() => {
                                EventBus.dispatch(ROAMING_INVOICE_PERSON_ACCEPT_SUCCESS, id);
                                resolve();
                            })
                            .catch((e) => {
                                errorResponse = errorSender(e.response.data, lang)
                                EventBus.dispatch(ROAMING_INVOICE_PERSON_ACCEPT_FAILED, errorResponse);
                                reject(errorResponse);
                            })
                    })
                    .catch(() => {
                        errorResponse = {message: t(lang, "roaming.empowerment.alert.error.attachPkcs7Async")};
                        EventBus.dispatch(ROAMING_INVOICE_PERSON_ACCEPT_FAILED, errorResponse);
                        reject();
                    })
            })
            .catch(() => {
                errorResponse = {message: t(lang, "roaming.empowerment.alert.error.getAcceptHashCode")};
                EventBus.dispatch(ROAMING_INVOICE_PERSON_ACCEPT_FAILED, errorResponse);
                reject();
            })
    })
}
export const personDeclineAsync = async (certificate, lang, id, comment) => {
    EventBus.dispatch(ROAMING_INVOICE_PERSON_DECLINE_REQUESTED, id);
    let errorResponse;
    return new Promise((resolve, reject) => {
        roamingInvoiceService.getDeclineHashCode(id, comment)
            .then(({data: hashCode}) => {
                createPkcs7WithTimestamp(hashCode, certificate.keyId)
                    .then(({pkcs7: signature}) => {
                        roamingInvoiceService.personDecline(id, signature, comment)
                            .then(() => {
                                EventBus.dispatch(ROAMING_INVOICE_PERSON_DECLINE_SUCCESS, id);
                                resolve();
                            })
                            .catch((e) => {
                                errorResponse = errorSender(e.response.data, lang)
                                EventBus.dispatch(ROAMING_INVOICE_PERSON_DECLINE_FAILED, errorResponse);
                                reject(errorResponse);
                            })
                    })
                    .catch(() => {
                        errorResponse = {message: t(lang, "roaming.empowerment.alert.error.createPkcs7Async")}
                        EventBus.dispatch(ROAMING_INVOICE_PERSON_DECLINE_FAILED, errorResponse);
                        reject();
                    })
            })
            .catch(() => {
                errorResponse = {message: t(lang, "roaming.empowerment.alert.error.getDeclineHashCode")}
                EventBus.dispatch(ROAMING_INVOICE_PERSON_DECLINE_FAILED, errorResponse);
                reject();
            })


    })
}
export const syncAsync = async (id) => {
    EventBus.dispatch(ROAMING_INVOICE_SYNC_REQUESTED, id);
    try {
        const response = await roamingInvoiceService.sync(id);
        EventBus.dispatch(ROAMING_INVOICE_SYNC_SUCCESS, id);
        return response.data;
    } catch (e) {
        let errorMessage = {message: e.response.data};
        EventBus.dispatch(ROAMING_INVOICE_SYNC_FAILED, errorMessage);
        console.log(e);
    }
};

export const syncHistoriesAsync = async (id) => {
    EventBus.dispatch(ROAMING_INVOICE_SYNC_HISTORIES_REQUESTED, id);
    try {
        const response = await roamingInvoiceService.syncHistories(id);
        EventBus.dispatch(ROAMING_INVOICE_SYNC_HISTORIES_SUCCESS, id);
        return response.data;
    } catch (e) {
        let errorMessage = {message: e.response.data};
        EventBus.dispatch(ROAMING_INVOICE_SYNC_HISTORIES_FAILED, errorMessage);
        console.log(e);
    }
};

export const importRoamingInvoiceAsync = (insertItem, executorInn, customerInn) => {
    return new Promise((resolve, reject) => {
        roamingInvoiceService.importRoamingInvoice(insertItem, executorInn, customerInn)
            .then((response) => resolve(response))
            .catch((error) => reject(error))
    })
}

export const loadPDFAsync = (id) => {
    return new Promise((resolve, reject) => {
        roamingInvoiceService.getPDF(id)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
    })
}

export const loadDraftPDFAsync = (id) => {
    return new Promise((resolve, reject) => {
        roamingInvoiceService.getDraftPDF(id)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
    })
}

export const uploadCustomerInvoicesToExcelAsync = ({uri, page, limit, ...filters}) => {
    const skip = (page - 1) * limit;
    const params = {skip, limit, ...filters};

    return new Promise((resolve, reject) => {
        roamingInvoiceService.uploadCustomerInvoicesToExcel({params: params, uri: uri})
            .then(response => resolve(response.data))
            .catch(error => reject(error))
    })
};
export const uploadCustomerInvoicesWithItemsToExcelAsync = ({uri, page, limit, ...filters}) => {
    const skip = (page - 1) * limit;
    const params = {skip, limit, ...filters};

    return new Promise((resolve, reject) => {
        roamingInvoiceService.uploadCustomerInvoicesWithItemsToExcel({params: params, uri: uri})
            .then(response => resolve(response.data))
            .catch(error => reject(error))
    })
};
export const uploadExecutorInvoicesToExcelAsync = ({uri, page, limit, ...filters}) => {
    const skip = (page - 1) * limit;
    const params = {skip, limit, ...filters};

    return new Promise((resolve, reject) => {
        roamingInvoiceService.uploadExecutorInvoicesToExcel({params: params, uri: uri})
            .then(response => resolve(response.data))
            .catch(error => reject(error))
    })
};
export const uploadExecutorInvoicesWithItemsToExcelAsync = ({uri, page, limit, ...filters}) => {
    const skip = (page - 1) * limit;
    const params = {skip, limit, ...filters};

    return new Promise((resolve, reject) => {
        roamingInvoiceService.uploadExecutorInvoicesWithItemsToExcel({params: params, uri: uri})
            .then(response => resolve(response.data))
            .catch(error => reject(error))
    })
};

