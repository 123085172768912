import React, {Fragment, useState} from 'react';
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {FormProvider, useForm} from "react-hook-form";
import {
    registerCompanyAddressAsync,
    registerSystemOrganizationAddressesAsync
} from "../../../../app/store/reducers/roamingReference/roamingReferenceReducer";
import {toast} from "react-toastify";
import IconButton from "../../../common/IconButton";
import {Button, Form, Modal} from "react-bootstrap";
import OrganizationAddressForm from "../my-address/form/OrganizationAddressForm";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const AddSystemOrganizationAddressModal = ({...props}) => {

    const [isLoading, setIsLoading] = useState(false);
    const [show, setShow] = useState(false);
    const lang = useSelector(selectLang);
    const t = Translate;

    const methods = useForm({
        defaultValues: {
            id: null,
            regionCode: null,
            regionName: null,
            districtCode: null,
            districtName: null,
            villageCode: null,
            villageName: null,
            street: null,
            other: null,
            location: {
                latitude: 41.311151,
                longitude: 69.279737,
                address: 'сквер Амира Темура'
            },
            contractor_inn: null
        },
    });

    const handleShow = () => setShow(true);
    const handleClose = () => {
        methods.reset();
        setIsLoading(false);
        setShow(false);
    };

    const onSubmit = (formData) => {
        const payload = {
            region_code: formData.regionCode,
            region_name: formData.regionName,
            district_code: formData.districtCode,
            district_name: formData.districtName,
            village_code: formData.villageCode,
            village_name: formData.villageName,
            street: formData.street,
            other: formData.other,
            longitude: formData.location.longitude,
            latitude: formData.location.latitude,
            contractor_inn: formData.contractor_inn
        };

        setIsLoading(true);
        registerSystemOrganizationAddressesAsync(payload)
            .then(() => {
                toast.success(t(lang, 'common.toast.success'));
                handleClose();
            })
            .catch(() => toast.error(t(lang, 'common.toast.error')))
            .finally(() => setIsLoading(false))
    };

    return (
        <Fragment>
            <IconButton variant={'falcon-primary'} size={'sm'} icon={'plus'} onClick={handleShow} {...props}>
                {t(lang, 'roaming.common.add')}
            </IconButton>

            <Modal show={show} onHide={handleClose} size={'lg'}>
                <FormProvider {...methods}>
                    <Form onSubmit={methods.handleSubmit(onSubmit)}>
                        <Modal.Header closeButton={true}>
                            <Modal.Title>{t(lang, 'roaming.organization_address.add_modal.title')}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <OrganizationAddressForm systemOrganization={true} isEditing={false}/>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant={'secondary'} type={'button'} onClick={handleClose}>
                                {t(lang, 'roaming.common.cancel')}
                            </Button>
                            <Button variant={'primary'} type={'submit'} disabled={isLoading}>
                                {isLoading && <FontAwesomeIcon pulse={true} className="me-2" icon="spinner"/>}
                                <span>{t(lang, 'roaming.common.add')}</span>
                            </Button>
                        </Modal.Footer>
                    </Form>
                </FormProvider>
            </Modal>
        </Fragment>
    );
};

export default AddSystemOrganizationAddressModal;
