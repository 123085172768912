import React from 'react';
import Flex from "../../../common/Flex";
import SoftBadge from "../../../common/SoftBadge";
import {Badge, Dropdown} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import SelectDropdownLabels from "../../form/taskCard/SelectDropdownLabels";
import {useSelector} from "react-redux";
import {selectLabels} from "../../../../app/store/reducers/kanban/kanbanReducer";
import {statusLabels} from "../../../../enum/KanbanWrapper";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";

const ModalLabelContent = ({labels, addLabels}) => {
    const lang = useSelector(selectLang);
    const t = Translate;
    // const labels = useSelector(selectLabels)

    return (
        <Flex>
            {labels.slice(0, 3).map(label => (
                <SoftBadge bg={statusLabels(label.color_type)} className="me-1 py-2" key={label.color_type}>
                    {t(lang, label.name)}
                </SoftBadge>
            ))}

            {/*<Dropdown drop={'end'}>
                <Dropdown.Toggle
                    variant="secondary"
                    size="sm"
                    className="px-2 fsp-75 bg-400 border-400 dropdown-caret-none"
                >
                    <FontAwesomeIcon icon="plus" />
                </Dropdown.Toggle>
                <SelectLabels fields={labels} addLabels={addLabels} />
            </Dropdown>*/}
        </Flex>
    );
};

export default ModalLabelContent;
