import {Button, Card, Col, ListGroup, Row} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {selectLang, selectNumberFormat, Translate} from "../../../../../../app/store/reducers/main/mainReducer";
import React, {useEffect} from "react";
import numeral from "numeral";
import ContractorNote from "./ContractorNote";
import {
    loadContractorAsync,
    selectCardContractor,
    updateCardContractor
} from "../../../../../../app/store/reducers/contractor/contractorReducer";
import {Link, useRouteMatch} from "react-router-dom";
import SoftBadge from "../../../../../common/SoftBadge";
import AddContractorPayment from "../../payment/AddContractorPayment";
import AddContractorPayout from "../../payout/AddContractorPayout";
import AddContractorLocationForm from "./AddContractorLocationForm";
import EventBus from "../../../../../../app/eventbus/EventBus";
import {
    LOCATION_ADD_SUCCESS,
    LOCATION_DELETE_SUCCESS,
    LOCATION_EDIT_SUCCESS
} from "../../../../../../app/eventbus/contractor/contractorLocationEvents";
import classNames from "classnames";
import EditContractorLocationForm from "./EditContractorLocationForm";
import DeleteContractorLocationModal from "./DeleteContractorLocationModal";

const ContractorGeneralDetails = () => {
    const dispatch = useDispatch()
    const t = Translate;
    const lang = useSelector(selectLang);
    const contractor = useSelector(selectCardContractor)
    const numberFormat = useSelector(selectNumberFormat);
    const {params: {id}} = useRouteMatch();

    let phones = []
    let emails = []
    if (contractor) {
        phones = contractor.contacts.filter(contact => contact.type === 1)
        emails = contractor.contacts.filter(contact => contact.type === 3)
    }
    const loadContractor = () => {
        loadContractorAsync(id).then(cr => {
            dispatch(updateCardContractor(cr))
        }).catch(err => console.log(err));
    };

    useEffect(() => {
        loadContractor()

        const onLocationAddedHandler = EventBus.on(LOCATION_ADD_SUCCESS, loadContractor)
        const onLocationEditedHandler = EventBus.on(LOCATION_EDIT_SUCCESS, loadContractor)
        const onLocationDeletedHandler = EventBus.on(LOCATION_DELETE_SUCCESS, loadContractor)

        return () => {
            EventBus.remove(LOCATION_ADD_SUCCESS, onLocationAddedHandler);
            EventBus.remove(LOCATION_EDIT_SUCCESS, onLocationEditedHandler);
            EventBus.remove(LOCATION_DELETE_SUCCESS, onLocationDeletedHandler);
        };
    }, [id]);

    return (
        <Row className="g-2">
            <Col xs={12}>
                <Card>
                    <Card.Body>
                        <div className={"d-flex align-items-center justify-content-between border-bottom pb-3"}>
                            <div className={"d-flex flex-column me-3"}>
                                <h6 className={"text-secondary"}>{t(lang, "edi.common.inn")}</h6>
                                <h6>{contractor?.inn ? contractor?.inn : t(lang, "edi.common.not_specified")}</h6>
                            </div>
                            <div className={"d-flex flex-column me-3"}>
                                <h6 className={"text-secondary"}>{t(lang, "crm.contractor.datatable.label")}</h6>
                                <h6>{contractor?.labels.length > 0 ? contractor?.labels?.map((label, index) => {
                                    return (
                                        <SoftBadge className={'me-1'} key={index}>{label.name}</SoftBadge>
                                    )
                                }) : 'Не указано'}</h6>
                            </div>
                            <div className={"d-flex flex-column me-3"}>
                                <h6 className={"text-secondary"}>{t(lang, "edi.contractor.datatable.header.row.state")}</h6>
                                <div className={"h6"}>
                                    {contractor?.debts?.length > 0 ? contractor?.debts.map((item, index) => {
                                        return (
                                            <div
                                                key={index}>{numeral.formats[numberFormat].format(item.amount)} <span
                                                className="text-info">{item.currency.name}</span>
                                            </div>
                                        )
                                    }) : '0 сум'}
                                </div>

                                <div className={'mt-1 d-flex flex-column'}>
                                    <AddContractorPayment contractor={contractor}>
                                        <Button className={'my-1'} size={'sm'}
                                                variant={'primary'}>{t(lang, 'crm.contractor_card.general.add_payment')}</Button>
                                    </AddContractorPayment>

                                    <AddContractorPayout>
                                        <Button className={'my-1'} size={'sm'}
                                                variant={'warning'}>{t(lang, 'crm.contractor_card.general.add_payout')}</Button>
                                    </AddContractorPayout>
                                </div>
                            </div>
                            <div className={"d-flex flex-column me-3"}>
                                <h6 className={"text-secondary"}>{t(lang, "edi.contractor_card.general.type")}</h6>
                                {
                                    contractor?.type?.length > 0 ?
                                        contractor?.type.map((item, index) => {
                                            if (item === 1) {
                                                return (
                                                    <h6 key={index}>{t(lang, "edi.common.buyer")}</h6>
                                                )
                                            }
                                            if (item === 2) {
                                                return (
                                                    <h6 key={index}>{t(lang, "edi.common.executor")}</h6>
                                                )
                                            }
                                        }) : <h6>{t(lang, "edi.common.not_specified")}</h6>
                                }
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
            <Col xs={12} lg={4}>
                <Card className="h-100">
                    <Card.Body>
                        <div className={"mb-3"}>
                            <div
                                className={"text-secondary h6"}>{t(lang, "roaming.contract.company.info.phone")}</div>
                            {phones.length > 0 ? phones.map((ph, index) => {
                                return (<div className={"h6 text-info"} key={index}>{ph.value}</div>)
                            }) : <div className={"h6"}>{t(lang, "edi.common.not_specified")}</div>}
                        </div>
                        <hr/>
                        <div>
                            <div
                                className={"text-secondary h6"}>{t(lang, "edi.contractor_card.general.email")}</div>
                            {emails.length > 0 ? emails.map((ph, index) => {
                                return (<div className={"h6 text-info"} key={index}>{ph.value}</div>)
                            }) : <div className={"h6"}>{t(lang, "edi.common.not_specified")}</div>}
                        </div>
                    </Card.Body>
                </Card>
            </Col>
            <Col xs={12} lg={8}>
                <Card className="h-100">
                    <Card.Body>
                        <Row className="gy-3">
                            <Col xs={12} className="text-end">
                                <AddContractorLocationForm contractor={contractor}/>
                            </Col>
                            <Col xs={12}>
                                <ListGroup variant="flush" className="fw-normal fs--1 scrollbar pb-3"
                                           style={{maxHeight: '25rem'}}>
                                    {contractor?.locations.map((location) => (
                                        <ListGroup.Item key={location.id} className="border-0">
                                            <Link to="#"
                                                  className={classNames('notification flex justify-content-between align-items-center rounded-0')}
                                            >
                                                <div className="notification-body">
                                                    <p className="mb-1 fw-bold text-dark">
                                                        {location.name}
                                                    </p>
                                                    <span className="notification-time">{location.latitude}, {location.longitude}</span>
                                                </div>
                                                <div>
                                                    <div className="mb-2">
                                                        <EditContractorLocationForm contractor={contractor}
                                                                                    location={location}
                                                        />
                                                    </div>
                                                    <div>
                                                        <DeleteContractorLocationModal contractor={contractor}
                                                                                       location={location}
                                                        />
                                                    </div>
                                                </div>
                                            </Link>
                                        </ListGroup.Item>
                                    ))}
                                </ListGroup>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
            <Col xs={12}>
                <Card className="h-100">
                    <Card.Body>
                        <ContractorNote/>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
}
export default ContractorGeneralDetails;