// item access types
import {faKey, faLock, faLockOpen} from "@fortawesome/free-solid-svg-icons";

export const AllItemsDisabled = 1
export const AllItemsEnabled = 2
export const SpecificItemsEnabled = 3

export const ItemPriceAccessTypeDisabled = 1;
export const ItemPriceAccessTypeEnabled = 2;

export const ItemsAccessTypes = [
    {
        value: AllItemsEnabled,
        label: 'partners.common.items_access_options.2',
        icon: faKey,
    },
    {
        value: SpecificItemsEnabled,
        label: 'partners.common.items_access_options.3',
        icon: faLockOpen
    },
    {
        value: AllItemsDisabled,
        label: 'partners.common.items_access_options.1',
        icon: faLock
    }
];

export const ItemsPriceAccessTypes = [
    {
        value: ItemPriceAccessTypeDisabled,
        label: 'partners.common.items_price_access_options.1',
        icon: faLock,
    },
    {
        value: ItemPriceAccessTypeEnabled,
        label: 'partners.common.items_price_access_options.2',
        icon: faKey
    }
];

// sale access types
export const AllSalesDisabled = 1
export const AllSalesEnabled = 2
export const SalesPaymentAccessTypeDisabled = 1;
export const SalesPaymentAccessTypeEnabled = 2;
export const SalesPaymentAccessTypes = [
    {
        value: SalesPaymentAccessTypeDisabled,
        label: 'partners.common.sales_payment_access_options.1',
        icon: faLock
    },
    {
        value: SalesPaymentAccessTypeEnabled,
        label: 'partners.common.sales_payment_access_options.2',
        icon: faKey
    }
];

export const SalesAccessTypes = [
    {
        value: AllSalesEnabled,
        label: 'partners.common.sales_access_options.2',
        icon: faKey
    },
    {
        value: AllSalesDisabled,
        label: 'partners.common.sales_access_options.1',
        icon: faLock
    }
];

// warehouse access types
export const AllWarehousesDisabled = 1
export const AllWarehousesEnabled = 2
export const SpecificWarehousesEnabled = 3
export const WarehousesAccessTypes = [
    {
        value: AllWarehousesEnabled,
        label: 'partners.common.warehouses_access_options.2',
        icon: faKey,
    },
    {
        value: SpecificWarehousesEnabled,
        label: 'partners.common.warehouses_access_options.3',
        icon: faLockOpen
    },
    {
        value: AllWarehousesDisabled,
        label: 'partners.common.warehouses_access_options.1',
        icon: faLock
    }
];


class OrganizationBindingWrapper {
    isAllItemsDisabled(type) {
        return type === AllItemsDisabled;
    }

    isAllItemsEnabled(type) {
        return type === AllItemsEnabled;
    }

    isSpecificItemsEnabled(type) {
        return type === SpecificItemsEnabled;
    }

    isItemPriceAccessDisabled(type) {
        return type === ItemPriceAccessTypeDisabled;
    }

    isItemPriceAccessEnabled(type) {
        return type === ItemPriceAccessTypeEnabled;
    }

    isAllSalesDisabled(type) {
        return type === AllSalesDisabled;
    }

    isAllSalesEnabled(type) {
        return type === AllSalesEnabled;
    }

    isSalePaymentAccessDisabled(type) {
        return type === SalesPaymentAccessTypeDisabled;
    }

    isSalePaymentAccessEnabled(type) {
        return type === SalesPaymentAccessTypeEnabled;
    }

    isAllWarehousesDisabled(type) {
        return type === AllWarehousesDisabled;
    }

    isAllWarehousesEnabled(type) {
        return type === AllWarehousesEnabled;
    }

    isSpecificWarehousesEnabled(type) {
        return type === SpecificWarehousesEnabled;
    }
}

const organizationBindingWrapper = new OrganizationBindingWrapper();
export default organizationBindingWrapper;