import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import SystemOrganizationAddressDataTable
    from "../../../../components/hippo/roaming/system-company-address/SystemOrganizationAddressDataTable";
import EventBus from "../../../../app/eventbus/EventBus";
import {
    DELETE_SYSTEM_COMPANY_ADDRESS_SUCCEED,
    REGISTER_SYSTEM_COMPANY_ADDRESS_SUCCEED,
} from "../../../../app/eventbus/roamingReferenceEvents";
import {
    loadSystemOrganizationAddressesAsync
} from "../../../../app/store/reducers/roamingReference/roamingReferenceReducer";

const SystemCompanyAddress = () => {

    const activeOrganization = useSelector(selectActiveOrganization);
    const dispatch = useDispatch();

    useEffect(() => {
        const onRegisterSystemOrganizationAddressSucceed = EventBus.on(REGISTER_SYSTEM_COMPANY_ADDRESS_SUCCEED, () => {
            loadSystemOrganizationAddresses();
        });

        const onDeleteSystemOrganizationAddressSucceed = EventBus.on(DELETE_SYSTEM_COMPANY_ADDRESS_SUCCEED, () => {
            loadSystemOrganizationAddresses();
        });

        return () => {
            EventBus.remove(REGISTER_SYSTEM_COMPANY_ADDRESS_SUCCEED, onRegisterSystemOrganizationAddressSucceed);
            EventBus.remove(DELETE_SYSTEM_COMPANY_ADDRESS_SUCCEED, onDeleteSystemOrganizationAddressSucceed);
        }
    }, [])

    useEffect(() => {
        loadSystemOrganizationAddresses();
    }, [activeOrganization])

    const loadSystemOrganizationAddresses = () => {
        dispatch(loadSystemOrganizationAddressesAsync());
    };

    return (
        <SystemOrganizationAddressDataTable />
    );
};

export default SystemCompanyAddress;
