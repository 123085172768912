import React from 'react';
import ReactSelect, {components} from "react-select";
import {useSelector} from "react-redux";
import {selectItems} from "../../../../app/store/reducers/item/itemReducer";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";

const SearchItem = ({onChange, defaultItemId, selectProps}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const items = useSelector(selectItems);

    const formatOptionLabel = ({item}) => {
        return <h6 className="fs-0 mb-0">{item.name}</h6>
    }

    const MenuList = ({children, ...props}) => {
        return (
            <components.MenuList {...props}>
                {Array.isArray(children) ? children.slice(0, props.selectProps?.maxOptions) : children}
            </components.MenuList>
        )
    }

    return (
        <ReactSelect options={items}
                     components={{MenuList}}
                     maxOptions={15}
                     onChange={onChange}
                     getOptionValue={option => option.item.id}
                     getOptionLabel={option => `${option.item.name}`}
                     placeholder={t(lang, 'items.common.select')}
                     formatOptionLabel={formatOptionLabel}
                     hideSelectedOptions
                     isSearchable={!defaultItemId}
                     isClearable
                     value={items.find(i => i.item.id === defaultItemId) || null}
                     {...selectProps}
        />
    );
};


export default SearchItem;