import React from 'react';
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import ReactSelect from "react-select";
import {
    ALERT_ON_STATE_0,
    ALERT_ON_STATE_1,
    ALERT_ON_STATE_2,
    ALERT_ON_STATE_3,
    ALERT_ON_STATE_4
} from "../enum/itemWrapper";

const AlertOnSelector = ({defaultValue, onChange}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const options = [
        {label: t(lang, "items.common.filter.alert_on.all"), value: ALERT_ON_STATE_0},
        {label: t(lang, "items.common.filter.products.in.stack"), value: ALERT_ON_STATE_1},
        {label: t(lang, "items.common.filter.lack.of.goods.in.stack"), value: ALERT_ON_STATE_2},
        {label: t(lang, "items.common.filter.items.out.of.stack"), value: ALERT_ON_STATE_3},
        {label: t(lang, "items.common.filter.negative.balances.of.goods"), value: ALERT_ON_STATE_4},
    ];

    return (
        <ReactSelect options={options}
                     hideSelectedOptions={true}
                     isClearable={false}
                     isSearchable={false}
                     getOptionValue={option => option.value}
                     getOptionLabel={option => option.label}
                     classNamePrefix="react-select"
                     value={options.find(o => o.value === defaultValue)}
                     onChange={onChange}
        />
    );
};

export default AlertOnSelector;