import {useState} from "react";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {toast} from "react-toastify";
import {Dropdown, Modal, Spinner} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import FalconCloseButton from "../../../../common/FalconCloseButton";
import Button from "react-bootstrap/Button";
import {refundDeleteAsync} from "../../../../../app/store/reducers/warehouse-operation/refundReducer";


const RefundDelete = ({id}) => {
    const [show, setShow] = useState(false)
    const [loading, setLoading] = useState(false)
    const lang = useSelector(selectLang);
    const t = Translate;

    const handleShow = () => {
        setShow(true)
    }

    const handlerClose = () => {
        setShow(false)
    }



    const onDeleteItem = async () => {
        setLoading(true)

        await refundDeleteAsync({refundId: id})
            .then(() => {
                handlerClose()
                toast.success(t(lang, "warehouse.operation.item.common.toast.success"))
            })
            .catch(() => {
                toast.error(t(lang, "warehouse.operation.item.common.toast.error"))
            })
            .finally(() => {
                setLoading(false)
            })
    }


    return (
        <>
            <Dropdown.Item className="text-danger align-center"
                           onClick={() => handleShow()}
            >
                <FontAwesomeIcon className="me-1" size="sm" icon={faTrash} />
                <span>{t(lang, 'items.common.delete')}</span>
            </Dropdown.Item>

            <Modal show={show} onHide={handlerClose}>
                <Modal.Header>
                    <FalconCloseButton onClick={handlerClose}/>
                </Modal.Header>
                <Modal.Body>
                    <Modal.Title className="text-center">{t(lang, "warehouse.operation.item.common.delete_item_modal.title")}</Modal.Title>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handlerClose}>
                        {t(lang, "warehouse.operation.item.common.close")}
                    </Button>
                    <Button variant="danger" onClick={() => onDeleteItem()}>
                        {loading &&
                            <Spinner className='align-middle me-2' animation='border' size="sm"
                                     role='switch'/>
                        }
                        {t(lang, "warehouse.operation.item.common.delete_button")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default RefundDelete;