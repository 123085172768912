import React, {useState} from 'react';
import {Button, Col, Form, Row, Spinner} from "react-bootstrap";
import {selectDateFormat, selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import DatePicker from "react-datepicker";
import ContractorSelect from "../../common/ContractorSelect";
import SearchItem from "../../draft-item/components/SearchItem";
import SelectCategory from "../../item/components/SelectCategory";
import {selectCategories} from "../../../../app/store/reducers/category/categoryReducer";
import {selectCategoriesTree, selectItems} from "../../../../app/store/reducers/item/itemReducer";
import SelectWarehouse from "../../../common/SelectWarehouse";
import {
    OperationTypes,
    Purchase,
    Refund,
    Return,
    Revision,
    Sale,
    TransferIn,
    TransferOut,
    WriteOff
} from "../../../../enum/OperationTypes";
import itemWrapper from "../../item/enum/itemWrapper";
import dayjs from "dayjs";

const OperationsItemsReportDataTableFilter = ({loading, filters, onFilterChange, onDebouncedFilterChange}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const dateFormat = useSelector(selectDateFormat);
    const items = useSelector(selectItems);
    const categories = useSelector(selectCategories);
    const categoriesTree = useSelector(selectCategoriesTree);
    const [filter, setFilter] = useState({
        dateStart: filters.dateStart,
        dateEnd: filters.dateEnd,
        categoryId: filters.categoryId,
        itemIds: filters.itemIds,
        itemId: filters.itemId,
        types: filters.types,
        contractorId: filters.contractorId,
        warehouseId: filters.warehouseId
    });

    const onSubmitFilter = (filter) => {
        onFilterChange(filter);
    };

    const onResetFilter = () => {
        const filter = {
            dateStart: dayjs().startOf('month').hour(0).minute(0).second(0).format("YYYY-MM-DD HH:mm:ss"),
            dateEnd: dayjs().endOf('month').hour(23).minute(59).second(59).format("YYYY-MM-DD HH:mm:ss"),
            categoryId: null,
            itemIds: [],
            itemId: null,
            types: [Sale, Purchase, Refund, Return, Revision, WriteOff, TransferIn, TransferOut],
            contractorId: null,
            warehouseId: null,
        };
        setFilter(filter);
        onFilterChange(filter);
    };

    return (
        <Row className="g-2">
            <Col xs={12} sm={6} lg={4} xl={3} xxl={2}>
                <Form.Group>
                    <Form.Label>{t(lang, "warehouse.items.history.filter.date_start")}</Form.Label>
                    <DatePicker className='form-control'
                                placeholderText={t(lang, "warehouse.operation.item.common.filter.date:placeholder")}
                                dateFormat={`${dateFormat} HH:mm:ss`}
                                selected={filter?.dateStart ? new Date(filter.dateStart) : null}
                                onChange={(value) => setFilter({
                                    ...filter,
                                    dateStart: value ? dayjs(value).format("YYYY-MM-DD HH:mm:ss") : null
                                })}
                                showTimeSelect
                                timeFormat="p"
                                timeIntervals={60}
                    />
                </Form.Group>
            </Col>
            <Col xs={12} sm={6} lg={4} xl={3} xxl={2}>
                <Form.Group>
                    <Form.Label>{t(lang, "warehouse.items.history.filter.date_end")}</Form.Label>
                    <DatePicker className='form-control'
                                placeholderText={t(lang, "warehouse.operation.item.common.filter.date:placeholder")}
                                dateFormat={`${dateFormat} HH:mm:ss`}
                                selected={filter?.dateEnd ? new Date(filter.dateEnd) : null}
                                onChange={(value) => setFilter({
                                    ...filter,
                                    dateEnd: value ? dayjs(value).format("YYYY-MM-DD HH:mm:ss") : null
                                })}
                                showTimeSelect
                                timeFormat="p"
                                timeIntervals={60}
                    />
                </Form.Group>
            </Col>
            <Col xs={12} sm={6} lg={4} xl={3} xxl={2}>
                <Form.Group>
                    <Form.Label>{t(lang, "crm.data.table.contractor")}</Form.Label>
                    <ContractorSelect defaultValue={filter?.contractorId}
                                      isClearable={true}
                                      onChange={(option) => setFilter({
                                          ...filter,
                                          contractorId: option ? option.id : null
                                      })}
                    />
                </Form.Group>
            </Col>
            <Col xs={12} sm={6} lg={4} xl={3} xxl={2}>
                <Form.Group>
                    <Form.Label>{t(lang, "crm.common.items.product")}</Form.Label>
                    <SearchItem defaultItemId={filter?.itemId}
                                onChange={(item) => setFilter({...filter, itemId: item ? item.item.id : null})}
                    />
                </Form.Group>
            </Col>
            <Col xs={12} sm={6} lg={4} xl={3} xxl={2}>
                <Form.Group>
                    <Form.Label>{t(lang, "items.common.category")}</Form.Label>
                    <SelectCategory categories={categories}
                                    defaultCategoryId={filter?.categoryId}
                                    onChange={(category) => {
                                        setFilter({
                                            ...filter,
                                            categoryId: category ? category.id : null,
                                            itemIds: category ? items.filter(i => itemWrapper.filterCategory(categoriesTree, category.id, i.item.category?.id)).map(i => i.item.id) : []
                                        })
                                    }}
                    />
                </Form.Group>
            </Col>
            <Col xs={12} sm={6} lg={4} xl={3} xxl={2}>
                <Form.Group>
                    <Form.Label>{t(lang, "warehouse.navbar.routes.warehouse")}</Form.Label>
                    <SelectWarehouse defaultWarehouseId={filter?.warehouseId}
                                     onChange={(option) => setFilter({
                                         ...filter,
                                         warehouseId: option ? option.id : null
                                     })}
                    />
                </Form.Group>
            </Col>
            <Col xs={12} className="my-3">
                <div className="d-flex justify-content-space-between flex-wrap align-items-center">
                    {OperationTypes[0].map((op, index) => {
                        return (
                            <Form.Group key={index}>
                                <Form.Label className="d-flex align-content-center pe-5">
                                    <Form.Switch checked={filter.types.some(x => x === op.value)}
                                                 onChange={(e) => {
                                                     if (e.target.checked) {
                                                         setFilter({...filter, types: [...filter.types, op.value]})
                                                     } else {
                                                         const types = filter.types.filter(t => t !== op.value);
                                                         setFilter({...filter, types});
                                                     }
                                                 }}
                                    />
                                    <div className={'m-0'}>{t(lang, op.label)}</div>
                                </Form.Label>
                            </Form.Group>
                        )
                    })}
                    {OperationTypes[1].map((op, index) => {
                        return (
                            <Form.Group key={index}>
                                <Form.Label className="d-flex align-content-center pe-5">
                                    <Form.Switch checked={filter.types.some(x => x === op.value)}
                                                 onChange={(e) => {
                                                     if (e.target.checked) {
                                                         setFilter({...filter, types: [...filter.types, op.value]})
                                                     } else {
                                                         const types = filter.types.filter(t => t !== op.value);
                                                         setFilter({...filter, types});
                                                     }
                                                 }}
                                    />
                                    <div className={'m-0'}>{t(lang, op.label)}</div>
                                </Form.Label>
                            </Form.Group>
                        )
                    })}
                    {OperationTypes[2].map((op, index) => {
                        return (
                            <Form.Group key={index}>
                                <Form.Label className="d-flex align-content-center pe-5">
                                    <Form.Switch checked={filter.types.some(x => x === op.value)}
                                                 onChange={(e) => {
                                                     if (e.target.checked) {
                                                         setFilter({...filter, types: [...filter.types, op.value]})
                                                     } else {
                                                         const types = filter.types.filter(t => t !== op.value);
                                                         setFilter({...filter, types});
                                                     }
                                                 }}
                                    />
                                    <div className={'m-0'}>{t(lang, op.label)}</div>
                                </Form.Label>
                            </Form.Group>
                        )
                    })}
                    {OperationTypes[3].map((op, index) => {
                        return (
                            <Form.Group key={index}>
                                <Form.Label className="d-flex align-content-center pe-5">
                                    <Form.Switch checked={filter.types.some(x => x === op.value)}
                                                 onChange={(e) => {
                                                     if (e.target.checked) {
                                                         setFilter({...filter, types: [...filter.types, op.value]})
                                                     } else {
                                                         const types = filter.types.filter(t => t !== op.value);
                                                         setFilter({...filter, types});
                                                     }
                                                 }}
                                    />
                                    <div className={'m-0'}>{t(lang, op.label)}</div>
                                </Form.Label>
                            </Form.Group>
                        )
                    })}
                </div>
            </Col>
            <Col xs={12}>
                <div className="d-grid d-sm-flex justify-content-sm-end align-items-xl-end h-xl-100 gap-2">
                    <Button variant="falcon-primary"
                            disabled={loading}
                            onClick={onResetFilter}
                    >
                        {loading && <Spinner className="align-middle me-2" animation="border"
                                             role="switch" size="sm"/>}
                        {t(lang, "items.common.clear_imported_file")}
                    </Button>
                    <Button variant="primary"
                            disabled={loading}
                            onClick={() => onSubmitFilter(filter)}
                    >
                        {loading && <Spinner className="align-middle me-2" animation="border"
                                             role="switch" size="sm"/>}
                        {t(lang, "roaming.invoices.table_header.show")}
                    </Button>
                </div>
            </Col>
        </Row>
    );
};

export default OperationsItemsReportDataTableFilter;