import React, {useState} from 'react';
import numeral from "numeral";
import Flex from "../../../common/Flex";
import {useSelector} from "react-redux";
import {Card, Tab, Tabs} from "react-bootstrap";
import Background from "../../../common/Background";
import AccountSessionsComponent from "../../components/AccountSessionsComponent";
import cardImage from "../../../../assets/img/icons/spot-illustrations/corner-2.png";
import TopUpOrganizationBalance from "../../../hippo/user/billing/TopUpOrganizationBalance";
import {selectLang, selectSystemMoneyFormat, Translate} from "../../../../app/store/reducers/main/mainReducer";
import ApplyCredit from "../../../hippo/user/billing/ApplyCredit";
import AdminCharge from "../../../../pages/main/admin/component/AdminCharge";
import ActivePackages from "./ActivePackages";
import OrganizationPayments from "./OrganizationPayments";
import OrganizationCredentials from "./OrganizationCredentials";
import OrganizationPDFTemplates from "../datatable/OrganizationPDFTemplates";

const CHARGES = 1
const ACTIVE_PACKAGES = 2
const PAYMENTS = 3
const CREDENTIALS = 4
const PDF_TEMPLATES = 5

const Organization = ({organization, accounts, id}) => {
    const [key, setKey] = useState(CHARGES);

    const systemMoneyFormat = useSelector(selectSystemMoneyFormat);
    const lang = useSelector(selectLang);
    const t = Translate;

    const hasActivePackages = organization.active_packages.length > 0;
    const hasOrganizationAccounts = accounts.length > 0;

    return (
        <>
            <Card>
                <Background image={cardImage} className="bg-card"/>
                <Card.Body className="position-relative">
                    <Flex alignItems="center" justifyContent="between">
                        <div className="d-none d-sm-block">
                            <h4 className="text-black">{t(lang, 'main.billing.balance_info_card.organization')}</h4>
                            <div>
                                <span className="mb-1">{t(lang, 'main.billing.balance_info_card.organization.name')}:</span>
                                <span className="ms-1 text-black fw-bolder">{organization?.name}</span>
                            </div>
                            <div>
                                <span className="mb-1">{t(lang, 'main.billing.balance_info_card.organization.inn')}:</span>
                                <span className="ms-1 text-black fw-bolder">{organization?.inn || t(lang, 'common.organization.no_inn_provided_organization')}</span>
                            </div>
                            <div>
                                <span className="mb-1">{t(lang, 'main.billing.balance_info_card.organization.code')}:</span>
                                <span className="ms-1 text-black fw-bolder">{organization?.organization_code}</span>
                            </div>
                        </div>
                        <div className="d-flex">
                            <div className="d-flex flex-column mx-3">
                                <h4>{t(lang, 'main.billing.balance_info_card.organization.total_balance')}</h4>
                                <span className="text-primary h3">
                                {numeral.formats[systemMoneyFormat].format(organization?.balance)}
                                    <span className="ms-1">{t(lang, 'main.billing.balance_info_card.organization.sum')}</span>
                            </span>
                                <TopUpOrganizationBalance id={organization.id}/>
                            </div>
                            <div className="d-flex flex-column">
                                <h4>{t(lang, "admin.organization.apply_credit_button.title")}</h4>
                                <span className="text-warning h3">
                                {numeral.formats[systemMoneyFormat].format(organization.credit_amount)}
                                    <span className="ms-1">{t(lang, 'main.billing.balance_info_card.organization.sum')}</span>
                            </span>
                                <ApplyCredit id={organization.id}/>
                            </div>
                        </div>

                    </Flex>
                </Card.Body>
            </Card>


            <Card className="mt-3">
                <Tabs activeKey={key} fill onSelect={key => setKey(key)} className='border-bottom p-3 pb-0'>
                    <Tab className="bg-light" eventKey={CHARGES} title={t(lang, 'Расходы')}>
                        <AdminCharge id={id}/>
                    </Tab>
                    <Tab className="bg-light" eventKey={ACTIVE_PACKAGES} title={t(lang, 'main.billing.tabs.active_packages_tab.title')}>
                        <ActivePackages hasActivePackages={hasActivePackages} organization={organization} />
                    </Tab>
                    <Tab className="bg-light" eventKey={PAYMENTS} title={t(lang, 'admin.navbar.routes.payments')}>
                        <OrganizationPayments id={id}/>
                    </Tab>
                    <Tab className="bg-light" eventKey={CREDENTIALS} title={t(lang, 'admin.navbar.routes.credentials')}>
                        <OrganizationCredentials organizationId={id}/>
                    </Tab>
                    <Tab className="bg-light" eventKey={PDF_TEMPLATES} title={t(lang, 'admin.navbar.routes.pdf_templates')}>
                        <OrganizationPDFTemplates organizationId={id}/>
                    </Tab>
                </Tabs>
            </Card>

            {hasOrganizationAccounts && (
                <AccountSessionsComponent className="mt-3" accounts={accounts}/>
            )}
        </>
    );
};

export default Organization;
