import numeral from "numeral";
import classNames from "classnames";
import {Card} from "react-bootstrap";
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import {selectLang, selectSystemMoneyFormat, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {getOrganizationBillingInfoAsync, selectBillingIsLoading, selectOrganizationBillingInfo} from "../../../../app/store/reducers/billing/billingReducer";
import PaymentInstruction from "./PaymentInstruction";

const OrganizationBalanceInfo = () => {
    const activeOrganization = useSelector(selectActiveOrganization);
    const billingInfo = useSelector(selectOrganizationBillingInfo);
    const systemMoneyFormat = useSelector(selectSystemMoneyFormat);
    const loading = useSelector(selectBillingIsLoading);
    const lang = useSelector(selectLang);
    const dispatch = useDispatch();
    const t = Translate;

    const loadBillingInfo = () => {
        dispatch(getOrganizationBillingInfoAsync())
    }

    useEffect(() => {
        loadBillingInfo();
    }, [activeOrganization])

    return (
        <>
            {billingInfo && (
                <Card className={classNames('mx-2 d-none d-sm-block', {'opacity-75': loading})}>
                    <Card.Body className="py-1">
                        <div className="d-flex justify-content-between align-items-center">
                            <p className="my-0 text-primary me-2">
                                {t(lang, 'main.navbar.nav_item.billing.balance')}:
                                <span className="ms-2">{t(lang, 'main.top_nav.current_balance', {sum: numeral.formats[systemMoneyFormat].format(billingInfo?.balance)})}</span>
                            </p>
                            <FontAwesomeIcon onClick={loadBillingInfo} spin={loading} className="cursor-pointer" icon="rotate"/>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                            <p className="my-0 text-warning">
                                {t(lang, "admin.organization.apply_credit_button.title")}:
                                <span className="ms-2">{t(lang, 'main.top_nav.current_balance', {sum: numeral.formats[systemMoneyFormat].format(billingInfo.credit_amount)})}</span>
                            </p>
                            <PaymentInstruction className="cursor-pointer"/>
                        </div>
                    </Card.Body>
                </Card>
            )}
        </>
    );
};

export default OrganizationBalanceInfo;
