import React from "react";
import {useForm} from "react-hook-form";
import {Button, Col, Form, Row, Spinner} from "react-bootstrap";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";

const ContractorLabelForm = ({onClose, loading, onSubmit, contractorLabel}) => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const {register, formState: {errors}, handleSubmit, control, setValue} = useForm({
        defaultValues: {
            name: contractorLabel?.name,
        },
    });

    return (<>
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className="g-3 mb-3">
                <Col xs={12}>
                    <Form.Group className="text-start">
                        <Form.Label>{t(lang, "edi.common.name")}</Form.Label>
                        <Form.Control
                            placeholder={t(lang, "edi.common.name")}
                            name="name"
                            type="text"
                            isInvalid={errors?.name?.message}
                            isValid={Object.keys(errors).length > 0 && !errors?.name?.message}
                            {...register("name", {
                                required: {
                                    value: true,
                                    message: t(lang, "edi.common.forms.validations.is_required")
                                }
                            })}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors?.name?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>

            <div className={'text-end'}>
                <Button variant="secondary" onClick={onClose} className={'me-2'}>
                    {t(lang, "edi.common.cancel_button")}
                </Button>
                <Button variant="primary" disabled={loading} type="submit">
                    {loading && <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="me-2"
                    />}
                    {t(lang, "edi.common.button.save")}
                </Button>
            </div>
        </Form>
    </>)
}
export default ContractorLabelForm