import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    deletePushUpdateNotification
} from "../../app/store/reducers/push-update-notification/pushUpdateNotificationReducer";
import {selectWarehouses} from "../../app/store/reducers/warehouse/warehouseReducer";
import {
    AccountCashBoxBindingDisabled,
    AccountCashBoxBindingEnabled, AccountCashBoxBindRegistered, AccountCashBoxBindUnregistered,
    AccountContractorBindingDisable,
    AccountContractorBindingEnable,
    AccountContractorBindRegister,
    AccountContractorBindUnregister, CashBoxAmountsUpdated, CashBoxDeleted,
    CashBoxRegistered,
    CashBoxUpdated,
    ContractorDebtUpdated,
    WarehouseItemUpdated
} from "../../enum/PushUpdateNotification";
import {Translate, selectLang, selectNumberFormat} from "../../app/store/reducers/main/mainReducer";
import SoftBadge from "../common/SoftBadge";
import numeral from "numeral";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {toast} from "react-toastify";
import {selectUsers} from "../../app/store/reducers/auth/authReducer";
import {selectAllContractor} from "../../app/store/reducers/contractor/contractorReducer";
import {Badge} from "react-bootstrap";
import {selectCashbox} from "../../app/store/reducers/cashbox/CashboxReducer";

const Notification = ({index, notification}) => {
    const t = Translate
    const lang = useSelector(selectLang)
    const [currencyArr, setCurrencyArr] = useState(null)
    const warehouses = useSelector(selectWarehouses)
    const numberFormat = useSelector(selectNumberFormat);
    const dispatch = useDispatch()
    const users = useSelector(selectUsers)
    const contractors = useSelector(selectAllContractor)
    const cashbox = useSelector(selectCashbox)
    const deleteOne = (index) => {
        dispatch(deletePushUpdateNotification(index))
        // toast.error(t(lang,"notification.toast.notification_deleted"))
    }
    useEffect(() => {
        if (notification.type === ContractorDebtUpdated) {
            const debtsArray = notification?.payload?.debts?.map((item) => ({
                id: item.currency.id,
                name: item.currency.name,
                amount: item.amount
            }))
            setCurrencyArr(debtsArray)
        }
    }, [notification]);


    return (
        <div className={"notification-body"}>
            <div className={"d-flex align-items-start justify-content-between gap-1"}>
                <div>
                    {notification.type === WarehouseItemUpdated &&
                        <div>
                            <SoftBadge bg='info' className='me-1'>
                                {notification?.payload?.item_name ? notification?.payload?.item_name : null}
                            </SoftBadge>
                            {t(lang, "notification.warehouse_item.message.first_line")}
                            <SoftBadge bg='info' className='mx-1'>
                                {(notification?.payload?.warehouse_id && warehouses.find(i => i.id === notification?.payload?.warehouse_id).name)}
                            </SoftBadge>
                            {t(lang, "notification.warehouse_item.message.second_line")}
                            {notification?.payload?.state > 0 ? <SoftBadge bg='success' className='mx-1'>
                                    {notification?.payload?.state}
                                </SoftBadge>
                                :
                                <SoftBadge bg='danger' className='mx-1'>
                                    {notification?.payload?.state}
                                </SoftBadge>}
                        </div>
                    }
                    {notification.type === ContractorDebtUpdated &&
                        <div>
                            <h6 className={"m-0 mb-1"}>{t(lang, "notification.contractor_debt.client_name")} <span
                                className={"text-primary fw-medium"}>{notification?.payload?.name}</span></h6>
                            <h6 className={"m-0 mb-1"}>{t(lang, "notification.contractor_debt.client_debt")}</h6>
                            <div className={"d-flex flex-wrap fs--1 gap-1"}>
                                {currencyArr && currencyArr.map(item => <SoftBadge key={item.id} bg='primary'>
                                    <span>{numeral.formats[numberFormat].format(item.amount)}</span>
                                    <small className={'ms-2 fw-bold text-info'}>{item.name}</small>
                                </SoftBadge>)}
                            </div>
                        </div>
                    }
                    {
                        (notification.type === AccountContractorBindingDisable || notification.type === AccountCashBoxBindingDisabled) &&
                        <div className={'text-center'}>
                            <span
                                className={'text-start d-block text-700 fw-bold'}>{t(lang, notification.type === AccountContractorBindingDisable ? "crm.data.table.contractor" : "cashbox.bind.account.title")}</span>
                            <SoftBadge bg={'primary'}>
                                {users.find(i => i.id === notification.payload.account_id)?.name || t(lang, "crm.bind.contractor.account.unknown.account")}
                            </SoftBadge>
                            <span className={'text-wrap'}>{t(lang, "notification.toast.account.blocked")}</span>
                            <Badge bg={"secondary"}
                                   className="ms-2">{t(lang, "crm.bind.contractor.account.block.action")}</Badge>
                        </div>
                    }

                    {
                        (notification.type === AccountContractorBindingEnable || notification.type === AccountCashBoxBindingEnabled) &&
                        <div className={'text-center'}>
                            <span
                                className={'text-start d-block text-700 fw-bold'}>{t(lang, notification.type === AccountContractorBindingEnable ? "crm.data.table.contractor" : "cashbox.bind.account.title")}</span>
                            <SoftBadge bg={'secondary'}>
                                {users.find(i => i.id === notification.payload.account_id)?.name || t(lang, "crm.bind.contractor.account.unknown.account")}
                            </SoftBadge>
                            <span className={'text-wrap'}>{t(lang, "notification.toast.account.blocked")}</span>
                            <Badge bg={"success"}
                                   className="ms-2">{t(lang, "crm.bind.contractor.account.unlock.action")}</Badge>
                        </div>
                    }

                    {
                        (notification.type === AccountContractorBindRegister) &&
                        <div className={'text-center'}>
                            <span
                                className={'text-start d-block text-700 fw-bold'}>{t(lang, "crm.data.table.contractor")}</span>
                            <SoftBadge bg={'success'} className={'text-wrap'}>
                                {contractors.find(i => i.id === notification.payload.contractor_id)?.name || t(lang, "crm.bind.contractor.account.unknown.account")}
                            </SoftBadge>
                            <span>{t(lang, "notification.toast.account.register.text.part.1")}</span>
                            <SoftBadge bg={'primary'}>
                                {users.find(i => i.id === notification.payload.account_id)?.name || t(lang, "crm.bind.contractor.account.unknown.account")}
                            </SoftBadge>
                            <span>{t(lang, "notification.toast.account.register.text.part.2")}</span>
                        </div>
                    }

                    {
                        (notification.type === AccountContractorBindUnregister) &&
                        <div className={'text-center'}>
                            <span
                                className={'text-start d-block text-700 fw-bold'}>{t(lang, "crm.data.table.contractor")}</span>
                            {lang === 'uz' ?
                                <Fragment>
                                    <SoftBadge bg={'danger'} className={'text-wrap'}>
                                        {contractors.find(i => i.id === notification.payload.contractor_id)?.name || t(lang, "crm.bind.contractor.account.unknown.account")}
                                    </SoftBadge>
                                    <SoftBadge bg={'primary'}>
                                        {users.find(i => i.id === notification.payload.account_id)?.name || t(lang, "crm.bind.contractor.account.unknown.account")}
                                    </SoftBadge>
                                    <span
                                        className={'text-wrap'}>{t(lang, "notification.toast.account.unregister.text.part.2")}</span>
                                </Fragment> :
                                <Fragment>
                                    <span>{t(lang, "notification.toast.account.unregister.text.part.1")}</span>
                                    <SoftBadge bg={'danger'} className={'text-wrap'}>
                                        {contractors.find(i => i.id === notification.payload.contractor_id)?.name || t(lang, "crm.bind.contractor.account.unknown.account")}
                                    </SoftBadge>
                                    <span
                                        className={'text-wrap'}>{t(lang, "notification.toast.account.unregister.text.part.2")}</span>
                                    <SoftBadge bg={'primary'}>
                                        {users.find(i => i.id === notification.payload.account_id)?.name || t(lang, "crm.bind.contractor.account.unknown.account")}
                                    </SoftBadge>
                                </Fragment>
                            }
                        </div>
                    }

                    {
                        (notification.type === CashBoxRegistered) &&
                        <div>
                            {lang === 'uz' ?
                                <Fragment>
                                    <SoftBadge bg={'primary'} className={'text-wrap'}>
                                        {notification.payload.branch?.name}
                                    </SoftBadge>
                                    <span>{t(lang, "notification.toast.cash_box.registered.text.part.1")}</span>
                                    <SoftBadge bg={'success'} className={'text-wrap'}>
                                        {notification.payload.name}
                                    </SoftBadge>
                                    <span>{t(lang, "notification.toast.cash_box.registered.text.part.2")}</span>
                                </Fragment> :
                                <Fragment>
                                    <span>{t(lang, "notification.toast.cash_box.registered.text.part.1")}</span>
                                    <SoftBadge bg={'primary'} className={'text-wrap'}>
                                        {notification.payload.branch?.name}
                                    </SoftBadge>
                                    <span>{t(lang, "notification.toast.cash_box.registered.text.part.2")}</span>
                                    <SoftBadge bg={'success'} className={'text-wrap'}>
                                        {notification.payload.name}
                                    </SoftBadge>
                                </Fragment>
                            }
                        </div>
                    }

                    {
                        (notification.type === CashBoxUpdated) &&
                        <div>
                            {lang === 'uz' ?
                                <Fragment>
                                    <SoftBadge bg={'primary'} className={'text-wrap'}>
                                        {notification.payload.branch?.name}
                                    </SoftBadge>
                                    <span>{t(lang, "notification.toast.cash_box.updated.text.part.1")}</span>
                                    <SoftBadge bg={'warning'} className={'text-wrap'}>
                                        {notification.payload.name}
                                    </SoftBadge>
                                    <span>{t(lang, "notification.toast.cash_box.updated.text.part.2")}</span>
                                </Fragment> :
                                <Fragment>
                                    <span>{t(lang, "notification.toast.cash_box.updated.text.part.1")}</span>
                                    <SoftBadge bg={'warning'} className={'text-wrap'}>
                                        {notification.payload.name}
                                    </SoftBadge>
                                    <span>{t(lang, "notification.toast.cash_box.updated.text.part.2")}</span>
                                    <SoftBadge bg={'primary'} className={'text-wrap'}>
                                        {notification.payload.branch?.name}
                                    </SoftBadge>
                                </Fragment>
                            }
                        </div>
                    }

                    {
                        (notification.type === CashBoxDeleted) &&
                        <div>
                            {lang === 'uz' ?
                                <Fragment>
                                    <SoftBadge bg={'primary'} className={'text-wrap'}>
                                        {notification.payload.branch?.name}
                                    </SoftBadge>
                                    <span>{t(lang, "notification.toast.cash_box.deleted.text.part.1")}</span>
                                    <SoftBadge bg={'danger'} className={'text-wrap'}>
                                        {notification.payload.name}
                                    </SoftBadge>
                                    <span>{t(lang, "notification.toast.cash_box.deleted.text.part.2")}</span>
                                </Fragment> :
                                <Fragment>
                                    <span>{t(lang, "notification.toast.cash_box.deleted.text.part.1")}</span>
                                    <SoftBadge bg={'danger'} className={'text-wrap'}>
                                        {notification.payload.name}
                                    </SoftBadge>
                                    <span>{t(lang, "notification.toast.cash_box.deleted.text.part.2")}</span>
                                    <SoftBadge bg={'primary'} className={'text-wrap'}>
                                        {notification.payload.branch?.name}
                                    </SoftBadge>
                                </Fragment>
                            }
                        </div>
                    }

                    {
                        (notification.type === AccountCashBoxBindRegistered) &&
                        <div className={'text-center'}>
                            {lang === 'uz' ?
                                <Fragment>
                                    <span
                                        className={'text-start d-block text-700 fw-bold'}>{t(lang, "cashbox.bind.account.title")}</span>
                                    <SoftBadge bg={'success'} className={'text-wrap me-2'}>
                                        {cashbox.find(i => i.id === notification.payload.cash_box_id)?.name || t(lang, "crm.bind.contractor.account.unknown.account")}
                                    </SoftBadge>
                                    <SoftBadge bg={'primary'}>
                                        {users.find(i => i.id === notification.payload.account_id)?.name || t(lang, "crm.bind.contractor.account.unknown.account")}
                                    </SoftBadge>
                                    <span>{t(lang, "notification.toast.cash_box.account.contractor.register.text.part.1")}</span>
                                </Fragment> :
                                <Fragment>
                                    <span
                                        className={'text-start d-block text-700 fw-bold'}>{t(lang, "cashbox.bind.account.title")}</span>
                                    <span>{t(lang, "notification.toast.cash_box.account.contractor.register.text.part.1")}</span>
                                    <SoftBadge bg={'primary'}>
                                        {users.find(i => i.id === notification.payload.account_id)?.name || t(lang, "crm.bind.contractor.account.unknown.account")}
                                    </SoftBadge>
                                    <span>{t(lang, "notification.toast.cash_box.account.contractor.register.text.part.2")}</span>
                                    <SoftBadge bg={'success'} className={'text-wrap'}>
                                        {cashbox.find(i => i.id === notification.payload.cash_box_id)?.name || t(lang, "crm.bind.contractor.account.unknown.account")}
                                    </SoftBadge>
                                </Fragment>
                            }
                        </div>
                    }
                    {
                        (notification.type === AccountCashBoxBindUnregistered) &&
                        <div className={'text-center'}>
                            {lang === 'uz' ?
                                <Fragment>
                                    <span
                                        className={'text-start d-block text-700 fw-bold'}>{t(lang, "cashbox.bind.account.title")}</span>
                                    <SoftBadge bg={'danger'} className={'text-wrap me-2'}>
                                        {cashbox.find(i => i.id === notification.payload.cash_box_id)?.name || t(lang, "crm.bind.contractor.account.unknown.account")}
                                    </SoftBadge>
                                    <SoftBadge bg={'primary'}>
                                        {users.find(i => i.id === notification.payload.account_id)?.name || t(lang, "crm.bind.contractor.account.unknown.account")}
                                    </SoftBadge>
                                    <span>{t(lang, "notification.toast.cash_box.account.contractor.unregister.text.part.1")}</span>
                                </Fragment> :
                                <Fragment>
                                    <span
                                        className={'text-start d-block text-700 fw-bold'}>{t(lang, "cashbox.bind.account.title")}</span>
                                    <span>{t(lang, "notification.toast.cash_box.account.contractor.unregister.text.part.1")}</span>
                                    <SoftBadge bg={'primary'}>
                                        {users.find(i => i.id === notification.payload.account_id)?.name || t(lang, "crm.bind.contractor.account.unknown.account")}
                                    </SoftBadge>
                                    <span>{t(lang, "notification.toast.cash_box.account.contractor.unregister.text.part.2")}</span>
                                    <SoftBadge bg={'danger'} className={'text-wrap'}>
                                        {cashbox.find(i => i.id === notification.payload.cash_box_id)?.name || t(lang, "crm.bind.contractor.account.unknown.account")}
                                    </SoftBadge>
                                </Fragment>
                            }
                        </div>
                    }
                    {
                        (notification.type === CashBoxAmountsUpdated) &&
                        <div className={'text-center'}>
                            {lang === 'uz' ?
                                <Fragment>
                                    <SoftBadge bg={'success'} className={'text-wrap'}>
                                        {notification.payload.name}
                                    </SoftBadge>
                                    <span>{t(lang, "notification.toast.cash_box.amount.update")}</span>
                                </Fragment> :
                                <Fragment>
                                    <span>{t(lang, "notification.toast.cash_box.amount.update")}</span>
                                    <SoftBadge bg={'success'} className={'text-wrap'}>
                                        {notification.payload.name}
                                    </SoftBadge>
                                </Fragment>
                            }

                        </div>
                    }

                </div>
                <FontAwesomeIcon icon="fa-solid fa-xmark" style={{cursor: "pointer"}} transform="shrink-3"
                                 onClick={() => deleteOne(index)} size={"2x"} className="text-danger"/>
            </div>
        </div>
    )
};
export default Notification;
