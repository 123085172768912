import {Form} from "react-bootstrap";
import SelectCompanyCatalog from "../../IKPU/SelectCompanyCatalog";
import React, {useEffect} from "react";
import ActionButton from "../../../common/ActionButton";
import {Controller, useFormContext, useWatch} from "react-hook-form";
import PropTypes from "prop-types";
import classNames from "classnames";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import SelectMeasurement from "../SelectMeasurement";
import ReactSelect from "react-select";


const ActItemRow = ({allowDelete, onDelete, arrayActFieldName, index}) => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const {register, formState: {errors}, setValue, control} = useFormContext();
    const rootName = `${arrayActFieldName}.${index}`
    const item = useWatch({name: rootName});

    const {quantity, price, measurementId, packageCode, packageName } = item
    const catalog = useWatch({name: `${rootName}.catalog`, exact: true})
    const packages = useWatch({name: `${rootName}.packages`, exact: true})

    useEffect(() => {
        let total = quantity * price
        setValue(`${rootName}.totalSum`, total)

    }, [quantity, price])

    let itemErrors = {};
    if (errors && errors[arrayActFieldName])
        itemErrors = errors[arrayActFieldName][index];


    const onDeleteClicked = () => {
        onDelete(index);
    };


    const onChangeCatalog = (catalog) => {
        if (catalog) {
            setValue(`${rootName}.catalog`, catalog)
            setValue(`${rootName}.catalogClassCode`, catalog.class_code, {shouldValidate: true});
            setValue(`${rootName}.catalogClassName`, catalog.name, {shouldValidate: true});
        } else {
            setValue(`${rootName}.catalogClassCode`, null, {shouldValidate: true});
            setValue(`${rootName}.catalogClassName`, null, {shouldValidate: true});
        }
    };

    useEffect(() => {
        if (catalog && packages) {
            if (measurementId !== "")
                setValue(`${rootName}.measurementId`, "")

            const name = lang === "ru" ? packages.name_ru : packages.name_uz
            if (packageName !== name)
                setValue(`${rootName}.packageName`, name)
            if (packageCode !== packages.code)
                setValue(`${rootName}.packageCode`, packages.code);
        } else {
            if (packageName !== null)
                setValue(`${rootName}.packageName`, null)
            if (packageCode !== null)
                setValue(`${rootName}.packageCode`, null);
        }
    },[catalog, packages])



    return(
        <tr>
            <td>
                {index + 1}
            </td>

            <td>
                <Controller
                    name={`${rootName}.catalogClassCode`}
                    control={control}
                    rules={{
                        required: t(lang, "edi.common.forms.validations.is_required")
                    }}
                    render={({field}) => (
                        <SelectCompanyCatalog
                            size="sm"
                            className="m-1"
                            variant={itemErrors?.catalogClassCode ? 'outline-danger' : 'outline-primary'}
                            textButton={field.value || t(lang, "edi.common.select_button")}
                            onChange={(catalog) => {
                                onChangeCatalog(catalog)
                            }}
                        />
                    )}
                />
                <Form.Control.Feedback type="invalid">
                    {itemErrors?.catalogClassCode?.message}
                </Form.Control.Feedback>
            </td>

            <td>
                <Form.Control
                    name={`${rootName}.Items`}
                    placeholder={t(lang, "edi.common.items.product.placeholder")}
                    className="p-1"
                    {...register(`${rootName}.Items`, {
                        required: t(lang, "edi.common.forms.validations.is_required"),
                    })}
                    isInvalid={itemErrors?.Items}
                />

                <Form.Control.Feedback type="invalid">
                    {itemErrors?.Items?.message}
                </Form.Control.Feedback>
            </td>

            {catalog?.use_package ?
                <td className="p-1">
                    <Controller name={`${rootName}.packages`}
                                rules={{
                                    required: t(lang, "roaming.validations.is_required")
                                }}
                                render={({field}) => (
                                    <ReactSelect options={catalog?.package_names}
                                                 placeholder={t(lang, "roaming.common.select.placeholder")}
                                                 getOptionLabel={option => lang === "ru" ? option.name_ru : option.name_uz}
                                                 getOptionValue={option => option.code}
                                                 defaultValue={catalog?.package_names.find(p => p.code === field.value?.code)}
                                                 menuPortalTarget={document.body}
                                                 hideSelectedOptions
                                                 className={classNames({
                                                     'is-invalid': itemErrors?.packages
                                                 })}
                                                 isClearable
                                                 onChange={field.onChange}
                                    />
                                )}
                    />

                    <Form.Control.Feedback
                        type="invalid">{itemErrors?.packages?.message}</Form.Control.Feedback>
                </td>
                :
                <td>
                    <Controller
                        name={`${rootName}.measurementId`}
                        control={control}
                        rules={{
                            required: t(lang, "edi.common.forms.validations.is_required")
                        }}
                        render={({field}) => {
                            return (
                                <SelectMeasurement
                                    placeholder={t(lang, "edi.common.select_button")}
                                    classNamePrefix={"react-select"}
                                    className={classNames({
                                        "is-invalid": itemErrors?.measurementId
                                    })}
                                    isClearable
                                    onChange={measurement => field.onChange(measurement?.measureId)}
                                    defaultMeasureId={field.value}
                                    menuPortalTarget={document.body}
                                />
                            )
                        }}
                    />
                    <Form.Control.Feedback type="invalid">
                        {itemErrors?.measurementId?.message}
                    </Form.Control.Feedback>
                </td>
            }

            <td>
                <Form.Control
                    name={`${rootName}.quantity`}
                    type="number"
                    placeholder={t(lang, "edi.common.items.product.quantity.placeholder")}
                    className="p-1"
                    {...register(`${rootName}.quantity`, {
                        pattern: /^[0-9]+$/,
                        required: t(lang, "edi.common.forms.validations.is_required"),
                    })}
                    isInvalid={itemErrors?.quantity}
                />

                <Form.Control.Feedback type="invalid">
                    {itemErrors?.quantity?.message}
                </Form.Control.Feedback>
            </td>

            <td>
                <Form.Control
                    name={`${rootName}.price`}
                    type="number"
                    placeholder={t(lang, "edi.common.items.product.price.placeholder")}
                    className="p-1"
                    {...register(`${rootName}.price`, {
                        pattern: /^[0-9]+$/,
                        required: t(lang, "edi.common.forms.validations.is_required"),
                    })}
                    isInvalid={itemErrors?.price}
                />

                <Form.Control.Feedback type="invalid">
                    {itemErrors?.price?.message}
                </Form.Control.Feedback>
            </td>

            <td>
                <Form.Control
                    {...register(`${rootName}.totalSum`)}
                    readOnly={true}
                    className="p-1"
                />
            </td>

            <td className="text-end">
                <ActionButton
                    icon="trash-alt"
                    title="Delete"
                    variant="action"
                    className="p-1"
                    onClick={onDeleteClicked}
                    disabled={!allowDelete} />
            </td>
        </tr>
    )
}

ActItemRow.propTypes = {
    allowDelete: PropTypes.bool,
    onDelete: PropTypes.func,
    arrayActFieldName: PropTypes.string,
    index: PropTypes.number,
};

ActItemRow.defaultProps = {
    allowDelete: false,
    onDelete: () => {
    },
    arrayActFieldName: "",
    index: 0,
};

export default ActItemRow;