import React, {useState} from 'react';
import {Button, Form, Modal, Spinner} from "react-bootstrap";
import FalconCloseButton from "../../../common/FalconCloseButton";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {FormProvider, useForm} from "react-hook-form";
import {memberRegister, memberUpdate} from "../../../../app/store/reducers/kanban/kanbanReducer";
import {useParams} from "react-router";
import PropTypes from "prop-types";

const MemberModal = ({show, setShow, defaultValue}) => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const {id} = useParams()

    const [loading, setLoading] = useState(false)

    const handleClose = () => setShow(false);


    const methods = useForm({
        defaultValues: {
            name: defaultValue?.name || null
        }
    })

    const onSubmit = (formData, event) => {
        setLoading(true)
        if (defaultValue) {
            memberUpdate(defaultValue.id, formData)
                .then(() => {
                    setShow(false)
                    methods.reset()
                })
                .catch((e) => new Error(e))
                .finally(()=> setLoading(false))
        } else {
            memberRegister({...formData, board_id: +id})
                .then(() => {
                    setShow(false)
                    methods.reset()
                })
                .catch((e) => new Error(e))
                .finally(()=>setLoading(false))
        }
    }

    return (
        <>

            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={true}>
                <Modal.Header>
                    <Modal.Title>{t(lang,  defaultValue ? "task.board.common.edit" : "task.board.add.member.button.title") }</Modal.Title>
                    <FalconCloseButton onClick={handleClose}/>
                </Modal.Header>
                <Modal.Body>
                    <FormProvider {...methods}>
                        <Form id={'member'} onSubmit={methods.handleSubmit(onSubmit)}>
                            <Form.Group>
                                <Form.Label>{t(lang, 'task.board.member.name')}</Form.Label>
                                <Form.Control
                                    type={'text'}
                                    placeholder={t(lang, 'task.board.member.name.placeholder')}
                                    isInvalid={methods.formState?.errors?.name}
                                    {...methods?.register('name', {
                                        required: t(lang, "task.board.common.forms.validations.is_required")
                                    })}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {methods.formState?.errors?.name?.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form>
                    </FormProvider>
                </Modal.Body>
                <Modal.Footer>
                    <Button form={'member'} className={'float-end'} type={'submit'} variant="falcon-primary" disabled={loading}>
                        {loading &&
                            <Spinner className='align-middle me-2' animation='border' role='switch' size='sm'/>
                        }
                        {t(lang, defaultValue? "task.board.common.edit" : "task.board.common.add")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

MemberModal.propTypes = {
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
    defaultValue: PropTypes.string
}
export default MemberModal;
