import {toast} from "react-toastify";
import React, {Fragment, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {FormProvider, useForm} from "react-hook-form";
import {Button, Card, Col, Form, Row} from "react-bootstrap";
import {TypeDefault} from "../../../../../../enum/RoamingInvoiceWrapper";
import MassAddRoamingInvoiceItemsForm from "./MassAddRoamingInvoiceItemsForm";
import MassAddRoamingInvoiceFileUploader from "./MassAddRoamingInvoiceFileUploader";
import RoamingInvoiceFormTypeSelector from "../../form/RoamingInvoiceFormTypeSelector";
import {ExcelRegistryFieldUnimportant} from "../../../../../../enum/roaming/RoamingInvoiceFieldOptions";
import {selectInvoiceRegistrySettings, updateInvoiceRegistrySettingsAsync} from "../../../../../../app/store/reducers/settings/settingsReducer";
import {registerMassInvoiceAsync} from "../../../../../../app/store/reducers/excel-registry/excelRegistryReducer";
import {selectLang, Translate, updateShowInProgressAsync} from "../../../../../../app/store/reducers/main/mainReducer";

const MassAddRoamingInvoiceForm = () => {
    const invoiceRegistrySettings = useSelector(selectInvoiceRegistrySettings);
    const lang = useSelector(selectLang);
    const dispatch = useDispatch();
    const t = Translate;

    const methods = useForm({
        defaultValues: {
            sheets: [],
            sheets_skip_rows: [],
            sheets_column_keys: [],
            invoice_type: TypeDefault,
            file: {
                name: null,
                size: null,
                content: null,
            },
        }
    });

    const sheets = methods.watch('sheets');
    const invoiceType = methods.watch('invoice_type');

    useEffect(() => {
        if (invoiceRegistrySettings?.invoice_type) {
            methods.setValue('invoice_type', invoiceRegistrySettings?.invoice_type);
        }
    }, [invoiceRegistrySettings])

    const onSelectInvoiceType = selectedInvoiceType => {
        methods.setValue('invoice_type', selectedInvoiceType);
    }

    const onSubmit = formData => {
        dispatch(updateShowInProgressAsync(true));
        const {file, sheets_column_keys, sheets_skip_rows, invoice_type} = formData;

        let sheetsColumnKeys = [];
        let sheetsSkipRows = [];

        // append sheets column keys
        for (let sheetIndex = 0; sheetIndex < sheets_column_keys.length; sheetIndex++) {
            const columnKeys = sheets_column_keys[sheetIndex];

            for (let columnIndex = 0; columnIndex < columnKeys.length; columnIndex++) {
                const columnKey = columnKeys[columnIndex];

                if (columnKey === ExcelRegistryFieldUnimportant) {
                    continue;
                }

                sheetsColumnKeys.push({
                    key_name: columnKey,
                    column_index: columnIndex,
                    sheet_index: sheetIndex,
                })
            }
        }

        // append sheets skip rows
        for (let sheetIndex = 0; sheetIndex < sheets_skip_rows.length; sheetIndex++) {
            const sheetSkpRows = sheets_skip_rows[sheetIndex];

            sheetsSkipRows.push({
                count_row: +sheetSkpRows.count_row,
                sheet_index: sheetIndex,
            })
        }

        const payload = {
            sheets_columns: sheetsColumnKeys,
            sheets_skip_rows: sheetsSkipRows,
            invoice_type: invoice_type,
            file: {
                name: file.name,
                content: file.content.split(',')[1],
            }
        }

        registerMassInvoiceAsync(payload)
            .catch(() => toast.error(t(lang, 'roaming.invoice.mass_add.toast.error')))
            .finally(() => dispatch(updateShowInProgressAsync(false)))

        updateInvoiceRegistrySettingsAsync({
            sheet_columns: sheetsColumnKeys,
            sheet_skip_rows: sheetsSkipRows,
            invoice_type: invoice_type,
        });
    }

    return (
        <Fragment>
            <FormProvider {...methods}>
                <Form onSubmit={methods.handleSubmit(onSubmit)}>
                    <Card>
                        <Card.Header className="bg-light">
                            <Row>
                                <Col>
                                    <Card.Title>{t(lang, 'roaming.invoice.mass_add.title')}</Card.Title>
                                </Col>
                                <Col xs={12} md={3} className={'my-2 my-lg-1'}>
                                    {!!sheets.length && <RoamingInvoiceFormTypeSelector defaultType={invoiceType} onChange={onSelectInvoiceType} selectProps={{className: 'mx-2'}} />}
                                </Col>
                                <Col xs={'auto'} className={'text-end my-2 my-lg-1'}>
                                    <Button disabled={!sheets.length} size="lg" variant="primary" type="submit">{t(lang, 'roaming.invoice.mass_add.import_details.synchronize')}</Button>
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <MassAddRoamingInvoiceFileUploader/>
                            <MassAddRoamingInvoiceItemsForm/>
                        </Card.Body>
                    </Card>
                </Form>
            </FormProvider>
        </Fragment>
    );
};

export default MassAddRoamingInvoiceForm;