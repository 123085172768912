import dayjs from "dayjs";
import printJS from "print-js";
import classNames from "classnames";
import {useSelector} from "react-redux";
import DatePicker from "react-datepicker";
import React, {Fragment, useState} from 'react';
import {Controller, useForm} from "react-hook-form";
import IconButton from "../../../../common/IconButton";
import EmployeeSelect from "../../../common/EmployeeSelect";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {Button, Dropdown, Form, Modal} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {downloadFile} from "../../../../../helpers/excel-uploader";
import {getDriverSalePdfAsync} from "../../../../../app/store/reducers/print/printReducer";
import {selectDateFormat, selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";

const UploadDriverSalePDF = ({...props}) => {
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isDownloadRequested, setIsDownloadRequested] = useState(false);
    const [isPrintRequested, setIsPrintRequested] = useState(false);
    const dateFormat = useSelector(selectDateFormat);
    const lang = useSelector(selectLang);
    const t = Translate;

    const currentDate = new Date()
    const dateTimeStartFormat = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
    const dateTimeEndFormat = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 23, 59, 59);

    const {control, handleSubmit, reset, formState: {errors}} = useForm({
        defaultValues: {
            employeeId: null,
            dateStart: dateTimeStartFormat,
            dateEnd: dateTimeEndFormat,
        },
    });

    const downloadPDFHandler = () => {
        setIsDownloadRequested(true);
        handleShow();
    };

    const printPDFHandler = () => {
        setIsPrintRequested(true);
        handleShow();
    };

    const handleShow = () => setShow(true);
    const handleClose = () => {
        reset();
        setIsPrintRequested(false);
        setIsDownloadRequested(false);
        setShow(false);
    };

    const onSubmit = (formData) => {
        const payload = {
            employeeId: formData.employeeId,
            dateStart: dayjs(formData.dateStart).format("YYYY-MM-DD HH:mm:ss"),
            dateEnd: dayjs(formData.dateEnd).format("YYYY-MM-DD HH:mm:ss"),
        };

        setLoading(true);
        getDriverSalePdfAsync(payload)
            .then(pdfContent => {
                if (isDownloadRequested) {
                    downloadFile({
                        content: pdfContent,
                        contentType: 'application/pdf',
                        fileName: t(lang, "warehouse_operation.get_driver_sale_pdf_modal.file.title"),
                    });
                }

                if (isPrintRequested) {
                    const blob = new Blob([pdfContent], {type: "application/pdf"});
                    const blobUrl = window.URL.createObjectURL(blob);
                    printJS(blobUrl);
                }

                handleClose();
            })
            .catch(error => console.log(error))
            .finally(() => setLoading(false))
    };
    console.log(errors)
    return (
        <Fragment>
            <Dropdown className={'d-inline-block'}>
                <Dropdown.Toggle as={IconButton} size={'sm'} variant={'falcon-info'} {...props}>
                    {t(lang, 'warehouse_operation.get_driver_sale_pdf_modal.dropdown.title')}
                </Dropdown.Toggle>

                <Dropdown.Menu className={'py-2'}>
                    <Dropdown.Item onClick={downloadPDFHandler}>
                        <FontAwesomeIcon icon={'cloud-arrow-down'} className={'me-1'}/>
                        {t(lang, 'warehouse_operation.get_driver_sale_pdf_modal.dropdown_item.download')}
                    </Dropdown.Item>
                    <Dropdown.Item onClick={printPDFHandler}>
                        <FontAwesomeIcon icon={'print'} className={'me-2'}/>
                        {t(lang, 'warehouse_operation.get_driver_sale_pdf_modal.dropdown_item.print')}
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>

            <Modal show={show} onHide={handleClose}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Header closeButton={true}>
                        <Modal.Title>{t(lang, 'warehouse_operation.get_driver_sale_pdf_modal.title')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label>{t(lang, 'warehouse.operation.item.common.employee')}</Form.Label>
                            <Controller
                                control={control}
                                name={'employeeId'}
                                rules={{required: t(lang, "warehouse.operation.item.common.validation.is_required")}}
                                render={({field, fieldState}) => (
                                    <EmployeeSelect
                                        isClearable={true}
                                        defaultValue={field.value}
                                        classNamePrefix={'react-select'}
                                        onChange={option => field.onChange(option ? option.id : null)}
                                        className={classNames({'is-invalid border border-danger rounded-2': fieldState.error})}
                                    />
                                )}
                            />
                            <Form.Control.Feedback type={'invalid'} className={'d-block'}>
                                {errors?.employeeId?.message}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>{t(lang, 'warehouse_operation.get_driver_sale_pdf_modal.field.date_start')}</Form.Label>
                            <Controller
                                control={control}
                                name={'dateStart'}
                                rules={{required: t(lang, "warehouse.operation.item.common.validation.is_required")}}
                                render={({field, fieldState}) => (
                                    <DatePicker
                                        timeFormat={'p'}
                                        isClearable={true}
                                        timeIntervals={60}
                                        onChange={field.onChange}
                                        dateFormat={`${dateFormat} HH:mm`}
                                        selected={field.value ? new Date(field.value) : null}
                                        wrapperClassName={classNames({'is-invalid': !!fieldState.error})}
                                        className={classNames('form-control', {'is-invalid': !!fieldState.error})}
                                        placeholderText={t(lang, "warehouse.operation.item.common.filter.date:placeholder")}
                                    />
                                )}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors?.dateStart?.message}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>{t(lang, 'warehouse_operation.get_driver_sale_pdf_modal.field.date_end')}</Form.Label>
                            <Controller
                                control={control}
                                name={'dateEnd'}
                                rules={{required: t(lang, "warehouse.operation.item.common.validation.is_required")}}
                                render={({field, fieldState}) => (
                                    <DatePicker
                                        timeFormat={'p'}
                                        isClearable={true}
                                        timeIntervals={60}
                                        onChange={field.onChange}
                                        dateFormat={`${dateFormat} HH:mm`}
                                        selected={field.value ? new Date(field.value) : null}
                                        wrapperClassName={classNames({'is-invalid': !!fieldState.error})}
                                        className={classNames('form-control', {'is-invalid': !!fieldState.error})}
                                        placeholderText={t(lang, "warehouse.operation.item.common.filter.date:placeholder")}
                                    />
                                )}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors?.dateEnd?.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant={'secondary'} onClick={handleClose}>{t(lang, 'warehouse_operation.get_driver_sale_pdf_modal.button.back')}</Button>
                        <Button variant={'primary'} type={'submit'} disabled={loading}>
                            {loading && <FontAwesomeIcon className="me-1" pulse={true} icon={faSpinner}/>}
                            {isPrintRequested && t(lang, 'warehouse_operation.get_driver_sale_pdf_modal.button.print')}
                            {isDownloadRequested && t(lang, 'warehouse_operation.get_driver_sale_pdf_modal.button.download')}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </Fragment>
    );
};

export default UploadDriverSalePDF;