import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {selectLang, selectNumberFormat, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {debounce} from "underscore";
import {Card} from "react-bootstrap";
import DataTable from "../../../common/table/DataTable";
import DataTablePagination from "../../../common/table/DataTablePagination";
import DataTableLimiter from "../../../common/table/DataTableLimiter";
import {getCoreRowModel, useReactTable} from "@tanstack/react-table";
import OwnerOrganizationSalesDataTableHeader from "./OwnerOrganizationSalesDataTableHeader";
import referenceDataAggregatorService
    from "../../../../services/referenceDataAggregator/referenceDataAggregatorService";
import dayjs from "dayjs";
import ViewSaleModal from "./ViewSaleModal";
import DataTableColumnSettings from "../../../common/table/DataTableColumnSettings";
import numeral from "numeral";
import SoftBadge from "../../../common/SoftBadge";

const OwnerOrganizationSalesDataTable = ({organizationBinding, isViewSalePaymentsEnabled}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const numberFormat = useSelector(selectNumberFormat);
    const [filters, setFilters] = useState({
        dateStart: dayjs().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
        dateEnd: dayjs().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
    });
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10
    });
    const [columnVisibility, setColumnVisibility] = useState({});
    const [loading, setLoading] = useState(false);
    const [sales, setSales] = useState([]);
    const [count, setCount] = useState(0);

    const onFilterChange = (filter) => {
        const filterParams = {...filter};
        const paginationParams = {...pagination, pageIndex: 0};
        setFilters(filterParams);
        setPagination(paginationParams);
        load(filterParams, paginationParams)
    };
    const onDebouncedFilterChange = useCallback(debounce((filter) => {
        const filterParams = {...filter};
        const paginationParams = {...pagination, pageIndex: 0};
        setFilters(filterParams);
        setPagination(paginationParams);
        load(filterParams, paginationParams)
    }, 500), [filters, pagination]);
    const onColumnVisibilityChange = (updater) => {
        setColumnVisibility(updater);
    };
    const onPaginationChange = (updater) => {
        const nextState = updater(pagination);
        const paginationParams = {pageSize: nextState.pageSize, pageIndex: nextState.pageIndex};
        setPagination(paginationParams);
        load(filters, paginationParams)
    };

    const load = async (filter, pagination) => {
        try {
            const filterParams = {};
            const paginationParams = {};

            // build filters
            {
                if (filter.dateStart)
                    filterParams["date_start"] = dayjs(filter.dateStart).format("YYYY-MM-DD HH:mm:ss")

                if (filter.dateEnd)
                    filterParams["date_end"] = dayjs(filter.dateEnd).format("YYYY-MM-DD HH:mm:ss")
            }

            // build pagination
            {
                paginationParams["skip"] = pagination.pageIndex * pagination.pageSize;
                paginationParams["limit"] = pagination.pageSize;
            }

            setLoading(true);
            const promiseGroup = await Promise.all([
                referenceDataAggregatorService.getOrganizationBindingSales(organizationBinding.id, {
                    ...filterParams,
                    ...paginationParams
                }),
                referenceDataAggregatorService.getOrganizationBindingSalesCount(organizationBinding.id, {...filterParams})
            ]);
            setSales(promiseGroup[0].data);
            setCount(promiseGroup[1].data);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setSales([]);
            setCount(0);
            setLoading(false);
        }
    };

    const columns = useMemo(() => {
        const dataTableColumns = [
            {
                id: '#',
                accessorKey: '№',
                enableSorting: false,
                enableHiding: false,
                header: ({column}) => {
                    return (
                        <th className="p-1 align-middle text-center text-dark fs--1"
                            style={{
                                width: "40px",
                                maxWidth: "40px",
                                minWidth: "40px"
                            }}
                        >
                            №
                        </th>
                    );
                },
                cell: ({row}) => {
                    return (
                        <td className="p-1 align-middle text-center text-dark"
                            style={{
                                width: "40px",
                                maxWidth: "40px",
                                minWidth: "40px"
                            }}
                        >
                            {pagination.pageIndex * pagination.pageSize + row.index + 1}
                        </td>
                    );
                },
                footer: () => {
                    return (
                        <td className="p-1 align-middle text-center text-dark"
                            style={{
                                width: "40px",
                                maxWidth: "40px",
                                minWidth: "40px"
                            }}
                        >

                        </td>
                    )
                },
            },
            {
                id: 'client',
                accessorKey: t(lang, 'partners.common.sales.client'),
                enableSorting: false,
                enableHiding: false,
                header: ({column}) => {
                    return (
                        <th className="p-1 align-middle text-dark fs--1"
                            style={{
                                minWidth: "200px"
                            }}
                        >
                            {t(lang, 'partners.common.sales.client')}
                        </th>
                    );
                },
                cell: ({row: {original}}) => {
                    return (
                        <td className="p-1 align-middle text-dark"
                            style={{
                                minWidth: "200px"
                            }}
                        >
                            <h5 className="fs--1 fw-semi-bold font-sans-serif text-black text-800">{original?.contractor?.name}</h5>
                        </td>
                    );
                },
                footer: () => {
                    return (
                        <td className="p-1 align-middle text-center text-dark"
                            style={{
                                minWidth: "200px"
                            }}
                        >

                        </td>
                    )
                },
            },
            {
                id: 'saleTotalSum',
                accessorKey: t(lang, 'warehouse-operation.sale.datatable.sale_total_price'),
                enableSorting: false,
                enableHiding: false,
                header: ({column}) => {
                    return (
                        <th className="p-1 align-middle text-center text-dark fs--1"
                            style={{
                                width: "150px",
                                maxWidth: "150px",
                                minWidth: "150px"
                            }}
                        >
                            {t(lang, 'warehouse-operation.sale.datatable.sale_total_price')}
                        </th>
                    );
                },
                cell: ({row: {original}}) => {
                    const saleItemsPriceSummary = original.items.filter(i => !i.is_deleted).reduce((acc, item) => {
                        if (acc[item.net_price.currency.name])
                            acc[item.net_price.currency.name] += item.net_price.amount;
                        else
                            acc[item.net_price.currency.name] = item.net_price.amount;

                        return acc;
                    }, {});

                    return (
                        <td className="p-1 align-middle text-center text-dark"
                            style={{
                                width: "150px",
                                maxWidth: "150px",
                                minWidth: "150px"
                            }}
                        >
                            <div className="d-flex flex-column justify-content-center align-items-center gap-2">
                                {Object.keys(saleItemsPriceSummary).map((key) => (
                                    <SoftBadge bg="info"
                                               className="text-end w-100"
                                    >
                                        {`${numeral.formats[numberFormat].format(saleItemsPriceSummary[key])} ${key}`}
                                    </SoftBadge>
                                ))}
                            </div>
                        </td>
                    );
                },
                footer: () => {
                    const rows = table.getSelectedRowModel().rows.length ? table.getSelectedRowModel().rows : table.getRowModel().rows;
                    const salesItemsPriceSummary = rows.reduce((acc, {original}) => {
                        const saleItems = original.items.filter(i => !i.is_deleted);
                        saleItems.forEach((saleItem) => {
                            if (acc[saleItem.net_price.currency.name])
                                acc[saleItem.net_price.currency.name] += saleItem.net_price.amount;
                            else
                                acc[saleItem.net_price.currency.name] = saleItem.net_price.amount;
                        })
                        return acc;
                    }, {})

                    return (
                        <td className="p-1 align-middle text-center text-dark"
                            style={{
                                width: "150px",
                                maxWidth: "150px",
                                minWidth: "150px"
                            }}
                        >
                            <div className="d-flex flex-column justify-content-center align-items-center gap-2">
                                {Object.keys(salesItemsPriceSummary).map((key) => (
                                    <SoftBadge bg="info"
                                               className="w-100 text-end"
                                    >
                                        {`${numeral.formats[numberFormat].format(salesItemsPriceSummary[key])} ${key}`}
                                    </SoftBadge>
                                ))}
                            </div>
                        </td>
                    )
                },
            },
            {
                id: 'time',
                accessorKey: t(lang, 'partners.common.sales.time'),
                enableSorting: false,
                enableHiding: true,
                header: ({column}) => {
                    return (
                        <th className="p-1 align-middle text-center text-dark fs--1"
                            style={{
                                width: "150px",
                                maxWidth: "150px",
                                minWidth: "200px"
                            }}
                        >
                            {t(lang, 'partners.common.sales.time')}
                        </th>
                    );
                },
                cell: ({row: {original}}) => {
                    return (
                        <td className="p-1 align-middle text-center text-dark"
                            style={{
                                width: "150px",
                                maxWidth: "150px",
                                minWidth: "200px"
                            }}
                        >
                            {dayjs(original.date).format("YYYY-MM-DD HH:mm:ss")}
                        </td>
                    );
                },
                footer: () => {
                    return (
                        <td className="p-1 align-middle text-center text-dark"
                            style={{
                                width: "150px",
                                maxWidth: "150px",
                                minWidth: "200px"
                            }}
                        >

                        </td>
                    )
                },
            },
            {
                id: 'action',
                accessorKey: t(lang, "notification.table.actions"),
                enableSorting: false,
                enableHiding: false,
                header: ({table}) => {
                    return (
                        <th className="p-1 align-middle text-center text-dark fs--1"
                            style={{
                                width: "30px",
                                maxWidth: "30px",
                                minWidth: "30px"
                            }}
                        >
                            <DataTableColumnSettings table={table}/>
                        </th>
                    )
                },
                cell: ({row: {original}}) => {
                    return (
                        <td className="p-1 align-middle text-center">
                            <ViewSaleModal binding={organizationBinding}
                                           saleId={original.id}
                            />
                        </td>
                    )
                },
                footer: () => {
                    return (
                        <td className="p-1 align-middle text-center text-dark"
                            style={{
                                width: "30px",
                                maxWidth: "30px",
                                minWidth: "30px"
                            }}
                        >

                        </td>
                    )
                },
            }
        ];

        if (isViewSalePaymentsEnabled) {
            dataTableColumns.splice(2, 0, {
                id: 'saleTotalPayment',
                accessorKey: t(lang, "warehouse-operation.sale.datatable.sale_payment_total_price"),
                enableSorting: false,
                enableHiding: true,
                header: ({column}) => {
                    return (
                        <th className="p-1 align-middle text-center text-dark fs--1">
                            {t(lang, 'warehouse-operation.sale.datatable.sale_payment_total_price')}
                        </th>
                    )
                },
                cell: ({row: {original}}) => {
                    if (original.payment) {
                        const paymentTotalSummary = original.payment?.cash_box_states?.reduce((acc, item) => {
                            if (acc[item.currency.name])
                                acc[item.currency.name] += item.amount;
                            else
                                acc[item.currency.name] = item.amount;
                            return acc;
                        }, {});

                        return (
                            <td className="p-1 align-middle text-center text-dark">
                                <div className="d-flex flex-column justify-content-center align-items-center gap-2">
                                    {Object.keys(paymentTotalSummary).map((key) => (
                                        <SoftBadge bg="success"
                                                   className="text-end w-100"
                                        >
                                            {`${numeral.formats[numberFormat].format(paymentTotalSummary[key])} ${key}`}
                                        </SoftBadge>
                                    ))}
                                </div>
                            </td>
                        )
                    }

                    return (
                        <td className="p-1 align-middle text-center text-dark"></td>
                    )
                },
                footer: ({table}) => {
                    const rows = table.getSelectedRowModel().rows.length ? table.getSelectedRowModel().rows : table.getRowModel().rows;
                    const payments = rows.map(({original}) => original).filter((x => x.payment));
                    const paymentsTotalSummary = payments.reduce((acc, item) => {
                        item.payment.cash_box_states.forEach((cashBoxState) => {
                            if (acc[cashBoxState.currency.name])
                                acc[cashBoxState.currency.name] += cashBoxState.amount;
                            else
                                acc[cashBoxState.currency.name] = cashBoxState.amount;
                        })
                        return acc;
                    }, {});

                    return (
                        <td className="p-1 align-middle text-center text-dark">
                            <div className="d-flex flex-column justify-content-center align-items-center gap-2">
                                {Object.keys(paymentsTotalSummary).map((key) => (
                                    <SoftBadge bg="success"
                                               className="text-end w-100"
                                    >
                                        {`${numeral.formats[numberFormat].format(paymentsTotalSummary[key])} ${key}`}
                                    </SoftBadge>
                                ))}
                            </div>
                        </td>
                    )
                }
            });
        }

        return dataTableColumns;
    }, [pagination, lang, isViewSalePaymentsEnabled]);
    const data = useMemo(() => {
        return sales;
    }, [sales]);
    const table = useReactTable({
        data: data,
        columns: columns,
        getCoreRowModel: getCoreRowModel(),
        getRowId: row => row.id,
        onPaginationChange: onPaginationChange,
        onColumnVisibilityChange: onColumnVisibilityChange,
        autoResetPageIndex: false,
        manualFiltering: true,
        manualSorting: true,
        manualPagination: true,
        enableSorting: true,
        enableSortingRemoval: true,
        pageCount: Math.ceil(count / pagination.pageSize),
        meta: {
            getHeaderRowClassNames: () => {
                return "bg-200";
            },
        },
        state: {
            pagination: pagination,
            columnVisibility: columnVisibility
        }
    });

    useEffect(() => {
        load(filters, pagination)
    }, [organizationBinding]);

    return (
        <Card>
            <Card.Header>
                <OwnerOrganizationSalesDataTableHeader table={table}
                                                       loading={loading}
                                                       filters={filters}
                                                       onFilterChange={onFilterChange}
                                                       load={() => load(filters, pagination)}
                />
            </Card.Header>
            <Card.Body>
                <DataTable table={table}
                           loading={loading}
                           columns={columns}
                           tableProps={{
                               responsive: true
                           }}
                />
            </Card.Body>
            <Card.Footer>
                <DataTablePagination table={table}
                                     pagination={pagination}
                                     count={count}
                />
                <DataTableLimiter table={table}
                                  pagination={pagination}
                                  limitOptions={[10, 25, 50, 75, 100]}
                />
            </Card.Footer>
        </Card>
    );
};

export default OwnerOrganizationSalesDataTable;