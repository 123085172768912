import React, {useState} from 'react';
import Flex from "../../../common/Flex";
import {Button, Form} from "react-bootstrap";
import {useSelector} from "react-redux";
import {
    itemDeleteComment,
    itemRegisterComment, itemUpdateComment,
    selectMembers, selectModalContent
} from "../../../../app/store/reducers/kanban/kanbanReducer";
import ConfirmationModal from "../ConfirmationModal";
import IconButton from "../../../common/IconButton";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import dayjs from "dayjs";

const ModalCommentContent = ({comments}) => {

    const lang = useSelector(selectLang);
    const t = Translate;


    const members = useSelector(selectMembers)
    const modalContent = useSelector(selectModalContent)

    const [comment, setComment] = useState('')
    const [edit, setEdit] = useState(null)
    const [loading, setLoading] = useState(false)

    const saveComment = (requesterId) => {
        setLoading(true)

        if (edit) {

            const params = {
                item_id: modalContent.id,
                comment: comment
            }

            return  new Promise((resolve, reject) => {
                itemUpdateComment(edit.comment_id, params)
                    .then(() => {
                        setEdit(null)
                        setComment('')
                    })
                    .catch((e) => reject(e))
                    .finally(() => setLoading(false))
            })
        }
        else {
            const params = {
                requester_id: requesterId,
                comments: [comment]
            }

            return new Promise((resolve, reject) => {
                itemRegisterComment(modalContent?.id, params)
                    .then((res) => {
                        setComment('')
                        resolve(res)
                    })
                    .catch((e) => {
                        reject(e)
                    })
                    .finally(() => setLoading(false))
            })
        }
    }

    const deleteComment = (commentId) => {
        return new Promise((resolve, reject) => {
            itemDeleteComment(commentId, modalContent?.id)
                .then((res) => resolve(res))
                .catch((e) => reject(e))
        })
    }


    return (
        <>

            <Flex>
                <div className="flex-1 fs--1">
                    <div className="position-relative border rounded mb-3">
                        <Form.Control
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                            as="textarea"
                            className="border-0 rounded-bottom-0 resize-none"
                            placeholder={t(lang, 'task.board.modal.comments.placeholder')}
                            rows={3}
                        />
                        <Flex
                            justifyContent="end"
                            alignItems="center"
                            className="bg-light rounded-bottom p-2 mt-1"
                        >
                            {edit ? <Button onClick={saveComment} size="sm" color="primary" type="submit">
                                    {t(lang, 'task.board.common.edit')}
                                </Button> :
                                <ConfirmationModal
                                    titleLabel={t(lang, 'task.board.modal.comments')}
                                    title={t(lang, 'task.board.common.add')}
                                    register={t(lang, 'task.board.common.add')}
                                    icon={'plus'}
                                    loading={loading}
                                    comment={comment}
                                    confirmationAction={saveComment}
                        />}
                        </Flex>
                    </div>
                </div>
            </Flex>

            {comments.fields?.map(comment => (
                <Flex key={comment?.id} className="mb-3">
                    <div className="flex-1 fs--1">
                        <p className="mb-1 bg-200 rounded-3 p-2">
                            <i className="fw-bold text-primary">
                                {typeof comment?.commenter === "number" ? members.find(i => i.id === comment?.commenter)?.name : comment?.commenter?.name}
                            </i>
                            <span>{' - '} {comment?.text}</span>
                        </p>
                        <div className={'d-flex justify-content-end align-items-center gap-1'}>
                            <i className={' fw-bold text-primary'}>{dayjs(comment?.created_at).format('DD-MM-YYYY HH:mm')}</i>

                            <IconButton
                                icon={'edit'}
                                className={'p-0 me-0 border-none shadow-none'}
                                variant={'falcon-warning'}
                                onClick={() => {
                                    setEdit(comment)
                                    setComment(comment.text)
                                }}
                            />

                            <ConfirmationModal
                                icon={'trash'}
                                className={'p-0 border-none shadow-none'}
                                variant={'falcon-danger'}
                                title={'Удалить'}
                                hideModalBody={true}
                                confirmationAction={() =>deleteComment(comment?.comment_id)}
                            />
                        </div>

                    </div>
                </Flex>
            ))}

        </>
    );
};

export default ModalCommentContent;
