import {createSlice} from "@reduxjs/toolkit";
import {
    Purchase,
    Refund,
    Return,
    Revision,
    Sale,
    TransferIn,
    TransferOut,
    WriteOff
} from "../../../../enum/OperationTypes";
import dayjs from "dayjs";

const initialState = {
    filters: {
        dateStart: dayjs().startOf('month').hour(0).minute(0).second(0).format("YYYY-MM-DD HH:mm:ss"),
        dateEnd: dayjs().endOf('month').hour(23).minute(59).second(59).format("YYYY-MM-DD HH:mm:ss"),
        categoryId: null,
        itemIds: [],
        itemId: null,
        types: [Sale, Purchase, Refund, Return, Revision, WriteOff, TransferIn, TransferOut],
        contractorId: null,
        warehouseId: null,
    },
    pagination: {
        pageIndex: 0,
        pageSize: 10
    },
    columnVisibility: {
        "#": true,
        "operation_type": true,
        "operation_item_name": true,
        "contractor_name": true,
        "operation_item_before_quantity": true,
        "operation_item_quantity": true,
        "operation_item_after_quantity": true,
        "operation_item_price": true,
        "operation_item_net_price": true,
        "operation_item_warehouse_name": true,
        "operation_date": true,
        "action": true,
    },
    report: {
        reports: [],
        operations_total_count: 0,
        purchases_count: 0,
        purchases_net_price: [],
        refunds_count: 0,
        refunds_net_price: [],
        returns_count: 0,
        returns_net_price: [],
        sales_count: 0,
        sales_net_price: [],
        revisions_count: 0,
        transfer_ins_count: 0,
        transfer_outs_count: 0,
        write_offs_count: 0
    },
};

const operationItemHistorySlice = createSlice({
    name: 'operationItemsReport',
    initialState: initialState,
    reducers: {
        setFilters: (state, action) => {
            state.filters = action.payload;
        },
        setPagination: (state, action) => {
            state.pagination = action.payload;
        },
        setColumnVisibility: (state, action) => {
            state.columnVisibility = action.payload;
        },
        setReport: (state, action) => {
            state.report = action.payload;
        },
    }
});
export default operationItemHistorySlice.reducer;


export const selectFilters = state => state.operationItemsReport.filters;
export const selectPagination = state => state.operationItemsReport.pagination;
export const selectColumnVisibility = state => state.operationItemsReport.columnVisibility;
export const selectReport = state => state.operationItemsReport.report;

export const setFilters = (filters) => (dispatch) => dispatch(operationItemHistorySlice.actions.setFilters(filters));
export const setPagination = (pagination) => (dispatch) => dispatch(operationItemHistorySlice.actions.setPagination(pagination));
export const setColumnVisibility = (columnVisibility) => (dispatch) => dispatch(operationItemHistorySlice.actions.setColumnVisibility(columnVisibility));
export const setReport = (report) => (dispatch) => dispatch(operationItemHistorySlice.actions.setReport(report));
