export const ExcelRegistryFieldUnimportant = "unimportant";
export const ExcelRegistryFieldInvoiceNumber = "invoice_number";
export const ExcelRegistryFieldInvoiceDate = "invoice_date";
export const ExcelRegistryFieldOldInvoiceNumber = "old_invoice_number";
export const ExcelRegistryFieldOldInvoiceDate = "old_invoice_date";
export const ExcelRegistryFieldContractNumber = "contract_number";
export const ExcelRegistryFieldContractDate = "contract_date";
export const ExcelRegistryFieldExecutorRoamingBranchCode = "executor_roaming_branch_code";
export const ExcelRegistryFieldExecutorBankAccount = "executor_bank_account";
export const ExcelRegistryFieldExecutorBankMfo = "executor_bank_mfo";
export const ExcelRegistryFieldCustomerInn = "customer_inn";
export const ExcelRegistryFieldCustomerName = "customer_name";
export const ExcelRegistryFieldEmpowermentNumber = "empowerment_number";
export const ExcelRegistryFieldEmpowermentDate = "empowerment_date";
export const ExcelRegistryFieldEmpowermentPersonPinfl = "empowerment_person_pinfl";
export const ExcelRegistryFieldEmpowermentPersonName = "empowerment_person_name";
export const ExcelRegistryFieldReleaserPinfl = "releaser_pinfl";
export const ExcelRegistryFieldReleaserName = "releaser_name";
export const ExcelRegistryFieldCustomerRoamingBranchCode = "customer_roaming_branch_code";
export const ExcelRegistryFieldProductName = "product_name";
export const ExcelRegistryFieldProductCatalogCode = "product_catalog_code";
export const ExcelRegistryFieldProductCatalogName = "product_catalog_name";
export const ExcelRegistryFieldProductMeasurement = "product_measurement";
export const ExcelRegistryFieldProductPrice = "product_price";
export const ExcelRegistryFieldProductQuantity = "product_quantity";
export const ExcelRegistryFieldProductTotal = "product_total";
export const ExcelRegistryFieldProductSerial = "product_serial";
export const ExcelRegistryFieldProductBasePrice = "product_base_price";
export const ExcelRegistryFieldProductProfitRate = "product_profit_rate";
export const ExcelRegistryFieldProductVatRate = "product_vat_rate";
export const ExcelRegistryFieldProductVatSum = "product_vat_sum";
export const ExcelRegistryFieldProductBenefitId = "product_benefit_id";
export const ExcelRegistryFieldProductCommittentInn = "product_committent_inn";
export const ExcelRegistryFieldProductTotalWithVat = "product_total_with_vat";
export const ExcelRegistryFieldProductOrigin = "product_origin";
export const ExcelRegistryFieldProductMarkType = "mark_product_type";
export const ExcelRegistryFieldProductMarkKiz = "product_mark_kiz";
export const ExcelRegistryFieldProductMarkNomUpak = "product_mark_nom_upak";
export const ExcelRegistryFieldProductMarkIdentTransUpak = "product_mark_ident_trans_upak";

export const GetExcelRegistryFieldLabel = key => {
    switch (key) {
        case ExcelRegistryFieldInvoiceNumber:
            return 'roaming.invoice.mass_add.column.invoice_number';
        case ExcelRegistryFieldInvoiceDate:
            return 'roaming.invoice.mass_add.column.invoice_date';
        case ExcelRegistryFieldOldInvoiceNumber:
            return 'roaming.invoice.mass_add.column.old_invoice_number';
        case ExcelRegistryFieldOldInvoiceDate:
            return 'roaming.invoice.mass_add.column.old_invoice_date';
        case ExcelRegistryFieldContractNumber:
            return 'roaming.invoice.mass_add.column.contract_number';
        case ExcelRegistryFieldContractDate:
            return 'roaming.invoice.mass_add.column.contract_date';
        case ExcelRegistryFieldExecutorRoamingBranchCode:
            return 'roaming.invoice.mass_add.column.executor_roaming_branch_code';
        case ExcelRegistryFieldExecutorBankAccount:
            return 'roaming.invoice.mass_add.column.executor_bank_account';
        case ExcelRegistryFieldExecutorBankMfo:
            return 'roaming.invoice.mass_add.column.executor_bank_mfo';
        case ExcelRegistryFieldCustomerInn:
            return 'roaming.invoice.mass_add.column.customer_inn';
        case ExcelRegistryFieldCustomerName:
            return 'roaming.invoice.mass_add.column.customer_name';
        case ExcelRegistryFieldEmpowermentNumber:
            return 'roaming.invoice.mass_add.column.empowerment_number';
        case ExcelRegistryFieldEmpowermentDate:
            return 'roaming.invoice.mass_add.column.empowerment_date';
        case ExcelRegistryFieldEmpowermentPersonPinfl:
            return 'roaming.invoice.mass_add.column.empowerment_person_pinfl';
        case ExcelRegistryFieldEmpowermentPersonName:
            return 'roaming.invoice.mass_add.column.empowerment_person_name';
        case ExcelRegistryFieldReleaserPinfl:
            return 'roaming.invoice.mass_add.column.releaser_pinfl';
        case ExcelRegistryFieldReleaserName:
            return 'roaming.invoice.mass_add.column.releaser_name';
        case ExcelRegistryFieldCustomerRoamingBranchCode:
            return 'roaming.invoice.mass_add.column.customer_roaming_branch_code';
        case ExcelRegistryFieldProductName:
            return 'roaming.invoice.mass_add.column.product_name';
        case ExcelRegistryFieldProductCatalogCode:
            return 'roaming.invoice.mass_add.column.product_catalog_code';
        case ExcelRegistryFieldProductCatalogName:
            return 'roaming.invoice.mass_add.column.product_catalog_name';
        case ExcelRegistryFieldProductMeasurement:
            return 'roaming.invoice.mass_add.column.product_measurement';
        case ExcelRegistryFieldProductPrice:
            return 'roaming.invoice.mass_add.column.product_price';
        case ExcelRegistryFieldProductQuantity:
            return 'roaming.invoice.mass_add.column.product_quantity';
        case ExcelRegistryFieldProductTotal:
            return 'roaming.invoice.mass_add.column.product_total';
        case ExcelRegistryFieldProductSerial:
            return 'roaming.invoice.mass_add.column.product_serial';
        case ExcelRegistryFieldProductBasePrice:
            return 'roaming.invoice.mass_add.column.product_base_price';
        case ExcelRegistryFieldProductProfitRate:
            return 'roaming.invoice.mass_add.column.product_profit_rate';
        case ExcelRegistryFieldProductVatRate:
            return 'roaming.invoice.mass_add.column.product_vat_rate';
        case ExcelRegistryFieldProductVatSum:
            return 'roaming.invoice.mass_add.column.product_vat_sum';
        case ExcelRegistryFieldProductBenefitId:
            return 'roaming.invoice.mass_add.column.product_benefit_id';
        case ExcelRegistryFieldProductOrigin:
            return 'roaming.invoice.mass_add.column.product_origin';
        case ExcelRegistryFieldProductCommittentInn:
            return 'roaming.invoice.mass_add.column.product_committent_inn';
        case ExcelRegistryFieldProductTotalWithVat:
            return 'roaming.invoice.mass_add.column.product_total_with_vat';
        case ExcelRegistryFieldProductMarkType:
            return 'roaming.invoice.mass_add.column.product_mark_type';
        case ExcelRegistryFieldProductMarkKiz:
            return 'roaming.invoice.mass_add.column.product_mark_kiz'
        case ExcelRegistryFieldProductMarkNomUpak:
            return 'roaming.invoice.mass_add.column.product_mark_nom_upak'
        case ExcelRegistryFieldProductMarkIdentTransUpak:
            return 'roaming.invoice.mass_add.column.product_mark_ident_trans_upak'
        default:
            return 'roaming.invoice.mass_add.column.unimportant';
    }
};

export const GetExcelRegistryFieldRequirement = key => {
    switch (key) {
        case ExcelRegistryFieldInvoiceNumber:
            return true;
        case ExcelRegistryFieldInvoiceDate:
            return true;
        case ExcelRegistryFieldOldInvoiceNumber:
            return false;
        case ExcelRegistryFieldOldInvoiceDate:
            return false
        case ExcelRegistryFieldContractNumber:
            return true;
        case ExcelRegistryFieldContractDate:
            return true;
        case ExcelRegistryFieldExecutorRoamingBranchCode:
            return false;
        case ExcelRegistryFieldExecutorBankAccount:
            return false;
        case ExcelRegistryFieldExecutorBankMfo:
            return false;
        case ExcelRegistryFieldCustomerInn:
            return true;
        case ExcelRegistryFieldCustomerName:
            return false;
        case ExcelRegistryFieldEmpowermentNumber:
            return false;
        case ExcelRegistryFieldEmpowermentDate:
            return false;
        case ExcelRegistryFieldEmpowermentPersonPinfl:
            return false;
        case ExcelRegistryFieldEmpowermentPersonName:
            return false;
        case ExcelRegistryFieldReleaserPinfl:
            return false;
        case ExcelRegistryFieldReleaserName:
            return false;
        case ExcelRegistryFieldCustomerRoamingBranchCode:
            return false;
        case ExcelRegistryFieldProductName:
            return true;
        case ExcelRegistryFieldProductCatalogCode:
            return true;
        case ExcelRegistryFieldProductCatalogName:
            return false;
        case ExcelRegistryFieldProductMeasurement:
            return true;
        case ExcelRegistryFieldProductPrice:
            return true;
        case ExcelRegistryFieldProductQuantity:
            return true;
        case ExcelRegistryFieldProductTotal:
            return false;
        case ExcelRegistryFieldProductSerial:
            return false;
        case ExcelRegistryFieldProductBasePrice:
            return false;
        case ExcelRegistryFieldProductProfitRate:
            return false;
        case ExcelRegistryFieldProductVatRate:
            return false;
        case ExcelRegistryFieldProductOrigin:
            return true;
        case ExcelRegistryFieldProductVatSum:
            return false;
        case ExcelRegistryFieldProductBenefitId:
            return false;
        case ExcelRegistryFieldProductCommittentInn:
            return false;
        case ExcelRegistryFieldProductTotalWithVat:
            return false;
        case ExcelRegistryFieldProductMarkType:
            return false;
        case ExcelRegistryFieldProductMarkKiz:
            return false;
        case ExcelRegistryFieldProductMarkNomUpak:
            return false;
        case ExcelRegistryFieldProductMarkIdentTransUpak:
            return false;
        default:
            return false;
    }
};

const ExcelRegistryField = (key, label, isRequired) => {
    return {
        key,
        label,
        isRequired,
    }
};

export const ExcelRegistryFields = [
    ExcelRegistryField(ExcelRegistryFieldUnimportant, GetExcelRegistryFieldLabel(ExcelRegistryFieldUnimportant), GetExcelRegistryFieldRequirement(ExcelRegistryFieldUnimportant)),
    ExcelRegistryField(ExcelRegistryFieldInvoiceNumber, GetExcelRegistryFieldLabel(ExcelRegistryFieldInvoiceNumber), GetExcelRegistryFieldRequirement(ExcelRegistryFieldInvoiceNumber)),
    ExcelRegistryField(ExcelRegistryFieldInvoiceDate, GetExcelRegistryFieldLabel(ExcelRegistryFieldInvoiceDate), GetExcelRegistryFieldRequirement(ExcelRegistryFieldInvoiceDate)),
    ExcelRegistryField(ExcelRegistryFieldOldInvoiceNumber, GetExcelRegistryFieldLabel(ExcelRegistryFieldOldInvoiceNumber), GetExcelRegistryFieldRequirement(ExcelRegistryFieldOldInvoiceNumber)),
    ExcelRegistryField(ExcelRegistryFieldOldInvoiceDate, GetExcelRegistryFieldLabel(ExcelRegistryFieldOldInvoiceDate), GetExcelRegistryFieldRequirement(ExcelRegistryFieldOldInvoiceDate)),
    ExcelRegistryField(ExcelRegistryFieldContractNumber, GetExcelRegistryFieldLabel(ExcelRegistryFieldContractNumber), GetExcelRegistryFieldRequirement(ExcelRegistryFieldContractNumber)),
    ExcelRegistryField(ExcelRegistryFieldContractDate, GetExcelRegistryFieldLabel(ExcelRegistryFieldContractDate), GetExcelRegistryFieldRequirement(ExcelRegistryFieldContractDate)),
    ExcelRegistryField(ExcelRegistryFieldExecutorRoamingBranchCode, GetExcelRegistryFieldLabel(ExcelRegistryFieldExecutorRoamingBranchCode), GetExcelRegistryFieldRequirement(ExcelRegistryFieldExecutorRoamingBranchCode)),
    ExcelRegistryField(ExcelRegistryFieldExecutorBankAccount, GetExcelRegistryFieldLabel(ExcelRegistryFieldExecutorBankAccount), GetExcelRegistryFieldRequirement(ExcelRegistryFieldExecutorBankAccount)),
    ExcelRegistryField(ExcelRegistryFieldExecutorBankMfo, GetExcelRegistryFieldLabel(ExcelRegistryFieldExecutorBankMfo), GetExcelRegistryFieldRequirement(ExcelRegistryFieldExecutorBankMfo)),
    ExcelRegistryField(ExcelRegistryFieldCustomerInn, GetExcelRegistryFieldLabel(ExcelRegistryFieldCustomerInn), GetExcelRegistryFieldRequirement(ExcelRegistryFieldCustomerInn)),
    ExcelRegistryField(ExcelRegistryFieldCustomerName, GetExcelRegistryFieldLabel(ExcelRegistryFieldCustomerName), GetExcelRegistryFieldRequirement(ExcelRegistryFieldCustomerName)),
    ExcelRegistryField(ExcelRegistryFieldEmpowermentNumber, GetExcelRegistryFieldLabel(ExcelRegistryFieldEmpowermentNumber), GetExcelRegistryFieldRequirement(ExcelRegistryFieldEmpowermentNumber)),
    ExcelRegistryField(ExcelRegistryFieldEmpowermentDate, GetExcelRegistryFieldLabel(ExcelRegistryFieldEmpowermentDate), GetExcelRegistryFieldRequirement(ExcelRegistryFieldEmpowermentDate)),
    ExcelRegistryField(ExcelRegistryFieldEmpowermentPersonPinfl, GetExcelRegistryFieldLabel(ExcelRegistryFieldEmpowermentPersonPinfl), GetExcelRegistryFieldRequirement(ExcelRegistryFieldEmpowermentPersonPinfl)),
    ExcelRegistryField(ExcelRegistryFieldEmpowermentPersonName, GetExcelRegistryFieldLabel(ExcelRegistryFieldEmpowermentPersonName), GetExcelRegistryFieldRequirement(ExcelRegistryFieldEmpowermentPersonName)),
    ExcelRegistryField(ExcelRegistryFieldReleaserPinfl, GetExcelRegistryFieldLabel(ExcelRegistryFieldReleaserPinfl), GetExcelRegistryFieldRequirement(ExcelRegistryFieldReleaserPinfl)),
    ExcelRegistryField(ExcelRegistryFieldReleaserName, GetExcelRegistryFieldLabel(ExcelRegistryFieldReleaserName), GetExcelRegistryFieldRequirement(ExcelRegistryFieldReleaserName)),
    ExcelRegistryField(ExcelRegistryFieldCustomerRoamingBranchCode, GetExcelRegistryFieldLabel(ExcelRegistryFieldCustomerRoamingBranchCode), GetExcelRegistryFieldRequirement(ExcelRegistryFieldCustomerRoamingBranchCode)),
    ExcelRegistryField(ExcelRegistryFieldProductName, GetExcelRegistryFieldLabel(ExcelRegistryFieldProductName), GetExcelRegistryFieldRequirement(ExcelRegistryFieldProductName)),
    ExcelRegistryField(ExcelRegistryFieldProductCatalogCode, GetExcelRegistryFieldLabel(ExcelRegistryFieldProductCatalogCode), GetExcelRegistryFieldRequirement(ExcelRegistryFieldProductCatalogCode)),
    ExcelRegistryField(ExcelRegistryFieldProductCatalogName, GetExcelRegistryFieldLabel(ExcelRegistryFieldProductCatalogName), GetExcelRegistryFieldRequirement(ExcelRegistryFieldProductCatalogName)),
    ExcelRegistryField(ExcelRegistryFieldProductMeasurement, GetExcelRegistryFieldLabel(ExcelRegistryFieldProductMeasurement), GetExcelRegistryFieldRequirement(ExcelRegistryFieldProductMeasurement)),
    ExcelRegistryField(ExcelRegistryFieldProductPrice, GetExcelRegistryFieldLabel(ExcelRegistryFieldProductPrice), GetExcelRegistryFieldRequirement(ExcelRegistryFieldProductPrice)),
    ExcelRegistryField(ExcelRegistryFieldProductQuantity, GetExcelRegistryFieldLabel(ExcelRegistryFieldProductQuantity), GetExcelRegistryFieldRequirement(ExcelRegistryFieldProductQuantity)),
    ExcelRegistryField(ExcelRegistryFieldProductTotal, GetExcelRegistryFieldLabel(ExcelRegistryFieldProductTotal), GetExcelRegistryFieldRequirement(ExcelRegistryFieldProductTotal)),
    ExcelRegistryField(ExcelRegistryFieldProductSerial, GetExcelRegistryFieldLabel(ExcelRegistryFieldProductSerial), GetExcelRegistryFieldRequirement(ExcelRegistryFieldProductSerial)),
    ExcelRegistryField(ExcelRegistryFieldProductBasePrice, GetExcelRegistryFieldLabel(ExcelRegistryFieldProductBasePrice), GetExcelRegistryFieldRequirement(ExcelRegistryFieldProductBasePrice)),
    ExcelRegistryField(ExcelRegistryFieldProductProfitRate, GetExcelRegistryFieldLabel(ExcelRegistryFieldProductProfitRate), GetExcelRegistryFieldRequirement(ExcelRegistryFieldProductProfitRate)),
    ExcelRegistryField(ExcelRegistryFieldProductVatRate, GetExcelRegistryFieldLabel(ExcelRegistryFieldProductVatRate), GetExcelRegistryFieldRequirement(ExcelRegistryFieldProductVatRate)),
    ExcelRegistryField(ExcelRegistryFieldProductVatSum, GetExcelRegistryFieldLabel(ExcelRegistryFieldProductVatSum), GetExcelRegistryFieldRequirement(ExcelRegistryFieldProductVatSum)),
    ExcelRegistryField(ExcelRegistryFieldProductBenefitId, GetExcelRegistryFieldLabel(ExcelRegistryFieldProductBenefitId), GetExcelRegistryFieldRequirement(ExcelRegistryFieldProductBenefitId)),
    ExcelRegistryField(ExcelRegistryFieldProductCommittentInn, GetExcelRegistryFieldLabel(ExcelRegistryFieldProductCommittentInn), GetExcelRegistryFieldRequirement(ExcelRegistryFieldProductCommittentInn)),
    ExcelRegistryField(ExcelRegistryFieldProductTotalWithVat, GetExcelRegistryFieldLabel(ExcelRegistryFieldProductTotalWithVat), GetExcelRegistryFieldRequirement(ExcelRegistryFieldProductTotalWithVat)),
    ExcelRegistryField(ExcelRegistryFieldProductOrigin, GetExcelRegistryFieldLabel(ExcelRegistryFieldProductOrigin), GetExcelRegistryFieldRequirement(ExcelRegistryFieldProductOrigin)),
    ExcelRegistryField(ExcelRegistryFieldProductMarkType, GetExcelRegistryFieldLabel(ExcelRegistryFieldProductMarkType), GetExcelRegistryFieldRequirement(ExcelRegistryFieldProductMarkType)),
    ExcelRegistryField(ExcelRegistryFieldProductMarkKiz, GetExcelRegistryFieldLabel(ExcelRegistryFieldProductMarkKiz), GetExcelRegistryFieldRequirement(ExcelRegistryFieldProductMarkKiz)),
    ExcelRegistryField(ExcelRegistryFieldProductMarkNomUpak, GetExcelRegistryFieldLabel(ExcelRegistryFieldProductMarkNomUpak), GetExcelRegistryFieldRequirement(ExcelRegistryFieldProductMarkNomUpak)),
    ExcelRegistryField(ExcelRegistryFieldProductMarkIdentTransUpak, GetExcelRegistryFieldLabel(ExcelRegistryFieldProductMarkIdentTransUpak), GetExcelRegistryFieldRequirement(ExcelRegistryFieldProductMarkIdentTransUpak)),
];
