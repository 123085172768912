import {createSlice} from "@reduxjs/toolkit";
import EventBus from "../../../eventbus/EventBus";
import BillingService from "../../../../services/billingService";
import billingService from "../../../../services/billingService";
import {
    ACTIVATE_DEMO_FAILED,
    ACTIVATE_DEMO_REQUESTED,
    ACTIVATE_DEMO_SUCCEED,
    ACTIVATE_PACKAGE_FAILED,
    ACTIVATE_PACKAGE_REQUESTED,
    ACTIVATE_PACKAGE_SUCCEED,
    ADD_ORGANIZATION_BALANCE_FAILED,
    ADD_ORGANIZATION_BALANCE_REQUESTED,
    ADD_ORGANIZATION_BALANCE_SUCCEED,
    APPLY_CREDIT_INTO_ORGANIZATION_FAILED,
    APPLY_CREDIT_INTO_ORGANIZATION_REQUESTED,
    APPLY_CREDIT_INTO_ORGANIZATION_SUCCESS,
    SEEN_BANK_PAYMENTS_ERROR_FAILED,
    SEEN_BANK_PAYMENTS_ERROR_SUCCESS,
    REGISTER_CREDENTIAL_SUCCESS,
    REGISTER_CREDENTIAL_FAIL
} from "../../../eventbus/billingEvents";
import dayjs from "dayjs";

export const EDI_SERVICE_CODE = "edi";
export const POS_SERVICE_CODE = "pos";
export const WAREHOUSE_MANAGEMENT_SERVICE_CODE = "warehouse_management";
export const EIMZO_SIGNER_SERVICE_CODE = "eimzo-signer";

export const billingSlice = createSlice({
    name: 'billing',
    initialState: {
        packages: [],
        isLoading: false,
        organizations: [],
        organizationsCount: 0,
        organizationBankPaymentsError: [],
        organizationBankPaymentsErrorCount: 0,
        isEdiServiceActive: false,
        isWarehouseServiceActive: false,
        isPosServiceActive: false,
        organizationBillingInfo: null,
        filterOptions: {page: 1, limit: 10},
        filterOptionsPayment: {
            page: 1,
            limit: 10,
            created_on_start: dayjs(new Date()).format('YYYY-MM-DD'),
            created_on_end: dayjs(new Date()).format('YYYY-MM-DD'),
        },
        filterOptionsBankPaymentsError: {
            page: 1,
            limit: 10,
            created_on_start: dayjs(new Date()).format('YYYY-MM-DD'),
            created_on_end: dayjs(new Date()).format('YYYY-MM-DD'),
        },
        payments: [],
        paymentsCount: 0,

        // own billing organization

        ownPayments: [],
        ownPaymentsCount: 0,
        paymentFilterOptions: {page: 1, limit: 10},
        boughtPackages: [],
        boughtPackagesCount: 0,
        boughtFilterOptions: {page: 1, limit: 10},
        charges: [],
        chargesCount: 0,
        chargesFilterOptions: {page: 1, limit: 10},

        // admin
        adminBoughtPackages: [],
        adminBoughtPackagesCount: 0,
        adminBoughtFilterOptions: {
            page: 1,
            limit: 10,
            created_on_start: dayjs(new Date()).format('YYYY-MM-DD'),
            created_on_end: dayjs(new Date()).format('YYYY-MM-DD'),
        },
        adminCharges: [],
        adminChargesCount: 0,
        adminChargesFilterOptions: {page: 1, limit: 10},
        adminPayments: [],
        adminPaymentsCount: 0,
        adminPaymentsFilterOptions: {page: 1, limit: 10},

        // credentials
        credentialsData: [],
        credentialsFilterOptions: {page: 1, limit: 10},
        credentialsCount: 0
    },
    reducers: {
        updateIsEdiServiceActive: (state, action) => {
            state.isEdiServiceActive = action.payload.active_packages.some(p => p.package.service.code === EDI_SERVICE_CODE);
        },
        updateIsWarehouseServiceActive: (state, action) => {
            state.isWarehouseServiceActive = action.payload.active_packages.some(p => p.package.service.code === WAREHOUSE_MANAGEMENT_SERVICE_CODE)
        },
        updateIsPosServiceActive: (state, action) => {
            state.isPosServiceActive = action.payload.active_packages.some(p => p.package.service.code === POS_SERVICE_CODE)
        },
        updatePackages: (state, action) => {
            state.packages = action.payload;
        },
        updateOrganizationBillingInfo: (state, action) => {
            state.organizationBillingInfo = action.payload;
        },
        updateIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        updateSystemOrganizations: (state, action) => {
            state.organizations = action.payload;
        },
        updateSystemOrganizationsCount: (state, action) => {
            state.organizationsCount = action.payload;
        },
        updateSystemOrganizationsBankPaymentsError: (state, action) => {
            state.organizationBankPaymentsError = action.payload
        },
        updateSystemOrganizationsBankPaymentsErrorCount: (state, action) => {
            state.organizationBankPaymentsErrorCount = action.payload
        },
        updateOrganizationsFilterOptions: (state, action) => {
            state.filterOptions = {...state.filterOptions, ...action.payload}
        },
        updateOrganizationsFilterOptionsPayment: (state, action) => {
            state.filterOptionsPayment = {...state.filterOptionsPayment, ...action.payload}
        },
        clearOrganizationsFilterOptionsPayment: (state, action) => {
            state.filterOptionsPayment = action.payload
        },
        updateOrganizationsFilterOptionBankPaymentsError: (state, action) => {
            state.filterOptionsBankPaymentsError = {...state.filterOptionsBankPaymentsError, ...action.payload}
        },
        clearOrganizationsFilterOptionBankPaymentsError: (state,action) => {
            state.filterOptionsBankPaymentsError = action.payload
        },
        updatePayments: (state, action) => {
            state.payments = action.payload;
        },
        updatePaymentsCount: (state, action) => {
            state.paymentsCount = action.payload;
        },

        // own billing organization

        getOwnPayments: (state, {payload}) => {
            state.ownPayments = payload
        },
        getOwnPaymentsCount: (state, {payload}) => {
            state.ownPaymentsCount = payload
        },
        updateOwnPaymentsFilterOptions: (state, {payload}) => {
            state.paymentFilterOptions = {...state.paymentFilterOptions, ...payload}
        },
        getBoughtPackages: (state, {payload}) => {
            state.boughtPackages = payload
        },
        getBoughtPackagesCount: (state, {payload}) => {
            state.boughtPackagesCount = payload
        },
        updateBoughtFilterOptions: (state, {payload}) => {
            state.boughtFilterOptions = {...state.boughtFilterOptions, ...payload}
        },
        getCharges: (state, {payload}) => {
            state.charges = payload
        },
        getChargesCount: (state, {payload}) => {
            state.chargesCount = payload
        },
        updateOwnChargesFilterOptions: (state, {payload}) => {
            state.chargesFilterOptions = {...state.chargesFilterOptions, ...payload}
        },

        // admin

        getChargesAdmin: (state, {payload}) => {
            state.adminCharges = payload
        },
        getChargesAdminCount: (state, {payload}) => {
            state.adminChargesCount = payload
        },
        updateOwnChargesAdminFilterOptions: (state, {payload}) => {
            state.adminChargesFilterOptions = {...state.adminChargesFilterOptions, ...payload}
        },

        getBoughtAdminPackages: (state, {payload}) => {
            state.adminBoughtPackages = payload
        },
        getBoughtAdminPackagesCount: (state, {payload}) => {
            state.adminBoughtPackagesCount = payload
        },
        updateBoughtAdminFilterOptions: (state, {payload}) => {
            state.adminBoughtFilterOptions = {...state.adminBoughtFilterOptions, ...payload}
        },
        clearBoughtAdminFilterOptions: (state,action) => {
            state.adminBoughtFilterOptions = action.payload
        },
        getAdminPayments: (state, {payload}) => {
            state.adminPayments = payload
        },
        getAdminPaymentsCount: (state, {payload}) => {
            state.adminPaymentsCount = payload
        },
        updateAdminPaymentsFilterOption: (state, {payload}) => {
            state.adminPaymentsFilterOptions = {...state.adminPaymentsFilterOptions, ...payload}
        },

        // credentials
        getCredentials: (state, action) => {
            state.credentialsData = action.payload
        },
        getCredentialsCount: (state, action) => {
            state.credentialsCount = action.payload
        },
        updateAdminCredentialsFilterOption: (state, {payload}) => {
            state.credentialsFilterOptions = {...state.credentialsFilterOptions, ...payload}
        }

    }
});

export const selectBillingPackages = (state) => state.billing.packages;
export const selectBillingIsLoading = (state) => state.billing.isLoading;
export const selectOrganizationBillingInfo = (state) => state.billing.organizationBillingInfo;
export const selectOrganizationActivePackages = (state) => state.billing.organizationBillingInfo?.active_packages;
export const selectOrganizationServiceDemoUses = (state) => state.billing.organizationBillingInfo?.service_demo_uses || [];
export const selectOrganizationGetPayments = (state) => state.billing.payments
export const selectOrganizationGetPaymentsCount = (state) => state.billing.paymentsCount
export const selectIsEDIServiceActive = (state) => state.billing.isEdiServiceActive;
export const selectIsWarehouseServiceActive = (state) => state.billing.isWarehouseServiceActive;
export const selectIsPosServiceActive = (state) => state.billing.isPosServiceActive;

export const selectSystemOrganizations = (state) => state.billing.organizations;
export const selectSystemOrganizationsCount = (state) => state.billing.organizationsCount;
export const selectSystemOrganizationsBankPaymentsError = (state) => state.billing.organizationBankPaymentsError
export const selectSystemOrganizationBankPaymentsErrorCount = (state) => state.billing.organizationBankPaymentsErrorCount
export const selectSystemOrganizationsFilterOptions = (state) => state.billing.filterOptions;
export const selectSystemOrganizationsFilterOptionsPayment = (state) => state.billing.filterOptionsPayment;
export const selectSystemOrganizationsFilterOptionsBankPaymentsError = (state) => state.billing.filterOptionsBankPaymentsError

// own billing organization selects

export const selectCharges = (state) => state.billing.charges
export const selectChargesCount = (stata) => stata.billing.chargesCount
export const selectChargesFilter = (state) => state.billing.chargesFilterOptions

export const selectOwnPayments = (state) => state.billing.ownPayments
export const selectOwnPaymentsCount = (state) => state.billing.ownPaymentsCount
export const selectOwnPaymentsFilter = (state) => state.billing.paymentFilterOptions

export const selectBoughtPackages = (state) => state.billing.boughtPackages
export const selectBoughtPackagesCount = (state) => state.billing.boughtPackagesCount
export const selectBoughtFilter = (state) => state.billing.boughtFilterOptions

// admin

export const selectChargesAdmin = (state) => state.billing.adminCharges
export const selectChargesAdminCount = (stata) => stata.billing.adminChargesCount
export const selectChargesAdminFilter = (state) => state.billing.adminChargesFilterOptions

export const selectBoughtAdminPackages = (state) => state.billing.adminBoughtPackages
export const selectBoughtAdminPackagesCount = (state) => state.billing.adminBoughtPackagesCount
export const selectBoughtAdminFilter = (state) => state.billing.adminBoughtFilterOptions

export const selectAdminPayments = (state) => state.billing.adminPayments
export const selectAdminPaymentsCount = (state) => state.billing.adminPaymentsCount
export const selectAdminPaymentsFilter = (state) => state.billing.adminPaymentsFilterOptions

// credentials
export const selectAdminOrganizationCredential = (state) => state.billing.credentialsData
export const selectAdminOrganizationCredentialsFilterOptions = (state) => state.billing.credentialsFilterOptions
export const selectAdminOrganizationCredentialsCount = (state) => state.billing.credentialsCount

export const getOrganizationBillingInfoAsync = () => dispatch => {
    dispatch(billingSlice.actions.updateIsLoading(true));

    return new Promise((resolve, reject) => {
        BillingService.getOrganizationBillingInfo()
            .then(response => {
                dispatch(billingSlice.actions.updateOrganizationBillingInfo(response.data));
                dispatch(billingSlice.actions.updateIsEdiServiceActive(response.data));
                dispatch(billingSlice.actions.updateIsWarehouseServiceActive(response.data));
                dispatch(billingSlice.actions.updateIsPosServiceActive(response.data));
                dispatch(billingSlice.actions.updateIsLoading(false));
                resolve();
            }).catch(error => reject(error))
    })
}
export const loadBillingPackagesAsync = () => dispatch => {
    return new Promise((resolve, reject) => {
        BillingService.getBillingPackages()
            .then(response => {
                dispatch(billingSlice.actions.updatePackages(response.data));
                resolve();
            }).catch(error => reject(error))
    })
}
export const activatePackageAsync = ({packageId}) => {
    EventBus.dispatch(ACTIVATE_PACKAGE_REQUESTED);

    return new Promise((resolve, reject) => {
        BillingService.activatePackage({packageId})
            .then(response => {
                EventBus.dispatch(ACTIVATE_PACKAGE_SUCCEED);
                resolve(response.data);
            })
            .catch(error => {
                EventBus.dispatch(ACTIVATE_PACKAGE_FAILED);
                reject(error);
            })
    })
}
export const activateDemoPeriodAsync = ({serviceCode}) => {
    EventBus.dispatch(ACTIVATE_DEMO_REQUESTED);

    return new Promise((resolve, reject) => {
        BillingService.activateDemoPeriod({serviceCode})
            .then(response => {
                EventBus.dispatch(ACTIVATE_DEMO_SUCCEED);
                resolve(response.data)
            })
            .catch(error => {
                EventBus.dispatch(ACTIVATE_DEMO_FAILED);
                reject(error)
            })
    })
}
export const loadPaymentsAsync = ({page, limit, ...filters}) => (dispatch) => {
    const skip = (page - 1) * limit
    const params = {skip, limit, ...filters}

    billingService.getPayments(params)
        .then(response => {
            dispatch(billingSlice.actions.updatePayments(response.data))
        })
        .catch(error => {
            console.log(error)
        })
};

export const loadPaymentsCountAsync = params => async (dispatch) => {
    let {limit, page, ...other} = params

    billingService.getPaymentsCount(other)
        .then(response => {
            dispatch(billingSlice.actions.updatePaymentsCount(response.data))
        })
        .catch(error => {
            console.log(error)
        })
}

export const loadBankPaymentsErrorAsync = ({page, limit, ...filters}) => (dispatch) => {
    const skip = (page - 1) * limit
    const params = {skip, limit, ...filters}

    return new Promise((resolve, reject) => {
        billingService.getBankPaymentsError(params)
            .then(response => {
                dispatch(billingSlice.actions.updateSystemOrganizationsBankPaymentsError(response.data))
                resolve(response.data)
            })
            .catch(error => {
                console.log(error)
                reject(error)
            })
    })
}

export const loadBankPaymentsErrorCountAsync = (params) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        let {limit, page, ...other} = params
        billingService.getBankPaymentsErrorCount(other)
            .then(response => {
                dispatch(billingSlice.actions.updateSystemOrganizationsBankPaymentsErrorCount(response.data))
                resolve(response.data)
            })
            .catch(error => {
                console.log(error)
                reject(error)
            })
    })
}

export const seenBankPaymentsErrorBilling = (id) => {
    return new Promise((resolve, reject) => {
        billingService.resolveBankPaymentsError(id)
            .then(res => {
                EventBus.dispatch(SEEN_BANK_PAYMENTS_ERROR_SUCCESS)
                resolve(res)
            })
            .catch(e => {
                EventBus.dispatch(SEEN_BANK_PAYMENTS_ERROR_FAILED)
                reject(e)
            })
    })
}

export const loadSystemOrganizationsAsync = ({page, limit, ...filters}) => dispatch => {
    const skip = (page - 1) * limit;
    const params = {skip, limit, ...filters};

    return new Promise((resolve, reject) => {
        BillingService.getSystemOrganizations(params)
            .then(response => {
                dispatch(billingSlice.actions.updateSystemOrganizations(response.data));
                resolve(response.data);
            }).catch(error => reject(error))
    })
}
export const getSystemOrganizationsCount = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        let {limit, page, ...others} = filter;

        BillingService.getSystemOrganizationsCount(others)
            .then(response => {
                dispatch(billingSlice.actions.updateSystemOrganizationsCount(response.data));
                resolve(response.data);
            }).catch(error => reject(error))
    })
}
export const changeSystemOrganizationsFilterOptions = (params) => dispatch => {
    return dispatch(billingSlice.actions.updateOrganizationsFilterOptions(params));
}
export const changeSystemOrganizationsFilterOptionsPayment = (params) => dispatch => {
    return dispatch(billingSlice.actions.updateOrganizationsFilterOptionsPayment(params));
}
export const changeSystemOrganizationsFilterOptionsBankPaymentsError = (params) => dispatch => {
    return dispatch(billingSlice.actions.updateOrganizationsFilterOptionBankPaymentsError(params))
}
export const addOrganizationBalanceAsync = ({id, balance}) => {
    EventBus.dispatch(ADD_ORGANIZATION_BALANCE_REQUESTED);

    return new Promise((resolve, reject) => {
        BillingService.addOrganizationBalance({id, balance})
            .then(response => {
                EventBus.dispatch(ADD_ORGANIZATION_BALANCE_SUCCEED, response.data);
                resolve(response.data);
            })
            .catch(error => {
                EventBus.dispatch(ADD_ORGANIZATION_BALANCE_FAILED, error);
                reject(error);
            })
    })
}

export const applyCreditIntoOrganizationAsync = ({id, amount}) => {
    EventBus.dispatch(APPLY_CREDIT_INTO_ORGANIZATION_REQUESTED, id);

    return new Promise((resolve, reject) => {
        billingService.applyCreditIntoOrganization({id, amount})
            .then((response) => {
                EventBus.dispatch(APPLY_CREDIT_INTO_ORGANIZATION_SUCCESS, id);
                resolve(response.data);
            })
            .catch((error) => {
                console.log(error);
                EventBus.dispatch(APPLY_CREDIT_INTO_ORGANIZATION_FAILED, id);
                reject(error);
            })
    })
}


export const getSystemOrganizationAsync = (id) => {
    return new Promise((resolve, reject) => {
        BillingService.getSystemOrganization(id)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
    })
}

export const autoActiveExtendPackage = (params) => {
        BillingService.setAutoExtendPackage(params)
            .then(() => null)
            .catch(() => null)
}

// own billing organization API

export const changeFilterOwnPaymentAsync = (params) => (dispatch) => {
    return  dispatch(billingSlice.actions.updateOwnPaymentsFilterOptions(params))
}

export const loadOwnPayments = ({page,limit}) => (dispatch) => {
    const skip = (page - 1) * limit
    const params = {skip,limit}
    return new Promise((resolve, reject) => {
        billingService.getOwnPayments(params)
            .then(response => {
                dispatch(billingSlice.actions.getOwnPayments(response.data))
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export const loadOwnPaymentsCount = () => (dispatch) => {
    return new Promise((resolve, reject) => {
        billingService.getOwnPaymentsCount()
            .then(response => {
                dispatch(billingSlice.actions.getOwnPaymentsCount(response.data))
            })
            .catch(error => {
                reject(error)
            })
    })
}

export const changeFilterBoughtAsync = (params) => (dispatch) => {
    return  dispatch(billingSlice.actions.updateBoughtFilterOptions(params))
}

export const loadBoughtPackages = ({page,limit}) => (dispatch) => {
    const skip = (page - 1) * limit
    const params = {skip,limit}
    return new Promise((resolve, reject) => {
        billingService.getBought(params)
            .then(response => {
                dispatch(billingSlice.actions.getBoughtPackages(response.data))
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export const loadBoughtPackagesCount = () => (dispatch) => {
    return new Promise((resolve, reject) => {
        billingService.getBoughtCount()
            .then(response => {
                dispatch(billingSlice.actions.getBoughtPackagesCount(response.data))
            })
            .catch(error => {
                reject(error)
            })
    })
}

export const changeFilterChargesAsync = (params) => (dispatch) => {
    return  dispatch(billingSlice.actions.updateOwnChargesFilterOptions(params))
}

export const loadChargesAsync = ({page, limit, ...filters}) => (dispatch) => {
    const skip = (page - 1) * limit;
    const params = {skip, limit, ...filters};
    return new Promise((resolve, reject) => {
        billingService.getCharges(params)
            .then(response => {
                dispatch(billingSlice.actions.getCharges(response.data))
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export const loadChargesCount = (params) => (dispatch) => {
    const {skip, limit, ...filter} = params
    return new Promise((resolve, reject) =>  {
        billingService.getChargesCount(filter)
            .then(response => {
                dispatch(billingSlice.actions.getChargesCount(response.data))
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
    })
}

// admin

export const changeFilterChargesAdminAsync = (params) => (dispatch) => {
    return  dispatch(billingSlice.actions.updateOwnChargesAdminFilterOptions(params))
}

export const loadChargesAdminAsync = ({id, page, limit, ...filters}) => (dispatch) => {
    const skip = (page - 1) * limit;
    const params = {skip, limit, ...filters};

    return new Promise((resolve, reject) => {
        billingService.getChargesAdmin(params)
            .then(response => {
                dispatch(billingSlice.actions.getChargesAdmin(response.data))
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export const loadChargesAdminCount = (params) => (dispatch) => {

    return new Promise((resolve, reject) =>  {
        billingService.getChargesAdminCount(params)
            .then(response => {
                dispatch(billingSlice.actions.getChargesAdminCount(response.data))
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export const changeFilterBoughtAdminAsync = (params) => (dispatch) => {
    return  dispatch(billingSlice.actions.updateBoughtAdminFilterOptions(params))
}

export const loadBoughtAdminPackages = ({page,limit, ...filter}) => (dispatch) => {
    const skip = (page- 1) * limit
    const params = {skip,limit, ...filter}
    return new Promise((resolve, reject) => {
        billingService.getBoughtAdmin(params)
            .then(response => {
                dispatch(billingSlice.actions.getBoughtAdminPackages(response.data))
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export const loadBoughtAdminPackagesCount = (params) => (dispatch) => {
    let {limit, page, ...filter} = params
    return new Promise((resolve, reject) => {
        billingService.getBoughtAdminCount(filter)
            .then(response => {
                dispatch(billingSlice.actions.getBoughtAdminPackagesCount(response.data))
            })
            .catch(error => {
                reject(error)
            })
    })
}

export const changeFilterAdminFilterAsync = (params) => (dispatch) => {
    return  dispatch(billingSlice.actions.updateAdminPaymentsFilterOption(params))
}
export const loadAdminOrganizationPaymentsAsync = ({page, limit, ...filters}) => (dispatch) => {
    const skip = (page - 1) * limit
    const params = {skip, limit, ...filters}

    billingService.getPayments(params)
        .then(response => {
            dispatch(billingSlice.actions.getAdminPayments(response.data))
        })
        .catch(error => {
            console.log(error)
        })
};

export const loadAdminOrganizationPaymentsCountAsync = params => (dispatch) => {
    let {limit, page, ...other} = params

    billingService.getPaymentsCount(other)
        .then(response => {
            dispatch(billingSlice.actions.getAdminPaymentsCount(response.data))
        })
        .catch(error => {
            console.log(error)
        })
}

// credentials
export const loadAdminOrganizationCredentialAsync = ({page, limit, organization_id}) => (dispatch) => {
    const skip = (page - 1) * limit
    const params = {skip, limit, organization_id}
    billingService.getCredentials(params)
        .then((res) => {
            dispatch(billingSlice.actions.getCredentials(res.data))
        })
        .catch((e) => {
            return new Error(e)
        })
}

export const getAdminOrganizationCredentialCountAsync = (params) => (dispatch) => {
    billingService.getCredentialsCount(params)
        .then((res) => {
            dispatch(billingSlice.actions.getCredentialsCount(res.data))
        })
        .catch(e => {
            return new Error(e)
        })
}

export const registerAdminOrganizationCredential = (payload) => {
    return new Promise((resolve, reject) => {
        billingService.registerCredential(payload)
            .then((res) => {
                EventBus.dispatch(REGISTER_CREDENTIAL_SUCCESS)
                resolve(res)
            })
            .catch((e) => {
                EventBus.dispatch(REGISTER_CREDENTIAL_FAIL)
                reject(e)
            })
    })
}

export const changeFilterAdminOrganizationCredentialsAsync = (params) => (dispatch) => {
    return dispatch(billingSlice.actions.updateAdminCredentialsFilterOption(params))
}

export const {clearOrganizationsFilterOptionsPayment,clearBoughtAdminFilterOptions,clearOrganizationsFilterOptionBankPaymentsError} = billingSlice.actions
export default billingSlice.reducer;
