import React from 'react';
import ReactSelect from "react-select";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {measurements} from "../enum/itemWrapper";


const SelectMeasurement = React.forwardRef(({onChange, defaultMeasurement, selectProps}, ref) => {
    const t = Translate;
    const lang = useSelector(selectLang);

    return (
        <ReactSelect options={measurements}
                     classNamePrefix="select"
                     isSearchable={false}
                     hideSelectedOptions
                     getOptionLabel={option => t(lang, option.label)}
                     getOptionValue={option => option.value}
                     placeholder={t(lang, "items.common.select")}
                     onChange={(option) => onChange(option.value)}
                     value={measurements.find(m => m.value === defaultMeasurement) || null}
                     {...selectProps}
        />
    )
})

export default SelectMeasurement;