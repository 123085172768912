import React, {useEffect, useRef, useState} from 'react';
import {Card, Col, Form, Row} from "react-bootstrap";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useDispatch, useSelector} from "react-redux";
import {FormProvider, useForm} from "react-hook-form";
import ImportItemsFromExcelForm from "./ImportItemsFromExcelForm";
import ExcelFileImportDropzone from "./ExcelFileImportDropzone";
import {addItemAsync, editItemAsync, selectItems} from "../../../../app/store/reducers/item/itemReducer";
import {
    Barcode,
    BulkPrice,
    Category,
    Code,
    CommonPrice,
    Description,
    ID,
    Measurement,
    Name,
    PackageMeasurementName,
    PackageMeasurementQuantity,
    PurchasePrice,
    Sku,
    State,
    TaxCatalogCode,
    TaxCatalogName,
    TaxRate
} from "../enum/itemExcelWrapper";
import {updatePriceAsync} from "../../../../app/store/reducers/price/priceReducer";
import {selectCurrency, selectNationalCurrency} from "../../../../app/store/reducers/currency/currencyReducer";
import {loadCategoriesAsync, selectAllCategories, updateAllCategories} from "../../../../app/store/reducers/category/categoryReducer";
import EventBus from "../../../../app/eventbus/EventBus";
import {BULK_ADD_ITEMS_FINISHED, BULK_ADD_ITEMS_STARTED, BULK_EDIT_ITEMS_FINISHED, BULK_EDIT_ITEMS_STARTED} from "../../../../app/eventbus/itemEvents";
import itemWrapper, {Piece, UZS} from "../enum/itemWrapper";
import ResultCounter from "../forms/bulk-item/ResultCounter";
import {toast} from "react-toastify";
import {selectWarehouses, updateWarehouseItemPurchasePriceAsync} from "../../../../app/store/reducers/warehouse/warehouseReducer";
import dayjs from "dayjs";
import {revisionRegisterAsync} from "../../../../app/store/reducers/warehouse-operation/revisionReducer";

const ImportItemsFromExcel = () => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const items = useSelector(selectItems);
    const categories = useSelector(selectAllCategories);
    const currencies = useSelector(selectCurrency);
    const warehouses = useSelector(selectWarehouses);
    const nationalCurrency = useSelector(selectNationalCurrency);
    const dispatch = useDispatch()
    const form = useForm({
        defaultValues: {
            items: [],
            columns: [],
            skipRows: 1,
            globalWarehouseId: null,
            globalCurrencyId: null,
            isEditing: false,
            skipNameUniqeValidation: false
        },
    });
    const ref = useRef(null);
    const [failed, setFailed] = useState([]);

    useEffect(() => {
        if (currencies.length)
            form.setValue(`globalCurrencyId`, currencies.find(c => c.code === UZS).id)
    }, [currencies])

    useEffect(() => {
        if (warehouses.length)
            form.setValue(`globalWarehouseId`, warehouses[0].id)
    }, [warehouses])

    const loadCategories = () => {
        loadCategoriesAsync()
            .then((categories) => dispatch(updateAllCategories(categories)))
            .catch((error) => dispatch(updateAllCategories([])))
    };

    const findExistingItem = (id, name, code, barcodes) => {
        return items.find(i => {
            if (id && i.item.id === id.toString().trim())
                return true;

            if (name && name.trim().toLowerCase() === i.item.name.trim().toLowerCase())
                return true;

            if (code && code.trim().toLowerCase() === i.item.code.trim().toLowerCase())
                return true;

            if (barcodes) {
                for (let j = 0; j < barcodes.length; j++)
                    if (i.item.barcodes.includes(barcodes[j]))
                        return true
            }
            return false;
        })
    }

    const findCategory = name => {
        if (typeof (name) === 'string' && name.length > 0) {
            return categories.find(c => c.name.toLowerCase().trim() === name.toLowerCase().trim()) || null
        }
        return null
    }

    const onSubmit = async (formData) => {
        ref.current.updateLoadedCount(formData.items.length - formData.skipRows);
        setFailed([]);
        const failed = [];

        const getCleanString = (input, trimWhiteSpaces, emptyIsNull) => {
            let stringValue = `${input}`.trim()

            if (trimWhiteSpaces)
                while (stringValue.indexOf(' ') > -1)
                    stringValue = stringValue.replace(' ', '')

            if (stringValue.length === 0)
                return null

            return stringValue
        }

        const getArrayValues = (input, trimChars) => {
            let stringValue = `${input}`.trim()
            let values = []

            for (let i = 0; i < trimChars.length; i++) {
                const parts = stringValue.split(trimChars[i])
                if (parts.length > 1) {
                    for (let j = 0; j < parts.length; j++) {
                        const v = `${parts[j]}`.trim()
                        if (v.length > 0) values.push(v)
                    }
                    break
                }
            }

            if (values.length === 0)
                values.push(stringValue)

            return values
        }

        const getNumber = (input, emptyIsNull) => {
            let stringValue = getCleanString(input, true, false)
            while (stringValue?.indexOf(',') > -1)
                stringValue = stringValue.replace(',', '.')

            if (emptyIsNull && stringValue.length === 0)
                return null

            return +stringValue
        }

        try {
            // collect form data
            const data = []
            for (let i = formData.skipRows; i < formData.items.length; i++) {
                let rowData = formData.items[i];
                let rowItem = {
                    commonPriceCurrencyId: formData.commonPrice?.currencyId || null,
                    bulkPriceCurrencyId: formData.bulkPrice?.currencyId || null,
                    purchasePriceCurrencyId: formData.purchasePrice?.currencyId || null,
                    rowData: rowData
                };

                for (let j = 0; j < rowData.length; j++) {
                    if (rowData[j]) {
                        switch (formData.columns[j]) {
                            case Name:
                                rowItem.name = getCleanString(rowData[j], false, false);
                                break;
                            case Description:
                                rowItem.description = getCleanString(rowData[j], false, true);
                                break;
                            case Sku:
                                rowItem.sku = getCleanString(rowData[j], true, true);
                                break;
                            case Code:
                                rowItem.code = getCleanString(rowData[j], false, true);
                                break;
                            case Barcode:
                                rowItem.barcodes = getArrayValues(rowData[j], [',',' ', '\n']).filter(x => x.length > 0) || null;
                                break;
                            case State:
                                rowItem.state = getNumber(rowData[j], false);
                                break;
                            case Measurement:
                                rowItem.measurement = getCleanString(rowData[j], false, true);
                                break;
                            case Category:
                                rowItem.categoryName = getCleanString(rowData[j], false, true);
                                break;
                            case CommonPrice:
                                rowItem.commonPrice = getNumber(rowData[j], false);
                                break;
                            case BulkPrice:
                                rowItem.bulkPrice = getNumber(rowData[j], false);
                                break;
                            case PurchasePrice:
                                rowItem.purchasePrice = getNumber(rowData[j], false);
                                break;
                            case TaxCatalogCode:
                                rowItem.taxCatalogCode = getCleanString(rowData[j], false, true);
                                break;
                            case TaxCatalogName:
                                rowItem.taxCatalogName = getCleanString(rowData[j], false, true);
                                break;
                            case TaxRate:
                                rowItem.taxRate = getNumber(rowData[j], true);
                                break;

                            case PackageMeasurementName:
                                rowItem.packageMeasurementName = getCleanString(rowData[j], false, true);
                                break;

                            case PackageMeasurementQuantity:
                                rowItem.packageMeasurementQuantity = getNumber(rowData[j], false);
                                break;

                            case ID:
                                rowItem.id = getCleanString(rowData[j], false, true);
                                break;

                            default:
                                break;
                        }
                    }
                }

                data.push(rowItem)
            }

            if (formData.isEditing) {
                EventBus.dispatch(BULK_EDIT_ITEMS_STARTED);
                const revisionItems = [];
                console.log(data, 'data')
                for (let i = 0; i < data.length; i++) {
                    const itemData = data[i];
                    const existingItem = findExistingItem(itemData.id, itemData.name, itemData.code, itemData.barcodes)
                    console.log(existingItem, 'existingItem')
                    if (!existingItem) {
                        failed.push(itemData.rowData);
                        ref.current.incrementFailedCount();
                        continue
                    }

                    let itemJson = {
                        skip_name_unique_validation: formData.skipNameUniqeValidation,
                        name: typeof (itemData.name) === 'undefined' ? existingItem.item.name : itemData.name,
                        description: typeof (itemData.description) === 'undefined' ? existingItem.item.description : itemData.description,
                        sku: typeof (itemData.sku) === 'undefined' ? existingItem.item.sku : itemData.sku,
                        code: typeof (itemData.code) === 'undefined' ? existingItem.item.code : itemData.code,
                        barcodes: typeof (itemData.barcodes) === 'undefined' ? existingItem.item.barcodes : itemData.barcodes,
                        measurement: typeof (itemData.measurement) === 'undefined' ? existingItem.item.measurement.toString() : itemData.measurement,
                        category_id: typeof (itemData.categoryName) === 'undefined' ? existingItem.item.category?.id : (findCategory(itemData.categoryName)?.id || null),
                        category_name: itemData.categoryName,
                        tax: {
                            catalog: null,
                            measurement: existingItem.item.tax?.measurement || null,
                            benefit: existingItem.item.tax?.benefit || null,
                            tax_rate: typeof (itemData.taxRate) === 'undefined' ? existingItem.item.tax?.tax_rate || null : itemData.taxRate,
                            origin: existingItem.item.tax?.origin || null
                        },
                        images: existingItem.item.images?.map(x => {
                            return {
                                id: x.id,
                                name: x.name
                            }
                        }) || [],
                        package_measurements: existingItem.package_measurements || []
                    }

                    if (existingItem.item.tax.catalog) {
                        itemJson.tax.catalog = {
                            code: existingItem.item.tax.catalog.code,
                            name: existingItem.item.tax.catalog.name,
                            package: null,
                        }
                        if (existingItem.item.tax.catalog.package) {
                            itemJson.tax.catalog.package = {
                                code: existingItem.item.tax.catalog.package.code,
                                name: existingItem.item.tax.catalog.package.name,
                            }
                        }
                    }

                    if (itemData.taxCatalogCode && itemData.taxCatalogCode.length === 17 && itemData.taxCatalogName)
                        itemJson.tax.catalog = {
                            code: itemData.taxCatalogCode,
                            name: itemData.taxCatalogName,
                            package: itemJson.tax.catalog?.package
                        }

                    if (itemData.packageMeasurementName && itemData.packageMeasurementQuantity)
                        if (typeof (itemData.packageMeasurementName) === 'string' && typeof (itemData.packageMeasurementQuantity) === 'number' && !isNaN(itemData.packageMeasurementQuantity))
                            itemJson.package_measurements = [{name: itemData.packageMeasurementName, quantity: itemData.packageMeasurementQuantity}]

                    try {
                        const updatedItem = await editItemAsync(existingItem.item.id, itemJson)
                        if (formData.globalWarehouseId && itemData.hasOwnProperty(State)) {
                            const revisionItem = {
                                item_id: updatedItem.id,
                                warehouse_id: formData.globalWarehouseId,
                                quantity: +itemData.state
                            };
                            revisionItems.push(revisionItem);
                        }

                        // update price
                        let shouldUpdatePrice = false
                        let priceJson = {
                            item_id: updatedItem.id,
                            common_price: {
                                currency_id: existingItem.price.common_price.currency.global_currency_id,
                                amount: existingItem.price.common_price.amount,
                            },
                            bulk_price: {
                                currency_id: existingItem.price.bulk_price.currency.global_currency_id,
                                amount: existingItem.price.bulk_price.amount,
                            }
                        };

                        if (typeof (itemData.commonPrice) !== 'undefined') {
                            priceJson.common_price = {
                                currency_id: itemData.commonPriceCurrencyId || nationalCurrency.id,
                                amount: itemData.commonPrice,
                            }
                            shouldUpdatePrice = true
                        }

                        if (typeof (itemData.bulkPrice) !== 'undefined') {
                            priceJson.bulk_price = {
                                currency_id: itemData.bulkPriceCurrencyId || nationalCurrency.id,
                                amount: itemData.bulkPrice,
                            }
                            shouldUpdatePrice = true
                        }

                        if (shouldUpdatePrice)
                            await updatePriceAsync(priceJson);


                        // update warehouse item purchase price
                        let shouldUpdatePurchasePrice = false;
                        let purchasePriceJson = {}

                        if (formData.globalWarehouseId) {
                            if (typeof (itemData.purchasePrice) !== 'undefined') {
                                purchasePriceJson = {
                                    id: updatedItem.id,
                                    warehouse_id: formData.globalWarehouseId,
                                    currency_id: itemData.purchasePriceCurrencyId || nationalCurrency.id,
                                    amount: itemData.purchasePrice,
                                }
                                shouldUpdatePurchasePrice = true
                            }
                        }

                        if (shouldUpdatePurchasePrice)
                            await updateWarehouseItemPurchasePriceAsync(purchasePriceJson)


                        ref.current.incrementSucceedCount();
                    } catch (error) {
                        console.log(error);

                        if (error?.response?.status === 404)
                            itemData.rowData[itemData.rowData.length] = t(lang, "items.common.not_found");

                        if (error?.response?.data?.name_exists)
                            itemData.rowData[itemData.rowData.length] = t(lang, "items.common.validations.name_exists", {name: itemData.name});

                        if (error?.response?.data?.code_exists)
                            itemData.rowData[itemData.rowData.length] = t(lang, "items.common.validations.code_exists", {code: itemData.code});

                        if (error?.response?.data?.existing_barcodes?.length > 0)
                            itemData.rowData[itemData.rowData.length] = t(lang, "items.common.validations.existing_barcodes", {barcode: error.existing_barcodes});

                        ref.current.incrementFailedCount();
                        failed.push(itemData.rowData);
                    }
                }

                if (revisionItems.length) {
                    const revisionJson = {
                        approve: true,
                        date: dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                        items: revisionItems,
                    };
                    await revisionRegisterAsync(revisionJson);
                }

                EventBus.dispatch(BULK_EDIT_ITEMS_FINISHED);
            } else {
                EventBus.dispatch(BULK_ADD_ITEMS_STARTED);
                const revisionItems = [];

                for (let i = 0; i < data.length; i++) {
                    const itemData = data[i];
                    let itemJson = {
                        skip_name_unique_validation: formData.skipNameUniqeValidation,
                        name: itemData.name || '',
                        description: itemData.description || '',
                        sku: itemData.sku || null,
                        code: itemData.code || null,
                        barcodes: itemData.barcodes || [itemWrapper.generateGtinBarcode(new Date().getTime().toString().slice(5, 13))],
                        measurement: itemData.measurement || Piece,
                        category_id: findCategory(itemData.categoryName)?.id || null,
                        category_name: itemData.categoryName,
                        tax: {
                            catalog: null,
                            measurement: null,
                            benefit: null,
                            tax_rate: itemData.taxRate
                        },
                        common_price: null,
                        bulk_price: null,
                        purchase_price: null,
                        package_measurements: [],
                    }

                    if (itemData.taxCatalogCode && itemData.taxCatalogCode.length === 17 && itemData.taxCatalogName)
                        itemJson.tax.catalog = {
                            code: itemData.taxCatalogCode,
                            name: itemData.taxCatalogName,
                            package: null
                        }

                    if (typeof (itemData.commonPrice) !== 'undefined')
                        itemJson.common_price = {
                            currency_id: itemData.commonPriceCurrencyId || nationalCurrency.id,
                            amount: itemData.commonPrice,
                        }

                    if (typeof (itemData.bulkPrice) !== 'undefined')
                        itemJson.bulk_price = {
                            currency_id: itemData.bulkPriceCurrencyId || nationalCurrency.id,
                            amount: itemData.bulkPrice,
                        }

                    if (typeof (itemData.purchasePrice) !== 'undefined')
                        itemJson.purchase_price = {
                            currency_id: itemData.purchasePriceCurrencyId || nationalCurrency.id,
                            amount: itemData.purchasePrice,
                        }

                    if (typeof (itemData.purchasePrice) !== 'undefined')
                        itemJson.purchase_price = {
                            currency_id: itemData.purchasePriceCurrencyId || nationalCurrency.id,
                            amount: itemData.purchasePrice,
                        }

                    if (itemData.packageMeasurementName && itemData.packageMeasurementQuantity)
                        if (typeof (itemData.packageMeasurementName) === 'string' && typeof (itemData.packageMeasurementQuantity) === 'number' && !isNaN(itemData.packageMeasurementQuantity))
                            itemJson.package_measurements = [{name: itemData.packageMeasurementName, quantity: itemData.packageMeasurementQuantity}]

                    try {
                        const createdItem = await addItemAsync(itemJson)
                        if (formData.globalWarehouseId && itemData.hasOwnProperty(State)) {
                            const revisionItem = {
                                item_id: createdItem.id,
                                warehouse_id: formData.globalWarehouseId,
                                quantity: +itemData.state
                            };
                            revisionItems.push(revisionItem);
                        }
                        ref.current.incrementSucceedCount();
                    } catch (error) {
                        console.log(error);

                        if (error?.response?.status === 404)
                            itemData.rowData[itemData.rowData.length] = t(lang, "items.common.not_found");

                        if (error?.response?.data?.name_exists)
                            itemData.rowData[itemData.rowData.length] = t(lang, "items.common.validations.name_exists", {name: itemData.name});

                        if (error?.response?.data?.code_exists)
                            itemData.rowData[itemData.rowData.length] = t(lang, "items.common.validations.code_exists", {code: itemData.code});

                        if (error?.response?.data?.existing_barcodes?.length > 0)
                            itemData.rowData[itemData.rowData.length] = t(lang, "items.common.validations.existing_barcodes", {barcode: error.existing_barcodes});

                        ref.current.incrementFailedCount();
                        failed.push(itemData.rowData);
                    }
                }

                if (revisionItems.length) {
                    const revisionJson = {
                        approve: true,
                        date: dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                        items: revisionItems,
                    };
                    await revisionRegisterAsync(revisionJson);
                }
                EventBus.dispatch(BULK_ADD_ITEMS_FINISHED);
            }

            loadCategories()
        } catch (error) {
            console.log(error);
        }

        if (failed.length > 0)
            toast.error(t(lang, 'items.common.toast.error'))
        else
            toast.success(t(lang, 'items.common.toast.success'));

        form.setValue('items', failed);
        setFailed(failed);
    }

    const onChangeFile = (parsedContent) => {
        setFailed([]);
        form.setValue('items', parsedContent);
    }

    return (
        <Row>
            <Col xs={12}>
                <ResultCounter ref={ref}/>
            </Col>
            <Col xs={12}>
                <Row className="mt-3">
                    <Col xs={12}>
                        <Card>
                            <Card.Body>
                                <ExcelFileImportDropzone onChangeFile={onChangeFile}/>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12}>
                        <FormProvider {...form}>
                            <Form onSubmit={form.handleSubmit(onSubmit)}>
                                <ImportItemsFromExcelForm failed={failed}/>
                            </Form>
                        </FormProvider>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default ImportItemsFromExcel;
