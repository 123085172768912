import React, {useEffect, useState} from 'react';
import {Card, Col, Dropdown, Row, Table} from "react-bootstrap";
import ItemRow from "./ItemRow";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {useFieldArray, useFormContext} from "react-hook-form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import itemWrapper, {ItemLegalTypeWhite, Piece, UZS, WithoutVat} from "../../enum/itemWrapper";
import {toast} from "react-toastify";
import {getProductGuideAsync, selectItems} from "../../../../../app/store/reducers/item/itemReducer";
import EventBus from "../../../../../app/eventbus/EventBus";
import {SCAN_SUCCESS} from "../../../../../app/eventbus/itemEvents";
import {selectWarehouses} from "../../../../../app/store/reducers/warehouse/warehouseReducer";
import {selectCurrency} from "../../../../../app/store/reducers/currency/currencyReducer";
import {selectOrganization} from "../../../../../app/store/reducers/draft-item/draftItemReducer";
import SoftBadge from "../../../../common/SoftBadge";
import IconButton from "../../../../common/IconButton";
import {faDownload, faPlus, faXmarkCircle} from "@fortawesome/free-solid-svg-icons";
import {CheckScanBarcodeOrMarking} from "../../../warehouse-operation/enum/warehouseOperationWrapper";


const itemsFieldName = "items";
const BulkItemsForm = ({
                           isEditing,
                           allowAddItem,
                           allowDeleteItem,
                           onReset,
                           onSubmit
                       }) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const form = useFormContext();
    const items = useSelector(selectItems);
    const warehouses = useSelector(selectWarehouses);
    const currencies = useSelector(selectCurrency);
    const organization = useSelector(selectOrganization);
    const [clickState, setClickState] = useState({
        editingIndex: 0,
        clicked: false,
        points: {x: 0, y: 0},
        clickedFieldName: '',
        clickedFieldValue: null
    });
    const {fields, append, remove, replace} = useFieldArray({
        name: itemsFieldName,
        control: form.control,
        keyName: 'fieldId'
    });
    const nameFieldArray = fields.length > 0 ? fields[0].names : [{name: ''}];

    const addNewItem = () => {
        const item = {
            names: nameFieldArray.map((name, index) => {
                return {name: ''}
            }),
            description: '',
            isStateControlled: false,
            sku: '',
            code: '',
            barcodes: [
                {barcode: itemWrapper.generateGtinBarcode(new Date().getTime().toString().slice(5, 13))}
            ],
            state: '',
            warehouseId: warehouses.length ? warehouses[0].id : null,
            measurement: Piece,
            categoryId: null,
            descriptionAttributes: [],
            packageMeasurements: [],
            taxCatalog: null,
            taxMeasurement: null,
            taxPackage: null,
            taxBenefit: null,
            taxRate: organization?.default_tax_rate || WithoutVat,
            legalType: ItemLegalTypeWhite,
            images: [],
            commonPrice: {
                price: '',
                currencyId: currencies.find(c => c.code === UZS)?.id,
            },
            bulkPrice: {
                price: '',
                currencyId: currencies.find(c => c.code === UZS)?.id,
            },
            purchasePrice: {
                price: '',
                currencyId: currencies.find(c => c.code === UZS)?.id
            },
        };

        append(item, {shouldFocus: true, focusName: `${itemsFieldName}.${fields.length}.names.${0}.name`});
    };
    const addNameColumn = () => {
        const newFields = fields.map((field, index) => {
            return {
                ...field,
                names: [
                    ...field.names,
                    {name: ''}
                ]
            }
        });
        replace(newFields);
    };
    const removeNameColumn = (index) => {
        const newFields = fields.map((field, i) => {
            return {
                ...field,
                names: [...field.names.slice(0, index), ...field.names.slice(index + 1)]
            }
        });
        replace(newFields);
    };
    const deleteItem = (index) => {
        remove(index);
    };
    const checkIsExistingItem = (name, code, barcode, id) => {
        return items.some(i => {
            if (i.item.id === id)
                return false;

            if (name && name.trim().toLowerCase() === i.item.name.trim().toLowerCase())
                return true;

            if (code && code.trim().toLowerCase() === i.item.code?.trim().toLowerCase())
                return true;

            if (barcode && i.item.barcodes.includes(barcode))
                return true;

            return false;
        })
    };

    const onRightClickHandler = (e, index, name, value) => {
        e.preventDefault();
        setClickState(prev => ({
                ...prev,
                editingIndex: index,
                clicked: true,
                points: {
                    x: e.clientX,
                    y: e.clientY
                },
                clickedFieldName: name,
                clickedFieldValue: value,
            })
        );
    };
    const onFillRawValuesByContextMenu = () => {
        for (let i = clickState.editingIndex + 1; i < fields.length; i++) {
            form.setValue(`items.${i}.${clickState.clickedFieldName}`, clickState.clickedFieldValue);
        }
        onCloseContextMenu();
    };
    const onClearRawValuesByContextMenu = () => {
        for (let i = clickState.editingIndex + 1; i < fields.length; i++) {
            form.setValue(`items.${i}.${clickState.clickedFieldName}`, fields[i][clickState.clickedFieldName]);
        }
        onCloseContextMenu();
    };
    const onCloseContextMenu = () => {
        if (clickState.clicked) {
            setClickState({
                editingIndex: 0,
                clicked: false,
                points: {x: 0, y: 0},
                clickedFieldName: '',
                clickedFieldValue: null
            })
        }
    };

    useEffect(() => {
        document.addEventListener("click", onCloseContextMenu);
        return () => {
            document.removeEventListener("click", onCloseContextMenu);
        };
    }, [clickState]);

    const onScan = async (value) => {
        const formItems = form.getValues(itemsFieldName);

        const barcodes = [];
        const cuteBarcode = CheckScanBarcodeOrMarking(value)
        barcodes.push({barcode: cuteBarcode});
        try {
            const {product_guide, tasnif_product_guide: catalog} = await getProductGuideAsync(cuteBarcode);
            if (isEditing) {
                const existingBarcodeItemIndex = formItems.findIndex(i => i.barcodes.find(b => b.barcode === cuteBarcode));
                if (existingBarcodeItemIndex > -1) {
                    const item = formItems[existingBarcodeItemIndex];
                    form.setValue(`${itemsFieldName}.${existingBarcodeItemIndex}.names.${0}.name`, item.name || product_guide?.name || catalog?.name || '');
                    form.setValue(`${itemsFieldName}.${existingBarcodeItemIndex}.tax.catalog`, catalog);
                    form.setValue(`${itemsFieldName}.${existingBarcodeItemIndex}.tax.package`, null)
                } else {
                    toast.error(t(lang, "items.common.not_found"));
                }
            } else {
                // try to find from field array
                {
                    const isExistingBarcodeItem = formItems.some(i => i.barcodes.find(b => b.barcode === cuteBarcode));
                    if (isExistingBarcodeItem) {
                        toast.warning(t(lang, "items.common.validations.existing_barcodes", {barcode: cuteBarcode}))
                        return;
                    }
                }

                // try to find from existing items
                {
                    const isExistingBarcodeItem = checkIsExistingItem(null, null, cuteBarcode);
                    if (isExistingBarcodeItem) {
                        toast.error(t(lang, "items.common.validations.existing_barcodes", {barcode: cuteBarcode}))
                        return
                    }
                }

                const item = {
                    names: nameFieldArray.map((name, index) => {
                        if (index === 0)
                            return {name: product_guide?.name || catalog?.name || ''};
                        return {name: ''}
                    }),
                    description: '',
                    isStateControlled: false,
                    sku: '',
                    code: '',
                    barcodes: barcodes,
                    state: '',
                    warehouseId: warehouses.length ? warehouses[0].id : null,
                    measurement: Piece,
                    categoryId: null,
                    descriptionAttributes: [],
                    packageMeasurements: [],
                    taxCatalog: catalog,
                    taxMeasurement: null,
                    taxPackage: (catalog?.use_package && catalog?.package_names.length === 1) ? catalog.package_names[0] : null,
                    taxBenefit: null,
                    taxRate: organization?.default_tax_rate || WithoutVat,
                    images: [],
                    commonPrice: {
                        price: '',
                        currencyId: currencies.find(c => c.code === UZS)?.id,
                    },
                    bulkPrice: {
                        price: '',
                        currencyId: currencies.find(c => c.code === UZS)?.id,
                    },
                    purchasePrice: {
                        price: '',
                        currencyId: currencies.find(c => c.code === UZS)?.id
                    },
                };
                append(item, {
                    shouldFocus: true,
                    focusName: !!form.getValues(`${itemsFieldName}.${formItems.length}.name`) ? `${itemsFieldName}.${formItems.length}.commonPrice` : `${itemsFieldName}.${formItems.length}.name`
                });
            }
        } catch (error) {
            console.log(error);
            const item = {
                names: nameFieldArray.map((name, index) => {
                    return {name: ''}
                }),
                description: '',
                isStateControlled: false,
                sku: '',
                code: '',
                barcodes: barcodes,
                state: '',
                warehouseId: warehouses.length ? warehouses[0].id : null,
                measurement: Piece,
                categoryId: null,
                descriptionAttributes: [],
                packageMeasurements: [],
                taxCatalog: null,
                taxMeasurement: null,
                taxPackage: null,
                taxBenefit: null,
                taxRate: organization?.default_tax_rate,
                images: [],
                commonPrice: {
                    price: '',
                    currencyId: currencies.find(c => c.code === UZS)?.id,
                },
                bulkPrice: {
                    price: '',
                    currencyId: currencies.find(c => c.code === UZS)?.id,
                },
                purchasePrice: {
                    price: '',
                    currencyId: currencies.find(c => c.code === UZS)?.id
                },
            };
            append(item, {shouldFocus: true, focusName: `${itemsFieldName}.${formItems.length}.name`});
        }
    }

    useEffect(() => {
        const onScanSuccessHandler = EventBus.on(SCAN_SUCCESS, onScan);

        return () => {
            EventBus.remove(SCAN_SUCCESS, onScanSuccessHandler);
        }
    }, [nameFieldArray])

    return (
        <>
            {clickState.clicked &&
                <Dropdown.Menu show={true}
                               style={{
                                   position: 'absolute',
                                   top: `${clickState.points.y}px`,
                                   left: `${clickState.points.x}px`
                               }}>
                    <Dropdown.Item onClick={onFillRawValuesByContextMenu}>
                        {t(lang, 'items.common.bulk_fill_column_values')}
                    </Dropdown.Item>
                    <Dropdown.Item onClick={onClearRawValuesByContextMenu}>
                        {t(lang, 'items.common.bulk_remove_column_values')}
                    </Dropdown.Item>
                    <Dropdown.Item onClick={onCloseContextMenu}>
                        {t(lang, 'items.common.close')}
                    </Dropdown.Item>
                </Dropdown.Menu>
            }
            <Card className={'border border-300'} style={{position: 'initial'}}>
                <Card.Header>
                    <div className="d-flex flex-column flex-sm-row justify-content-sm-between flex-sm-wrap">
                        <Card.Title>
                            <span className="me-2">{t(lang, 'items.table.title')}</span>
                            <SoftBadge bg={'warning'}>{fields.length}</SoftBadge>
                        </Card.Title>
                        <div className="d-grid d-sm-block">
                            <IconButton icon={faXmarkCircle}
                                        variant={'falcon-danger'}
                                        onClick={onReset}
                                        className="my-2 my-sm-0 me-sm-2"
                            >
                                {t(lang, 'items.common.reset_all_values')}
                            </IconButton>
                            {allowAddItem &&
                                <IconButton icon={faPlus}
                                            variant={'falcon-primary'}
                                            onClick={addNewItem}
                                >
                                    {t(lang, 'items.common.add_new')}
                                </IconButton>
                            }
                        </div>
                    </div>
                </Card.Header>
                <Card.Body>
                    <div className="table-responsive overflow-auto" style={{maxHeight: '700px'}}>
                        <Table striped bordered>
                            <thead style={{position: 'sticky', top: '-1px', zIndex: '3', backgroundColor: 'aliceblue'}}>
                            <tr>
                                <th className="p-0 text-center align-middle" style={{minWidth: '30px'}}>#</th>
                                <th className="p-0 text-center align-middle" style={{minWidth: '300px'}}>
                            <span className="me-2">
                                <FontAwesomeIcon icon={"plus"}
                                                 className="cursor-pointer text-primary"
                                                 onClick={addNameColumn}
                                />
                            </span>
                                    <span>{t(lang, "items.common.name")}</span>
                                    <span className="text-danger cursor-pointer">*</span>
                                </th>
                                {nameFieldArray.map((field, index) => {
                                    if (index > 0) {
                                        return (
                                            <th className="p-0 align-middle text-center" key={`${field.name} ${index}`}>
                                                 <span className="me-2">
                                                    <FontAwesomeIcon icon={"trash-alt"}
                                                                     className="cursor-pointer text-danger"
                                                                     onClick={() => removeNameColumn(index)}
                                                    />
                                                 </span>
                                                <span>{t(lang, "items.common.name")} {index + 1}</span>
                                            </th>
                                        )
                                    }
                                    return null;
                                })}
                                <th className="p-0 text-center align-middle" style={{minWidth: '200px'}}>
                                    {t(lang, "items.common.measurement")}
                                    <span className="text-danger">*</span>
                                </th>
                                <th className="p-0 text-center align-middle" style={{minWidth: '250px'}}>
                                    {t(lang, "items.common.price")}
                                </th>
                                <th className="p-0 text-center align-middle" style={{minWidth: '250px'}}>
                                    {t(lang, "items.common.bulk_price")}
                                </th>
                                <th className="p-0 text-center align-middle" style={{minWidth: '350px'}}>
                                    {t(lang, 'items.common.purchase_price')}
                                </th>
                                {!isEditing && <>
                                    <th className="p-0 text-center align-middle" style={{minWidth: '150px'}}>
                                        {t(lang, "items.common.state")}
                                    </th>
                                    <th className="p-0 text-center align-middle" style={{minWidth: '250px'}}>
                                        {t(lang, "items.common.warehouse")}
                                    </th>
                                </>}
                                <th className="p-0 text-center align-middle" style={{minWidth: '200px'}}>
                                    {t(lang, 'items.common.alert_on.state')}
                                </th>
                                <th className="p-0 text-center align-middle" style={{minWidth: '500px'}}>
                                    {t(lang, 'items.common.package_measurement')}
                                </th>
                                <th className="p-0 text-center align-middle" style={{minWidth: '350px'}}>
                                    {t(lang, "items.common.category")}
                                </th>
                                <th className="p-0 text-center align-middle" style={{minWidth: '230px'}}>
                                    {t(lang, "items.common.barcode")}
                                </th>
                                <th className="p-0 text-center align-middle" style={{minWidth: '120px'}}>
                                    {t(lang, "items.common.code")}
                                </th>
                                <th className="p-0 text-center align-middle" style={{minWidth: '160px'}}>
                                    {t(lang, "items.common.sku")}
                                </th>
                                <th className="p-0 text-center align-middle" style={{minWidth: '230px'}}>
                                    {t(lang, 'items.common.catalog')}
                                </th>
                                <th className="p-0 text-center align-middle" style={{minWidth: '230px'}}>
                                    {t(lang, 'items.common.roaming_measurement')}
                                </th>
                                <th className="p-0 text-center align-middle" style={{minWidth: '230px'}}>
                                    {t(lang, 'items.common.tax_rate')}
                                </th>
                                <th className="p-0 text-center align-middle" style={{minWidth: '230px'}}>
                                    {t(lang, 'items.common.benefit')}
                                </th>
                                <th className="p-0 text-center align-middle" style={{minWidth: '230px'}}>
                                    {t(lang, 'items.common.legal_type')}
                                </th>
                                <th>{t(lang, "items.common.images")}</th>
                                <th className="p-0 text-center align-middle" style={{minWidth: '30px'}}></th>
                            </tr>
                            </thead>
                            <tbody>
                            {fields.map((field, index) => (
                                <ItemRow key={field.fieldId}
                                         isEditing={isEditing}
                                         fieldName={`${itemsFieldName}.${index}`}
                                         index={index}
                                         deleteItem={deleteItem}
                                         allowDeleteItem={allowDeleteItem}
                                         id={field.id}
                                         checkIsExistingItem={checkIsExistingItem}
                                         onRightClickHandler={onRightClickHandler}
                                />
                            ))}
                            </tbody>
                        </Table>
                    </div>
                </Card.Body>
                <Card.Footer>
                    <Row>
                        <Col xs={12} className="d-grid d-md-block text-end">
                            <IconButton icon={faDownload}
                                        variant={'falcon-primary'}
                                        onClick={() => onSubmit(replace)}
                            >
                                {t(lang, 'items.common.save')}
                            </IconButton>
                        </Col>
                    </Row>
                </Card.Footer>
            </Card>
        </>
    );
};

export default BulkItemsForm;
