import classNames from "classnames";
import Cleave from "cleave.js/react";
import React, {useState} from 'react';
import {useSelector} from "react-redux";
import IconButton from "../../../../common/IconButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button, Card, Col, Form, InputGroup, Row, Spinner} from "react-bootstrap";
import {Controller, useFieldArray, useForm} from "react-hook-form";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {ContractorPaymentTypes} from "../../../../../enum/ContractorWrapper";
import Divider from "../../../../common/Divider";
import {toast} from "react-toastify";
import {useHistory} from "react-router-dom";
import DatePicker from "react-datepicker";
import EventBus from "../../../../../app/eventbus/EventBus";
import {
    CONFIGURE_TELEGRAM_ORDER_BOT_CONFIG_FAILED,
    CONFIGURE_TELEGRAM_ORDER_BOT_CONFIG_REQUESTED,
    CONFIGURE_TELEGRAM_ORDER_BOT_CONFIG_SUCCEED
} from "../../../../../app/eventbus/telegram-bot/telegramOrderBotEvents";
import TelegramOrderBotService from "../../../../../services/telegram-order-bot/telegramOrderBotService";


const nationalPhoneNumberPrefix = '998';
const TelegramOrderBotConfigsForm = ({botConfiguration}) => {
    const history = useHistory();
    const t = Translate;
    const lang = useSelector(selectLang);
    const form = useForm({
        defaultValues: {
            allowSendDailyReport: botConfiguration.allow_send_daily_report,
            dailyReportHour: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), botConfiguration.daily_report_hour, 0, 0),
            allowOrders: botConfiguration.allow_orders,
            displayItemWarehouseStates: botConfiguration.display_item_warehouse_states,
            allowOrdersContractorsOnly: botConfiguration.allow_orders_contractors_only,
            orderUseBulkPrice: botConfiguration.order_use_bulk_price,
            minDaysBeforeOrdering: botConfiguration.order_min_before_days,
            notifySaleEvents: botConfiguration.notify_sale_events,
            notifyPaymentEvents: botConfiguration.notify_payment_events,
            notifyPayoutEvents: botConfiguration.notify_payout_events,
            notifyCashInEvents: botConfiguration.notify_cash_in_events,
            notifyCashOutEvents: botConfiguration.notify_cash_out_events,
            notifyExpenseEvents: botConfiguration.notify_expense_events,
            notifyCashTransferEvents: botConfiguration.notify_cash_transfer_events,
            notifyContractorStateEvents: botConfiguration.notify_contractor_state_events,
            notifyTelegramOrderBotOrderEvents: botConfiguration.notify_telegram_order_bot_order_events,
            contacts: botConfiguration.contacts.length ? botConfiguration.contacts.map(contact => {
                const phoneNumber = contact.startsWith(nationalPhoneNumberPrefix) ? contact.slice(nationalPhoneNumberPrefix.length) : contact;
                return {phoneNumber: phoneNumber};
            }) : [{phoneNumber: ''}],
            payment_types: botConfiguration.order_allowed_payment_types?.length ? botConfiguration.order_allowed_payment_types.map(pt => {
                return {value: pt}
            }) : [],
        }
    });
    const {fields: botContacts, append: appendContact, remove: removeContact} = useFieldArray({
        name: 'contacts',
        control: form.control
    });
    const {fields: paymentTypes, append: appendPaymentType, replace: replacePaymentType} = useFieldArray({
        name: 'payment_types',
        control: form.control
    });
    const [loading, setLoading] = useState(false);

    const addContactHandler = () => appendContact({phoneNumber: ''});
    const removeContactHandler = (index) => removeContact(index);
    const handleClickPaymentType = (el, paymentType) => {
        if (el.target.checked) {
            appendPaymentType({value: paymentType})
        } else {
            replacePaymentType(paymentTypes.filter(field => field.value !== paymentType))
        }
    };
    const isCheckedPaymentType = (val) => {
        let checked = paymentTypes.find(item => item.value === val);
        return !!checked
    };

    const onSubmit = async (formData) => {
        try {
            const payload = {
                allow_send_daily_report: formData.allowSendDailyReport,
                daily_report_hour: formData.dailyReportHour.getHours(),
                allow_orders: formData.allowOrders,
                display_item_warehouse_states: formData.displayItemWarehouseStates,
                allow_orders_contractors_only: formData.allowOrdersContractorsOnly,
                order_use_bulk_price: formData.orderUseBulkPrice,
                order_min_before_days: Number(formData.minDaysBeforeOrdering),
                notify_sale_events: formData.notifySaleEvents,
                notify_payment_events: formData.notifyPaymentEvents,
                notify_payout_events: formData.notifyPayoutEvents,
                notify_cash_in_events: formData.notifyCashInEvents,
                notify_cash_out_events: formData.notifyCashOutEvents,
                notify_expense_events: formData.notifyExpenseEvents,
                notify_cash_transfer_events: formData.notifyCashTransferEvents,
                notify_contractor_state_events: formData.notifyContractorStateEvents,
                notify_telegram_order_bot_order_events: formData.notifyTelegramOrderBotOrderEvents,
                contacts: formData.contacts.filter(c => !!c.phoneNumber).map(c => nationalPhoneNumberPrefix + c.phoneNumber),
                order_allowed_payment_types: formData.payment_types.map(pt => pt.value)
            };

            setLoading(true);
            EventBus.dispatch(CONFIGURE_TELEGRAM_ORDER_BOT_CONFIG_REQUESTED, botConfiguration.bot_id);
            const {data: botConfig} = await TelegramOrderBotService.configureTelegramOrderBotConfigs(botConfiguration.bot_id, payload);
            setLoading(false);
            toast.success(t(lang, 'crm.telegram_bots.order.configure_bot_configs_modal.toast.success'));
            EventBus.dispatch(CONFIGURE_TELEGRAM_ORDER_BOT_CONFIG_SUCCEED, botConfig);
            history.push("/crm/telegram-bots/order");
        } catch (error) {
            setLoading(false);
            toast.error(t(lang, 'common.toast.error'));
            EventBus.dispatch(CONFIGURE_TELEGRAM_ORDER_BOT_CONFIG_FAILED, error);
        }
    };

    return (
        <Form>
            <Card>
                <Card.Body>
                    <Row>
                        <Col xs={12}>
                            <Row className="gy-1">
                                <Col xs={12} className="mb-3">
                                    <h5>{t(lang, 'crm.telegram_bots.order.configure_bot_configs_form.base_configs')}</h5>
                                </Col>
                                <Col xs={12} md={6} lg={4}>
                                    <Form.Group>
                                        <Form.Switch {...form.register('allowOrders')}
                                                     label={t(lang, 'crm.telegram_bots.order.configure_bot_configs_form.field.allow_orders')}
                                                     id="allowOrders"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6} lg={4}>
                                    <Form.Group>
                                        <Form.Switch {...form.register('orderUseBulkPrice')}
                                                     label={t(lang, 'crm.telegram_bots.order.configure_bot_configs_form.field.order_use_bulk_price')}
                                                     id="orderUseBulkPrice"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6} lg={4}>
                                    <Form.Group>
                                        <Form.Switch {...form.register('displayItemWarehouseStates')}
                                                     label={t(lang, 'crm.telegram_bots.order.configure_bot_configs_form.field.display_item_warehouse_states')}
                                                     id="displayItemWarehouseStates"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6} lg={4}>
                                    <Form.Group>
                                        <Form.Switch {...form.register('allowOrdersContractorsOnly')}
                                                     label={t(lang, 'crm.telegram_bots.order.configure_bot_configs_form.field.allow_orders_contractors_only')}
                                                     id="allowOrdersContractorsOnly"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6} lg={4}>
                                    <Form.Group>
                                        <Form.Switch {...form.register('allowSendDailyReport')}
                                                     label={t(lang, 'crm.telegram_bots.order.configure_bot_configs_form.field.allow_send_daily_report')}
                                                     id="allowSendDailyReport"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12}>
                                    <Row className="gy-2">
                                        <Col xs={12} md={6} xl={4} xxl={3}>
                                            <Controller control={form.control}
                                                        name="minDaysBeforeOrdering"
                                                        rules={{
                                                            valueAsNumber: true,
                                                            min: {
                                                                value: 0,
                                                                message: t(lang, 'crm.common.forms.validations.min', {number: 0})
                                                            },
                                                        }}
                                                        render={({field, fieldState: {error}}) => (
                                                            <Form.Group>
                                                                <Form.Label>{t(lang, 'crm.telegram_bots.order.configure_bot_configs_form.field.min_days_before_ordering')}</Form.Label>
                                                                <Form.Control type="number"
                                                                              defaultValue={field.value}
                                                                              isInvalid={!!error}
                                                                              onChange={field.onChange}
                                                                              className="input-spin-none"
                                                                />
                                                                {!!error && <Form.Control.Feedback
                                                                    type="invalid">{error?.message}</Form.Control.Feedback>}
                                                            </Form.Group>
                                                        )}
                                            />
                                        </Col>
                                        <Col xs={12} md={6} xl={4} xxl={3}>
                                            <Controller control={form.control}
                                                        name="dailyReportHour"
                                                        rules={{
                                                            required: t(lang, "items.common.validations.is_required")
                                                        }}
                                                        render={({field, fieldState: {error}}) => (
                                                            <Form.Group>
                                                                <Form.Label>{t(lang, 'crm.telegram_bots.order.configure_bot_configs_form.field.daily_report_hour')}</Form.Label>
                                                                <DatePicker showTimeSelect
                                                                            showTimeSelectOnly
                                                                            timeIntervals={60}
                                                                            timeCaption={t(lang, "edi.order.protocol_info.table.time")}
                                                                            dateFormat="H:mm"
                                                                            wrapperClassName={classNames({
                                                                                'is-invalid': !!error
                                                                            })}
                                                                            className={classNames('form-control', {
                                                                                'is-invalid': !!error,
                                                                            })}
                                                                            selected={field.value}
                                                                            onChange={field.onChange}
                                                                />
                                                                {!!error && <Form.Control.Feedback type="invalid">{error?.message}</Form.Control.Feedback>}
                                                            </Form.Group>
                                                        )}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Divider/>
                        <Col xs={12}>
                            <Row>
                                <Col xs={12} className="d-flex justify-content-center align-items-center">
                                    <Form.Group>
                                        <Form.Label
                                            className="text-dark me-2">{t(lang, "warehouse.operation.item.common.cash_box_state.type")}:</Form.Label>

                                        {ContractorPaymentTypes.map((val, valIndex) => {
                                            return (
                                                <Form.Check type="checkbox" className="form-check d-inline-block me-3"
                                                            key={valIndex}>
                                                    <Form.Check.Label className="form-label text-dark">
                                                        <Form.Check.Input id={val.value}
                                                                          type="checkbox"
                                                                          onChange={e => {
                                                                              handleClickPaymentType(e, val.value);
                                                                          }}
                                                                          name={val.label}
                                                                          checked={isCheckedPaymentType(val.value)}/>
                                                        {t(lang, val.label)}
                                                    </Form.Check.Label>
                                                </Form.Check>
                                            )
                                        })}
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Col>
                        <Divider/>
                        <Col xs={12}>
                            <Row>
                                <Col xs={12} className="mb-3">
                                    <h5>{t(lang, 'crm.telegram_bots.order.configure_bot_configs_form.notification_configs')}</h5>
                                </Col>
                                <Col xs={12} md={6} lg={4}>
                                    <Form.Group>
                                        <Form.Check {...form.register('notifySaleEvents')}
                                                    label={t(lang, 'crm.telegram_bots.order.configure_bot_configs_form.field.notify_sale_events')}
                                                    id="notifySaleEvents"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6} lg={4}>
                                    <Form.Group>
                                        <Form.Check {...form.register('notifyTelegramOrderBotOrderEvents')}
                                                    id="notifyTelegramOrderBotOrderEvents"
                                                    label={t(lang, 'crm.telegram_bots.order.configure_bot_configs_form.field.notify_telegram_order_bot_order_events')}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6} lg={4}>
                                    <Form.Group>
                                        <Form.Check {...form.register('notifyPaymentEvents')}
                                                    id="notifyPaymentEvents"
                                                    label={t(lang, 'crm.telegram_bots.order.configure_bot_configs_form.field.notify_payment_events')}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6} lg={4}>
                                    <Form.Group>
                                        <Form.Check {...form.register('notifyPayoutEvents')}
                                                    id="notifyPayoutEvents"
                                                    label={t(lang, 'crm.telegram_bots.order.configure_bot_configs_form.field.notify_payout_events')}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6} lg={4}>
                                    <Form.Group>
                                        <Form.Check {...form.register('notifyCashInEvents')}
                                                    id="notifyCashInEvents"
                                                    label={t(lang, 'crm.telegram_bots.order.configure_bot_configs_form.field.notify_cash_in_events')}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6} lg={4}>
                                    <Form.Group>
                                        <Form.Check {...form.register('notifyCashOutEvents')}
                                                    id="notifyCashOutEvents"
                                                    label={t(lang, 'crm.telegram_bots.order.configure_bot_configs_form.field.notify_cash_out_events')}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6} lg={4}>
                                    <Form.Group>
                                        <Form.Check {...form.register('notifyExpenseEvents')}
                                                    id="notifyExpenseEvents"
                                                    label={t(lang, 'crm.telegram_bots.order.configure_bot_configs_form.field.notify_expense_events')}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6} lg={4}>
                                    <Form.Group>
                                        <Form.Check {...form.register('notifyCashTransferEvents')}
                                                    id="notifyCashTransferEvents"
                                                    label={t(lang, 'crm.telegram_bots.order.configure_bot_configs_form.field.notify_cash_transfer_events')}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6} lg={4}>
                                    <Form.Group>
                                        <Form.Check {...form.register('notifyContractorStateEvents')}
                                                    id="notifyContractorStateEvents"
                                                    label={t(lang, 'crm.telegram_bots.order.configure_bot_configs_form.field.notify_contractor_state_events')}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12}>
                            <Form.Group>
                                <Form.Label>{t(lang, 'crm.telegram_bots.order.configure_bot_configs_form.field.telegram_bot_admin_contacts')}</Form.Label>
                                <Row>
                                    <Col xs={12} md={6}>
                                        {botContacts.slice(0, Math.ceil(botContacts.length / 2)).map((contact, index) => (
                                            <InputGroup key={contact.id} className={'mb-2'}>
                                                <InputGroup.Text>998</InputGroup.Text>
                                                <Controller
                                                    control={form.control}
                                                    name={`contacts.${index}.phoneNumber`}
                                                    rules={{
                                                        validate: {
                                                            format: value =>
                                                                !value ||
                                                                /^(\d{9})$/.test(value.replace(/ /g, '')) ||
                                                                t(lang, 'crm.telegram_bots.order.configure_bot_configs_form.field.telegram_bot_admin_contact.validation'),
                                                        },
                                                    }}
                                                    render={({field}) => (
                                                        <Cleave
                                                            value={field.value}
                                                            placeholder={"-- --- -- --"}
                                                            onChange={(event) => field.onChange(event.target.rawValue)}
                                                            className={classNames('form-control', {
                                                                'is-invalid': form.formState.errors?.contacts?.[index]?.phoneNumber,
                                                            })}
                                                            options={{
                                                                numericOnly: true,
                                                                blocks: [2, 3, 2, 2],
                                                                delimiters: [' ', ' ', ' ', ' '],
                                                            }}
                                                        />
                                                    )}
                                                />
                                                <IconButton
                                                    icon={'trash'}
                                                    variant={'falcon-danger'}
                                                    onClick={() => removeContactHandler(index)}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {form.formState.errors?.contacts?.[index]?.phoneNumber?.message}
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        ))}
                                        <div className="text-center">
                                            <Button variant={'link'} onClick={addContactHandler}>
                                                <FontAwesomeIcon className={'me-1'} icon={'plus'}/>
                                                <span>{t(lang, 'crm.common.add.button')}</span>
                                            </Button>
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        {!!botContacts.slice(Math.ceil(botContacts.length / 2)).length &&
                                            <>
                                                {botContacts.slice(Math.ceil(botContacts.length / 2)).map((contact, index) => (
                                                    <InputGroup key={contact.id} className={'mb-2'}>
                                                        <InputGroup.Text>998</InputGroup.Text>
                                                        <Controller
                                                            control={form.control}
                                                            name={`contacts.${Math.ceil(botContacts.length / 2) + index}.phoneNumber`}
                                                            rules={{
                                                                validate: {
                                                                    format: (value) =>
                                                                        !value ||
                                                                        /^(\d{9})$/.test(value.replace(/ /g, '')) ||
                                                                        t(
                                                                            lang,
                                                                            'crm.telegram_bots.order.configure_bot_configs_form.field.telegram_bot_admin_contact.validation'
                                                                        ),
                                                                },
                                                            }}
                                                            render={({field}) => (
                                                                <Cleave
                                                                    value={field.value}
                                                                    placeholder={"-- --- -- --"}
                                                                    onChange={(event) =>
                                                                        field.onChange(event.target.rawValue)
                                                                    }
                                                                    className={classNames('form-control', {
                                                                        'is-invalid':
                                                                        form.formState.errors?.contacts?.[
                                                                        Math.ceil(botContacts.length / 2) + index
                                                                            ]?.phoneNumber,
                                                                    })}
                                                                    options={{
                                                                        numericOnly: true,
                                                                        blocks: [2, 3, 2, 2],
                                                                        delimiters: [' ', ' ', ' ', ' '],
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                        <IconButton
                                                            icon={'trash'}
                                                            variant={'falcon-danger'}
                                                            onClick={() =>
                                                                removeContactHandler(
                                                                    Math.ceil(botContacts.length / 2) + index
                                                                )
                                                            }
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {
                                                                form.formState.errors?.contacts?.[
                                                                Math.ceil(botContacts.length / 2) + index
                                                                    ]?.phoneNumber?.message
                                                            }
                                                        </Form.Control.Feedback>
                                                    </InputGroup>
                                                ))}
                                                <div className="text-center">
                                                    <Button variant={'link'} onClick={addContactHandler}>
                                                        <FontAwesomeIcon className={'me-1'} icon={'plus'}/>
                                                        <span>{t(lang, 'crm.common.add.button')}</span>
                                                    </Button>
                                                </div>
                                            </>
                                        }
                                    </Col>
                                </Row>
                            </Form.Group>
                        </Col>
                    </Row>
                </Card.Body>
                <Card.Footer className="text-end">
                    <Button variant="primary"
                            disabled={loading}
                            onClick={form.handleSubmit(onSubmit)}
                    >
                        {loading && <Spinner className="align-middle me-2" animation="border" role="switch" size="sm"/>}
                        {t(lang, "edi.common.button.save")}
                    </Button>
                </Card.Footer>
            </Card>
        </Form>
    );
};

export default TelegramOrderBotConfigsForm;