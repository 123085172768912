import React, {Fragment, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    changeFilterCashIn, changeFilterOperation, getAllOperationAsync,
    selectCashBoxOperation,
    selectCashBoxOperationCount, selectCashBoxOperationFilter, selectedCurrencyForCashBox
} from "../../../../app/store/reducers/cashbox/CashboxReducer";
import AdvanceTableWrapper from "../../../common/advance-table/AdvanceTableWrapper";
import {Badge, Card} from "react-bootstrap";
import AdvanceTable from "../../../common/advance-table/AdvanceTable";
import AdvanceTableCustomPagination from "../../../common/advance-table/AdvanceTableCustomPagination";
import {selectLang, selectNumberFormat, Translate} from "../../../../app/store/reducers/main/mainReducer";
import CashBoxOperationDataTableHeader from "./CashBoxOperationDataTableHeader";
import {Link} from "react-router-dom";
import {
    CashBoxOperationTypeCashIn,
    CashBoxOperationTypeCashOut, CashBoxOperationTypeExpense, CashBoxOperationTypePayment,
    CashBoxOperationTypePayout,
    CashBoxOperationTypes, CashBoxOperationTypeTransferIn, CashBoxOperationTypeTransferOut
} from "../../../../enum/cash_box/cashBoxOperationType";
import numeral from "numeral";
import {totalAmountInCashBox, totalGroupAmounts, totalGroupCashAmount} from "../../../../enum/cash_box/cashBoxWrapper";
import {selectCurrency} from "../../../../app/store/reducers/currency/currencyReducer";
import {ContractorPaymentTypes, PaymentTypeCashCode} from "../../../../enum/ContractorWrapper";
import SoftBadge from "../../../common/SoftBadge";
import classNames from "classnames";
import ViewOperation from "../../../../pages/main/cashbox/operation/ViewOperation";
const CashBoxOperationDataTable = () => {
    const dispatch = useDispatch()

    const t = Translate;
    const lang = useSelector(selectLang);
    const operation = useSelector(selectCashBoxOperation)
    const count = useSelector(selectCashBoxOperationCount)
    const filters = useSelector(selectCashBoxOperationFilter)
    const numberFormat = useSelector(selectNumberFormat);
    const [show, setShow] = useState(false)
    const [id, setId] = useState(null)

    const columns = [
        {
            Header: "#",
            id: "index",
            cellProps: {
                width: '40px',
            },
            filterable: false,
            Cell: ({row}) => {
                return `${((filters.page - 1) * filters.limit) + row.index + 1}`;
            }
        },
        {
            accessor: 'name',
            Header: t(lang, "cashbox.bind.account.title"),
            cellProps: {width: 250, className: 'pe-4'},
            Cell: ({row: {original}}) => {
                return (
                    // <Link to={`./operation/view/${original.id}`}>
                        <strong className={'text-primary cursor-pointer'} onClick={() => {
                            setId(original.id)
                            setShow(true)
                        }}>
                            {original.cash_box.name}
                        </strong>
                    // </Link>
                );
            }
        },
        {
            accessor: 'account',
            Header: t(lang, "crm.contractor.view.tab.act_report.account"),
            cellProps: {className: 'pe-4'},
            Cell: ({row: {original}}) => {
                return (
                    <strong>{original.account.name}</strong>
                );
            }
        },
        {
            accessor: 'created_at',
            Header: t(lang, "crm.data.table.date"),
            cellProps: {width: 300, className: 'pe-4'},
            Cell: ({row: {original}}) => {
                return (
                    <span>{original.created_at}</span>
                );
            }
        },
        {
            accessor: 'type',
            Header: t(lang, "edi.contractor_card.general.type"),
            cellProps: {className: 'text-center fs-0'},
            headerProps: {className: 'text-center'},
            Cell: ({row: {original}}) => {
                const type = original.type === CashBoxOperationTypePayment || original.type === CashBoxOperationTypeCashIn || original.type === CashBoxOperationTypeTransferIn
                return (
                    <SoftBadge bg={type ? "success" : "danger"}>
                        <strong>{t(lang, CashBoxOperationTypes.find(i => i.value === original.type)?.label)}</strong>
                    </SoftBadge>
                );
            }
        },
        {
            accessor: 'cash',
            Header: t(lang, "crm.payment_type.cash"),
            headerProps: {className: "text-end pe-4"},
            cellProps: {className: 'pe-2 text-end'},
            Cell: ({row: {original}}) => {
                const cash = totalGroupCashAmount(original.amounts.filter(i => i.money_type === PaymentTypeCashCode)) || []
                const type = original.type === CashBoxOperationTypePayment || original.type === CashBoxOperationTypeCashIn || original.type === CashBoxOperationTypeTransferIn
                return (
                    <Fragment>
                        {cash.map((i, index) => <Badge key={index} bg={type ? "success" : "danger"} className='me-2 py-2'
                                                       style={{fontSize: 12}}>
                            <span>{numeral.formats[numberFormat].format(i?.total)}</span>
                            <strong className={'ms-2 fw-bold'}>{i?.name}</strong>
                        </Badge>)}
                    </Fragment>
                );
            }
        },
        {
            accessor: 'plastic_card',
            Header: t(lang, "Карта"),
            headerProps: {className: "text-start pe-4"},
            cellProps: {width: 250, className: 'pe-2 text-start'},
            Cell: ({row: {original}}) => {
                let plasticCard = totalGroupAmounts(original.amounts.filter(i => i.money_type !== PaymentTypeCashCode && (i.money_type !== null && i.money_type))) || [];
                const type =  original.type === CashBoxOperationTypePayment || original.type === CashBoxOperationTypeCashIn || original.type === CashBoxOperationTypeTransferIn
                return (
                    <Fragment>
                        {plasticCard.map((item, index) => <Badge key={index} bg={!type ? 'dark' : 'info'} className='me-2'
                                                                 style={{fontSize: 12}}>
                                <span>{numeral.formats[numberFormat].format(item?.total)}</span>
                                <strong className={'ms-2 fw-bold'}>{item?.name}</strong>
                                <i className={classNames('d-block fs--2 fw-bold text-center m-0 mt-1', {
                                    'text-dark': type,
                                    'text-danger': !type
                                })}>{t(lang, ContractorPaymentTypes.find(b => b.value === item?.money_type)?.label)}</i>
                            </Badge>
                        )}
                    </Fragment>
                );
            }
        },
    ]

    const onPageChange = (page) => dispatch(changeFilterOperation({...filters, page: page}))
    const onLimitChange = (limit) => dispatch(changeFilterOperation({...filters, limit: limit, page: 1}))

    return (
       <Fragment>
           <AdvanceTableWrapper
               data={operation}
               columns={columns}
               perPage={1000}
               pagination
           >
               <Card>
                   <Card.Header className={'pb-0'}>
                       <CashBoxOperationDataTableHeader
                           filters={filters}
                           pageTitle={"edi.return_order.protocol_info.title"}
                           requestAsync={changeFilterOperation}
                           currency={false}
                       />
                   </Card.Header>
                   <Card.Body>
                       <AdvanceTable
                           table
                           headerClassName="bg-200 text-900 text-nowrap align-middle"
                           rowClassName="align-middle white-space-nowrap"
                           tableProps={{
                               size: 'sm',
                               striped: true,
                               className: 'fs--1 mb-0 overflow-hidden'
                           }}/>
                   </Card.Body>
                   <Card.Footer>
                       <AdvanceTableCustomPagination
                           count={count}
                           limit={filters.limit}
                           page={filters.page}
                           onPageChange={onPageChange}
                           onLimitChange={onLimitChange}
                       />
                   </Card.Footer>
               </Card>
           </AdvanceTableWrapper>

        <ViewOperation show={show} setShow={setShow} id={id}/>
       </Fragment>
    );
};

export default CashBoxOperationDataTable;
