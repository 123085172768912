export const REGISTER_SHIPMENT_REQUESTED = 'REGISTER_SHIPMENT_REQUESTED';
export const REGISTER_SHIPMENT_SUCCEED = 'REGISTER_SHIPMENT_SUCCEED';
export const REGISTER_SHIPMENT_FAILED = 'REGISTER_SHIPMENT_FAILED';
export const EDIT_SHIPMENT_REQUESTED = 'EDIT_SHIPMENT_REQUESTED';
export const EDIT_SHIPMENT_SUCCEED = 'EDIT_SHIPMENT_SUCCEED';
export const EDIT_SHIPMENT_FAILED = 'EDIT_SHIPMENT_FAILED';
export const CUSTOMER_EDITED_SHIPMENT = 'CUSTOMER_EDITED_SHIPMENT';
export const DELETE_SHIPMENT_REQUESTED = 'DELETE_SHIPMENT_REQUESTED';
export const DELETE_SHIPMENT_SUCCESS = 'DELETE_SHIPMENT_SUCCESS';
export const DELETE_SHIPMENT_FAILED = 'DELETE_SHIPMENT_FAILED';
export const EXECUTOR_APPROVE_SHIPMENT_REQUESTED = 'EXECUTOR_APPROVE_SHIPMENT_REQUESTED';
export const EXECUTOR_APPROVE_SHIPMENT_SUCCESS = 'EXECUTOR_APPROVE_SHIPMENT_SUCCESS';
export const EXECUTOR_APPROVE_SHIPMENT_FAILED = 'EXECUTOR_APPROVE_SHIPMENT_FAILED';
export const CUSTOMER_APPROVE_SHIPMENT_REQUESTED = "CUSTOMER_APPROVE_SHIPMENT_REQUESTED";
export const CUSTOMER_APPROVE_SHIPMENT_SUCCESS = "CUSTOMER_APPROVE_SHIPMENT_SUCCESS";
export const CUSTOMER_APPROVE_SHIPMENT_FAILED = "CUSTOMER_APPROVE_SHIPMENT_FAILED";
export const CUSTOMER_DECLINE_SHIPMENT_REQUESTED = "CUSTOMER_DECLINE_SHIPMENT_REQUESTED";
export const CUSTOMER_DECLINE_SHIPMENT_SUCCESS = "CUSTOMER_DECLINE_SHIPMENT_SUCCESS";
export const CUSTOMER_DECLINE_SHIPMENT_FAILED = "CUSTOMER_DECLINE_SHIPMENT_FAILED";
