import {createSlice} from "@reduxjs/toolkit";
import dayjs from "dayjs";

export const reportSlice = createSlice({
    name: "report",
    initialState: {
        filter: {
            date_start: dayjs(new Date()).format("YYYY-MM-DD"),
            date_end: dayjs(new Date()).format("YYYY-MM-DD"),
            contractor_id: null,
            item_id: null,
            warehouse_id: null
        },
    },
    reducers: {
        setFilter: (state, action) => {
            state.filter = action.payload
        },
    },
});
export const selectFilters = state => state.report.filter;
export const {setFilter} = reportSlice.actions;
export default reportSlice.reducer;

