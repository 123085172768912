import {useSelector} from "react-redux";
import {selectLang, selectNumberFormat, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {Card, Col, Row, Tab, Tabs} from "react-bootstrap";
import SoftBadge from "../../../common/SoftBadge";
import {Link} from "react-router-dom";
import IconButton from "../../../common/IconButton";
import numeral from "numeral";
import {
    isApprovedCheckedWarehouseOperation, OperationTotalSum,
} from "../enum/warehouseOperationWrapper";
import {
    refundApproveAsync,
    refundPayoutRegisterAsync, refundPayoutUpdateAsync
} from "../../../../app/store/reducers/warehouse-operation/refundReducer";
import {toast} from "react-toastify";
import React, {useEffect, useState} from "react";
import WarehouseOperationWithoutPayment from "../components/WarehouseOperationWithoutPayment";
import {selectCurrency} from "../../../../app/store/reducers/currency/currencyReducer";
import {selectItems} from "../../../../app/store/reducers/item/itemReducer";
import WarehouseOperationCashBoxCard from "../components/WarehouseOperationCashBoxCard";
import WarehouseOperationDebtStateCard from "../components/WarehouseOperationDebtStateCard";
import {selectCardContractor} from "../../../../app/store/reducers/contractor/contractorReducer";
import WarehouseOperationDatatableTab from "../components/WarehouseOperationDatatableTab";
import WarehouseOperationFooterCurrencyTotalSum from "../components/WarehouseOperationFooterCurrencyTotalSum";
import WOWithoutDiscountPayment from "../components/WOWithoutDiscountPayment";
import UploadRefundToExcel from "./excel/UploadRefundToExcel";
import PrintRefundDropdown from "./components/PrintRefundDropdown";
import DownloadRefundDropdown from "./components/DownloadRefundDropdown";


const Refund = ({refund}) => {
    const [activeTab, setActiveTab] = useState("all")
    const numberFormat = useSelector(selectNumberFormat);
    const contractor = useSelector(selectCardContractor)
    const currencies = useSelector(selectCurrency)
    const items = useSelector(selectItems)
    const lang = useSelector(selectLang)
    const t = Translate



    useEffect(() => {
        if (refund?.items?.filter(f => !f?.is_deleted).length > 0) {
            setActiveTab("all")
        } else {
            setActiveTab("deleted")
        }
    },[currencies])



    const onSubmitPay = async (pay) => {
        if (refund?.payout) {
            await refundPayoutUpdateAsync({refundId: pay?.id, payout: pay?.payment})
                .then(() => {
                    toast.success(t(lang, "warehouse.operation.item.common.toast.success"))
                })
                .catch(() => {
                    toast.error(t(lang, "warehouse.operation.item.common.toast.error"))
                })
        }

        if (!refund?.payout && refund?.is_approved) {
            await refundPayoutRegisterAsync({refundId: pay?.id, payout: pay?.payment})
                .then(() => {
                    toast.success(t(lang, "warehouse.operation.item.common.toast.success"))
                })
                .catch(() => {
                    toast.error(t(lang, "warehouse.operation.item.common.toast.error"))
                })

        }

        if (!refund?.is_approved) {
            await refundApproveAsync({refundId: pay?.id, payout: pay?.payment || null})
                .then(() => {
                    toast.success(t(lang, "warehouse.operation.item.common.toast.success"))
                })
                .catch(() => {
                    toast.error(t(lang, "warehouse.operation.item.common.toast.error"))
                })
        }
    }

    return(
        <>
            <Card>
                <Card.Header>
                    <Row>
                        <Col xs={6}><h3 className="text-600">{t(lang, "warehouse.operation.item.common.time_refund")} {refund?.date}</h3></Col>
                        <Col xs={6} className="text-end">
                            <span className="me-2">{t(lang, "warehouse.operation.item.common.sale_status")}: </span>
                            <SoftBadge bg={refund?.is_approved ? 'success' : 'warning'}>
                                {t(lang, isApprovedCheckedWarehouseOperation(refund?.is_approved))}
                            </SoftBadge>
                        </Col>
                        <Col xs={12}>
                            {currencies?.length > 0 && refund?.items?.filter(x => !x.is_deleted).length > 0 &&
                                // <WarehouseOperationPayment onSubmitPay={onSubmitPay} data={refund}/>
                                <WOWithoutDiscountPayment
                                    data={refund}
                                    onSubmitPay={onSubmitPay}
                                    isEditing={refund?.payout}
                                />
                            }
                            {!refund?.is_approved && refund?.items?.filter(x => !x.is_deleted).length > 0 &&
                                <WarehouseOperationWithoutPayment onClick={onSubmitPay} id={refund?.id} />
                            }
                            <Link to={`../update/${refund?.id}`}>
                                <IconButton
                                    variant="falcon-warning"
                                    icon="edit"
                                    title={t(lang,"warehouse.operation.item.common.edit")}
                                    className="ms-3"
                                    size="sm"
                                >{t(lang,"warehouse.operation.item.common.edit")}</IconButton>
                            </Link>
                            <Link to={`../update-excel/${refund?.id}`}>
                                <IconButton
                                    variant="falcon-success"
                                    icon="edit"
                                    title={t(lang,"warehouse.operation.item.common.edit")}
                                    className="mx-2"
                                    size="sm"
                                >{t(lang,"Редактировать с Excel")}</IconButton>
                            </Link>
                            <UploadRefundToExcel className="me-2 d-inline-block" size="sm" id={refund?.id} number={refund?.number} />
                            <Link to={`../register`}>
                                <IconButton
                                    variant="falcon-primary"
                                    icon="plus"
                                    title={t(lang,"Новый возврат")}
                                    className="ms-2"
                                    size="sm"
                                >{t(lang,"Новый возврат")}</IconButton>
                            </Link>

                            <PrintRefundDropdown className={'d-inline-block mx-1'} refundId={refund?.id}/>
                            <DownloadRefundDropdown className={'d-inline-block mx-1'} refundId={refund?.id} refundNumber={refund?.number}/>
                        </Col>
                    </Row>
                </Card.Header>
            </Card>

            <Row>
                <Col xs={12} className="my-3">
                    <Card>
                        <Card.Header>
                            <h4 className="text-info mb-2">{t(lang, "warehouse.operation.item.common.refund")}</h4>
                            <div className="d-flex flex-column">
                                <div className="d-flex align-items-center fw-bold">
                                    <h5 className="me-2 fw-bold">{t(lang, "warehouse.operation.item.common.client_name")}:</h5> <span>{refund?.contractor?.name}</span>
                                </div>
                                <div className={"d-flex align-items-start flex-wrap fw-bold"}>
                                    <h5 className="d-flex justify-content-end text-nowrap fw-bold">{t(lang, "warehouse.operation.item.common.real_credit")}:</h5>
                                    <div className="fs-1 d-flex flex-wrap">
                                        {contractor?.debts?.length > 0 ? contractor?.debts.map((item, index) => {
                                            if (item.amount){
                                                return (
                                                    <div key={index} className="d-flex text-nowrap justify-content-end mx-2">
                                                        {numeral.formats[numberFormat].format(item.amount)} <span className="text-info ms-1">{item.currency.name}</span>
                                                    </div>
                                                )
                                            }
                                        }) : '0 сум'}
                                    </div>
                                </div>
                            </div>
                        </Card.Header>
                    </Card>
                </Col>
            </Row>

            {refund?.payout &&
                <>
                    <Row className="mb-3">
                        <Col xs={12} lg={6} className="m-1 m-lg-0">
                            <WarehouseOperationCashBoxCard cashBox={refund?.payout?.cash_box_states} />
                        </Col>
                        <Col xs={12} lg={6} className="m-1 m-lg-0">
                            <WarehouseOperationDebtStateCard debtState={refund?.payout?.debt_states} />
                        </Col>
                    </Row>
                    {refund?.payout?.notes &&
                        <Row>
                            <Col xs={12}>
                                <Card className="mb-3 p-3">
                                    <Card.Title className="text-center">
                                        {t(lang, "warehouse.operation.item.common.info")}
                                    </Card.Title>
                                    <Card.Text>
                                        {refund?.payout?.notes}
                                    </Card.Text>
                                </Card>
                            </Col>
                        </Row>
                    }
                </>
            }

            <Row>
                <Col xs={12}>
                    <Card>
                        <Card.Body>
                            {refund?.note &&
                                <Col xs={12}>
                                    <p className="fw-bold m-0">Описание: <span className="fw-medium">{refund?.note}</span> </p>
                                </Col>
                            }
                            <Tabs activeKey={activeTab} id="controlled-tab-example" className="d-flex justify-content-center fs-1" onSelect={(tab) => {
                                setActiveTab(tab)
                            }}>
                                {refund?.items?.filter(f => !f?.is_deleted).length > 0 &&
                                    <Tab eventKey="all" tabClassName="fs-1" title={t(lang, 'Списки')}>
                                        {activeTab === 'all' &&
                                            <WarehouseOperationDatatableTab
                                                dataItems={refund?.items?.filter(f => !f.is_deleted)}
                                            />
                                        }
                                    </Tab>
                                }
                                {refund?.items?.filter(f => f.is_deleted).length > 0 &&
                                    <Tab eventKey="deleted" tabClassName="fs-1" title={t(lang, 'Удалённые из списка')}>
                                        {activeTab === 'deleted' &&
                                            <WarehouseOperationDatatableTab
                                                dataItems={refund?.items?.filter(f => f.is_deleted)}
                                            />
                                        }
                                    </Tab>
                                }
                            </Tabs>
                            <Row className="g-0 d-flex flex-column align-items-end">
                                {activeTab === "all" &&
                                    <WarehouseOperationFooterCurrencyTotalSum
                                        netPrice={refund?.net_price}
                                        pay={refund?.payout}
                                    />
                                }
                                {activeTab === "deleted" &&
                                    <WarehouseOperationFooterCurrencyTotalSum
                                        netPriceType={"deleted"}
                                        netPrice={OperationTotalSum(refund?.items?.filter(f => f?.is_deleted))}
                                        pay={refund?.payout}
                                    />
                                }
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default Refund;