import React from 'react';
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import ItemDataTableFilter from "./ItemDataTableFilter";
import {Col, OverlayTrigger, Popover, Row} from "react-bootstrap";
import Divider from "../../../common/Divider";
import SoftBadge from "../../../common/SoftBadge";
import ReloadItems from "../components/ReloadItems";
import ValidateItemCatalogs from "../components/ValidateItemCatalogs";
import UploadItemsToExcel from "../components/UploadItemsToExcel";
import UploadItemsToScales from "../components/UploadItemsToScales";
import {Link} from "react-router-dom";
import IconButton from "../../../common/IconButton";
import {faCartPlus, faFileExcel} from "@fortawesome/free-solid-svg-icons";
import ItemsTotalAmountsInfo from "../components/ItemsTotalAmountsInfo";
import EditBulkItemsModal from "../forms/EditBulkItemsModal";
import BulkItemRevision from "../components/BulkItemRevision";
import SelectBulkCatalogButton from "../components/SelectBulkCatalogButton";
import BulkDeleteItems from "../components/BulkDeleteItems";
import BindItemsToOrganizationsModal from "../components/BindItemsToOrganizationsModal";
import UploadBulkItemsToExcel from "../components/UploadBulkItemsToExcel";
import ContractorPriceModal from "../components/ContractorPriceModal";
import BulkEditItemLegalType from "../components/BulkEditItemLegalType";

const ItemDataTableHeader = ({
                                 table,
                                 filter,
                                 onFilterChange,
                                 onDebouncedFilterChange,
                                 sorting,
                                 onSortingChange,
                                 columnVisibility,
                             }) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const selectedItems = table.getSelectedRowModel().rows.map(({original}) => original);

    return (
        <Row className="gy-3">
            <Col xs={12}>
                <h5 className="mb-2">{t(lang, "items.common.datatable.filter")}</h5>
                <ItemDataTableFilter filter={filter}
                                     onFilterChange={onFilterChange}
                                     onDebouncedFilterChange={onDebouncedFilterChange}
                                     sorting={sorting}
                                     onSortingChange={onSortingChange}
                />
            </Col>
            <Divider/>
            <Col xs={12}>
                <div className="d-sm-flex">
                    <h5>{t(lang, 'items.table.title')}</h5>
                    <div className={'d-flex flex-column flex-md-row'}>
                        <div className="border border-200 text-center ms-sm-2 px-sm-2">
                            <OverlayTrigger trigger={['hover', 'focus']}
                                            placement={'bottom'}
                                            overlay={
                                                <Popover id="popover-basic" className='mt-0'>
                                                    <Popover.Header>
                                                        <SoftBadge
                                                            bg={'primary'}>{t(lang, 'items.common.catalog.unknown')}</SoftBadge>
                                                    </Popover.Header>
                                                </Popover>
                                            }
                            >
                                        <span className="dot bg-primary d-inline-block"
                                              style={{borderRadius: '0.25rem'}}></span>
                            </OverlayTrigger>
                            <OverlayTrigger trigger={['hover', 'focus']}
                                            placement={'bottom'}
                                            overlay={
                                                <Popover id="popover-basic" className='mt-0'>
                                                    <Popover.Header>
                                                        <SoftBadge
                                                            bg={'success'}>{t(lang, 'items.common.catalog.is_active')}</SoftBadge>
                                                    </Popover.Header>
                                                </Popover>
                                            }
                            >
                                        <span className="dot bg-success d-inline-block"
                                              style={{borderRadius: '0.25rem'}}></span>
                            </OverlayTrigger>
                            <OverlayTrigger trigger={['hover', 'focus']}
                                            placement={'bottom'}
                                            overlay={
                                                <Popover id="popover-basic" className='mt-0'>
                                                    <Popover.Header className='mx-auto'>
                                                        <SoftBadge
                                                            bg={'warning'}>{t(lang, 'items.common.catalog.moved_or_package_edited')}</SoftBadge>
                                                    </Popover.Header>
                                                </Popover>
                                            }
                            >
                                        <span className="dot bg-warning d-inline-block"
                                              style={{borderRadius: '0.25rem'}}></span>
                            </OverlayTrigger>
                            <OverlayTrigger trigger={['hover', 'focus']}
                                            placement={'bottom'}
                                            overlay={
                                                <Popover id="popover-basic" className='mt-0'>
                                                    <Popover.Header>
                                                        <SoftBadge
                                                            bg={'danger'}>{t(lang, 'items.common.catalog.deleted')}</SoftBadge>
                                                    </Popover.Header>
                                                </Popover>
                                            }
                            >
                                        <span className="dot bg-danger d-inline-block"
                                              style={{borderRadius: '0.25rem'}}></span>
                            </OverlayTrigger>
                            <OverlayTrigger trigger={['hover', 'focus']}
                                            placement={'bottom'}
                                            overlay={
                                                <Popover id="popover-basic" className='mt-0'>
                                                    <Popover.Header>
                                                        <SoftBadge
                                                            bg={'secondary'}>{t(lang, 'items.common.catalog.server_error')}</SoftBadge>
                                                    </Popover.Header>
                                                </Popover>
                                            }
                            >
                                        <span className="dot bg-secondary d-inline-block"
                                              style={{borderRadius: '0.25rem'}}></span>
                            </OverlayTrigger>
                            <span className="fs--1 fw-bold">{t(lang, 'items.common.catalog')}</span>
                        </div>
                        <div
                            className="d-flex flex-column flex-sm-row justify-content-sm-around ms-md-3 py-md-2">
                            <SoftBadge bg='info'
                                       className={'mt-2 mt-md-0'}>{t(lang, 'items.common.category')}</SoftBadge>
                            <SoftBadge bg='secondary'
                                       className={'mt-2 mt-md-0 mx-md-1'}>{t(lang, 'items.common.barcode')}</SoftBadge>
                            <SoftBadge bg='warning'
                                       className={'mt-2 mt-md-0'}>{t(lang, 'items.common.code')}</SoftBadge>
                            <SoftBadge bg='dark'
                                       className={'mt-2 mt-md-0 ms-md-1'}>{t(lang, 'items.common.sku')}</SoftBadge>
                            <SoftBadge bg='primary'
                                       className={'mt-2 mt-md-0 ms-md-1'}>{t(lang, 'items.common.package_measurement')}</SoftBadge>
                        </div>
                    </div>
                </div>
            </Col>
            <Col xs={12}>
                {selectedItems.length === 0 &&
                    <div className="d-md-flex flex-wrap">
                        <div className="d-grid w-md-50 d-lg-inline-block w-lg-auto p-1 p-lg-0 m-lg-1">
                            <ReloadItems/>
                        </div>
                        <div className="d-grid w-md-50 d-lg-inline-block w-lg-auto p-1 p-lg-0 m-lg-1">
                            <ValidateItemCatalogs/>
                        </div>
                        <UploadItemsToExcel/>
                        <div className="d-grid w-md-50 d-lg-inline-block w-lg-auto p-1 p-lg-0 m-lg-1">
                            <UploadItemsToScales/>
                        </div>
                        <Link to={'/warehouse/items/registry-add'}
                              className="d-grid w-md-50 d-lg-inline-block w-lg-auto p-1 p-lg-0 m-lg-1">
                            <IconButton icon={faFileExcel}
                                        variant={'falcon-success'}
                                        size={'sm'}
                            >
                                {t(lang, 'items.common.import_from_excel')}
                            </IconButton>
                        </Link>
                        <Link to={'/warehouse/items/bulk-add'}
                              className="d-grid w-md-50 d-lg-inline-block w-lg-auto p-1 p-lg-0 m-lg-1">
                            <IconButton icon={faCartPlus}
                                        variant={'falcon-primary'}
                                        size={'sm'}
                            >
                                {t(lang, 'items.common.bulk_add')}
                            </IconButton>
                        </Link>
                        <Link to={"./items/add"}
                              className="d-grid w-md-50 d-lg-inline-block w-lg-auto p-1 p-lg-0 m-lg-1">
                            <IconButton size="sm"
                                        variant="falcon-primary"
                                        icon="fa-plus"
                            >
                                {t(lang, "items.common.add_new")}
                            </IconButton>
                        </Link>
                        <div className="d-grid w-md-50 d-lg-inline-block w-lg-auto p-1 p-lg-0 m-lg-1">
                            <ItemsTotalAmountsInfo table={table}
                                                   columnVisibility={columnVisibility}
                            />
                        </div>
                    </div>
                }
                {selectedItems.length > 0 && <div className="d-flex flex-wrap">
                    <EditBulkItemsModal items={selectedItems}/>
                    <BulkItemRevision items={selectedItems}/>
                    <SelectBulkCatalogButton items={selectedItems}/>
                    <BulkDeleteItems items={selectedItems}/>
                    <BindItemsToOrganizationsModal items={selectedItems}/>
                    <UploadBulkItemsToExcel selectedItems={selectedItems}/>
                    <ContractorPriceModal items={selectedItems}/>
                    <BulkEditItemLegalType items={selectedItems}/>
                    <h6 className="m-2">{t(lang, 'common.table.selected_count', {length: selectedItems.length})}</h6>
                </div>}
            </Col>
        </Row>
    );
};

export default ItemDataTableHeader;