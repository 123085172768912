import React, {useState} from 'react';
import Avatar, {AvatarGroup} from "../../common/Avatar";
import {Button, Dropdown, Nav, OverlayTrigger, Tooltip} from "react-bootstrap";
import classNames from "classnames";
import Flex from "../../common/Flex";
import MemberDropdownSelect from "../form/taskCard/MemberDropdownSelect";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";
import MemberModal from "../modal/member/MemberModal";
import DeleteMemberModal from "../modal/member/DeleteMemberModal";
import {Controller} from "react-hook-form";
import PropTypes from "prop-types";

const GroupMember = ({
                         avatarSize = 'l',
                         value,
                         members = [],
                         showMember = 4,
                         className,
                         name,
                         edit
                     }) => {

    const [defaultValue, setDefaultValue] = useState(null);
    const [memberId, setMemberId] = useState(null);
    const [show, setShow] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const lang = useSelector(selectLang);
    const t = Translate;

    const showModal = (user) => {
        setShow(true)
        setDefaultValue(user)
    }

    const onDeleteModal = (id) => {
        setShowDeleteModal(true)
        setMemberId(id)
    }

    return (
        <div className={className}>
            <AvatarGroup>
                {members.slice(0, showMember).map((user, index) => (
                    <Dropdown key={user.id}>
                        <Dropdown.Toggle as={Nav.Link} className={classNames('dropdown-caret-none p-0', {
                            'ms-n1': index > 0
                        })}>
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                <Tooltip>{user.name}</Tooltip>}
                            >
                                <div><Avatar size="l" name={user.name}/></div>
                            </OverlayTrigger>

                        </Dropdown.Toggle>

                        <Dropdown.Menu align={'start'} className="dropdown-md px-0 py-3">
                            <Dropdown>
                                <Dropdown.Toggle as={Nav.Link} className={classNames('dropdown-caret-none p-0', {
                                    'ms-n1': index > 0
                                })}>
                                    <Flex alignItems="center" className="px-3">
                                        <Avatar size="l" name={user.name}/>
                                        <div className="flex-1">
                                            <h6 className="mb-0 text-900">
                                                {user.name}
                                            </h6>
                                        </div>
                                    </Flex>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className={'py-1'}>
                                    <Dropdown.Item>
                                        <Button
                                            onClick={() => showModal(user)}
                                            variant={'falcon-light'} size="sm"
                                            className={'me-2 d-none d-md-block px-0'}>
                                            {t(lang, 'task.board.common.edit')}
                                        </Button>
                                    </Dropdown.Item>
                                    <Dropdown.Item className={'text-danger'}
                                                   onClick={() => onDeleteModal(user.id)}>{t(lang, 'task.board.common.delete')}</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Dropdown.Menu>
                    </Dropdown>
                ))}
                {(members.length > showMember) && (
                    <Dropdown>
                        <Dropdown.Toggle as={Nav.Link} className="dropdown-caret-none p-0 ms-n1">
                            <Avatar
                                name={`${members.length - showMember}+`}
                                size={avatarSize}
                                isExact
                                mediaClass="avatar-button"/>
                        </Dropdown.Toggle>
                        <Dropdown.Menu align={'start'} className="dropdown-md px-0 py-3">
                            <h6 className="dropdown-header py-0 px-3 mb-0">{t(lang, 'task.board.members')}</h6>
                            <Dropdown.Divider/>
                            <Flex justifyContent={'around'}>
                                {members.map((user, index) => (
                                    <Dropdown key={user.id}>
                                        <Dropdown.Toggle as={Nav.Link} className={classNames('dropdown-caret-none p-0', {
                                            'ms-n1': index > 0
                                        })}>
                                                <div className="position-relative text-900">
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={<Tooltip
                                                            id={`member-${user.id}`}>{user.name}</Tooltip>}>
                                                        <div>
                                                            <Avatar size="l" name={user.name}/>
                                                        </div>
                                                    </OverlayTrigger>
                                                </div>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className={'py-1'}>
                                            <Dropdown.Item>
                                                <Button
                                                    onClick={() => showModal(user)}
                                                    variant={'falcon-light'} size="sm"
                                                    className={'me-2 d-none d-md-block px-0'}>
                                                    {t(lang, 'task.board.common.edit')}
                                                </Button>
                                            </Dropdown.Item>
                                            <Dropdown.Item className={'text-danger'}
                                                           onClick={() => onDeleteModal(user.id)}>{t(lang, 'task.board.common.delete')}</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                ))}
                            </Flex>

                        </Dropdown.Menu>
                    </Dropdown>
                )}
                {(value) && (
                    <Dropdown className="font-sans-serif btn-reveal-trigger">
                        <Dropdown.Toggle disabled={edit} as={Nav.Link}
                                         className="d-flex text-dark gap-1 align-items-center dropdown-caret-none p-0 ms-n1">
                            <Avatar name={value} size={avatarSize}/>
                            <strong>{value}</strong>
                        </Dropdown.Toggle>
                        <Dropdown.Menu align={'start'} className="dropdown-md">
                            <h6 className="dropdown-header py-0 px-3 mb-0">{'Пользователь'}</h6>
                            <Dropdown.Divider/>
                            <Flex className="px-3">
                                {members.map(user => (
                                    <div className="position-relative text-900" key={user.id}>
                                        <OverlayTrigger placement="top" overlay={<Tooltip
                                            id={`member-${user.id}`}>{user.name}</Tooltip>}>
                                            <Dropdown className="dropdown-caret-none p-0 ms-n1">
                                                <Dropdown.Toggle variant="light" className={'py-0 px-1'}>
                                                    <Avatar size="l" name={user.name}/>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className={'py-1'}>
                                                    <Dropdown.Item>
                                                        <Button
                                                            onClick={() => showModal(user)}
                                                            variant={'falcon-light'}
                                                            size="sm"
                                                            className={'me-2 d-none d-md-block px-0'}
                                                        >
                                                            {t(lang, 'Редактировать')}
                                                        </Button>
                                                    </Dropdown.Item>
                                                    <Dropdown.Item className={'text-danger'}
                                                                   onClick={() => onDeleteModal(user.id)}>{t(lang, 'Удалить')}</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </OverlayTrigger>
                                    </div>
                                ))}
                            </Flex>
                        </Dropdown.Menu>
                        <Controller name={name} render={({field}) => (
                            <MemberDropdownSelect edit={edit} value={field.value}
                                                  onChange={field.onChange}/>
                        )}/>
                    </Dropdown>
                )}

                {show && <MemberModal show={show} setShow={setShow} defaultValue={defaultValue}/>}
                {showDeleteModal &&
                    <DeleteMemberModal show={showDeleteModal} setShow={setShowDeleteModal} id={memberId}/>}
            </AvatarGroup>
        </div>
    );
};

GroupMember.propTypes = {
    avatarSize: PropTypes.string,
    value: PropTypes.string,
    members: PropTypes.array,
    showMember: PropTypes.number,
    className: PropTypes.string,
    name: PropTypes.string,
    edit: PropTypes.bool
}
export default GroupMember;
