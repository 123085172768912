import {createSlice} from '@reduxjs/toolkit'
import kanbanService from "../../../../services/kanban/KanbanService";
import EventBus from "../../../eventbus/EventBus";
import {
    REGISTER_BOARD_REQUEST,
    REGISTER_BOARD_SUCCESS,
    REGISTER_BOARD_FAIL,
    CHANGE_BOARD_NAME_REQUEST,
    CHANGE_BOARD_NAME_SUCCESS,
    CHANGE_BOARD_NAME_FAIL,
    STATUS_REGISTER_SUCCESS,
    STATUS_REGISTER_FAIL,
    STATUS_DELETE_REQUEST,
    STATUS_DELETE_SUCCESS,
    STATUS_DELETE_FAIL,
    STATUS_UPDATE_SUCCESS,
    STATUS_UPDATE_FAIL,
    MEMBER_REGISTER_SUCCESS,
    MEMBER_REGISTER_FAIL,
    MEMBER_UPDATE_REQUEST,
    MEMBER_UPDATE_SUCCESS,
    MEMBER_UPDATE_FAIL,
    MEMBER_DELETE_FAIL,
    MEMBER_DELETE_SUCCESS,
    ITEM_REGISTER_SUCCESS,
    ITEM_REGISTER_FAIL,
    ITEM_DELETE_ASSIGNER_SUCCESS,
    ITEM_DELETE_ASSIGNER_FAIL,
    ITEM_UPDATE_COMMENT_SUCCESS,
    ITEM_UPDATE_COMMENT_FAIL,
    ITEM_REGISTER_ASSIGNER_SUCCESS,
    ITEM_REGISTER_ASSIGNER_FAIL,
    ITEM_UPDATE_ASSIGNER_FAIL,
    ITEM_UPDATE_ASSIGNER_SUCCESS,
    ITEM_REGISTER_COMMENT_FAIL,
    ITEM_REGISTER_COMMENT_SUCCESS,
    ITEM_DELETE_COMMENT_FAIL,
    ITEM_DELETE_COMMENT_SUCCESS,
    ITEM_REGISTER_LABEL_SUCCESS,
    ITEM_REGISTER_LABEL_FAIL,
    ITEM_UPDATE_LABEL_SUCCESS,
    ITEM_UPDATE_LABEL_FAIL,
    ITEM_DELETE_LABEL_SUCCESS,
    ITEM_DELETE_LABEL_FAIL, ITEM_CHANGE_STATUS_SUCCESS, ITEM_CHANGE_STATUS_FAIL
} from "../../../eventbus/kanban/KanbanEvents";

const initialState = {
    boards: [],
    boardName: "",
    members: [],
    labels: [
        {uuid: 1, name: 'task.board.modal.new.label.name', color_type: 1},
        {uuid: 2, name: 'task.board.modal.error.label.name', color_type: 4},
    ],
    kanbanItems: [],
    kanbanItemEdit: null,
    statusItems: [],
    itemChangeStatusLoader: false,
    modalContent: {}
}


const kanbanReducer = createSlice({
    name: 'kanbanReducer',
    initialState,
    reducers: {
        getBoards: (state, action) => {
            state.boards = action.payload
        },
        getBoardItem:(state, action) => {
            state.kanbanItems = action.payload.statuses?.sort((a, b) => a.order - b.order)
            state.members = action.payload.members
            state.boardName = action.payload?.board?.name
            // state.labels = action.payload.labels
        },
        getBoardStatusItems: (state, {payload}) => {
            state.statusItems = payload
        },
        clearBoardStatusItems: (state, {payload}) => {
            state.statusItems = payload
        },
        addMembers: (state, {payload}) => {
            state.members = [...state.members, payload]
        },
        updateModalContent: (state, {payload}) => {
            state.modalContent = payload ?  payload : {}
            state.labels = payload.labels ? Array.from(
                new Map([...state.labels, ...payload.labels].map((obj) => [obj.name, obj])).values()
            ) : state.labels
        },
        defaultLabels: (state) => {
              state.labels = [
                  {name: 'task.board.modal.new.label.name', color_type: 1},
                  {name: 'task.board.modal.error.label.name', color_type: 4},
              ]
        },
        addKanbanColumn: (state, {payload}) => {
            state.kanbanItems = [...state.kanbanItems, payload]
        },
        editTaskCard: (state, {payload}) => {
            state.kanbanItems = state.kanbanItems.map(item => {
                if (item.id === payload.columnId) {
                    return {
                        ...item,
                        items: item.items.map(i => {
                            if (i.id === payload.id) {
                                return {...payload}
                            }
                            return item
                        })
                    }
                }
                return item
            })
        },
        updateSingleColumn: (state, {payload}) => {
            state.kanbanItems = state.kanbanItems.map(kanbanItem => {
                if (kanbanItem.id === payload.column.id) {
                    return {...kanbanItem, items: [...payload.reorderedItems]}
                }
                return kanbanItem
            })
        },
        updateDualColumn: (state, {payload}) => {

            /*state.kanbanItems = state.kanbanItems.map(kanbanItem => {
                if (kanbanItem.id === payload.sourceColumn.id || kanbanItem.id === payload.destColumn.id) {
                    return {
                        ...kanbanItem,
                        items:
                            (kanbanItem.id === payload.sourceColumn.id && payload.updatedSourceItems) ||
                            (kanbanItem.id === payload.destColumn.id && payload.updatedDestItems)
                    }
                }
                return kanbanItem
            })*/
        },
        removeTaskCard: (state, {payload}) => {
            state.kanbanItems = state.kanbanItems.map(kanbanItem => {
                return {
                    ...kanbanItem,
                    items: kanbanItem.items.filter(item => item.id !== payload.id)
                }
            })
        },
        removeKanbanColumn: (state, {payload}) => {
            state.kanbanItems = state.kanbanItems.filter(kanbanItem => kanbanItem.id !== payload.id)
        },
        addItemRegisterLabel: (state, {payload}) => {
            state.labels = [...state.labels, payload]
        },
        changeLoader: (state, {payload}) => {
            state.itemChangeStatusLoader = payload
        }
    }
})
export const selectBoards = (state) => state.kanban.boards
export const selectBoardName = (state) => state.kanban.boardName
export const selectMembers = (state) => state.kanban.members
export const selectKanbanItems = (state) => state.kanban.kanbanItems
export const selectKanbanStatusItems = (state) => state.kanban.statusItems
export const selectActivities = (state) => state.kanban.activities || []
export const selectCurrentUser = (state) => state.kanban.currentUser
export const selectModalContent = (state) => state.kanban.modalContent
export const selectLabels = (state) => state.kanban.labels
export const itemLoader = (state) => state.kanban.itemChangeStatusLoader

export const getBoardOrganizationAsync = () => (dispatch) => {
    kanbanService.boardOrganizationGet()
        .then(res => {
            dispatch(kanbanReducer.actions.getBoards(res.data))
        })
        .catch(error => {
            console.log(error)
        })
}
export const getBoardItemAsync = (id) => (dispatch) => {
    return new Promise((resolve, reject) => {
        kanbanService.boardGet(id)
            .then((res) => {
                dispatch(kanbanReducer.actions.getBoardItem(res.data))
                resolve(res)
            })
            .catch((e) => {
                new Error(e)
                reject(e)
            })
    })

}

export const getBoardStatusItemsAsync = id => dispatch => {
    return new Promise((resolve, reject) => {
        kanbanService.boardItemsGet(id)
            .then(res => {
                dispatch(kanbanReducer.actions.getBoardStatusItems(res.data))
                resolve(res)
            })
            .catch((e) => {
                new Error(e)
                reject(e)
            })
    })

}


export const boardRegister = (name) => {
    EventBus.dispatch(REGISTER_BOARD_REQUEST)
    return new Promise((resolve, reject) => {
        kanbanService.boardRegister(name)
            .then((res) => {
                EventBus.dispatch(REGISTER_BOARD_SUCCESS)
                resolve(res)
            })
            .catch((e) => {
                EventBus.dispatch(REGISTER_BOARD_FAIL)
                reject(e)
            })
    })
}
export const boardChangeName = ({id,name}) => {
    EventBus.dispatch(CHANGE_BOARD_NAME_REQUEST)
    return new Promise((resolve, reject) => {
        kanbanService.boardChangeName({id,name})
            .then((res)=> {
                EventBus.dispatch(CHANGE_BOARD_NAME_SUCCESS)
                resolve(res)
            })
            .catch((e) => {
                EventBus.dispatch(CHANGE_BOARD_NAME_FAIL)
                reject(e)
            })
    })
}

export const statusRegister = (data) => {
    return new Promise((resolve, reject) => {
        kanbanService.statusRegister(data)
            .then((res) => {
                EventBus.dispatch(STATUS_REGISTER_SUCCESS)
                resolve(res)
            })
            .catch((e) => {
                EventBus.dispatch(STATUS_REGISTER_FAIL)
                reject(e)
            })
    })
}
export const statusDelete = (id) => {
    EventBus.dispatch(STATUS_DELETE_REQUEST)
    return new Promise((resolve, reject) => {
        kanbanService.statusDelete(id)
            .then((res) => {
                EventBus.dispatch(STATUS_DELETE_SUCCESS)
                resolve(res)
            })
            .catch((e) => {
                EventBus.dispatch(STATUS_DELETE_FAIL)
                reject(e)
            })
    })
}
export const statusUpdate = (data) => {
    return new Promise((resolve, reject) => {
        kanbanService.statusUpdate(data)
            .then((res) => {
                EventBus.dispatch(STATUS_UPDATE_SUCCESS)
                resolve(res)
            })
            .catch((e) => {
                EventBus.dispatch(STATUS_UPDATE_FAIL)
                reject(e)
            })
    })
}

export const memberRegister = (data) => {
    return new Promise((resolve, reject) => {
        kanbanService.memberRegister(data)
            .then((res) => {
                EventBus.dispatch(MEMBER_REGISTER_SUCCESS)
                resolve(res)
            })
            .catch((e) => {
                EventBus.dispatch(MEMBER_REGISTER_FAIL)
                reject(e)
            })
    })
}
export const memberUpdate = (id,data) => {
    EventBus.dispatch(MEMBER_UPDATE_REQUEST)
    return new Promise((resolve, reject) => {
        kanbanService.memberChangeName(id,data)
            .then((res) => {
                EventBus.dispatch(MEMBER_UPDATE_SUCCESS)
                resolve(res)
            })
            .catch((e) => {
                EventBus.dispatch(MEMBER_UPDATE_FAIL)
                reject(e)
            })
    })
}
export const memberDelete = (id) => {
    return new Promise((resolve, reject) => {
        kanbanService.memberDelete(id)
            .then((res) => {
                EventBus.dispatch(MEMBER_DELETE_SUCCESS)
                resolve(res)
            })
            .catch((e) => {
                EventBus.dispatch(MEMBER_DELETE_FAIL)
                reject(e)
            })
    })
}

export const itemRegister = (data) => {
    return new Promise((resolve, reject) => {
        kanbanService.itemRegister(data)
            .then((res) => {
                EventBus.dispatch(ITEM_REGISTER_SUCCESS)
                resolve(res.data)
            })
            .catch((e) => {
                EventBus.dispatch(ITEM_REGISTER_FAIL)
                reject(e)
            })
    })
}

export const getItem = (id) =>  dispatch => {
    kanbanService.getItem(id)
        .then((res) => dispatch(kanbanReducer.actions.updateModalContent(res.data)))
        .catch((e) => new Error(e))
}

// assigner

export const itemRegisterAssigner = (itemId, params) => {
    return new Promise((resolve, reject) => {
        kanbanService.itemRequestAssigner(itemId, params)
            .then((res) => {
                EventBus.dispatch(ITEM_REGISTER_ASSIGNER_SUCCESS)
                resolve(res.data)
            })
            .catch((e) => {
                EventBus.dispatch(ITEM_REGISTER_ASSIGNER_FAIL)
                reject(e)
            })
    })
}

export const itemDeleteAssigner = (itemId, params) => {
    return new Promise((resolve, reject) => {
        kanbanService.itemDeleteAssigner(itemId, params)
            .then((res) => {
                EventBus.dispatch(ITEM_DELETE_ASSIGNER_SUCCESS)
                resolve(res)
            })
            .catch((e) => {
                EventBus.dispatch(ITEM_DELETE_ASSIGNER_FAIL)
                reject(e)
            })
    })
}

export const itemUpdateAssigner = (itemId, params) => {
    return new Promise((resolve, reject) => {
        kanbanService.itemUpdateAssigner(itemId, params)
            .then((res) => {
                EventBus.dispatch(ITEM_UPDATE_ASSIGNER_SUCCESS)
                resolve(res.data)
            })
            .catch((e) => {
                EventBus.dispatch(ITEM_UPDATE_ASSIGNER_FAIL)
                reject(e)
            })
    })
}

// comment

export const itemRegisterComment = (itemId, params) => {
    return new Promise((resolve, reject) => {
        kanbanService.itemRegisterComment(itemId, params)
            .then((res) => {
                EventBus.dispatch(ITEM_REGISTER_COMMENT_SUCCESS)
                resolve(res)
            })
            .catch((e) => {
                EventBus.dispatch(ITEM_REGISTER_COMMENT_FAIL)
                reject(e)
            })
    })
}

export const itemDeleteComment = (commentId, itemId) => {
    return new Promise((resolve, reject) => {
        kanbanService.itemDeleteComment(commentId, itemId)
            .then((res) => {
                EventBus.dispatch(ITEM_DELETE_COMMENT_SUCCESS)
                resolve(res.data)
            })
            .catch((e) => {
                EventBus.dispatch(ITEM_DELETE_COMMENT_FAIL)
                reject(e)
            })
    })
}

export const itemUpdateComment = (commentId, params) => {
    return new Promise((resolve, reject) => {
        kanbanService.itemUpdateComment(commentId, params)
            .then((res) => {
                EventBus.dispatch(ITEM_UPDATE_COMMENT_SUCCESS)
                resolve(res.data)
            })
            .catch((e) => {
                EventBus.dispatch(ITEM_UPDATE_COMMENT_FAIL)
                reject(e)
            })
    })
}

// label

export const itemRegisterLabel = (itemId, params) => {
    return new Promise((resolve, reject) => {
        kanbanService.itemRegisterLabel(itemId, params)
            .then((res) => {
                EventBus.dispatch(ITEM_REGISTER_LABEL_SUCCESS)
                resolve(res)
            })
            .catch((e) => {
                EventBus.dispatch(ITEM_REGISTER_LABEL_FAIL)
                reject(e)
            })
    })
}

export const itemUpdateLabel = () => {
    return new Promise((resolve, reject) => {
        kanbanService.itemUpdateLabel()
            .then((res) => {
                EventBus.dispatch(ITEM_UPDATE_LABEL_SUCCESS)
                resolve(res)
            })
            .catch((e) => {
                EventBus.dispatch(ITEM_UPDATE_LABEL_FAIL)
                reject(e)
            })
    })
}

export const itemDeleteLabel = () => {
    return new Promise((resolve, reject) => {
        kanbanService.itemDeleteLabel()
            .then((res) => {
                EventBus.dispatch(ITEM_DELETE_LABEL_SUCCESS)
                resolve(res)
            })
            .catch((e) => {
                EventBus.dispatch(ITEM_DELETE_LABEL_FAIL)
                reject(e)
            })
    })
}

export const itemChangeStatus = (id, params) => {
    return new Promise((resolve, reject) => {
        kanbanService.itemChangeStatus(id, params)
            .then(() => {
                EventBus.dispatch(ITEM_CHANGE_STATUS_SUCCESS)
                resolve(true)
            })
            .catch(() => {
                EventBus.dispatch(ITEM_CHANGE_STATUS_FAIL)
                reject(false)
            })
    })
}

export const getItemStatusChange = (id) => {
    return new Promise((resolve, reject) => {
        kanbanService.getItemStatusChange(id)
            .then((res) => {
                resolve(res.data)
            })
            .catch((e) => new Error(e))
    })
}

export const getItemMemberChange = (id) => {
    return new Promise((resolve, reject) => {
        kanbanService.getItemMemberChange(id)
            .then((res) => {
                resolve(res.data)
            })
            .catch((e) => new Error(e))
    })
}

export const {
    updateModalContent,
    editTaskCard,
    removeTaskCard,
    removeKanbanColumn,
    addItemRegisterLabel,
    defaultLabels,
    changeLoader,
    clearBoardStatusItems
} = kanbanReducer.actions
export default kanbanReducer.reducer
