import Select from "react-select";
import PropTypes from "prop-types";
import {useSelector} from "react-redux";
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import React, {useEffect, useState} from 'react';
import {selectCompanyCatalogs} from "../../../app/store/reducers/roaming/roamingReducer";
import classNames from "classnames";
import FalconCloseButton from "../../common/FalconCloseButton";
import IconAlert from "../../common/IconAlert";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";

const SelectCompanyCatalog = ({
                                  onChange,
                                  fullWidth,
                                  className,
                                  textButton,
                                  defaultCatalogCode,
                                  defaultLgota,
                                  ...props
                              }) => {
    const [catalog, setCatalog] = useState(null);
    const [lgota, setLgota] = useState(null);
    const [modalShow, setModalShow] = useState(false);
    const companyCatalogs = useSelector(selectCompanyCatalogs);
    const lang = useSelector(selectLang);
    const t = Translate;

    useEffect(() => {
        if (defaultCatalogCode && typeof (defaultCatalogCode) === 'string') {
            setCatalog(companyCatalogs.find(catalog => catalog.class_code === defaultCatalogCode) || null)
        }
    }, [defaultCatalogCode, companyCatalogs])

    useEffect(() => {
        if (catalog)
            setLgota(catalog.lgotas.find(catalogLgota => catalogLgota.id === defaultLgota || catalogLgota.new_id === defaultLgota) || null)
    }, [defaultLgota, catalog])

    const getOptionCatalogValue = (option) => option.class_code;
    const getOptionCatalogLabel = (option) => `${option.class_code} ${option.name}`;

    const formatCatalogLabel = (option) => {
        return (
            <div className="fs--1">
                <span className="text-black fw-bold me-1">{option.class_code}</span>-
                <span className="ms-1 text-facebook">{option.name}</span>
            </div>
        )
    }

    const getOptionLgotaValue = (option) => option.new_id || option.id;
    const getOptionLgotaLabel = (option) => `${option.new_id} ${option.id} ${option.name}`;

    const formatLgotaLabel = (option) => {
        return (
            <div className="fs--1">
                <h5 className="fs--1 fw-bold">
                    {option.type === 1 &&
                        <span className="text-success">{t(lang, "edi.common.privilege.type.1")}</span>}
                    {option.type === 2 &&
                        <span className="text-primary">{t(lang, "edi.common.privilege.type.2")}</span>}
                </h5>
                <p>
                    <span className="text-success fw-bold me-1">{option.new_id};</span>
                    <span className="text-warning fw-bold me-1">{option.id}</span>-
                    <span className="ms-1 text-facebook">{option.name}</span>
                </p>
            </div>
        )
    };

    const onSaveHandler = () => {
        onChange(catalog, lgota)
        handleClose()
    }

    const handleClose = () => {
        setModalShow(false);
    }
    const handleShow = () => setModalShow(true);

    return (
        <>
            <Button variant="outline-primary" className={classNames({'w-100': fullWidth}, className)} {...props}
                    onClick={handleShow}>{catalog?.class_code && textButton  || t(lang, 'edi.common.button.select')}</Button>
            {modalShow && <Modal show={true} onHide={handleClose} size="lg" centered>
                <Modal.Header>
                    <Modal.Title>{t(lang, 'edi.select_company_catalog.title')}</Modal.Title>
                    <FalconCloseButton onClick={handleClose}/>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm="12" className="mb-3">
                            <Form.Label>Идентификационный код продукции и услуг</Form.Label>
                            <Select
                                {...props}
                                isClearable
                                value={catalog}
                                options={companyCatalogs}
                                onChange={setCatalog}
                                hideSelectedOptions
                                getOptionLabel={getOptionCatalogLabel}
                                getOptionValue={getOptionCatalogValue}
                                formatOptionLabel={formatCatalogLabel}
                                classNamePrefix="react-select"
                                placeholder={t(lang, 'edi.common.form.select.placeholder')}
                            />
                        </Col>
                        {catalog?.lgotas?.length > 0 &&
                            <Col sm="12" className="mb-3">
                                <Form.Label>Льгота продукции или услуг</Form.Label>
                                <Select
                                    {...props}
                                    isClearable
                                    value={lgota}
                                    options={catalog?.lgotas}
                                    onChange={setLgota}
                                    hideSelectedOptions
                                    getOptionLabel={getOptionLgotaLabel}
                                    getOptionValue={getOptionLgotaValue}
                                    formatOptionLabel={formatLgotaLabel}
                                    classNamePrefix="react-select"
                                    placeholder={t(lang, 'edi.common.form.select.placeholder')}
                                />
                            </Col>
                        }
                        {catalog && (catalog?.lgotas?.length > 0
                            ?
                            <Col sm="12">
                                <IconAlert variant="success">
                                    <p className="mb-0">Льгота для этого товара найдена</p>
                                    <b>Можете выбрать льготу!</b>
                                </IconAlert>
                            </Col>
                            :
                            <Col sm="12">
                                <IconAlert variant="info">
                                    <p className="mb-0">Льгота для этого товара не найдена</p>
                                    <b>Можете продолжать работу!</b>
                                </IconAlert>
                            </Col>)
                        }
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>{t(lang, 'edi.common.button.close')}</Button>
                    <Button variant="primary" onClick={onSaveHandler}>{t(lang, 'edi.common.button.select')}</Button>
                </Modal.Footer>
            </Modal>}
        </>
    );
};

SelectCompanyCatalog.propTypes = {
    onChange: PropTypes.func,
    fullWidth: PropTypes.bool,
}
SelectCompanyCatalog.defaultProps = {
    onChange: () => {
    },
    fullWidth: true,
}

export default SelectCompanyCatalog;
