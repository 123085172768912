import React from 'react';
import {selectLang, selectNumberFormat, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {Accordion, Card, Col, Row} from "react-bootstrap";
import numeral from "numeral";
import SaleItemsReportDataTable from "./SaleItemsReportDataTable";
import {selectActiveCurrencies} from "../../../../app/store/reducers/currency/currencyReducer";

const PeriodReportSummary = ({report}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const numberFormat = useSelector(selectNumberFormat);
    const activeCurrencies = useSelector(selectActiveCurrencies);

    return (
        <Row className="g-2">
            <Col xs={12} sm={6} lg={4}>
                <Card className="h-100">
                    <Card.Body>
                        <div className="d-flex justify-content-between flex-wrap">
                            <h5>{t(lang, "warehouse.operation.report.sales")}</h5>
                            <p className="font-sans-serif lh-1 mb-1 fs-2 text-success">{report.overall_period_report.sales_count}</p>
                        </div>
                        {activeCurrencies.map((currency, index) => {
                            const price = report.overall_period_report.sales_net_price.find((price) => price.currency.id === currency.id);
                            if (price) {
                                return (
                                    <h5 className="text-700 mb-0 fs-0 text-end"
                                        key={index}>{numeral.formats[numberFormat].format(price.amount)} {price.currency.name}</h5>
                                )
                            }

                            return (
                                <h5 className="text-700 mb-0 fs-0 text-end"
                                    key={index}>{numeral.formats[numberFormat].format(0)} {currency.name}</h5>
                            )
                        })}
                    </Card.Body>
                </Card>
            </Col>
            <Col xs={12} sm={6} lg={4}>
                <Card className="h-100">
                    <Card.Body>
                        <div className="d-flex justify-content-between flex-wrap">
                            <h5>{t(lang, "warehouse.operation.report.payments")}</h5>
                            <p className="font-sans-serif lh-1 mb-1 fs-2 text-info">{report.overall_period_report.payments_count}</p>
                        </div>
                        {activeCurrencies.map((currency, index) => {
                            const price = report.overall_period_report.payments_net_price.find((price) => price.currency.id === currency.id);
                            if (price) {
                                return (
                                    <h6 className="text-700 mb-0 fs-0 text-end" key={index}>{numeral.formats[numberFormat].format(price.amount)} {price.currency.name}</h6>
                                )
                            }

                            return (
                                <h6 className="text-700 mb-0 fs-0 text-end" key={index}>{numeral.formats[numberFormat].format(0)} {currency.name}</h6>
                            )
                        })}
                    </Card.Body>
                </Card>
            </Col>
            <Col xs={12} sm={6} lg={4}>
                <Card className="h-100">
                    <Card.Body>
                        <div className="d-flex justify-content-between flex-wrap">
                            <h5>{t(lang, "warehouse.operation.report.payouts")}</h5>
                            <p className="font-sans-serif lh-1 mb-1 fs-2 text-warning">{report.overall_period_report.payouts_count}</p>
                        </div>
                        {activeCurrencies.map((currency, index) => {
                            const price = report.overall_period_report.payouts_net_price.find((price) => price.currency.id === currency.id);
                            if (price) {
                                return (
                                    <h6 className="text-700 mb-0 fs-0 text-end" key={index}>{numeral.formats[numberFormat].format(price.amount)} {price.currency.name}</h6>
                                )
                            }

                            return (
                                <h6 className="text-700 mb-0 fs-0 text-end" key={index}>{numeral.formats[numberFormat].format(0)} {currency.name}</h6>
                            )
                        })}
                    </Card.Body>
                </Card>
            </Col>
            <Col xs={12} sm={6} lg={4}>
                <Card className="h-100">
                    <Card.Body>
                        <div className="d-flex justify-content-between flex-wrap">
                            <h5>{t(lang, "warehouse.operation.report.refunds")}</h5>
                            <p className="font-sans-serif lh-1 mb-1 fs-2 text-secondary">{report.overall_period_report.refunds_count}</p>
                        </div>
                        {activeCurrencies.map((currency, index) => {
                            const price = report.overall_period_report.refunds_net_price.find((price) => price.currency.id === currency.id);
                            if (price) {
                                return (
                                    <h6 className="text-700 mb-0 fs-0 text-end"
                                        key={index}>{numeral.formats[numberFormat].format(price.amount)} {price.currency.name}</h6>
                                )
                            }

                            return (
                                <h6 className="text-700 mb-0 fs-0 text-end"
                                    key={index}>{numeral.formats[numberFormat].format(0)} {currency.name}</h6>
                            )
                        })}
                    </Card.Body>
                </Card>
            </Col>
            <Col xs={12} sm={6} lg={4}>
                <Card className="h-100">
                    <Card.Body>
                        <div className="d-flex justify-content-between flex-wrap">
                            <h5>{t(lang, "warehouse.operation.report.purchases")}</h5>
                            <p className="font-sans-serif lh-1 mb-1 fs-2 text-primary">{report.overall_period_report.purchases_count}</p>
                        </div>
                        {activeCurrencies.map((currency, index) => {
                            const price = report.overall_period_report.purchases_net_price.find((price) => price.currency.id === currency.id);
                            if (price) {
                                return (
                                    <h6 className="text-700 mb-0 fs-0 text-end"
                                        key={index}>{numeral.formats[numberFormat].format(price.amount)} {price.currency.name}</h6>
                                )
                            }

                            return (
                                <h6 className="text-700 mb-0 fs-0 text-end"
                                    key={index}>{numeral.formats[numberFormat].format(0)} {currency.name}</h6>
                            )
                        })}
                    </Card.Body>
                </Card>
            </Col>
            <Col xs={12} sm={6} lg={4}>
                <Card className="h-100">
                    <Card.Body>
                        <div className="d-flex justify-content-between flex-wrap">
                            <h5>{t(lang, "warehouse.operation.report.returns")}</h5>
                            <p className="font-sans-serif lh-1 mb-1 fs-2 text-danger">{report.overall_period_report.returns_count}</p>
                        </div>
                        {activeCurrencies.map((currency, index) => {
                            const price = report.overall_period_report.returns_net_price.find((price) => price.currency.id === currency.id);
                            if (price) {
                                return (
                                    <h6 className="text-700 mb-0 fs-0 text-end" key={index}>{numeral.formats[numberFormat].format(price.amount)} {price.currency.name}</h6>
                                )
                            }

                            return (
                                <h6 className="text-700 mb-0 fs-0 text-end" key={index}>{numeral.formats[numberFormat].format(0)} {currency.name}</h6>
                            )
                        })}
                    </Card.Body>
                </Card>
            </Col>
            <Col xs={12} className="mt-3">
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Card className="h-100">
                            <Accordion.Header>
                                <Card.Header className="w-100">
                                    <Card.Title className="text-end">
                                        {t(lang, "warehouse.operation.report.sales_profit_by_purchase_price")}
                                    </Card.Title>
                                    <Card.Subtitle>
                                        <Row>
                                            <Col xs={12} className="text-center">
                                                <div className="d-flex flex-column justify-content-center align-items-center">
                                                    {activeCurrencies.map((currency, index) => {
                                                        const price = report.overall_period_report.sales_profit_by_purchase_price.find((price) => price.currency.id === currency.id);
                                                        if (price) {
                                                            return (
                                                                <h6 className="text-700 mb-0 w-100 fs-0 text-end" key={index}>{numeral.formats[numberFormat].format(price.amount)} {price.currency.name}</h6>
                                                            )
                                                        }

                                                        return (
                                                            <h6 className="text-700 mb-0 fs-0 w-100 text-end" key={index}>{numeral.formats[numberFormat].format(0)} {currency.name}</h6>
                                                        )
                                                    })}
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card.Subtitle>
                                </Card.Header>
                            </Accordion.Header>
                            <Accordion.Body>
                                <Card.Body className="px-0">
                                    <SaleItemsReportDataTable report={report}/>
                                </Card.Body>
                            </Accordion.Body>
                        </Card>
                    </Accordion.Item>
                </Accordion>
            </Col>
        </Row>
    );
};

export default PeriodReportSummary;