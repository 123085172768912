import React from 'react';
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import organizationBindingWrapper, {
    SalesAccessTypes
} from "../../../../enum/organizationBinding/OrganizationBindingWrapper";
import ReactSelect from "react-select";

const SelectSalesAccessType = ({onChange, defaultSalesAccessType}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const options = SalesAccessTypes;

    return (
        <ReactSelect options={options}
                     isSearchable={!!defaultSalesAccessType}
                     placeholder={t(lang, 'items.common.select')}
                     getOptionLabel={option => t(lang, option.label)}
                     getOptionValue={(option) => option.value}
                     onChange={(option) => onChange(option.value)}
                     value={options.find(o => o.value === defaultSalesAccessType) || null}
        />
    );
};

export default SelectSalesAccessType;