import AdvanceTableWrapper from "../../../../common/advance-table/AdvanceTableWrapper";
import AdvanceTable from "../../../../common/advance-table/AdvanceTable";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    selectDateDayJSFormat,
    selectLang,
    selectNumberFormat,
    Translate
} from "../../../../../app/store/reducers/main/mainReducer";
import {Card, Col, Row} from "react-bootstrap";
import AdvanceTableCustomPagination from "../../../../common/advance-table/AdvanceTableCustomPagination";
import {
    ActFormSendAsync,
    changeFilterOptionsAsync,
    loadRoamingActAsync, loadRoamingActsCountAsync,
    selectActsFilterOptions, selectCount,
    selectRoamingActs, setId, setShowModal, viewPage
} from "../../../../../app/store/reducers/roaming/roamingActReducer";
import {selectActiveOrganization} from "../../../../../app/store/reducers/auth/authReducer";
import {Link} from "react-router-dom";
import dayjs from "dayjs";
import numeral from "numeral";
import SoftBadge from "../../../../common/SoftBadge";
import {ActWrapperClass} from "../View/ActStatus";
import {GetViewerIcon, TypeViewers, VIEW_DRAFT} from "../View/TypeView";
import classNames from "classnames";
import Flex from "../../../../common/Flex";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ActDataTableHeader from "./ActDataTableHeader";
import CountbleProgressBar from "../../../../common/CountbleProgressBar";
import {selectCurrentCertificate} from "../../../../../app/store/reducers/eimzo/eimzoReducer";
import ViewModalPage from "../../components/ViewModalPage";
import ViewAct from "../../../../../pages/main/roaming/act/ViewAct";
import DraftActView from "../draftAct/DraftActView";
import {
    roamingHideModal,
    setRoamingCommonHideModal
} from "../../../../../app/store/reducers/roaming/roamingInvoiceReducer";


const ActDataTable = ({typeViewer}) => {
    const [ countSelectedAndSignedAct, SetCountSelectedAndSignedAct ] = useState(false)
    const [ showProgressBar, setShowProgressBar ] = useState(false)
    const [ lengthSignAct, setLengthSignAct ] = useState(0)
    const [ signResults, setSignResults ] = useState([])
    const currentDateDayJSFormat = useSelector(selectDateDayJSFormat)
    const activeOrganization = useSelector(selectActiveOrganization)
    const currentCertificate = useSelector(selectCurrentCertificate)
    const numberFormat = useSelector(selectNumberFormat);
    const roamingActs  = useSelector(selectRoamingActs)
    const dispatch = useDispatch()
    const filters = useSelector(selectActsFilterOptions)
    const count = useSelector(selectCount)
    const {id, draftId, showModal} = useSelector(viewPage)
    const hideModal = useSelector(roamingHideModal)

    const lang = useSelector(selectLang);
    const t = Translate;

    const openViewModalPage = (e,id) => {
        const ctrlKey = e.ctrlKey
        const history = window.open

        if (typeViewer === VIEW_DRAFT) {
            if (ctrlKey) {
                history('/#/roaming/draft-act/'+id, '_blank')
            } else {
                dispatch(setId({draftId: id}))
                dispatch(setShowModal(true))
            }
        }else {
            if (ctrlKey) {
                history('/#/roaming/act/'+id, '_blank')
            } else {
                dispatch(setId({id}))
                dispatch(setShowModal(true))
            }
        }
    }

    const onHideModalPage = () => {
        dispatch(setShowModal(false))
    }

    // to close the modal on page change
    useEffect(() => {
        if(hideModal) {
            onHideModalPage()
            dispatch(setRoamingCommonHideModal(null))
        }
    }, [])

    const columns = [
        {
            Header: "#",
            id: "row",
            filterable: false,
            Cell: ({row}) => {
                return `${((filters.page - 1) * filters.limit) + row.index + 1}`
            }
        },
        {
            accessor: 'info.number',
            Header: t(lang, "edi.branch.datatable.header.row.name"),
            headerProps: {className: 'pe-1'},
            cellProps: {
                className: 'py-2'
            },
            Cell: rowData => {
                const {original} = rowData.row;
                return (
                    <strong onClick={(e)=>openViewModalPage(e,original.id)} className={'cursor-pointer text-primary'}>{original.info.number}</strong>
                );
            }
        },
        {
            accessor: 'info.date',
            Header: t(lang, "edi.common.contract"),
            Cell: rowData => {
                const {date} = rowData.row.original.contract.date
                return <span>{rowData.row.original.contract.number} от {dayjs(date, "YYYY-MM-DD").format(currentDateDayJSFormat)}</span>
            }
        },
        typeViewer !== VIEW_DRAFT && {
            accessor: 'status',
            Header: t(lang, "edi.order.datatable.header.row.status"),
            Cell: ({row: {original}}) => {
                const actWrapper = new ActWrapperClass(original)

                return  <h5>
                            <SoftBadge bg={actWrapper.getRoamingStatusVariant(activeOrganization.pinfl, activeOrganization.inn)} className='me-2'>
                                {t(lang, actWrapper.getRoamingStatus(activeOrganization.pinfl,activeOrganization.inn))}
                            </SoftBadge>
                        </h5>
            }
        },
        {
            accessor: 'created_date',
            Header: t(lang, "edi.order.datatable.header.row.created_date"),
            Cell: rowData => {
                const {created_at} = rowData.row.original
                return dayjs(created_at, "YYYY-MM-DD").format(currentDateDayJSFormat)
            }
        },
        {
            accessor: 'contractor',
            Header: t(lang, "edi.order.datatable.header.row.contractor"),
            Cell: ({row: {original}}) => {
                return activeOrganization.inn === original.customer.inn
                    ? `${original.executor.name} (${original.executor.inn})`
                    : `${original.customer.name} (${original.customer.inn})`
            }
        },
        {
            accessor: 'total',
            Header: t(lang,"roaming.act.datatable.header.row.total_sum"),
            Cell: ({row: {original}}) => {
                let subtotal = 0
                if (original?.content === undefined){
                    subtotal = original?.items?.reduce((acc, item) => acc + item.total, 0);
                }else {
                    const Jsonparse = JSON.parse(original?.content)
                    subtotal = Jsonparse?.ProductList?.Products.reduce((acc, item) => acc + item.TotalSum, 0)
                }
                return (
                    <span>{numeral.formats[numberFormat].format(subtotal)}</span>
                )
            }
        }
    ].filter(column => typeof column === 'object' && column !== null)




    const onPageChange = async (page) => {
        dispatch(changeFilterOptionsAsync({...filters, page: page}))
    }

    const onLimitChange = async (limit) => {
        dispatch(changeFilterOptionsAsync({...filters, limit: limit, page: 1}))
    }


    const onBulkSignHandler = async (roamingActs) => {
        let notSignedActs = roamingActs.filter(roamingAct => !roamingAct.status)
        SetCountSelectedAndSignedAct(roamingActs.length - notSignedActs.length)
        setLengthSignAct(notSignedActs.length)
        setShowProgressBar(true)
        for (let i = 0; i < roamingActs.length; i++) {
            let responseInfo = await ActFormSendAsync(currentCertificate, lang, roamingActs[i].content, roamingActs[i].id, roamingActs[i].executor.inn)
            let result = responseInfo.status === 15
            setSignResults(old => [...old, result])
        }
        let params = {...filters, type_viewer: typeViewer, viewer_inn: activeOrganization.pinfl || activeOrganization.inn}
        dispatch(loadRoamingActAsync(params));
        dispatch(loadRoamingActsCountAsync({...params, viewer_inn: activeOrganization.pinfl || activeOrganization.inn}))
        setTimeout(() => {
            setSignResults([])
            setLengthSignAct(0)
            setShowProgressBar(false)
        }, 1000)
    }



    useEffect(() => {
        let params = {...filters, type_viewer: typeViewer, viewer_inn: activeOrganization.pinfl || activeOrganization.inn}
        dispatch(loadRoamingActAsync(params));
        dispatch(loadRoamingActsCountAsync({...params, viewer_inn: activeOrganization.pinfl || activeOrganization.inn}))
    }, [filters, activeOrganization, typeViewer]);


    return(
            <>
                <Row className="mb-3">
                    {TypeViewers.map((viewer, index) => (
                        <Col key={index} xs={4}>
                            <Link to={`../acts/${viewer.id}`} className="text-decoration-none">
                                <Card
                                    className={classNames("p-3 cursor-pointer", {'bg-primary text-white': viewer.id === typeViewer})}
                                >
                                    <Flex justifyContent="center" alignItems="center">
                                        <FontAwesomeIcon
                                            icon={GetViewerIcon(viewer.id)}
                                            className={classNames("fs-2 me-lg-2")}
                                            color="primary"
                                        />
                                        <Card.Title className={classNames("d-none d-lg-block mb-0", {'text-white': viewer.id === typeViewer, 'text-primary': viewer.id !== typeViewer})}
                                        >{t(lang, viewer.name)}
                                        </Card.Title>
                                    </Flex>
                                </Card>
                            </Link>
                        </Col>
                    ))}
                </Row>

                <AdvanceTableWrapper
                    columns={columns}
                    data={roamingActs}
                    pagination
                    perPage={1000}
                    selection
                    selectionColumnWidth={50}
                >
                    <CountbleProgressBar show={showProgressBar} results={signResults} signed={countSelectedAndSignedAct} length={lengthSignAct} />
                    <Card className="mb-3">
                        <Card.Header>
                            <ActDataTableHeader table onClickBulkSign={onBulkSignHandler} typeViewer={typeViewer}/>
                        </Card.Header>
                        <Card.Body className="p-0">
                            <AdvanceTable
                                table
                                headerClassName="bg-200 text-900 text-nowrap align-middle"
                                rowClassName="align-middle white-space-nowrap"
                                tableProps={{
                                    size: 'sm',
                                    striped: true,
                                    className: 'fs--1 mb-0 overflow-hidden'
                                }}
                            />
                        </Card.Body>
                        <Card.Footer>
                            <AdvanceTableCustomPagination
                                count={count}
                                limit={filters.limit}
                                page={filters.page}
                                onLimitChange={onLimitChange}
                                onPageChange={onPageChange}
                            />
                        </Card.Footer>
                    </Card>
                </AdvanceTableWrapper>

                <ViewModalPage show={showModal} onHide={onHideModalPage}>
                    {id && <ViewAct modalId={id} />}
                    {draftId && <DraftActView modalId={draftId} />}
                </ViewModalPage>

            </>
        )
}


export default ActDataTable;
