export const ActSend = 15
export const ActCanceled = 17
export const CustomerDeclined = 20
export const CustomerApproved = 30

export const GetStatusText = status => {
    switch (status) {
        case ActSend:
            return "roaming.act.status.act_send"
        case ActCanceled:
            return "roaming.act.status.act_canceled"
        case CustomerDeclined:
            return "roaming.act.status.customer_declined"
        case CustomerApproved:
            return "roaming.act.status.customer_approved"
        default:
            return "roaming.act.status.default"
    }
}

export const GetStatusVariant = status => {
    switch (status) {
        case ActSend:
            return 'primary'
        case ActCanceled:
            return 'danger'
        case CustomerDeclined:
            return 'danger'
        case CustomerApproved:
            return 'success'
        default:
            return 'dark'
    }
}

export const ActStatuses = [
    {id: ActSend, name: GetStatusText(ActSend)},
    {id: ActCanceled, name: GetStatusText(ActCanceled)},
    {id: CustomerDeclined, name: GetStatusText(CustomerDeclined)},
    {id: CustomerApproved, name: GetStatusText(CustomerApproved)}
]


export class ActWrapperClass {
    _act;
    constructor(act) {
        this._act = act
    }

    enumStatuses = {
        sendToBuyer: 15,
        sellerIsCanceled: 17,
        buyerIsDeclined: 20,
        signedByBothParties: 30,
    };

    allowSyncAct() {
        switch (this._act?.status) {
            case ActSend:
                return true;
            default:
                return false;
        }
    }

    getRoamingStatus = (activePinfl, activeInn) => {
        let status;
        if (this._act?.executor?.inn.length > 9 && this._act?.executor?.inn === activePinfl || this._act?.executor?.inn === activeInn) {
            switch (this._act?.status) {
                case this.enumStatuses.sendToBuyer: // 15 - [executor, seller] sended to [customer, buyer]
                    status = "roaming.act.roaming_statuses.seller.15"
                    break;
                case this.enumStatuses.sellerIsCanceled: // 17 - [executor, seller] sended to [customer, buyer] but the seller canceled
                    status = "roaming.act.roaming_statuses.seller.17"
                    break;
                case this.enumStatuses.buyerIsDeclined: // 20 - [executor, seller] sended to [customer, buyer] but [customer, buyer] declined
                    status = "roaming.act.roaming_statuses.seller.20"
                    break;
                case this.enumStatuses.signedByBothParties: // 30 - [executor, seller] sended to [customer, buyer] and [customer, buyer]
                    status = "roaming.act.roaming_statuses.seller.30"
                    break;
                default: // no such status exists
                    status = "roaming.act.roaming_statuses.not_exist"
                    break;
            }
        } else {
            switch (this._act?.status) {
                case this.enumStatuses.sendToBuyer: // 15 - [executor, seller] sended to [customer, buyer]
                    status = "roaming.act.roaming_statuses.buyyer.15"
                    break;
                case this.enumStatuses.sellerIsCanceled: // 17 - [executor, seller] sended to [customer, buyer] but the seller canceled
                    status = "roaming.act.roaming_statuses.buyyer.17"
                    break;
                case this.enumStatuses.buyerIsDeclined: // 20 - [executor, seller] sended to [customer, buyer] but [customer, buyer] declined
                    status = "roaming.act.roaming_statuses.buyyer.20"
                    break;
                case this.enumStatuses.signedByBothParties: // 30 - [executor, seller] sended to [customer, buyer] and [customer, buyer]
                    status = "roaming.act.roaming_statuses.buyyer.30"
                    break;
                default: // no such status exists
                    status = "roaming.act.roaming_statuses.not_exist"
                    break;
            }
        }
        return status
    }
    getRoamingStatusVariant = (activePinfl, activeInn) => {
        let status;
        if (this._act?.executor?.inn.length > 9 && this._act?.executor?.inn === activePinfl || this._act?.executor?.inn === activeInn) {
            switch (this._act?.status) {
                case 15: // [executor, seller] sended to [customer, buyer]
                    status = "primary"
                    break;
                case 17: // [executor, seller] sended to [customer, buyer] but the seller canceled
                    status = "danger"
                    break;
                case 20: // [executor, seller] sended to [customer, buyer] but [customer, buyer] declined
                    status = "danger"
                    break;
                case 30: // [executor, seller] sended to [customer, buyer] and [customer, buyer]
                    status = "success"
                    break;
                default: // no such status exists
                    status = "dark"
                    break;
            }
        } else {
            switch (this._act?.status) {
                case 15: // [executor, seller] sended to [customer, buyer]
                    status = "warning"
                    break;
                case 17: // [executor, seller] sended to [customer, buyer] but the seller canceled
                    status = "danger"
                    break;
                case 20: // [executor, seller] sended to [customer, buyer] but [customer, buyer] declined
                    status = "danger"
                    break;
                case 30: // [executor, seller] sended to [customer, buyer] and [customer, buyer]
                    status = "success"
                    break;
                default: // no such status exists
                    status = "dark"
                    break;
            }
        }
        return status
    }

    canTheSellerCancled(activeInn, activePinfl) {
        if (this._act?.executor?.inn.length > 9){
            return this._act?.status === this.enumStatuses.sendToBuyer && activePinfl === this._act?.executor?.inn
        }else {
            return this._act?.status === this.enumStatuses.sendToBuyer && activeInn === this._act?.executor?.inn
        }
    }
    canTheBuyerAccept(activeInn, activePinfl) {
        if (this._act?.customer?.inn.length > 9){
            return this._act?.status === this.enumStatuses.sendToBuyer && activePinfl === this._act?.customer?.inn
        }else {
            return this._act?.status === this.enumStatuses.sendToBuyer && activeInn === this._act?.customer?.inn
        }
    }
    canTheBuyerDecline(activeInn, activePinfl) {
        if (this._act?.customer?.inn.length > 9){
            return this._act?.status === this.enumStatuses.sendToBuyer && activePinfl === this._act?.customer?.inn
        }else {
            return this._act?.status === this.enumStatuses.sendToBuyer && activeInn === this._act?.customer?.inn
        }
    }
}
