import React, {useEffect} from 'react';
import ActFormInfo from "../../../../components/hippo/roaming/act/ActFormInfo";
import ActFormOwnerBuyer from "../../../../components/hippo/roaming/act/ActFormOwnerBuyer";
import ActFormDataTable from "../../../../components/hippo/roaming/act/ActFormDataTable";
import {FormProvider, useForm} from "react-hook-form";
import {Form} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import dayjs from "dayjs";
import {toast} from "react-toastify";
import {generateIdAsync, loadCompanyProductCatalogsAsync, loadMeasurementsAsync,} from "../../../../app/store/reducers/roaming/roamingReducer";
import {selectLang, Translate, updateShowInProgressAsync} from "../../../../app/store/reducers/main/mainReducer";
import {selectCurrentCertificate} from "../../../../app/store/reducers/eimzo/eimzoReducer";
import {useHistory} from "react-router-dom";
import {ActFormSendAsync, ActFormSendDraft} from "../../../../app/store/reducers/roaming/roamingActReducer";
import EventBus from "../../../../app/eventbus/EventBus";
import {
    ROAMING_ACT_DRAFT_FAILED, ROAMING_ACT_DRAFT_REQUESTED,
    ROAMING_ACT_DRAFT_SUCCESS,
    ROAMING_ACT_REGISTER_FAILED, ROAMING_ACT_REGISTER_REQUESTED,
    ROAMING_ACT_REGISTER_SUCCESS
} from "../../../../app/eventbus/roaming/roamingActEvents";
import {getOrganizationBillingInfoAsync} from "../../../../app/store/reducers/billing/billingReducer";
import {selectAccountRolePermissions} from "../../../../app/store/reducers/role/roleReducer";
import {PermissionRoamingActCreate} from "../../../../enum/Permission/RoamingPermission";
import checkPermission from "../../../../enum/Permission/CheckPermission";


const SendAct = () => {
    const activeOrganization = useSelector(selectActiveOrganization);
    const currentCertificate = useSelector(selectCurrentCertificate);
    const dispatch = useDispatch();
    const history = useHistory()
    const lang = useSelector(selectLang);
    const t = Translate;

    const permission = useSelector(selectAccountRolePermissions)

    useEffect(() => {
        if (permission.length && !dispatch(checkPermission(PermissionRoamingActCreate))) {
            if (history.length > 1) {
                history.push('/roaming/acts/inbox')
            } else {
                history.push('/')
            }
        }
    }, [permission])

    useEffect(() => {
        dispatch(loadCompanyProductCatalogsAsync(activeOrganization.inn, lang));
        dispatch(loadMeasurementsAsync(lang));

        methods.setValue('ActSeller', {
            name: activeOrganization?.name || '',
            inn: activeOrganization?.inn || '',
            pinfl: activeOrganization?.pinfl || '',
        });

    }, [activeOrganization])

    useEffect(() => {
        const onSendActRequestedHandler = EventBus.on(ROAMING_ACT_REGISTER_REQUESTED, () => {
            dispatch(updateShowInProgressAsync(true))
        })

        const onSendActSuccessHandler = EventBus.on(ROAMING_ACT_REGISTER_SUCCESS, act => {
            toast.success(t(lang, "edi.common.toast.success"))
            dispatch(getOrganizationBillingInfoAsync());
            history.push(`/roaming/act/${act.id}`)
            dispatch(updateShowInProgressAsync(false))
        })

        const onSendActFailedHandler = EventBus.on(ROAMING_ACT_REGISTER_FAILED, () => {
            toast.error(t(lang, "edi.common.toast.error"))
            dispatch(updateShowInProgressAsync(false))
        })

        const onDraftSendActRequestedHandler = EventBus.on(ROAMING_ACT_DRAFT_REQUESTED, () => {
            dispatch(updateShowInProgressAsync(true))
        })

        const onDraftSendActSuccessHandler = EventBus.on(ROAMING_ACT_DRAFT_SUCCESS, act => {
            toast.success(t(lang, "roaming.act_form_save_success"))
            history.push(`/roaming/draft-act/${act.id}`)
            dispatch(updateShowInProgressAsync(false))
        })

        const onDraftSendActFailedHandler = EventBus.on(ROAMING_ACT_DRAFT_FAILED, () => {
            toast.error(t(lang, "roaming.act_form_save_error"))
            dispatch(updateShowInProgressAsync(false))
        })





        return () => {
            EventBus.remove(ROAMING_ACT_REGISTER_REQUESTED, onSendActRequestedHandler)
            EventBus.remove(ROAMING_ACT_REGISTER_SUCCESS, onSendActSuccessHandler)
            EventBus.remove(ROAMING_ACT_REGISTER_FAILED, onSendActFailedHandler)
            EventBus.remove(ROAMING_ACT_DRAFT_REQUESTED, onDraftSendActRequestedHandler)
            EventBus.remove(ROAMING_ACT_DRAFT_SUCCESS, onDraftSendActSuccessHandler)
            EventBus.remove(ROAMING_ACT_DRAFT_FAILED, onDraftSendActFailedHandler)
        }

    },[])

    const methods = useForm({
        defaultValues: {
            act: {
                info: null,
                date: null,
            },
            contract: {
                number: null,
                date: null,
            },
            ActComment: '',
            ActSeller: {
                inn: activeOrganization.inn,
                name: activeOrganization.name,
                pinfl: activeOrganization.pinfl,
            },
            SellerTin: null,
            SellerName: '',
            SellerBranchCode: '',
            SellerBranchName: '',
            BuyerBranchName: '',
            BuyerBranchCode: '',
            ActBuyer: {
                inn: '',
                name: '',
                pinfl: '',
            },
            itemAct: [
                {
                    catalogClassCode: '',
                    catalogClassName: '',
                    Items: '',
                    measurementId: '',
                    packageNode: null,
                    packageName: null,
                    packages: null,
                    quantity: null,
                    price: null,
                    totalSum: '',
                }
            ]
        },
    });



    const onSubmit = async (formData, event) => {
        const buttonType = event.nativeEvent.submitter.name
        const [ActId, ActProductId] = await generateIdAsync(2)

        const ActSend = JSON.stringify({
            ActId,
            ActDoc: {
                ActNo: formData.act.info,
                ActDate: dayjs(formData.act.date).format('YYYY-MM-DD'),
                ActText: formData.ActComment,
            },
            ContractDoc: {
                ContractNo: formData.contract.number,
                ContractDate: dayjs(formData.contract.date).format('YYYY-MM-DD'),
            },
            SellerTin: formData.ActSeller.pinfl || formData.ActSeller.inn,
            SellerName: formData.ActSeller.name,
            SellerBranchCode: formData.SellerBranchCode,
            SellerBranchName: formData.SellerBranchName,
            BuyerTin: formData.ActBuyer.pinfl || formData.ActBuyer.inn,
            BuyerName: formData.ActBuyer.name,
            BuyerBranchName: formData.BuyerBranchName,
            BuyerBranchCode: formData.BuyerBranchCode,
            ProductList: {
                ActProductId,
                Tin: formData.ActSeller.pinfl || formData.ActSeller.inn,
                Products: formData.itemAct.map((item, index) => {
                    return {
                        OrdNo: index + 1,
                        Name: item.Items,
                        CatalogCode: item.catalogClassCode,
                        CatalogName: item.catalogClassName,
                        PackageCode: item.packageCode ? `${item.packageCode}` : null,
                        PackageName: item.packageName,
                        MeasureId: item.measurementId,
                        Count: item.quantity,
                        Summa: item.price,
                        TotalSum: item.totalSum,
                    }
                })
            },
        })

        if(buttonType === "send") {
            if(currentCertificate?.keyId) {
                await ActFormSendAsync(currentCertificate, lang, ActSend, ActId, formData.ActSeller.pinfl || formData.ActSeller.inn)
            }
        } else if(buttonType === "draft") {
            await ActFormSendDraft(ActSend)
        }
    };


    return (
        <>
            <FormProvider {...methods}>
                <Form onSubmit={methods.handleSubmit(onSubmit)} id="ActForm">
                    <ActFormInfo/>
                    <ActFormOwnerBuyer/>
                    <ActFormDataTable/>
                </Form>
            </FormProvider>
        </>
    )
}

export default SendAct;
