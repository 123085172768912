import {useRouteMatch} from "react-router-dom";
import React, {useEffect, useState} from 'react';
import EventBus from "../../../../app/eventbus/EventBus";
import Organization from "../../../../components/admin/organization/view/Organization";
import {ADD_ORGANIZATION_BALANCE_SUCCEED, APPLY_CREDIT_INTO_ORGANIZATION_SUCCESS} from "../../../../app/eventbus/billingEvents";
import {loadOrganizationAccountsAsync} from "../../../../app/store/reducers/auth/authReducer";
import {getSystemOrganizationAsync} from "../../../../app/store/reducers/billing/billingReducer";

const ViewOrganization = () => {
    const {params: {id}} = useRouteMatch();
    const [organization, setOrganization] = useState(null);
    const [organizationAccounts, setOrganizationAccounts] = useState([]);

    useEffect(() => {
        loadOrganizationInfo();
        loadOrganizationAccounts();
    }, [id])

    useEffect(() => {
        const onAddOrganizationBalanceSucceedHandler = EventBus.on(ADD_ORGANIZATION_BALANCE_SUCCEED, () => {
            loadOrganizationInfo();
        })

        const onCreditApplySuccessHandler = EventBus.on(APPLY_CREDIT_INTO_ORGANIZATION_SUCCESS, () => {
            loadOrganizationInfo();
        })

        return () => {
            EventBus.remove(ADD_ORGANIZATION_BALANCE_SUCCEED, onAddOrganizationBalanceSucceedHandler);
            EventBus.remove(APPLY_CREDIT_INTO_ORGANIZATION_SUCCESS, onCreditApplySuccessHandler);
        }
    }, [])


    const loadOrganizationInfo = () => {
        getSystemOrganizationAsync(id)
            .then(loadedOrganization => setOrganization(loadedOrganization))
            .catch(error => console.log(error))
    }

    const loadOrganizationAccounts = () => {
        loadOrganizationAccountsAsync(id)
            .then(loadedAccounts => setOrganizationAccounts(loadedAccounts))
            .catch(error => console.log(error))
    }

    return (
        <>
            {!organization && <span>loading....</span>}
            {organization && <Organization organization={organization} accounts={organizationAccounts} id={id} />}
        </>
    );
};

export default ViewOrganization;
