import React, {Fragment, useMemo} from 'react';
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {
    deleteSystemOrganizationAddressesAsync,
    selectSystemOrganizationAddresses,
    selectSystemOrganizationAddressesFilter,
    selectSystemOrganizationAddressesPagination,
    setSystemOrganizationAddressPagination
} from "../../../../app/store/reducers/roamingReference/roamingReferenceReducer";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import IconButton from "../../../common/IconButton";
import CommonDeleteModal from "../../../common/CommonDeleteModal";
import {toast} from "react-toastify";
import {Card} from "react-bootstrap";
import AdvanceTable from "../../../common/advance-table/AdvanceTable";
import AdvanceTableWrapper from "../../../common/advance-table/AdvanceTableWrapper";
import AdvanceTableFooter from "../../../common/advance-table/AdvanceTableFooter";
import AdvanceTableCustomPagination2 from "../../item/datatable/AdvanceTableCustomPagination2";
import SystemOrganizationDatatableHeader from "./SystemOrganizationDatatableHeader";
import {transliterate} from "transliteration";

const SystemOrganizationAddressDataTable = () => {

    const organizationAddresses = useSelector(selectSystemOrganizationAddresses);
    const {page, limit} = useSelector(selectSystemOrganizationAddressesPagination, shallowEqual);
    const filter = useSelector(selectSystemOrganizationAddressesFilter);
    const dispatch = useDispatch();
    const lang = useSelector(selectLang);
    const t = Translate;

    const columns = useMemo(() => {
        return [
            {
                Header: "#",
                Cell: ({row}) => {
                    return row.index + 1;
                },
            },
            {
                accessor: 'contractor_name',
                Header: t(lang, "roaming.organization_address.datatable.column.contractor"),
                Cell: ({row: {original}}) => {
                    return `${original?.contractor_name} (${original?.contractor_inn})`;
                }
            },
            {
                accessor: 'region_name',
                Header: t(lang, "roaming.organization_address.datatable.column.region"),
                Cell: ({row: {original}}) => {
                    return original?.region_name;
                }
            },
            {
                accessor: 'district_name',
                Header: t(lang, "roaming.organization_address.datatable.column.district"),
                Cell: ({row: {original}}) => {
                    return original?.district_name;
                }
            },
            {
                accessor: 'village_name',
                Header: t(lang, "roaming.organization_address.datatable.column.village"),
                Cell: ({row: {original}}) => {
                    return original?.village_name;
                }
            },
            {
                accessor: 'street',
                Header: t(lang, "roaming.organization_address.datatable.column.street"),
                Cell: ({row: {original}}) => {
                    return original?.street;
                }
            },
            {
                accessor: 'other',
                Header: t(lang, "roaming.organization_address.datatable.column.other"),
                Cell: ({row: {original}}) => {
                    return original?.other;
                }
            },
            {
                accessor: 'none',
                Header: '',
                cellProps: {className: 'text-end'},
                Cell: ({row: {original}}) => {
                    return (
                        <CommonDeleteModal modalTitle={t(lang, 'Удаление адреса')} onDelete={() => deleteCompanyAddress(original.id)}>
                            <IconButton icon={'trash'} variant={'danger'} size={'sm'} className={'mx-1'}/>
                        </CommonDeleteModal>
                    );
                },
            }
        ];
    }, [organizationAddresses, filter, page, limit]);

    const data = useMemo(() => {
        let filtered_contractors = [];
        for (let i = 0; i < organizationAddresses.length; i++) {
            const contractor = organizationAddresses[i];

            if (filter.name) {
                let found = false;
                const searchName = filter.name.trim().toLowerCase();
                let contractorNameLower

                if (!isNaN(filter.name)) {
                    contractorNameLower = contractor?.contractor_inn?.trim().toLowerCase();
                } else {
                    contractorNameLower = contractor?.contractor_name?.trim().toLowerCase()
                }

                const transliteratedQuery = transliterate(searchName);
                const filterNameParts = transliteratedQuery.replaceAll('  ', ' ').split(' ');
                const transliteratedItemName = transliterate(contractorNameLower);
                if (filterNameParts.length === filterNameParts.filter(fnp => transliteratedItemName.indexOf(fnp) > -1).length)
                    found = true;

                if (!found)
                    continue;

            }
            filtered_contractors.push(contractor);
        }

        return filtered_contractors;
    }, [organizationAddresses, filter, page, limit])

    const deleteCompanyAddress = (addressId) => {
        deleteSystemOrganizationAddressesAsync(addressId)
            .then(() => toast.success(t(lang, 'common.toast.success')))
            .catch(() => toast.error(t(lang, 'common.toast.error')))
    }
    const onLimitChange = (limit) => dispatch(setSystemOrganizationAddressPagination({limit: limit, page: page}));
    const onPageChange = (page) => dispatch(setSystemOrganizationAddressPagination({limit: limit, page: page}));


    return (
        <Fragment>
            <AdvanceTableWrapper
                pagination
                perPage={limit}
                columns={columns}
                data={data}
                currentPage={page - 1}
            >
                <Card>
                    <Card.Header className={'pb-0'}>
                       <SystemOrganizationDatatableHeader />
                    </Card.Header>
                    <Card.Body>
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}>
                        </AdvanceTable>
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTableFooter table/>
                        <AdvanceTableCustomPagination2 rowsPerPageOptions={[10, 25, 50, 75, 100, 500, 1000]}
                                                       onPageChange={onPageChange}
                                                       onLimitChange={onLimitChange}
                                                       table
                        />
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>
        </Fragment>
    );
};

export default SystemOrganizationAddressDataTable;
